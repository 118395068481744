import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, TableBody, Tooltip } from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import AddValueIcon from "../../../../../Assets/Images/Factory/plusIcon.png";
import RemoveIcon from "../../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
const getRemenantStockInputData = inventoryServices.getRemenantStockInputData;
const createRemenantStock = inventoryServices.createRemenantStock;
const updateRemenantStock = inventoryServices.updateRemenantStock;

const ManageStocks = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [saving, setSaving] = useState(false);
  const [expand, setExpand] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [emptyField, setEmptyField] = useState(false);
  const [lineId, setLineId] = useState();
  const [exceedQtyError, setExceedQtyError] = useState(false);

  const [emptyFieldMessage, setEmptyFieldMessage] = useState("");

  const [references, setReferences] = useState([]);
  const [storageBlocks, setStorageBlocks] = useState([]);
  const [referenceObject, setReferenceObject] = useState();
  const [referenceObjectId, setReferenceObjectId] = useState(0);
  const [referenceLengthObject, setReferenceLengthObject] = useState();
  const [referenceLengthObjectId, setReferenceLengthObjectId] = useState(0);

  useEffect(() => {
    if (props.inventoryId) {
      props.setLoadingData(true);
      getRemenantStockInputData(props.inventoryId).then((x) => {
        setReferences(x.Data);
        setStorageBlocks(x.Storages);

        props.setLoadingData(false);
      });
    }
  }, [props.inventoryId]);
  const InventoryReferenceStocks = {
    Stocks: referenceObject?.RemnantStocks,
  };
  const checkField = () => {
    let empty = false;
    if (referenceLengthObject?.Bundles.length === 0) {
      empty = true;
      setEmptyField(true);
      setEmptyFieldMessage("No Bundles, Add Bundles.");
    }
    if (!empty)
      referenceLengthObject?.Bundles?.map((bundle) => {
        if (
          !bundle.ZoneId ||
          !bundle.StorageId ||
          !bundle.HeatNumber ||
          !bundle.Quantity ||
          !bundle.Weight
        ) {
          empty = true;
          setEmptyField(true);
          setEmptyFieldMessage("Empty Field in " + bundle.Name);
        }
      });
    if (!empty) {
      setEmptyField(false);
      save();
    }
  };
  useEffect(() => {
    if (!referenceLengthObjectId || !referenceObjectId) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  const save = () => {
    setSaving(true);
    // if (referenceLengthObject.Bundles[0].Id) {
      updateRemenantStock(referenceLengthObject).then(() => {
        setSaving(false);
      });
    // } else {
    //   createRemenantStock(referenceLengthObject).then(() => {
    //     setSaving(false);
    //   });
    // }
  };

  const addOldZoneCapacity = (zoneId, storageId, bundleWeight) => {
    if (zoneId !== null && storageId !== null) {
      let ZoneChosen = storageBlocks
        .find((s) => s.Id === storageId)
        ?.Zones.find((z) => z.Id === zoneId);

      ZoneChosen.AvailableCapacity =
        ZoneChosen.AvailableCapacity + bundleWeight;
      forceUpdate();
    }
  };

  const checkZoneCapacity = (storageId, zoneId, bundleWeight, bundle) => {
    let ZoneChosen = storageBlocks
      .find((s) => s.Id === storageId)
      ?.Zones.find((z) => z.Id === zoneId);

    if (bundleWeight > ZoneChosen.AvailableCapacity) {
      setExceedQtyError(true);
    } else {
      bundle.ZoneId = zoneId;
      let BundleWeightINKg = bundleWeight;
      ZoneChosen.AvailableCapacity =
        ZoneChosen.AvailableCapacity - BundleWeightINKg;
      setExceedQtyError(false);
    }
    forceUpdate();
  };
  return (
    <>
      <Grid item container direction={"row"}>
        <Grid item xs={6} className={classes["TableTitle"]}>
          Manage Remnant Stock
        </Grid>
        <Grid
          item
          container
          xs={6}
          direction={"row-reverse"}
          paddingRight={1.5}
        >
          <button
            className={classes["cancelbut"]}
            onClick={() => {
              props.setActiveTab(props.activeTab + 1);
            }}
          >
            Next
          </button>
        </Grid>
      </Grid>
      <Grid item xs={2.3}>
        <Grid item className={classes["Item-Category"]}>
          Reference Group
        </Grid>
        <Grid item>
          <TextField
            value={referenceObjectId}
            fullWidth
            select
            SelectProps={{
              MenuProps: { disableScrollLock: true },
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              setReferenceObjectId(e.target.value);
              setReferenceLengthObjectId(0);
              setReferenceLengthObject(null);
              setReferenceObject(
                references.filter((x) => x.Id === e.target.value)[0]
              );
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Reference Group
            </MenuItem>
            {references.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid item className={classes["Item-Category"]}>
          Reference Length
        </Grid>
        <Grid item>
          <TextField
            value={referenceLengthObjectId}
            fullWidth
            select
            SelectProps={{
              MenuProps: { disableScrollLock: true },
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              setReferenceLengthObjectId(e.target.value);
              setReferenceLengthObject(
                referenceObject.RemnantStocks.filter(
                  (x) => x.Id === e.target.value
                )[0]
              );
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Reference Length
            </MenuItem>
            {referenceObject?.RemnantStocks?.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {referenceLengthObject ? (
        <>
          <Grid item xs={1.3}>
            <Grid item className={classes["Item-Category"]}>
              No. Of Bundles
            </Grid>
            <Grid item className={classes["Rectangle-9856"]}>
              {referenceLengthObject?.Bundles?.length}
            </Grid>
          </Grid>
          {expand ? (
            <Grid
              item
              xs={1}
              md={0.5}
              marginTop={1.5}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setExpand(false);
              }}
            >
              <ExpandLessSharpIcon
                fontSize="medium"
                htmlColor="#fff"
                style={{ backgroundColor: "#e1e319", borderRadius: 50 }}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={1}
              md={0.5}
              marginTop={1.5}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setExpand(true);
              }}
            >
              <ExpandMoreSharpIcon
                fontSize="medium"
                htmlColor="#fff"
                style={{ backgroundColor: "#e1e319", borderRadius: 50 }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={1}
            marginTop={1.5}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              localStorage.setItem("RemnantStockId", referenceLengthObjectId);

              props.setPrintingRemnant(true);
            }}
          >
            <LocalPrintshopOutlinedIcon fontSize="medium" htmlColor="#000" />
          </Grid>
          {expand ? (
            <Grid item container direction="column" gap={2}>
              <Grid item container className={classes["TableTitle"]}>
                Bundles:
              </Grid>
              {referenceLengthObject?.Bundles.map((bundle, index) => (
                <Grid item container direction={"row"} gap={2}>
                  <Grid item xs={1.5}>
                    <Grid item className={classes["Item-Category"]}>
                      Bundle Name
                    </Grid>
                    <Grid item className={classes["Rectangle-9856"]}>
                      {bundle.Name}
                    </Grid>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Grid item className={classes["Item-Category"]}>
                      *Heat No.
                    </Grid>
                    <Grid item>
                      <TextField
                        value={bundle.HeatNumber}
                        fullWidth
                        disabled={bundle.Id ? true : false}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onChange={(e) => {
                          bundle.HeatNumber = e.target.value;
                          forceUpdate();
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Grid item className={classes["Item-Category"]}>
                      *Initial Quantity (Pcs)
                    </Grid>
                    <Grid item>
                      <TextField
                        value={bundle.Quantity}
                        disabled={bundle.Id ? true : false}
                        fullWidth
                        type={"number"}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={(e) => {
                          bundle.Quantity = +e.target.value;
                          forceUpdate();
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Grid item className={classes["Item-Category"]}>
                      *Bundle Weight (Kg)
                    </Grid>
                    <Grid item>
                      <TextField
                        value={bundle.Weight}
                        fullWidth
                        disabled={bundle.Id ? true : false}
                        type={"number"}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                          inputProps: {
                            min: 0,
                          },
                        }}
                        onChange={(e) => {
                          let oldBundleWeight = bundle.Weight;
                          let oldZoneId = bundle.ZoneId;
                          bundle.Weight = parseInt(e.target.value);
                          bundle.ZoneId = null;
                          addOldZoneCapacity(
                            oldZoneId,
                            bundle.StorageId,
                            oldBundleWeight
                          );

                          forceUpdate();
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Grid item className={classes["Item-Category"]}>
                      *Storage Block
                    </Grid>
                    <Tooltip title={storageBlocks?.find((s) => s.Id === bundle.StorageId)?.Code} placement="top-start">
                    <TextField
                      value={bundle.StorageId}
                      fullWidth
                      disabled={bundle.Id ? true : false}
                      select
                      SelectProps={{
                        MenuProps: { disableScrollLock: true },
                      }}
                      defaultValue={0}
                      InputProps={{
                        style: {
                          color: "#707070",
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      onChange={(e) => {
                        let oldZoneId = bundle.ZoneId;
                        let oldStorageId = bundle.StorageId;
                        bundle.StorageId = e.target.value;
                        bundle.ZoneId = null;
                        addOldZoneCapacity(
                          oldZoneId,
                          oldStorageId,
                          bundle.Weight
                        );
                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} disabled>
                        Block
                      </MenuItem>
                      {storageBlocks.map((r) => (
                        <MenuItem value={r.Id} key={r.Id}>
                          {r.Code}
                        </MenuItem>
                      ))}
                    </TextField>
                    </Tooltip>
                  </Grid>
                  {bundle.StorageId !== 0 || !bundle.StorageId ? (
                    <Grid item xs={1.5}>
                      <Grid item className={classes["Item-Category"]}>
                        *Storage Zone
                      </Grid>
                      <TextField
                        value={bundle.ZoneId}
                        fullWidth
                        error={
                          lineId === index && exceedQtyError ? true : false
                        }
                        helperText={
                          lineId === index && exceedQtyError
                            ? "This bundle weight exceeds zone capacity"
                            : ""
                        }
                        disabled={
                          bundle.Weight === 0 || !bundle.Weight || bundle.Id
                            ? true
                            : false
                        }
                        select
                        default={0}
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onChange={(e) => {
                          setLineId(index);
                          let oldZoneId = bundle.ZoneId;

                          checkZoneCapacity(
                            bundle.StorageId,
                            e.target.value,
                            bundle.Weight,
                            bundle
                          );
                          addOldZoneCapacity(
                            oldZoneId,
                            bundle.StorageId,
                            bundle.Weight
                          );

                          forceUpdate();
                        }}
                      >
                        <MenuItem value={0} disabled>
                          Zone
                        </MenuItem>
                        {storageBlocks
                          .filter((x) => x.Id === bundle.StorageId)[0]
                          ?.Zones?.map((r) => (
                            <MenuItem value={r.Id} key={r.Id}>
                              {r.Code +
                                "/Capacity:" +
                                r.AvailableCapacity +
                                "Kg"}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            xs={12}
            marginTop={1.5}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              let bdNum = referenceLengthObject.Bundles.length + 1;
              referenceLengthObject.Bundles =
                referenceLengthObject.Bundles.concat({
                  Id: null,
                  Name: "Bundle " + bdNum,
                  HeatNumber: null,
                  Quantity: 0,
                  Weight: 0,
                  StorageId: null,
                  ZoneId: null,
                });
              forceUpdate();
            }}
          >
            <img src={AddValueIcon} width={20} alt="img" />
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          disabled={disabled}
          className={classes["SaveBut"]}
          onClick={() => {
            checkField();
          }}
        >
          {saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setManageStocks(false);
          }}
        >
          Go Back
        </button>
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={12}
          fontWeight={"bold"}
          color={"#ea001e"}
          paddingTop={1}
        >
          {emptyField ? emptyFieldMessage : ""}
        </Grid>
      </Grid>
    </>
  );
};

export default ManageStocks;
