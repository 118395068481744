import * as constFile from "../../ProductionPlanningConst";
import axios from "axios";

const GET_FACTORIES_CODES = constFile.GET_FACTORIES_CODES;
export const getFactoriesCodes = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_FACTORIES_CODES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const GET_OPTIMIZATION_CALENDAR = constFile.GET_OPTIMIZATION_CALENDAR;
export const getOptimizationCalendar = (Date,FactoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_OPTIMIZATION_CALENDAR}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Date: Date,
        FactoryId: FactoryId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const CREATE_COMBINATIONS_SALES_ORDER =
  constFile.CREATE_COMBINATIONS_SALES_ORDER;
export const createCombinationSales = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_COMBINATIONS_SALES_ORDER}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const GET_COMBINATIONS_SALES_ORDER = constFile.GET_COMBINATIONS_SALES_ORDER;
export const getCombinationSalesOrder = (CombinationId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_COMBINATIONS_SALES_ORDER}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        CombinationId: CombinationId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const ASSIGNING_ROUTE = constFile.ASSIGNING_ROUTE;
export const assignRoute = (RouteId, LineId,CombinationId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ASSIGNING_ROUTE}`,
      {
        RouteId: RouteId,
        LineId: LineId,
        CombinationId:CombinationId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const MACHINES_OPTIMIZATIONLIST = constFile.MACHINES_OPTIMIZATIONLIST;
export const getMachinesOptimizationList = (CombinationId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + MACHINES_OPTIMIZATIONLIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        CombinationId: CombinationId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const MACHINE_OCCUPATION = constFile.MACHINE_OCCUPATION;
export const getMachineOccupation = (MachineId, CombinationId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + MACHINE_OCCUPATION}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        MachineId: MachineId,
        CombinationId: CombinationId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const MACHINE_OPTIMIZATION = constFile.MACHINE_OPTIMIZATION;
export const machineOptimazation = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + MACHINE_OPTIMIZATION}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const VALIDATE_OPTIMIZATION = constFile.VALIDATE_OPTIMIZATION;
export const validateOptimazation = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + VALIDATE_OPTIMIZATION}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const VALIDATE_PRODUCTION_RUN = constFile.VALIDATE_PRODUCTION_RUN;
export const validateProductionRun = (CombinationId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + VALIDATE_PRODUCTION_RUN}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        CombinationId: CombinationId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const DELETE_COMBINATION = constFile.DELETE_COMBINATION;
export const deleteCombination = (CombinationId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_COMBINATION}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        CombinationId: CombinationId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
