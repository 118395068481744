import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import classes from "./DocumentModal.module.css";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import infoTip from "../../../../Assets/Images/form/info.png";
import { IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as SaasServices from "../../../Services/saas-services.proxy";
import { MenuItem } from "@mui/material";
import fileIcon from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import * as proAndSubServices from "../../../Services/activities-services.proxy";
import ErrorModal from "../../../SharedComponents/ErrorModal";

const getProjectList = proAndSubServices.getProjectList;
const getPhasesAndSubs = proAndSubServices.getPhasesAndSubs;
const subProjectDiscipline = proAndSubServices.subProjectDiscipline;
const getDocumentInputData = SaasServices.getDocumentInputData;
const addDocument = SaasServices.addDocument;
const updateDocument = SaasServices.updateDocument;
const uploadDocumentFile = SaasServices.uploadDocumentFile;
const deleteDocumentFile = SaasServices.deleteDocumentFile;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "75%",
  width: "60%",
  overflow: "scroll",
};
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}
const DocumentModal = (props) => {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const AccountType = localStorage.getItem("AccountType");

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [description, setDescription] = useState("");
  const [descError, setDescrError] = useState(false);

  const [discipline, setDiscipline] = useState("");
  const [disciplineError, setDisciplineError] = useState(false);
  const [disciplineErrorMessage, setDisciplineErrorMessage] = useState("");

  const [project, setProject] = useState("");
  const [projectError, setProjectError] = useState(false);

  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState(false);

  const [approval, setApproval] = useState("");
  const [approvalError, setApprovalError] = useState(false);

  const [number, setNumber] = useState("");
  const [version, setVersion] = useState("");

  const [type, setType] = useState("");
  const [size, setSize] = useState("");
  const [rfis, setRfis] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [revision, setRevision] = useState("");
  const [revisionDate, setRevisionDate] = useState("");
  const [creator, setCreator] = useState("");

  const [documentId, setDocumentId] = useState(null);
  const [documentError, setDocumentError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [subProject, setSubProject] = useState(null);
  const [phaseName, setPhaseName] = useState(null);
  const [phaseId, setPhaseId] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [beError, setBeError] = useState(null);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  const [disableStatus, setDisableStatus] = useState(false);
  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
        color: #101650;
        background-color: rgba(6, 116, 185, 0.3);
        font-size: 10px;
        width:"10em;
     
    `);
  useEffect(() => {
    for (let i = 0; i < UserPermissions?.length; i++) {
      for (let j = 0; j < UserPermissions[i].Permissions.length; j++) {
        if (UserPermissions[i].Permissions[j].Name === "Document") {
          if (
            UserPermissions[i].Permissions[j].Actions.find(
              (a) => a.Name === "Update Status"
            )
          ) {
            setDisableStatus(false);
            break;
          } else {
            setDisableStatus(true);
          }
        } else {
          setDisableStatus(true);
        }
      }
    }
  }, [UserPermissions]);
  useEffect(() => {
    if (props.rowData) {
      setDocumentId(props.rowData.id);
    } else {
      setDocumentId(null);
    }
  }, [props, setDocumentId, documentId]);

  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileIds] = useState([]);

  const [previousFileId, setPreviousFileId] = useState(null);
  const [subProjectId, setSubProjectId] = useState(null);
  const [disciplines, setDisciplines] = useState([]);
  const [projects, setProjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [approvals, setApprovals] = useState([]);

  const [loadingSubDisc, setLoadingSubDisc] = useState(false);
  const [loadingProjectDetails, setLoadingProjectDetails] = useState(false);

  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFileMsg, setLoadingFileMsg] = useState(false);

  // const handleFileChange = (e) => {
  //   if (previousFileId) {
  //     deleteDocumentFile(previousFileId);
  //   }
  //   if (e.target.files[0]) {
  //     if (fileId) setPreviousFileId(fileId);
  //     setFileLoading(true);
  //     uploadDocumentFile(e.target.files[0]).then((x) => {
  //       setFileLoading(false);
  //       setFile(x);
  //       setFileId(x.FileId);
  //     });
  //   }
  // };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLoadingFile(true);
      setLoadingFileMsg("Uploading File");
      uploadDocumentFile(e.target.files[0]).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setFile(x);
          setFileId(x.FileId);
        }
        setLoadingFile(false);

      });
    }
  };

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1);
      if (files.length === 0) setIsDisabled(true) // 2nd parameter means remove one item only
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setName("");
    setDescription("");
    setVersion("");
    setNumber("");
    setDiscipline("");
    setProject("");
    setStatus("");
    setApproval("");
    setType("");
    setSize("");
    setRfis("");
    setUploadDate("");
    setRevision("");
    setRevisionDate("");
    setCreator("");
    setFile(null);
    setFileId(null);
    setNameError(false);
    setDescrError(false);
    setDisciplineError(false);
    setProjectError(false);
    setStatusError(false);
    setApprovalError(false);
    setDocumentError(false);
    setDocumentId(null);
    setOpen(false);
    setSubProject("");
    setSubProjectId();
    setPhaseName("");
    props.setOpenModal(false);
    setDisciplineErrorMessage("");
    setProjectDetails([]);
    setLoading(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  useEffect(() => {
    if (
      !name ||
      !description ||
      !discipline ||
      !project ||
      !status ||
      !approval ||
      files.length === 0
      // file === null
      ||
      nameError ||
      descError ||
      disciplineError ||
      projectError ||
      statusError ||
      approvalError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    name,
    description,
    discipline,
    project,
    status,
    approval,
    file,
    nameError,
    descError,
    disciplineError,
    projectError,
    statusError,
    approvalError,
    files
  ]);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getDocumentInputData(documentId).then((x) => {
      setLoadingData(false);

      if (apiTest) {
        if (x.status) {
          setBeError(x.error)
        }
        else if (documentId) {
          setName(x.Name);
          setDescription(x.Description);
          setVersion(x.Version);
          setNumber(x.Number);
          setDiscipline(x.DisciplineId);
          setProject(x.ProjectId);
          setStatus(x.StatusId);
          setApproval(x.ApprovalStatusId);
          //props.setStatusType(x.ApprovalStatusId);
          // setType(x.File.Type);
          // setSize(bytesToSize(x.File.Size));
          setRfis(x.RFISNumber);
          setUploadDate(x.CreationDate);
          setRevision(x.RevisionNumber);
          setRevisionDate(x.RevisionDate);
          setCreator(x.Creator);
          // setFile(x.File);
          // setFileId(x.File.FileId);
          setFiles(x.Files)
          setSubProject(x.SubProject);
          setSubProjectId(x.SubProjectId);
          setPhaseName(x.Phase);
        } else {
          setName("");
          setDescription("");
          setVersion("");
          setNumber("");
          setDiscipline("");
          setProject("");
          setStatus("");
          setApproval("");
          setType("");
          setSize("");
          setRfis("");
          setUploadDate("");
          setRevision("");
          setRevisionDate("");
          setCreator("");
          setFile(null);
          setFileId(null);
        }
        setDisciplines(x.Discipline);
        // setProjects(x.Projects);
        setStatuses(x.Statuses);
        setApprovals(x.ApprovalStatuses);
        getProjectList().then((x) => {
          setProjects(x);
        });
      }
    });
    return () => {
      apiTest = false;
    };
  }, [documentId]);

  // Function to filter unique values of a specific field
  const  filterUniqueValues = (arr, fieldName) => {
    // Use Set to store unique values
    const uniqueValues = new Set();

    // Use filter to filter unique values based on the field
    const filteredArray = arr.filter(item => {
      if (uniqueValues.has(item[fieldName])) {
        return false; // Duplicate found, exclude
      }
      uniqueValues.add(item[fieldName]);
      return true; // Unique value, include
    });

    return filteredArray;
  }

  const data = {
    Id: documentId,
    Name: name,
    Description: description,
    Version: version,
    Number: number,
    DisciplineId: discipline,
    ProjectId: project,
    SubProjectId: subProjectId,
    StatusId: status,
    ApprovalStatusId: approval,
    Files: filterUniqueValues(fileIds, 'FileId')
    // File: {
    //   FileId: fileId,
    // },
  };

  const [loading, setLoading] = useState(false);
  const save = () => {
    setLoading(true);
    if (data.Id)
      updateDocument(data).then((x) => {
        props.setModify(!props.modify);
      });
    else
      addDocument(data).then((x) => {
        props.setModify(!props.modify);
      });
  };
  const [projectDetails, setProjectDetails] = useState([]);
  const [openedCard, setopenedCard] = useState([]);

  const getProjectDetails = () => {
    setLoadingProjectDetails(true);
    getPhasesAndSubs(project).then((x) => {
      setLoadingProjectDetails(false);
      setProjectDetails(x.Sections);
    });
  };
  const getDisciplineOfSubProject = (SubPID) => {
    setLoadingSubDisc(true);
    subProjectDiscipline(SubPID).then((x) => {
      setLoadingSubDisc(false);
      setDisciplines(x);
    });
  };
  const renderSubProjects = (phase, parentArray) => {
    return parentArray?.map((parent) => (
      <Grid container key={parent.Id}>
        <Grid
          item
          className={classes["Rectangle-1467"]}
          xs={11.5}
          container
          direction={"row"}
          color={
            subProjectId === parent.Id
              ? "#fff"
              : openedCard?.includes(parent.Id)
                ? "#0674b9"
                : "rgba(6, 116, 185, 0.5)"
          }
          bgcolor={subProjectId === parent.Id ? "#0674b9" : "#fff"}
          onClick={() => {
            setSubProjectId(parent.Id);
            setSubProject(parent.Name);
            setPhaseName(phase.Name);
            setPhaseId(phase.Id);
            getDisciplineOfSubProject(parent.Id);
            if (discipline) {
              setDiscipline("");
              setDisciplineError(true);
              setDisciplineErrorMessage(
                "Please Select a discpline that refers to the subproject"
              );
            }
          }}
        >
          <Grid
            item
            md={1}
            xs={2}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              if (openedCard?.includes(parent.Id)) {
                let arrayIDs = openedCard;
                arrayIDs = arrayIDs.filter(function (itemphase) {
                  return itemphase !== parent.Id;
                });
                setopenedCard(arrayIDs);
              } else {
                let arrayIDs = openedCard;

                setopenedCard(arrayIDs.concat(parent.Id));
              }
            }}
          >
            {parent.Sections?.length > 0 ? (
              openedCard?.includes(parent.Id) ? (
                "-"
              ) : (
                "+"
              )
            ) : (
              <></>
            )}
          </Grid>
          <Grid item paddingLeft={2.5} md={10} xs={9}>
            {parent.Name}
          </Grid>
        </Grid>
        {openedCard?.includes(parent.Id) ? (
          renderSubProjects(phase, parent.Sections)
        ) : (
          <></>
        )}
      </Grid>
    ));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf="flex-end"
            paddingRight="19.5%"
            paddingTop="50px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              sx={style}
              justifyContent={loadingData ? "center" : "normal"}
              alignItems={loadingData ? "center" : "normal"}
              height={
                nameError ||
                  descError ||
                  disciplineError ||
                  projectError ||
                  statusError ||
                  approvalError
                  ? "85%"
                  : "75%"
              }
            >
              {loadingData ? (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress />
                  <Grid
                    item
                    fontFamily={"Muli"}
                    fontSize={15}
                    fontWeight={"bold"}
                    color={"rgb(25, 118, 210)"}
                  >
                    &nbsp; &nbsp;Loading ...
                  </Grid>
                </Grid>
              ) : beError ?
                <Grid item xs={12} alignSelf="center" textAlign="center" style={{ fontFamily: "Muli", fontSize: "20px", color: "red" }}> {beError}</Grid>
                : (
                  <Grid container alignItems="center" direction="column" gap={1}>
                    <Grid item className={classes.newDocument} paddingTop={1}>
                      {documentId ? "Edit Document" : "New Document"}
                    </Grid>
                    <Grid
                      item
                      className={classes["Line-402"]}
                      alignSelf={"center"}
                      justifySelf={"center"}
                      width="90%"
                    ></Grid>
                    <Grid item container paddingLeft={"5%"} gap={1}>
                      <Grid item container xs={12} direction={"row"}>
                        <Grid
                          item
                          md={3}
                          xs={12}
                          className={classes["Document-Information"]}
                          alignSelf="flex-start"
                        >
                          Document Information
                          {AccountType === "1" ? (
                            <Grid
                              item
                              className={classes["Line-403-Main"]}
                              width="176px"
                            />
                          ) : (
                            <Grid
                              item
                              className={classes["Line-403-Sub"]}
                              width="176px"
                            />
                          )}
                        </Grid>
                        <Grid
                          md={9}
                          xs={12}
                          item
                          container
                          justifyContent={"flex-end"}
                          direction="row"
                          alignItems={"flex-end"}
                          paddingRight={5}
                        >
                          <Grid item paddingRight={2}>
                            <button
                              className={classes.exitModal}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                                e.target.style.backgroundColor = "#f5f5f5";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#fff";
                              }}
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Cancel
                            </button>
                          </Grid>
                          <Grid item>
                            <button
                              disabled={isDisabled || loading}
                              className={classes.SaveModal}
                              // onMouseOver={(e) => {
                              //   e.target.style.cursor = "pointer";
                              //   e.target.style.backgroundColor = "#242B64";
                              // }}
                              // onMouseLeave={(e) => {
                              //   e.target.style.backgroundColor = "#0b5cab";
                              // }}
                              style={{
                                backgroundColor: isDisabled
                                  ? "#bcbcbc"
                                  : "#0b5cab",
                                fontFamily: "Muli",
                                textTransform: "none",
                                opacity: loading ? 0.7 : 1,
                              }}
                              onClick={() => {
                                save();
                                setTimeout(() => {
                                  props.setRowData(null);
                                  handleClose();
                                }, [2500]);
                              }}
                            >
                              {loading ? "Saving..." : "Save"}
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container direction={"row"}>
                        <Grid item container direction={"column"} md={6} xs={12}>
                          <Grid item>
                            <span className={classes["field-label"]}>*Name</span>
                          </Grid>
                          <Grid item>
                            <TextField
                              className={classes["Field-input"]}
                              value={name}
                              error={nameError}
                              helperText={
                                nameError ? "Enter a document name" : ""
                              }
                              id="outlined-name"
                              size="small"
                              InputProps={{
                                style: { height: "30px" },
                              }}
                              onBlur={(e) => {
                                if (
                                  !e.target.value ||
                                  e.target.value.length > 150
                                )
                                  setNameError(true);
                              }}
                              onChange={(e) => {
                                if (
                                  !e.target.value ||
                                  e.target.value.length > 150
                                )
                                  setNameError(true);
                                else setNameError(false);
                                setName(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item container direction={"column"}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                *Description
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                className={classes["Field-input"]}
                                value={description}
                                error={descError}
                                multiline
                                helperText={
                                  descError ? "Enter a description" : ""
                                }
                                id="outlined-name"
                                rows={4}
                                size="small"
                                InputProps={{
                                  style: { height: "90px" },
                                }}
                                onBlur={(e) => {
                                  if (
                                    !e.target.value ||
                                    e.target.value.length > 255
                                  )
                                    setDescrError(true);
                                }}
                                onChange={(e) => {
                                  if (
                                    !e.target.value ||
                                    e.target.value.length > 255
                                  )
                                    setDescrError(true);
                                  else setDescrError(false);
                                  setDescription(e.target.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"row"}>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label"]}>
                                  Version
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={version}
                                  className={classes["Field-input2"]}
                                  InputProps={{ style: { height: "30px" } }}
                                  id="outlined-name"
                                  size="small"
                                  onChange={(e) => {
                                    setVersion(e.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label"]}>
                                  Number
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={number}
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                  onChange={(e) => {
                                    setNumber(e.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                *Project Reference
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                select
                                value={project}
                                error={projectError}
                                helperText={
                                  projectError ? "Choose a project" : ""
                                }
                                className={classes["Field-input"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                                onBlur={(e) => {
                                  if (!e.target.value) setProjectError(true);
                                }}
                                onChange={(e) => {
                                  setProjectError(false);

                                  setProject(e.target.value);
                                }}
                              >
                                {projects?.map((option) => (
                                  <MenuItem
                                    value={option.Id}
                                    key={option.Id}
                                    style={{ display: "block" }}
                                  >
                                    {option.Name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          {project ? (
                            <Grid item container direction={"column"}>
                              <Grid item>
                                <span className={classes["field-label"]}>
                                  SubProject
                                </span>
                              </Grid>
                              <Grid
                                item
                                className={classes["Rectangle-1169"]}
                                width={"90%"}
                                onClick={() => {
                                  getProjectDetails();
                                }}
                              >
                                {loadingProjectDetails ? "loading ..." : subProject ? subProject : "Subproject"}
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {subProject ? (
                            <Grid item container direction={"column"}>
                              <Grid item>
                                <span className={classes["field-label"]}>
                                  Phase
                                </span>
                              </Grid>
                              <Grid
                                item
                                className={classes["Rectangle-1170"]}
                                width={"90%"}
                              >
                                {phaseName}
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                          <Grid item container direction={"column"}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                *Discipline
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                select
                                value={discipline}
                                error={disciplineError}
                                helperText={disciplineErrorMessage}
                                className={classes["Field-input"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                                onBlur={(e) => {
                                  if (!e.target.value) {
                                    setDisciplineError(true);

                                    setDisciplineErrorMessage(
                                      "Choose a discipline"
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  setDisciplineError(false);
                                  setDiscipline(e.target.value);
                                  setDisciplineErrorMessage("");
                                }}
                              >
                                {disciplines?.map((option) => (
                                  <MenuItem
                                    value={option.Id}
                                    key={option.Id}
                                    style={{ display: "block" }}
                                  >
                                    {option.Name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>

                          {projectDetails.length > 0 ? (
                            <Grid
                              container
                              height={"fit-content"}
                              item
                              paddingTop={2}
                            >
                              <Grid
                                item
                                xs={12}
                                className={classes["page-title"]}
                              >
                                Select Subproject:
                              </Grid>
                              <Grid
                                item
                                container
                                bgcolor={"#f2f2f2"}
                                xs={9}
                                gap={1}
                                paddingBottom={5}
                                paddingLeft={1.5}
                                paddingTop={1}
                              >
                                {projectDetails?.map((phase) => (
                                  <Grid container>
                                    <Grid
                                      key={phase.Id}
                                      item
                                      className={classes["Rectangle-1466"]}
                                      xs={11.5}
                                      container
                                      direction={"row"}
                                      color={"#0674b9"}
                                    >
                                      <Grid
                                        md={1}
                                        xs={2}
                                        item
                                        onMouseOver={(e) => {
                                          e.target.style.cursor = "pointer";
                                        }}
                                        onClick={(e) => {
                                          if (openedCard?.includes(phase.Id)) {
                                            let arrayIDs = openedCard;
                                            arrayIDs = arrayIDs.filter(function (
                                              itemphase
                                            ) {
                                              return itemphase !== phase.Id;
                                            });
                                            setopenedCard(arrayIDs);
                                          } else {
                                            let arrayIDs = openedCard;

                                            setopenedCard(
                                              arrayIDs.concat(phase.Id)
                                            );
                                          }
                                        }}
                                      >
                                        {phase.Sections?.length > 0 ? (
                                          openedCard?.includes(phase.Id) ? (
                                            "-"
                                          ) : (
                                            "+"
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid item paddingLeft={2.5} md={10} xs={9}>
                                        {phase.Name}
                                      </Grid>
                                    </Grid>
                                    {openedCard?.includes(phase.Id) ? (
                                      renderSubProjects(phase, phase.Sections)
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item container direction={"column"} md={6} xs={12}>
                          <Grid item container direction={"row"}>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label"]}>
                                  *Status
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  select
                                  value={status}
                                  error={statusError}
                                  disabled={documentId ? disableStatus : false}
                                  helperText={
                                    statusError ? "Choose a status" : ""
                                  }
                                  className={classes["Field-input2"]}
                                  InputProps={{ style: { height: "30px" } }}
                                  id="outlined-name"
                                  size="small"
                                  onBlur={(e) => {
                                    if (!e.target.value) setStatusError(true);
                                  }}
                                  onChange={(e) => {
                                    setStatusError(false);
                                    setStatus(e.target.value);
                                  }}
                                >
                                  {statuses?.map((option) => (
                                    <MenuItem
                                      value={option.Id}
                                      key={option.Id}
                                      style={{ display: "block" }}
                                    >
                                      {option.Name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label"]}>
                                  *Approval Status
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  select
                                  value={approval}
                                  error={approvalError}
                                  helperText={
                                    approvalError
                                      ? "Choose an approval status"
                                      : ""
                                  }
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                  onBlur={(e) => {
                                    if (!e.target.value) setApprovalError(true);
                                  }}
                                  onChange={(e) => {
                                    setApprovalError(false);
                                    //props.setStatusType(e.target.value);
                                    setApproval(e.target.value);
                                  }}
                                >
                                  {approvals?.map((option) => (
                                    <MenuItem
                                      value={option.Id}
                                      key={option.Id}
                                      style={{ display: "block" }}
                                    >
                                      {option.Name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container direction={"row"}>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Type
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={type}
                                  disabled
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Size
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={size}
                                  disabled
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container direction={"row"}>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Number of RFIs
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={rfis}
                                  disabled
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Upload Date
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={uploadDate?.slice(0, 10)}
                                  disabled
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item container direction={"row"}>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Revision Number
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={revision}
                                  disabled
                                  className={classes["Field-input2"]}
                                  InputProps={{ style: { height: "30px" } }}
                                  id="outlined-name"
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Revision Date
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={revisionDate?.slice(0, 10)}
                                  disabled
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container direction={"row"}>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item>
                                <span className={classes["field-label2"]}>
                                  Creator
                                </span>
                              </Grid>
                              <Grid item>
                                <TextField
                                  value={creator}
                                  disabled
                                  className={classes["Field-input2"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"row"}>
                              <Grid item xs={4.8}>
                                <Button
                                  component="label"
                                  style={{
                                    width: "100%",
                                    marginTop: "23px",
                                    textTransform: "none",
                                    fontFamily: "Muli",
                                    fontSize: "12px",
                                    lineHeight: 1.45,
                                    textAlign: "left",
                                    color: "#101650",
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                    border: "solid 1px #b8b8b8",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "30px",
                                  }}
                                >
                                  <FileUploadOutlinedIcon
                                    htmlColor="#529bd7"
                                    style={{
                                      alignSelf: "center",
                                      paddingRight: "5%",
                                    }}
                                  />
                                  <span style={{ alignSelf: "center" }}>
                                    Upload File
                                  </span>
                                  <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                  />
                                </Button>
                              </Grid>
                              <Grid item marginRight={1} xs={1} paddingTop={3}>
                                <IconButton
                                  edge="end"
                                  onClick={openTip}
                                  onMouseOver={openTip}
                                  onMouseLeave={closeTip}
                                >
                                  <BlueTip
                                    open={tooltipIsOpen}
                                    title={
                                      files?.length > 0
                                        ? "Click on file to download"
                                        : "you can upload any document related to the contractor or a contract. "
                                    }
                                    placement="right"
                                  >
                                    <img
                                      width={"15px"}
                                      src={infoTip}
                                      alt={"close"}
                                    ></img>
                                  </BlueTip>
                                </IconButton>
                              </Grid>
                              {loadingFile ?
                                <Grid item xs={3} paddingTop={3} style={{ fontFamily: "Muli", fontSize: "12px" }}>  <CircularProgress size={14} />  {loadingFileMsg}</Grid>
                                :
                                <></>
                              }
                            </Grid>
                            {files?.map((item) => (
                              <Grid
                                container
                                direction={"row"}
                                key={item.FileId}
                                value={item}
                                paddingTop={1}
                              >
                                <Grid item xs={1} paddingLeft={1} paddingTop={0.5}>
                                  <img
                                    src={AccountType === "1" ? fileIcon : fileIconSub}
                                    alt="file"
                                    width={15}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={2.5}
                                  onMouseOver={(e) => {
                                    e.target.style.color = "grey";
                                    e.target.style.cursor = "pointer";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.color = "black";
                                  }}
                                  onClick={() => {
                                    window.open(file?.URL);
                                  }}
                                >
                                  <span>{item?.DisplayName.slice(0, 10)}</span>
                                </Grid>
                                <Grid
                                  item
                                  paddingLeft={2}
                                  onMouseOver={(e) => {
                                    e.target.style.cursor = "pointer";
                                  }}
                                  onClick={() => {
                                    deleteItem(item);
                                    setFile(null);
                                    setFileId(null);
                                    forceUpdate();
                                  }}
                                >
                                  <Grid item xs={1}>
                                    <CloseIcon fontSize="small" />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                            {/* <Grid
                              item
                              container
                              direction={"column"}
                              xs={6}
                              paddingTop={3}
                            >
                              <Grid item>
                                <Button
                                  component="label"
                                  style={{
                                    width: "80%",
                                    textTransform: "none",
                                    fontFamily: "Muli",
                                    fontSize: "12px",
                                    lineHeight: 1.45,
                                    textAlign: "left",
                                    color: "#101650",
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                    border: "solid 1px #b8b8b8",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "30px",
                                  }}
                                >
                                  <FileUploadOutlinedIcon
                                    htmlColor="#529bd7"
                                    style={{
                                      alignSelf: "center",
                                      paddingRight: "5%",
                                    }}
                                  />
                                  <span style={{ alignSelf: "center" }}>
                                    Upload File
                                  </span>
                                  <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                  />
                                </Button>
                              </Grid>
                              {fileLoading ?
                                <Grid item container className={classes["Document-Information"]} alignItems="center" marginTop={2}>
                                  Uploading File &nbsp; <CircularProgress size="18px" />
                                </Grid>
                                :
                                <></>}
                              {file ? (
                                <Grid item container paddingTop={1}>
                                  {documentId ? (
                                    <Grid item marginRight={1}>
                                      <IconButton
                                        edge="end"
                                        onClick={openTip}
                                        onMouseOver={openTip}
                                        onMouseLeave={closeTip}
                                      >
                                        <BlueTip
                                          open={tooltipIsOpen}
                                          title={"Click on file to download"}
                                          placement="right"
                                        >
                                          <img
                                            width={"15px"}
                                            src={infoTip}
                                            alt={"close"}
                                          ></img>
                                        </BlueTip>
                                      </IconButton>
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                  <Grid
                                    item
                                    container
                                    direction="row"
                                    alignContent="center"
                                    paddingLeft={1}
                                    paddingTop={1}
                                  >
                                    <Grid item xs={1.5}>
                                      <img
                                        src={
                                          AccountType === "1"
                                            ? fileIcon
                                            : fileIconSub
                                        }
                                        alt="file"
                                        width={15}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <span
                                        onMouseOver={(e) => {
                                          e.target.style.color = "grey";
                                          e.target.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.color = "black";
                                        }}
                                        onClick={() => {
                                          window.open(file.URL);
                                        }}
                                      >
                                        {file.DisplayName.slice(0, 7)}
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      paddingTop={0.2}
                                      onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                      }}
                                      onClick={() => {
                                        setFile(null);
                                        setFileId(null);
                                      }}
                                    >
                                      <CloseIcon fontSize="small" />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid item></Grid>
                              )}
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </div>
  );
};

export default DocumentModal;