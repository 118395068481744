import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Tooltip,
} from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import EndAnchorIcon from "../../../../../Assets/Images/Inventory/endanchorIcon.png";
import SlingIcon from "../../../../../Assets/Images/Inventory/slingIcon.png";
import ThreadIcon from "../../../../../Assets/Images/Inventory/threadNew.png";
import HalfThreadIcon from "../../../../../Assets/Images/Inventory/halfThread.jpg";
import CouplerIcon from "../../../../../Assets/Images/Inventory/couplerNew.png";
import * as inventoryConsts from "../../../../../../InventorySetupConsts";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const getInventoryInputData = inventoryServices.getInventoryInputData;
const createInventory = inventoryServices.createInventory;
const updateInventory = inventoryServices.updateInventory;

const CreationFrom = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();
  const [isdisabled, setIsDisabled] = useState(true);
  const [emptyField, setEmptyField] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [saving, setSaving] = useState(false);

  const [trade, setTrade] = useState(1);
  const [trades, setTrades] = useState([]);

  const [nbOfDeliveryUnit, setNbOfDeliveryUnit] = useState(3);
  const [deliveryUOMS, setDeliveryUOMS] = useState([]);
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  // add product types
  const [addNewType, setAddNewType] = useState(false);
  const [newTypeName, setNewTypeName] = useState("");
  const [hoveredPlusIcon, setHoveredPlusIcon] = useState(false);

  const [itemCategoriesData, setItemCategoriesData] = useState([
    {
      CategoryId: inventoryConsts.RAWMAMTERIAL_TYPE,
      Abbreviation: null,
      IsSelected: false,
      Types: [],
    },
    {
      CategoryId: inventoryConsts.REMNANTS_TYPE,
      Abbreviation: null,
      IsSelected: false,
      Types: [],
    },
    {
      CategoryId: inventoryConsts.PRODUCTS_TYPE,
      Abbreviation: null,
      IsSelected: false,
      Types: [],
    },
    {
      CategoryId: inventoryConsts.PRECUT_BAR_TYPE,
      Abbreviation: null,
      IsSelected: false,
      Types: [],
    },
  ]);

  useEffect(() => {
    if (!props.code || itemCategoriesData.length === 0 || !itemCategoriesData) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  const checkAllFilled = () => {
    let emptyFound = false;
    if (!itemCategoriesData.some(obj => obj.IsSelected === true)) emptyFound = true;
    itemCategoriesData.map((itemCategory) => {
      if (
        (!itemCategory.Abbreviation ||
          itemCategory.Types.length === 0 ||
          !itemCategory.Types) &&
        itemCategory.IsSelected === true
      ) {
        emptyFound = true;
      }
      if (!emptyFound) {
        itemCategory.Types.map((type) => {
          if (!type.Name || !type.Abbreviation) {
            emptyFound = true;
          }
        });
      }
    });

    if (!emptyFound) {
      setEmptyField(false);
      save();
    } else {
      setEmptyField(true);
    }
  };
  const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  useEffect(() => {
    getInventoryInputData(props.factoryId ? props.factoryId : null).then(
      (x) => {
        setTrades(x.Trades);
        setInventoryTypes(x.Types);
        setDeliveryUOMS(x.UOMs);
        setCategories(x.Categories);
        props.setCode(x.Inventory.Code);
        if (x.Inventory.Id) {
          props.setInventoryId(x.Inventory.Id);

          setTrade(x.Inventory.TradeId);
          let CategoriesToTwist = x.Inventory.Categories;
          CategoriesToTwist?.forEach((cat) => {
            cat.IsSelected = true;
          });
          console.log("CategoriesToTwist", CategoriesToTwist)
          let ExistingCats = CategoriesToTwist?.flatMap((x) => x.CategoryId);
          console.log("ExistingCats", ExistingCats)
          if (!ExistingCats?.includes(inventoryConsts.RAWMAMTERIAL_TYPE)) {
            let newItem = {
              CategoryId: inventoryConsts.RAWMAMTERIAL_TYPE,
              Abbreviation: null,
              IsSelected: false,
              Types: [],
            };
            CategoriesToTwist = insert(CategoriesToTwist, 0, newItem);
          }
          if (!ExistingCats?.includes(inventoryConsts.REMNANTS_TYPE)) {
            let newItem = {
              CategoryId: inventoryConsts.REMNANTS_TYPE,
              Abbreviation: null,
              IsSelected: false,
              Types: [],
            };
            CategoriesToTwist = insert(CategoriesToTwist, 1, newItem);
          }
          if (!ExistingCats?.includes(inventoryConsts.PRODUCTS_TYPE)) {
            let newItem = {
              CategoryId: inventoryConsts.PRODUCTS_TYPE,
              Abbreviation: null,
              IsSelected: false,
              Types: [],
            };
            CategoriesToTwist = insert(CategoriesToTwist, 2, newItem);
          }
          if (!ExistingCats?.includes(inventoryConsts.PRECUT_BAR_TYPE)) {
            let newItem = {
              CategoryId: inventoryConsts.PRECUT_BAR_TYPE,
              Abbreviation: null,
              IsSelected: false,
              Types: [],
            };
            CategoriesToTwist = insert(CategoriesToTwist, 3, newItem);
          }
          setItemCategoriesData(CategoriesToTwist);
        }

        setLoadingData(false);
      }
    );
  }, [props.factoryId]);

  const InventoryData = {
    Id: props.inventoryId,
    FactoryId: props.factoryId,
    Code: props.code,
    DeliveryUnitsNumber: 3,
    Categories: itemCategoriesData.filter((cat) => cat.IsSelected === true),
  };

  useEffect(() => {
    console.log("itemCategoriesData", itemCategoriesData)
  })

  // useEffect(() => {
  //   if (!props.inventoryId) {
  //     if (!props.code || itemCategoriesData.length === 0 || !itemCategoriesData || !itemCategoriesData.some(obj => obj.IsSelected === true)) {
  //       console.log("item categories", itemCategoriesData)

  //       setIsDisabled(true);
  //     } else {
  //       let empty = false;
  //       if (itemCategoriesData.some(obj => obj.IsSelected === true)) {
  //         itemCategoriesData.map((i) => {
  //           if (i.IsSelected === true) {
  //             if (i.Types.length === 0) empty = true
  //             else {
  //               i.Types.map((t) => {
  //                 if (!t.Name || !t.Abbreviation || !t.DeliveryUOMId || !t.Name || !t.ReceiptUOMId) empty = true
  //               })
  //             }
  //           }
  //         })
  //       }
  //       setIsDisabled(empty);
  //     }
  //   }

  // }, [itemCategoriesData]);
  const save = () => {
    setSaving(true);
    if (props.inventoryId) {
      updateInventory(InventoryData).then((x) => {
        setSaving(false);
        props.setActiveTab(props.activeTab + 1);
      });
    } else {
      createInventory(InventoryData).then((x) => {
        props.setInventoryId(x);
        setSaving(false);
        props.setActiveTab(props.activeTab + 1);
      });
    }
  };
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item md={2.2} sm={3.5} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          *Factory Inventory Code
        </Grid>
        <Grid item>
          <TextField
            value={props.code}
            disabled
            fullWidth
            InputProps={{
              style: {
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item md={1.2} sm={2.5} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          *Inventory Trade
        </Grid>
        <Grid item>
          <TextField
            value={trade}
            fullWidth
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            InputProps={{
              style: {
                color: "#0674b9",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
                cursor: "unset"
              },
            }}
          >
            {trades.map((r) => (
              <MenuItem value={r.Id} key={r.Id} disabled={true} >
                <span
                  style={{
                    color: r.Name !== "Rebar" ? "#707070" : "#0674b9",
                    cursor: "unset",
                    fontWeight: "normal"
                  }}
                >{r.Name}</span>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item md={1} lg={1} sm={1.3} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          No. of Delivery Unit
        </Grid>
        {/* <Grid item className={classes["Rectangle-3005"]}>{nbOfDeliveryUnit}</Grid> */}
        <Grid item>
          <TextField
            value={nbOfDeliveryUnit}
            fullWidth
            disabled
            InputProps={{
              style: {
                color: "#0674b9",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            placeholder="Nb"
          />
        </Grid>
      </Grid>
      <Grid item md={4} sm={4} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          Item Type Delivery UoM
        </Grid>
        <Grid item container direction={"row"} gap={1}>
          {deliveryUOMS.map((uom) => (
            <Grid item className={classes["Rectangle-3005"]}>
              {uom.Name}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={12} sm={0}></Grid>
      <Grid item overflow={"auto"}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}>*Inventory Category</TableCell>
                {categories.map((type) => (
                  <TableCell
                    key={type.Id}
                    // sx={
                    //   type.Id === inventoryConsts.PRODUCTS_TYPE
                    //     ? styles.tableCellheadPr
                    //     : styles.tableCellhead
                    // }
                    sx={styles.tableCellhead}
                  >
                    <Grid container direction={"row"} gap={1}>
                      {type.Name}
                      <Grid
                        marginTop={0.5}
                        item
                        className={
                          itemCategoriesData?.filter(
                            (itm) => itm.CategoryId === type.Id
                          )[0]?.IsSelected
                            ? classes["CheckedBox"]
                            : classes["notCheckedBox"]
                        }
                        onClick={() => {
                          const itemToChange = itemCategoriesData?.filter(
                            (itm) => itm.CategoryId === type.Id
                          )[0];
                          console.log(itemToChange);
                          if (itemToChange.IsSelected === false) {
                            itemToChange.IsSelected = true;
                          } else {
                            itemToChange.Types = [];
                            itemToChange.Abbreviation = null;

                            itemToChange.IsSelected = false;
                          }
                          forceUpdate();
                        }}
                      ></Grid>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  *Inventory Category Abbreviation
                </TableCell>
                {itemCategoriesData?.map((category) => (
                  <TableCell
                    sx={
                      category.CategoryId === inventoryConsts.PRODUCTS_TYPE
                        ? styles.tableCellheadPrBody
                        : styles.tableCellheadBody
                    }
                    key={category.CategoryId}
                  >
                    <TextField
                      disabled={category.IsSelected ? false : true}
                      value={category.Abbreviation}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      placeholder="Abbreviation"
                      onChange={(e) => {
                        category.Abbreviation = e.target.value;
                        forceUpdate();
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  *Inventory Type Code
                </TableCell>
                {itemCategoriesData?.map((category) => (
                  <TableCell
                    sx={styles.tableCellheadBody}
                    key={category.CategoryId}
                  >
                    <Grid container rowGap={1}>
                      {inventoryTypes
                        ?.filter((x) => x.CategoryId === category.CategoryId
                          && x.Name !== "Half Thread" && x.Name !== "End Anchor" && x.Name !== "Sling"
                          // && x.Name !== "Thread"
                        )
                        ?.map((item,index) => (
                          <Grid
                            // xs={item.Id === 10 || item.Id === 7 ? 6: item.Id === 3 || item.Id === 6 || item.CategoryId === 3? 12 : 6}
                            xs={item.TypeName?.length || item.Name?.length > 9 ? 12 : 6}
                            item
                            container
                            direction={"row"}
                            key={index}
                            gap={1}
                          >
                            <Grid
                              item
                              className={
                                category.Types.filter(
                                  (itr) => itr.TypeId?itr.TypeId === item.Id: itr.Name === item.Name
                                )[0]
                                  ? classes["CheckedBox"]
                                  : classes["notCheckedBox"]
                              }
                              onClick={() => {
                                console.log("category.Types",category.Types)
                                console.log("item",item)

                                if (category.IsSelected) {
                                  if (
                                    category.Types?.filter(
                                      (itr) =>  itr.TypeId?itr.TypeId === item.Id : itr.Name === item.Name
                                    )[0]
                                  ) {
                                    console.log("remove item",item)
                                    if(item.Id)
                                    category.Types = category.Types.filter(
                                      (rm) => rm.TypeId !== item.Id 
                                    );
                                    else
                                    category.Types = category.Types.filter(
                                      (rm) => rm.Name !== item.Name
                                    );
                                    console.log("category.Types",category.Types)
                                  } else {
                                    console.log("not exist add now", item)
                                    category.Types = category.Types.concat({
                                      TypeId: item.Id ? item.Id : null,
                                      TypeName: item.Name,
                                      Name: item.Name,
                                      Abbreviation: null,
                                      DeliveryUOMId:
                                      (category.CategoryId === inventoryConsts.PRODUCTS_TYPE || category.CategoryId === inventoryConsts.PRECUT_BAR_TYPE)
                                           ?
                                          deliveryUOMS.find((u) => u.Name === "Unit").Id
                                          : null,
                                      ReceiptUOMId:
                                      (category.CategoryId === inventoryConsts.PRODUCTS_TYPE || category.CategoryId === inventoryConsts.PRECUT_BAR_TYPE)
                                        // (item.Id === inventoryConsts.DECOILED_TYPE_RN ||
                                        //   item.Id === inventoryConsts.SLINGS_TYPE
                                        //   || item.Id === inventoryConsts.ENDANCHOR_TYPE
                                        //   || item.Id === inventoryConsts.COUPLER_TYPE) || !item.Id 
                                          ?
                                          deliveryUOMS.find((u) => u.Name === "Unit").Id :
                                          null,
                                    });
                                    console.log("category.Types",category.Types)

                                  }
                                  forceUpdate();
                                }
                              }}
                            ></Grid>
                            <Grid item>{item.Name}</Grid>
                          </Grid>
                        ))}
                      {category.CategoryId === inventoryConsts.PRODUCTS_TYPE ?
                        <>
                          {!addNewType ?
                            <Tooltip title="click to add a new type">
                              <AddCircleIcon fontSize="small" style={{ cursor: 'pointer', color: '#E1E319' }} onClick={() => setAddNewType(true)} />
                            </Tooltip>
                            :
                            <></>
                          }
                          {addNewType ?
                            <Grid item container direction='row' alignItems='center' columnGap={0.8}>
                              <TextField
                                value={newTypeName}
                                InputProps={{
                                  style: {
                                    height: "28px",
                                    width: "110px",
                                    fontSize: 12,
                                    fontFamily: "Muli",
                                  },
                                }}
                                onChange={(e) => {
                                  setNewTypeName(e.target.value)
                                }}

                              />
                              {hoveredPlusIcon ?
                                <CheckBoxIcon
                                  fontSize="small"
                                  onMouseOut={() => setHoveredPlusIcon(false)}
                                  style={{ color: "#32CD32", cursor: 'pointer' }}
                                  onClick={() => {
                                    let newTypeObject = {
                                      TypeId: null,
                                      ReceiptUOMId: deliveryUOMS.find((u) => u.Name === "Unit").Id,
                                      Name: newTypeName,
                                      TypeName: newTypeName,
                                      DeliveryUOMId: deliveryUOMS.find((u) => u.Name === "Unit").Id,
                                      Abbreviation: null
                                    }
                                    const updatedItemCategoriesData = itemCategoriesData?.map((i) => i.CategoryId === inventoryConsts.PRODUCTS_TYPE ? { ...i, Types: [...i.Types, newTypeObject] } : i)
                                    setItemCategoriesData(updatedItemCategoriesData);
                                    let newInventoryTypeObject = {
                                      Id: null,
                                      Name: newTypeName,
                                      CategoryId: inventoryConsts.PRODUCTS_TYPE

                                    }
                                    const updatedInventoryTypes = [...inventoryTypes, newInventoryTypeObject];
                                    setInventoryTypes(updatedInventoryTypes);
                                    setAddNewType(false);
                                    setNewTypeName("");
                                    setHoveredPlusIcon(false)
                                  }}
                                />
                                :
                                <CheckBoxOutlinedIcon fontSize="small" style={{ color: "#bbb8b8", cursor: 'pointer' }}
                                  onMouseOver={() => setHoveredPlusIcon(true)}
                                />
                              }
                              {/* <CheckBoxOutlinedIcon fontSize="small" style={{color:hoveredPlusIcon?"#32CD32":"#707070",cursor:'pointer'}}
                                onMouseOver={() => setHoveredPlusIcon(true)}
                                onMouseOut={() => setHoveredPlusIcon(false)}
                              /> */}
                              <CancelIcon fontSize="small" style={{ color: "#bbb8b8", cursor: 'pointer' }}
                                onClick={() => {
                                  setAddNewType(false)
                                  setNewTypeName("");
                                }}
                              />
                            </Grid>

                            : ""}
                        </>
                        : ""}

                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  Inventory Type Product Symbol
                </TableCell>
                <TableCell sx={styles.tableCellheadBody}></TableCell>
                <TableCell sx={styles.tableCellheadBody}></TableCell>
                <TableCell sx={styles.tableCellheadPrBody}>
                  <Grid container direction={"row"} gap={1}>
                    {itemCategoriesData
                      ?.filter(
                        (x) => x.CategoryId === inventoryConsts.PRODUCTS_TYPE
                      )[0]
                      ?.Types?.map((pr) => (
                        <Grid item key={pr.TypeId}>
                          {pr.TypeId &&
                            (pr.TypeId === inventoryConsts.COUPLER_TYPE
                              || pr.TypeId === inventoryConsts.SLINGS_TYPE
                              || pr.TypeId === inventoryConsts.ENDANCHOR_TYPE
                              || pr.TypeId === inventoryConsts.THREAD_TYPE
                              || pr.TypeId === inventoryConsts.HALF_THREAD_TYPE
                            ) ?
                            <img
                              src={
                                pr.TypeId === inventoryConsts.COUPLER_TYPE
                                  ? CouplerIcon
                                  : pr.TypeId === inventoryConsts.SLINGS_TYPE
                                    ? SlingIcon
                                    : pr.TypeId === inventoryConsts.ENDANCHOR_TYPE
                                      ? EndAnchorIcon
                                      : pr.TypeId === inventoryConsts.THREAD_TYPE
                                        ? ThreadIcon
                                        : pr.TypeId === inventoryConsts.HALF_THREAD_TYPE
                                          ? HalfThreadIcon
                                          : ""
                              }
                              width={54}
                              alt="icon"
                            />
                            :
                            ""}

                        </Grid>
                      ))}
                  </Grid>
                </TableCell>
                <TableCell sx={styles.tableCellheadBody}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  *Inventory Type Name
                </TableCell>
                {itemCategoriesData?.map((category) => (
                  <TableCell
                    sx={styles.tableCellheadBody}
                    key={category.CategoryId}
                  >
                    {category?.Types?.map((rm) => (
                      <Grid item xs={12}>
                        <Grid item className={classes["Lfields"]}>
                          {!rm.TypeId ? rm.TypeName :
                            inventoryTypes?.find((i) => i.Id === rm.TypeId)?.Name}
                          {/* {rm.TypeId === inventoryConsts.BARS_TYPE_RM ||
                            rm.TypeId === inventoryConsts.BARS_TYPE_RN
                            ? "Bars"
                            : rm.TypeId === inventoryConsts.COILS_TYPE_RM ||
                              rm.TypeId === inventoryConsts.COILS_TYPE_RN
                              ? "Coils"
                              : rm.TypeId === inventoryConsts.DECOILED_TYPE_RM ||
                                rm.TypeId === inventoryConsts.DECOILED_TYPE_RN
                                ? "Decoiled Products"
                                : rm.TypeId === inventoryConsts.SLINGS_TYPE
                                  ? "Sling"
                                  : rm.TypeId === inventoryConsts.ENDANCHOR_TYPE
                                    ? "End Anchor"
                                    : rm.TypeId === inventoryConsts.COUPLER_TYPE
                                      ? "Coupler"
                                      : rm.TypeId === inventoryConsts.THREAD_TYPE
                                        ? "Thread"
                                        : rm.TypeId === inventoryConsts.HALF_THREAD_TYPE
                                          ? "Half-Thread"
                                          : !rm.TypeId ? rm.TypeName : ""} */}
                        </Grid>
                        <Grid item>
                          <TextField
                            value={rm.Name}
                            fullWidth
                            disabled={category.IsSelected ? false : true}
                            InputProps={{
                              style: {
                                height: "28px",
                                fontSize: 12,
                                fontFamily: "Muli",
                              },
                            }}
                            placeholder="Name"
                            onChange={(e) => {
                              rm.Name = e.target.value;
                              forceUpdate();
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  *Inventory Type Abbreviation
                </TableCell>
                {itemCategoriesData?.map((category) => (
                  <TableCell
                    sx={styles.tableCellheadBody}
                    key={category.CategoryId}
                  >
                    {category?.Types?.map((rm) => (
                      <Grid item xs={12}>
                        <Grid item className={classes["Lfields"]}>
                          {!rm.TypeId ? rm.TypeName :
                            inventoryTypes?.find((i) => i.Id === rm.TypeId)?.Name}
                          {/* {rm.TypeId === inventoryConsts.BARS_TYPE_RM ||
                            rm.TypeId === inventoryConsts.BARS_TYPE_RN
                            ? "Bars"
                            : rm.TypeId === inventoryConsts.COILS_TYPE_RM ||
                              rm.TypeId === inventoryConsts.COILS_TYPE_RN
                              ? "Coils"
                              : rm.TypeId === inventoryConsts.DECOILED_TYPE_RM ||
                                rm.TypeId === inventoryConsts.DECOILED_TYPE_RN
                                ? "Decoiled Products"
                                : rm.TypeId === inventoryConsts.SLINGS_TYPE
                                  ? "Sling"
                                  : rm.TypeId === inventoryConsts.ENDANCHOR_TYPE
                                    ? "End Anchor"
                                    : rm.TypeId === inventoryConsts.COUPLER_TYPE
                                      ? "Coupler"
                                      : rm.TypeId === inventoryConsts.THREAD_TYPE
                                        ? "Thread"
                                        : rm.TypeId === inventoryConsts.HALF_THREAD_TYPE
                                          ? "Half-Thread"
                                          : !rm.TypeId ? rm.TypeName
                                            :
                                            ""} */}
                        </Grid>
                        <Grid item>
                          <TextField
                            value={rm.Abbreviation}
                            fullWidth
                            disabled={category.IsSelected ? false : true}
                            InputProps={{
                              style: {
                                height: "28px",
                                fontSize: 12,
                                fontFamily: "Muli",
                              },
                            }}
                            placeholder="Abbreviation"
                            onChange={(e) => {
                              rm.Abbreviation = e.target.value;
                              forceUpdate();
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  Inventory Type Delivery UoM
                </TableCell>
                {itemCategoriesData?.map((category) => (
                  <TableCell
                    sx={styles.tableCellheadBody}
                    key={category.CategoryId}
                  >
                    {category?.Types?.map((rm) =>
                      rm.TypeId !== inventoryConsts.THREAD_TYPE &&
                        rm.TypeId !== inventoryConsts.HALF_THREAD_TYPE ? (
                        <Grid item xs={12}>
                          <Grid item className={classes["Lfields"]}>
                            {!rm.TypeId ? rm.TypeName :
                              inventoryTypes?.find((i) => i.Id === rm.TypeId)?.Name}
                            {/* {rm.TypeId === inventoryConsts.BARS_TYPE_RM ||
                              rm.TypeId === inventoryConsts.BARS_TYPE_RN
                              ? "Bars"
                              : rm.TypeId === inventoryConsts.COILS_TYPE_RM ||
                                rm.TypeId === inventoryConsts.COILS_TYPE_RN
                                ? "Coils"
                                : rm.TypeId ===
                                  inventoryConsts.DECOILED_TYPE_RM ||
                                  rm.TypeId === inventoryConsts.DECOILED_TYPE_RN
                                  ? "Decoiled Products"
                                  : rm.TypeId === inventoryConsts.SLINGS_TYPE
                                    ? "Sling"
                                    : rm.TypeId === inventoryConsts.ENDANCHOR_TYPE
                                      ? "End Anchor"
                                      : rm.TypeId === inventoryConsts.COUPLER_TYPE
                                        ? "Coupler"
                                        : !rm.TypeId ? rm.TypeName
                                          :
                                          ""} */}
                          </Grid>
                          <Grid item>
                            <TextField
                              value={rm.DeliveryUOMId}
                              fullWidth
                              select
                              // disabled={category.IsSelected ? false : true}
                              // disabled={rm.TypeId === inventoryConsts.DECOILED_TYPE_RN ||
                              //   rm.TypeId === inventoryConsts.SLINGS_TYPE
                              //   || rm.TypeId === inventoryConsts.ENDANCHOR_TYPE
                              //   || rm.TypeId === inventoryConsts.COUPLER_TYPE 
                              //   || !rm.TypeId}
                              disabled={category.CategoryId === inventoryConsts.PRODUCTS_TYPE}
                              SelectProps={{
                                MenuProps: { disableScrollLock: true },
                              }}
                              InputProps={{
                                style: {
                                  color: "#707070",
                                  height: "28px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                },
                              }}
                              onChange={(e) => {
                                rm.DeliveryUOMId = e.target.value;
                                forceUpdate();
                              }}
                            >
                              {deliveryUOMS?.map((r) => (
                                <MenuItem value={r.Id} key={r.Id}>
                                  {r.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  Inventory Type Receipt UoM
                </TableCell>
                {itemCategoriesData?.map((category) => (
                  <TableCell
                    sx={styles.tableCellheadBody}
                    key={category.CategoryId}
                  >
                    {category?.Types?.map((rm) =>
                      rm.TypeId !== inventoryConsts.THREAD_TYPE &&
                        rm.TypeId !== inventoryConsts.HALF_THREAD_TYPE ? (
                        <Grid item xs={12}>
                          <Grid item className={classes["Lfields"]}>
                            {!rm.TypeId ? rm.TypeName :
                              inventoryTypes?.find((i) => i.Id === rm.TypeId)?.Name}
                            {/* {rm.TypeId === inventoryConsts.BARS_TYPE_RM ||
                              rm.TypeId === inventoryConsts.BARS_TYPE_RN
                              ? "Bars"
                              : rm.TypeId === inventoryConsts.COILS_TYPE_RM ||
                                rm.TypeId === inventoryConsts.COILS_TYPE_RN
                                ? "Coils"
                                : rm.TypeId ===
                                  inventoryConsts.DECOILED_TYPE_RM ||
                                  rm.TypeId === inventoryConsts.DECOILED_TYPE_RN
                                  ? "Decoiled Products"
                                  : rm.TypeId === inventoryConsts.SLINGS_TYPE
                                    ? "Sling"
                                    : rm.TypeId === inventoryConsts.ENDANCHOR_TYPE
                                      ? "End Anchor"
                                      : rm.TypeId === inventoryConsts.COUPLER_TYPE
                                        ? "Coupler"
                                        : !rm.TypeId ? rm.TypeName
                                          :
                                          ""} */}
                          </Grid>
                          <Grid item>
                            <TextField
                              value={rm.ReceiptUOMId}
                              fullWidth
                              select
                              // disabled={category.IsSelected ? false : true}
                              // disabled={rm.TypeId === inventoryConsts.DECOILED_TYPE_RN ||
                              //   rm.TypeId === inventoryConsts.SLINGS_TYPE
                              //   || rm.TypeId === inventoryConsts.ENDANCHOR_TYPE
                              //   || rm.TypeId === inventoryConsts.COUPLER_TYPE
                              //   || !rm.TypeId}
                              disabled={category.CategoryId === inventoryConsts.PRODUCTS_TYPE}
                              SelectProps={{
                                MenuProps: { disableScrollLock: true },
                              }}
                              InputProps={{
                                style: {
                                  color: "#707070",
                                  height: "28px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                },
                              }}
                              onChange={(e) => {
                                rm.ReceiptUOMId = e.target.value;
                                forceUpdate();
                              }}
                            >
                              {deliveryUOMS?.map((r) => (
                                <MenuItem
                                  value={r.Id}
                                  key={r.Id}
                                  disabled={
                                    r.Id === 3 &&
                                      (category.CategoryId === 1 ||
                                        category.CategoryId === 2)
                                      ? true
                                      : false
                                  }
                                >
                                  {r.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid >
      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classes["SaveBut"]}
          disabled={isdisabled || saving}
          onClick={() => {
            console.log("itemcategories on save", itemCategoriesData)
            checkAllFilled();
          }}
        >
          {saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </button>
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={12}
          fontWeight={"bold"}
          color={"#ea001e"}
        >
          {emptyField
            ? "There is empty fields in the table, Please check."
            : ""}
        </Grid>
      </Grid>
    </>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 67,
    paddingTop: 3.5,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 180,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
};

export default CreationFrom;
