import * as constFile from "../../saasApiConst";
import axios from "axios";
const FormData = require("form-data");
var fileDownload = require("js-file-download");
const CREATE_EDIT_DATA_ACTIVITY = constFile.CREATE_EDIT_DATA_ACTIVITY;
export const getActivitiesCreateEditData = (
  ActivityId,
  SubProjectId,
  ActivityDetailsId
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_EDIT_DATA_ACTIVITY}`,
      {
        ActivityId: ActivityId,
        SubProjectId: SubProjectId,
        ActivityDetailsId: ActivityDetailsId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const GET_PHASES_SUBS_BY_ID = constFile.GET_PHASES_SUBS_BY_ID;
export const getPhasesAndSubs = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_PHASES_SUBS_BY_ID}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProjectId: ProjectId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_PROJECT_LIST = constFile.GET_PROJECT_LIST;
export const getProjectList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_PROJECT_LIST}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_TM_BY_SUBPROJECT = constFile.GET_TM_BY_SUBPROJECT;
export const getTMBySub = (SubProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_TM_BY_SUBPROJECT}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { SubProjectId: SubProjectId },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const ADD_ACTIVITY = constFile.ADD_ACTIVITY;
export const addActivity = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ADD_ACTIVITY}`,

      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const UPDATE_ACTIVITY = constFile.UPDATE_ACTIVITY;
export const updateActivity = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_ACTIVITY}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const GET_ACTIVITIES_CARD_LIST = constFile.GET_ACTIVITIES_CARD_LIST;
export const getActivitiesCardList = (listData) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_ACTIVITIES_CARD_LIST}`,listData,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const TM_UPLOAD_FILE = constFile.TM_UPLOAD_FILE;
export const tMUploadFile = (file, SubProjectId, setProgress) => {
  const onUploadProgress = (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);

    setProgress(percentCompleted);
  };
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("SubProjectId", SubProjectId);
  return axios
    .post(`${process.env.REACT_APP_URL + TM_UPLOAD_FILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const TM_DELETE_FILE = constFile.TM_DELETE_FILE;
export const tMDeleteFile = (fileId, SubProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + TM_DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        SubProjectId: SubProjectId ? SubProjectId : "",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const TM_START_ACTIVITY = constFile.TM_START_ACTIVITY;
export const tMStartActivity = (ActivityId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + TM_START_ACTIVITY}`,
      {
        ActivityId: ActivityId,
        Date: new Date()
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const GET_ACTIVITIES_LIST = constFile.GET_ACTIVITIES_LIST;
export const getActivitiesList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_ACTIVITIES_LIST}`,

      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const SAVE_ACTIVITIES_FILES = constFile.SAVE_ACTIVITIES_FILES;
export const saveActivitiesFiles = (ActivityId, Files) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SAVE_ACTIVITIES_FILES}`,

      { ActivityId: ActivityId, Files: Files },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const TM_DELETE_FILE_AFTER_SAVE = constFile.TM_DELETE_FILE_AFTER_SAVE;
export const tMDeleteFileAfterSave = (ActivityFileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + TM_DELETE_FILE_AFTER_SAVE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ActivityFileId: ActivityFileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const TM_SUBMIT_ACTIVITY = constFile.TM_SUBMIT_ACTIVITY;
export const tMSubmitActivity = (
  ActivityId,
  ReceiverId,
  SubmitTo,
  SubProjectId
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + TM_SUBMIT_ACTIVITY}`,
      {
        ActivityId: ActivityId,
        ReceiverId: ReceiverId,
        SubmitTo: SubmitTo,
        SubProjectId: SubProjectId,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_COMMENT_BY_ACTIVITY = constFile.GET_COMMENT_BY_ACTIVITY;
export const getCommentByActivity = (ActivityId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_COMMENT_BY_ACTIVITY}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ActivityId: ActivityId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const ADD_COMMENT_ON_ACTIVITY = constFile.ADD_COMMENT_ON_ACTIVITY;
export const addCommentOnActivity = (ActivityId, Content, Files, Mentions) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ADD_COMMENT_ON_ACTIVITY}`,
      {
        ActivityDetailsId: ActivityId,
        Content: Content,
        Files: Files,
        Mentions: Mentions,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DELETE_COMMENT = constFile.DELETE_COMMENT;
export const deleteComment = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_COMMENT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_COMMENT_ACTIVITY = constFile.UPDATE_COMMENT_ACTIVITY;
export const UpdateCommentOnActivity = (Id, ActivityId, Content, Files, Mentions) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_COMMENT_ACTIVITY}`,
      { Id: Id, ActivityDetailsId: ActivityId, Content: Content, Files: Files, Mentions: Mentions },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DELETE_ACTIVITY = constFile.DELETE_ACTIVITY;
export const deleteActivity = (ActivityId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_ACTIVITY}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: ActivityId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
const REVISE_ACTIVITY = constFile.REVISE_ACTIVITY;
export const reviseActivity = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + REVISE_ACTIVITY}`,

      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const GET_ACTIVITIES_VERSIONS_LIST = constFile.GET_ACTIVITIES_VERSIONS_LIST;
export const getActivitiesVersionsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_ACTIVITIES_VERSIONS_LIST}`,

      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const ACCEPT_ACTIVITY = constFile.ACCEPT_ACTIVITY;
export const acceptActivity = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + ACCEPT_ACTIVITY}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id: Id,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const LIST_OF_DISCIPLINE_BY_SUBPROJETID =
  constFile.LIST_OF_DISCIPLINE_BY_SUBPROJETID;
export const subProjectDiscipline = (SubProject) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + LIST_OF_DISCIPLINE_BY_SUBPROJETID}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          SubProject: SubProject,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_ACTIVITY_BY_ID = constFile.GET_ACTIVITY_BY_ID;
export const getActivityByID = (token1) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_ACTIVITY_BY_ID}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          token: token1,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_SUBMITTAL_RESPONSE_DATA = constFile.GET_SUBMITTAL_RESPONSE_DATA;
export const getActivitySubmittalResponseData = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_SUBMITTAL_RESPONSE_DATA}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id: Id,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_SUBMITTAL_RESPONSE_DATA = constFile.CREATE_SUBMITTAL_RESPONSE_DATA;
export const createSubmittalResponse = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_SUBMITTAL_RESPONSE_DATA}`,

      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DOWNLOAD_ACTIVITY_PDF = constFile.DOWNLOAD_ACTIVITY_PDF;
export const downloadActFile = (token) => {
  return axios
    .get(`${process.env.REACT_APP_URL + DOWNLOAD_ACTIVITY_PDF}`, {
      responseType: "blob",
      params: { token: token },
    })
    .then((response) => {
      fileDownload(response.data, "Submittal Response Form.pdf");
      return response;
    })
    .catch((err) => {
      return err;
    });
};
const TM_UPLOAD_FORGE_FILE = constFile.TM_UPLOAD_FORGE_FILE;
export const updateForgeFile = (FileName, Urn, Size) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + TM_UPLOAD_FORGE_FILE}`,
      {
        FileName: FileName,
        Urn: Urn,
        Size: Size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_ACTIVITIES_TIMELINE = constFile.GET_ACTIVITIES_TIMELINE;
export const getActivitiesTimeline = (
  data
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_ACTIVITIES_TIMELINE}`, data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_ACTIVITIES_CHART = constFile.GET_ACTIVITIES_CHART;
export const getActivitiesChart = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_ACTIVITIES_CHART}`, data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};