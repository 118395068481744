import React, { useEffect, useState } from 'react';

function LocationInfo() {
  const [country, setCountry] = useState('');

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        handleGeolocationSuccess,
        handleGeolocationError
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  const handleGeolocationSuccess = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // Use a reverse geocoding service to get the country code from the coordinates
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    fetch(geocodingApiUrl)
      .then((response) => response.json())
      .then((data) => {
        const addressComponents = data.results[0].address_components;
        for (let component of addressComponents) {
          if (component.types.includes('country')) {
            const countryCode = component.short_name;
            setCountry(countryCode);
            localStorage.setItem("userCountry",countryCode)
            break;
          }
        }
      })
      .catch((error) => {
        console.error('Error getting location data: ' + error);
      });
  };

  const handleGeolocationError = (error) => {
    console.error('Error getting user location: ' + error.message);
  };

  return (
   <></>
  );
}

export default LocationInfo;
