import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import { Grid, TableContainer, Table, TableRow, TableHead, TableCell, TableBody } from "@mui/material";
import SalesOrderDetails from "./SalesOrderDetails";
import SubprojectsList from "./SubprojectsList";
import { useNavigate, useLocation } from "react-router-dom";
import SalesOrderMemberTable from "./SalesOrderMemberTable";
import * as incstr from "incstr";
import classes from "../../../Assets/Styles/Sales-Order.module.css";
import ShapeLibraryList from "./ShapeLibraryList";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as SALESORDERSCONST from "../../../../salesOrderConst";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import DatesModal from "./DatesModal";
import moment from "moment";
import PreviewPrinting from "./PreviewPrinting";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
import * as SALESORDERDCONSTS from "../../../../salesOrderConst";


const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const importSdi = salesOrderServices.importSdi;
const createOrEditSalesOrder = salesOrderServices.createOrEditSalesOrder;
const createSalesOrder = salesOrderServices.createSalesOrder;
const updateSalesOrder = salesOrderServices.updateSalesOrder;
const exportSdi = salesOrderServices.exportSdi;
const getFactoryProductReferences = salesOrderServices.getFactoryProductReferences;
const getWeigthPeremeter = salesOrderServices.getWeigthPeremeter;

export default function SalesOrderForm(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const [disabledPlace, setDisabledPlace] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Create/Place order manually",
        setDisabledPlace
      );
      CheckIfPermitted(UserPermissions, "Sales Order", "Edit", setDisabledEdit);
    }
  }, [UserPermissions]);

  const [openModal, setOpenModal] = useState(false);
  const [suggest, setSuggest] = useState(false);
  const [validate, setValidate] = useState(false);
  const [previewPrinting, setPreviewPrinting] = useState(false);
  const [finishUpdate, setFinishUpdate] = useState(false);
  const [suggestedDate, setSuggestedDate] = useState();
  const [commentForSuggestion, setCommentForSuggestion] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const [SalesOrderDetailsInfo, setSalesOrderDetailsInfo] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [selectedShape, setSelectedShape] = useState();
  const [members, setMembers] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [memberIdSelected, setMemberIdSelected] = useState(0);
  const [Modify, setModify] = useState(false);

  const [subProjectId, setSubProjectId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorEmptyMessage, setErrorEmptyMessage] = useState(null);
  const [drafting, setDrafting] = useState(false);
  const [placing, setPlacing] = useState(false);
  const [productionProgress, setProductionProgress] = useState(0);

  const [disabledFromActivity, setDisabledFromActivity] = useState(false);
  const [disabledAllFields, setDisabledAllFields] = useState(false);
  const [isvalidator, setIsvalidator] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isSuggester, setIsSuggestor] = useState(false);
  const [exceedWeight, setExceedWeight] = useState(false);

  const [isCreator, setIsCreator] = useState(false);

  //product series count
  const [productCount, setProductCount] = useState([]);

  //threads count
  const [threadCount, setThreadCount] = useState(0);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [SALES_ORDER_ID, setSALES_ORDER_ID] = useState(query.get("id"));

  const [previousSALES_ORDER_ID, setPreviousSALES_ORDER_ID] = useState(null);

  const [calenderDateSwapped, setcalenderDateSwapped] = useState(query.get("date"));

  const PROJECT_ID = query.get("projectId");
  const salesOrderNb = query.get("salesOrderNb");
  const [ACT_ID, setACT_ID] = useState(location.state?.ActId);
  const [chosenFileID, setChosenFileID] = useState(
    location.state?.chosenFileId
  );

  const catalogId = location.state?.CatalogId;

  const [spliceTypes, setSpliceTypes] = useState([]);

  useEffect(() => {
    return () => {
      if (finishUpdate === true) {
        setcalenderDateSwapped(null)
      }
      else {
        setcalenderDateSwapped(query.get("date"))
      }
    };
  }, [finishUpdate])

  useEffect(() => {
    if (PROJECT_ID) {
      setProjectId(PROJECT_ID);
    }
  }, [PROJECT_ID]);
  //#region
  //ORDER DETAILS

  const [typeId, setTypeId] = useState(0);
  const [typeIdError, setTypeIdError] = useState(false);

  const [orderNb, setOrderNb] = useState();

  const [projectName, setProjectName] = useState();

  const [parentId, setParentId] = useState(null);

  const [factoryId, setFactoryId] = useState(null);

  const [factoryMinDaysToDeliver, setFactoryMinDaysToDeliver] = useState(0);

  const [phaseName, setPhaseName] = useState();

  const [subprojectName, setSubprojectName] = useState();

  const [customerCompanyName, setCustomerCompanyName] = useState();

  const [customerCompanyNamesList, setCustomerCompanyNamesList] = useState([]);

  const [customerOrderReference, setCustomerOrderReference] = useState();
  const [customerOrderReferenceError, setCustomerOrderReferenceError] =
    useState(false);
  const [activityCode, setActivityCode] = useState();

  const [drawingNb, setDrawingNb] = useState();
  const [drawingNbError, setDrawingNbError] = useState(false);

  const [description, setDescription] = useState();
  const [descriptionError, setDescriptionError] = useState(false);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [fileIds, setfileIds] = useState([]);

  const [drawingRevisionNb, setDrawingRevisionNb] = useState(0);
  const [drawingRevisionNbError, setDrawingRevisionNbError] = useState(false);

  const [status, setStatus] = useState(1);
  const [sentBy, setSentBy] = useState();
  const [creationDateAndTime, setCreationDateAndTime] = useState();

  const [subcontractor, setSubcontractor] = useState(0);
  const [subcontractorError, setSubcontractorError] = useState(false);

  const [misingFMError, setmisingFMError] = useState(false);

  const [factoryNb, setFactoryNb] = useState(null);
  const [unitOfMeasures, setUnitOfMeasures] = useState();
  const [receiptDate, setReceiptDate] = useState(null);

  const [barStandard, setBarStandard] = useState(0);
  const [barStandards, setBarStandards] = useState([]);
  const [barStandardError, setBarStandardError] = useState(false);

  const [barGrade, setBarGrade] = useState(0);
  const [barGrades, setBarGrades] = useState([]);
  const [barGradeError, setBarGradeError] = useState(false);

  const [barCoating, setBarCoating] = useState(0);
  const [barCoatings, setBarCoatings] = useState([]);
  const [barCoatingError, setBarCoatingError] = useState(false);

  const [plannedDeliveryDate, setPlannedDeliveryDate] = useState();
  const [minPlannedDate, setMinPlannedDate] = useState();
  const [deliveryDateStatus, setDeliveryDateStatus] = useState(1);
  const [subcontractorsList, setSubcontractorList] = useState([]);

  const [salesOrderWeightTheoretical, setSalesOrderWeightTheoretical] =
    useState();

  const [memberTypes, setMemberTypes] = useState([]);
  const [barMarkTypes, setBarMarkTypes] = useState([]);
  const [servicesTypes, setServicesTypes] = useState([]);

  const [customerCompanyNameEmpty, setCustomerCompanyEmpty] = useState(false);
  const [subContractorsEmpty, setSubContractorsEmpty] = useState(false);
  const [factoryDetailsEmpty, setFactoryDetailsEmpty] = useState(false);

  const [subProjectCreator, setSubProjectCreator] = useState();

  const [catalogs, setCatalogs] = useState([]);
  const [importingSdiLoading, setImportingSdiLoading] = useState(false);

  const [products, setProducts] = useState([]);

  //factory product references
  const [factoryProductReferences, setFactoryProductReferences] = useState([]);
  const [weightPeremeters, setWeightPeremeters] = useState([]);

  const [canSaveAsDraft, setCanSaveAsDraft] = useState(false);

  const [hideInitialTotal, setHideInitialTotal] = useState(false);

  useEffect(() => {
    if (factoryId) {
      getFactoryProductReferences(factoryId).then((x) => {
        setFactoryProductReferences(x);
      })
      getWeigthPeremeter(factoryId).then((x) => {
        setWeightPeremeters(x);
      })
    }

  }, [factoryId])


  //#endregion
  useEffect(() => {
    if (
      !customerOrderReference ||
      customerOrderReferenceError ||
      !typeId ||
      // !drawingNb ||
      // drawingNbError ||
      !barGrade ||
      barGradeError ||
      !barStandard ||
      barStandardError ||
      !barCoating ||
      barCoatingError ||
      // drawingNbError ||
      (!subcontractor &&
        !subProjectCreator &&
        localStorage.getItem("AccountType") === "1") ||
      subcontractorError ||
      !plannedDeliveryDate ||
      members.length === 0 ||
      (!customerCompanyName && localStorage.getItem("AccountType") === "2")
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (SalesOrderDetailsInfo) {
      if (ACT_ID) setDisabledFromActivity(true);
      setSubprojectName(SalesOrderDetailsInfo.SubProject);
      setPhaseName(SalesOrderDetailsInfo.Phase);
      setProjectName(SalesOrderDetailsInfo.Project);
      setProjectId(SalesOrderDetailsInfo.ProjectId);
      if (!SalesOrderDetailsInfo.CustomerCompanyName) {
        if (localStorage.getItem("AccountType") === "2")
          setCustomerCompanyEmpty(true);
      } else {
        setCustomerCompanyEmpty(false);
        setCustomerCompanyName(SalesOrderDetailsInfo.CustomerCompanyName);
      }

      setCustomerCompanyName(SalesOrderDetailsInfo.CustomerCompanyName);
      if (SalesOrderDetailsInfo.SubContractors.length === 0) {
        if (localStorage.getItem("AccountType") === "1") {
          setSubContractorsEmpty(true);
        } else {
          setSubContractorsEmpty(false);
        }
      } else {
        setSubContractorsEmpty(false);
        setSubcontractorList(SalesOrderDetailsInfo.SubContractors);
      }

      setSubcontractorList(SalesOrderDetailsInfo.SubContractors);
      setMemberTypes(SalesOrderDetailsInfo.MemberTypes);
      setBarMarkTypes(SalesOrderDetailsInfo.BarMarTypes);
      setSubProjectId(SalesOrderDetailsInfo.SalesOrderDetails.SubProjectId);
      setOrderNb(SalesOrderDetailsInfo.SalesOrderDetails.Code);
      setTypeId(SalesOrderDetailsInfo.SalesOrderDetails.TypeId);
      console.log("check here TypeId", SalesOrderDetailsInfo.SalesOrderDetails.TypeId)
      setCustomerOrderReference(
        SalesOrderDetailsInfo.SalesOrderDetails.CustomerOrderReference
      );
      setActivityCode(SalesOrderDetailsInfo.Activitycode);
      setDrawingNb(SalesOrderDetailsInfo.DrawingNumber);
      setDescription(SalesOrderDetailsInfo.Description)
      setDrawingRevisionNb(SalesOrderDetailsInfo.DrawingRevisionNumber);
      setMembers(SalesOrderDetailsInfo.SalesOrderDetails.Members);
      setSubcontractor(SalesOrderDetailsInfo.SubProjectCreator);
      setSubProjectCreator(SalesOrderDetailsInfo.SubProjectCreator);
      setTypeId(SalesOrderDetailsInfo.TypeId)


      if (
        localStorage.getItem("AccountType") === "2" ||
        SalesOrderDetailsInfo?.PhaseContract
      ) {
        setFactoryMinDaysToDeliver(
          SalesOrderDetailsInfo?.PhaseContract?.Factory
            .MinimumDeliveryDaysPerSalesorder
        );

        let minimumDate = moment(
          SalesOrderDetailsInfo?.SalesOrderDetails.CreationTime
        ).add(
          SalesOrderDetailsInfo?.PhaseContract?.Factory
            .MinimumDeliveryDaysPerSalesorder,
          "days"
        );
        minimumDate = minimumDate.format("llll");
        setMinPlannedDate(minimumDate);
        setPlannedDeliveryDate(minimumDate)
        setFactoryNb(SalesOrderDetailsInfo.PhaseContract?.Factory.Name);
        setFactoryId(SalesOrderDetailsInfo.PhaseContract?.Factory.Id);

        setBarGrade(SalesOrderDetailsInfo.PhaseContract?.BarGradeId);
        setBarCoating(SalesOrderDetailsInfo.PhaseContract?.BarCoatingId);
        setBarStandard(SalesOrderDetailsInfo.PhaseContract?.BarStandardId);
        setBarCoatings(
          SalesOrderDetailsInfo.PhaseContract?.Factory.References?.length > 0
            ? SalesOrderDetailsInfo.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 3
            )[0]?.Values
            : []
        );
        setBarStandards(
          SalesOrderDetailsInfo.PhaseContract?.Factory.References?.length > 0
            ? SalesOrderDetailsInfo.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 1
            )[0]?.Values
            : []
        );
        setBarGrades(
          SalesOrderDetailsInfo.PhaseContract?.Factory.References?.length > 0
            ? SalesOrderDetailsInfo.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 2
            )[0]?.Values
            : []
        );
        setProducts(SalesOrderDetailsInfo.PhaseContract?.Factory?.ShapeProducts)
        setServicesTypes(SalesOrderDetailsInfo.PhaseContract?.Services)
        // console.log("catch",SalesOrderDetailsInfo.PhaseContract?.Factory?.ShapeProducts)
      }
    }
  }, [SalesOrderDetailsInfo]);
  useEffect(() => {
    if ((subProjectId || SALES_ORDER_ID) && !SalesOrderDetailsInfo) {
      setLoadingData(true);
      createOrEditSalesOrder(
        SALES_ORDER_ID,
        SALES_ORDER_ID ? null : subProjectId,
        null
      ).then((x) => {
        setOrderNb(x.Code);
        setTypeId(x.TypeId)
        setSubprojectName(x.SubProject);
        setPhaseName(x.Phase);
        setProjectName(x.Project);
        setProjectId(x.ProjectId);
        setSubProjectCreator(x.SubProjectCreator);
        if (!x.CustomerCompanyName) {
          if (localStorage.getItem("AccountType") === "2")
            setCustomerCompanyEmpty(true);
        } else {
          setCustomerCompanyEmpty(false);
          setCustomerCompanyName(x.CustomerCompanyName);
        }
        setCustomerCompanyName(x.CustomerCompanyName);
        if (x.SubContractors.length === 0) {
          if (localStorage.getItem("AccountType") === "1") {
            setSubContractorsEmpty(true);
          } else {
            setSubContractorsEmpty(false);
          }
        } else {
          setSubContractorsEmpty(false);
          setSubcontractorList(x.SubContractors);
        }
        setSubcontractorList(x.SubContractors);

        setFactoryNb(x.PhaseContract?.Factory.Name);
        setFactoryId(x.PhaseContract?.Factory.Id);

        setBarGrade(x.PhaseContract?.BarGradeId);
        setBarCoating(x.PhaseContract?.BarCoatingId);
        setBarStandard(x.PhaseContract?.BarStandardId);
        setBarCoatings(
          x.PhaseContract?.Factory.References?.length > 0
            ? x.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 3
            )[0]?.Values
            : []
        );
        setBarStandards(
          x.PhaseContract?.Factory.References?.length > 0
            ? x.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 1
            )[0]?.Values
            : []
        );
        setBarGrades(
          x.PhaseContract?.Factory.References?.length > 0
            ? x.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 2
            )[0]?.Values
            : []
        );
        setProducts(x.PhaseContract?.Factory?.ShapeProducts)
        setServicesTypes(x.PhaseContract?.Services)

        setMemberTypes(x.MemberTypes);
        setBarMarkTypes(x.BarMarTypes);
        // if (localStorage.getItem("AccountType") === "1") {
        //   setCustomerCompanyName(localStorage.getItem("companyName"));
        // }

        setSpliceTypes(x.SpliceTypes)
        if (SALES_ORDER_ID) {
          setProductCount(x.SalesOrderDetails.ProductCount);
          setThreadCount(x.SalesOrderDetails.ThreadCount)
          setParentId(x.SalesOrderDetails?.ParentId);
          setActivityCode(x.Activitycode);
          setACT_ID(x.SalesOrderDetails?.ActivityId);
          setSubProjectId(x.SalesOrderDetails.SubProjectId);
          setOrderNb(x.SalesOrderDetails.Code);
          setTypeId(x.SalesOrderDetails.TypeId);
          console.log("Or TypeId", x.SalesOrderDetails.TypeId)

          setSuggestedDate(x.SalesOrderDetails.SuggestedDate);
          setCommentForSuggestion(x.SalesOrderDetails.Comment);
          setCustomerOrderReference(x.SalesOrderDetails.CustomerOrderReference);
          setFiles(x.SalesOrderDetails.Files ? x.SalesOrderDetails.Files : []);
          let tempFileIds = [];
          x.SalesOrderDetails?.Files?.map((f) => {
            tempFileIds.push({ FileId: f.FileId });
          });
          setfileIds(tempFileIds);
          setDrawingNb(x.SalesOrderDetails.DrawingNumber);
          setDescription(x.SalesOrderDetails.Description);
          setDrawingRevisionNb(x.SalesOrderDetails.DrawingRevisionNumber);
          setStatus(x.SalesOrderDetails.StatusId);
          if (x.SalesOrderDetails.StatusId !== SALESORDERSCONST.DRAFT_ID) {
            setDisabledAllFields(true);
          }
          setSubcontractor(x.SalesOrderDetails.SubProjectCollaborativeTenantId);
          if (x.SalesOrderDetails.ProducedPercentage)
            setProductionProgress(x.SalesOrderDetails.ProducedPercentage + "%");
          setDeliveryDateStatus(x.SalesOrderDetails.DeliveryStatusId);
          setCreationDateAndTime(x.SalesOrderDetails.CreationTime);
          setSentBy(x.SalesOrderDetails.Creator);
          setPlannedDeliveryDate(x.SalesOrderDetails.DeliveryDate);
          setReceiptDate(x.SalesOrderDetails.ReceiptDate);
          setSalesOrderWeightTheoretical(x.SalesOrderDetails.TheoreticalWeight);
          setMembers(x.SalesOrderDetails.Members);
          setIsvalidator(x.SalesOrderDetails.IsValidator);
          setIsSuggestor(x.SalesOrderDetails.IsSuggester);
          setSubProjectCreator(x.SubProjectCreator);
          setIsCreator(x.SalesOrderDetails.IsCreator);
          setCustomerCompanyEmpty(false);
          setSubContractorsEmpty(false);
          setBarGrade(x.SalesOrderDetails.BarGradeId);
          setBarCoating(x.SalesOrderDetails.BarCoatingId);
          setBarStandard(x.SalesOrderDetails.BarStandardId);
          setServicesTypes(x.PhaseContract.Services)
          if (x.SubContractors.length === 0) {

            setFactoryMinDaysToDeliver(
              x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder
            );

            let minimumDate = moment(x.SalesOrderDetails.CreationTime).add(
              x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder,
              "days"
            );
            minimumDate = minimumDate.format("llll");
            setMinPlannedDate(minimumDate);
            // console.log(new Date(minimumDate).toDateString())
          } else {
            // console.log("subs exists");
            let subtmp = x.SubContractors.find(
              (s) =>
                s.Id === x.SalesOrderDetails.SubProjectCollaborativeTenantId
            );
            // console.log(x.SubContractors);
            // console.log(x.SalesOrderDetails.SubProjectCollaborativeTenantId);
            // console.log(subtmp);
            setFactoryMinDaysToDeliver(
              subtmp?.Factory?.MinimumDeliveryDaysPerSalesorder
            );

            let minimumDate = moment(x?.SalesOrderDetails?.CreationTime).add(
              subtmp?.Factory?.MinimumDeliveryDaysPerSalesorder,
              "days"
            );
            minimumDate = minimumDate.format("llll");
            setMinPlannedDate(minimumDate);
          }

        }

        if (SALES_ORDER_ID === null) {
          setFactoryMinDaysToDeliver(
            x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder
          );

          let minimumDate = moment(new Date()).add(
            x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder,
            "days"
          );
          minimumDate = minimumDate.format("llll");
          setPlannedDeliveryDate(minimumDate);
          setMinPlannedDate(minimumDate);
        }

        setLoadingData(false);
        if (calenderDateSwapped) {
          console.log("swapped")
          if (
            new Date(calenderDateSwapped).getTime() !==
            new Date(x.SalesOrderDetails.DeliveryDate).getTime() &&
            new Date(calenderDateSwapped).getTime() !==
            new Date(x.SalesOrderDetails.SuggestedDate).getTime() &&
            x.SalesOrderDetails.StatusId !== 1
          ) {
            console.log("open")
            setOpenModal(true);
            setSuggest(true);
          }
        }
      });
    }
  }, [Modify]);

  useEffect(() => {
    console.log("memberss", members)
  }, [members])
  const salesOrderData = {
    Id: SALES_ORDER_ID ? SALES_ORDER_ID : null,
    SubProjectId: subProjectId,
    ParentId: previousSALES_ORDER_ID ? previousSALES_ORDER_ID : null,
    ActivityId: ACT_ID,
    FactoryId: factoryId,
    SubProjectCollaborativeTenantId:
      localStorage.getItem("AccountType") === "2" ? null : subcontractor,
    Code: orderNb,
    CustomerOrderReference: customerOrderReference,
    DisciplineId: 3,
    TradeId: 1,
    StatusId: status,
    Files: fileIds,
    BarGrade: barGrade,
    BarCoating: barCoating,
    BarStandard: barStandard,
    PlannedDeliveryDate: plannedDeliveryDate,
    DeliveryStatusId: deliveryDateStatus,
    DrawingNumber: drawingNb,
    TypeId: typeId,
    IsTest: typeId === SALESORDERDCONSTS.TEST_SAMPLE_ID ? true:false,
    Description: description,
    DrawingRevisionNumber: drawingRevisionNb,
    SelecttionStatusId: 1,
    ReceiptDate: receiptDate,
    BarStandardId: barStandard,
    BarGradeId: barGrade,
    BarCoatingId: barCoating,

    Members: members,
    ProductCount: productCount?.filter((p) => p.Count > 0)
  };

  useEffect(() => {
    if (disabledFromActivity || disabledAllFields) {
    } else {
      if (selectedShape && memberIdSelected === 0) {
        let membersLines = members?.flatMap((x) => x.Lines);

        let nullableLine = membersLines?.find((x) => x.Shape === null);
        if (nullableLine) {
          // let memberBarMark = members.find((x) => x.Lines.includes(nullableLine));
          nullableLine.Shape = structuredClone(selectedShape);
          nullableLine.ShapeCode = selectedShape.subCode
            ? selectedShape.Code + selectedShape.subCode
            : selectedShape.Code;

          nullableLine.ShapeName = selectedShape.Name;
          nullableLine.Products = (selectedShape.Name?.toLowerCase() === 'bet + coupler' || selectedShape.Name?.toLowerCase() === 'double bet + coupler' || selectedShape.Name?.toLowerCase() === 'bet x2 + coupler') ? [{
            Id: members.length > 0
              ? members[memberIdSelected - 1].Lines.length + 1 + "pr1"
              : 1 + "pr1",
            SpliceTypeId: null,
            TypeId: null,
            SubCategoryId: null,
            TreatmentId: null,
            ProductId: null,
          }] :
            selectedShape.Name?.toLowerCase() === 'double bet + double coupler' ?
              [{
                Id: members.length > 0
                  ? members[memberIdSelected - 1].Lines.length + 1 + "pr1"
                  : 1 + "pr1",
                SpliceTypeId: null,
                TypeId: null,
                SubCategoryId: null,
                TreatmentId: null,
                ProductId: null,
              },
              {
                Id: members.length > 0
                  ? members[memberIdSelected - 1].Lines.length + 1 + "pr1"
                  : 1 + "pr2",
                SpliceTypeId: null,
                TypeId: null,
                SubCategoryId: null,
                TreatmentId: null,
                ProductId: null,
              }]
              :
              [];



          forceUpdate();

          setSelectedShape(null);
        } else {
          members[members?.length - 1]?.Lines.push({
            OrderId:
              members.length > 0
                ? members[members?.length - 1].Lines.length + 1
                : 1,
            BarMarkNo:
              members[members?.length - 1]?.BarMarkTypeId === 1
                ? members[members?.length - 1].Lines.length === 0
                  ? 1
                  : +members[members?.length - 1].Lines[
                    members[members?.length - 1].Lines.length - 1
                  ].BarMarkNo + 1
                : members[members?.length - 1]?.BarMarkTypeId === 2
                  ? members[members?.length - 1].Lines.length === 0
                    ? "A"
                    : incstr(
                      members[members?.length - 1].Lines[
                        members[members?.length - 1].Lines.length - 1
                      ].BarMarkNo,
                      "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                    )
                  : null,
            BarsQuantity: null,
            TotalNumberOfBars: null,
            BarDiameter: null,
            ShapeCode: selectedShape.subCode
              ? selectedShape.Code + selectedShape.subCode
              : selectedShape.Code,
            ShapeName: selectedShape.Name,
            FormerDiameter: 1,
            SecondDiameter: null,
            FileId: null,
            ActualLength: 0,
            TheoreticalLength: null,
            ActualWeight: 0,
            TheoreticalWeight: null,
            WeightPerMeter: 1,
            Products: (selectedShape.Name?.toLowerCase() === 'bet + coupler' || selectedShape.Name?.toLowerCase() === 'double bet + coupler' || selectedShape.Name?.toLowerCase() === 'bet x2 + coupler') ? [{
              Id: null,
              SpliceTypeId: null,
              TypeId: null,
              SubCategoryId: null,
              TreatmentId: null,
              ProductId: null,
            }] :
              selectedShape.Name?.toLowerCase() === 'double bet + double coupler' ?
                [{
                  Id: null,
                  SpliceTypeId: null,
                  TypeId: null,
                  SubCategoryId: null,
                  TreatmentId: null,
                  ProductId: null,
                },
                {
                  Id: null,
                  SpliceTypeId: null,
                  TypeId: null,
                  SubCategoryId: null,
                  TreatmentId: null,
                  ProductId: null,
                }]
                :
                [],
            Shape: structuredClone(selectedShape),
            ShapeBlob: null,
            TypeId: null,
            // ProductSubCategory: null,
            // ProductTreatment: null,
            GradeDiameter: null,
            SubCategoryId: null,
            TreatmentId: null,
            GradeId: null,
            ProductId: null,
            GradeDiameterId: null,
            Threads: (selectedShape.Name.toLowerCase() === 'bet' || selectedShape.Name?.toLowerCase() === 'double bet + coupler' ) ? new Array(1).fill(-1)
              : selectedShape.Name.toLowerCase() === 'double bet' ? new Array(2).fill(-1) : null,
            ProvidedLength: null,
            ProducedQuantity: null,
          });
          forceUpdate();
          setSelectedShape(null);
        }
      } else if (
        selectedShape &&
        memberIdSelected !== 0 &&
        memberIdSelected !== null
      ) {
        members[memberIdSelected - 1]?.Lines.push({
          OrderId:
            members.length > 0
              ? members[memberIdSelected - 1].Lines.length + 1
              : 1,
          BarMarkNo:
            members[memberIdSelected - 1]?.BarMarkTypeId === 1
              ? members[memberIdSelected - 1].Lines.length === 0
                ? 1
                : +members[memberIdSelected - 1].Lines[
                  members[memberIdSelected - 1].Lines.length - 1
                ].BarMarkNo + 1
              : members[memberIdSelected - 1]?.BarMarkTypeId === 2
                ? members[memberIdSelected - 1].Lines.length === 0
                  ? "A"
                  : incstr(
                    members[memberIdSelected - 1].Lines[
                      members[memberIdSelected - 1].Lines.length - 1
                    ].BarMarkNo,
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                  )
                : null,
          BarsQuantity: null,
          TotalNumberOfBars: null,
          BarDiameter: null,
          ShapeCode: selectedShape.subCode
            ? selectedShape.Code + selectedShape.subCode
            : selectedShape.Code,
          ShapeName: selectedShape.Name,
          FormerDiameter: null,
          SecondDiameter: null,
          ActualLength: 0,
          TheoreticalLength: null,
          ActualWeight: 0,
          TheoreticalWeight: null,
          WeightPerMeter: null,
          Products: [],
          Shape: structuredClone(selectedShape),
          ShapeBlob: null,
          FileId: null,
          TypeId: null,
          SubCategoryId: null,
          TreatmentId: null,
          GradeId: null,
          ProductId: null,
          GradeDiameterId: null,
          Threads: (selectedShape.Name.toLowerCase() === 'bet' || selectedShape.Name?.toLowerCase() === 'double bet + coupler') ? new Array(1).fill(-1)
            : selectedShape.Name.toLowerCase() === 'double bet' ? new Array(2).fill(-1) : null,
          ProvidedLength: null,
          ProducedQuantity: null,

        });
        forceUpdate();
        setSelectedShape(null);
      }
    }
  }, [selectedShape, memberIdSelected]);
  useEffect(() => {
    setFactoryDetailsEmpty(false);
    if (subcontractor && subcontractorsList.length > 0) {
      const subcontractorChosen = subcontractorsList.filter(
        (x) => x.Id === subcontractor
      );
      setUnitOfMeasures(
        subcontractorChosen[0]?.MeasurementSystemId ===
          SALESORDERSCONST.METRIC_MEASURES
          ? "mm--kg"
          : "inches--pounds"
      );
      setBarCoatingError(false);
      setBarStandardError(false);
      setBarGradeError(false);
      if (subcontractorChosen[0]?.FactoryName === null)
        setFactoryDetailsEmpty(true);
      else setFactoryDetailsEmpty(false);
      setFactoryNb(subcontractorChosen[0]?.FactoryName);
      setFactoryId(subcontractorChosen[0]?.Factory?.Id);
      setFactoryMinDaysToDeliver(
        subcontractorChosen[0]?.Factory?.MinimumDeliveryDaysPerSalesorder
      );
      let minimumDate = moment(new Date()).add(
        subcontractorChosen[0]?.Factory?.MinimumDeliveryDaysPerSalesorder,
        "days"
      );
      minimumDate = minimumDate.format("llll");
      console.log(minimumDate);
      setMinPlannedDate(minimumDate);
      setPlannedDeliveryDate(minimumDate);

      setBarCoatings(
        subcontractorChosen[0]?.Factory?.References?.length > 0
          ? subcontractorChosen[0]?.Factory?.References?.filter(
            (x) => x.ReferenceId === 3
          )[0]?.Values
          : []
      );
      setBarStandards(
        subcontractorChosen[0]?.Factory?.References?.length > 0
          ? subcontractorChosen[0]?.Factory?.References?.filter(
            (x) => x.ReferenceId === 1
          )[0]?.Values
          : []
      );
      setBarGrades(
        subcontractorChosen[0]?.Factory?.References?.length > 0
          ? subcontractorChosen[0]?.Factory?.References?.filter(
            (x) => x.ReferenceId === 2
          )[0]?.Values
          : []
      );
      setProducts(subcontractorChosen[0]?.Factory?.ShapeProducts)

      if (!SALES_ORDER_ID) {
        setBarStandard(subcontractorChosen[0]?.BarStandardId);
        setBarCoating(subcontractorChosen[0]?.BarCoatingId);
        setBarGrade(subcontractorChosen[0]?.BarGradeId);
        setProducts(subcontractorChosen[0]?.Factory?.ShapeProducts)

      }
    }
  }, [subcontractor]);
  const saveSalesOrder = async () => {
    if (SALES_ORDER_ID) {
      await updateSalesOrder(salesOrderData).then((x) => {
        setDrafting(false);
        setPlacing(false);
        setModify(!Modify);
        if (x.status === 409) {
          setExceedWeight(true);
        } else if (x.status === 500) {
          setExceedWeight(true);
          setmisingFMError(true);
        }
      });
    } else {
      await createSalesOrder(salesOrderData).then((x) => {
        setDrafting(false);
        setPlacing(false);
        if (x.status === 409) {
          setExceedWeight(true);
        } else if (x.status === 500) {
          setExceedWeight(true);
          setmisingFMError(true);
        } else if (x.statusCode === 500) {
          setExceedWeight(true);
          setmisingFMError(true);
        } else {
          navigate(`/saas/salesOrderListCard?id=${projectId}`);
        }
      });
    }
  };
  const CheckAllMembersFields = () => {

    members?.map((mem, index) => {
      if (mem.ServiceId === SALESORDERSCONST.CUT_SERVICE_ID) {
        mem.Lines?.map((line) => {
          setHideInitialTotal(true);
          line.ProducedQuantity = line.ProducedQuantity * mem.Quantity;
          forceUpdate();
          console.log("mems", members)

        })
      }
    })

    let stopArray = false;
    members.map((mem, index) => {
      if (
        mem.Quantity === null ||
        !mem.Name ||
        !mem.TypeId ||
        // mem.Lines.length === 0 ||
        !mem.ServiceId
      ) {
        setErrorEmpty(true);
        setErrorEmptyMessage("There is empty required fields in the table. Please check member " + (index + 1))
        stopArray = true;
      } else if (mem.Lines.length === 0) {
        setErrorEmpty(true);
        setErrorEmptyMessage("There is no added lines in member " + (index + 1))
        stopArray = true;
      }
      else {
        console.log("mem.lines", mem.Lines)
        mem.Lines.map((line, lineIndex) => {
          if (mem.ServiceId === SALESORDERSCONST.CUT_AND_BEND_SERVICE_ID) {
            if (
              line.BarsQuantity === null ||
              !line.BarDiameter ||
              !line.ShapeCode

            ) {
              setErrorEmpty(true);
              setErrorEmptyMessage("There is empty fields required in the table. Please check line " + (lineIndex + 1) + " in member " + (index + 1))
              stopArray = true;
            } else {
              if (!stopArray) {
                setErrorEmpty(false);
              }
            }
          }

          if (mem.ServiceId === SALESORDERSCONST.CUT_SERVICE_ID) {
            if (
              !line.ShapeName ||
              !line.BarDiameter ||
              !line.ProvidedLength ||
              !line.ActualLength ||
              line.BarsQuantity === null ||
              !line.ProducedQuantity
            ) {
              setErrorEmpty(true);
              setErrorEmptyMessage("There is empty required fields in the table. Please check line " + (lineIndex + 1) + " in member " + (index + 1))
              stopArray = true;
            } else {
              if (!stopArray) {
                setErrorEmpty(false);
              }
            }
          }
          if (mem.ServiceId === SALESORDERSCONST.THREAD_SERVICE_ID) {
            if (
              !line.ShapeName ||
              (line.ShapeName?.toLowerCase() === 'couplers and accessories' && (!line.TypeId || !line.ProductId)) ||
              (line.ShapeName?.toLowerCase() === 'bet' && line.Threads[0] === -1) ||
              (line.ShapeName?.toLowerCase() === 'double bet' && (line.Threads[0] === -1 || line.Threads[1] === -1)) ||

              ((line.ShapeName?.toLowerCase() === 'bet + coupler'
                || line.ShapeName?.toLowerCase() === 'double bet + coupler'
                || line.ShapeName?.toLowerCase() === 'bet x2 + coupler'
                || line.ShapeName?.toLowerCase() === 'double bet + double coupler')
                && (line.Products.some(product =>
                  !product.Id || !product.SpliceTypeId || !product.TypeId
                ))
              )
              ||
              !line.BarDiameter
              ||
              (!line.ActualLength && line.ShapeName?.toLowerCase() !== 'couplers and accessories') ||
              line.BarsQuantity === null
              ||
              (line.ShapeName?.toLowerCase() === 'couplers and accessories' &&
                factoryProductReferences?.find((p) => p.Id === parseInt(line.TypeId))?.Grades?.find((g) => g.Id === line.ProductId)?.Name?.toLowerCase().includes("transition")
                && !line.SecondDiameter
              )
              ||
              (
                (line.ShapeName?.toLowerCase() === 'bet + coupler' || line.ShapeName?.toLowerCase() === 'bet x2 + coupler' || line.ShapeName?.toLowerCase() === 'double bet + coupler')
                &&
                factoryProductReferences?.find((p) => p.Id === parseInt(line.Products[0]?.TypeId))?.Grades?.find((g) => g.Id === line.Products[0]?.Id)?.Name?.toLowerCase().includes("transition")
                && !line.SecondDiameter

              ) ||
              (
                (line.ShapeName?.toLowerCase() === 'double bet + double coupler')
                &&
                (
                  (
                    factoryProductReferences?.find((p) => p.Id === parseInt(line.Products[0]?.TypeId))?.Grades?.find((g) => g.Id === line.Products[0]?.Id)?.Name?.toLowerCase().includes("transition")
                  )
                  ||
                  (
                    factoryProductReferences?.find((p) => p.Id === parseInt(line.Products[1]?.TypeId))?.Grades?.find((g) => g.Id === line.Products[1]?.Id)?.Name?.toLowerCase().includes("transition")
                  )

                )
                && !line.SecondDiameter
              )

            ) {
              setErrorEmpty(true);
              setErrorEmptyMessage("There is empty required fields in the table. Please check line " + (lineIndex + 1) + " in member " + (index + 1))
              stopArray = true;
            } else {
              if (!stopArray) {
                setErrorEmpty(false);
              }
            }
          }

        });
      }
    });
    if (!stopArray) {
      setPlacing(true);
      salesOrderData.StatusId = SALESORDERSCONST.PLACED_ID;
      saveSalesOrder();

      setErrorEmpty(false);
      setErrorEmptyMessage(null);
    }
  };
  // from activity
  useEffect(() => {
    if (chosenFileID && ACT_ID) {
      setLoadingData(true);
      importSdi(ACT_ID, null, chosenFileID, catalogId).then((x) => {
        setSalesOrderDetailsInfo(x);
        setLoadingData(false);
      });
    }
  }, [chosenFileID]);

  const importingSdi = (sdifileId, selectedCatalog) => {
    setImportingSdiLoading(true);
    importSdi(null, subProjectId, sdifileId, selectedCatalog).then((x) => {
      setSalesOrderDetailsInfo(x);
      setLoadingData(false);
      setImportingSdiLoading(false);

    });
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      {exceedWeight ? (
        <Grid
          item
          className={classes["ExceedWeight"]}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={7} paddingTop={3} paddingBottom={2}>
            {misingFMError
              ? "Please set up your factory master parameters before creation"
              : ACT_ID
                ? "Cannot create a sales order! Sales order weight exceeds the predefined maximum limit in the master parameters,  select some members or reduce the member quantity"
                : "Cannot create a sales order! Sales order weight exceeds the predefined maximum limit in the master parameters, reduce the member quantity or remove some members"}
          </Grid>
          <Grid
            item
            xs={1}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              setExceedWeight(false);
            }}
          >
            <CloseIcon fontSize="small" htmlColor={"#3a3a3a"} />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        paddingTop={previewPrinting ? 0 : 3}
      >
        {previewPrinting ? (
          <PreviewPrinting
            setPreviewPrinting={setPreviewPrinting}
            SALES_ORDER_ID={SALES_ORDER_ID}
            setShowSaaSNavbar={props.setShowSaaSNavbar}
          />
        ) : (
          <>
            <Backdrop
              sx={{
                color: "#fff",
                backgroundColor: "transparent",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: "flex",
                flexDirection: "column",
                // position:"relative"
              }}
              open={loadingData}
            >
              <CircularProgress />

              <Grid
                item
                fontFamily={"Muli"}
                fontSize={15}
                fontWeight={"bold"}
                color={"rgb(25, 118, 210)"}
              >
                Loading Data...
              </Grid>
            </Backdrop>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Grid
                item
                lg={0.8}
                sm={2}
                fontSize={{ xs: "13px", sm: "18px" }}
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Muli",
                  color: "#101650",
                }}
              >
                Sales Order
              </Grid>
              <Grid
                item
                lg={8.8}
                sm={5.8}
                xs={3}
                sx={{
                  border: "solid 0.1px #fff",
                  backgroundColor: "#fff",
                  height: 0,
                }}
              ></Grid>
              <Grid
                item
                lg={1}
                sm={2}
                fontSize={{ xs: "9px", sm: "12px" }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.fontWeight = "bold";
                }}
                onMouseOut={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
                onClick={() => {
                  localStorage.removeItem("ProjectName");
                  navigate("/saas/salesOrderList");
                }}
              >
                View all Sales Orders
              </Grid>
              <Grid item xs={2.5} sm={1.3} lg={0.6}>
                <button
                  className={classes["back-button"]}
                  onClick={() => {
                    if (finishUpdate)
                      navigate('/saas/salesOrdersCalender')
                    else
                      navigate(-1);
                  }}
                >
                  Go Back
                </button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={11.4}
              spacing={1}
              marginBottom={4}
              justifyContent="center"
            >
              <Grid item xs={11.6} sm={8.5} lg={9} backgroundColor="#fff">
                <SalesOrderDetails
                  catalogs={catalogs}
                  importingSdi={importingSdi}
                  importingSdiLoading={importingSdiLoading}
                  disabledEdit={disabledEdit}
                  customerCompanyNameEmpty={customerCompanyNameEmpty}
                  subContractorsEmpty={subContractorsEmpty}
                  ACT_ID={ACT_ID}
                  orderNb={orderNb}
                  projectName={projectName}
                  phaseName={phaseName}
                  subprojectName={subprojectName}
                  customerCompanyName={customerCompanyName}
                  customerCompanyNamesList={customerCompanyNamesList}
                  setCustomerCompanyNamesList={setCustomerCompanyNamesList}
                  customerOrderReference={customerOrderReference}
                  setCustomerOrderReference={setCustomerOrderReference}
                  customerOrderReferenceError={customerOrderReferenceError}
                  setCustomerOrderReferenceError={
                    setCustomerOrderReferenceError
                  }
                  activityCode={activityCode}
                  drawingNb={drawingNb}
                  setDrawingNb={setDrawingNb}
                  drawingNbError={drawingNbError}
                  description={description}
                  setDescription={setDescription}
                  descriptionError={descriptionError}
                  setDescriptionError={setDescriptionError}
                  setDrawingNbError={setDrawingNbError}
                  file={file}
                  setFile={setFile}
                  files={files}
                  setFiles={setFiles}
                  fileId={fileId}
                  setFileId={setFileId}
                  fileIds={fileIds}
                  drawingRevisionNb={drawingRevisionNb}
                  setDrawingRevisionNb={setDrawingRevisionNb}
                  drawingRevisionNbError={drawingRevisionNbError}
                  setDrawingRevisionNbError={setDrawingRevisionNbError}
                  status={status}
                  sentBy={sentBy}
                  creationDateAndTime={creationDateAndTime}
                  subcontractor={subcontractor}
                  setSubcontractor={setSubcontractor}
                  subcontractorError={subcontractorError}
                  setSubcontractorError={setSubcontractorError}
                  factoryNb={factoryNb}
                  unitOfMeasures={unitOfMeasures}
                  receiptDate={receiptDate}
                  plannedDeliveryDate={plannedDeliveryDate}
                  setPlannedDeliveryDate={setPlannedDeliveryDate}
                  deliveryDateStatus={deliveryDateStatus}
                  subcontractorsList={subcontractorsList}
                  setSubcontractorList={setSubcontractorList}
                  salesOrderWeightTheoretical={salesOrderWeightTheoretical}
                  projectId={projectId}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  suggest={suggest}
                  setSuggest={setSuggest}
                  validate={validate}
                  setValidate={setValidate}
                  suggestedDate={suggestedDate}
                  setSuggestedDate={setSuggestedDate}
                  commentForSuggestion={commentForSuggestion}
                  setCommentForSuggestion={setCommentForSuggestion}
                  disabledFromActivity={disabledFromActivity}
                  disabledAllFields={disabledAllFields}
                  isvalidator={isvalidator}
                  isSuggestor={isSuggester}
                  isCreator={isCreator}
                  calenderDateSwapped={calenderDateSwapped}
                  setPreviewPrinting={setPreviewPrinting}
                  setShowSaaSNavbar={props.setShowSaaSNavbar}
                  SALES_ORDER_ID={SALES_ORDER_ID}
                  modify={Modify}
                  setModify={setModify}
                  subProjectId={subProjectId}
                  subProjectCreator={subProjectCreator}
                  barStandard={barStandard}
                  setBarStandard={setBarStandard}
                  barStandardError={barStandardError}
                  setBarStandardError={setBarStandardError}
                  barGrade={barGrade}
                  setBarGrade={setBarGrade}
                  barGradeError={barGradeError}
                  setBarGradeError={setBarGradeError}
                  barCoating={barCoating}
                  setBarCoating={setBarCoating}
                  barCoatingError={barCoatingError}
                  setBarCoatingError={setBarCoatingError}
                  barStandards={barStandards}
                  barGrades={barGrades}
                  barCoatings={barCoatings}
                  factoryMinDaysToDeliver={factoryMinDaysToDeliver}
                  minPlannedDate={minPlannedDate}
                  productionProgress={productionProgress}
                  factoryDetailsEmpty={factoryDetailsEmpty}
                  members={members}
                  productCount={productCount}
                  setProductCount={setProductCount}
                  threadCount={threadCount}
                  setThreadCount={setThreadCount}
                  typeId={typeId}
                  setTypeId={setTypeId}
                  typeIdError={typeIdError}
                  setTypeIdError={setTypeIdError}
                />

                <Grid
                  item
                  fontFamily={"Muli"}
                  fontSize={17}
                  fontWeight={"bold"}
                  color={"#ea001e"}
                >
                  {SalesOrderDetailsInfo
                    ? "**Please sync all shapes before saving or placing sales order"
                    : ""}
                </Grid>
                <SalesOrderMemberTable
                  SALES_ORDER_ID={SALES_ORDER_ID}
                  products={products}
                  members={members}
                  servicesTypes={servicesTypes}
                  setMembers={setMembers}
                  shapes={shapes}
                  memberIdSelected={memberIdSelected}
                  setMemberIdSelected={setMemberIdSelected}
                  memberTypes={memberTypes}
                  barMarkTypes={barMarkTypes}
                  projectId={projectId}
                  disabledFromActivity={disabledFromActivity}
                  disabledAllFields={disabledAllFields}
                  activityCode={activityCode}
                  status={status}
                  setSalesOrderWeightTheoretical={
                    setSalesOrderWeightTheoretical
                  }
                  factoryId={factoryId}
                  factoryProductReferences={factoryProductReferences}
                  setCanSaveAsDraft={setCanSaveAsDraft}
                  weightPeremeters={weightPeremeters}
                  productCount={productCount}
                  setProductCount={setProductCount}
                  threadCount={threadCount}
                  setThreadCount={setThreadCount}
                  spliceTypes={spliceTypes}
                  hideInitialTotal={hideInitialTotal}
                />

                {status === SALESORDERSCONST.DRAFT_ID ? (
                  <Grid
                    item
                    container
                    xs={12}
                    direction={"row"}
                    paddingLeft={2.5}
                    paddingBottom={2.5}
                  >
                    <Grid item container direction={"row"} xs={6} gap={2}>
                      <button
                        className={classes["CancelBut"]}
                        onClick={() => {
                          navigate("/saas/sales");
                        }}
                      >
                        Cancel
                      </button>
                      {canSaveAsDraft ?
                        <button
                          disabled={
                            isDisabled || (disabledPlace && SALES_ORDER_ID) || drafting
                          }
                          className={classes["Place-Order"]}
                          onClick={() => {
                            console.log("members", members)
                            setDrafting(true);
                            saveSalesOrder();
                          }}
                        >
                          {drafting ? "Saving.." : "Save As Draft"}
                        </button>
                        :
                        <></>

                      }

                    </Grid>
                    <Grid
                      item
                      xs={6}
                      direction={"row-reverse"}
                      container
                      paddingRight={2.5}
                      gap={2}
                    >
                      <button
                        disabled={
                          isDisabled || (disabledPlace && SALES_ORDER_ID) || placing
                        }
                        className={classes["Place-Order"]}
                        onClick={() => {
                          console.log("membersss", members)
                          CheckAllMembersFields();
                        }}
                      >
                        {placing ? "Placing..." : "Place Order"}
                      </button>

                    </Grid>

                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    direction={"row-reverse"}
                    paddingRight={2.5}
                    paddingBottom={2.5}
                    gap={2}
                  >
                    {parentId === null && ACT_ID ? (
                      <button
                        disabled={isDisabled}
                        className={classes["Place-Order"]}
                        onClick={() => {
                          setPlacing(true);
                          setLoadingData(true);
                          let memToChange = members.map((m1) => {
                            if (m1.RemainingQuantity > 0 && m1.Quantity) {
                              return { Id: m1.Id, Quantity: m1.Quantity };
                            }
                          });
                          memToChange = memToChange.filter((x) => x != null);
                          const parent = {
                            Id: SALES_ORDER_ID,
                            Members: memToChange,
                          };

                          createOrEditSalesOrder(null, null, null, parent).then(
                            (x) => {
                              setPreviousSALES_ORDER_ID(SALES_ORDER_ID);
                              setSALES_ORDER_ID(null);
                              setPlacing(false);
                              setLoadingData(false);
                              setSubprojectName(x.SubProject);
                              setPhaseName(x.Phase);
                              setProjectName(x.Project);
                              setProjectId(x.ProjectId);
                              setCustomerCompanyName(x.CustomerCompanyName);
                              setSubcontractorList(x.SubContractors);
                              setMemberTypes(x.MemberTypes);
                              setBarMarkTypes(x.BarMarTypes);
                              setSubProjectId(x.SalesOrderDetails.SubProjectId);
                              setOrderNb(x.SalesOrderDetails.Code);
                              setTypeId(x.SalesOrderDetails.TypeId);
                              console.log("TypeId", x.SalesOrderDetails.TypeId)
                              setSuggestedDate(null);
                              setCustomerOrderReference(" ");
                              setFiles(null);
                              setfileIds(null);
                              setDrawingNb(x.DrawingNumber);
                              setDescription(x.Description)
                              setDrawingRevisionNb(x.DrawingRevisionNumber);
                              setStatus(1);
                              setSubcontractor(0);
                              setDeliveryDateStatus(1);
                              setCreationDateAndTime(null);
                              setSentBy(null);
                              setPlannedDeliveryDate(null);
                              setReceiptDate(null);
                              setSalesOrderWeightTheoretical(null);
                              setMembers(x.SalesOrderDetails.Members);
                              setDisabledFromActivity(true);
                              setDisabledAllFields(false);
                            }
                          );
                        }}
                      >
                        {placing ? "Placing..." : "Place Selected"}
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      className={classes["Place-Order"]}
                      disabled={exporting}
                      onClick={() => {
                        setExporting(true);
                        exportSdi(SALES_ORDER_ID).then((x) => {
                          setExporting(false);
                        });
                      }}
                    >
                      {exporting ? "Exporting..." : "Export SDI"}
                    </button>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  justifyContent='flex-end'
                  fontFamily={"Muli"}
                  fontSize={17}
                  fontWeight={"bold"}
                  color={"#ea001e"}
                  paddingRight={2.5}
                >
                  {errorEmpty ? errorEmptyMessage
                    : ""
                  }
                </Grid>
              </Grid>
              <Grid item container direction="column" sm={3.5} lg={3}>
                <Grid item height={1000} overflow={"auto"}>
                  <SubprojectsList
                    subProjectId={subProjectId}
                    setSubProjectId={setSubProjectId}
                    projectId={projectId}
                    setProjectId={setProjectId}
                    SALES_ORDER_ID={SALES_ORDER_ID}
                    modify={Modify}
                    setModify={setModify}
                    SalesOrderDetailsInfo={SalesOrderDetailsInfo}
                    salesOrderNb={salesOrderNb}
                    setCustomerCompanyEmpty={setCustomerCompanyEmpty}
                    setSubContractorsEmpty={setSubContractorsEmpty}
                    setSubcontractorError={setSubcontractorError}
                    setCustomerCompanyName={setCustomerCompanyName}
                    setCustomerOrderReference={setCustomerOrderReference}
                    setDrawingNb={setDrawingNb}
                    setDrawingRevisionNb={setDrawingRevisionNb}
                    setSubcontractor={setSubcontractor}
                    setFactoryId={setFactoryId}
                    setFactoryNb={setFactoryNb}
                    setCustomerOrderReferenceError={
                      setCustomerOrderReferenceError
                    }
                    setDrawingNbError={setDrawingNbError}
                    setBarStandardError={setBarStandardError}
                    setBarCoatingError={setBarCoatingError}
                    setBarGradeError={setBarGradeError}
                  />
                </Grid>
                <Grid item height={status === SALESORDERSCONST.DRAFT_ID || !SALES_ORDER_ID ? 600 : 300} overflow={"auto"} marginTop={5}>
                  {subProjectId || SALES_ORDER_ID ?
                    <ShapeLibraryList
                      shapes={shapes}
                      catalogs={catalogs}
                      setCatalogs={setCatalogs}
                      setShapes={setShapes}
                      // selectedShape={selectedShape}
                      setSelectedShape={setSelectedShape}
                      servicesTypes={servicesTypes}
                      subProjectId={subProjectId}
                    />
                    :
                    <></>
                  }

                </Grid>

                <Grid item height={150} width='100%'>

                  {members?.length > 0 && members?.some((mem) => mem.Lines?.length > 0) ?
                    <Grid item container direction="column" margin={3}>
                      <Grid item style={styles.title} width="130px">
                        Threads Count
                      </Grid>

                      <Grid item marginTop={4} style={{ width: "100%", overflowX: "auto" }}>
                        <TableContainer
                          sx={{
                            width: "max-content",
                            maxHeight: "350px", // Adjust as needed for scrolling
                            overflowY: "auto", // Enable vertical scroll
                          }}
                        >
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                              borderCollapse: "separate",
                              borderSpacing: "0px 0px",
                              border: 0,
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                {/* Sticky header for Product Series and Count */}
                                <TableCell sx={{
                                  ...styles.tableCellhead,
                                  width: 70,
                                }}>Count</TableCell>
                                <TableCell sx={{
                                  ...styles.tableCellhead2,
                                  width: 120,
                                }} >{threadCount}</TableCell>
                              </TableRow>
                            </TableHead>

                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    :
                    <></>
                  }

                </Grid>

                <Grid item height={300} width='100%'>
                  {productCount?.some(i => i.Count > 0) ? (
                    <Grid item container direction="column" margin={3}>
                      <Grid item style={styles.title} width="180px">
                        Product Series Quantity
                      </Grid>

                      <Grid item marginTop={4} style={{ width: "100%", overflowX: "auto" }}>
                        <TableContainer
                          sx={{
                            width: "max-content",
                            maxHeight: "350px", // Adjust as needed for scrolling
                            overflowY: "auto", // Enable vertical scroll
                          }}
                        >
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                              borderCollapse: "separate",
                              borderSpacing: "0px 0px",
                              border: 0,
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                {/* Sticky header for Product Series and Count */}
                                <TableCell sx={{
                                  ...styles.tableCellhead,
                                  width: 180,
                                }}>Product Series</TableCell>
                                <TableCell sx={{
                                  ...styles.tableCellhead,
                                  width: 70,
                                }} >Count</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {productCount
                                ?.filter((c) => c.Count > 0) // Filter to include only rows with Count > 0
                                ?.map((i) => (
                                  <TableRow key={i.GradeId}>
                                    {/* Product Series (GradeId) */}
                                    <TableCell sx={{
                                      ...styles.tableCellhead2,
                                      width: 180,
                                    }}>{i.GradeId}</TableCell>
                                    {/* Count value for each GradeId */}
                                    <TableCell sx={{
                                      ...styles.tableCellhead2,
                                      width: 70,
                                    }}>{i.Count}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  ) : (
                    <></> // Render nothing if there are no counts greater than 0
                  )}

                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <DatesModal
        finishUpdate={finishUpdate}
        setFinishUpdate={setFinishUpdate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        suggest={suggest}
        setSuggest={setSuggest}
        validate={validate}
        setValidate={setValidate}
        suggestedDate={suggestedDate}
        setSuggestedDate={setSuggestedDate}
        commentForSuggestion={commentForSuggestion}
        setCommentForSuggestion={setCommentForSuggestion}
        modify={Modify}
        setModify={setModify}
        calenderDateSwapped={calenderDateSwapped}
        SALES_ORDER_ID={SALES_ORDER_ID}
        plannedDeliveryDate={plannedDeliveryDate}
        minPlannedDate={minPlannedDate}
      />
    </div>
  );
}

const styles = {

  title: {
    fontFamily: "Muli",
    color: " #101650",
    fontWeight: "bold",
    borderBottom: "solid 1px #e1e319",
  },


  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 400,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
  },

  tableCellhead2: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
  },
  tfInput: {
    color: "#707070",
    height: "28px",
    fontSize: 12,
    fontFamily: "Muli",
  },


};
