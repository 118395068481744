import React, { useState, useEffect } from "react";
import { Grid, Modal, Box, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import classes from "../../../Admin/UserRoles/modal.module.css";
import add from "../../../../Assets/Images/Subprojects/add.png";
import remove from "../../../../Assets/Images/Subprojects/remove.png";
import check from "../../../../Assets/Images/Subprojects/check.png";
import activeCheck from "../../../../Assets/Images/Subprojects/activeCheck.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "45%",
  height: "75%",
  overflow: "scroll",
};


const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color: #101650;
        background-color: lightgrey;
        font-size: 10px;
        width:100px;
        marginLeft:'50%';
     
    `);

const NewTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
            color: #101650;
            background-color: lightgrey;
            font-size: 10px;
            width:100px;
            font-family: Muli;
            line-height: 1.56,
        `);


const DisciplineModal = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const handleClose = () => {
    setOpen(false);
  };

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [disciplineName, setDisciplineName] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [disciplineAbbreviation, setDisciplineAbbreviation] = useState("");
  const [tradeAbbreviation, setTradeAbbreviation] = useState("");

  const [newDActions, setNewDActions] = useState([]);
  const [newTActions, setNewTActions] = useState([]);

  const [addDisc, setAddDisc] = useState(false);
  const [addTrade, setAddTrade] = useState(false);

  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [clicked, setClicked] = useState(false);

  const concatDT = () => {
    props.setDisciplines(props.disciplines?.concat(newDActions));
    props.setTrades(props.trades?.concat(newTActions));
    setNewDActions([]);
    setNewTActions([]);
  };

  const pushDisc = () => {
    let obj = {
      Id: null,
      Name: disciplineName,
      Abbreviation: disciplineAbbreviation,
      IsSelected: false,
    };
    let arr = newDActions;
    arr.push(obj);
    setNewDActions(arr);
    setDisciplineName("");
    setDisciplineAbbreviation("");
    obj = null;
    arr = null;
  };

  const pushTrade = () => {
    let obj = {
      Id: null,
      Name: tradeName,
      Abbreviation: tradeAbbreviation,
      IsSelected: false,
    };
    let arr = newTActions;
    arr.push(obj);
    setNewTActions(arr);
    setTradeName("");
    setTradeAbbreviation("");
    obj = null;
    arr = null;
  };

  const deleteDiscipline = (item) => {
    const index = newDActions.indexOf(item);
    if (index > -1) {
      newDActions.splice(index, 1);
    }
  };

  const deleteTrade = (item) => {
    const index = newTActions.indexOf(item);
    if (index > -1) {
      newTActions.splice(index, 1);
    }
  };

  return (
    <div>
      <Modal
        disableScrollLock
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            container
            direction={"row-reverse"}
            paddingRight={{ xs: "26.5%", md: "27%" }}
            paddingTop={{ xs: "12%", md: "3.5%" }}
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Box sx={style}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding="16.7px 28px 25.1px 57px"
            >
              <Grid
                item
                className={classes["Permission-title"]}
                fontSize={20}
                paddingTop={1}
                paddingBottom={2}
              >
                Identify Discipline and Trade
              </Grid>
              <Grid
                item
                sx={{
                  height: 0.1,
                  width: "100%",
                  backgroundColor: "#f2f2f2",
                  border: "solid 1px #f2f2f2",
                }}
              ></Grid>
              <Grid item container direction={"row"} paddingTop={3}>
                <Grid item container md={4} alignContent={"flex-start"}>
                  <Grid
                    item
                    container
                    paddingBottom={1}
                    xs={12}
                    alignItems="center"
                    paddingLeft={1}
                  >
                    <Grid item xs={8} className={classes["Action-title"]}>
                      *Subproject Discipline
                    </Grid>
                    {props.subProjectParentId ||
                      localStorage.getItem("viewSubPro") === "true" ||
                      (localStorage.getItem("AccountType") === "2" &&
                        (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro" ||
                          (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")))
                      ? (
                        <></>
                      ) : (
                        <NewTip title={"Add Discipline"} placement="top">
                          <Grid
                            item
                            onMouseOver={(e) =>
                              (e.target.style.cursor = "pointer")
                            }
                            onClick={() => setAddDisc(true)}
                          >
                            <img src={add} alt="add" width="110%" />
                          </Grid>
                        </NewTip>
                      )}
                    {addDisc ? (
                      <NewTip title={"cancel"} placement="top">
                        <Grid
                          item
                          padding="0 0 1% 1%"
                          onMouseOver={(e) =>
                            (e.target.style.cursor = "pointer")
                          }
                          onClick={() => setAddDisc(false)}
                        >
                          <img src={remove} alt="cancel" width="110%" />
                        </Grid>
                      </NewTip>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {props.disciplines?.map((action) => (
                    <Grid
                      item
                      container
                      direction={"row"}
                      gap={1}
                      xs={12}
                      key={action.Id}
                      paddingLeft={2.7}
                    >
                      <Grid
                        item
                        onMouseOver={(e) => {
                          if (
                            localStorage.getItem("viewSubPro") === "true" ||
                            (localStorage.getItem("AccountType") === "2" &&
                              localStorage.getItem("noCreator") !== "true" &&
                              (localStorage.getItem("planId") === "Core" || localStorage.getItem("PlanId") === "Pro" ||
                                (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")))
                          )
                            e.target.style.cursor = "unset";
                          else e.target.style.cursor = "pointer";
                        }}
                        onClick={(e) => {
                          if (
                            localStorage.getItem("viewSubPro") === "true" ||
                            (localStorage.getItem("AccountType") === "2" &&
                              localStorage.getItem("noCreator") !== "true" &&
                              (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro" ||
                                (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")))
                          )
                            console.log("");
                          else {
                            if (action.IsSelected === true)
                              action.IsSelected = false;
                            else if (action.IsSelected === false)
                              action.IsSelected = true;
                            forceUpdate();
                            setClicked(!clicked);
                          }
                        }}
                        className={
                          action.IsSelected
                            ? classes["FullChecked"]
                            : classes["Not-checked"]
                        }
                        marginTop={0.4}
                      ></Grid>
                      <Grid item className={classes["Action-permission"]}>
                        {action.Name}
                      </Grid>
                    </Grid>
                  ))}
                  {newDActions.map((action) => (
                    <Grid
                      item
                      container
                      direction={"row"}
                      gap={1}
                      xs={12}
                      key={action.Name}
                      alignItems="center"
                    >
                      <BlueTip title={"Remove Discipline"} placement="left">
                        <Grid
                          item
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            deleteDiscipline(action);
                            forceUpdate();
                          }}
                        >
                          <img src={remove} alt="remove" />
                        </Grid>
                      </BlueTip>
                      <Grid
                        item
                        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                        onClick={(e) => {
                          if (action.IsSelected === true)
                            action.IsSelected = false;
                          else if (action.IsSelected === false)
                            action.IsSelected = true;
                          forceUpdate();
                          setClicked(!clicked);
                        }}
                        className={
                          action.IsSelected
                            ? classes["FullChecked"]
                            : classes["Not-checked"]
                        }
                        marginTop={0.4}
                      ></Grid>
                      <Grid item className={classes["Action-permission"]}>
                        {action.Name}
                      </Grid>
                    </Grid>
                  ))}
                  {addDisc ? (
                    <Grid
                      item
                      container
                      padding="5% 0 0 10%"
                      alignItems="center"
                      gap={0.8}
                    >
                      <Grid item xs={5}>
                        <TextField
                          size="small"
                          label="Name"
                          value={disciplineName}
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "9px",
                            },
                          }}
                          inputProps={{
                            style: { height: 5, backgroundColor: "#fff" },

                            maxLength: 50,
                          }}
                          onChange={(e) => setDisciplineName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={5.3}>
                        <TextField
                          size="small"
                          label="Abbreviation"
                          value={disciplineAbbreviation}
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "9px",
                            },
                          }}
                          inputProps={{
                            style: { height: 5, backgroundColor: "#fff" },
                            maxLength: 50,
                          }}
                          onChange={(e) =>
                            setDisciplineAbbreviation(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          setHover(true);
                        }}
                        onMouseLeave={() => setHover(false)}
                        onClick={() => {
                          pushDisc();
                          setAddDisc(false);
                        }}
                      >
                        <img src={hover ? activeCheck : check} alt="save" />
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item container md={4} alignContent={"flex-start"}>
                  <Grid
                    item
                    container
                    paddingBottom={1}
                    xs={12}
                    alignItems="center"
                    paddingLeft={1}
                  >
                    <Grid item xs={7} className={classes["Action-title"]}>
                      *Subproject Trade
                    </Grid>
                    {props.subProjectParentId ||
                      localStorage.getItem("viewSubPro") === "true" ||
                      (localStorage.getItem("AccountType") === "2" &&
                        (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro") ||
                        (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")) ? (
                      <></>
                    ) : (
                      <NewTip title={"Add Trade"} placement="top">
                        <Grid
                          item
                          onMouseOver={(e) =>
                            (e.target.style.cursor = "pointer")
                          }
                          onClick={() => setAddTrade(true)}
                        >
                          <img src={add} alt="add" width="110%" />
                        </Grid>
                      </NewTip>
                    )}
                    {addTrade ? (
                      <NewTip title={"cancel"} placement="top">
                        <Grid
                          item
                          padding="0 0 1% 1%"
                          onMouseOver={(e) =>
                            (e.target.style.cursor = "pointer")
                          }
                          onClick={() => setAddTrade(false)}
                        >
                          <img src={remove} alt="cancel" width="110%" />
                        </Grid>
                      </NewTip>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {props.trades?.map((action) => (
                    <Grid
                      item
                      container
                      direction={"row"}
                      gap={1}
                      xs={12}
                      key={action.Id}
                      paddingLeft={2.7}
                    >
                      <Grid
                        item
                        onMouseOver={(e) => {
                          if (
                            localStorage.getItem("viewSubPro") === "true" ||
                            (localStorage.getItem("AccountType") === "2" &&
                              localStorage.getItem("noCreator") !== "true" &&
                              (localStorage.getItem("planId") === "Core" || localStorage.getItem("PlanId") === "Pro" ||
                                (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")))
                          )
                            e.target.style.cursor = "unset";
                          else e.target.style.cursor = "pointer";
                        }}
                        onClick={(e) => {
                          if (
                            localStorage.getItem("viewSubPro") === "true" ||
                            (localStorage.getItem("AccountType") === "2" &&
                              localStorage.getItem("noCreator") !== "true" &&
                              (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro" ||
                                (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")))
                          )
                            console.log("");
                          else {
                            if (action.IsSelected === true)
                              action.IsSelected = false;
                            else if (action.IsSelected === false)
                              action.IsSelected = true;
                            forceUpdate();
                            setClicked(!clicked);
                          }
                        }}
                        className={
                          action.IsSelected
                            ? classes["FullChecked"]
                            : classes["Not-checked"]
                        }
                        marginTop={0.4}
                      ></Grid>
                      <Grid item className={classes["Action-permission"]}>
                        {action.Name}
                      </Grid>
                    </Grid>
                  ))}
                  {newTActions.map((action) => (
                    <Grid
                      item
                      container
                      direction={"row"}
                      gap={1}
                      xs={12}
                      key={action.Name}
                      alignItems="center"
                    >
                      <BlueTip title={"Remove Trade"} placement="left">
                        <Grid
                          item
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            deleteTrade(action);
                            forceUpdate();
                          }}
                        >
                          <img src={remove} alt="remove" />
                        </Grid>
                      </BlueTip>
                      <Grid
                        item
                        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                        onClick={(e) => {
                          if (action.IsSelected === true)
                            action.IsSelected = false;
                          else if (action.IsSelected === false)
                            action.IsSelected = true;
                          forceUpdate();
                          setClicked(!clicked);
                        }}
                        className={
                          action.IsSelected
                            ? classes["FullChecked"]
                            : classes["Not-checked"]
                        }
                        marginTop={0.4}
                      ></Grid>
                      <Grid item className={classes["Action-permission"]}>
                        {action.Name}
                      </Grid>
                    </Grid>
                  ))}
                  {addTrade ? (
                    <Grid
                      item
                      container
                      padding="5% 0 0 10%"
                      alignItems="center"
                      gap={0.8}
                    >
                      <Grid item xs={5}>
                        <TextField
                          size="small"
                          label="Name"
                          value={tradeName}
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "9px",
                            },
                          }}
                          inputProps={{
                            style: { height: 5, backgroundColor: "#fff" },

                            maxLength: 50,
                          }}
                          onChange={(e) => setTradeName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={5.3}>
                        <TextField
                          size="small"
                          label="Abbreviation"
                          value={tradeAbbreviation}
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "9px",
                            },
                          }}
                          inputProps={{
                            style: { height: 5, backgroundColor: "#fff" },
                            maxLength: 50,
                          }}
                          onChange={(e) => setTradeAbbreviation(e.target.value)}
                        />
                      </Grid>
                      <Grid
                        item
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          setHover1(true);
                        }}
                        onMouseLeave={() => setHover1(false)}
                        onClick={() => {
                          pushTrade();
                          setAddTrade(false);
                        }}
                      >
                        <img src={hover1 ? activeCheck : check} alt="save" />
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row-reverse"
                flexWrap="wrap-reverse"
              >
                <Grid item>
                  <button
                    disabled={
                      (localStorage.getItem("viewSubPro") === "true" ||
                        (localStorage.getItem("AccountType") === "2" &&
                          localStorage.getItem("noCreator") !== "true" &&
                          (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro" ||
                            (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true"))))
                        || ( (props.disciplines.filter((d) => d.IsSelected === true).length === 0 && newDActions.filter((d) => d.IsSelected === true).length === 0 ) ||
                          (props.trades.filter((d) => d.IsSelected === true).length === 0  && newTActions.filter((d) => d.IsSelected === true).length === 0))
                        ? true
                        : false
                    }
                    className={classes.SaveModal}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#242B64";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#0b5cab";
                    }}
                    style={{
                      backgroundColor:
                        (localStorage.getItem("viewSubPro") === "true" ||
                          (localStorage.getItem("AccountType") === "2" &&
                            localStorage.getItem("noCreator") !== "true" &&
                            (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro" ||
                              (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true"))))
                          || ( (props.disciplines.filter((d) => d.IsSelected === true).length === 0 && newDActions.filter((d) => d.IsSelected === true).length === 0 )||
                            (props.trades.filter((d) => d.IsSelected === true).length === 0  && newTActions.filter((d) => d.IsSelected === true).length === 0 ))
                          ? "lightgrey"
                          : "#0b5cab",
                      fontFamily: "Muli",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      concatDT();
                      props.setDiscSaved(true);
                      props.setDiscAssigned(!props.discAssigned);
                      setOpen(false);
                    }}
                  >
                    Save
                  </button>
                </Grid>
                <Grid item paddingRight={2}>
                  <button
                    className={classes.exitModal}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#f5f5f5";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#fff";
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>
    </div>
  );
};

export default DisciplineModal;
