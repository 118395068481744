import {
    Grid,
    TableCell,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableBody,
    Modal,
    TextField,
    MenuItem
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../Assets/Images/SaaSbackground.png";
import classes from "../../Assets/Styles/factory.module.css";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import * as loadingServices from '../../Services/loading-services.proxy';
import useScanDetection from 'use-scan-detection';

const scanBarcodes = loadingServices.scanBarcodes;
const getLinesData = loadingServices.getLinesData;
const validateLines = loadingServices.validateLines;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    backgroundColor: "#f1f1f1",
    outline: "none",
    width: '526px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '-webkit-backdrop-filter': 'blur(30px)',
    'backdrop-filter': 'blur(30px)',
}

const removeStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
    outline: "none",
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    WebkitBackdropFilter: 'blur(30px)',
    backdropFilter: 'blur(30px)',
    width: '474px',
    borderRadius: '18px',
    border: 'solid 1px #707070',
    backgroundColor: '#101650'
}

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: 10,
    },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
    root: {
        height: 100,
    },
}))(TableRow);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor:
            theme.palette.mode === 'light' ? '#101650' : '#308fe8',
    },
}));


export default function ResultView(props) {

    useEffect(() => {
        props.setRegister(true);
    }, [props.setRegister]);

    useEffect(() => {
        props.setNaved(false);
    }, [props.setNaved]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const handleOpenRemoveModal = () => setOpenRemoveModal(true);
    const handleCloseRemoveModal = () => setOpenRemoveModal(false);

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();

    const loadId = query.get("LoadId") ? query.get("LoadId") : null;
    const LoadDock = query.get("LoadDock") ? query.get("LoadDock") : "";

    const [trailerName, setTrailerName] = useState("");
    const [dockName, setDockName] = useState("");

    const [lineBarcodes, setLineBarcodes] = useState([]);
    const [lineBarcode, setLineBarcode] = useState(null);

    const [allLinesBarcodes, setAllLinesBarcodes] = useState([]);
    const [selectedLineBarcode, setSelectedLineBarcode] = useState(null);

    const [salesOrders, setSalesOrders] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');

    const [scanned, setScanned] = useState(false);

    const [modify, setModify] = useState(false);

    const sumOfWeightPerMember = (LinesToCalculateWeight) => {
        let sum = 0;
        for (let i = 0; i < LinesToCalculateWeight.length; i++) {
            sum = sum + LinesToCalculateWeight[i].TheoreticalWeight;
        }
        return !sum ? 0 : sum.toFixed(2);
    };

    const totalSum = (order) => {
        let sum = 0;
        order.Members?.map(member => {
            sum += +sumOfWeightPerMember(member?.Lines)
        })
        return !sum ? 0 : sum;
    }

    const scannedSum = (order) => {
        let sum = 0;
        order.Members?.map(member => {
            sum += +sumOfWeightPerMember(member?.Lines?.filter(l => l.IsScanned === true))
        })
        return !sum ? 0 : sum;
    }

    const ordersTotal = (orders) => {
        let sum = 0;
        orders.map(o => {
            sum += totalSum(o);
        })
        return !sum ? 0 : sum;
    }

    const ordersLoaded = (orders) => {
        let sum = 0;
        orders.map(o => {
            sum += scannedSum(o);
        })
        return !sum ? 0 : sum;
    }

    const [scanning, setScanning] = useState(false);
    const [validating, setValidating] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        let apiTest = true;
        setSelectedLineBarcode(null);

        if (loadId) {
            setLoadingData(true);
            getLinesData(loadId).then((x) => {
                if (apiTest && x) {
                    setTrailerName(x.TrailerName);
                    setSalesOrders(x.SalesOrders);
                    setDockName(x.DockName);
                    setIsVerified(x.IsVerified);
                    let arr = [];
                    let arr2 = [];
                    x.SalesOrders.map((so) => {
                        so.Members.map((mem) => {
                            mem.Lines.map((l) => {
                                if (l.IsScanned === true)
                                    arr = arr.concat(l.Barcode);

                                arr2 = arr2.concat(l.Barcode);
                            })
                        })
                    })
                    setLineBarcodes(arr);
                    setAllLinesBarcodes(arr2);
                }
                setLoadingData(false);
            })
        }

        return () => {
            apiTest = false;
        }
    }, [loadId, modify])


    function LinearProgressWithLabel(props) {
        return (
            <Grid item xs={12} container alignItems='center' direction='column' justifyContent='flex-start'>
                <Grid item container alignItems='center' justifyContent='space-between' >
                    <Grid item sx={{ color: "#707070", fontFamily: 'Muli', fontSize: '12px', fontWeight: 'bold' }}>
                        Weight Loaded
                    </Grid>
                    <Grid item sx={{ color: "text.secondary", fontSize: '12px' }}>
                        {`${Math.round(props.value)}%`}
                    </Grid>
                </Grid>
                <Grid item sx={{ width: '100%', mr: 1.5 }}>
                    <BorderLinearProgress variant="determinate" value={props.value ? props.value : 0} />
                </Grid>
            </Grid>
        );
    }

    useScanDetection({
        onComplete: (code) => {
            if (!lineBarcodes?.includes(code.replace("Shift", "").replace("Enter", "").replace("ArrowDown", "")) &&
                salesOrders.flatMap(o => o.Members)?.flatMap(m => m.Lines)?.find(l => l.Barcode === code.replace("Shift", "").replace("Enter", "").replace("ArrowDown", ""))?.IsScanned === false &&
                isVerified === false) {
                setLineBarcodes(lineBarcodes.concat(code.replace("Shift", "").replace("Enter", "").replace("ArrowDown", "")));
                setScanning(true);
                scanBarcodes({
                    Id: loadId,
                    LineBarcodes: lineBarcodes.concat(code.replace("Shift", "").replace("Enter", "").replace("ArrowDown", ""))
                }).then((x) => {
                    if (x.status) {
                        setError(x.error)
                        setDisabled(true);
                    } else {
                        setScanned(true);
                    }
                    setScanning(false);
                    setModify(!modify);
                    forceUpdate();
                })

            }
        },
        minLength: 3
    })

    const data = {
        Id: loadId,
        LineBarcodes: lineBarcodes
    }

    return (
        <div
            style={{
                minHeight: "100vh",
                backgroundImage: `url(${SaasBack})`,
                backgroundSize: "cover",
                objectFit: "cover",
            }}
        >
            <Grid item container paddingTop={disabled ? 0 : 3} rowGap={2} paddingBottom={2}>
                {disabled ? (
                    <Grid
                        item
                        container
                        bgcolor={"rgba(225, 227, 25, 0.5)"}
                        xs={12}
                        padding={1}
                        paddingTop={{ xs: '3%', md: '1%' }}
                    >
                        <Grid item xs={4} md={4.5}></Grid>
                        <Grid
                            item
                            xs={7}
                            md={5.5}
                            style={{
                                fontFamily: "Muli",
                                fontSize: "15px",
                                textAlign: "left",
                                color: "#000",
                            }}
                        >
                            The Load Limit is {error} Kgs.
                        </Grid>
                        <Grid item xs={1} md={1} container>
                            <Grid
                                item
                                paddingLeft={1}
                                onClick={() => {
                                    setDisabled(false);
                                }}
                                onMouseOver={(e) => {
                                    e.target.style.cursor = "pointer";
                                }}
                            >
                                <Close fontSize="medium" />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                <Backdrop
                    sx={{
                        color: "#fff",
                        backgroundColor: "transparent",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                    open={loadingData}
                >
                    <CircularProgress />

                    <Grid
                        item
                        fontFamily={"Muli"}
                        fontSize={15}
                        fontWeight={"bold"}
                        color={"rgb(25, 118, 210)"}
                    >
                        Loading Data...
                    </Grid>
                </Backdrop>
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    gap={1}
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        direction="column"
                        lg={1.5}
                        sm={2.5}
                        xs={3.7}
                        fontSize={{ xs: "13px", sm: "16px" }}
                        fontWeight="bold"
                        className={classes["Title"]}
                        paddingTop={{ xs: 1.5, sm: 3 }}
                    >
                        <Grid item>{LoadDock ? LoadDock : ""} {dockName}</Grid>
                        <Grid item>{trailerName}</Grid>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sm={5}
                        lg={7}
                        sx={{
                            border: "solid 0.1px #fff",
                            backgroundColor: "#fff",
                            height: 0,
                        }}
                    ></Grid>
                    {
                        isVerified === true ? ""
                            :
                            <>
                                {
                                    salesOrders?.flatMap(s => s.Members)?.flatMap(m => m.Lines)?.find(l => l.IsScanned === true) ?
                                        <Grid item>
                                            <button
                                                className={classes["back-button"]}
                                                onClick={handleOpenModal}
                                                style={{
                                                    boxShadow: '0 3px 6px 0 rgba(16, 22, 80, 0.5)',
                                                    border: 'solid 1px #101650',
                                                    backgroundColor: 'transparent',
                                                    color: '#101650'
                                                }}
                                            >
                                                Validate
                                            </button>
                                        </Grid> : ""
                                }
                                <Grid item>
                                    <button
                                        className={classes["back-button"]}
                                        disabled={!loadId || lineBarcodes.length === 0}
                                        // onClick={() => {
                                        //     if (loadId && lineBarcodes.length > 0) {
                                        //         setScanning(true);
                                        //         scanBarcodes(data).then((x) => {
                                        //             if (x.status) {
                                        //                 setError(x.error)
                                        //                 setDisabled(true);
                                        //             } else {
                                        //                 setScanned(true);
                                        //             }
                                        //             setScanning(false);
                                        //             setModify(!modify);
                                        //             forceUpdate();
                                        //         })
                                        //     }
                                        // }}
                                        style={{
                                            boxShadow: '0 3px 6px 0 rgba(16, 22, 80, 0.5)',
                                            backgroundColor: !loadId || lineBarcodes.length === 0 ? '#bcbcbc' : '#101650',
                                            color: '#fff',
                                            width: '66px',
                                            cursor: !loadId || lineBarcodes.length === 0 ? 'unset' : 'pointer'
                                        }}
                                    >
                                        {scanning ? "Scanning" : scanned ? "Scan Again" : "Scan"}
                                    </button>
                                </Grid>
                            </>
                    }

                    <Grid item>
                        <button
                            className={classes["back-button"]}
                            onClick={() => {
                                navigate("/saas/Loading/Calendar");
                            }}
                        >
                            Go Back
                        </button>
                    </Grid>
                </Grid>
                <Grid item xs={10.5} md={6.5} marginLeft={{ xs: 2, sm: 5, md: 15 }}>
                    <LinearProgressWithLabel variant="determinate" value={((ordersLoaded(salesOrders) / ordersTotal(salesOrders)) * 100).toFixed(2)} />
                </Grid>
                <Grid item container xs={11} md={7} marginLeft={{ xs: 2, sm: 5, md: 15 }}>
                    <TableContainer sx={{ width: "100%" }}>
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                                borderCollapse: "separate",
                                borderSpacing: "0px 0px",
                                border: 0,
                                // backgroundColor: "#fff",
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={styles.tableCell}>
                                        Tag
                                        <br />
                                        No.
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        Bar Mark
                                        <br />
                                        No.
                                    </TableCell>
                                    <TableCell sx={styles.tableCell}>Total No.<br />of Bars</TableCell>
                                    <TableCell sx={styles.tableCell}>Weight<br />(Kgs)</TableCell>
                                    <TableCell sx={styles.tableCell}>
                                        Shape
                                    </TableCell>
                                    <TableCell sx={{
                                        backgroundColor: 'transparent', border: 0
                                    }}></TableCell>
                                </TableRow>
                            </TableHead>
                            {salesOrders?.map((so) => (
                                <>
                                    <StyledTableRow>
                                        <TableCell sx={styles.tableSubCellSo}>{so.Code}</TableCell>
                                        <TableCell sx={styles.tableSubCellSo}></TableCell>
                                        <TableCell sx={styles.tableSubCellSo}></TableCell>
                                        <TableCell sx={styles.tableSubCellSo}></TableCell>
                                        <TableCell sx={styles.tableSubCellSo}></TableCell>
                                        <TableCell sx={{
                                            backgroundColor: 'transparent', border: 0
                                        }}></TableCell>
                                    </StyledTableRow>
                                    {so.Members.map((member) => (
                                        <TableBody key={member.OrderId}>
                                            <StyledTableRow>
                                                <TableCell sx={styles.tableSubCell}></TableCell>
                                                <TableCell sx={styles.tableSubCell}>
                                                    {member.OrderId}
                                                </TableCell>
                                                <TableCell sx={styles.tableSubCell}></TableCell>
                                                <TableCell sx={styles.tableSubCell}>
                                                    {sumOfWeightPerMember(member?.Lines)}
                                                </TableCell>
                                                <TableCell sx={styles.tableSubImage2}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={classes["subsubTitiles"]}
                                                        >
                                                            {member.Name}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={classes["subsubTitiles"]}
                                                        >
                                                            {member.Type}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={classes["subsubTitiles"]}
                                                        >
                                                            {member.BarMarkType}
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell sx={{
                                                    backgroundColor: 'transparent', border: 0
                                                }}></TableCell>
                                            </StyledTableRow>
                                            {member.Lines.map((Line, index) => (
                                                <StyledTableSubRow key={index}
                                                // onMouseOver={(e) => {
                                                //     if (Line.IsScanned === false && isVerified === false)
                                                //         e.target.style.cursor = 'pointer'
                                                // }}
                                                // onClick={(e) => {
                                                //     if (!lineBarcodes?.includes(Line.Barcode) && Line.IsScanned === false && isVerified === false) {
                                                //         setLineBarcodes(lineBarcodes.concat(Line.Barcode));
                                                //     }
                                                // }}
                                                >
                                                    <TableCell sx={styles.tableSubSubCell} style={{
                                                        backgroundColor: Line.IsScanned ? "#e8f2d3" : "#fff"
                                                    }}>
                                                        {Line.TagNumber}
                                                    </TableCell>
                                                    <TableCell sx={styles.tableSubSubCell} style={{
                                                        backgroundColor: Line.IsScanned ? "#e8f2d3" : "#fff"
                                                    }}>
                                                        {Line.BarMarkNo}
                                                    </TableCell>
                                                    <TableCell sx={styles.tableSubSubCell} style={{
                                                        backgroundColor: Line.IsScanned ? "#e8f2d3" : "#fff"
                                                    }}>
                                                        {Line.TotalNumberOfBars}
                                                    </TableCell>
                                                    <TableCell sx={styles.tableSubSubCell} style={{
                                                        backgroundColor: Line.IsScanned ? "#e8f2d3" : "#fff"
                                                    }}>
                                                        {Line.TheoreticalWeight}
                                                    </TableCell>
                                                    <TableCell sx={styles.tableSubSubCell} style={{
                                                        backgroundColor: Line.IsScanned ? "#e8f2d3" : "#fff"
                                                    }}>
                                                        <Grid item padding='5%'>
                                                            <img
                                                                width='100%'
                                                                src={
                                                                    Line.ShapeBlob
                                                                        ? Line.ShapeBlob
                                                                        : Line.Shape.File.URL
                                                                }
                                                                alt={"shapeImg"}
                                                            ></img>
                                                        </Grid>
                                                    </TableCell>
                                                    {
                                                        lineBarcodes?.includes(Line.Barcode) && isVerified === false ?
                                                            <TableCell sx={{
                                                                backgroundColor: 'transparent', border: 0, cursor: 'pointer'
                                                            }}
                                                                onClick={() => {
                                                                    console.log(Line.Barcode);
                                                                    if (lineBarcodes.includes(Line.Barcode)) {
                                                                        setLineBarcode(Line.Barcode);
                                                                        setLineBarcodes(lineBarcodes.filter(x => x !== Line.Barcode));
                                                                        handleOpenRemoveModal();
                                                                    }
                                                                }}>
                                                                <CancelSharpIcon />
                                                            </TableCell>
                                                            : ""
                                                    }
                                                </StyledTableSubRow>
                                            ))}
                                        </TableBody>
                                    ))}
                                </>
                            ))}
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={11} md={4}>
                    <TextField
                        select
                        fullWidth
                        value={selectedLineBarcode}
                        InputProps={{
                            style: {
                                height: "40px",
                                fontSize: 12,
                                fontFamily: "Muli",
                                width: "200px"
                            },
                        }}

                        onChange={(e) => {
                            setSelectedLineBarcode(e.target.value);
                            setLineBarcodes(lineBarcodes.concat(e.target.value));
                            setScanning(true);
                            scanBarcodes({
                                Id: loadId,
                                LineBarcodes: lineBarcodes.concat(e.target.value)
                            }).then((x) => {
                                if (x.status) {
                                    setError(x.error)
                                    setDisabled(true);
                                } else {
                                    setScanned(true);
                                }
                                setScanning(false);
                                setModify(!modify);
                                forceUpdate();
                            })



                        }}
                    >
                        {allLinesBarcodes.map((s, index) => (
                            <MenuItem key={index} value={s} disabled={lineBarcodes.find((b) => b === s)}>{s}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <Modal
                disableScrollLock
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid container sx={modalStyle} justifyContent="center" alignItems="center" gap={2} direction='column'>
                    <Grid item sx={{
                        fontFamily: 'Muli',
                        fontSize: 15,
                        fontWeight: 'bold',
                        lineHeight: 1.27,
                        color: '#101650'
                    }}>Validate Load</Grid>
                    <Grid item>
                        <Table sx={{ width: 312 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={styles.modalTableCell}>Sales Order Code</TableCell>
                                    <TableCell sx={styles.modalTableCell}>Total<br />Weight<br />(Kgs)</TableCell>
                                    <TableCell sx={styles.modalTableCell}>Loaded<br />Weight<br />(Kgs)</TableCell>
                                    <TableCell sx={styles.modalTableCell} style={{ borderRight: 0 }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salesOrders?.map((So, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                                    >
                                        <TableCell sx={styles.modalTableCell}>
                                            {So.Code}
                                        </TableCell>
                                        <TableCell sx={styles.modalTableCell}>
                                            {totalSum(So).toFixed(2)}
                                        </TableCell>
                                        <TableCell sx={styles.modalTableCell}>
                                            {scannedSum(So).toFixed(2)}
                                        </TableCell>
                                        <TableCell sx={styles.modalTableCell} style={{ borderRight: 0 }}>
                                            {scannedSum(So) === totalSum(So) ?
                                                "Fully"
                                                :
                                                "Partially"
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item container
                        justifyContent="center" alignItems="center" gap={1} direction='column'>
                        <Grid item sx={{
                            fontFamily: 'Muli',
                            fontSize: 12,
                            lineHeight: 1.22,
                            textAlign: 'center',
                            color: '#000',
                            fontWeight: "bold",
                            width: "250px"
                        }}>
                            Once validated, you will be unable to add or remove a new line
                        </Grid>
                    </Grid>
                    <Grid item container
                        direction="row"
                        justifyContent="center"
                        gap={1}
                        alignItems="center">
                        <Grid item>
                            <button
                                className={classes["back-button"]}
                                onClick={() => {
                                    handleCloseModal();
                                }}
                                style={{
                                    boxShadow: '0 3px 6px 0 rgba(16, 22, 80, 0.5)',
                                    border: 'solid 1px #101650',
                                    backgroundColor: 'transparent',
                                    color: '#101650',
                                    width: '66px'
                                }}
                            >
                                Cancel
                            </button>
                        </Grid>
                        <Grid item>
                            <button
                                className={classes["back-button"]}
                                onClick={() => {
                                    setValidating(true)
                                    if (loadId && lineBarcodes.length > 0)
                                        validateLines(loadId).then((x) => {
                                            if (x.status)
                                                setDisabled(true);
                                            else {
                                                setModify(!modify);
                                                setScanned(false);
                                            }
                                            setValidating(false);
                                            handleCloseModal();
                                        })
                                }}
                                style={{
                                    boxShadow: '0 3px 6px 0 rgba(16, 22, 80, 0.5)',
                                    backgroundColor: '#101650',
                                    color: '#fff',
                                    width: '66px'
                                }}
                            >
                                {validating ? "Loading" : "Confirm"}
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                disableScrollLock
                open={openRemoveModal}
                onClose={handleCloseRemoveModal}
                aria-labelledby="modal-modal-title1"
                aria-describedby="modal-modal-description1"
            >
                <Grid container sx={removeStyle} justifyContent="center" alignItems="center" gap={2} direction='column'>
                    <Grid item sx={{
                        fontFamily: 'Muli',
                        fontSize: 20,
                        lineHeight: 1.27,
                        color: '#fff'
                    }}>Are you sure you want to unload<br />this label from the trailer ?</Grid>
                    <Grid item container
                        direction="row"
                        justifyContent="center"
                        gap={1}
                        alignItems="center">
                        <Grid item>
                            <button
                                className={classes["back-button"]}
                                onClick={() => {
                                    if (!lineBarcodes?.includes(lineBarcode)) {
                                        setLineBarcodes(lineBarcodes.concat(lineBarcode));
                                    }
                                    handleCloseRemoveModal();
                                }}
                                style={{
                                    border: 'solid 1px #707070',
                                    backgroundColor: '#fff',
                                    color: '#101650',
                                    width: '66px'
                                }}
                            >
                                No
                            </button>
                        </Grid>
                        <Grid item>
                            <button
                                className={classes["back-button"]}
                                onClick={() => {
                                    scanBarcodes(data).then((x) => {
                                        if (x.status) {
                                            setError(x.error);
                                            setDisabled(true);
                                        }
                                        setModify(!modify);
                                        setScanned(false);
                                        forceUpdate();
                                        handleCloseRemoveModal();
                                    })
                                }}
                                style={{
                                    border: 'solid 1px #fff',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    width: '66px'
                                }}
                            >
                                Yes
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </div >
    );
}
const styles = {
    tableCell: {
        padding: '1%',
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#101650",
        lineHeight: 0.91,
        textAlign: "center",
        width: "250px",
    },
    tableCellEmpty: {
        backgroundColor: "#EFF183",
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#101650",
        lineHeight: 0.91,
        textAlign: "center",
        width: 43,
    },
    tableCellQty: {
        padding: 0,
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#101650",
        lineHeight: 0.91,
        textAlign: "center",
        width: 600,
    },
    tableSubCellSo: {
        backgroundColor: "rgba(225, 227, 25,0.9)",
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#707070",
        lineHeight: 1.45,
        textAlign: "left",
        padding: '0 0 0 1%',
    },
    tableSubCell: {
        backgroundColor: "#EFF183",
        fontSize: 11,
        fontFamily: "Muli",
        fontWeight: "bold",
        color: "#707070",
        lineHeight: 1.45,
        textAlign: "center",
        padding: 0,
    },
    tableSubSubCell: {
        border: "solid 1px",
        borderColor: "#e1e319",
        backgroundColor: "#fff",
        // backgroundColor: '#CED2F5',
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#707070",
        lineHeight: 1.45,
        textAlign: "center",
        padding: 0,
    },
    tableCellImage: {
        backgroundColor: "#fff",
        padding: 0,
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#101650",
        lineHeight: 0.91,
        textAlign: "center",
        width: 800,
    },
    tableSubImage2: {
        backgroundColor: "#EFF183",
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#707070",
        lineHeight: 1.45,
        width: 400,
        padding: 0,
        // height: 38,
    },
    tableRow: {
        backgroundColor: "#fff",
        boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
        maxHeight: 38,
    },
    tableCell2: {
        backgroundColor: "transparent",
        fontFamily: "Muli",
        fontSize: 13,
        color: "#0674b9",
    },
    tableBody: {
        "& > :not(:last-child)": {
            borderBottom: "25px solid red",
        },
    },
    tfInput: {
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#707070",
        lineHeight: 1.45,
        textAlign: "center",
        paddingLeft: 2,
        height: 5,
    },
    tfInput2: {
        fontFamily: "Muli",
        fontSize: 11,
        fontWeight: "bold",
        color: "#707070",
        textAlign: "center",
        height: 5,
    },
    modalTableCell: {
        fontFamily: 'Muli',
        fontSize: 10,
        lineHeight: 1.3,
        textAlign: 'left',
        color: '#000',
        borderRight: 'solid 0.5px #707070',
        borderBottom: 'solid 0.5px #707070'
    }
};
