import React, { useState, useEffect, useMemo } from "react";
import "../../../Assets/Styles/home.css";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import { Box, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import classes from "../../../Assets/Styles/Delivery.module.css";
import { styled } from "@mui/material/styles";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import moment from "moment";
import plusNavy from "../../../Assets/Images/plusNavy.png";
import ShipmentCard from "./ShipmentCard";
import ShipmentModal from "./ShipmentModal";
import DeleteModal from "./DeleteModal";
import * as DeliveryServices from "../../../Services/delivery-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
import useWindowDimensions from "../../../../Components/Navbar/WindowDimension";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const getInputData = DeliveryServices.getInputData;
const getDeliveryCalendar = DeliveryServices.getDeliveryCalendar;
const createShipment = DeliveryServices.createShipment;
const updateShipment = DeliveryServices.updateShipment;
const deleteShipment = DeliveryServices.deleteShipment;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 10px;
      box-sizing: border-box;
      height: 35px;
      width: 7rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      margin-top: 0.7em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[100]
        };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      cursor: pointer;
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: "Muli";
      font-size: 0.6rem;
      box-sizing: border-box;
      padding: 5px;
      width: 8rem;
      height:130px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: scroll;
      outline: 0px;
      
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: pointer;
      margin-top: 2px;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default function Calendar(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabledD, setDisabledD] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(UserPermissions, "Delivery", "Create", setDisabledD);
    }
  }, [UserPermissions]);

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [dateSelected, setDateSelected] = useState(new Date());

  const [factoryFilter, setFactoryFilter] = useState(0);
  const [factoriesList, setFactoriesList] = useState([]);
  const [hint, setHint] = useState(false);

  const [deliveryList, setDeliveryList] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [shipmentId, setShipmentId] = useState(null);

  const [modify, setModify] = useState(false);

  //////////////////  Shipment Fields /////////////////////////////////
  const [date, setDate] = useState();

  const [timeSlotCode, setTimeSlotCode] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const [shipmentNb, setShipmentNb] = useState(null);

  const [driverId, setDriverId] = useState(null);
  const [driversList, setDriverList] = useState([]);

  const [truckId, setTruckId] = useState(null);
  const [trucksList, setTrucksList] = useState([]);

  const [trailerId, setTrailerId] = useState(null);
  const [trailersList, setTrailersList] = useState([]);

  const [loads, setLoads] = useState([]);

  const [salesOrdersCodes, setSalesOrdersCodes] = useState([]);

  //////////////////// Loading States /////////////////////////////

  const [loading, setLoading] = useState(false);
  const [shipmentLoading, setShipmentLoading] = useState(false);
  const [shipmentSuccess, setShipmentSuccess] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    setLoading(true);
    getDeliveryCalendar(
      new Date(dateSelected).toISOString().slice(0, 10),
      // moment().format('DD/MM/YYYY'),
      factoryFilter === 0 ? null : factoryFilter
    ).then((response) => {
      // setFactoryFilter(response?.Factories[0]?.Id)
      setFactoriesList(response.Factories);
      setDeliveryList(response.Calendar);
      setLoading(false);
    });
  }, [dateSelected, factoryFilter, modify]);

  const getShipmentInputData = (
    currentShipmentId,
    currentSlotCode,
    currentDate
  ) => {
    setShipmentId(currentShipmentId);
    setModalLoading(true);

    getInputData(
      currentShipmentId === null ? null : currentShipmentId,
      factoryFilter
    ).then((x) => {
      setTrucksList(x.Trucks);
      setTimeSlots(x.TimeSlots);
      setDriverList(x.Drivers);
      setTrailersList(x.Trailers);

      if (currentShipmentId === null) {
        setShipmentNb(x.Number);
        setTimeSlotCode(currentSlotCode);
        setDate(currentDate);
      } else {
        setDate(moment(x.Shipment.Date).format("YYYY-MM-DD"));
        setShipmentNb(x.Shipment.Number);
        setTruckId(x.Shipment.TruckId);
        setTrailerId(x.Shipment.TrailerId);
        setDriverId(x.Shipment.DriverId);
        let trailerObject = x.Trailers?.find(
          (t) => t.Id === x.Shipment.TrailerId
        );
        setLoads(trailerObject?.Loads);
        setSalesOrdersCodes(trailerObject?.SalesOrderCodes);

        setTimeSlotCode(x.Shipment.TransitTimeSlotId);
      }
      setModalLoading(false);
    });
  };

  const creatingShipment = () => {
    setShipmentLoading(true);
    let input = {
      Id: null,
      Number: shipmentNb,
      Date: date,
      TransitTimeSlotId: timeSlotCode,
      TruckId: truckId,
      TrailerId: trailerId,
      DriverId: driverId,
      Loads: loads.map((x) => x.Id),
    };

    createShipment(input).then((x) => {
      setShipmentLoading(false);
      // if (x.status === 200 || x.status === 201 || x.status === 204) {
      setShipmentSuccess(true);
      setTimeout(() => {
        setShipmentSuccess(false);
      }, 2000);

      setOpenModal(false);

      setModify(!modify);
    });
  };

  const updatingShipment = () => {
    setShipmentLoading(true);
    let input = {
      Id: shipmentId,
      Number: shipmentNb,
      Date: date,
      TransitTimeSlotId: timeSlotCode,
      TruckId: truckId,
      TrailerId: trailerId,
      DriverId: driverId,
      Loads: loads.map((x) => x.Id),
    };

    updateShipment(input).then((x) => {
      setShipmentLoading(false);
      // if (x.status === 200 || x.status === 201 || x.status === 204) {

      setShipmentSuccess(true);
      setTimeout(() => {
        setShipmentSuccess(false);
      }, 10000);
      if (!shipmentSuccess) {
      }
      if (!shipmentSuccess) {
        setOpenModal(false);

        setModify(!modify);
      }
    });
  };
  const deletingShipment = () => {
    setShipmentLoading(true);
    deleteShipment(shipmentId).then((x) => {
      setShipmentLoading(false);
      // if (x.status === 201 || x.status === 202 || x.status === 203) {
      setShipmentSuccess(true);
      setTimeout(() => {
        setShipmentSuccess(false);
      }, 2000);

      setOpenDeleteModal(false);
      setModify(!modify);

      setShipmentId(null);
    });
  };

  return (
    <div
      style={{
        // minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container padding={1} rowSpacing={2} paddingTop={2.5}>
        <Grid item xs={5} className={classes["title"]}>
          Delivery Section
        </Grid>
        <Grid item container xs={7} direction="row-reverse">
          <Grid item xs={6} sm={4} md={1.5}>
            <CustomSelect
              value={factoryFilter}
              onChange={(option) => {
                setFactoryFilter(option);
                setHint(false);
              }}
            >
              <StyledOption value={0} disabled>
                Factory Name
              </StyledOption>
              {factoriesList?.map((f) => (
                <StyledOption key={f.Id} value={f.Id}>
                  {f.Code}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {hint ? (
            <Grid
              item
              xs={12}
              sm={2}
              className={classes["title"]}
              fontSize={15}
              paddingTop={1.5}
            >
              {disabledD
                ? "You don't have permission to create/update Shipment-DeliveryNote"
                : "Select Factory:"}
            </Grid>
          ) : (
            <></>
          )}
          {factoryFilter ? (
            <Grid item marginTop={0.6}>
              <button
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.fontWeight = "bold";
                }}
                onMouseOut={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
                onClick={() => {
                  setFactoryFilter(0);
                }}
                className={classes["Rectangle-1224"]}
              >
                Clear filters
              </button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          container
          direction="row"
          rowSpacing={1}
          marginBottom={3}
          marginTop={2}
        >
          <Grid item container xs={12} md={2}>
            <Box
              sx={{
                "& > div": {
                  minWidth: 256,
                  height: width > 1300 ? 650 : 290,
                },
                "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                  {
                    width: 256,
                  },
                "& .MuiTypography-caption": {
                  width: 30,
                  margin: 0,
                },
                "& .PrivatePickersSlideTransition-root": {
                  minHeight: 30 * 6,
                },
                '& .PrivatePickersSlideTransition-root [role="row"]': {
                  margin: 0,
                },
                "& .MuiPickersDay-dayWithMargin": {
                  margin: 0,
                },
                "& .MuiPickersDay-root": {
                  width: 30,
                  height: 30,
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={dateSelected}
                  onChange={(newValue) => {
                    setDateSelected(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size={"small"} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>

          <Grid item xs={12} md={9.9} marginLeft={1.5}>
            <Backdrop
              sx={{
                color: "#fff",
                backgroundColor: "transparent",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: "flex",
                flexDirection: "column",
              }}
              open={loading}
            >
              <CircularProgress />
              <Grid
                item
                fontFamily={"Muli"}
                fontSize={15}
                fontWeight={"bold"}
                color={"rgb(25, 118, 210)"}
              >
                Loading Data...
              </Grid>
            </Backdrop>
            <Grid item container direction="row" xs={12}>
              <Grid item xs={12} className={classes["monthYear"]}>
                {new Date(dateSelected).toDateString()}
              </Grid>
              <Grid item container xs={12} direction="row">
                <Grid
                  item
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    let d = moment(dateSelected).subtract(7, "days");
                    d = d.format("llll");
                    setDateSelected(new Date(d));
                  }}
                >
                  <ArrowLeftIcon />
                </Grid>
                <Grid
                  item
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    let d = moment(dateSelected).add(7, "days");
                    d = d.format("llll");
                    setDateSelected(new Date(d));
                  }}
                >
                  <ArrowRightIcon />
                </Grid>
              </Grid>
              {deliveryList?.map((dayList, index) => (
                <>
                  <Grid
                    item
                    container
                    direction="column"
                    key={index}
                    lg={1.7}
                    sm={2.9}
                    marginRight={0.15}
                  >
                    <Grid
                      item
                      className={classes["day"]}
                      padding={1}
                      fontWeight="bold"
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor =
                          "rgba(6, 116, 185, 0.5)";
                        e.target.style.cursor = "pointer";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor = "";
                      }}
                      backgroundColor={
                        new Date(dateSelected).toISOString().slice(0, 10) ===
                        dayList?.Date
                          ? "rgba(6, 116, 185, 0.5)"
                          : ""
                      }
                    >
                      {dayList?.Day}
                    </Grid>
                    <Grid
                      item
                      backgroundColor="#fff"
                      container
                      padding={0.8}
                      lg={1.7}
                      sm={3}
                      rowGap={1.5}
                      minHeight="565px"
                      maxHeight="565px"
                      alignContent="flex-start"
                      overflow="auto"
                    >
                      <Grid item className={classes["date"]} height={25} paddingTop={1}>
                        {dayList?.Date}
                      </Grid>

                      {dayList?.TimeSlots?.map((ts, index) => (
                        <>
                          <Grid item container key={index} borderRadius={5}>
                            <Grid
                              item
                              container
                              className={classes["timeSlot"]}
                              padding={0.5}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                {ts.StartTime.slice(0, 5)} to{" "}
                                {ts.EndTime.slice(0, 5)} <br />
                                Slot Code : {ts.Code}
                              </Grid>
                              <Grid item>
                                <img
                                  src={plusNavy}
                                  width={15}
                                  height={20}
                                  onMouseOver={(e) => {
                                    e.target.style.cursor = "pointer";
                                  }}
                                  onClick={() => {
                                    if (disabledD === false) {
                                      if (factoryFilter !== 0) {
                                        getShipmentInputData(
                                          null,
                                          ts.Id,
                                          dayList?.Date
                                        );

                                        setOpenModal(true);
                                      } else setHint(true);
                                    } else setHint(true);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              className={classes["ShipmentsContainer"]}
                              height={{ xs: "140px", md: "250px" }}
                              overflow="auto"
                              alignContent="flex-start"
                            >
                              {ts.Shipments.length > 0 ? (
                                ts.Shipments?.map((s, index) => (
                                  <Grid
                                    item
                                    key={index}
                                    marginBottom={0.2}
                                    padding={1}
                                  >
                                    <ShipmentCard
                                      disabledD={disabledD}
                                      data={s}
                                      setShipmentId={setShipmentId}
                                      setOpenModal={setOpenModal}
                                      setOpenDeleteModal={setOpenDeleteModal}
                                      factoryFilter={factoryFilter}
                                      setHint={setHint}
                                      getShipmentInputData={
                                        getShipmentInputData
                                      }
                                    />
                                  </Grid>
                                ))
                              ) : (
                                <Grid
                                  item
                                  className={classes["noShipmentMessage"]}
                                  container
                                  justifyContent="center"
                                  marginTop={{ xs: 5, md: 10 }}
                                >
                                  No Shipments created <br />
                                  in this slot
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <ShipmentModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shipmentId={shipmentId}
          setShipmentId={setShipmentId}
          date={date}
          setDate={setDate}
          timeSlotCode={timeSlotCode}
          setTimeSlotCode={setTimeSlotCode}
          timeSlots={timeSlots}
          shipmentNb={shipmentNb}
          setShipmentNb={setShipmentNb}
          driverId={driverId}
          setDriverId={setDriverId}
          driversList={driversList}
          truckId={truckId}
          setTruckId={setTruckId}
          trucksList={trucksList}
          trailerId={trailerId}
          setTrailerId={setTrailerId}
          trailersList={trailersList}
          loads={loads}
          setLoads={setLoads}
          salesOrdersCodes={salesOrdersCodes}
          setSalesOrdersCodes={setSalesOrdersCodes}
          creatingShipment={creatingShipment}
          updatingShipment={updatingShipment}
          shipmentLoading={shipmentLoading}
          shipmentSuccess={shipmentSuccess}
          modalLoading={modalLoading}
        />
        <DeleteModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          shipmentId={shipmentId}
          setShipmentId={setShipmentId}
          deletingShipment={deletingShipment}
          shipmentLoading={shipmentLoading}
          shipmentSuccess={shipmentSuccess}
        />
      </Grid>
    </div>
  );
}
