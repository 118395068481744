import React, { useState, useEffect } from "react";
import * as incstr from "incstr";
import { exportToBlob } from "@excalidraw/excalidraw";
import {
  Grid,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  MenuItem,
  IconButton,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import RemoveIcon from "../../../Assets/Images/SalesOrder/RemoveIcon.png";
import classes from "../../../Assets/Styles/Sales-Order.module.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import * as SALESORDERDCONSTS from "../../../../salesOrderConst";
import SyncIcon from "../../../Assets/Images/SalesOrder/Sync.png";
import AddProductIcon from "../../../Assets/Images/SalesOrder/addProduct.png";
import CircularProgress from "@mui/material/CircularProgress";
import ExcalidrawModal from "./ExcalidrawModal";
import CautionIcon from "../../../Assets/Images/SalesOrder/CautionIcon.png";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import ErrorModal from "../../../SharedComponents/ErrorModal";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const uploadFile = salesOrderServices.uploadFile;
const deleteFile = salesOrderServices.deleteFile;
const calculateActualLength = salesOrderServices.calculateActualLength;
const getDataSceneById = salesOrderServices.getDataSceneById;

var mexp = require("math-expression-evaluator");
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);
const StyledTableSubRow = withStyles((theme) => ({
  root: {
    height: 120,
  },
}))(TableRow);
const SalesOrderMemberTable = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [syncingImage, setSyncingImage] = useState(false);
  const [chosenId, setChosenId] = useState(false);
  const [chosenMemberId, setChosenMemberId] = useState(null);

  const [open, setOpen] = useState(false);
  const [lineToAddproductOn, setLineToAddproductOn] = useState(null);
  const [lineToChange, setLineToChange] = useState(null);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = ({ key }) => {
      if (key === targetKey) setKeyPressed(true);
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) setKeyPressed(false);
    };

    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);

      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []);

    return keyPressed;
  };
  const F9Pressed = useKeyPress("F9");
  const F8Pressed = useKeyPress("F8");

  useEffect(() => {
    if (F9Pressed && !props.disabledFromActivity && !props.disabledAllFields) {
      var existingMembers = props.members;
      props.setMembers(
        existingMembers.concat({
          OrderId: props.members.length + 1,
          Quantity: null,
          Name: null,
          TypeId: 1,
          BarMarkTypeId: 1,
          ServiceId: props.servicesTypes[0]?.Id,
          Lines: [],
        })
      );
    }
  }, [F9Pressed]);
  useEffect(() => {
    if (F8Pressed && !props.disabledFromActivity && !props.disabledAllFields) {
      props.members[
        props.memberIdSelected === 0 || !props.memberIdSelected
          ? props.members?.length - 1
          : props.memberIdSelected - 1
      ]?.Lines.push({
        OrderId:
          props.members[
            props.memberIdSelected === 0 || !props.memberIdSelected
              ? props.members?.length - 1
              : props.memberIdSelected - 1
          ].Lines.length + 1,
        BarMarkNo:
          props.members[
            props.memberIdSelected === 0 || !props.memberIdSelected
              ? props.members?.length - 1
              : props.memberIdSelected - 1
          ]?.BarMarkTypeId === 1
            ? props.members[
              props.memberIdSelected === 0 || !props.memberIdSelected
                ? props.members?.length - 1
                : props.memberIdSelected - 1
            ].Lines.length === 0
              ? 1
              : +props.members[
                props.memberIdSelected === 0 || !props.memberIdSelected
                  ? props.members?.length - 1
                  : props.memberIdSelected - 1
              ].Lines[
                props.members[
                  props.memberIdSelected === 0 || !props.memberIdSelected
                    ? props.members?.length - 1
                    : props.memberIdSelected - 1
                ].Lines.length - 1
              ].BarMarkNo + 1
            : props.members[
              props.memberIdSelected === 0 || !props.memberIdSelected
                ? props.members?.length - 1
                : props.memberIdSelected - 1
            ]?.BarMarkTypeId === 2
              ? props.members[
                props.memberIdSelected === 0 || !props.memberIdSelected
                  ? props.members?.length - 1
                  : props.memberIdSelected - 1
              ].Lines.length === 0
                ? "A"
                : incstr(
                  props.members[
                    props.memberIdSelected === 0 || !props.memberIdSelected
                      ? props.members?.length - 1
                      : props.memberIdSelected - 1
                  ].Lines[
                    props.members[
                      props.memberIdSelected === 0 || !props.memberIdSelected
                        ? props.members?.length - 1
                        : props.memberIdSelected - 1
                    ].Lines.length - 1
                  ].BarMarkNo,
                  "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                )
              : null,
        BarsQuantity: null,
        TotalNumberOfBars: null,
        BarDiameter: null,
        ShapeCode: null,
        ShapeName: null,
        FormerDiameter: null,
        SecondDiameter: null,
        ActualLength: 0,
        TheoreticalLength: null,
        ActualWeight: 0,
        TheoreticalWeight: null,
        WeightPerMeter: null,
        Products: [],
        Shape: null,
        ShapeBlob: null,
        FileId: null,
        TypeId: null,
        SubCategoryId: null,
        TreatmentId: null,
        GradeId: null,
        ProductId: null,
        GradeDiameterId: null,
        Threads: [],
        ProvidedLength: null,
        ProducedQuantity: null,
      });
      forceUpdate();
    }
  }, [F8Pressed]);
  const sumOfWeightPerMember = (LinesToCalculateWeight) => {
    let sum = 0;
    for (let i = 0; i < LinesToCalculateWeight.length; i++) {
      sum = sum + LinesToCalculateWeight[i].ActualWeight;
    }
    // props.setSalesOrderWeightTheoretical(!sum ? 0 : sum);
    return !sum ? 0 : sum;
  };
  const syncShape = async (LineToSync) => {
    setSyncingImage(true);
    getDataSceneById(
      LineToSync.Shape.Id,
      // LineToSync.Id,
      null,
      props.factoryId
    ).then(async (x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
        setSyncingImage(false);

      } else {
        let dataScene = JSON.parse(x.DataScene);
        dataScene.elements.map((tobeChangedEl) => {
          if (tobeChangedEl.type === "text") {
            let displayedElement = LineToSync.Shape.Elements.find(
              (x) => x.Name === tobeChangedEl.originalText
            );
            if (
              displayedElement.MeasureTypeId ===
              SALESORDERDCONSTS.MEASURE_CALCULATED
            ) {
              let measureFuction = structuredClone(
                displayedElement.MeasureFunction.split(" ").join("")
              );

              LineToSync.Shape.Elements.map((elToReplaceIn) => {
                measureFuction = measureFuction.replace(
                  elToReplaceIn.Name,
                  JSON.stringify(elToReplaceIn.MeasureValue)
                );
              });

              measureFuction = measureFuction.replace(/arcsin/gm, "asin");
              measureFuction = measureFuction.replace(/arccos/gm, "acos");

              let evaluationResult = Math.round(mexp.eval(measureFuction.trim()));
              tobeChangedEl.text = JSON.stringify(evaluationResult);
              displayedElement.MeasureValue = evaluationResult;
            } else {
              tobeChangedEl.text = JSON.stringify(displayedElement.MeasureValue);
            }
          }
        });
        LineToSync.Shape.DataScene = JSON.stringify(dataScene);

        const blob = await exportToBlob({
          elements: dataScene.elements,
          mimeType: "image/png",
          appState: dataScene.appState,
          files: dataScene.files,
        });
        // if (LineToSync.FileId) {
        //   deleteFile(LineToSync.FileId, props.projectId);
        // }

        uploadFile(blob, props.projectId).then((x2) => {

          if (x2.status || x2.statusCode) {
            if (x2.status) setBEerror(x2.error)
            if (x2.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
            setOpenErrorModal(true);
            setSyncingImage(false);

          } else {
            LineToSync.FileId = x2.FileId;
            LineToSync.ShapeBlob = x2.URL;
            let ShapeWOAnno = structuredClone(LineToSync.Shape);
            ShapeWOAnno.Elements = ShapeWOAnno.Elements.filter(
              (ElType) => ElType.ShapeElementId !== 4
            );
            calculateActualLength(ShapeWOAnno).then((x3) => {
              LineToSync.ActualLength = x3.actualLength;
              LineToSync.TheoreticalLength = x3.theoreticalLength;
              LineToSync.WeightPerMeter = +(
                Math.PI *
                Math.pow(+LineToSync.BarDiameter / 2, 2) *
                0.00785
              ).toFixed(3);
              LineToSync.ActualWeight =
                LineToSync.TotalNumberOfBars *
                LineToSync.WeightPerMeter *
                (LineToSync.ActualLength / 1000);
              LineToSync.TheoreticalWeight =
                LineToSync.TotalNumberOfBars *
                LineToSync.WeightPerMeter *
                (LineToSync.TheoreticalLength / 1000);
              forceUpdate();
            });
          }
        });
        setSyncingImage(false);
      }


    });
  };

  const checkItemsAdded = (line, libraryItems) => {
    console.log("current Elements", line.shape.elements)
    const addedProducts = [];

    const array1 = line.shape.elements;
    const array2 = [];

    libraryItems?.map(item => {
      array2.push(item.elements?.find((e) => e.type === 'text'))
    });
    let tmpPr = [];
    array1?.map((item1) => {
      let tmp = array2.find((item2) => (item2.type === item1.type) && (item2.originalText === item1.originalText))
      if (tmp) {
        if (tmp.originalText === 'CP') {
          let productObject = {
            Id: null,
            SpliceTypeId: null,
            ProductId: null,
          }
          tmpPr.push(productObject)
        }
        if (tmp.originalText === 'Thr') {
          let productObject = {
            Id: null,
            SpliceTypeId: null,
            ProductId: line.ProductId,
          }
          tmpPr.push(productObject)
        }

      }
    })
    console.log("tmppR", tmpPr)
    addedProducts = tmpPr
    if (tmpPr.length === 0) {
      props.lineToChange.Products = addedProducts;
      forceUpdate();
    }

  }

  return (
    <>
      <Grid
        container
        paddingTop={2}
        paddingBottom={10}
        paddingLeft={3}
        gap={2}
        paddingRight={3}
      >
        <Grid container item xs={12} direction={"row"}>
          <Grid item xs={6}>
            <button
              className={classes["AddMemberButton"]}
              disabled={props.disabledFromActivity || props.disabledAllFields}
              onClick={() => {
                let existingMembers = props.members;
                props.setMembers(
                  existingMembers.concat({
                    OrderId: props.members.length + 1,
                    Quantity: null,
                    Name: null,
                    TypeId: 1,
                    BarMarkTypeId: 1,
                    ServiceId: props.servicesTypes[0]?.Id,
                    Lines: [],
                  })
                );
              }}
            >
              Add Member (F9)
            </button>
          </Grid>
          <Grid item xs={6} container direction={"row-reverse"}>
            <Grid item md={2.5} sm={5} xs={6}>
              <button
                disabled={props.disabledFromActivity || props.disabledAllFields}
                className={classes["AddMemberButton"]}
                onClick={() => {
                  props.members[
                    props.memberIdSelected === 0 || !props.memberIdSelected
                      ? props.members?.length - 1
                      : props.memberIdSelected - 1
                  ].Lines.push({
                    OrderId:
                      props.members[
                        props.memberIdSelected === 0 || !props.memberIdSelected
                          ? props.members?.length - 1
                          : props.memberIdSelected - 1
                      ].Lines.length + 1,
                    BarMarkNo:
                      props.members[
                        props.memberIdSelected === 0 || !props.memberIdSelected
                          ? props.members?.length - 1
                          : props.memberIdSelected - 1
                      ]?.BarMarkTypeId === 1
                        ? props.members[
                          props.memberIdSelected === 0 ||
                            !props.memberIdSelected
                            ? props.members?.length - 1
                            : props.memberIdSelected - 1
                        ].Lines.length === 0
                          ? 1
                          : +props.members[
                            props.memberIdSelected === 0 ||
                              !props.memberIdSelected
                              ? props.members?.length - 1
                              : props.memberIdSelected - 1
                          ].Lines[
                            props.members[
                              props.memberIdSelected === 0 ||
                                !props.memberIdSelected
                                ? props.members?.length - 1
                                : props.memberIdSelected - 1
                            ].Lines.length - 1
                          ].BarMarkNo + 1
                        : props.members[
                          props.memberIdSelected === 0 ||
                            !props.memberIdSelected
                            ? props.members?.length - 1
                            : props.memberIdSelected - 1
                        ]?.BarMarkTypeId === 2
                          ? props.members[
                            props.memberIdSelected === 0 ||
                              !props.memberIdSelected
                              ? props.members?.length - 1
                              : props.memberIdSelected - 1
                          ].Lines.length === 0
                            ? "A"
                            : incstr(
                              props.members[
                                props.memberIdSelected === 0 ||
                                  !props.memberIdSelected
                                  ? props.members?.length - 1
                                  : props.memberIdSelected - 1
                              ].Lines[
                                props.members[
                                  props.memberIdSelected === 0 ||
                                    !props.memberIdSelected
                                    ? props.members?.length - 1
                                    : props.memberIdSelected - 1
                                ].Lines.length - 1
                              ].BarMarkNo,
                              "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                            )
                          : null,
                    BarsQuantity: null,
                    TotalNumofBars: null,
                    BarDiameter: null,
                    ShapeCode: null,
                    ShapeName: null,
                    FormerDiameter: null,
                    SecondDiameter: null,
                    ActualLength: 0,
                    TheoreticalLength: null,
                    ActualWeight: 0,
                    TheoreticalWeight: null,
                    WeightPerMeter: null,
                    Products: [],
                    Shape: null,
                    ShapeBlob: null,
                    FileId: null,
                    TypeId: null,
                    // SubCategoryId: null,
                    // ProductTreatment: null,
                    // ProductId: null,
                    GradeDiameterId: null,
                    SubCategoryId: null,
                    TreatmentId: null,
                    GradeId: null,
                    ProductId: null,
                    Threads: null,
                    ProvidedLength: null,
                    ProducedQuantity: null,

                  });
                  forceUpdate();
                }}
              >
                Line (F8)
              </button>
            </Grid>
            <Grid item md={3} sm={5} xs={6}>
              <TextField
                variant="standard" // <== changed this
                margin="normal"
                value={props.memberIdSelected}
                sx={styles.tfInput}
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                onChange={(e) => {
                  props.setMemberIdSelected(e.target.value);
                }}
                placeholder="Qty"
                InputProps={{
                  disableUnderline: true,
                  style: styles.tfInput,
                }}
              >
                <MenuItem value={0} disabled>
                  Member Name
                </MenuItem>
                {props.members?.map((r, index) => (
                  <MenuItem value={index + 1} key={r.Name}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item
          style={{
            width: "100%",
            overflowX: "auto"
          }}>
          <TableContainer sx={{ maxHeight: '650px', overflowX: 'auto', width: "max-content" }} >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0px 0px",
                border: 0,
              }}>
              {/* <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableCellEmpty}></TableCell>
                  <TableCell sx={styles.tableCellEmpty}></TableCell>
                  <TableCell sx={styles.tableCell}>
                    *Bar Mark
                    <br />
                    No.
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    Shape <br />
                    Code
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    Shape <br />
                    Name
                  </TableCell>



                  <TableCell sx={styles.tableCellImage}></TableCell>
                  <TableCell sx={styles.tableCell}>Product <br />Category</TableCell>
                  <TableCell sx={styles.tableCell}>Product <br />Subcategory</TableCell>
                  <TableCell sx={styles.tableCell}>Product <br />Sub 1</TableCell>
                  <TableCell sx={styles.tableCell}>Product <br />Item Series</TableCell>
                  <TableCell sx={styles.tableCell}>Dia</TableCell>
                  <TableCell sx={styles.tableCell}>Second Dia</TableCell>
                  <TableCell sx={styles.tableCell}>Provided Length</TableCell>
                  <TableCell sx={styles.tableCell}>Length to Produce</TableCell>
                  <TableCell sx={styles.tableCellQty}>
                    *Qty <br />
                    <Grid item className={classes["SubTitle"]} paddingTop={1}>
                      Total No.
                      <br /> of Bars
                    </Grid>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    Quantity to produce
                  </TableCell>
                  <TableCell sx={styles.tableCell}>Weight</TableCell>

                  <TableCell sx={styles.tableCell}> Ductility <br />Requirement</TableCell>

                  <TableCell sx={styles.tableCell}>Remark</TableCell>
                  {props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                    <TableCell sx={styles.tableCell}>Status</TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead> */}

              {props.members.map((member) => (
                <>
                  <TableHead key={member.OrderId} sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 2 }}>
                    <StyledTableRow sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 2 }}>
                      <TableCell sx={styles.tableSubCell}>
                        <Grid
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            if (
                              !props.disabledFromActivity &&
                              !props.disabledAllFields
                            ) {
                              let existingMembersToChange = props.members;

                              props.setMembers(
                                existingMembersToChange.filter(
                                  (x) => x.OrderId !== member.OrderId
                                )
                              );

                              if (member.Lines.length > 0) {
                                member.Lines.map((li) => {
                                  if (li.Threads?.length === 1 && li.Threads[0] !== -1) props.setThreadCount((props.threadCount - li.TotalNumberOfBars))
                                  if (li.Threads?.length === 2) {
                                    if (li.Threads[0] !== -1 && (li.Threads[1] !== -1))
                                      props.setThreadCount((props.threadCount - (li.TotalNumberOfBars * 2)))
                                    else if (li.Threads[0] !== -1)
                                      props.setThreadCount((props.threadCount - li.TotalNumberOfBars))
                                    else if (li.Threads[1] !== -1)
                                      props.setThreadCount((props.threadCount - li.TotalNumberOfBars))
                                  }

                                  if (li.Products?.length === 1 && li.Products[0].SpliceTypeId) props.setThreadCount((props.threadCount - li.TotalNumberOfBars))
                                  if (li.Products?.length === 2) {
                                    console.log('testttt')
                                    if (li.Products[0].SpliceTypeId && li.Products[0].SpliceTypeId)
                                      props.setThreadCount((props.threadCount - (li.TotalNumberOfBars * 2)))
                                    else if (li.Products[0].SpliceTypeId)
                                      props.setThreadCount((props.threadCount - li.TotalNumberOfBars))
                                    else if (li.Products[1].SpliceTypeId)
                                      props.setThreadCount((props.threadCount - li.TotalNumberOfBars))

                                  }

                                  if (li.ProductId) {
                                    const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(li.TypeId))?.Grades?.find((g) => g.Id === li.ProductId)?.Name;
                                    props.setProductCount(prev =>
                                      prev.map(item => {
                                        if (item.GradeId === oldProductName) {
                                          return { ...item, Count: +(item.Count - (+li.BarsQuantity * +member.Quantity)) };
                                        }
                                        return item;
                                      })
                                    );
                                  }

                                  if (li.Products) {
                                    li.Products?.map((pr) => {
                                      const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Name;
                                      props.setProductCount(prev =>
                                        prev.map(item => {
                                          if (item.GradeId === oldProductName) {
                                            return { ...item, Count: +(item.Count - (+li.BarsQuantity * +member.Quantity)) };
                                          }
                                          return item;
                                        })
                                      );
                                      //if (pr.SpliceTypeId) props.setThreadCount((props.threadCount - li.TotalNumberOfBars))

                                    })

                                  }
                                })
                              }
                            }
                            if (props.members?.find((m) => (m.ServiceId === 1 || m.ServiceId === 2))) props.setCanSaveAsDraft(true)
                            else props.setCanSaveAsDraft(false)
                          }}
                        >
                          <img src={RemoveIcon} alt={"Remove"} />
                        </Grid>


                      </TableCell>
                      <TableCell sx={styles.tableSubCell}>
                        {
                          <Grid item container gap={1}>
                            <Grid item xs={6}>
                              {member.OrderId}
                            </Grid>

                            {!member.RemainingQuantity &&
                              props.activityCode &&
                              props.status === SALESORDERDCONSTS.PLACED_ID ? (
                              <Grid item color={"#0674b9"} xs={6}>
                                Placed
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        }
                      </TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}>
                        {props.activityCode && member.RemainingQuantity > 0 ? (
                          <Grid container direction={"row"}>
                            <Grid item xs={4}>
                              <TextField
                                size="small"
                                variant="standard" // <== changed this
                                margin="normal"
                                disabled={
                                  props.disabledAllFields &&
                                    member.RemainingQuantity === 0
                                    ? true
                                    : false
                                }
                                fullWidth
                                name="Qunatity"
                                value={member.Quantity}
                                type={"number"}
                                sx={styles.tfInput}
                                onChange={(e) => {
                                  member.Quantity = +e.target.value;

                                  forceUpdate();
                                }}
                                placeholder="Qty"
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput, // <== added this
                                  inputProps: {
                                    min: 0,
                                    max: member.RemainingQuantity,
                                  },
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={8}
                              paddingTop={1.1}
                              container
                              paddingLeft={1}
                            >
                              of
                              {member.TotalQuantity}
                              <Grid item xs={12} spacing={1}>
                                Remaining:
                                {member.RemainingQuantity - member.Quantity < 0
                                  ? "Please decrease the quantity"
                                  : member.RemainingQuantity - member.Quantity}
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <TextField
                            variant="standard" // <== changed this
                            margin="normal"
                            fullWidth
                            name="Quantity"
                            disabled={props.disabledAllFields}
                            value={member.Quantity}
                            type={"number"}
                            sx={styles.tfInput}
                            onChange={(e) => {
                              const oldMemberQty = member.Quantity;

                              member.Quantity = +e.target.value;
                              member.Lines.map((liToChangeQuantity) => {
                                if (liToChangeQuantity.BarsQuantity) {
                                  liToChangeQuantity.TotalNumberOfBars =
                                    liToChangeQuantity.BarsQuantity * e.target.value;
                                }
                              });

                              let tempCountThread = props.threadCount;

                              member.Lines?.map((l, lIndex) => {
                                console.log("check line ", l)

                                if (l.Threads) {
                                  l.Threads?.map((th, thIndex) => {
                                    if (th !== -1) tempCountThread = tempCountThread - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)
                                    if (thIndex === l.Threads.length - 1) props.setThreadCount(tempCountThread)
                                  })
                                }


                                // if (l.Threads?.length === 1 && l.Threads[0] !== -1) {
                                //   console.log("mem qty updated, thread length 1 and [0]")
                                //   props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))
                                // }
                                // if (l.Threads?.length === 2) {
                                //   console.log("mem qty updated, thread length 2")
                                //   if (l.Threads[0] !== -1 && (l.Threads[1] !== -1)) {
                                //     console.log(" both * 2")
                                //     console.log("l.BarsQuantity",l.BarsQuantity)
                                //     console.log("oldMemberQty",oldMemberQty)
                                //     console.log("old thread",props.threadCount)
                                //     console.log("new",(props.threadCount - (oldMemberQty * +l.BarsQuantity * 2) + (+e.target.value * +l.BarsQuantity * 2)))
                                //     props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity * 2) + (+e.target.value * +l.BarsQuantity * 2)))
                                //     forceUpdate()


                                //   }
                                //   else if (l.Threads[0] !== -1) {
                                //     console.log("and [0]")

                                //     props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))


                                //   }
                                //   else if (l.Threads[1] !== -1) {
                                //     console.log("and [1]")
                                //     props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))
                                //   }

                                // }

                                // if (l.Products?.length === 1 && l.Products[0].SpliceTypeId) {
                                //   console.log("check p ", l)
                                //   console.log("index", lIndex)
                                //   console.log("check", (props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))
                                //   props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))
                                // }
                                // if (l.Products?.length === 2) {
                                //   console.log('testttt')
                                //   if (l.Products[0].SpliceTypeId && l.Products[1].SpliceTypeId)
                                //     props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity * 2) + (+e.target.value * +l.BarsQuantity * 2)))
                                //   else if (l.Products[0].SpliceTypeId)
                                //     props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))
                                //   else if (l.Products[1].SpliceTypeId)
                                //     props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))

                                // }

                                if (l.ProductId) {
                                  const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(l.TypeId))?.Grades?.find((g) => g.Id === l.ProductId)?.Name;
                                  props.setProductCount(prev =>
                                    prev.map(item => {
                                      if (item.GradeId === oldProductName) {
                                        return {
                                          ...item, Count: (item.Count - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity))
                                        };
                                      }
                                      return item;
                                    })
                                  );
                                }

                                if (l.Products) {
                                  l.Products?.map((pr, prIndex) => {
                                    // if (pr.SpliceTypeId) props.setThreadCount((props.threadCount - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)))
                                    if (pr.SpliceTypeId) tempCountThread = tempCountThread - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity)
                                    if (prIndex === l.Products.length - 1) props.setThreadCount(tempCountThread)
                                    const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Name;
                                    props.setProductCount(prev =>
                                      prev.map(item => {
                                        if (item.GradeId === oldProductName) {
                                          return {
                                            ...item, Count: (item.Count - (oldMemberQty * +l.BarsQuantity) + (+e.target.value * +l.BarsQuantity))
                                          };
                                        }
                                        return item;
                                      })
                                    );
                                  })

                                }

                                if (member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID && l.ShapeName !== 'Couplers and accessories') {
                                  l.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(l.BarDiameter))?.WeightPerMeter) * l.ActualLength / 1000 * l.BarsQuantity * member.Quantity);
                                  l.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(l.BarDiameter))?.WeightPerMeter) * l.ActualLength / 1000 * l.BarsQuantity * member.Quantity);
                                }

                                if (member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID) {
                                  l.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(l.BarDiameter))?.WeightPerMeter) * l.ActualLength / 1000 * l.BarsQuantity * member.Quantity);
                                  l.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(l.BarDiameter))?.WeightPerMeter) * l.ActualLength / 1000 * l.BarsQuantity * member.Quantity);
                                }
                              })


                              forceUpdate();
                            }}
                            placeholder="Qty"
                            InputProps={{
                              disableUnderline: true,
                              style: styles.tfInput, // <== added this
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      </TableCell>

                      {/* <TableCell sx={styles.tableSubCell}></TableCell> */}

                      <TableCell sx={styles.tableSubImage2}>
                        <Grid container>
                          <Grid item xs={3} className={classes["subsubTitiles"]}>
                            <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              value={member.Name}
                              sx={styles.tfInput2}
                              disabled={
                                props.disabledFromActivity || props.disabledAllFields
                              }
                              onChange={(e) => {
                                member.Name = e.target.value;
                                forceUpdate();
                              }}
                              placeholder="Member Name"
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} className={classes["subsubTitiles"]}>
                            <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              name="Service Type"
                              value={member.ServiceId}
                              select
                              disabled={props.disabledAllFields}
                              sx={styles.tfInput2}
                              onChange={(e) => {
                                member.ServiceId = e.target.value;
                                member.Lines = [];
                                forceUpdate();
                                if (e.target.value === 1 || e.target.value === 2 || props.members?.find((m) => (m.ServiceId === 1 || m.ServiceId === 2))) props.setCanSaveAsDraft(false)
                                else props.setCanSaveAsDraft(true)
                              }}
                              SelectProps={{ MenuProps: { disableScrollLock: true } }}
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            >
                              <MenuItem value={0} disabled>
                                Service Type
                              </MenuItem>
                              {props.servicesTypes.map((r) => (
                                <MenuItem value={r.Id} key={r.Id}>
                                  {r.Name === "CUT" ? "Cut to Length Service" : r.Name === "THREAD" ? "Threading Service" :
                                    r.Name === "BENDCUT" ? "Cut & Bend Service" : ""}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={3} className={classes["subsubTitiles"]}>
                            <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              name="Member Type"
                              value={member.TypeId}
                              select
                              disabled={props.disabledAllFields}
                              sx={styles.tfInput2}
                              onChange={(e) => {
                                member.TypeId = e.target.value;
                                forceUpdate();
                              }}
                              SelectProps={{ MenuProps: { disableScrollLock: true } }}
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            >
                              <MenuItem value={0} disabled>
                                Member Type
                              </MenuItem>
                              {props.memberTypes.map((r) => (
                                <MenuItem value={r.Id} key={r.Id}>
                                  {r.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={3} className={classes["subsubTitiles"]}>
                            <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              name="Bar Mark"
                              value={member.BarMarkTypeId}
                              select
                              disabled={props.disabledAllFields}
                              sx={styles.tfInput2}
                              onChange={(e) => {
                                member.BarMarkTypeId = e.target.value;
                                member?.Lines.map((liToEnum, index) => {
                                  liToEnum.BarMarkNo =
                                    e.target.value === 1
                                      ? index + 1
                                      : e.target.value === 2
                                        ? index === 0
                                          ? "A"
                                          : incstr(
                                            member?.Lines[index - 1].BarMarkNo,
                                            "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                                          )
                                        : null;
                                });
                                forceUpdate();
                              }}
                              SelectProps={{ MenuProps: { disableScrollLock: true } }}
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            >
                              <MenuItem value={0} disabled>
                                Bar Mark
                              </MenuItem>
                              {props.barMarkTypes.map((r) => (
                                <MenuItem value={r.Id} key={r.Id}>
                                  {r.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      {member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID ?
                        <>
                          <TableCell sx={styles.tableSubCell}></TableCell>
                          <TableCell sx={styles.tableSubCell}></TableCell>

                        </>
                        :
                        member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                          <>
                            <TableCell sx={styles.tableSubCell}></TableCell>
                            <TableCell sx={styles.tableSubCell}></TableCell>
                            <TableCell sx={styles.tableSubCell}></TableCell>
                            <TableCell sx={styles.tableSubCell}></TableCell>
                          </>
                          :
                          <></>
                      }
                      {/* <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell>
                    <TableCell sx={styles.tableSubCell}></TableCell> */}



                      <TableCell sx={styles.tableSubCell}>
                        {/* {member.ServiceId === 3 ?
                          <> */}
                        {sumOfWeightPerMember(member?.Lines)?.toFixed(2)}
                        {/* </>
                          :
                          <></>} */}

                      </TableCell>

                      {props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                        <TableCell sx={styles.tableSubCell}></TableCell>
                      ) : (
                        <></>
                      )}
                    </StyledTableRow>

                    <TableRow sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                      <TableCell sx={styles.tableCellEmpty}></TableCell>
                      <TableCell sx={styles.tableCellEmpty}></TableCell>
                      <TableCell sx={styles.tableCell}>
                        *Bar Mark
                        <br />
                        No.
                      </TableCell>
                      {member.ServiceId === SALESORDERDCONSTS.CUT_AND_BEND_SERVICE_ID ?
                        <TableCell sx={styles.tableCell}>
                          Shape <br />
                          Code
                        </TableCell>
                        :
                        <TableCell sx={styles.tableCell}>
                          Shape <br />
                          Name
                        </TableCell>

                      }




                      <TableCell sx={styles.tableCellImage}></TableCell>
                      {member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                        <>
                          <TableCell sx={styles.tableCell}>Product <br />Category</TableCell>
                          {/* <TableCell sx={styles.tableCell}>Product <br />Subcategory</TableCell> */}
                          {/* <TableCell sx={styles.tableCell}>Product <br />Sub 1</TableCell> */}
                          <TableCell sx={styles.tableCell}>Product <br />Item Series</TableCell>
                        </>

                        :
                        <></>
                      }
                      <TableCell sx={styles.tableCell}>Dia</TableCell>
                      {member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                        <TableCell sx={styles.tableCell}>Second Dia</TableCell>
                        :
                        <></>
                      }
                      {member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID ?

                        <TableCell sx={styles.tableCell}>Provided Length</TableCell>
                        :
                        <></>

                      }
                      {/* {member.ServiceId !== SALESORDERDCONSTS.THREAD_SERVICE_ID ? */}
                      <TableCell sx={styles.tableCell}>Length to Produce</TableCell>
                      {/* :
                      <></>
                    } */}

                      {member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID ?

                        <TableCell sx={styles.tableCell}>
                          Initial Quantity
                        </TableCell>
                        :
                        <></>
                      }

                      <TableCell sx={styles.tableCellQty}>
                        *Qty <br />
                        <Grid item className={classes["SubTitle"]} paddingTop={1}>
                          Total No.
                          <br /> of Bars
                        </Grid>
                      </TableCell>

                      <TableCell sx={styles.tableCell}>Weight</TableCell>
                      {member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                        <TableCell sx={styles.tableCell}> Ductility <br />Requirement</TableCell>
                        :
                        <></>
                      }

                      <TableCell sx={styles.tableCell}>Remark</TableCell>
                      {props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                        <TableCell sx={styles.tableCell}>Status</TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      maxHeight: '400px', // Set the max height for vertical scrolling
                      overflowY: 'auto',  // Enables vertical scrolling

                    }}>
                    {member.Lines.map((Line, index) => (
                      <StyledTableSubRow key={Line.OrderId}>
                        <TableCell sx={styles.tableSubSubCell}></TableCell>
                        <TableCell sx={styles.tableSubSubCell}>
                          <Grid
                            item
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            onClick={() => {
                              if (
                                (!props.disabledFromActivity &&
                                  !props.disabledAllFields) ||
                                Line.Shape === null
                              ) {
                                console.log("line to remove", Line)

                                if (Line.Threads?.length === 1 && Line.Threads[0] !== -1) {
                                  console.log("thread check length 1")
                                  props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                }
                                if (Line.Threads?.length === 2) {
                                  console.log("thread check length 2")
                                  if (Line.Threads[0] !== -1 && (Line.Threads[1] !== -1)) {
                                    console.log("thread check remove * 2", Line.TotalNumberOfBars, " which is ", (Line.TotalNumberOfBars * 2))
                                    props.setThreadCount((props.threadCount - (Line.TotalNumberOfBars * 2)))
                                  }
                                  else if (Line.Threads[0] !== -1) {
                                    console.log("thread check remove [0]")
                                    props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                  }
                                  else if (Line.Threads[1] !== -1) {
                                    console.log("thread check remove [1]")

                                    props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                  }
                                }

                                if (Line.Products?.length === 1 && Line.Products[0].SpliceTypeId) {
                                  console.log("product check length 1")
                                  props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                }
                                if (Line.Products?.length === 2) {
                                  console.log("product check length 2")
                                  if (Line.Products[0].SpliceTypeId && Line.Products[1].SpliceTypeId) {
                                    console.log("product check remove * 2")

                                    props.setThreadCount((props.threadCount - (Line.TotalNumberOfBars * 2)))
                                  }
                                  else if (Line.Products[0].SpliceTypeId) {
                                    console.log("product check remove  [0]")

                                    props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                  }
                                  else if (Line.Products[1].SpliceTypeId) {
                                    console.log("product check remove [1]")

                                    props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                  }

                                }

                                if (Line.ProductId) {
                                  const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Name;
                                  props.setProductCount(prev =>
                                    prev.map(item => {
                                      if (item.GradeId === oldProductName) {
                                        return { ...item, Count: +(item.Count - (+Line.BarsQuantity * +member.Quantity)) };
                                      }
                                      return item;
                                    })
                                  );
                                }

                                if (Line.Products) {
                                  Line.Products?.map((pr) => {
                                    // if (pr.SpliceTypeId) props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))

                                    const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Name;
                                    props.setProductCount(prev =>
                                      prev.map(item => {
                                        if (item.GradeId === oldProductName) {
                                          return { ...item, Count: +(item.Count - (+Line.BarsQuantity * +member.Quantity)) };
                                        }
                                        return item;
                                      })
                                    );
                                  })

                                }

                                member.Lines = member.Lines.filter(
                                  (lineTocheck) =>
                                    lineTocheck.OrderId !== Line.OrderId
                                );

                                forceUpdate();
                              }
                            }}
                          >
                            <img src={RemoveIcon} alt={"Remove"} />

                          </Grid>
                          <Grid item
                            onMouseOver={(e) => {
                              if (
                                (!props.disabledFromActivity &&
                                  !props.disabledAllFields))
                                e.target.style.cursor = "pointer";
                            }}
                            onClick={() => {
                              if (
                                (!props.disabledFromActivity &&
                                  !props.disabledAllFields) ||
                                Line.Shape === null
                              ) {
                                console.log("line to duplicate", Line)
                                console.log("member", member)
                                const duplicateLine = {
                                  ...Line,
                                  OrderId:
                                    props.members.length > 0
                                      ? props.members[props.members?.length - 1].Lines.length + 1
                                      : 1,
                                  BarMarkNo:
                                    props.members[props.members?.length - 1]?.BarMarkTypeId === 1
                                      ? props.members[props.members?.length - 1].Lines.length === 0
                                        ? 1
                                        : +props.members[props.members?.length - 1].Lines[
                                          props.members[props.members?.length - 1].Lines.length - 1
                                        ].BarMarkNo + 1
                                      : props.members[props.members?.length - 1]?.BarMarkTypeId === 2
                                        ? props.members[props.members?.length - 1].Lines.length === 0
                                          ? "A"
                                          : incstr(
                                            props.members[props.members?.length - 1].Lines[
                                              props.members[props.members?.length - 1].Lines.length - 1
                                            ].BarMarkNo,
                                            "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                                          )
                                        : null,
                                }
                                member.Lines.push(duplicateLine)
                                // member.Lines = member.Lines.filter(
                                //   (lineTocheck) =>
                                //     lineTocheck.OrderId !== Line.OrderId
                                // );

                                if (Line.Threads?.length === 1 && Line.Threads[0] !== -1) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                if (Line.Threads?.length === 2) {
                                  if (Line.Threads[0] !== -1 && (Line.Threads[1] !== -1))
                                    props.setThreadCount((props.threadCount + (Line.TotalNumberOfBars * 2)))
                                  else if (Line.Threads[0] !== -1)
                                    props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                  else if (Line.Threads[1] !== -1)
                                    props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                }

                                if (Line.Products?.length === 1 && Line.Products[0].SpliceTypeId) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                if (Line.Products?.length === 2) {
                                  console.log('testttt')
                                  if (Line.Products[0].SpliceTypeId && Line.Products[1].SpliceTypeId)
                                    props.setThreadCount((props.threadCount + (Line.TotalNumberOfBars * 2)))
                                  else if (Line.Products[0].SpliceTypeId)
                                    props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                  else if (Line.Products[1].SpliceTypeId)
                                    props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))

                                }

                                if (Line.ProductId) {
                                  const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Name;
                                  props.setProductCount(prev =>
                                    prev.map(item => {
                                      if (item.GradeId === oldProductName) {
                                        return { ...item, Count: +(item.Count + (+Line.BarsQuantity * +member.Quantity)) };
                                      }
                                      return item;
                                    })
                                  );
                                }

                                if (Line.Products) {
                                  Line.Products?.map((pr) => {

                                    //if (pr.SpliceTypeId) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))

                                    const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Name;
                                    props.setProductCount(prev =>
                                      prev.map(item => {
                                        if (item.GradeId === oldProductName) {
                                          return { ...item, Count: +(item.Count + (+Line.BarsQuantity * +member.Quantity)) };
                                        }
                                        return item;
                                      })
                                    );
                                  })

                                }

                                forceUpdate();
                              }
                            }}
                          >
                            <ContentCopyIcon />

                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.tableSubSubCell}>
                          {member.BarMarkTypeId === 3 ? (
                            <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              disabled={props.disabledAllFields}
                              fullWidth
                              value={Line.BarMarkNo}
                              sx={styles.tfInput2}
                              onChange={(e) => {
                                Line.BarMarkNo = e.target.value;
                                forceUpdate();
                              }}
                              placeholder="No."
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            />
                          ) : (
                            Line.BarMarkNo
                          )}
                        </TableCell>

                        <TableCell sx={styles.tableSubSubCell}>
                          {member.ServiceId === 3 ?
                            <>
                              {/* Shape code */}
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                value={Line.ShapeCode}
                                disabled={props.disabledAllFields}
                                sx={styles.tfInput2}
                                onChange={(e) => {
                                  Line.ShapeCode = e.target.value;
                                  let Found = false;
                                  props.shapes.map((shape) => {
                                    Line.ShapeBlob = null;
                                    if (shape.Code === e.target.value) {
                                      Found = true;
                                      Line.Shape = structuredClone(shape);
                                      forceUpdate();
                                    } else {
                                      if (!Found) {
                                        Line.Shape = null;
                                      }
                                    }
                                  });
                                  forceUpdate();
                                }}
                                placeholder="Code"
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              />
                            </>
                            :
                            <>
                              {/* Shape Name */}
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                value={Line.ShapeName}
                                disabled={props.disabledAllFields}
                                sx={{
                                  // Ensure the TextField has proper height and aligns content
                                  '& .MuiInputBase-root': {
                                    display: 'flex',
                                    alignItems: 'center',  // Vertically center the content inside the input box
                                    justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                    height: '40px', // Adjust the height as needed for your design
                                    padding: 0, // Remove any default padding if needed
                                  },
                                  // You can also control the text alignment here
                                  ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                                  '& .MuiInputBase-input': {
                                    textAlign: 'center', // Center the text horizontally
                                    padding: '0', // Remove internal padding to avoid misalignment
                                    lineHeight: 'normal', // Adjust line height to ensure vertical centering
                                  }
                                }}
                                onChange={(e) => {
                                  Line.ShapeName = e.target.value;
                                  let Found = false;
                                  props.shapes.map((shape) => {
                                    Line.ShapeBlob = null;
                                    if (shape.Name?.toLowerCase() === e.target.value?.toLowerCase()) {
                                      Found = true;
                                      Line.Shape = structuredClone(shape);
                                      Line.Threads = (e.target.value?.toLowerCase() === 'bet') ? new Array(1)
                                        : e.target.value?.toLowerCase() === 'double bet' ? new Array(2) : null;
                                      Line.Products = (e.target.value?.toLowerCase() === 'bet + coupler' || e.target.value?.toLowerCase() === 'double bet + coupler' || e.target.value?.toLowerCase() === 'bet x2 + coupler') ? [{
                                        Id: null,
                                        SpliceTypeId: null,
                                        TypeId: null,
                                        SubCategoryId: null,
                                        TreatmentId: null,
                                        ProductId: null,
                                      }] :
                                        e.target.value?.toLowerCase() === 'double bet + double coupler' ?
                                          [{
                                            Id: null,
                                            SpliceTypeId: null,
                                            TypeId: null,
                                            SubCategoryId: null,
                                            TreatmentId: null,
                                            ProductId: null,
                                          },
                                          {
                                            Id: null,
                                            SpliceTypeId: null,
                                            TypeId: null,
                                            SubCategoryId: null,
                                            TreatmentId: null,
                                            ProductId: null,
                                          }]
                                          :
                                          [];
                                      forceUpdate();
                                    } else {
                                      if (!Found) {
                                        Line.Shape = null;
                                      }
                                    }
                                  });
                                  forceUpdate();
                                }}
                                placeholder="Name"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    ...styles.tfInput,
                                    textAlign: "center", // Ensure horizontal centering of text
                                    lineHeight: "normal", // Ensure vertical centering of text
                                  },
                                }}
                              />
                            </>
                          }
                        </TableCell>


                        {/* <TableCell sx={styles.tableSubSubCell}>
                        <TextField
                          variant="standard" // <== changed this
                          margin="normal"
                          fullWidth
                          value={Line.ShapeName}
                          disabled={props.disabledAllFields}
                          sx={styles.tfInput2}
                          onChange={(e) => {
                            Line.ShapeName = e.target.value;
                            let Found = false;
                            props.shapes.map((shape) => {
                              Line.ShapeBlob = null;
                              if (shape.Name === e.target.value) {
                                Found = true;
                                Line.Shape = structuredClone(shape);
                                Line.Products = (e.target.value === 'BET + Coupler' || e.target.value === 'Double BET + Coupler' || e.target.value === 'BET x2 + Coupler') ? [{
                                  Id: null,
                                  SpliceTypeId: null,
                                  TypeId: null,
                                  SubCategoryId: null,
                                  TreatmentId: null,
                                  ProductId: null,
                                }] :
                                  e.target.value === 'Double BET + Double Coupler' ?
                                    [{
                                      Id: null,
                                      SpliceTypeId: null,
                                      TypeId: null,
                                      SubCategoryId: null,
                                      TreatmentId: null,
                                      ProductId: null,
                                    },
                                    {
                                      Id: null,
                                      SpliceTypeId: null,
                                      TypeId: null,
                                      SubCategoryId: null,
                                      TreatmentId: null,
                                      ProductId: null,
                                    }]
                                    :
                                    [];
                                forceUpdate();
                              } else {
                                if (!Found) {
                                  Line.Shape = null;
                                }
                              }
                            });
                            forceUpdate();
                          }}
                          placeholder="Name"
                          InputProps={{
                            disableUnderline: true,
                            style: styles.tfInput,
                          }}
                        />
                      </TableCell> */}


                        <TableCell sx={styles.tableSubSubCell}>

                          {Line.Shape ? (
                            <Grid container direction={"column"} gap={2}>
                              {member.ServiceId === 3 ?
                                <Grid item container direction={"row-reverse"} gap={2}>
                                  <Grid
                                    item
                                    onMouseOver={(e) => {
                                      if (!props.disabledAllFields) e.target.style.cursor = "pointer";
                                      else e.target.style.cursor = "unset";
                                    }}
                                    onClick={() => {
                                      if (!props.disabledAllFields) {
                                        if (Line.Id) {
                                          setSyncingImage(true);
                                          setChosenId(Line.OrderId);
                                          setChosenMemberId(member.OrderId);
                                          setLineToChange(Line);
                                          getDataSceneById(
                                            Line.ShapeCode,
                                            Line.Id,
                                            props.factoryId
                                          ).then((x) => {
                                            Line.Shape.DataScene = x.DataScene;
                                            setLineToAddproductOn(
                                              JSON.parse(Line?.Shape.DataScene)
                                            );
                                            console.log("lineeee", Line)
                                            setLineToChange(Line);
                                            // setProducts(x.ShapeProducts);
                                            setOpen(true);
                                            setSyncingImage(false);
                                          });
                                        } else {
                                          setChosenId(Line.OrderId);
                                          setLineToAddproductOn(
                                            JSON.parse(Line?.Shape.DataScene)
                                          );
                                          setLineToChange(Line);

                                          setOpen(true);
                                          setSyncingImage(false);

                                        }
                                      }
                                    }}
                                  >
                                    <Tooltip title={props.disabledAllFields ? "" : "Click here to add products"}>
                                      <img src={AddProductIcon} alt={"addProduct"} width="12px" height="12px" />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                                :
                                <></>
                              }
                              {(member.ServiceId === 1 && Line.ShapeName !== "Bar")
                                ?
                                <>You can only choose Bar in Cut</>
                                : (member.ServiceId === 2 && Line.ShapeName === "Bar") ?
                                  <>You cannot choose Bar in Thread</>
                                  :
                                  <Grid item style={{ position: "relative" }}>
                                    <img
                                      width={175}
                                      src={
                                        Line.ShapeBlob
                                          ? Line.ShapeBlob
                                          : Line.Shape.File.URL
                                      }
                                      alt={"shapeImg"}
                                    ></img>
                                    {syncingImage &&
                                      chosenId === Line.OrderId &&
                                      chosenMemberId === member.OrderId ? (
                                      <CircularProgress
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                              }

                              {member.ServiceId === 3 ?

                                <Grid item container direction={"row"}>
                                  <Grid
                                    item
                                    container
                                    xs={10.5}
                                    direction={"row"}
                                    gap={1}
                                  >
                                    {Line.Shape.Elements.map((elToFill) => (
                                      <Grid container direction={"row"} xs={3.3}>
                                        <Grid
                                          item
                                          sx={styles.tfInput2}
                                          xs={6}
                                          paddingTop={1}
                                        >
                                          {elToFill.Name}:
                                        </Grid>
                                        <Grid item xs={6}>
                                          <TextField
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            fullWidth
                                            disabled={
                                              elToFill.MeasureTypeId !==
                                                SALESORDERDCONSTS.MEASURE_VARIABLE ||
                                                props.disabledAllFields
                                                ? true
                                                : false
                                            }
                                            value={
                                              elToFill.MeasureValue
                                                ? elToFill.MeasureValue
                                                : elToFill.MeasureTypeId ===
                                                  SALESORDERDCONSTS.MEASURE_CALCULATED
                                                  ? elToFill.MeasureFunction
                                                  : elToFill.MeasureValue
                                            }
                                            sx={styles.tfInput2}
                                            onChange={(e) => {
                                              elToFill.MeasureValue = +e.target.value;
                                              forceUpdate();
                                            }}
                                            placeholder="Value"
                                            InputProps={{
                                              disableUnderline: true,
                                              style: styles.tfInput,
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    marginLeft={0.8}
                                    onMouseOver={(e) => {
                                      if (!syncingImage) e.target.style.cursor = "pointer";
                                      else e.target.style.cursor = "unset"
                                    }}
                                    paddingLeft={1.3}
                                    paddingTop={1}
                                    onClick={() => {
                                      if (!props.disabledAllFields && !syncingImage) {
                                        setChosenId(Line.OrderId);
                                        setChosenMemberId(member.OrderId);
                                        syncShape(Line);
                                      }
                                    }}
                                  >
                                    <IconButton>
                                      <img src={SyncIcon} alt={"Sync"} width="12px" height="12px" />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                :
                                <></>
                              }

                            </Grid>
                          ) : (Line.ShapeCode !== null || Line.ShapeName !== null) && Line.Shape === null ? (
                            <Grid container>
                              <Grid item xs={12} container justifyContent="left">
                                <img src={CautionIcon} alt="caution" />
                              </Grid>
                              <Grid item xs={12} className={classes["ShapeDoesNot"]}>
                                Shape code does not exist in the Shape Library
                              </Grid>
                              <Grid item xs={12}>
                                Select another shape or contact pilecubes team to add
                                the shape.
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        {member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                          <>
                            <TableCell sx={styles.tableSubSubCell}>
                              {
                                Line.ShapeName?.toLowerCase() === 'couplers and accessories' ?
                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    fullWidth
                                    name="Product Category"
                                    value={parseInt(Line.TypeId)}
                                    // defaultValue={0}
                                    select
                                    disabled={props.disabledAllFields}
                                    sx={styles.tfInput2}
                                    placeholder="Product Category"
                                    onChange={(e) => {
                                      Line.TypeId = e.target.value;
                                      Line.SubCategoryId = null;
                                      Line.TreatmentId = null;
                                      Line.ProductId = null;
                                      Line.Threads = null;
                                      forceUpdate();
                                    }}
                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: styles.tfInput,
                                    }}
                                  >
                                    <MenuItem value={0} disabled>
                                      Product Category
                                    </MenuItem>
                                    {props.factoryProductReferences.map((r) => (
                                      <MenuItem value={r.Id} key={r.Id}>
                                        {r.Name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  : Line.ShapeName?.toLowerCase() === 'bet + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + coupler' || Line.ShapeName?.toLowerCase() === 'bet x2 + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + double coupler' ?
                                    <>
                                      {Line.Products?.map((pr, index) => (
                                        <Grid item container direction='column'>
                                          <Grid item>Product {index + 1}</Grid>
                                          <TextField
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            fullWidth
                                            name="Product Category"
                                            value={parseInt(pr.TypeId)}
                                            // defaultValue={0}
                                            select
                                            disabled={props.disabledAllFields}
                                            sx={styles.tfInput2}
                                            placeholder="Product Category"
                                            onChange={(e) => {
                                              pr.TypeId = e.target.value;
                                              pr.SubCategoryId = null;
                                              pr.TreatmentId = null;
                                              pr.Id = null;
                                              if (Line.Products?.length === 2 && index === 0) {
                                                Line.Products[1].TypeId = e.target.value;
                                                Line.Products[1].SubCategoryId = null;
                                                Line.Products[1].TreatmentId = null;
                                                Line.Products[1].Id = null;
                                                if (Line.Products[1].SpliceTypeId) props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                                Line.Products[1].SpliceTypeId = null;

                                              }
                                              forceUpdate();
                                            }}
                                            SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                            InputProps={{
                                              disableUnderline: true,
                                              style: styles.tfInput,
                                            }}
                                          >
                                            <MenuItem value={0} disabled>
                                              Product Category
                                            </MenuItem>
                                            {props.factoryProductReferences.map((r) => (
                                              <MenuItem value={r.Id} key={r.Id}>
                                                {r.Name}
                                              </MenuItem>
                                            ))}
                                          </TextField>

                                        </Grid>

                                      ))}
                                    </>
                                    :
                                    <span>N/A</span>
                              }

                            </TableCell>
                            {/* 
                          <TableCell sx={styles.tableSubSubCell}>

                            {member.ServiceId === 2 ?
                              Line.ShapeName === 'Couplers and accessories' ?
                                <TextField
                                  variant="standard" // <== changed this
                                  margin="normal"
                                  fullWidth
                                  name="Product SubCategory"
                                  value={parseInt(Line.SubCategoryId)}
                                  // defaultValue={0}
                                  select
                                  disabled={props.disabledAllFields}
                                  sx={styles.tfInput2}
                                  placeholder="Product SubCategory"
                                  onChange={(e) => {
                                    Line.SubCategoryId = e.target.value;
                                    Line.TreatmentId = null;
                                    Line.ProductId = null;
                                    forceUpdate();
                                  }}
                                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: styles.tfInput,
                                  }}
                                >
                                  <MenuItem value={0} disabled>
                                    Product SubCategory
                                  </MenuItem>
                                  {props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.SubCategories?.map((r) => (
                                    <MenuItem value={r.Id} key={r.Id}>
                                      {r.Name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                : Line.ShapeName === 'BET + Coupler' || Line.ShapeName === 'Double BET + Coupler' || Line.ShapeName === 'BET x2 + Coupler' || Line.ShapeName === 'Double BET + Double Coupler' ?
                                  <>
                                    {Line.Products?.map((pr, index) => (
                                      <Grid item container direction='column'>
                                        <Grid item>Product {index + 1}</Grid>
                                        <TextField
                                          variant="standard" // <== changed this
                                          margin="normal"
                                          fullWidth
                                          name="Product SubCategory"
                                          value={parseInt(pr.SubCategoryId)}
                                          // defaultValue={0}
                                          select
                                          disabled={props.disabledAllFields}
                                          sx={styles.tfInput2}
                                          placeholder="Product SubCategory"
                                          onChange={(e) => {
                                            pr.SubCategoryId = e.target.value;
                                            pr.TreatmentId = null;
                                            pr.Id = null;
                                            forceUpdate();
                                          }}
                                          SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            style: styles.tfInput,
                                          }}
                                        >
                                          <MenuItem value={0} disabled>
                                            Product SubCategory
                                          </MenuItem>
                                          {props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.SubCategories?.map((r) => (
                                            <MenuItem value={r.Id} key={r.Id}>
                                              {r.Name}
                                            </MenuItem>
                                          ))}
                                        </TextField>

                                      </Grid>

                                    ))}
                                  </>
                                  :
                                  <></>
                              :
                              <span>N/A</span>
                            }
                          </TableCell> */}

                            {/* <TableCell sx={styles.tableSubSubCell}>
                            {member.ServiceId === 2 ?
                              Line.ShapeName === 'Couplers and accessories' ?
                                <TextField
                                  variant="standard" // <== changed this
                                  margin="normal"
                                  fullWidth
                                  name="Product Sub1"
                                  value={parseInt(Line.TreatmentId)}
                                  // defaultValue={0}
                                  select
                                  disabled={props.disabledAllFields}
                                  sx={styles.tfInput2}
                                  placeholder="Product SubCategory"
                                  onChange={(e) => {
                                    Line.TreatmentId = e.target.value;
                                    Line.ProductId = null;
                                    forceUpdate();
                                  }}
                                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: styles.tfInput,
                                  }}
                                >
                                  <MenuItem value={0} disabled>
                                    Product Sub1
                                  </MenuItem>
                                  {props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.SubCategories.find((s) => s.Id === parseInt(Line.SubCategoryId))?.Treatments?.map((r) => (
                                    <MenuItem value={r.Id} key={r.Id}>
                                      {r.Name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                : Line.ShapeName === 'BET + Coupler' || Line.ShapeName === 'Double BET + Coupler' || Line.ShapeName === 'BET x2 + Coupler' || Line.ShapeName === 'Double BET + Double Coupler' ?
                                  <>
                                    {Line.Products?.map((pr, index) => (
                                      <Grid item container direction='column'>
                                        <Grid item>Product {index + 1}</Grid>
                                        <TextField
                                          variant="standard" // <== changed this
                                          margin="normal"
                                          fullWidth
                                          name="Product Sub1"
                                          value={parseInt(pr.TreatmentId)}
                                          // defaultValue={0}
                                          select
                                          disabled={props.disabledAllFields}
                                          sx={styles.tfInput2}
                                          placeholder="Product SubCategory"
                                          onChange={(e) => {
                                            pr.TreatmentId = e.target.value;
                                            pr.Id = null;
                                            forceUpdate();
                                          }}
                                          SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            style: styles.tfInput,
                                          }}
                                        >
                                          <MenuItem value={0} disabled>
                                            Product Sub1
                                          </MenuItem>
                                          {props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.SubCategories.find((s) => s.Id === parseInt(pr.SubCategoryId))?.Treatments?.map((r) => (
                                            <MenuItem value={r.Id} key={r.Id}>
                                              {r.Name}
                                            </MenuItem>
                                          ))}
                                        </TextField>

                                      </Grid>

                                    ))}
                                  </>
                                  :
                                  <></>
                              :
                              <span>N/A</span>
                            }
                          </TableCell> */}

                            <TableCell sx={styles.tableSubSubCell}>

                              {Line.ShapeName?.toLowerCase() === 'couplers and accessories' ?
                                <TextField
                                  variant="standard" // <== changed this
                                  margin="normal"
                                  fullWidth
                                  name="Item Series"
                                  value={Line.ProductId}
                                  // defaultValue={0}
                                  select
                                  disabled={props.disabledAllFields}
                                  sx={styles.tfInput2}
                                  placeholder="Product Item Series"
                                  onChange={(e) => {
                                    const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Name;
                                    const newProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === e.target.value)?.Name;
                                    if (props.productCount?.find((p) => p.GradeId === newProductName)) {
                                      props.setProductCount(prev =>
                                        prev.map(item => {
                                          if (item.GradeId === newProductName) {
                                            return { ...item, Count: +(item.Count + (+Line.BarsQuantity * +member.Quantity)) };
                                          } else if (item.GradeId === oldProductName) {
                                            return { ...item, Count: +(item.Count - (+Line.BarsQuantity * +member.Quantity)) };
                                          }
                                          return item;
                                        })
                                      );
                                      Line.ProductId = e.target.value;
                                      Line.Threads[0] = null


                                    }
                                    else {
                                      props.setProductCount(prev =>
                                        prev.map(item => {
                                          if (item.GradeId === oldProductName) {
                                            return { ...item, Count: +(item.Count - (+Line.BarsQuantity * +member.Quantity)) };

                                          }
                                          return item; // Leave other items unchanged
                                        })
                                          .concat({ GradeId: newProductName, Count: (+Line.BarsQuantity * +member.Quantity) })
                                      );
                                      Line.ProductId = e.target.value;
                                      Line.Threads[0] = null

                                    }

                                    forceUpdate();

                                  }}
                                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: styles.tfInput,
                                  }}
                                >
                                  <MenuItem value={0} disabled>
                                    Product Item Series
                                  </MenuItem>
                                  {props.factoryProductReferences?.find((i) => i.Id === (props.SALES_ORDER_ID && member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ? parseInt(props.factoryProductReferences?.find(item => item.Grades?.some(grade => grade.Id === Line.ProductId))?.Id) : parseInt(Line.TypeId)))?.Grades?.map((r) => (

                                    <MenuItem value={r.Id} key={r.Id}>
                                      {r.Name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                : Line.ShapeName?.toLowerCase() === 'bet + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + coupler' || Line.ShapeName?.toLowerCase() === 'bet x2 + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + double coupler' ?
                                  <>
                                    {Line.Products?.map((pr, index) => (
                                      <Grid item container direction='column' key={index}>
                                        <Grid item>Product {index + 1}</Grid>
                                        <TextField
                                          variant="standard" // <== changed this
                                          margin="normal"
                                          fullWidth
                                          name="Item Series"
                                          value={pr.Id}
                                          // defaultValue={0}
                                          select
                                          disabled={props.disabledAllFields}
                                          sx={styles.tfInput2}
                                          placeholder="Product Item Series"
                                          onChange={(e) => {
                                            console.log("e.target.value", e.target.value)
                                            const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Name;
                                            const newProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === e.target.value)?.Name;
                                            if (props.productCount?.find((p) => p.GradeId === newProductName)) {
                                              props.setProductCount(prev =>
                                                prev.map(item => {
                                                  if (item.GradeId === newProductName) {
                                                    return { ...item, Count: +(item.Count + (+Line.BarsQuantity * +member.Quantity)) };
                                                  } else if (item.GradeId === oldProductName) {
                                                    return { ...item, Count: +(item.Count - (+Line.BarsQuantity * +member.Quantity)) };
                                                  }
                                                  return item;
                                                })
                                              );
                                              pr.Id = e.target.value;
                                              if (pr.SpliceTypeId) props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                              pr.SpliceTypeId = null;
                                              if (Line.Products?.length === 2 && index === 0) {
                                                Line.Products[1].Id = e.target.value;
                                                if (Line.Products[1].SpliceTypeId) props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                                Line.Products[1].SpliceTypeId = null
                                              }

                                            }
                                            else {
                                              props.setProductCount(prev =>
                                                prev.map(item => {
                                                  if (item.GradeId === oldProductName) {
                                                    return { ...item, Count: +(item.Count - (+Line.BarsQuantity * +member.Quantity)) };

                                                  }
                                                  return item; // Leave other items unchanged
                                                })
                                                  .concat({ GradeId: newProductName, Count: (+Line.BarsQuantity * +member.Quantity) })
                                              );
                                              pr.Id = e.target.value;
                                              if (pr.SpliceTypeId) props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                              pr.SpliceTypeId = null;
                                              if (Line.Products?.length === 2 && index === 0) {
                                                Line.Products[1].Id = e.target.value;
                                                if (Line.Products[1].SpliceTypeId) props.setThreadCount((props.threadCount - Line.TotalNumberOfBars))
                                                Line.Products[1].SpliceTypeId = null
                                              }
                                            }

                                            forceUpdate();
                                          }}
                                          SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            style: styles.tfInput,
                                          }}
                                        >
                                          <MenuItem value={0} disabled>
                                            Product Item Series
                                          </MenuItem>
                                          {props.factoryProductReferences?.find((i) => i.Id === (props.SALES_ORDER_ID && member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ? parseInt(props.factoryProductReferences?.find(item => item.Grades?.some(grade => grade.Id === pr.Id))?.Id) : parseInt(pr.TypeId)))?.Grades?.map((r) => (
                                            <MenuItem value={r.Id} key={r.Id}>
                                              {r.Name}
                                            </MenuItem>
                                          ))}
                                        </TextField>

                                      </Grid>

                                    ))}
                                  </>
                                  :
                                  <span>N/A</span>
                              }
                            </TableCell>
                          </>

                          :
                          <></>

                        }

                        <TableCell sx={styles.tableSubSubCell}>
                          {/* dia */}
                          <TextField
                            variant="standard" // <== changed this
                            margin="normal"
                            disabled={
                              props.disabledFromActivity || props.disabledAllFields
                            }
                            fullWidth
                            value={Line.BarDiameter}
                            sx={{
                              // Ensure the TextField has proper height and aligns content
                              '& .MuiInputBase-root': {
                                display: 'flex',
                                alignItems: 'center',  // Vertically center the content inside the input box
                                justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                height: '40px', // Adjust the height as needed for your design
                                padding: 0, // Remove any default padding if needed
                              },
                              // You can also control the text alignment here
                              ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                              '& .MuiInputBase-input': {
                                textAlign: 'center', // Center the text horizontally
                                padding: '0', // Remove internal padding to avoid misalignment
                                lineHeight: 'normal', // Adjust line height to ensure vertical centering
                              }
                            }}
                            onChange={(e) => {
                              Line.BarDiameter = e.target.value;
                              if (member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID && Line.ShapeName !== 'Couplers and accessories') {
                                Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                              }

                              if (member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID) {
                                Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                              }
                              forceUpdate();

                            }}
                            placeholder="Dia"
                            InputProps={{
                              disableUnderline: true,
                              style: styles.tfInput,
                            }}
                          />
                        </TableCell>
                        {member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                          <>
                            <TableCell sx={styles.tableSubSubCell}>
                              {/* second dia */}
                              {
                                (Line.ShapeName?.toLowerCase() === 'couplers and accessories' &&
                                  props.factoryProductReferences?.find((p) => p.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Name?.toLowerCase().includes("transition")
                                )
                                  ||
                                  (
                                    (Line.ShapeName?.toLowerCase() === 'bet + coupler' || Line.ShapeName?.toLowerCase() === 'bet x2 + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + coupler')
                                    &&
                                    props.factoryProductReferences?.find((p) => p.Id === parseInt(Line.Products[0].TypeId))?.Grades?.find((g) => g.Id === Line.Products[0].Id)?.Name?.toLowerCase().includes("transition")

                                  ) ||
                                  (
                                    (Line.ShapeName?.toLowerCase() === 'double bet + double coupler')
                                    &&
                                    (
                                      (
                                        props.factoryProductReferences?.find((p) => p.Id === parseInt(Line.Products[0].TypeId))?.Grades?.find((g) => g.Id === Line.Products[0]?.Id)?.Name?.toLowerCase().includes("transition")
                                      )
                                      ||
                                      (
                                        props.factoryProductReferences?.find((p) => p.Id === parseInt(Line.Products[1].TypeId))?.Grades?.find((g) => g.Id === Line.Products[1]?.Id)?.Name?.toLowerCase().includes("transition")
                                      )

                                    )

                                  ) ?
                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    disabled={
                                      props.disabledFromActivity || props.disabledAllFields
                                    }
                                    fullWidth
                                    value={Line.SecondDiameter}
                                    sx={{
                                      // Ensure the TextField has proper height and aligns content
                                      '& .MuiInputBase-root': {
                                        display: 'flex',
                                        alignItems: 'center',  // Vertically center the content inside the input box
                                        justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                        height: '40px', // Adjust the height as needed for your design
                                        padding: 0, // Remove any default padding if needed
                                      },
                                      // You can also control the text alignment here
                                      ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                                      '& .MuiInputBase-input': {
                                        textAlign: 'center', // Center the text horizontally
                                        padding: '0', // Remove internal padding to avoid misalignment
                                        lineHeight: 'normal', // Adjust line height to ensure vertical centering
                                      }
                                    }}
                                    onChange={(e) => {
                                      Line.SecondDiameter = e.target.value;
                                      forceUpdate();
                                    }}
                                    placeholder="Second Dia"
                                    InputProps={{
                                      disableUnderline: true,
                                      style: styles.tfInput,
                                    }}
                                  />
                                  :
                                  <span>N/A</span>
                              }

                              {/* {member.ServiceId === 2 ?
                            <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              disabled={
                                props.disabledFromActivity || props.disabledAllFields
                              }
                              fullWidth
                              value={Line.SecondDiameter}
                              sx={styles.tfInput2}
                              onChange={(e) => {
                                Line.SecondDiameter = e.target.value;
                                forceUpdate();
                              }}
                              placeholder="Second Dia"
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            />
                            :
                            <span>N/A</span>
                          } */}
                            </TableCell>
                          </>
                          :
                          <></>
                        }
                        {member.ServiceId === SALESORDERDCONSTS.CUT_AND_BEND_SERVICE_ID ?
                          <>
                            <TableCell sx={styles.tableSubSubCell}>
                              {/* length to pr */}
                              {/* Math.round(Line.ActualLength) + */}
                              {/* " " + */}
                              {member.BarMarkTypeId === 3 ?
                                <span>
                                  {Line.TheoreticalLength?.toFixed(2)}
                                </span>

                                :
                                <></>
                              }
                            </TableCell>
                          </>
                          :
                          <></>}

                        {member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID ?
                          <TableCell sx={styles.tableSubSubCell}>
                            {/* provided length */}
                            {Line.ShapeName !== 'Couplers and accessories' ?
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                value={Line.ProvidedLength}
                                disabled={props.disabledAllFields}
                                sx={{
                                  // Ensure the TextField has proper height and aligns content
                                  '& .MuiInputBase-root': {
                                    display: 'flex',
                                    alignItems: 'center',  // Vertically center the content inside the input box
                                    justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                    height: '40px', // Adjust the height as needed for your design
                                    padding: 0, // Remove any default padding if needed
                                  },
                                  // You can also control the text alignment here
                                  ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                                  '& .MuiInputBase-input': {
                                    textAlign: 'center', // Center the text horizontally
                                    padding: '0', // Remove internal padding to avoid misalignment
                                    lineHeight: 'normal', // Adjust line height to ensure vertical centering
                                  }
                                }}
                                onChange={(e) => {
                                  Line.ProvidedLength = e.target.value;
                                  // if (member.ServiceId === 1) Line.ProvidedLength = parseInt(e.target.value);
                                  forceUpdate();
                                }}
                                placeholder="Length"
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              />
                              :
                              <span>N/A</span>}
                          </TableCell>
                          :
                          <></>

                        }

                        {member.ServiceId !== SALESORDERDCONSTS.CUT_AND_BEND_SERVICE_ID ?
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.ShapeName !== 'Couplers and accessories' ?
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                value={Line.ActualLength}
                                disabled={props.disabledAllFields}
                                sx={{
                                  // Ensure the TextField has proper height and aligns content
                                  '& .MuiInputBase-root': {
                                    display: 'flex',
                                    alignItems: 'center',  // Vertically center the content inside the input box
                                    justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                    height: '40px', // Adjust the height as needed for your design
                                    padding: 0, // Remove any default padding if needed
                                  },
                                  // You can also control the text alignment here
                                  ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                                  '& .MuiInputBase-input': {
                                    textAlign: 'center', // Center the text horizontally
                                    padding: '0', // Remove internal padding to avoid misalignment
                                    lineHeight: 'normal', // Adjust line height to ensure vertical centering
                                  }
                                }}
                                onChange={(e) => {
                                  Line.ActualLength = e.target.value;
                                  Line.TheoreticalLength = e.target.value;
                                  // if (member.ServiceId === 1) Line.ProvidedLength = parseInt(e.target.value);
                                  if (member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID && Line.ShapeName !== 'Couplers and accessories') {
                                    Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                    Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                  }

                                  if (member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID) {
                                    Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                    Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                  }
                                  forceUpdate();
                                }}
                                placeholder="Length"
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              />
                              :
                              <span>N/A</span>

                            }

                          </TableCell>
                          :
                          <></>
                        }

                        {member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID ?
                          <>
                            <TableCell sx={styles.tableSubSubCell}>
                              {props.status !== SALESORDERDCONSTS.PLACED_ID ?
                                <>

                                  {/* qty to produce */}
                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    disabled={
                                      props.disabledFromActivity || props.disabledAllFields
                                    }
                                    fullWidth
                                    value={Line.ProducedQuantity}
                                    type="number"
                                    sx={{
                                      // Ensure the TextField has proper height and aligns content
                                      '& .MuiInputBase-root': {
                                        display: 'flex',
                                        alignItems: 'center',  // Vertically center the content inside the input box
                                        justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                        height: '40px', // Adjust the height as needed for your design
                                        padding: 0, // Remove any default padding if needed
                                      },
                                      // You can also control the text alignment here
                                      ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                                      '& .MuiInputBase-input': {
                                        textAlign: 'center', // Center the text horizontally
                                        padding: '0', // Remove internal padding to avoid misalignment
                                        lineHeight: 'normal', // Adjust line height to ensure vertical centering
                                      }
                                    }}
                                    onChange={(e) => {
                                      Line.ProducedQuantity = +e.target.value

                                      if (member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID && Line.ShapeName !== 'Couplers and accessories') {
                                        Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                        Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                      }

                                      if (member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID) {
                                        Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                        Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                      }

                                      forceUpdate();
                                    }}
                                    placeholder="qty"
                                    InputProps={{
                                      disableUnderline: true,
                                      style: styles.tfInput,
                                      inputProps: { min: 0 },
                                    }}
                                  />
                                  <br />
                                  {!props.hideInitialTotal ?
                                    <Grid item className={classes["SubTitle"]} paddingTop={1} marginLeft={5}>
                                      {Line.ProducedQuantity ? +Line.ProducedQuantity * +member.Quantity : ""}
                                    </Grid>
                                    :
                                    <></>
                                  }

                                </>
                                :
                                <Grid item className={classes["SubTitle"]} paddingTop={1}>
                                  {Line.ProducedQuantity}
                                </Grid>
                              }


                            </TableCell>
                            {/* <TableCell sx={styles.tableSubSubCell}>
                          {member.ServiceId === 1 ?
                            <>
                              qty to produce
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                disabled={
                                  props.disabledFromActivity || props.disabledAllFields
                                }
                                fullWidth
                                value={Line.ProducedQuantity}
                                type="number"
                                sx={styles.tfInput2}
                                onChange={(e) => {
                                  Line.ProducedQuantity = (+e.target.value * +member.Quantity);

                                  forceUpdate();
                                }}
                                placeholder="qty"
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                  inputProps: { min: 0 },
                                }}
                              />
                            </>
                            :
                            <span>N/A</span>
                          }
                        </TableCell> */}
                          </>


                          :
                          <></>}




                        <TableCell sx={styles.tableSubSubCell}>
                          {props.status !== SALESORDERDCONSTS.PLACED_ID ?
                            <>
                              {/* old qty */}
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                disabled={
                                  props.disabledFromActivity || props.disabledAllFields
                                }
                                fullWidth
                                // value={props.SALES_ORDER_ID && Line.BarsQuantity * Line.TotalNumberOfBars !== member.Quantity ? parseInt(((Line.BarsQuantity * Line.TotalNumberOfBars) / (member.Quantity * Line.BarsQuantity))) : Line.BarsQuantity}
                                value={Line.BarsQuantity}

                                type="number"
                                sx={{
                                  // Ensure the TextField has proper height and aligns content
                                  '& .MuiInputBase-root': {
                                    display: 'flex',
                                    alignItems: 'center',  // Vertically center the content inside the input box
                                    justifyContent: 'center', // Optionally, for horizontally centering (though this is handled by textAlign too)
                                    height: '40px', // Adjust the height as needed for your design
                                    padding: 0, // Remove any default padding if needed
                                  },
                                  // You can also control the text alignment here
                                  ...styles.tfInput2, // Inherit your existing styles (like font size, weight, etc.)
                                  '& .MuiInputBase-input': {
                                    textAlign: 'center', // Center the text horizontally
                                    padding: '0', // Remove internal padding to avoid misalignment
                                    lineHeight: 'normal', // Adjust line height to ensure vertical centering
                                  }
                                }}
                                onChange={(e) => {
                                  const oldBarsQuantity = Line.BarsQuantity
                                  Line.BarsQuantity = +e.target.value;
                                  Line.TotalNumberOfBars =
                                    +e.target.value * +member.Quantity;

                                  if (member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID && Line.ShapeName !== 'Couplers and accessories') {
                                    Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                    Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                  }

                                  if (member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID) {
                                    Line.ActualWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                    Line.TheoreticalWeight = ((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity);
                                  }

                                  if (Line.Threads?.length === 1 && Line.Threads[0] !== -1) props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                  if (Line.Threads?.length === 2) {
                                    console.log('change bar qty in threads of length 2')
                                    if (Line.Threads[0] !== -1 && Line.Threads[1] !== -1) {
                                      console.log('change  * 2', props.threadCount - (oldBarsQuantity * +member.Quantity * 2) + (+e.target.value * +member.Quantity * 2))
                                      props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity * 2) + (+e.target.value * +member.Quantity * 2)))
                                    } else if (Line.Threads[0] !== -1) {
                                      console.log('change [0]')

                                      props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                    } else if (Line.Threads[1] !== -1) {
                                      console.log('change [1]')
                                      props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))

                                    }

                                  }

                                  if (Line.Products?.length === 1 && Line.Products[0].SpliceTypeId) props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                  if (Line.Products?.length === 2) {
                                    console.log('testttt')
                                    if (Line.Products[0].SpliceTypeId && Line.Products[1].SpliceTypeId) {
                                      console.log("here")
                                      props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity * 2) + (+e.target.value * +member.Quantity * 2)))
                                    } else if (Line.Products[0].SpliceTypeId) {
                                      console.log("Or here")
                                      props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                    } else if (Line.Products[1].SpliceTypeId) {
                                      console.log("Or Or here")

                                      props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                    }
                                  }

                                  // if (Line.Threads?.length === 1 && Line.Threads[0] !== -1) props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                  // if (Line.Threads?.length === 2) {
                                  //   if (Line.Threads[0] !== -1) props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                  //   if (Line.Threads[1] !== -1) props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                  // }
                                  if (Line.ProductId) {
                                    const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Name;
                                    props.setProductCount(prev =>
                                      prev.map(item => {
                                        if (item.GradeId === oldProductName) {
                                          return { ...item, Count: (item.Count - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)) };
                                        }
                                        return item;
                                      })
                                    );
                                  }

                                  if (Line.Products) {
                                    Line.Products?.map((pr, prIndex) => {

                                      // if (pr.SpliceTypeId) {
                                      //   console.log("test",prIndex)
                                      //   props.setThreadCount((props.threadCount - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)))
                                      // }

                                      const oldProductName = props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Name;
                                      props.setProductCount(prev =>
                                        prev.map(item => {
                                          if (item.GradeId === oldProductName) {
                                            return { ...item, Count: (item.Count - (oldBarsQuantity * +member.Quantity) + (+e.target.value * +member.Quantity)) };
                                          }
                                          return item;
                                        })
                                      );
                                    })

                                  }

                                  forceUpdate();
                                }}
                                placeholder="qty"
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                  inputProps: { min: 0 },
                                }}
                              />
                              <br />
                              <Grid item className={classes["SubTitle"]} paddingTop={1} marginLeft={5}>
                                {Line.BarsQuantity ? Line.TotalNumberOfBars : ""}
                              </Grid>
                            </>
                            :
                            <Grid item className={classes["SubTitle"]} paddingTop={1}>
                              {Line.TotalNumberOfBars}
                            </Grid>
                          }
                        </TableCell>

                        <TableCell sx={styles.tableSubSubCell}>
                          {/* Line.ActualWeight?.toFixed(2) + */}
                          {/* " " + */}
                          {member.BarMarkTypeId === 3 && member.ServiceId === SALESORDERDCONSTS.CUT_AND_BEND_SERVICE_ID ?
                            <span>{Line.TheoreticalWeight?.toFixed(2)}</span>

                            :
                            member.ServiceId === SALESORDERDCONSTS.CUT_SERVICE_ID ?
                              <>
                                {/* <TextField
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              value={Line.ActualWeight}
                              disabled={props.disabledAllFields}
                              sx={styles.tfInput2}
                              onChange={(e) => {
                                Line.ActualWeight = e.target.value
                                forceUpdate();
                              }}
                              placeholder="Weight"
                              InputProps={{
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            /> */}
                                {/* <span> weight/m :{props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter}
                                <br />
                                actual len:{Line.ActualLength / 1000}
                                <br />
                                producedQty:{Line.ProducedQuantity}
                                <br />
                                member Qty:{member.Quantity}
                                <br />
                              </span>
                               */}
                                <span>{Line.TheoreticalWeight?.toFixed(2)}</span>
                                {/* <span> {((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.ProducedQuantity * member.Quantity)}</span> */}
                              </>
                              :
                              member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID && Line.ShapeName !== 'Couplers and accessories' ?
                                <>
                                  {/* <span> weight/m :{props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter}
                                  <br />
                                  actual len:{Line.ActualLength / 1000}
                                  <br />
                                  oldQty:{Line.BarsQuantity}
                                  <br />
                                  member Qty:{member.Quantity}
                                  <br />
                                </span>
                                */}
                                  {/* <span> {((props.weightPeremeters?.find((w) => w.Diameter === parseInt(Line.BarDiameter))?.WeightPerMeter) * Line.ActualLength / 1000 * Line.BarsQuantity * member.Quantity)}</span> */}
                                  <span>{Line.TheoreticalWeight?.toFixed(2)}</span>
                                </>
                                :

                                <span>N/A</span>
                          }
                        </TableCell>




                        {member.ServiceId === SALESORDERDCONSTS.THREAD_SERVICE_ID ?
                          <TableCell sx={styles.tableSubSubCell}>

                            {Line.ShapeName?.toLowerCase() === 'bet' ?
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                name="Splice Type"
                                value={Line.Threads[0]}
                                // defaultValue={0}
                                select
                                disabled={props.disabledAllFields}
                                sx={styles.tfInput2}
                                placeholder="Splice Type"
                                onChange={(e) => {
                                  if (Line.Threads[0] === -1) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                  console.log("e", e.target.value)
                                  Line.Threads[0] = e.target.value;
                                  forceUpdate();
                                }}
                                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              >
                                <MenuItem value={0} disabled>
                                  Splice Type
                                </MenuItem>
                                {/* {props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Splices?.map((r) => ( */}
                                {/* {Line.ShapeName?.toLowerCase() === 'couplers and accessories' ?
                                  props.factoryProductReferences?.find((r) => r.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Splices?.map((r) => (
                                    <MenuItem value={r.TypeId} key={r.TypeId}>
                                      {props.spliceTypes?.find((s) => s.Id === r.TypeId)?.Name}
                                    </MenuItem>
                                  )) : */}
                                {props.spliceTypes?.map((r) => (
                                  <MenuItem value={r.Id} key={r.Id}>
                                    {r.Name}
                                  </MenuItem>
                                ))}
                              </TextField>
                              : Line.ShapeName?.toLowerCase() === 'double bet' ?
                                <>
                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    fullWidth
                                    name="Splice Type"
                                    value={Line.Threads[0]}
                                    // defaultValue={0}
                                    select
                                    disabled={props.disabledAllFields}
                                    sx={styles.tfInput2}
                                    placeholder="Splice Type"
                                    onChange={(e) => {
                                      if (Line.Threads[0] === -1 && Line.Threads[1] === -1) props.setThreadCount((props.threadCount + (Line.TotalNumberOfBars * 2)))
                                      else if (Line.Threads[0] === -1) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                      console.log("e", e.target.value)
                                      Line.Threads[0] = e.target.value;
                                      Line.Threads[1] = e.target.value;
                                      forceUpdate();
                                    }}
                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: styles.tfInput,
                                    }}
                                  >
                                    <MenuItem value={0} disabled>
                                      Splice Type
                                    </MenuItem>

                                    {props.spliceTypes?.map((r) => (
                                      <MenuItem value={r.Id} key={r.Id}>
                                        {r.Name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    fullWidth
                                    name="Splice Type"
                                    value={Line.Threads[1]}
                                    // defaultValue={0}
                                    select
                                    disabled={props.disabledAllFields}
                                    sx={styles.tfInput2}
                                    placeholder="Splice Type"
                                    onChange={(e) => {
                                      console.log("e", e.target.value)
                                      if (Line.Threads[1] === -1) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                      Line.Threads[1] = e.target.value;
                                      forceUpdate();
                                    }}
                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: styles.tfInput,
                                    }}
                                  >
                                    <MenuItem value={0} disabled>
                                      Splice Type
                                    </MenuItem>
                                    {
                                      props.spliceTypes?.map((r) => (
                                        <MenuItem value={r.Id} key={r.Id}>
                                          {r.Name}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </>

                                : Line.ShapeName?.toLowerCase() === 'bet + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + coupler' || Line.ShapeName?.toLowerCase() === 'bet x2 + coupler' || Line.ShapeName?.toLowerCase() === 'double bet + double coupler' ?
                                  <>
                                    {Line.Products?.map((pr, index) => (
                                      <Grid item container direction='column' key={index}>
                                        <Grid item>Product {index + 1}</Grid>
                                        <TextField
                                          variant="standard" // <== changed this
                                          margin="normal"
                                          fullWidth
                                          name="Splice Type"
                                          value={pr.SpliceTypeId}
                                          // defaultValue={0}
                                          select
                                          disabled={props.disabledAllFields}
                                          sx={styles.tfInput2}
                                          placeholder="Splice Type"
                                          onChange={(e) => {
                                            console.log("e", Line)
                                            const oldPrSpliceType = pr.SpliceTypeId
                                            if (!oldPrSpliceType && Line.Products?.length === 2 && !Line.Products[1].SpliceTypeId) props.setThreadCount((props.threadCount + (Line.TotalNumberOfBars * 2)))
                                            else if (!oldPrSpliceType) props.setThreadCount((props.threadCount + (Line.TotalNumberOfBars)))

                                            pr.SpliceTypeId = e.target.value;

                                            if (Line.Products?.length === 2) {
                                              Line.Products[1].SpliceTypeId = e.target.value;
                                            }
                                            forceUpdate();
                                          }}
                                          SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            style: styles.tfInput,
                                          }}
                                        >
                                          <MenuItem value={0} disabled>
                                            Product Splice Type
                                          </MenuItem>
                                          {/* {props.factoryProductReferences?.find((i) => i.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Splices?.map((r) => ( */}
                                          {
                                            props.factoryProductReferences?.find((r) => r.Id === parseInt(pr.TypeId))?.Grades?.find((g) => g.Id === pr.Id)?.Splices?.map((r) => (

                                              <MenuItem value={r.TypeId} key={r.TypeId}>
                                                {props.spliceTypes?.find((s) => s.Id === r.TypeId)?.Name}
                                              </MenuItem>
                                            ))}
                                        </TextField>

                                      </Grid>

                                    ))}
                                    {Line.ShapeName?.toLowerCase() === 'double bet + coupler' ?
                                      <Grid item container direction='column'>
                                      <Grid item>Thread Splice Type</Grid>
                                      <TextField
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        fullWidth
                                        name="Splice Type"
                                        value={Line.Threads[0]}
                                        // defaultValue={0}
                                        select
                                        disabled={props.disabledAllFields}
                                        sx={styles.tfInput2}
                                        placeholder="Splice Type"
                                        onChange={(e) => {
                                          if (Line.Threads[0] === -1) props.setThreadCount((props.threadCount + Line.TotalNumberOfBars))
                                          console.log("e", e.target.value)
                                          Line.Threads[0] = e.target.value;
                                          forceUpdate();
                                        }}
                                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                        InputProps={{
                                          disableUnderline: true,
                                          style: styles.tfInput,
                                        }}
                                      >
                                        <MenuItem value={0} disabled>
                                          Splice Type
                                        </MenuItem>
                                        {/* {props.factoryProductReferences?.find((i) => i.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Splices?.map((r) => ( */}
                                        {/* {Line.ShapeName?.toLowerCase() === 'couplers and accessories' ?
                                  props.factoryProductReferences?.find((r) => r.Id === parseInt(Line.TypeId))?.Grades?.find((g) => g.Id === Line.ProductId)?.Splices?.map((r) => (
                                    <MenuItem value={r.TypeId} key={r.TypeId}>
                                      {props.spliceTypes?.find((s) => s.Id === r.TypeId)?.Name}
                                    </MenuItem>
                                  )) : */}
                                        {props.spliceTypes?.map((r) => (
                                          <MenuItem value={r.Id} key={r.Id}>
                                            {r.Name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      </Grid>
                                      :
                                      <></>}
                                  </>
                                  :
                                  <span>N/A</span>
                            }
                          </TableCell>
                          :
                          <></>
                        }



                        <TableCell sx={styles.tableSubSubCell}>
                          <TextField
                            variant="standard" // <== changed this
                            margin="normal"
                            disabled={
                              props.disabledFromActivity || props.disabledAllFields
                            }
                            fullWidth
                            value={Line.Remark}
                            sx={styles.tfInput2}
                            onChange={(e) => {
                              Line.Remark = e.target.value;
                              forceUpdate();
                            }}
                            placeholder="Remark"
                            InputProps={{
                              disableUnderline: true,
                              style: styles.tfInput,
                            }}
                          />
                        </TableCell>
                        {
                          props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                            <TableCell sx={styles.tableSubSubCell}>
                              {Line.Status}
                            </TableCell>
                          ) : (
                            <></>
                          )
                        }
                      </StyledTableSubRow>
                    ))}
                  </TableBody>
                </>
              ))}

              {lineToAddproductOn ? (
                <ExcalidrawModal
                  openModal={open}
                  setOpenModal={setOpen}
                  chosenId={chosenId}
                  lineToAddproductOn={lineToAddproductOn}
                  setLineToAddproductOn={setLineToAddproductOn}
                  projectId={props.projectId}
                  members={props.members}
                  lineToChange={lineToChange}
                  products={props.products}
                  chosenMemberId={chosenMemberId}
                  factoryId={props.factoryId}
                />
              ) : (
                ""
              )}

            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </>

  );
};

export default SalesOrderMemberTable;
const styles = {
  tableCell: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: "100px",
  },
  tableCellEmpty: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 10,
  },
  tableCellQty: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 130,
  },
  tableSubCell: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.5)"
        : "rgba(45, 164, 208, 0.5)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
  },
  tableSubSubCell: {
    border: "solid 0.5px ",
    borderColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.5)"
        : "rgba(45, 164, 208, 0.5)",
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
  },
  tableCellImage: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 400,
  },
  tableSubImage2: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.5)"
        : "rgba(45, 164, 208, 0.5)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    width: 180,
    // height: 38,
  },
  tableRow: {
    backgroundColor: "#fff",
    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
    maxHeight: 38,
  },
  tableCell2: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#0674b9",
  },
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    paddingLeft: 2,
    height: 5,
  },
  tfInput2: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    textAlign: "center",
    height: 5,
  },
};
