import React, { useState, useEffect } from "react";
import { Grid, Link } from "@mui/material";
import Logo from "../../Assets/Images/Navbar/logo.png";
import "../../Assets/Styles/LogIn.css";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import * as loginApi from "../../Services/log-in.proxy";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import jwt from "jwt-decode";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { getUserData } from "../../Services/user-data-service.proxy";

const LogInFunction = loginApi.logIn;
function LoginDesign({
  continues,
  setScrolled,
  setLoading,
  setToken,
  isVerified,
  setIsVerified,
  isSubscribed,
  setIsSubscribed,
  token,
  account,
  setAccount,
  setAccountName,
  inviterTenantId,
  projectId,
  subProjectId,
  RedirectURL,
  setRouting,
  routing,
  setShowSaaSNavbar,
}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [inputsError, setInputsError] = useState(false);
  const [inputsInvalid, setInputsInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [password, setPassword] = useState("");
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unauthMsg, setUnauthMsg] = useState("");

  const IsUserVerified = useSelector((state) => state.userData.IsUserVerified);
  const IsUserSubscribed = useSelector(
    (state) => state.userData.IsUserSubscribed
  );
  const IsGuest = useSelector((state) => state.userData.IsGuest);
  const IsTeamMember = useSelector((state) => state.userData.IsTeamMember);
  const CompanyName = useSelector((state) => state.userData.CompanyName);

  const buttonSx = {
    ...(success && {
      bgcolor: "#1173CF",
      "&:hover": {
        bgcolor: "#101650",
      },
    }),
  };

  const handleSubmit = (event) => {
    if (!loadingProgress) {
      setSuccess(false);
      setLoadingProgress(true);
    }
    event.preventDefault();
    if (email === "" || password === "") {
      setInputsError(true);
      setSuccess(false);
      setLoadingProgress(false);
    } else {
      LogInFunction(
        email,
        password,
        inviterTenantId,
        projectId,
        subProjectId,
        setLoading,
        false
      ).then((res) => {
        if (res.response?.data.status) {
          setUnauthMsg(res.response?.data.error);
          setInputsError(true);
          setInputsInvalid(true);
          setSuccess(false);
          setLoadingProgress(false);
        } else {
          localStorage.setItem("accessToken", res.data.accessToken);
          getUserData().then((userData) => {
            if (userData.statusCode === 401 || userData.statusCode === 500 || userData.error || !userData) {
              localStorage.clear();
            }
            else {
              setShowSaaSNavbar(false);
              userData?.Plans?.map((plan) => {
                if (
                  plan.SubscriptionTypeId === 1 ||
                  plan.SubscriptionTypeId === 2
                ) {
                  localStorage.setItem("PlanNameEngineering", plan.PlanName);
                  localStorage.setItem("IsScheduled", plan.IsScheduled);
                  localStorage.setItem("StartDate", plan.StartDate);
                  localStorage.setItem("EndDate", plan.EndDate);
                  localStorage.setItem("isSubscribed", plan.IsSubscribed);
                  localStorage.setItem("planId", plan.ProductType);
                  localStorage.setItem("companyPlanId", plan.ProductType);
                  localStorage.setItem(
                    "IsSubscribedBefore",
                    plan.IsSubscribedBefore
                  );
                  setIsSubscribed(true);
                } else if (plan.SubscriptionTypeId === 3) {
                  localStorage.setItem("PlanNameManu", plan.PlanName);
                  localStorage.setItem("IsScheduledManu", plan.IsScheduled);
                  localStorage.setItem("StartDateManuPlan", plan.StartDate);
                  localStorage.setItem("EndDateManuPlan", plan.EndDate);
                  localStorage.setItem("isSubscribedManu", plan.IsSubscribed);
                  localStorage.setItem("planIdManu", plan.ProductType);
                  localStorage.setItem("companyPlanIdManu", plan.ProductType);
                  localStorage.setItem(
                    "IsSubscribedBeforeManu",
                    plan.IsSubscribedBefore
                  );
                  setIsSubscribed(true);
                }
              });

              localStorage.setItem("isVerified", userData.IsUserVerified);
              // localStorage.setItem("isSubscribed", true);
              localStorage.setItem("companyName", userData.DisplayName);
              localStorage.setItem("isGuest", userData.IsGuest);
              localStorage.setItem("isTeamMember", userData.IsTeamMember);
              // localStorage.setItem("companyPlan", userData.Plan);
              // localStorage.setItem("planSubscribed", userData.Plan);
              // localStorage.setItem("planId", userData.ProductType);
              // localStorage.setItem("companyPlanId", userData.ProductType);
              // localStorage.setItem("planId", "Pro +");
              // localStorage.setItem("companyPlanId", "Pro +");
              localStorage.setItem(
                "IsFreePlanActivated",
                userData.IsFreePlanActivated
              );

              localStorage.setItem(
                "AccountType",
                jwt(res.data.accessToken).Roles[0]
              );
              localStorage.setItem("UserId", jwt(res.data.accessToken).Id);
              localStorage.setItem(
                "TenantId",
                jwt(res.data.accessToken).TenantId
              );
              setIsVerified(userData.IsUserVerified);

              setToken(res.data.accessToken);
              setAccountName(CompanyName);
              if (res.data.accessToken) setAccount(res.data.accessToken);
              setSuccess(true);
              setLoadingProgress(false);
              setRouting(!routing);
            }
          });
        }
      });
      // .catch((err) => {
      //   setInputsError(true);
      //   setInputsInvalid(true);
      //   setSuccess(false);
      //   setLoadingProgress(false);
      // });
    }
  };

  return (
    <Grid
      container
      className="LoginBg"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        container
        direction={"column"}
        width="380px"
        height="517.6px"
        alignItems={"center"}
        className="FormBg"
      >
        <Grid item marginTop={5}>
          <a href="/">
            <img src={Logo} alt="logo" height={50} />
          </a>
        </Grid>
        <Grid item marginTop={1}>
          <span className="LoginText">Log in to your account</span>
        </Grid>
        <Grid item marginTop={2}>
          <span className="login-errors">
            {inputsError
              ? inputsInvalid
                ? unauthMsg ===
                  "You can't login since you're company not subscribed" ||
                  unauthMsg === "Unauthorized, contact your company admin "
                  ? unauthMsg
                  : "Incorrect email address and / or password."
                : "Empty fields."
              : ""}
          </span>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          marginTop={1}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item marginRight={38}>
            <span className="FiedLabels">Email</span>
          </Grid>
          <Grid item>
            <TextField
              value={email}
              error={inputsError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name1"
              size="small"
              className="FieldsInput"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          marginTop={1}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item marginRight={35}>
            <span className="FiedLabels">Password</span>
          </Grid>
          <Grid item>
            <TextField
              type={showPassword ? "text" : "password"}
              error={inputsError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name"
              size="small"
              className="FieldsInput"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </Grid>
        </Grid>
        <Grid item marginTop={2}>
          {/* <button
            className="loginButton"
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#101650";
              e.target.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#1173cf";
            }}
            onClick={handleSubmit}
          >
            Log In
          </button> */}
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              className="loginButton"
              variant="contained"
              sx={buttonSx}
              disabled={loadingProgress}
              onClick={handleSubmit}
            >
              Log In
            </Button>
            {loadingProgress && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          container
          direction={"row"}
          paddingLeft={2.2}
          marginTop={1}
          className="RememberMe"
          columnSpacing={1}
        >
          <Grid item>
            <input
              type="checkbox"
            //   defaultChecked={info.AgreementAcceptance}
            //   checked={info.AgreementAcceptance}
            //   onChange={(e) => {
            //    setAgreeCheck(e.target.checked);
            //    info.AgreementAcceptance = e.target.checked;
            //    SetAgreeCheckError(!e.target.checked);

            //   }}
            ></input>
          </Grid>
          <Grid item>Remember me</Grid>
        </Grid>
        <Grid item className="HorizontalLine" marginTop={1}></Grid>
        <Grid item paddingRight={26.5} marginTop={2}>
          <div
            className="LoginLinks"
            onClick={(e) => {
              continues(e, 2);
            }}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            Forgot your Password?
          </div>
        </Grid>
        <Grid item className="HorizontalLine" marginTop={1}></Grid>
        <Grid item className="LoginLinks" paddingRight={20} marginTop={2}>
          <div
            className="LoginLinks"
            onClick={(e) => {
              navigate("/");
              setScrolled(true);
            }}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            Don't have an account? Sign up.
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginDesign;
