import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import SupplierModal from "./SupplierModal";
import SupplierTable from "./SupplierTable";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";

const SupplierTableView = (props) => {
  
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [openModal, setOpenModal] = useState(false);

  const [rowData, setRowData] = useState(null);
  const [modify, setModify] = useState(false);

  return (
    <div
      style={{
        minHeight: "87vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid paddingLeft={5} paddingTop={5}>
        <SupplierTable
          paging={false}
          tableHeight={480}
          showViewAll={false}
          ShowGoBack={true}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setRowData={setRowData}
          viewSearch={true}
          modify={modify}
        />
      </Grid>
      <SupplierModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        setModify={setModify}
        modify={modify}
      />
    </div>
  );
};

export default SupplierTableView;
