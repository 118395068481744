import * as constFile from "../../salesOrderConst";
import axios from "axios";
var fileDownload = require("js-file-download");

const GET_LINE_SHAPE_INPUT_DATA =
  constFile.GET_LINE_SHAPE_INPUT_DATA;

export const getLineShapeInputData = (factoryId,diameter) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_LINE_SHAPE_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FactoryId : factoryId,
        Diameter: diameter
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_FACTORY_PRODUCT_REFERENCES =
  constFile.GET_FACTORY_PRODUCT_REFERENCES;

export const getFactoryProductReferences = (factoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_FACTORY_PRODUCT_REFERENCES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FactoryId : factoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_WEIGHT_PEREMETER =
  constFile.GET_WEIGHT_PEREMETER;

export const getWeigthPeremeter = (factoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_WEIGHT_PEREMETER}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FactoryId : factoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_PROJECT_ORDER_BOOKS_CARDS_LIST =
  constFile.GET_PROJECT_ORDER_BOOKS_CARDS_LIST;

export const getProjectOrderBooksCardList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PROJECT_ORDER_BOOKS_CARDS_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_PROJECT_ORDER_BOOKS_TABLE_LIST =
  constFile.GET_PROJECT_ORDER_BOOKS_TABLE_LIST;

export const getProjectOrderBooksTableList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_PROJECT_ORDER_BOOKS_TABLE_LIST}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CREATE_SALES_ORDER = constFile.CREATE_SALES_ORDER;

export const createSalesOrder = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_SALES_ORDER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_SALES_ORDER_CARDS_BY_PROJECTID =
  constFile.GET_SALES_ORDER_CARDS_BY_PROJECTID;

export const getSalesOrderCards = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SALES_ORDER_CARDS_BY_PROJECTID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_SALES_ORDER_TABLE_BY_PROJECTID =
  constFile.GET_SALES_ORDER_TABLE_BY_PROJECTID;
export const getSalesOrderTable = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_SALES_ORDER_TABLE_BY_PROJECTID}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_SALES_ORDER_DASHBOARD = constFile.GET_SALES_ORDER_DASHBOARD;
export const getSalesOrderDashboard = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SALES_ORDER_DASHBOARD}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_SALES_PROJECTS_ORDER_DASHBOARD =
  constFile.GET_SALES_PROJECTS_ORDER_DASHBOARD;
export const getSalesProjectOrderDashboard = (StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SALES_PROJECTS_ORDER_DASHBOARD}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        StatusId: StatusId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const GET_PUBLISHED_SHAPES = constFile.GET_PUBLISHED_SHAPES;

export const getPublishedShapes = (search, listData) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_PUBLISHED_SHAPES}`, listData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: search,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const CREATE_OR_EDIT_DATA = constFile.CREATE_OR_EDIT_DATA;
export const createOrEditSalesOrder = (
  SalesOrderId,
  SubProjectId,
  ActivityId,
  Parent
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_OR_EDIT_DATA}`,

      {
        SalesOrderId: SalesOrderId,
        SubProjectId: SubProjectId,
        ActivityId: ActivityId,
        Parent: Parent,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPLOAD_FILE = constFile.UPLOAD_FILE;
export const uploadFile = (file, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", ProjectId);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_FILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const DELETE_FILE = constFile.DELETE_FILE;
export const deleteFile = (fileId, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        ProjectId: ProjectId ? ProjectId : "",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const UPDATE_SALES_ORDER = constFile.UPDATE_SALES_ORDER;

export const updateSalesOrder = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_SALES_ORDER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const PLACE_SALES_ORDER = constFile.PLACE_SALES_ORDER;

export const placeSalesOrder = (Id, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + PLACE_SALES_ORDER}`,
      {
        Id: Id,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const PROJECTS_LIST_DATA = constFile.PROJECTS_LIST_DATA;
export const getProjectListData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + PROJECTS_LIST_DATA}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const CHANGE_SALES_ORDER_STATUS = constFile.CHANGE_SALES_ORDER_STATUS;

export const changeSalesOrderStatus = (Id, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + PLACE_SALES_ORDER}`,
      {
        Id: Id,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CALCULATE_ACTUAL_LENGTH = constFile.CALCULATE_ACTUAL_LENGTH;

export const calculateActualLength = (Shape) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CALCULATE_ACTUAL_LENGTH}`, Shape, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const SUGGEST_DATE = constFile.SUGGEST_DATE;

export const suggestDate = (Id, Date, Comment) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SUGGEST_DATE}`,
      {
        Id: Id,
        Date: Date,
        Comment: Comment,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const ACCEPT_SUGGESTED_DATE = constFile.ACCEPT_SUGGESTED_DATE;

export const acceptSuggestedDate = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + ACCEPT_SUGGESTED_DATE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const IMPORT_SDI = constFile.IMPORT_SDI;

export const importSdi = (ActivityId, SubProjectId, FileId, CatalogueId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + IMPORT_SDI}`,
      {
        ActivityId: ActivityId,
        SubProjectId: SubProjectId,
        FileId: FileId,
        CatalogueId: CatalogueId,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const EXPORT_SDI = constFile.EXPORT_SDI;
export const exportSdi = (salesOrderId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + EXPORT_SDI}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        salesOrderId: salesOrderId,
      },
    })
    .then((response) => {
      fileDownload(response.data, "report.sdi");
      return response;
    })
    .catch((err) => {
      return err;
    });
};

const GET_DATASCENE_BY_ID = constFile.GET_DATASCENE_BY_ID;

export const getDataSceneById = (Id, MemberLineId, FactoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DATASCENE_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
        MemberLineId: MemberLineId,
        FactoryId: FactoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const SALES_ORDER_CALENDER = constFile.SALES_ORDER_CALENDER;
export const getCalender = (Date, FactoryId,StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + SALES_ORDER_CALENDER}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Date: Date,
        FactoryId: FactoryId,
        StatusId: StatusId
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.data;
    });
};

const PRINT_LABEL = constFile.PRINT_LABEL;

export const printLabel = (salesOrderId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PRINT_LABEL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        salesOrderId: salesOrderId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const HOLD_SO =
  constFile.HOLD_SO;

export const holdSO = (Id, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + HOLD_SO}`,
      {
        Id: Id,
        StatusId: StatusId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};