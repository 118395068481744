import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import { colors, Grid } from "@mui/material";
import RMNA from "../../../Assets/Images/ProductionLabel/rmmarked.png";
import REMNA from "../../../Assets/Images/ProductionLabel/remmarked.png";
import FGNA from "../../../Assets/Images/ProductionLabel/fgmarked.png";
import DNA from "../../../Assets/Images/ProductionLabel/DeliveryNoteIcon.png";

import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import * as ProductionLabelServices from "../../../Services/production-label-services.proxy";
import * as salesOrderConsts from "../../../../salesOrderConst";

const getProjectLabelList = ProductionLabelServices.getProjectLabelList;
const saveProjectLabel = ProductionLabelServices.saveProjectlabel;
const RAW_MATERIAL_LABEL_ID = salesOrderConsts.RAW_MATERIAL_LABEL_ID;
const REMNENT_LABEL_ID = salesOrderConsts.REMNENT_LABEL_ID;
const PRODUCT_LABEL_ID = salesOrderConsts.PRODUCT_LABEL_ID;
const FINISHED_GOODS_LABEL_ID = salesOrderConsts.FINISHED_GOODS_LABEL_ID;
const DELIVERY_NOTE_LABEL_ID = salesOrderConsts.DELIVERY_NOTE_LABEL_ID;


const ProjectLabels = (props) => {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const category = query?.get("category");

  const [projects, setProjects] = useState([]);
  const [factories, setFactories] = useState([]);

  const [rawMaterialLabels, setRawMaterialLabels] = useState([]);
  const [remnentLabels, setRemnentLabels] = useState([]);
  const [productLabels, setProductLabels] = useState([]);
  const [finishedGoodsLabels, setFinishedGoodsLabels] = useState([]);
  const [deliveryNoteLabels, setDeliveryNoteLabels] = useState([]);

  const [dictionary, setDictionary] = useState([]);
  const [modify, setModify] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savedProject, setSavedProject] = useState("");

  const [searchText, setSearchText] = useState("");

  // get list
  useEffect(() => {
    let apiTest = true;
    // setLoadingData(true);
    getProjectLabelList(searchText ? searchText : "", category).then((x) => {
      console.log(x.factories);
      if (x.ProjectLabels) {
        setProjects(x.ProjectLabels);
      }
      if (x.factories) {
        setFactories(x.factories);
      }

      setRawMaterialLabels(x?.RawMaterialLabels);
      setRemnentLabels(x?.RemnentLabels);
      setFinishedGoodsLabels(x?.FinishedGoodsLabels);
      setDeliveryNoteLabels(x?.DeliveryNotesLabels);
      setProductLabels(x?.ProductLabels);
      if (x.factories) {
        setDictionary(
          Object.assign(
            {},
            ...structuredClone(x.factories).map((pl) => ({
              [pl.InventoryId]: pl,
            }))
          )
        );
      }

      if (x.ProjectLabels) {
        setDictionary(
          Object.assign(
            {},
            ...structuredClone(x.ProjectLabels).map((pl) => ({
              [pl.ProjectId]: pl,
            }))
          )
        );
      }
    });
    return () => {
      apiTest = false;
    };
  }, [searchText]);

  // force update
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const projectLabelHandler = (data) => {
    //construct project label object
    const labels = [];
    if (data.RawMaterialLabelId != null) {
      // add raw materail label
      labels.push({
        Id: data.RawMaterialLabelId,
        LabelTypeId: RAW_MATERIAL_LABEL_ID,
      });
    }

    if (data.RemnentLabelId != null) {
      // add raw materail label
      labels.push({
        Id: data.RemnentLabelId,
        LabelTypeId: REMNENT_LABEL_ID,
      });
    }

    if (data.ProductLabelId != null) {
      // add product label
      labels.push({
        Id: data.ProductLabelId,
        LabelTypeId: PRODUCT_LABEL_ID,
      });
    }

    if (data.FinishedGoodsLabelId != null) {
      // add raw materail label
      labels.push({
        Id: data.FinishedGoodsLabelId,
        LabelTypeId: FINISHED_GOODS_LABEL_ID,
      });
    }
    if (data.DeliveyNotesLabelId != null) {
      // add Delivery Nie
      labels.push({
        Id: data.DeliveyNotesLabelId,
        LabelTypeId: DELIVERY_NOTE_LABEL_ID,
      });
    }
    const input = {
      ProjectId: data.ProjectId ? data.ProjectId : null,
      Labels: labels,
      InventoryId: data.InventoryId ? data.InventoryId : null,
      CategoryId: category,
    };

    setSaving(true);
    if (category === "2") {
      setSavedProject(data.InventoryId);
    } else {
      setSavedProject(data.ProjectId);
    }
    saveProjectLabel(input).then((x) => {
      setSaving(false);
      setModify(!modify);

      // update dictionary
      if (category === "2") {
        dictionary[data.InventoryId].RawMaterialLabelId =
          data.RawMaterialLabelId;
        dictionary[data.InventoryId].RemnentLabelId = data.RemnentLabelId;
      }
      if (category === "1")
        dictionary[data.ProjectId].FinishedGoodsLabelId =
          data.FinishedGoodsLabelId;
      dictionary[data.ProjectId].DeliveyNotesLabelId = data.DeliveyNotesLabelId;
      forceUpdate();
    });
  };

  return dictionary ? (
    <div
      style={{
        minHeight: props.adminPage ? "100%" : "85vh",
        backgroundImage: props.adminPage ? null : `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingTop={3.3} paddingLeft={1}>
        <Grid item md={6} lg={6} sm={6} xs={6}>
          <Grid item xs={12} className={classes["Production-Label"]}>
            Production Labels Templates
          </Grid>
          <Grid item xs={12} className={classes["Choose-options"]}>
            Choose the label template for each
            {category === "1" ? " project" : " inventory"}
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          md={6}
          sm={6}
          lg={6}
          container
          direction={"row"}
          paddingRight={{ md: 5 }}
        >
          <Grid item container xs={12} direction={"row-reverse"}>
            <Grid item paddingLeft={1}>
              <button
                className={classes["Go-Back"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </button>
            </Grid>

            <Grid item paddingLeft={4}>
              <button
                className={classes["Rectangle-60"]}
                onClick={() => {
                  navigate("/saas/admin/productionLabel/table");
                }}
              >
                Go to List
              </button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            paddingLeft={0.5}
            direction={"row-reverse"}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                onChange={inputHandler}
                placeholder="Search this list…"
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      fontSize="small"
                      htmlColor={"#707070"}
                      style={{ paddingLeft: 5 }}
                    />
                  ),
                }}
                sx={{
                  fontFamily: "Muli",
                  backgroundColor: "white",
                  borderRadius: 1,
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 0,
                    borderColor: "white",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          item
          lg={10}
          md={12}
          sm={12}
          paddingLeft={23}
          paddingRight={10}
          direction={"row-reverse"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {category === "1" ? (
            <>
              <Grid
                container
                xs={6}
                sm={6}
                md={5}
                lg={4}
                item
                direction={"row"}
              >
                <Grid item>
                  <img src={DNA} width={115} alt={"img"} />
                </Grid>
                <Grid
                  item
                  className={classes["Raw-Materials"]}
                  paddingTop={4}
                  width={50}
                >
                  Delivery Notes
                </Grid>
              </Grid>
              <Grid
                container
                xs={6}
                sm={6}
                md={5}
                lg={4}
                item
                direction={"row"}
                paddingLeft={{ xs: 3, sm: 0 }}
              >
                <Grid item>
                  <img src={FGNA} alt={"img"} />
                </Grid>
                <Grid
                  width={50}
                  className={classes["Raw-Materials"]}
                  paddingTop={4}
                  paddingLeft={{ xs: 3, sm: 0 }}
                >
                  Finished Goods
                </Grid>
              </Grid>
            </>
          ) : (
            <>
            <Grid
                container
                xs={6}
                sm={6}
                md={5}
                lg={4}
                item
                direction={"row"}
                paddingLeft={{ xs: 3, sm: 0 }}
              >
                <Grid item>
                  <img src={REMNA} alt={"img"} />
                </Grid>
                <Grid
                  width={50}
                  className={classes["Raw-Materials"]}
                  paddingTop={4}
                  paddingLeft={{ xs: 3, sm: 0 }}
                >
                  Products
                </Grid>
              </Grid>
              <Grid
                container
                xs={6}
                sm={6}
                md={5}
                lg={4}
                item
                direction={"row"}
                paddingLeft={{ xs: 3, sm: 0 }}
              >
                <Grid item>
                  <img src={REMNA} alt={"img"} />
                </Grid>
                <Grid
                  width={50}
                  className={classes["Raw-Materials"]}
                  paddingTop={4}
                  paddingLeft={{ xs: 3, sm: 0 }}
                >
                  Remnants
                </Grid>
              </Grid>
              <Grid
                container
                xs={6}
                sm={6}
                md={5}
                lg={4}
                item
                direction={"row"}
              >
                <Grid item>
                  <img src={RMNA} alt={"img"} />
                </Grid>
                <Grid
                  item
                  className={classes["Raw-Materials"]}
                  paddingTop={4}
                  width={{ sm: 50, xs: 100 }}
                >
                  Raw Material
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          xs={12}
          lg={12}
          md={12}
          item
          className={classes["Production-Label"]}
          paddingLeft={{ sm: "5%", xs: 0 }}
        >
          {category === "1" ? "Projects List" : "Inventories List"}
        </Grid>
        {category === "1"
          ? projects?.map((project) => {
              return (
                <Grid
                  container
                  item
                  paddingTop={"8px"}
                  paddingLeft={{ sm: "5%", xs: 0 }}
                  key={project.ProjectId}
                >
                  <Grid
                    lg={2}
                    md={2}
                    sm={2.8}
                    xs={3}
                    item
                    className={classes["Rectangle-1464"]}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <label className={classes["Project-Name"]}>
                      {project.ProjectName}
                    </label>
                  </Grid>

                  <Grid
                    container
                    item
                    lg={10}
                    md={10}
                    sm={8}
                    xs={8}
                    paddingLeft={5}
                    gap={2}
                  >
                    <Grid lg={3} sm={5.5} item md={5} xs={5.5}>
                      <CustomSelect
                        value={project.FinishedGoodsLabelId}
                        onChange={(option) => {
                          project.FinishedGoodsLabelId = option;
                          forceUpdate();
                        }}
                      >
                        <StyledOption value={0} disabled>
                          Label
                        </StyledOption>
                        {finishedGoodsLabels.map((l) => (
                          <StyledOption key={l.Id} value={l.Id}>
                            {l.Name}
                          </StyledOption>
                        ))}
                      </CustomSelect>
                    </Grid>
                    <Grid lg={3} sm={5.5} item md={5} xs={5.5}>
                      <CustomSelect
                        value={project.DeliveyNotesLabelId}
                        onChange={(option) => {
                          project.DeliveyNotesLabelId = option;
                          forceUpdate();
                        }}
                      >
                        <StyledOption value={0} disabled>
                          Label
                        </StyledOption>
                        {deliveryNoteLabels.map((l) => (
                          <StyledOption key={l.Id} value={l.Id}>
                            {l.Name}
                          </StyledOption>
                        ))}
                      </CustomSelect>
                    </Grid>

                    {(dictionary[project.ProjectId]?.FinishedGoodsLabelId ===
                      project.FinishedGoodsLabelId &&
                      dictionary[project.ProjectId]?.DeliveyNotesLabelId ===
                        project.DeliveyNotesLabelId) !== true ? (
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={1}
                        marginLeft={{ lg: 2, md: 0 }}
                      >
                        <button
                          className={classes["Rectangle-60"]}
                          onClick={() => {
                            projectLabelHandler(project);
                          }}
                        >
                          {saving && savedProject === project.ProjectId
                            ? "Saving..."
                            : "Save"}
                        </button>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              );
            })
          : factories?.map((factory) => {
              return (
                <Grid
                  container
                  item
                  paddingTop={"8px"}
                  paddingLeft={{ sm: "5%", xs: 0 }}
                  key={factory.InventoryId}
                >
                  <Grid
                    lg={2}
                    md={2}
                    sm={2.8}
                    xs={3}
                    item
                    className={classes["Rectangle-1464"]}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <label className={classes["Project-Name"]}>
                      {factory.InventoryName}
                    </label>
                  </Grid>

                  <Grid
                    container
                    item
                    lg={10}
                    md={10}
                    sm={8}
                    xs={8}
                    paddingLeft={5}
                    gap={2}
                  >
                    <>
                      <Grid lg={3} sm={5.5} item md={5} xs={5.5}>
                        <CustomSelect
                          value={factory.RawMaterialLabelId}
                          onChange={(option) => {
                            factory.RawMaterialLabelId = option;
                            forceUpdate();
                          }}
                        >
                          <StyledOption value={0} disabled>
                            Label
                          </StyledOption>
                          {rawMaterialLabels.map((l) => (
                            <StyledOption key={l.Id} value={l.Id}>
                              {l.Name}
                            </StyledOption>
                          ))}
                        </CustomSelect>
                      </Grid>
                      <Grid md={5} sm={5.5} lg={3} item xs={5.5}>
                        <CustomSelect
                          value={factory.RemnentLabelId}
                          onChange={(option) => {
                            factory.RemnentLabelId = option;
                            forceUpdate();
                          }}
                        >
                          <StyledOption value={0} disabled>
                            Label
                          </StyledOption>
                          {remnentLabels.map((l) => (
                            <StyledOption key={l.Id} value={l.Id}>
                              {l.Name}
                            </StyledOption>
                          ))}
                        </CustomSelect>
                      </Grid>
                      <Grid lg={3} sm={5.5} item md={5} xs={5.5}>
                        <CustomSelect
                          value={factory.ProductLabelId}
                          onChange={(option) => {
                            factory.ProductLabelId = option;
                            forceUpdate();
                          }}
                        >
                          <StyledOption value={0} disabled>
                            Label
                          </StyledOption>
                          {productLabels.map((l) => (
                            <StyledOption key={l.Id} value={l.Id}>
                              {l.Name}
                            </StyledOption>
                          ))}
                        </CustomSelect>
                      </Grid>
                    </>

                    {(dictionary[factory.InventoryId]?.RemnentLabelId ===
                      factory.RemnentLabelId &&
                      dictionary[factory.InventoryId]?.RawMaterialLabelId ===
                        factory.RawMaterialLabelId  &&
                      dictionary[factory.InventoryId]?.ProductLabelId ===
                        factory.ProductLabelId) !== true ? (
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={1}
                        marginLeft={{ lg: 2, md: 0 }}
                      >
                        <button
                          className={classes["Rectangle-60"]}
                          onClick={() => {
                            projectLabelHandler(factory);
                          }}
                        >
                          {saving && savedProject === factory.InventoryId
                            ? "Saving..."
                            : "Save"}
                        </button>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              );
            })}
      </Grid>
      <div style={{ height: 30 }}></div>
    </div>
  ) : (
    ""
  );
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 15px;
        box-sizing: border-box;
        width: 100%;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        text-align: left;
        color: #bcbcbc;
        padding:5px;
        &:hover {
          background: ${theme.palette.mode === "dark" ? "" : grey[100]};
          border-color: ${
            theme.palette.mode === "dark" ? grey[700] : grey[400]
          };
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${
            theme.palette.mode === "dark" ? blue[600] : blue[100]
          };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
        }
      
        &::after {
          content: '▾';
          float: right;
        }
        `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 0.875rem;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        width: fit-content;
        overflow-x:hidden;
        max-height:250px !important;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[800] : grey[300]
        };
        border-radius: 0.75em;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        overflow: auto;
        outline: 0px;
        `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: default;
        width: 90%;
        
margin: 0 79px 0 0;
font-family: Muli;
font-size: 15px;
line-height: 1.47;
text-align: left;
color: #707070; 
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${
            theme.palette.mode === "dark" ? blue[900] : blue[100]
          };
          color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${
            theme.palette.mode === "dark" ? grey[800] : grey[100]
          };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${
    optionUnstyledClasses.selected
  } {
          background-color: ${
            theme.palette.mode === "dark" ? blue[900] : blue[100]
          };
          color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color: ${
            theme.palette.mode === "dark" ? grey[800] : grey[100]
          };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default ProjectLabels;
