import React, { useEffect, useState, useRef } from 'react';
import Timeline from 'react-visjs-timeline';
import { Grid, TextField } from '@mui/material';
import * as activityServices from "../../../Services/activities-services.proxy";
import ErrorModal from '../../../SharedComponents/ErrorModal';
import classes from "../../../Assets/Styles/ActivitiesTimeline.module.css";
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DatePickerModal from './DatePickerModal';
import { useNavigate } from 'react-router-dom';
import DeleteActivityModal from '../DeleteActivityModal';
import * as StatusConst from "../../../../StatusConst";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const addActivity = activityServices.addActivity;
const updateActivity = activityServices.updateActivity;
const deleteActivity = activityServices.deleteActivity;

const ActivityTimeline = (props) => {

    const today = new Date();
    const currentYear = today.getFullYear();

    const navigate = useNavigate();

    const startDateYearsAgo = new Date(currentYear - 2, 0, 1).toISOString();
    const endDatePostYears = new Date(currentYear + 2, 11, 31, 23, 59, 59, 999).toISOString();

    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openActModal, setOpenActModal] = useState(false);

    const [visibleWindow, setVisibleWindow] = useState({
        start: startDateYearsAgo,
        end: endDatePostYears
    });
    const timelineContainerRef = useRef(null);
    const [selectedTimeline, setSelectedTimeline] = useState(null);

    const [openSubsTree, setOpenSubsTree] = useState(false);

    const [selectedSubprojectName, setSelectedSubprojectName] = useState(props.phase?.Sections[0]?.Name);
    const [selectedSubprojectId, setSelectedSubprojectId] = useState(props.phase?.Sections[0]?.Id)
    const [renderedSubsIds, setRenderedSubsIds] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [itemName, setItemName] = useState('');
    const [plannedDuration, setPlannedDuration] = useState();

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);

    //loading and error handling
    const [BEerror, setBEerror] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [addLoading, setAddLoading] = useState(false);

    //Delete Modal
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [modalMessage, setModalMessage] = useState();
    const [deletingAct, setDeletingAct] = useState(false);
    const [actIdToDelete, setActIdToDelete] = useState(null);

    const verticalLines = [
        { id: 'vertical-line-1', date: '2024-04-23', message: 'Important Event 1' },
        { id: 'vertical-line-2', date: '2024-04-25', message: 'Important Event 2' },
        // Add more vertical lines as needed
    ];

    useEffect(() => {
        setSelectedSubprojectName(props.phase.Sections[0]?.Name);
        setSelectedSubprojectId(props.phase?.Sections[0]?.Id)
        setSelectedDate(new Date())
    }, []);

    const deleteTheActivity = () => {
        setDeletingAct(true);
        deleteActivity(actIdToDelete).then((x) => {
            if (x.status || x.statusCode) {
                if (x.status) setBEerror(x.error)
                if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                setOpenErrorModal(true);
            } else {
                props.setGroups([]);
                props.setNestedGroups([]);
                props.setItems([]);
                props.setModify(!props.modify);
            }
            setDeletingAct(false);

        });
    };

    const addTheActivity = () => {
        const ActivityDetails = {
            SubProjectId: selectedSubprojectId,
            PlannedStartDate: selectedDate,
            Code: itemName,
            PlannedDuration: plannedDuration,

        };
        setAddLoading(true);
        addActivity(ActivityDetails).then((x) => {
            if (x.status || x.statusCode) {
                if (x.status) setBEerror(x.error)
                if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                setOpenErrorModal(true);
            } else {
                setPlannedDuration(null);
                setItemName(null);
                setOpenActModal(false);
                props.setGroups([]);
                props.setNestedGroups([]);
                props.setItems([]);
                props.setModify(!props.modify);
            }
            setAddLoading(false);
        })
    };

    const updateTheActivity = (actId, newPlannedDuration) => {
        const ActivityDetails = {
            Id: actId,
            PlannedDuration: newPlannedDuration,
        };
        updateActivity(ActivityDetails).then((x) => {
            if (x.status || x.statusCode) {
                if (x.status) setBEerror(x.error)
                if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                setOpenErrorModal(true);
            } else {
                props.setModify(!props.modify);
            }

        });
    };

    // Function to handle clicking on the timeline to select a date
    // const handleTimelineClick = (properties) => {
    //     console.log("propertiestime", properties.time);
    //     setSelectedTimeline(props.subproject.Id);
    //     if (properties.time && selectedItemId === null) {
    //         // setSelectedDate(properties.time);
    //     }
    // };

    const weekendAndNonWorkingStyle = {
        background: '#E8E8E8',
    };

    // const GroupLabel = ({ group, handleButtonClick }) => (
    //     <div>
    //       <div style={{color:"red"}}> {group.content}</div> 
    //       <div style={{color:"orange"}}>{group.actCount}</div>
    //     </div>
    //   );

    const options = {
        orientation: {
            axis: 'top',
            item: 'top' // Display items at the top
        },
        stack: true,
        maxHeight: 600,
        minHeight: 350,
        horizontalScroll: false,
        verticalScroll: true,
        zoomMin: 24 * 60 * 60 * 500, // 24 hours in milliseconds
        zoomMax: 126144000000,
        // start: visibleWindow.start,
        // end: visibleWindow.end,
        min: startDateYearsAgo,
        max: endDatePostYears,
        showCurrentTime: false, // Disable the current time indicator
        // groupTemplate: (group, element) => {
        //     console.log("grouppp",group)
        //     if (!group) return;
        //     ReactDOM.unmountComponentAtNode(element);
        //     const parts = group.content.split(' ');
        //     parts.forEach(part => {
        //       const span = document.createElement('span');
        //       span.textContent = part + ' ';
        //       if (part === 'Hello' || part === 'Everyone') {
        //         span.style.color = 'black';
        //       } else if (part === 'Today') {
        //         span.style.color = 'red';
        //       }
        //       element.appendChild(span);
        //     });
        //     return "";
        //   },
        // groupTemplate: (group, element) => {
        //     if (!group) return;
        //     ReactDOM.render(<div style={{color:"red"}}>{group.content}<div style={{color:"blue"}}>hlllo</div></div>, element);
        //     return "";
        // },
        // groupTemplate: (group, element) => {
        //     if (!group) return;
        //     // Separate the words to apply different colors
        //     const words = group.content.split(' ');
        //     element.innerHTML = `
        //       <div style="color: black;">
        //         ${words.slice(0, 2).join(' ')} 
        //         <span style="color: red;">${words.slice(2).join(' ')}</span>
        //       </div>
        //     `;
        //   },
        // groupTemplate: (group, element) => {
        //     if (!group) return;
        //     // Render custom HTML for each group using a React component
        //     ReactDOM.render(
        //       <GroupLabel group={group} />,
        //       element
        //     );
        //     return "";
        //   },

        // onMoving: function (item, callback) {
        //     if (item.statusId !== 1) {
        //         callback(null)
        //     } else {
        //         callback(item)
        //     }
        // },
        // onMove: function (item, callback) {
        //     // console.log("item", item);
        //     if (item.statusId !== 1) {
        //         callback(null)
        //     } else {
        //         callback(item)
        //     }
        //     let newPlannedDuration = (moment(item.end).diff(moment(item.start))) / 3600000;
        //     updateTheActivity(item.id, newPlannedDuration)
        // },
        onRemove: function (item, callback) {
            setActIdToDelete(item.id);
            if (item.statusId !== StatusConst.DRAFT_ID) {
                setModalMessage(
                    "You can't delete this activity because it is not draft "
                );
            }
            setOpenDeleteModal(true);
        },
        editable:
        {
            add: false,         // add new items by double tapping
            updateTime: false,  // drag items horizontally
            updateGroup: false, // drag items from one group to another
            remove: true       // delete an item by tapping the delete button top right
        }
    };

    let timelineRef = useRef(null);
    useEffect(() => {
        if (timelineRef.current) {
            const timeline = timelineRef.current?.$el;
            const selectHandler = (event, properties) => {
                const itemId = event.items[0];
                const itemIds = event.items;
                const items = timeline.itemsData.get(itemIds);

                if (items?.length === 0) setOpenActModal(true);
                else setOpenActModal(false);

                if (itemId === selectedItemId && items?.length > 0) {
                    let itemObject = props.items.find((i) => i.id === selectedItemId);
                    // Second click, navigate
                    navigate("/saas/execution/create", {
                        state: { id: itemId, edit: itemObject.isCreator === true ? true : false },
                    });
                } else {
                    // First click, select and focus the item
                    setSelectedItemId(itemId);
                    timeline.focus(itemId, { animation: false });
                }
                // console.log('Selected item(s):', items);
            };

            const blurHandler = () => {
                // Reset selected item when focus is lost
                setSelectedItemId(null);
            };

            timeline.on('select', selectHandler);
            timeline.on('blur', blurHandler);

            return () => {
                timeline.off('select', selectHandler);
                timeline.off('blur', blurHandler);

            };
        }
    }, [timelineRef, selectedItemId]);

    useEffect(() => {
        if (timelineRef.current) {
            props.milestones.forEach(({ id, date, message }) => {
                const dateTime = new Date(date);
                timelineRef.current.$el.addCustomTime(dateTime, id);
                timelineRef.current.$el.setCustomTimeTitle(message, id);  // Set the title using the message
            });
        }
    }, [])

    const renderSubprojects = (subprojects) => {
        return subprojects.map((sc) => (
            <>
                <Grid item container direction="row" key={sc.Id} borderRadius="5px" padding={.5} style={{ fontFamily: "Muli", fontSize: "14px" }} zIndex={2}
                    bgcolor={selectedSubprojectId === sc.Id ? "#518de1" : "white"} color={selectedSubprojectId === sc.Id ? "white" : "#808080"}>
                    <Grid item onMouseOver={(e) => e.target.style.cursor = "pointer"}
                        onClick={() => {
                            setSelectedSubprojectId(sc.Id);
                            setSelectedSubprojectName(sc.Name);
                        }
                        }> {sc.Name}</Grid>
                    <Grid item>
                        {sc.Sections?.length > 0 ?
                            (
                                renderedSubsIds?.includes(sc.Id) ?
                                    <ArrowDropUpIcon
                                        onMouseOver={(e) => { e.target.style.cursor = "pointer" }}
                                        onClick={() => {
                                            let arrayIDs = renderedSubsIds;
                                            arrayIDs = arrayIDs.filter(function (sub) {
                                                return sub !== sc.Id;
                                            });
                                            setRenderedSubsIds(arrayIDs);
                                        }}
                                        fontSize='small' style={{ color: selectedSubprojectId === sc.Id ? "white" : "grey" }}
                                    />
                                    :
                                    <ArrowDropDownIcon
                                        onMouseOver={(e) => { e.target.style.cursor = "pointer" }}
                                        onClick={() => {
                                            setRenderedSubsIds(renderedSubsIds.concat(sc.Id));
                                        }}
                                        fontSize='small' style={{ color: selectedSubprojectId === sc.Id ? "white" : "grey" }}
                                    />
                            )
                            :
                            <></>
                        }
                    </Grid>

                </Grid>
                {renderedSubsIds?.includes(sc.Id) ? (
                    renderSubprojects(sc.Sections)
                ) : (
                    <></>
                )}
            </>
        ))
    }
    return (
        <>
            {props.groups ?
                <>
                    <Grid item ref={timelineContainerRef} style={{ position: 'relative', overflowX: 'scroll', borderRadius: "10px" }} bgcolor="white">
                        <Timeline
                            ref={(ref) => { timelineRef.current = ref; }}
                            // currentTime={props.subproject.EndDate}
                            items={props.items}
                            groups={props.groups}
                            options={options}
                            selection={[selectedItemId]}
                        // clickHandler={handleTimelineClick}
                        // visibleWindow={visibleWindow}
                        />
                    </Grid>
                    <style>{`
                    .vis-time-axis .vis-text{
                        font-family: Muli;
                        color: #a7a8ab;
                    }
                    .vis-custom-time{
                        background-color:#8fb1d5;
                    }
                    .vis-foreground .vis-group{position:relative;box-sizing:border-box;
                    border-bottom:none
                    }
                    .vis-ltr .vis-label.vis-nested-group .vis-inner{padding-left:0px}
                    {/* .vis-itemset.vis-nested .vis-group {
    border-color: transparent !important;
  } */}
                    .vis-timeline{
                       border-radius:5px;
                        border: none !important;
                    }
                    .vis-label .vis-inner {
                       min-height: 70px;
                    }
        ${props.weekendDays.map(day => `
       
          .vis-time-axis .vis-grid.vis-${day.toLowerCase()} {
            background: ${weekendAndNonWorkingStyle.background};
          }
          .vis-time-axis .vis-text.vis-${day.toLowerCase()} {
            color: ${weekendAndNonWorkingStyle.color};
          }
        `).join('')}
        ${props.nonWorkingHours.map(hour => `
          .vis-time-axis .vis-grid.vis-h${hour} {
            background: ${weekendAndNonWorkingStyle.background};
          }
        `).join('')}
       */}
      `}</style>
                    {openActModal && (
                        <Grid item container zIndex={1} direction="column" xs={10} md={5} left={{ xs: timelineContainerRef?.current?.offsetLeft, md: timelineContainerRef?.current?.offsetLeft + 500 }} style={{
                            position: 'absolute',
                            top: timelineContainerRef?.current?.offsetTop + 80,
                            padding: '10px',
                            borderRadius: '5px',
                            fontFamily: "Muli"
                        }}>
                            <Grid item bgcolor="#518de1" width="140px" style={{ fontSize: "12px", color: "white", fontWeight: "bold", borderTopLeftRadius: "5px", borderTopRightRadius: '5px' }} padding={1}>
                                Create a new Activity
                            </Grid>
                            <Grid item xs={12} container direction="row" columnGap={1} alignItems="center"
                                style={{
                                    backgroundColor: '#ffffff',
                                    border: 'solid 2px #518de1',
                                    padding: '10px',
                                    borderBottomRightRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                    borderTopRightRadius: '5px'
                                }}
                            >
                                <Grid item container justifyContent="flex-end">  <CloseIcon
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer"
                                    }}
                                    onClick={() => {
                                        setOpenActModal(false);
                                        setPlannedDuration(null);
                                        setItemName(null)
                                    }}
                                    htmlColor='518de1' style={{ color: "#518de1", fontSize: '15px' }} /></Grid>
                                <Grid item xs={12} md={2} style={{ color: "grey", fontFamily: "Muli", fontSize: "12px" }}>
                                    <span > In</span>
                                    <Grid item container direction="row" alignItems="center" style={{ fontFamily: "Muli", fontSize: "12px", height: "30px" }}>
                                        {selectedSubprojectName?.length > 10 ? selectedSubprojectName.slice(0, 10) + "..." : selectedSubprojectName}
                                        {openSubsTree ?
                                            <KeyboardArrowUpIcon
                                                onMouseOver={(e) => { e.target.style.cursor = "pointer" }}
                                                onClick={() => {
                                                    setOpenSubsTree(false);
                                                }}
                                                fontSize='small' style={{ color: "grey" }} />
                                            :
                                            <KeyboardArrowDownIcon
                                                onMouseOver={(e) => { e.target.style.cursor = "pointer" }}
                                                onClick={() => {
                                                    setOpenSubsTree(true);
                                                }}
                                                fontSize='small' style={{ color: "grey" }} />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <span style={{ color: "grey", fontFamily: "Muli", fontSize: "12px" }}> Content</span>
                                    <TextField
                                        fullWidth
                                        value={itemName}
                                        InputProps={{
                                            style: {
                                                fontSize: 12,
                                                fontFamily: "Muli",
                                                height: "30px"
                                            },
                                        }}
                                        onChange={(e) => {
                                            setItemName(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <span style={{ color: "grey", fontFamily: "Muli", fontSize: "12px" }}> Duration (hrs)</span>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        value={plannedDuration}
                                        InputProps={{
                                            style: {
                                                fontSize: 12,
                                                fontFamily: "Muli",
                                                height: "30px"

                                            },
                                            inputProps: { min: 0 },
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value > 0) setPlannedDuration(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item container direction="row" alignItems="center" xs={12} md={2.5} paddingTop={2} columnGap={0.7}>
                                    <Grid item style={{ fontFamily: "Muli", fontSize: "13px", color: "#0674b9" }}>{moment(selectedDate).format('ll')}</Grid>
                                    <Grid item>
                                        <CalendarTodayIcon style={{ fontSize: '16px', color: "#0674b9" }}
                                            onMouseOver={(e) => {
                                                e.target.style.cursor = 'pointer'
                                            }}
                                            onClick={() => {
                                                setOpenDatePicker(true)
                                            }}
                                        /></Grid> </Grid>

                                <Grid item container xs={12} md={1.8} direction="column" alignItems="flex-end" justifyContent="flex-end">
                                    <button onClick={addTheActivity}
                                        className={classes['save-button']}
                                        disabled={!selectedDate || !plannedDuration || addLoading || !selectedSubprojectId}
                                    >{addLoading ? "Saving..." : "Save"}</button>
                                </Grid>
                            </Grid>
                            {openSubsTree ?
                                <Grid item container boxShadow="0 3px 6px 0 #2d357e" borderRadius="5px" bgcolor="white"
                                    marginTop={1.5} padding={1} width="60%">{renderSubprojects(props.phase.Sections)}</Grid>
                                :
                                <></>}
                        </Grid>
                    )}
                </>
                :
                <></>
            }
            <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
            <DatePickerModal
                openDatePicker={openDatePicker}
                setOpenDatePicker={setOpenDatePicker}
                timelineContainerRef={timelineContainerRef}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />
            <DeleteActivityModal
                openModal={openDeleteModal}
                setOpenModal={setOpenDeleteModal}
                deleteTheActivity={deleteTheActivity}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                deletingAct={deletingAct}
            />
        </>
    )
}

export default ActivityTimeline
