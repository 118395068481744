import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Grid, TextField, Button, CircularProgress, Backdrop,
  ClickAwayListener,
  CssBaseline,
  Fade,
  List,
  ListItemButton,
  Paper,
  Box,
} from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import TMIcon from "../../../Assets/Images/Table/TmRevised.png";
import SubIcon from "../../../../Assets/Images/Navbar/subIcon.png";
import MainIcon from "../../../../Assets/Images/Navbar/mainIcon.png";
import DeleteCommentModal from "./DeleteCommentModal";
import * as commentServices from "../../../Services/activities-services.proxy";
import * as activityServices from "../../../Services/activities-services.proxy";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";

import fileIcon from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";

import { atom, RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil';
import * as activityConst from "../../../../StatusConst";


const getCommentByActivity = commentServices.getCommentByActivity;
const addCommentOnActivity = commentServices.addCommentOnActivity;
const deleteComment = commentServices.deleteComment;
const UpdateCommentOnActivity = commentServices.UpdateCommentOnActivity;
const tMUploadFile = activityServices.tMUploadFile;



const CommentSection = (props) => {
  const [addComment, setAddComment] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [open, setOpen] = useState(false);
  const [chosenId, setchosenId] = useState(null);

  const profile = null;
  const [commentText, setCommentText] = useState(null);
  const [commentTextError, setCommentTextError] = useState(null);
  const [commentTextErrorMessage, setCommentTextErrorMessage] = useState(null);
  const [commentEditText, setCommentEditText] = useState(null);
  const [commentEditTextError, setCommentEditTextError] = useState(null);
  const [commentEditTextErrorMessage, setCommentEditTextErrorMessage] =
    useState(null);
  const [comments, setcomments] = useState([]);
  const [modify, setModify] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const textFieldRef = useRef();
  const [modifyComment,setModifyComment] = useState(false);
  const [comment,setComment] = useState(null);

  useEffect(() => {
    console.log("testtt",textFieldRef);
    setComment(textFieldRef?.current?.value);

  },[modifyComment])

  const suggestionsState = atom({
    key: 'BandSuggestionsVisible',
    default: false,
  });

  const inputState = atom({
    key: 'SuggestableInputBands',
    default: editComment?comment:'',
  });


  const Suggestions = ({ field }) => {
    const [hasSuggestions, setHasSuggestions] = useRecoilState(suggestionsState);
    const [value, setValue] = useRecoilState(inputState);

    const handleClick = useCallback(
      (band) => {
        setValue(value + band);
        let tmp = value + band
        setCommentText(tmp.toString())
        setHasSuggestions(false);
        field.current?.focus();
      },
      [value, setValue, setHasSuggestions, field]
    );

    const handleClickAway = useCallback(() => {
      setHasSuggestions(false);
      field.current?.focus();
    }, [field, setHasSuggestions]);

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Fade in={hasSuggestions}>
          <List component={Paper} sx={{ mt: 1 }}>
            {props.toBeMentionedUsers?.map((u) => (
              <ListItemButton key={u.Id} onClick={() => handleClick(u.Name)}>
                {u.Name}
              </ListItemButton>
            ))}


          </List>
        </Fade>
      </ClickAwayListener>
    );
  };

  const SuggestionsField = () => {
    const setHasSuggestions = useSetRecoilState(suggestionsState);
    const [value, setValue] = useRecoilState(inputState);

    const handleChange = useCallback(
      (event) => {
        setModifyComment(!modify)
        localStorage.setItem("comment", event.target.value)
        setValue(event.target.value);
        if (event.target.value.match(/@$/)) {
          setHasSuggestions(true);
        } else setHasSuggestions(false);
      },
      [setHasSuggestions, setValue]
    );

    return (
      <Box p={4} display="inline-block" width="100%">
        <TextField
          fullWidth
          inputRef={textFieldRef}
          multiline
          label="Add Comment (@ to mention)"
          value={value}
          onChange={handleChange}
        />
        <Suggestions field={textFieldRef} />
      </Box>
    );
  };


  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFileUploading(true);
      tMUploadFile(e.target.files[0], props.subProjectId).then((x) => {
        setFile(x);
        setFileId(x.FileId);
        setFileUploading(false);
      });
    }
  };

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      // fileIds.splice(index, 1); // 2nd parameter means remove one item only
      setFileIds(fileIds.filter((f) => f.FileId !== item.FileId))
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      if (!files.includes(tempFile))
        files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId && !fileIds.find((f) => f.FileId === fileId)) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  useEffect(() => {
    setLoading(true);
    getCommentByActivity(props.activityId).then((x) => {
      setcomments(x);
      setLoading(false);
    });
  }, [modify, props.activityId]);
  useEffect(() => {
    if (props.revised) {
      setcomments([]);
    }
  }, [props.revised]);

  const filterUniqueValues = (arr, fieldName) => {
    // Use Set to store unique values
    const uniqueValues = new Set();

    // Use filter to filter unique values based on the field
    const filteredArray = arr.filter(item => {
      if (uniqueValues.has(item[fieldName])) {
        return false; // Duplicate found, exclude
      }
      uniqueValues.add(item[fieldName]);
      return true; // Unique value, include
    });

    return filteredArray;
  }

  const addTheComment = () => {
    setLoadings(true);
    // let ids = fileIds.map((f)=> f.FileId)
    let filteredIds = filterUniqueValues(fileIds, 'FileId');
    let mentions = [];
    let comment = textFieldRef.current.value;
    props.toBeMentionedUsers?.map((u) => {
      if (comment.includes("@")) {
        if (comment.includes(u.Name))
          mentions.push(u.Id)
      }
    })

    addCommentOnActivity(props.activityDetailsId, comment, filteredIds, mentions).then((x) => {
      setModify(!modify);
      setAddComment(false);
      setCommentText("");
      setLoadings(false);
    });
  };
  const deleteTheComment = () => {
    setLoadings(true);
    deleteComment(chosenId).then((x) => {
      setModify(!modify);
      setLoadings(false);
    });
  };
  const UpdateTheComment = () => {
    setLoadings(true);
    // let ids = fileIds.map((f)=> f.FileId)
    let filteredIds = filterUniqueValues(fileIds, 'FileId');
    let mentions = [];
    let comment = textFieldRef.current.value;
    props.toBeMentionedUsers?.map((u) => {
      if (comment.includes("@")) {
        if (comment.includes(u.Name))
          mentions.push(u.Id)
      }
    })
    UpdateCommentOnActivity(chosenId, props.activityDetailsId, comment, filteredIds).then(
      (x) => {
        setModify(!modify);
        setEditComment(false);
        setCommentEditText("");
        setchosenId(null);
        setLoadings(false);
      }
    );
  };
  return (
    <Grid container paddingBottom={5} paddingTop={2} direction={"column"}>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loading}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item className={classes["Activity-Details"]}>
        Comments
      </Grid>
      <Grid container paddingTop={4}>
        <Grid item xs={2} md={0.7}>
          <img
            src={
              profile
                ? profile
                : localStorage.getItem("isTeamMember") === "true"
                  ? TMIcon
                  : localStorage.getItem("AccountType") === "1"
                    ? MainIcon
                    : SubIcon
            }
            alt={"Icon"}
          />
        </Grid>
        {addComment ? (
          <>
            <Grid item container xs={12} direction="row" columnGap={0.5}>
              <Grid item xs={7}>
                <RecoilRoot>
                  <CssBaseline />
                  <SuggestionsField />
                </RecoilRoot>
              </Grid>
              {/* <Grid item xs={7}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    className: classes.Addcomment,
                  }}
                  multiline
                  InputProps={{ className: classes.DescriptionfieldDesign }}
                  size="small"
                  rows={6}
                  label={commentText ? null : "Add Comment..."}
                  value={commentText}
                  variant="outlined"
                  error={commentTextError}
                  helperText={commentTextErrorMessage}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setCommentTextError(true);
                      setCommentTextErrorMessage("Enter a Comment");
                    }
                  }}
                  onChange={(e) => {
                    setCommentText(e.target.value);
                    if (e.target.value.length > 500) {
                      setCommentTextError(true);
                      setCommentTextErrorMessage(
                        "Comment must be less than 500 char"
                      );
                    } else {
                      setCommentTextError(false);
                      setCommentTextErrorMessage(null);
                    }
                  }}
                />
              </Grid> */}
              <Grid item xs={4} container direction="column">
                <Grid item container direction="row" columnGap={1}>
                  <Grid item xs={6}>
                    <Button
                      component="label"
                      style={{
                        width: "100%",
                        marginTop: "23px",
                        textTransform: "none",
                        fontFamily: "Muli",
                        fontSize: "12px",
                        lineHeight: 1.45,
                        textAlign: "left",
                        color: "#101650",
                        backgroundColor: "white",
                        borderRadius: "6px",
                        border: "solid 1px #b8b8b8",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "30px",
                      }}
                    >
                      <FileUploadOutlinedIcon
                        htmlColor="#529bd7"
                        style={{
                          alignSelf: "center",
                          paddingRight: "5%",
                        }}
                      />
                      <span style={{ alignSelf: "center" }}>
                        Upload File
                      </span>
                      <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item style={{ fontFamily: "Muli", fontSize: "14px" }} marginTop={3}>

                    {fileUploading ?
                      <>
                        <CircularProgress size={12} /> Uploading ....
                      </> : ""}</Grid>
                </Grid>
                {files?.map((item) => (
                  <Grid
                    container
                    direction={"row"}
                    key={item.FileId}
                    value={item}
                    paddingTop={1}
                    alignItems="center"
                  >
                    <Grid item xs={1} paddingLeft={1} paddingTop={0.5}>
                      <img
                        src={localStorage.getItem("AccountType") === "1" ? fileIcon : fileIconSub}
                        alt="file"
                        width={15}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      onMouseOver={(e) => {
                        e.target.style.color = "grey";
                        e.target.style.cursor = "pointer";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "black";
                      }}
                      onClick={() => {
                        window.open(file?.URL);
                      }}
                    >
                      <span>{item?.DisplayName?.slice(0, 10)}...</span>
                    </Grid>
                    <Grid
                      item
                      paddingLeft={2}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        deleteItem(item);
                        setFile(null);
                        setFileId(null);
                        forceUpdate();
                      }}
                    >
                      <Grid item xs={1}>
                        <CloseIcon fontSize="small" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              gap={2}
              paddingTop={1}
              paddingLeft={7.5}
            >
              <Grid item>
                <button
                  disabled={
                    !comment || commentTextError || loadings ? true : false
                  }
                  className={classes["SaveButton"]}
                  onClick={() => {
                    addTheComment();
                  }}
                >
                  {loadings ? "Saving..." : "Save"}
                </button>
              </Grid>
              <Grid
                item
                className={classes["BackButton"]}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setAddComment(false);
                }}
              >
                Cancel
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            className={classes["Rectangle-1464"]}
            xs={7.8}
            height={"50px"}
            onMouseOver={(e) => {
              // if (props.status !== activityConst.DRAFT_ACTIVITY)
              e.target.style.cursor = "pointer";
              // else e.target.style.cursor = "unset";
            }}
            onClick={() => {
              // if (props.status !== activityConst.DRAFT_ACTIVITY) {
              setFiles([]);
              setFileIds([])
              setAddComment(true);
              // }

            }}
          >
            Add a comment...
          </Grid>
        )}
      </Grid>
      {comments.map((item) => (
        <Grid container paddingTop={4} key={item.Id}>
          <Grid item xs={2} md={0.7}>
            <img
              style={{ width: 50, borderRadius: 50 }}
              src={
                item.User.ProfilePicture
                  ? item.User.ProfilePicture.URL
                  : item.User.IsMainContractor === true
                    ? MainIcon
                    : item.User.IsTeamMember === true
                      ? TMIcon
                      : SubIcon
              }
              alt={"Icon"}
            />
          </Grid>
          {editComment && chosenId === item.Id ? (
            <>
              <Grid item container direction="row" xs={12} columnGap={1}>
                <Grid item xs={7.8}>
                  <RecoilRoot>
                    <CssBaseline />
                    <SuggestionsField />
                  </RecoilRoot>
                  {/* <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: false,
                      className: classes.Addcomment,
                    }}
                    multiline
                    InputProps={{ className: classes.DescriptionfieldDesign }}
                    size="small"
                    rows={6}
                    label={commentEditText ? null : "Add Comment..."}
                    value={commentEditText}
                    variant="outlined"
                    error={commentEditTextError}
                    helperText={commentEditTextErrorMessage}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setCommentEditTextError(true);
                        setCommentEditTextErrorMessage("Enter a Comment");
                      }
                    }}
                    onChange={(e) => {
                      setCommentEditText(e.target.value);
                      if (e.target.value.length > 500) {
                        setCommentEditTextError(true);
                        setCommentEditTextErrorMessage(
                          "Comment must be less than 500 char"
                        );
                      } else {
                        setCommentEditTextError(false);
                        setCommentEditTextErrorMessage(null);
                      }
                    }}
                  /> */}
                </Grid>
                <Grid item xs={4} container direction="column">
                  <Grid item container direction="row" alignItems="center" columnGap={1} fontFamily="Muli">
                    <Grid item xs={6}>
                      <Button
                        component="label"
                        style={{
                          width: "100%",
                          marginTop: "23px",
                          textTransform: "none",
                          fontFamily: "Muli",
                          fontSize: "12px",
                          lineHeight: 1.45,
                          textAlign: "left",
                          color: "#101650",
                          backgroundColor: "white",
                          borderRadius: "6px",
                          border: "solid 1px #b8b8b8",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          height: "30px",
                        }}
                      >
                        <FileUploadOutlinedIcon
                          htmlColor="#529bd7"
                          style={{
                            alignSelf: "center",
                            paddingRight: "5%",
                          }}
                        />
                        <span style={{ alignSelf: "center" }}>
                          Upload File
                        </span>
                        <input
                          type="file"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Grid>
                    <Grid item style={{ fontFamily: "Muli", fontSize: "14px" }} marginTop={1}>

                      {fileUploading ?
                        <>
                          <CircularProgress size={12} /> Uploading ....
                        </> : ""}</Grid>
                  </Grid>
                  {files?.map((item) => (
                    <Grid
                      container
                      direction={"row"}
                      key={item.FileId}
                      value={item}
                      paddingTop={1}
                      alignItems="center"
                    >
                      <Grid item xs={1} paddingLeft={1} paddingTop={0.5}>
                        <img
                          src={localStorage.getItem("AccountType") === "1" ? fileIcon : fileIconSub}
                          alt="file"
                          width={15}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        onMouseOver={(e) => {
                          e.target.style.color = "grey";
                          e.target.style.cursor = "pointer";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                        onClick={() => {
                          window.open(file?.URL);
                        }}
                      >
                        <span>{item?.DisplayName?.slice(0, 10)}...</span>
                      </Grid>
                      <Grid
                        item
                        paddingLeft={2}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          deleteItem(item);
                          setFile(null);
                          setFileId(null);
                          forceUpdate();
                        }}
                      >
                        <Grid item xs={1}>
                          <CloseIcon fontSize="small" />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction={"row"}
                gap={2}
                paddingTop={1}
                paddingLeft={7.5}
              >
                <Grid item>
                  <button
                    disabled={
                      !comment || commentEditTextError || loadings
                        ? true
                        : false
                    }
                    className={classes["SaveButton"]}
                    onClick={() => {
                      UpdateTheComment();
                    }}
                  >
                    {loadings ? "Saving..." : "Save"}
                  </button>
                </Grid>
                <Grid
                  item
                  className={classes["BackButton"]}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setEditComment(false);
                  }}
                >
                  Cancel
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item container xs={7.8}>
              <Grid
                item
                container
                direction={"row"}
                className={classes["commentorName"]}
              >
                {item.User.Name}
                <Grid
                  item
                  className={classes["commentDate"]}
                  paddingLeft={{ md: 3, xs: 0 }}
                  paddingTop={0.5}
                >
                  {new Date(item.CreationTime).toString()?.slice(0, 21)}
                </Grid>
              </Grid>
              <Grid item className={classes["CommentDesc"]} xs={12}>
                {item.Content}
              </Grid>
              {item.Files?.map((f) => (
                <Grid
                  container
                  direction={"row"}
                  key={f.FileId}
                  value={f}
                  paddingTop={1}
                >
                  <Grid item xs={1} paddingLeft={1} paddingTop={0.5}>
                    <img
                      src={localStorage.getItem("AccountType") === "1" ? fileIcon : fileIconSub}
                      alt="file"
                      width={15}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    onMouseOver={(e) => {
                      e.target.style.color = "grey";
                      e.target.style.cursor = "pointer";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "black";
                    }}
                    onClick={() => {
                      window.open(f?.URL);
                    }}
                  >
                    <span>{f?.DisplayName?.slice(0, 14)}...</span>
                  </Grid>
                </Grid>
              ))}
              {item.User.Id === localStorage.getItem("UserId") ? (
                <Grid item container direction={"row"} paddingTop={1}>
                  <Grid
                    item
                    className={classes["Edit-Delete"]}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setchosenId(item.Id);
                      item.Files.map(file => {
                        let temp = {
                          FileId: file.FileId
                        }
                        fileIds.push(temp);
                        temp = [];
                      })
                      setEditComment(true);
                      setComment(item.Content)
                      // setCommentEditText(item.Content);
                      setFiles(item.Files)
                    }}
                  >
                    Edit .
                  </Grid>
                  <Grid
                    item
                    className={classes["Edit-Delete"]}
                    paddingLeft={0.5}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setchosenId(item.Id);
                      setOpen(true);
                    }}
                  >
                    Delete
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          )}
        </Grid>
      ))}
      <DeleteCommentModal
        openModal={open}
        setOpenModal={setOpen}
        deleteTheComment={deleteTheComment}
        loadings={loadings}
      />
    </Grid>
  );
};

export default CommentSection;
