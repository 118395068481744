import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as contractServices from "../../../../Services/projects-services.proxy";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ContractTradeModal from "./ContractTradeModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import DeleteModal from "./DeleteModal";
import classes from "../../../Admin/UserRoles/modal.module.css";
import moment from "moment";


const uploadContractFile = contractServices.uploadContractFile;
const deleteContractFile = contractServices.deleteContractFile;
const updateContractFields = contractServices.updateContractFields;

const styles = {
  label: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "left",
    color: "#101650",
  },
  error: {
    fontFamily: "Muli",
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "left",
    color: "#d32f2f",
    paddingTop: "1.5%",
  },
};

export default function ContractDetails(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [cnError, setCnError] = useState(false);
  const [cnInvalid, setCnInvalid] = useState(false);
  const [ctError, setCtError] = useState(false);
  const [ctoError, setCtoError] = useState(false);
  const [cstError, setCstError] = useState(false);
  const [cendError, setCendError] = useState(false);
  const [previousFileId, setPreviousFileId] = useState(null);

  const [subprojectName, setSubprojectName] = useState("Subproject Name");
  const [contractorName, setContractorName] = useState("Contractor Name");
  const [billingRRs, setBillingRRs] = useState([]);
  const [openCDT, setOpenCDT] = useState(false);

  useEffect(() => {
    setSubprojectName(props.subProjectName);
    setContractorName(props.contractorName ? props.contractorName : "No Name");
  }, [props.contractorName, props.subProjectName]);

  const [contractName, setContractName] = useState("");
  const [contractType, setContractType] = useState(1);
  const [contractAbbreviation, setContractAbbreviation] = useState("");
  const [contractCode, setContractCode] = useState("");
  const [contractDescription, setContractDescription] = useState("");
  const [contractFile, setContractFile] = useState(null);
  const [contractFileId, setContractFileId] = useState(null);
  const [contractStartDate, setContractStartDate] = useState(null);
  const [contractEndDate, setContractEndDate] = useState(null);
  const [billingType, setBillingType] = useState("");
  const [billingRR, setBillingRR] = useState("");
  const [sheetSize, setSheetSize] = useState("");
  const [sheetSizeOther, setSheetSizeOther] = useState("");
  const [billingTypeOther, setBillingTypeOther] = useState("");
  const [contractTypeOther, setContractTypeOther] = useState("");
  const [contractDisciplines, setcontractDisciplines] = useState([]);
  const [contractTrades, setcontractTrades] = useState([]);
  const [getDisciplines, setGetDisciplines] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [assigned, setAssigned] = useState(false);
  const [csubtError, setCsubtError] = useState(false);
  const [contractSubType, setContractSubType] = useState("");
  const [factoryNumb, setFactoryNumb] = useState("");
  const [barStandard, setBarStandard] = useState("");
  const [barGrade, setBarGrade] = useState("");
  const [barCoating, setBarCoating] = useState("");
  const [fnError, setFnError] = useState(false);
  const [bsError, setbsError] = useState(false);
  const [bgError, setbgError] = useState(false);
  const [bcError, setbcError] = useState(false);
  const [fnInvalid, setFnInvalid] = useState(false);
  const [csError, setCsError] = useState(false);
  const [contractStatus, setContractStatus] = useState(1);
  const [contractIndex, setContractIndex] = useState(0);
  const [contractId, setContractId] = useState(null);
  const [disableType, setDisableType] = useState(false);
  const [measurement, setMeasurement] = useState(1);
  const [unit, setUnit] = useState("mm -- kg");
  const [msError, setMsError] = useState(false);

  useEffect(() => {
    let apiTest = true;
    if (props.subContracts.length > 0 && apiTest) {
      if (props.subContracts[contractIndex]) {
        props.setContractDetails(props.subContracts[contractIndex]);
        props.setFactoryNumbers(props.subContracts[contractIndex]?.Factories);
        setContractId(props.subContracts[contractIndex]?.Id);
        setContractName(props.subContracts[contractIndex]?.Name);
        setContractType(props.subContracts[contractIndex]?.AccountTypeId);
        if (props.subContracts[contractIndex]?.TypeId)
          setContractSubType(props.subContracts[contractIndex]?.TypeId);
        if (props.subContracts[contractIndex]?.TypeOthers)
          setContractTypeOther(props.subContracts[contractIndex]?.TypeOthers);
        setContractStatus(props.subContracts[contractIndex]?.StatusId);
        if (props.subContracts[contractIndex]?.FactoryNumber) {
          setFactoryNumb(props.subContracts[contractIndex]?.FactoryNumber);
          props.setBarStandards(
            props.subContracts[contractIndex]?.Factories.find(
              (f) => f.Id === props.subContracts[contractIndex]?.FactoryNumber
            )?.References.find((r) => r.ReferenceId === 1)?.Values
          );
          props.setBarGrades(
            props.subContracts[contractIndex]?.Factories.find(
              (f) => f.Id === props.subContracts[contractIndex]?.FactoryNumber
            )?.References.find((r) => r.ReferenceId === 2)?.Values
          );
          props.setBarCoatings(
            props.subContracts[contractIndex]?.Factories.find(
              (f) => f.Id === props.subContracts[contractIndex]?.FactoryNumber
            )?.References.find((r) => r.ReferenceId === 3)?.Values
          );
        }
        if (props.subContracts[contractIndex]?.BarStandardId)
          setBarStandard(props.subContracts[contractIndex]?.BarStandardId);
        if (props.subContracts[contractIndex]?.BarGradeId)
          setBarGrade(props.subContracts[contractIndex]?.BarGradeId);
        if (props.subContracts[contractIndex]?.BarCoatingId)
          setBarCoating(props.subContracts[contractIndex]?.BarCoatingId);
        if (props.subContracts[contractIndex]?.MeasurementSystemId)
          //setMeasurement(props.subContracts[contractIndex]?.MeasurementSystemId);
          setMeasurement(1);
        if (props.subContracts[contractIndex]?.MeasurementSystemId === 1)
          setUnit("mm -- kg");
        if (props.subContracts[contractIndex]?.MeasurementSystemId === 2)
          setUnit("inches -- pounds");
        setContractAbbreviation(
          props.subContracts[contractIndex]?.Abbreviation
        );
        setContractCode(props.subContracts[contractIndex]?.Code);
        setContractDescription(props.subContracts[contractIndex]?.Description);
        setContractFile(props.subContracts[contractIndex]?.File);
        if (props.subContracts[contractIndex]?.File)
          setContractFileId(props.subContracts[contractIndex]?.File.FileId);
        setBillingRR(props.subContracts[contractIndex]?.BillingRetentionRatio);
        setBillingType(props.subContracts[contractIndex]?.BillingTypeId);
        if (props.subContracts[contractIndex]?.BillingTypeOthers)
          setBillingTypeOther(
            props.subContracts[contractIndex]?.BillingTypeOthers
          );
        setSheetSize(props.subContracts[contractIndex]?.DefaultSheetSizeId);
        if (props.subContracts[contractIndex]?.DefaultSheetSizeOthers)
          setSheetSizeOther(
            props.subContracts[contractIndex]?.DefaultSheetSizeOthers
          );
        setContractStartDate(moment(props.subContracts[contractIndex]?.StartDate).format("YYYY-MM-DD")
        );
        setContractEndDate(moment(props.subContracts[contractIndex]?.EndDate).format("YYYY-MM-DD"));
        setGetDisciplines(props.subContracts[contractIndex]?.Disciplines);
        if (props.subContracts[contractIndex]?.Disciplines) setAssigned(true);
      } else {
        props.setContractDetails(null);
        setContractId(null);
        setContractName("");
        setContractName("");
        setContractType(1);
        setContractAbbreviation("");
        setContractCode("");
        setContractDescription("");
        setContractFile(null);
        setContractFileId(null);
        setContractStartDate(null);
        setContractEndDate(null);
        setBillingType("");
        setBillingRR("");
        setSheetSize("");
        setSheetSizeOther("");
        setBillingTypeOther("");
        setContractTypeOther("");
        setcontractDisciplines([]);
        setcontractTrades([]);
        setGetDisciplines([]);
        setContractSubType("");
        setFactoryNumb("");
        setBarStandard("");
        setBarCoating("");
        setBarGrade("");
        setMeasurement(1);
        setUnit("");
        setContractStatus(1);
      }
    }
    return () => {
      apiTest = false;
    };
  }, [contractIndex]);

  useEffect(() => {
    if (
      !contractName ||
      cnError ||
      !contractType ||
      cnError ||
      !contractStartDate ||
      cstError ||
      !contractEndDate ||
      cendError ||
      !contractDisciplines ||
      (contractType === "1" && !contractSubType && !assigned) ||
      (contractType === "2" &&
        localStorage.getItem("AccountType") === "2" &&
        !factoryNumb &&
        !barStandard &&
        !barCoating &&
        !barGrade &&
        !measurement) ||
      (contractType === "2" &&
        localStorage.getItem("AccountType") === "1" &&
        !measurement) ||
      !contractStatus ||
      csError
    )
      setEmpty(true);
    else setEmpty(false);
  });

  const data = {
    Name: contractName,
    TypeId: contractSubType ? contractSubType : null,
    TypeOthers: contractTypeOther ? contractTypeOther : null,
    Abbreviation: contractAbbreviation ? contractAbbreviation : null,
    Code: contractCode ? contractCode : null,
    Description: contractDescription ? contractDescription : null,
    File: contractFileId ? { FileId: contractFileId } : null,
    BillingTypeId: billingType ? billingType : null,
    BillingTypeOthers: billingTypeOther ? billingTypeOther : null,
    BillingRetentionRatio: billingRR ? billingRR : null,
    DefaultSheetSizeId: sheetSize ? sheetSize : null,
    DefaultSheetSizeOthers: sheetSizeOther ? sheetSizeOther : null,
    StartDate: contractStartDate,
    EndDate: contractEndDate,
    Disciplines: contractDisciplines,
    AccountTypeId: contractType,
    StatusId: contractStatus,
    FactoryNumber: factoryNumb ? factoryNumb : null,
    BarStandard: barStandard ? barStandard : null,
    BarGrade: barGrade ? barGrade : null,
    BarCoating: barCoating ? barCoating : null,
    MeasurementSystemId: measurement ? measurement : null,
  };

  const [load, setLoad] = useState(false);

  const addNewContract = () => {
    if (contractId) {
      let index = props.subContracts
        .map((x) => {
          return x.Id;
        })
        .indexOf(contractId);
      props.subContracts.splice(index, 1);
    }
    if (contractName !== null) {
      let temp = data;
      let arr = props.subContracts;
      arr.push(temp);
      let filtered = arr;
      filtered = arr.filter((contract) => contract?.Name !== "");
      props.setSubContracts(filtered);
      temp = null;
      arr = [];
      filtered = [];
    }
    setContractName("");
    if (contractType === 1) {
      setContractType(2);
      setcontractDisciplines([{ Id: 3, Trades: [{ Id: 1 }] }]);
    } else {
      setContractType(1);
      setcontractDisciplines([]);
      setAssigned(false);
    }
    setDisableType(true);
    setContractAbbreviation("");
    setContractCode("");
    setContractDescription("");
    setContractFile(null);
    setContractFileId(null);
    setContractStartDate(null);
    setContractEndDate(null);
    setBillingType("");
    setBillingRR("");
    setSheetSize("");
    setSheetSizeOther("");
    setBillingTypeOther("");
    setContractTypeOther("");
    setcontractTrades([]);
    setGetDisciplines([]);
    setContractSubType("");
    setFactoryNumb("");
    setBarStandard("");
    setBarCoating("");
    setBarGrade("");
    setMeasurement(1);
    setUnit("");
    setContractStatus(1);
  };

  const save = () => {
    props.setContractDetails(data);
    setLoad(true);
    if (contractId) {
      let index = props.subContracts
        .map((x) => {
          return x.Id;
        })
        .indexOf(contractId);
      props.subContracts.splice(index, 1);
    }
    let temp = data;
    let arr = props.subContracts;
    arr.push(temp);
    let filtered = arr;
    filtered = arr.filter((contract) => contract?.Name !== "");
    props.setSubContracts(filtered);
    //console.log(props.subContracts)
    temp = null;
    arr = [];
    filtered = [];
    setTimeout(() => {
      setContractName("");
      setContractId(null);
      setContractName("");
      setContractType(1);
      setContractAbbreviation("");
      setContractCode("");
      setContractDescription("");
      setContractFile(null);
      setContractFileId(null);
      setContractStartDate(null);
      setContractEndDate(null);
      setBillingType("");
      setBillingRR("");
      setSheetSize("");
      setSheetSizeOther("");
      setBillingTypeOther("");
      setContractTypeOther("");
      setcontractDisciplines([]);
      setcontractTrades([]);
      setGetDisciplines([]);
      setContractSubType("");
      setFactoryNumb("");
      setBarStandard("");
      setBarCoating("");
      setBarGrade("");
      setMeasurement(1);
      setUnit("");
      setContractStatus(1);
      setLoad(false);
      props.setShowContract(!props.showContract);
    }, 2000);
  };

  useEffect(() => {
    for (let i = 1; i <= 100; i++) {
      let arr = billingRRs;
      arr.push(i + "%");
      setBillingRRs(arr);
    }
  });

  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteContractFile(previousFileId, props.projectId).then((x) =>
        console.log("Done!")
      );
    }
    if (e.target.files[0]) {
      if (contractFileId) setPreviousFileId(contractFileId);
      uploadContractFile(e.target.files[0], props.projectId).then((x) => {
        setContractFile(x);
        setContractFileId(x.FileId);
      });
    }
  };

  const updateData = {
    Id: contractId,
    FactoryId: factoryNumb,
    BarStandardId: barStandard,
    BarCoatingId: barCoating,
    BarGradeId: barGrade,
  };

  const updateContract = () => {
    setLoad(true);
    updateContractFields(updateData).then(() => {
      props.setSubContSaved(!props.subContSaved);
      setLoad(false);
    });
  };

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignItems="flex-start"
      columnGap={5}
      rowGap={5}
      bgcolor="#fff"
      marginTop="1%"
      padding="1% 0 3% 0"
    >
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 21,
          lineHeight: 1.48,
          textAlign: "left",
          color: "#101650",
          backgroundColor: "#fff",
          borderBottom: "solid #2da4d0",
        }}
        marginLeft="4%"
        marginRight={{ xl: "80%", lg: "78%", md: "75%", xs: "60%" }}
      >
        Contract Details
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 15,
          fontWeight: "bold",
          lineHeight: 1.47,
          textAlign: "left",
          color: "rgba(16, 22, 80, 0.5)",
        }}
        marginLeft="9%"
        container
      >
        <Grid item xs={3.5}>
          {subprojectName + " - " + contractorName}
        </Grid>
        <Grid item xs={1.5}>
          {contractName}
        </Grid>
        {props.subContracts?.length > 0 ? (
          <Grid item xs={1}>
            <Grid
              item
              xl={2}
              id="contract-drop"
              aria-controls={open ? "noti-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            >
              <KeyboardArrowDownIcon
                htmlColor="#0674b9"
                fontSize="medium"
                style={{ height: 18 }}
              />
            </Grid>
            <Menu
              id="contract-drop"
              aria-labelledby="contract-drop"
              anchorEl={anchorEl}
              open={open}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#fff",
                },
              }}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
              }}
              disableScrollLock
            >
              {props.subContracts?.map((contract, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    setContractIndex(index);
                    handleClose();
                  }}
                  style={{
                    height: "60px",
                    fontWeight: "bold",
                    fontFamily: "Muli",
                    fontSize: 13,
                    borderRadius: 5,
                    color: "#0674b9",
                    backgroundColor: "#fff",
                  }}
                >
                  {contract?.Name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : (
          <Grid item xs={1}></Grid>
        )}
        <Grid item>
          <button
            style={{
              fontFamily: "Muli",
              fontSize: 12,
              height: 23,
              width: 150,
              boxShadow:
                localStorage.getItem("AccountType") === "2" ||
                  !localStorage.getItem("planId")?.includes("Pro") ||
                  !contractId ||
                  empty ||
                  localStorage.getItem("viewSubPro") === "true" ||
                  props.subContracts.length === 2 ||
                  (props.disciplines.filter((d) => d.Id === 3).length === 0 &&
                    props.trades.filter((t) => t.Id === 1).length === 0)
                  ? "0 3px 6px 0 lightgrey"
                  : "0 3px 6px 0 #0674b9",
              backgroundColor:
                localStorage.getItem("AccountType") === "2" ||
                  !localStorage.getItem("planId")?.includes("Pro") ||
                  !contractId ||
                  empty ||
                  localStorage.getItem("viewSubPro") === "true" ||
                  props.subContracts.length === 2 ||
                  (props.disciplines.filter((d) => d.Id === 3).length === 0 &&
                    props.trades.filter((t) => t.Id === 1).length === 0)
                  ? "lightgrey"
                  : "#fff",
              borderRadius: 5,
              border: 0,
              color:
                localStorage.getItem("AccountType") === "2" ||
                  !localStorage.getItem("planId")?.includes("Pro") ||
                  !contractId ||
                  empty ||
                  localStorage.getItem("viewSubPro") === "true" ||
                  props.subContracts.length === 2 ||
                  (props.disciplines.filter((d) => d.Id === 3).length === 0 &&
                    props.trades.filter((t) => t.Id === 1).length === 0)
                  ? "#fff"
                  : "#0674b9",
            }}
            disabled={
              localStorage.getItem("AccountType") === "2" ||
              !localStorage.getItem("planId")?.includes("Pro") ||
              !contractId ||
              empty ||
              localStorage.getItem("viewSubPro") === "true" ||
              props.subContracts.length === 2 ||
              (props.disciplines.filter((d) => d.Id === 3).length === 0) &
              (props.trades.filter((t) => t.Id === 1).length === 0)
            }
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.backgroundColor = "#0674b9";
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#fff";
              e.target.style.color = "#0674b9";
            }}
            onClick={() => {
              setContractId(null);
              addNewContract();
            }}
          >
            New Contract +
          </button>
          <div style={styles.label}>
            {!props.viewdisable &&
              !localStorage.getItem("viewSubPro") === "true" &&
              (localStorage.getItem("AccountType") === "2" ||
                !localStorage.getItem("planId")?.includes("Pro")) ? (
              <div style={{ marginTop: "1.5%" }}>
                Upgrade your subscription plan to Pro or Pro+ to unlock this
                feature
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={styles.label}>
            {props.disciplines?.filter((d) => d.Name === "Structural")?.length === 0 ||
              props.trades?.filter((t) => t.Name === "Rebar")?.length === 0 ? (
              <div style={{ marginTop: "1.5%" }}>
                A manufacturing Contract requires the selection :
                "Structural-Rebar"
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          *Contract Name
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
            fullWidth
            variant="standard"
            value={contractName}
            error={cnError}
            helperText={
              cnError
                ? cnInvalid
                  ? "Contract Name must be less than 150 characters"
                  : "Enter a contract name"
                : ""
            }
            onBlur={(e) => {
              if (!e.target.value) setCnError(true);
            }}
            onChange={(e) => {
              setContractName(e.target.value);
              if (e.target.value.length > 150) {
                setCnError(true);
                setCnInvalid(true);
              } else {
                setCnError(false);
                setCnInvalid(false);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label} marginTop="1.5%">
          *Contract Type
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={
              props.viewdisable ||
              localStorage.getItem("viewSubPro") === "true" ||
              disableType
            }
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={ctError}
              value={contractType}
              onBlur={(e) => {
                if (!e.target.value) setCtError(true);
              }}
              onChange={(e) => {
                setContractType(e.target.value);
                if (e.target.value === 2) {
                  setcontractDisciplines([{ Id: 3, Trades: [{ Id: 1 }] }]);
                }
                setCtError(false);
              }}
            >
              {props.accountTypes?.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                  disabled={
                    option.Id === 2 &&
                    (localStorage.getItem("AccountType") === "2" ||
                      !localStorage.getItem("planId")?.includes("Pro") ||
                      (props.disciplines?.filter((d) => d.Name === "Structural")?.length === 0 ||
                        props.trades?.filter((t) => t.Name === "Rebar")?.length === 0))
                  }
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {ctError ? "Enter a contract type" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract Abbreviation
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
            fullWidth
            variant="standard"
            value={contractAbbreviation}
            onChange={(e) => {
              setContractAbbreviation(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      {contractType === 1 ? (
        <Grid item xs={4} container>
          <Grid item xs={12} sx={styles.label} marginTop="1.5%">
            *Contract SubType
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="standard"
              fullWidth
              disabled={
                props.viewdisable ||
                localStorage.getItem("viewSubPro") === "true"
              }
            >
              <Select
                MenuProps={{ disableScrollLock: true }}
                error={csubtError}
                value={contractSubType}
                onBlur={(e) => {
                  if (!e.target.value) setCsubtError(true);
                }}
                onChange={(e) => {
                  setContractSubType(e.target.value);
                  setCsubtError(false);
                  if (
                    e.target.value !==
                    props.contractTypes?.find((x) => x.Name === "Others")?.Id
                  )
                    setContractTypeOther("");
                }}
              >
                {props.contractTypes?.map((option) => (
                  <MenuItem
                    value={option.Id}
                    key={option.Id}
                    style={{ display: "block" }}
                  >
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
              <div style={styles.error}>
                {csubtError ? "Enter a contract sub type" : ""}
              </div>
            </FormControl>
            {contractSubType ===
              props.contractTypes?.find((x) => x.Name === "Others")?.Id ? (
              <>
                <Grid item sx={styles.label}>
                  *Other Type
                </Grid>
                <Grid item>
                  <TextField
                    disabled={
                      props.viewdisable ||
                      localStorage.getItem("viewSubPro") === "true"
                    }
                    variant="standard"
                    fullWidth
                    value={contractTypeOther}
                    size="small"
                    error={ctoError}
                    helperText={ctoError ? "Enter a contract sub type" : ""}
                    onBlur={(e) => {
                      if (!e.target.value) setCtoError(true);
                    }}
                    onChange={(e) => {
                      setContractTypeOther(e.target.value);
                      setCtoError(false);
                    }}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label}>
              {localStorage.getItem("AccountType") === "1" ? "" : "*"}Factory No
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={
                  ((props.viewdisable ||
                    localStorage.getItem("viewSubPro") === "true") &&
                    localStorage.getItem("noCreator") !== "true") ||
                  localStorage.getItem("AccountType") === "1"
                }
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={fnError}
                  value={factoryNumb}
                  onBlur={(e) => {
                    if (!e.target.value) setFnError(true);
                  }}
                  onChange={(e) => {
                    setFactoryNumb(e.target.value);
                    props.setBarStandards(
                      props.subContracts[contractIndex]?.Factories.find(
                        (f) => f.Id === e.target.value
                      )?.References.find((r) => r.ReferenceId === 1)?.Values
                    );
                    props.setBarGrades(
                      props.subContracts[contractIndex]?.Factories.find(
                        (f) => f.Id === e.target.value
                      )?.References.find((r) => r.ReferenceId === 2)?.Values
                    );
                    props.setBarCoatings(
                      props.subContracts[contractIndex]?.Factories.find(
                        (f) => f.Id === e.target.value
                      )?.References.find((r) => r.ReferenceId === 3)?.Values
                    );
                    setFnError(false);
                  }}
                >
                  {props.factoryNumbers?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={
                    localStorage.getItem("AccountType") === "1"
                      ? { color: "grey" }
                      : styles.error
                  }
                >
                  {fnError
                    ? fnInvalid
                      ? "Factory number must be less than 50 characters"
                      : "Enter a Factory number"
                    : localStorage.getItem("AccountType") === "1"
                      ? "Populated by the invited subcontractor"
                      : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label}>
              {localStorage.getItem("AccountType") === "1" ? "" : "*"}Bar
              Standard
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={
                  ((props.viewdisable ||
                    localStorage.getItem("viewSubPro") === "true") &&
                    localStorage.getItem("noCreator") !== "true") ||
                  localStorage.getItem("AccountType") === "1"
                }
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={bsError}
                  value={barStandard}
                  onBlur={(e) => {
                    if (!e.target.value) setbsError(true);
                  }}
                  onChange={(e) => {
                    setBarStandard(e.target.value);
                    setbsError(false);
                  }}
                >
                  {props.barStandards?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={
                    localStorage.getItem("AccountType") === "1"
                      ? { color: "grey" }
                      : styles.error
                  }
                >
                  {bsError
                    ? "Enter a Bar Standard"
                    : localStorage.getItem("AccountType") === "1"
                      ? "Populated by the invited subcontractor"
                      : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label}>
              {localStorage.getItem("AccountType") === "1" ? "" : "*"}Bar Grade
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={
                  ((props.viewdisable ||
                    localStorage.getItem("viewSubPro") === "true") &&
                    localStorage.getItem("noCreator") !== "true") ||
                  localStorage.getItem("AccountType") === "1"
                }
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={bgError}
                  value={barGrade}
                  onBlur={(e) => {
                    if (!e.target.value) setbgError(true);
                  }}
                  onChange={(e) => {
                    setBarGrade(e.target.value);
                    setbgError(false);
                  }}
                >
                  {props.barGrades?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={
                    localStorage.getItem("AccountType") === "1"
                      ? { color: "grey" }
                      : styles.error
                  }
                >
                  {bgError
                    ? "Enter a Bar Grade"
                    : localStorage.getItem("AccountType") === "1"
                      ? "Populated by the invited subcontractor"
                      : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label}>
              {localStorage.getItem("AccountType") === "1" ? "" : "*"}Bar
              Coating
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={
                  ((props.viewdisable ||
                    localStorage.getItem("viewSubPro") === "true") &&
                    localStorage.getItem("noCreator") !== "true") ||
                  localStorage.getItem("AccountType") === "1"
                }
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={bcError}
                  value={barCoating}
                  onBlur={(e) => {
                    if (!e.target.value) setbcError(true);
                  }}
                  onChange={(e) => {
                    setBarCoating(e.target.value);
                    setbcError(false);
                  }}
                >
                  {props.barCoatings?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={
                    localStorage.getItem("AccountType") === "1"
                      ? { color: "grey" }
                      : styles.error
                  }
                >
                  {bcError
                    ? "Enter a Bar Coating"
                    : localStorage.getItem("AccountType") === "1"
                      ? "Populated by the invited subcontractor"
                      : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          {localStorage.getItem("AccountType") === "2" &&
            localStorage.getItem("noCreator") === "true" &&
            contractType === 2 ? (
            <Grid item xs={12} container direction="row-reverse">
              <Grid item paddingRight="10%">
                <Button
                  style={{
                    borderRadius: 5,
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#0b5cab",
                    fontFamily: "Muli",
                    fontSize: 13,
                    fontWeight: "bold",
                    lineHeight: 1.46,
                    textAlign: "left",
                    color: "#fff",
                    textTransform: "none",
                    opacity: load ? 0.7 : 1,
                  }}
                  onClick={updateContract}
                >
                  {load ? "Updating  . . ." : "Update Contract"}
                </Button>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </>
      )}
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract Description
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
            fullWidth
            variant="standard"
            multiline
            value={contractDescription}
            onChange={(e) => {
              setContractDescription(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract Code
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
            fullWidth
            variant="standard"
            value={contractCode}
            onChange={(e) => {
              setContractCode(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={5.4}></Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract File
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{ borderBottom: "solid 2px #b8b8b8" }}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Button
              disabled={
                props.viewdisable ||
                localStorage.getItem("viewSubPro") === "true"
              }
              component="label"
              style={{
                textTransform: "none",
                fontFamily: "Muli",
                fontSize: "12px",
                lineHeight: 1.45,
                textAlign: "left",
                color: "#101650",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "32px",
              }}
            >
              <FileUploadOutlinedIcon
                htmlColor="#529bd7"
                style={{
                  alignSelf: "center",
                  paddingRight: "5%",
                }}
              />
              <input type="file" hidden onChange={handleFileChange} required />
            </Button>
          </Grid>
          <Grid item xs={10}>
            <span
              style={{ alignSelf: "center" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color = "grey";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "black";
              }}
              onClick={(e) => {
                if (contractFile) {
                  window.open(contractFile?.URL);
                } else {
                  e.target.style.color = "grey";
                }
              }}
            >
              {contractFile ? contractFile.DisplayName : "Upload File"}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {contractType === 2 ? (
        <>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Measurement System
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={
                  props.viewdisable ||
                  localStorage.getItem("viewSubPro") === "true"
                }
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={msError}
                  value={measurement}
                  onBlur={(e) => {
                    if (!e.target.value) setMsError(true);
                  }}
                  onChange={(e) => {
                    setMeasurement(e.target.value);
                    if (e.target.value === 1) setUnit("mm -- kg");
                    else setUnit("inches -- pounds");
                    setMsError(false);
                  }}
                >
                  {props.measurements?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                      disabled={option.Id !== 1}
                    >
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {msError ? "Enter a measurement system" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Unit of Measures
            </Grid>
            <Grid item xs={12}>
              <TextField disabled fullWidth variant="standard" value={unit} />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Billing Retention Ratio
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={billingRR}
              onChange={(e) => {
                setBillingRR(e.target.value.toString());
              }}
            >
              {billingRRs.map((b, index) => (
                <MenuItem key={index} value={index + 1}>
                  {b}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Billing Type
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={billingType}
              onChange={(e) => {
                setBillingType(e.target.value);
                if (
                  e.target.value !==
                  props.contractBillingTypes?.find((x) => x.Name === "Others")
                    ?.Id
                )
                  setBillingTypeOther("");
              }}
            >
              {props.contractBillingTypes.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {billingType ===
            props.contractBillingTypes?.find((x) => x.Name === "Others")?.Id ? (
            <>
              <Grid item sx={styles.label}>
                *Other Billing Type
              </Grid>
              <Grid item>
                <TextField
                  disabled={
                    props.viewdisable ||
                    localStorage.getItem("viewSubPro") === "true"
                  }
                  variant="standard"
                  fullWidth
                  value={billingTypeOther}
                  size="small"
                  onChange={(e) => {
                    setBillingTypeOther(e.target.value);
                  }}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          *Status
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={csError}
              value={contractStatus}
              onBlur={(e) => {
                if (!e.target.value) setCsError(true);
              }}
              onChange={(e) => {
                setContractStatus(e.target.value);
                setCsError(false);
              }}
            >
              {props.contractStatuses?.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {csError ? "Enter a contract status" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Default Sheet Size
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={
              props.viewdisable || localStorage.getItem("viewSubPro") === "true"
            }
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={sheetSize}
              onChange={(e) => {
                setSheetSize(e.target.value);
                if (
                  e.target.value !==
                  props.defaultSheetSizes?.find((x) => x.Name === "Others")?.Id
                )
                  setSheetSizeOther("");
              }}
            >
              {props.defaultSheetSizes?.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {sheetSize ===
            props.defaultSheetSizes?.find((x) => x.Name === "Others")?.Id ? (
            <>
              <Grid item sx={styles.label}>
                *Other Sheet Size
              </Grid>
              <Grid item>
                <TextField
                  disabled={
                    props.viewdisable ||
                    localStorage.getItem("viewSubPro") === "true"
                  }
                  variant="standard"
                  fullWidth
                  value={sheetSizeOther}
                  size="small"
                  onChange={(e) => {
                    setSheetSizeOther(e.target.value);
                  }}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Subcontractor Disciplines and Trades
        </Grid>
        <Grid item xs={12} paddingTop="3%">
          {contractType === 1 ? (
            <Button
              sx={{
                border:
                  localStorage.getItem("AccountType") === "1"
                    ? "solid 1px #e1e319"
                    : "solid 1px #2da4d0",
                backgroundColor: "#fff",
                color: "#101650",
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                textTransform: "none",
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.backgroundColor =
                  localStorage.getItem("AccountType") === "1"
                    ? "#e1e319"
                    : "#2da4d0";
                e.target.style.color = "#fff";
              }}
              onMouseLeave={(e) => {
                e.target.style.border =
                  localStorage.getItem("AccountType") === "1"
                    ? "solid 1px #e1e319"
                    : "solid 1px #2da4d0";
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#101650";
              }}
              onClick={() => setOpenCDT(true)}
            >
              {assigned
                ? "View Assigned Trades and Disciplines"
                : "Assign Trade to Discipline +"}
            </Button>
          ) : (
            <Grid container>
              <Grid item xs={5}>
                <Grid item xs={12} sx={styles.label}>
                  Subproject Disciplines
                </Grid>
                <Grid item xs={12} container gap={0.5}>
                  <Grid
                    item
                    className={classes["FullChecked"]}
                    marginTop={0.4}
                  ></Grid>
                  <Grid
                    item
                    className={classes["Action-permission"]}
                    marginTop={0.1}
                  >
                    Structural
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid item xs={12} sx={styles.label}>
                  Subproject Trades
                </Grid>
                <Grid item xs={12} container gap={0.5}>
                  <Grid
                    item
                    className={classes["FullChecked"]}
                    marginTop={0.4}
                  ></Grid>
                  <Grid
                    item
                    className={classes["Action-permission"]}
                    marginTop={0.1}
                  >
                    Rebar
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={0.6}></Grid>
      <ContractTradeModal
        setAssigned={setAssigned}
        getDisciplines={getDisciplines}
        setGetDisciplines={setGetDisciplines}
        openModal={openCDT}
        setOpenModal={setOpenCDT}
        disciplines={props.disciplines}
        trades={props.trades}
        contractDisciplines={contractDisciplines}
        setcontractDisciplines={setcontractDisciplines}
        contractTrades={contractTrades}
        setcontractTrades={setcontractTrades}
      />
      <Grid item xs={1.8} container>
        <Grid item xs={12} sx={styles.label}>
          *Start Date
        </Grid>
        <Grid item xs={12} paddingTop="5%">
          <TextField
            fullWidth
            disabled={
              props.viewdisable ||
              localStorage.getItem("viewSubPro") === "true"
            }
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{ className: classes.fieldDesign }}
            size="small"
            label={""}
            type={"date"}
            value={contractStartDate}
            variant="outlined"
            error={cstError}
            // helperText={plannedStartDateErrorMessage}
            // onBlur={(e) => {
            //   if (!e.target.value) {
            //     setPhsError(true);
            //   }
            // }}
            onChange={(e) => {
              setContractStartDate(e.target.value);
              if (e.target.value.length > 10) setCstError(true);
              else setCstError(false);

            }}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={
                props.viewdisable ||
                localStorage.getItem("viewSubPro") === "true"
              }
              openTo="day"
              disablePast
              views={["year", "month", "day"]}
              label="dd/mm/yyyy"
              disableMaskedInput
              inputFormat="dd/MM/yyyy"
              value={contractStartDate}
              onChange={(newValue) => {
                if (newValue === "") {
                  setCstError(true);
                } else {
                  setCstError(false);
                }
                var date = new Date(newValue);
                setContractStartDate(newValue.toLocaleDateString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "15px",
                    },
                  }}
                  error={cstError}
                  onBlur={() => {
                    if (!contractStartDate) setCstError(true);
                  }}
                  helperText={cstError ? "Enter a date" : <></>}
                  size="small"
                />
              )}
            />
          </LocalizationProvider> */}
        </Grid>
      </Grid>
      <Grid item xs={1.8} container>
        <Grid item xs={12} sx={styles.label}>
          *End Date
        </Grid>
        <Grid item xs={12} paddingTop="5%">
        <TextField
            fullWidth
            disabled={
              props.viewdisable ||
              localStorage.getItem("viewSubPro") === "true"
            }
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{ className: classes.fieldDesign }}
            size="small"
            label={""}
            type={"date"}
            value={contractEndDate}
            variant="outlined"
            error={cendError}
            // helperText={plannedStartDateErrorMessage}
            // onBlur={(e) => {
            //   if (!e.target.value) {
            //     setPhsError(true);
            //   }
            // }}
            onChange={(e) => {
              setContractEndDate(e.target.value);
              if (e.target.value.length > 10) setCendError(true);
              else setCendError(false);

            }}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={
                props.viewdisable ||
                localStorage.getItem("viewSubPro") === "true"
              }
              openTo="day"
              disablePast
              views={["year", "month", "day"]}
              label="dd/mm/yyyy"
              minDate={new Date(contractStartDate)}
              disableMaskedInput
              inputFormat="dd/MM/yyyy"
              value={contractEndDate}
              onChange={(newValue) => {
                if (newValue === "") {
                  setCendError(true);
                } else {
                  setCendError(false);
                }
                var date = new Date(newValue);
                setContractEndDate(newValue.toLocaleDateString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "15px",
                    },
                  }}
                  error={cendError}
                  onBlur={() => {
                    if (!setContractEndDate) setCendError(true);
                  }}
                  helperText={cendError ? "Enter a date" : <></>}
                  size="small"
                />
              )}
            />
          </LocalizationProvider> */}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={10}
        direction={"row-reverse"}
        justifyContent="space-between"
      >
        <Button
          disabled={
            empty || localStorage.getItem("viewSubPro") === "true" || load
          }
          style={{
            borderRadius: 5,
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor:
              empty || localStorage.getItem("viewSubPro") === "true"
                ? "lightgrey"
                : "#0b5cab",
            fontFamily: "Muli",
            fontSize: 13,
            fontWeight: "bold",
            lineHeight: 1.46,
            textAlign: "left",
            color: "#fff",
            textTransform: "none",
            opacity: load ? 0.7 : 1,
          }}
          onClick={() => save()}
        >
          {load ? "Saving . . . ." : "Save Contract"}
        </Button>
        <Button
          disabled={
            props.subContracts.length === 1 ||
            localStorage.getItem("AccountType") === "2" ||
            !localStorage.getItem("planId")?.includes("Pro") ||
            empty ||
            localStorage.getItem("viewSubPro") === "true" ||
            !contractId
          }
          style={{
            borderRadius: 5,
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor:
              props.subContracts.length === 1 ||
                localStorage.getItem("AccountType") === "2" ||
                !localStorage.getItem("planId")?.includes("Pro") ||
                !contractId ||
                empty ||
                localStorage.getItem("viewSubPro") === "true"
                ? "lightgrey"
                : "#0b5cab",
            fontFamily: "Muli",
            fontSize: 13,
            fontWeight: "bold",
            lineHeight: 1.46,
            textAlign: "left",
            color: "#fff",
            textTransform: "none",
            opacity: load ? 0.7 : 1,
          }}
          onClick={() => setOpenDelete(true)}
        >
          Delete Contract
        </Button>
      </Grid>
      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        contractId={contractId}
        setSubContSaved={props.setSubContSaved}
        subContSaved={props.subContSaved}
        setShowContract={props.setShowContract}
        showContract={props.showContract}
      />
    </Grid>
  );
}
