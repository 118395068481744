
import React, { useState, useEffect } from "react";
import { Grid, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import classes from "../../Assets/Styles/factory.module.css";




const AlertModal = (props) => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
        props.setOpenAlertModal(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setOpenAlertModal(false);
        props.setModify(!props.modify)

    };

    useEffect(() => {
        if (props.openAlertModal) {
            handleOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [props.openAlertModal]);



    return (

        <Dialog
            open={open}
            onClose={handleClose} >

            <Grid item container xs={12} direction="column" rowGap={2} padding={5} alignItems="center" width="50vw">

                <Grid
                    item
                    style={{ fontFamily: "Muli", fontSize: "20px", color: "#101650" }}
                >

                    Updating Splitting parameters will affect draft sales orders. Are you sure you want to save the changes?

                </Grid>

                <Grid item container direction="row" justifyContent="flex-end" gap={2} marginTop={2} alignItems="center">
                {props.successMsgSaved ? (
              <>
                <Grid item className={classes["ParametersFields"]}>
                  Succesfully Saved
                </Grid>
              </>
            ) : (
              <></>
            )}
                    <Grid item>
                        <button className={classes["cancel-button"]}
                            onClick={() => handleClose()}>
                            Cancel
                        </button>
                    </Grid>
                    <Grid item>
                        <button 
                        disabled={props.updateLoading || props.loading}
                        className={classes["SaveBut"]}
                            onClick={() => props.save()}
                        >
                          {props.updateLoading || props.loading?"Updating ...":"Ok"} 
                        </button>
                    </Grid>
                </Grid>
            </Grid>

        </Dialog>
    );
};

export default AlertModal;
