import * as constFile from "../../FactoryConsts";
import axios from "axios";

const GET_PRODUCTION_CELL_LIST = constFile.GET_PRODUCTION_CELL_LIST;
export const getProductionCellList = (FactoryId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(`${process.env.REACT_APP_URL + GET_PRODUCTION_CELL_LIST}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                FactoryId: FactoryId,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const GET_PRODUCTION_CELL_DATA = constFile.GET_PRODUCTION_CELL_DATA;
export const getProductionCellData = (FactoryId, Id) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(`${process.env.REACT_APP_URL + GET_PRODUCTION_CELL_DATA}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                factoryId: FactoryId,
                id: Id
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const ADD_PRODUCTION_CELL = constFile.ADD_PRODUCTION_CELL;
export const addProductionCell = (object) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(`${process.env.REACT_APP_URL + ADD_PRODUCTION_CELL}`, object, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
         
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const UPDATE_PRODUCTION_CELL = constFile.UPDATE_PRODUCTION_CELL;
export const updateProductionCell = (id,object) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .put(`${process.env.REACT_APP_URL + UPDATE_PRODUCTION_CELL}`, object,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                id: id,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};