import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Modal,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import classes from "../../../Assets/Styles/factory.module.css";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import shapeimg from "../../../Assets/Images/Dashboard/shape.PNG";
import checked from "../../../Assets/Images/checked.png";
import notChecked from "../../../Assets/Images/notChecked.png";
import * as DeliveryServices from "../../../Services/delivery-services.proxy";
import ErrorModal from "../../../SharedComponents/ErrorModal";

const getDeliveryNoteSalesOrderData =
  DeliveryServices.getDeliveryNoteSalesOrderData;
const confirmDeliveryNote = DeliveryServices.confirmDeliveryNote;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  backgroundColor: "#f1f1f1",
  outline: "none",
  width: "526px",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  "-webkit-backdrop-filter": "blur(30px)",
  "backdrop-filter": "blur(30px)",
};

const removeStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  outline: "none",
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  WebkitBackdropFilter: "blur(30px)",
  backdropFilter: "blur(30px)",
  width: "474px",
  borderRadius: "18px",
  backgroundColor: "#fff",
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    height: 100,
  },
}))(TableRow);

export default function ResultView(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const freeCore = localStorage.getItem("planIdManu")?.includes("Pro") ? false : true;
  const salesOrderId = query.get("salesOrderId");
  const deliveryNoteId = query.get("deliveryNoteId");
  const shipmentId = query.get("shipmentId");

  const sumOfWeightPerMember = (LinesToCalculateWeight) => {
    let sum = 0;
    for (let i = 0; i < LinesToCalculateWeight.length; i++) {
      sum = sum + LinesToCalculateWeight[i].TheoreticalWeight;
    }
    return !sum ? 0 : sum.toFixed(2);
  };

  const [loadingData, setLoadingData] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [noProducts, setNoProducts] = useState(false);

  const [salesOrder, setSalesOrder] = useState({});

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const navigateToOnClose = -1


  const [selectedLines, setSelectedLines] = useState([]);
  useEffect(() => {
    if (deliveryNoteId || salesOrderId) {
      setLoadingData(true);
      getDeliveryNoteSalesOrderData(deliveryNoteId, salesOrderId).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setSalesOrder(x);
          // if (localStorage.getItem("planIdManu")?.includes("Pro")) {
          //   let soLines = x?.SalesOrder?.Members?.flatMap((mem) => mem.Lines);
          //   if (soLines.filter((l) => l.Products[0] !== null).length === 0) {
          //     setNoProducts(true);
          //   }
          // }
        }

        setLoadingData(false);

      });
    }
  }, [deliveryNoteId, salesOrderId]);
  const dataToSend = {
    Id: deliveryNoteId ? deliveryNoteId : null,
    Code: salesOrder?.SalesOrder?.Code,
    SalesOrderId: salesOrderId ? salesOrderId : null,
    Lines: freeCore ? salesOrder?.SalesOrder?.Members?.flatMap((x) => x.Lines.filter((l) => selectedLines.includes(l.Id))) : salesOrder?.SalesOrder?.Members?.flatMap((x) => x.Lines),
  };

  const confirmSo = () => {
    setConfirmLoading(true);
    confirmDeliveryNote(dataToSend).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        if (freeCore)
          navigate(
            `/saas/Delivery/DeliveryNote?deliveryNoteId=${x}&FreeCore=${freeCore}`
          );
        else {
          navigate(
            `/saas/Delivery/DeliveryNote?deliveryNoteId=${deliveryNoteId}&Validated=${true}&shipmentId=${shipmentId}`
          );
        }
      }

      setConfirmLoading(false);


    });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid item container paddingTop={3} rowGap={2} paddingBottom={2}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={2.5}
            sm={3.5}
            xs={4.5}
            fontSize={{ xs: "13px", sm: "16px" }}
            fontWeight="bold"
            className={classes["Title"]}
          >
            <Grid item>
              {salesOrder?.Code} - {salesOrder?.SalesOrder?.Code}
            </Grid>
          </Grid>
          <Grid
            item
            sm={5}
            lg={7}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          marginLeft={{ xs: 2, sm: 5, md: 15 }}
          bgcolor="#fff"
          padding="1%"
          gap={1}
        >
          <Grid
            item
            xs={12}
            sx={{
              fontFamily: "Muli",
              fontSize: "20px",
              lineHeight: 1.25,
              textAlign: "left",
              color: "#707070",
            }}
          >
            {freeCore
              ? "Select the lines you want to deliver"
              : "Verify the type and quantities of the product attached"}
          </Grid>
          {noProducts ? (
            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"#ea001e"}
              paddingTop={1}
            >
              No Products please confirm.
            </Grid>
          ) : (
            <TableContainer sx={{ width: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 0px",
                  border: 0,
                  backgroundColor: "#fff",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}></TableCell>
                    <TableCell sx={styles.tableCell}>
                      Tag
                      <br />
                      No.
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      Bar Mark
                      <br />
                      No.
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      Qty <br />
                      <Grid item sx={{ color: "#0674b9" }} paddingTop={1}>
                        Total No.
                        <br /> of Bars
                      </Grid>
                    </TableCell>
                    <TableCell sx={styles.tableCell}>Bar Dia</TableCell>
                    <TableCell sx={styles.tableCell}>Shape Code</TableCell>
                    <TableCell sx={styles.tableCell}>Shape</TableCell>
                    <TableCell sx={styles.tableCell}>Length</TableCell>
                    <TableCell sx={styles.tableCell}>
                      Weight
                      <br />
                      (Kgs)
                    </TableCell>
                    {/* <TableCell sx={styles.tableCell}>
                      No. of product added on one shape
                    </TableCell> */}
                    {/* <TableCell sx={styles.tableCell}>
                      Type
                      <br />
                      of
                      <br />
                      product
                    </TableCell> */}
                    <TableCell sx={styles.tableCell}>
                      Total
                      <br />
                      Qty
                    </TableCell>
                  </TableRow>
                </TableHead>
                {salesOrder?.SalesOrder?.Members?.map((m) => (
                  <TableBody key={m.Id}>
                    <StyledTableRow>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}>
                        {m.OrderId}
                      </TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}>
                        {m.Quantity}
                      </TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubImage2}>
                        <Grid container>
                          <Grid
                            item
                            xs={4}
                            className={classes["subsubTitiles"]}
                          >
                            {m.Name}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes["subsubTitiles"]}
                          >
                            {m.Type}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className={classes["subsubTitiles"]}
                          >
                            {m.BarMarkType}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}>
                        {sumOfWeightPerMember(m?.Lines)}
                      </TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                      <TableCell sx={styles.tableSubCell}></TableCell>
                    </StyledTableRow>

                    {localStorage?.getItem("planIdManu")?.includes("Pro")
                      ? m.Lines?.filter(
                        (line) =>
                          localStorage
                            ?.getItem("planIdManu")
                            ?.includes("Pro") &&
                          !line.Products?.includes(null)
                      )?.map((l) => (
                        <StyledTableSubRow key={l.Id}>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <Grid
                              item
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (selectedLines.includes(l.Id))
                                  setSelectedLines(
                                    selectedLines.filter(
                                      (line) => line !== l.Id
                                    )
                                  );
                                else
                                  setSelectedLines(
                                    selectedLines.concat(l.Id)
                                  );
                              }}
                            >
                              <img
                                src={
                                  selectedLines.includes(l.Id)
                                    ? checked
                                    : notChecked
                                }
                                alt="check"
                              />
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          ></TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.BarMarkNo}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.BarsQuantity}
                            <br />
                            <Grid
                              item
                              sx={{ color: "#0674b9" }}
                              paddingTop={1}
                            >
                              {l.TotalNumberOfBars}
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.BarDiameter}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ShapeCode}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <Grid item padding="5%">
                              <img
                                width="200px"
                                src={l.ShapeBlob}
                                alt={"shapeImg"}
                              ></img>
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ActualLength}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ActualWeight}
                          </TableCell>
                          {/* <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <FormControl>
                              <RadioGroup
                                value={l.ProductCount}
                                onChange={(e) => {
                                  if (e.target.value === "1") {
                                    if (l.ProductTypes.length === 0) {
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    } else if (l.ProductTypes.length === 2) {
                                      l.ProductTypes = [];
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    }
                                  } else if (e.target.value === "2") {
                                    if (l.ProductTypes.length === 0) {
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    } else if (l.ProductTypes.length === 1) {
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    }
                                  }

                                  forceUpdate();
                                }}
                              >
                                {l.Products.length === 0 ||
                                  l.Products[0] === null ? (
                                  <></>
                                ) : (
                                  <>
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio size="small" />}
                                      label="1"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      control={<Radio size="small" />}
                                      label="2"
                                    />
                                  </>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </TableCell> */}
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ProductTypes?.map((Pt) => (
                              <FormControl
                                variant="standard"
                                sx={{ width: "100px" }}
                              >
                                <Select
                                  MenuProps={{ disableScrollLock: true }}
                                  disableUnderline
                                  onChange={(e) => {
                                    Pt.Id = e.target.value;
                                    Pt.Count = l.TotalNumberOfBars;

                                    forceUpdate();
                                  }}
                                  label="Product"
                                  size="small"
                                  value={Pt.Id}
                                >
                                  <MenuItem
                                    sx={{
                                      fontSize: 11,
                                      fontFamily: "Muli",
                                      fontWeight: "bold",
                                      color: "#707070",
                                    }}
                                    disabled
                                    value={0}
                                  >
                                    <div
                                      style={{
                                        fontSize: 11,
                                        fontFamily: "Muli",
                                        fontWeight: "bold",
                                        color: "#707070",
                                      }}
                                    >
                                      Product
                                    </div>
                                  </MenuItem>
                                  {l.Products?.filter((x) => x !== null).map(
                                    (p) => (
                                      <MenuItem
                                        sx={{
                                          fontSize: 11,
                                          fontFamily: "Muli",
                                          fontWeight: "bold",
                                          color: "#707070",
                                        }}
                                        value={p}
                                      >
                                        <div
                                          style={{
                                            fontSize: 11,
                                            fontFamily: "Muli",
                                            fontWeight: "bold",
                                            color: "#707070",
                                          }}
                                        >
                                          {p === 1
                                            ? "Coupler"
                                            : p === 2
                                              ? "End Anchor"
                                              : ""}
                                        </div>
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            ))}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ProductTypes?.length * l.TotalNumberOfBars}
                          </TableCell>
                        </StyledTableSubRow>
                      ))
                      : m.Lines?.map((l) => (
                        <StyledTableSubRow key={l.Id}>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <Grid
                              item
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (selectedLines.includes(l.Id))
                                  setSelectedLines(
                                    selectedLines.filter(
                                      (line) => line !== l.Id
                                    )
                                  );
                                else
                                  setSelectedLines(
                                    selectedLines.concat(l.Id)
                                  );
                              }}
                            >
                              <img
                                src={
                                  selectedLines.includes(l.Id)
                                    ? checked
                                    : notChecked
                                }
                                alt="check"
                              />
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          ></TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.BarMarkNo}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.BarsQuantity}
                            <br />
                            <Grid
                              item
                              sx={{ color: "#0674b9" }}
                              paddingTop={1}
                            >
                              {l.TotalNumberOfBars}
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.BarDiameter}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ShapeCode}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <Grid item padding="5%">
                              <img
                                width="200px"
                                src={l.ShapeBlob}
                                alt={"shapeImg"}
                              ></img>
                            </Grid>
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ActualLength}
                          </TableCell>
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ActualWeight}
                          </TableCell>
                          {/* <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <FormControl>
                              <RadioGroup
                                value={l.ProductCount}
                                onChange={(e) => {
                                  if (e.target.value === "1") {
                                    if (l.ProductTypes?.length === 0) {
                                      l.ProductTypes = l.ProductTypes?.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    } else if (l.ProductTypes?.length === 2) {
                                      l.ProductTypes = [];
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    }
                                  } else if (e.target.value === "2") {
                                    if (l.ProductTypes?.length === 0) {
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    } else if (l.ProductTypes?.length === 1) {
                                      l.ProductTypes = l.ProductTypes.concat({
                                        Id: 0,
                                        Count: 0,
                                      });
                                    }
                                  }

                                  forceUpdate();
                                }}
                              >
                                 {l.Products.length === 0 ||
                                  l.Products[0] === null ? (
                                  <></>
                                ) : (
                                  <>
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio size="small" />}
                                      label="1"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      control={<Radio size="small" />}
                                      label="2"
                                    />
                                  </>
                                )} 
                              </RadioGroup>
                            </FormControl>
                          </TableCell> */}
                          {/* <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ProductTypes?.map((Pt) => (
                              <FormControl
                                variant="standard"
                                sx={{ width: "100px" }}
                              >
                                <Select
                                  MenuProps={{ disableScrollLock: true }}
                                  disableUnderline
                                  onChange={(e) => {
                                    Pt.Id = e.target.value;
                                    Pt.Count = l.TotalNumberOfBars;

                                    forceUpdate();
                                  }}
                                  label="Product"
                                  size="small"
                                  value={Pt.Id}
                                >
                                  <MenuItem
                                    sx={{
                                      fontSize: 11,
                                      fontFamily: "Muli",
                                      fontWeight: "bold",
                                      color: "#707070",
                                    }}
                                    disabled
                                    value={0}
                                  >
                                    <div
                                      style={{
                                        fontSize: 11,
                                        fontFamily: "Muli",
                                        fontWeight: "bold",
                                        color: "#707070",
                                      }}
                                    >
                                      Product
                                    </div>
                                  </MenuItem>
                                  {l.Products?.filter((x) => x !== null).map(
                                    (p) => (
                                      <MenuItem
                                        sx={{
                                          fontSize: 11,
                                          fontFamily: "Muli",
                                          fontWeight: "bold",
                                          color: "#707070",
                                        }}
                                        value={p}
                                      >
                                        <div
                                          style={{
                                            fontSize: 11,
                                            fontFamily: "Muli",
                                            fontWeight: "bold",
                                            color: "#707070",
                                          }}
                                        >
                                          {p === 1
                                            ? "Coupler"
                                            : p === 2
                                              ? "End Anchor"
                                              : ""}
                                        </div>
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            ))}
                          </TableCell> */}
                          <TableCell
                            sx={styles.tableSubSubCell}
                            style={{
                              backgroundColor: "#fff",
                            }}
                          >
                            {l.ProductTypes.length * l.TotalNumberOfBars}
                          </TableCell>
                        </StyledTableSubRow>
                      ))}
                      
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          )}

          <Grid
            item
            container
            xs={12}
            sx={{
              fontFamily: "Muli",
              fontSize: "20px",
              lineHeight: 1.25,
              textAlign: "left",
              color: "#707070",
            }}
            direction="row-reverse"
          >
            <Grid item>
              <button
                disabled={
                  (localStorage.getItem("planIdManu")?.includes("Pro") && selectedLines.length !== salesOrder?.SalesOrder?.Members?.flatMap((x) => x.Lines).length)
                  ||
                  (localStorage.getItem("planIdManu")?.includes("Pro") && selectedLines.length === 0)}
                style={{
                  width: "133.2px",
                  height: "35px",
                  backgroundColor: selectedLines.length === 0 ? "#707070" : "#101650",
                  border: 0,
                  borderRadius: "3px",
                  fontFamily: "Muli",
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: 1.25,
                  color: "#fff",
                  cursor: selectedLines.length === 0 ? "unset" : "pointer",
                }}
                onClick={() => {
                  handleOpenModal();
                }}
              >
                Confirm
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title1"
        aria-describedby="modal-modal-description1"
      >
        <Grid
          container
          sx={removeStyle}
          justifyContent="center"
          alignItems="center"
          gap={2}
          direction="column"
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: 20,
              lineHeight: 1.27,
              color: "#0674b9",
            }}
          >
            Are you sure you want to confirm 
            <br />
            delivery of the selected lines?
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            gap={1}
            alignItems="center"
          >
            <Grid item>
              <button
                // className={classes["back-button"]}
                onClick={() => {
                  handleCloseModal();
                }}
                style={{
                  border: "solid 1px #0674b9",
                  backgroundColor: "#fff",
                  color: "#0674b9",
                  // width: "66px",
                  fontSize: 14,
                  borderRadius: 5,
                  cursor: "pointer",
                  padding: "5px 20px 5px 20px"

                }}
              >
                No
              </button>
            </Grid>
            <Grid item>
              <button
                // className={classes["back-button"]}
                onClick={() => {
                  confirmSo();
                }}
                style={{
                  backgroundColor: confirmLoading ? "#707070" : "#0674b9",
                  color: "#fff",
                  // width: "66px",
                  fontSize: 14,
                  border: 0,
                  borderRadius: 5,
                  cursor: confirmLoading ? "unset" : "pointer",
                  padding: "5px 20px 5px 20px"



                }}
              >
                {confirmLoading ? "Saving..." : "Yes"}
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose} />

    </div>
  );
}
const styles = {
  tableCell: {
    padding: "1%",
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: "250px",
  },
  tableCellEmpty: {
    backgroundColor: "#EFF183",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 43,
  },
  tableCellQty: {
    padding: 0,
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 600,
  },
  tableSubCellSo: {
    backgroundColor: "rgba(225, 227, 25,0.9)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "left",
    padding: "0 0 0 1%",
  },
  tableSubCell: {
    backgroundColor: "rgba(225, 227, 25, 0.5)",
    fontSize: 11,
    fontFamily: "Muli",
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    padding: 0,
  },
  tableSubSubCell: {
    border: "solid 1px",
    borderColor: "#e1e319",
    backgroundColor: "#fff",
    // backgroundColor: '#CED2F5',
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    padding: 0,
  },
  tableCellImage: {
    backgroundColor: "#fff",
    padding: 0,
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 800,
  },
  tableSubImage2: {
    backgroundColor: "#EFF183",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    width: 400,
    padding: 0,
    // height: 38,
  },
  tableRow: {
    backgroundColor: "#fff",
    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
    maxHeight: 38,
  },
  tableCell2: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#0674b9",
  },
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    paddingLeft: 2,
    height: 5,
  },
  tfInput2: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    textAlign: "center",
    height: 5,
  },
  modalTableCell: {
    fontFamily: "Muli",
    fontSize: 10,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#000",
    borderRight: "solid 0.5px #707070",
    borderBottom: "solid 0.5px #707070",
  },
};
