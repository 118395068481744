import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Select, CircularProgress, TableContainer, Table, TableRow, TableHead, TableCell, TableBody } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import { IconButton, Tooltip } from "@mui/material";
import infoTip from "../../../Assets/Images/info.png";
import { MenuItem, FormControl, FormHelperText } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ProgressBar from "./ProgressBar";
import fileIcon from "../../../Assets/Images/Table/file.png";
import CloseIcon from "@mui/icons-material/Close";
import classes from "../../../Assets/Styles/Sales-Order.module.css";
import LabelIcon from "../../../Assets/Images/SalesOrder/label.png";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import * as salesOrderConst from "../../../../salesOrderConst";
import { useSelector } from "react-redux";
import HoldOrderModal from "./HoldOrderModal";
import * as permissionFunctions from "../../../../ReusableFunctions";
import ErrorModal from "../../../SharedComponents/ErrorModal";
import UploadIcon from '@mui/icons-material/Upload';

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const uploadFile = salesOrderServices.uploadFile;
const deleteFile = salesOrderServices.deleteFile;
const changeStatus = salesOrderServices.changeSalesOrderStatus;
const holdSO = salesOrderServices.holdSO;

function SalesOrderDetails(props) {
  const [openHoldModal, setOpenHoldModal] = useState(false);
  const [loadingHold, setLoadingHold] = useState(false);
  const [successHold, setSuccessHold] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledOnHold, setDisabledOnHold] = useState(false);
  const [disabledRequestOnHold, setDisabledRequestOnHold] = useState(false);
  const [disabledSugestReqVal, setDisabledSugestReqVal] = useState(false);
  const [disabledPrinTLabel, setDisabledPrinTLabel] = useState(false);

  useEffect(() => {
    if (props.subContractorsEmpty)
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    else window.scrollTo(0, 0);
  }, [props.subContractorsEmpty]);
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "On hold",
        setDisabledOnHold
      );
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Request on hold",
        setDisabledRequestOnHold
      );
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Validate / Suggest delivery date",
        setDisabledSugestReqVal
      );
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Print Label",
        setDisabledPrinTLabel
      );
    }
  }, [UserPermissions]);

  const holdingSO = () => {
    setLoadingHold(true);
    holdSO(props.SALES_ORDER_ID, salesOrderConst.HOLD_ID).then((x) => {
      setLoadingHold(false);
      setOpenHoldModal(false);
      props.setModify(!props.Modify);
      setSuccessHold(true);
      setTimeout(() => {
        setSuccessHold(false);
      }, 6000);
    });
  };
  const styles = {
    blueFields: {
      fontFamily: "Muli",
      color: " #0674b9",
    },

    greyFields: {
      fontFamily: "Muli",
      color: "#707070",
    },

    title: {
      fontFamily: "Muli",
      color: " #101650",
      fontWeight: "bold",
      borderBottom: "solid 1px #e1e319",
    },
    Dateinput: {
      height: "45px",
      fontSize: "30px",
      fontFamily: "Muli",
    },

    tableCell: {
      backgroundColor: "#fff",
      fontFamily: "Muli",
      fontSize: 12,
      color: "#707070",
      lineHeight: 1.17,
      textAlign: "left",
      borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
      maxHeight: "20px",
      width: 400,
    },
    tableCellhead: {
      backgroundColor: "#fff",
      fontFamily: "Muli",
      fontSize: 12,
      color: "#707070",
      textAlign: "left",
      borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
      maxHeight: "20px",
      width: 90,
    },
    tableCellhead3: {
      backgroundColor: "#fff",
      fontFamily: "Muli",
      fontSize: 12,
      color: "#0674b9",
      textAlign: "center",
      borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
      maxHeight: "20px",
      width: 75,
      background:
        "linear-gradient(to top right, #fff calc(50% - 1px), rgba(206, 210, 225, 0.97) , #fff calc(50% + 1px))",
    },
    tableCellhead2: {
      backgroundColor: "#fff",
      fontFamily: "Muli",
      fontSize: 12,
      color: "#0674b9",
      textAlign: "center",
      borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
      maxHeight: "20px",
    },
    tfInput: {
      color: "#707070",
      height: "28px",
      fontSize: 12,
      fontFamily: "Muli",
    },


  };

  const [customerOrderReferenceErrorMsg, setCustomerOrderReferenceErrorMsg] =
    useState("");
  const [drawingNbErrorMsg, setDrawingNbErrorMsg] = useState("");
  const [subcontractorErrorMsg, setSubcontractorErrorMsg] = useState("");
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [loading, setLoading] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const [selectedCatalog, setSelectedCatalog] = useState();
  const [uploadedFile, setUploadedFile] = useState();


  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
  
  width:110px;
          color: #101650;
          background-color: rgba(6, 116, 185, 0.3);
          font-size: 10px;
       
      `);
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setUploadingFile(true);
      uploadFile(e.target.files[0], props.projectId).then((x) => {
        props.setFile(x);
        props.setFileId(x.FileId);
        setUploadingFile(false);
        setUploadedFile(x);
      });
    }
  };

  const changeOrderStatus = (salesOrderid, statusId) => {
    setLoading(true)
    changeStatus(salesOrderid, statusId).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        props.setModify(!props.modify);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (props.file) {
      let tempFile = null;
      tempFile = props.file;
      props.files.push(tempFile);
    }
  }, [props.file]);

  useEffect(() => {
    if (props.fileId) {
      let tempFileId = null;
      tempFileId = props.fileId;
      let fileData = { FileId: tempFileId };
      props.fileIds.push(fileData);
    }
  }, [props.fileId]);
  const deleteItem = (item) => {
    const index = props.files.indexOf(item);
    deleteFile(item.FileId, props.projectId).then(() => {
      if (index > -1) {
        props.files.splice(index, 1);
        props.fileIds.splice(index, 1);
        forceUpdate();
        // 2nd parameter means remove one item only
      }
    });
  };

  return (
    <>
      <Grid
        container
        paddingTop={2}
        paddingBottom={10}
        paddingLeft={2}
        gap={3}
        fontSize={{ xs: "11px", sm: "16px" }}
      >
        <Grid item container direction="row" gap={{ xs: 3, lg: 15 }}>
          <Grid
            item
            xs={2.1}
            sm={2.5}
            lg={1.5}
            style={styles.title}
            fontSize={{ xs: "9px", sm: "16px" }}
          >
            Order Details
          </Grid>
          {props.members.length === 0 ?
            <Grid item xs={2}>
              <button
                className={classes["importButton"]}
                disabled={!props.subProjectId}
                onClick={() => setOpenUpload(true)}
              >
                Import
              </button></Grid>
            :
            <></>
          }
          <Grid item xs={9} sm={8} lg={5.5}>
            <ProgressBar status={props.status} />
          </Grid>


        </Grid>
        {openUpload && props.members.length === 0 ?
          <Grid item container direction="row" xs={12} columnGap={0.5} alignItems="center">
            <Grid item width="150px">
              <Button
                fullWidth
                component="label"
                className={classes["fieldUploadDesign"]}
                sx={{ backgroundColor: "#f3f3f3" }}
              >
                <Grid container item xs={12} justifyContent="center" alignItems="center" padding={0.5}>
                  <Grid item className={classes["fileText"]}>
                    Upload File
                  </Grid>
                  <Grid item paddingTop={0.5}>
                    <UploadIcon />
                  </Grid>
                </Grid>
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </Button>
            </Grid>
            {uploadingFile ?
              <Grid item>
                <CircularProgress size="25px" />
              </Grid> :
              <></>}
            {uploadedFile ?
              <>
                <Grid item paddingLeft={1}>
                  <img src={fileIcon} alt="file" width={15} />
                </Grid>
                <Grid
                  item
                  fontSize={{ xs: 10, md: 14 }}
                  onMouseOver={(e) => {
                    e.target.style.color = "grey";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "#000";
                  }}
                  onClick={() => {
                    window.open(uploadedFile.FileId);
                  }}
                >
                  <span>{uploadedFile.DisplayName.slice(0, 11)}</span>
                </Grid>
                <Grid
                  item
                  paddingLeft={2}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    deleteItem(uploadedFile);
                    setUploadedFile(null)
                    props.setFile(null);
                    props.setFileId(null);
                    forceUpdate();
                  }}
                >
                  <Grid item>
                    <CloseIcon fontSize="small" />
                  </Grid>
                </Grid>

              </>


              : (
                <></>
              )}

            <Grid item container xs={12} direction="column" spacing={1} paddingTop={1}>
              <Grid item style={styles.greyFields}>
                *Shape Catalog
              </Grid>
              <Grid item>
                <TextField
                  select
                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                  value={selectedCatalog}
                  fullWidth
                  InputProps={{
                    style: { height: "45px", width: "150px" },
                  }}
                  placeholder="Shape Catalog"

                  onChange={(e) => {
                    setSelectedCatalog(e.target.value);
                  }}
                >
                  <MenuItem value={0} key={0} disabled>
                    Shape Catalog
                  </MenuItem>
                  {props.catalogs?.map((r) => (
                    <MenuItem
                      value={r.Id}
                      key={r.Id}
                      style={{ display: "block" }}
                    >
                      {r.Name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={2} paddingTop={1}>
              <button
                className={classes["importButton"]}
                disabled={!props.subProjectId || !uploadedFile || !selectedCatalog || props.importingSdiLoading}
                onClick={() => props.importingSdi(uploadedFile.FileId, selectedCatalog)}
              >
                {props.importingSdiLoading ? "Importing ..." : "Import to SO"}
              </button></Grid>

          </Grid>

          :
          <></>
        }

        <Grid
          item
          container
          xs={5.3}
          md={2.7}
          direction="column"
          spacing={1}
          paddingTop={2}
        >
          <Grid item style={styles.blueFields}>
            Order No.
          </Grid>
          <Grid item>
            <TextField
              value={props.orderNb}
              fullWidth
              placeholder="Order No."
              InputProps={{ style: { height: "45px" } }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5.3}
          md={2.7}
          direction="column"
          spacing={1}
          paddingTop={2}
        >
          <Grid item style={styles.greyFields}>
            * Project Name
          </Grid>
          <Grid item>
            <TextField
              value={props.projectName}
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="Project Name"
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5.3}
          md={2.7}
          direction="column"
          spacing={1}
          paddingTop={2}
        >
          <Grid item style={styles.greyFields}>
            *Phase Name
          </Grid>
          <Grid item>
            <TextField
              value={props.phaseName}
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="Phase Name"
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5.3}
          md={2.7}
          direction="column"
          spacing={1}
          paddingTop={2}
        >
          <Grid item style={styles.greyFields}>
            *Subproject Name
          </Grid>
          <Grid item>
            <TextField
              value={props.subprojectName}
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="Subproject Name"
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Customer Company Name
          </Grid>
          <Grid item>
            <TextField
              value={
                props.customerCompanyName
              }
              error={props.customerCompanyNameEmpty}
              helperText={
                props.customerCompanyNameEmpty
                  ? "No manufacturing contracts on this phase"
                  : ""
              }
              fullWidth
              placeholder="Customer Company Name"
              InputProps={{ style: { height: "45px" } }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.greyFields}>
            *Customer Order Reference
          </Grid>
          <Grid item>
            <TextField
              disabled={
                props.disabledAllFields ||
                (props.disabledEdit && props.SALES_ORDER_ID)
              }
              value={props.customerOrderReference}
              error={props.customerOrderReferenceError}
              helperText={
                props.customerOrderReferenceError
                  ? customerOrderReferenceErrorMsg
                  : ""
              }
              InputProps={{ style: { height: "45px" } }}
              placeholder="Customer Order Reference"
              multiline
              rows={1}
              onBlur={(e) => {
                if (!e.target.value) {
                  props.setCustomerOrderReferenceError(true);
                  setCustomerOrderReferenceErrorMsg(
                    "Enter a Customer Order Reference"
                  );
                }
                else props.setCustomerOrderReferenceError(false);
              }}
              onChange={(e) => {
                props.setCustomerOrderReference(e.target.value);
                if (!e.target.value) {
                  props.setCustomerOrderReferenceError(true);
                  setCustomerOrderReferenceErrorMsg(
                    "Enter a Customer Order Reference"
                  );
                } else if (e.target.value.length > 30) {
                  props.setCustomerOrderReferenceError(true);
                  setCustomerOrderReferenceErrorMsg(
                    "Customer Order Reference should be less than 30 characters"
                  );
                } else props.setCustomerOrderReferenceError(false);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column">
          <Grid item container direction="row">
            <Grid item xs={7.5} sm={4.5} style={styles.greyFields}>
              Attachments
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton
                edge="end"
                onClick={openTip}
                onMouseOver={openTip}
                onMouseLeave={closeTip}
              >
                <BlueTip
                  open={tooltipIsOpen}
                  title={
                    props.files?.length > 0
                      ? "Click on file to download"
                      : "Multiple files are allowed"
                  }
                  placement="right"
                >
                  <img src={infoTip} alt={"close"} width="13px"></img>
                </BlueTip>
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              component="label"
              style={{
                width: "100%",
                textTransform: "none",
                fontFamily: "Muli",
                fontSize: "12px",
                textAlign: "left",
                color: "#101650",
                backgroundColor: "white",
                border: "solid 1px #b8b8b8",
                display: "flex",
                justifyContent: "flex-start",
                height: "45px",
              }}
            >
              <FileUploadOutlinedIcon
                htmlColor="#529bd7"
                style={{
                  alignSelf: "center",
                  paddingRight: "5%",
                }}
              />
              <span style={{ alignSelf: "center" }}>Upload File</span>
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                disabled={
                  props.disabledAllFields ||
                  (props.disabledEdit && props.SALES_ORDER_ID)
                }
              />
            </Button>
          </Grid>
          {props.files?.length >= 0 ? (
            props.files.map((item) => (
              <Grid
                item
                container
                direction={"row"}
                key={item.FileId}
                value={item}
                marginTop={2}
              >
                <Grid itempaddingLeft={1}>
                  <img src={fileIcon} alt="file" width={15} />
                </Grid>
                <Grid
                  item
                  xs={5}
                  fontSize={{ xs: 10, md: 14 }}
                  onMouseOver={(e) => {
                    e.target.style.color = "grey";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "#000";
                  }}
                  onClick={() => {
                    window.open(item.file.URL);
                  }}
                >
                  <span>{item.DisplayName.slice(0, 11)}</span>
                </Grid>
                <Grid
                  item
                  paddingLeft={2}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    deleteItem(item);
                    props.setFile(null);
                    props.setFileId(null);
                    forceUpdate();
                  }}
                >
                  <Grid item>
                    <CloseIcon fontSize="small" />
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>


        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.greyFields}>
            *Type
          </Grid>
          <Grid item>
            <TextField
              disabled={
                props.disabledAllFields ||
                (props.disabledEdit && props.SALES_ORDER_ID) || props.ACT_ID
              }
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              value={props.typeId}
              
              error={props.typeIdError}
              helperText={props.typeIdError ? "Choose type" : ""}
              fullWidth
              InputProps={{
                style: { height: "45px" },
              }}
              placeholder="Bar Grade"
              onBlur={(e) => {
                if (!e.target.value) {
                  props.setTypeIdError(true);
                }
                else props.setTypeIdError(false);
              }}
              onChange={(e) => {
                props.setTypeId(e.target.value);
                if (!e.target.value) {
                  props.setTypeIdError(true);
                } else props.setTypeIdError(false);
              }}
            >
              <MenuItem value={0} key={0} disabled>
                Type
              </MenuItem>
              {salesOrderConst.salesOrderTypes?.map((r) => (
                <MenuItem
                  value={r.Id}
                  key={r.Id}
                  style={{ display: "block" }}
                >
                  {r.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {props.activityCode ? (
          <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.greyFields}>
              Activity Code
            </Grid>
            <Grid item>
              <TextField
                value={props.activityCode}
                fullWidth
                InputProps={{ style: { height: "45px" } }}
                placeholder="Activity Code"
                disabled
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={5.3}
            md={2.7}
            direction="column"
            spacing={1}
          ></Grid>
        )}

        <Grid item container xs={11.6} spacing={1}>
          <Grid item xs={12} style={styles.greyFields}>
            Drawings Nb
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={props.drawingNb}
              disabled={
                props.disabledFromActivity ||
                props.disabledAllFields ||
                (props.disabledEdit && props.SALES_ORDER_ID)
              }
              // error={props.drawingNbError}
              // helperText={props.drawingNbError ? drawingNbErrorMsg : ""}
              multiline
              rows={2}
              fullWidth
              placeholder="Drawings Nb"
              // onBlur={(e) => {
              //   if (!e.target.value) {
              //     props.setDrawingNbError(true);
              //     setDrawingNbErrorMsg("Enter a number for drawing");
              //   }
              //   else props.setDrawingNbError(false);

              // }}
              onChange={(e) => {
                props.setDrawingNb(e.target.value);
                if (!e.target.value) {
                  props.setDrawingNbError(true);
                  setDrawingNbErrorMsg("Enter a number for drawing");
                } else if (e.target.value.length > 150) {
                  props.setDrawingNbError(true);
                  setDrawingNbErrorMsg(
                    "Drawing No. should be less than 150 characters"
                  );
                } else props.setDrawingNbError(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={11.6} spacing={1}>
          <Grid item xs={12} style={styles.greyFields}>
            Description
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={props.description}
              disabled={
                props.disabledFromActivity ||
                props.disabledAllFields ||
                (props.disabledEdit && props.SALES_ORDER_ID)
              }
              // error={props.descriptionError}
              // helperText={props.descriptionError ? "Enter a description < 150 characters" : ""}
              multiline
              rows={2}
              fullWidth
              placeholder="Description"
              // onBlur={(e) => {
              //   if (!e.target.value) {
              //     props.setDescriptionError(true);
              //   }
              //   else props.setDescriptionError(false);

              // }}
              onChange={(e) => {
                props.setDescription(e.target.value);
                if (!e.target.value) {
                  props.setDescriptionError(true);
                } else if (e.target.value.length > 150) {
                  props.setDescriptionError(true);
                } else props.setDescriptionError(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.greyFields}>
            Drawing Revision No.
          </Grid>
          <Grid item>
            <TextField
              disabled={
                props.disabledFromActivity ||
                props.disabledAllFields ||
                (props.disabledEdit && props.SALES_ORDER_ID)
              }
              type="number"
              value={props.drawingRevisionNb}
              defaultValue={0}
              fullWidth
              InputProps={{ style: { height: "45px" }, inputProps: { min: 0 } }}
              placeholder="Drawing Revision No."
              onChange={(e) => {
                props.setDrawingRevisionNb(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Status
          </Grid>
          <Grid item>
            <TextField
              value={
                props.status === salesOrderConst.DRAFT_ID
                  ? salesOrderConst.DRAFT
                  : props.status === salesOrderConst.PLACED_ID
                    ? salesOrderConst.PLACED
                    : props.status === salesOrderConst.PLANNED_ID
                      ? salesOrderConst.PLANNED
                      : props.status === salesOrderConst.IN_PRODUCTION_ID
                        ? salesOrderConst.IN_PRODUCTION
                        : props.status === salesOrderConst.LOADING_ID
                          ? salesOrderConst.LOADING
                          : props.status === salesOrderConst.DELIVERED_ID
                            ? salesOrderConst.DELIVERED
                            : props.status === salesOrderConst.HOLD_ID
                              ? salesOrderConst.ON_HOLD
                              : props.status === salesOrderConst.INTERRUPTED_ID
                                ? salesOrderConst.INTERRUPTED
                                : props.status === salesOrderConst.PRODUCED_ID
                                  ? salesOrderConst.PRODUCED
                                  : ""
              }
              InputProps={{ style: { height: "45px" } }}
              fullWidth
              placeholder="Status"
              disabled
            />
          </Grid>
        </Grid>
        {props.SALES_ORDER_ID &&
          props.status === salesOrderConst.IN_PRODUCTION_ID ? (
          <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.blueFields}>
              Production Progress
            </Grid>
            <Grid item>
              <TextField
                value={props.productionProgress}
                InputProps={{ style: { height: "45px" } }}
                fullWidth
                placeholder="Status"
                disabled
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {props.SALES_ORDER_ID &&
          (props.status === salesOrderConst.DRAFT_ID ||
            props.status === salesOrderConst.PLACED_ID ||
            props.status === salesOrderConst.PLANNED_ID ||
            props.status === salesOrderConst.IN_PRODUCTION_ID) ? (
          <>
            <Grid item xs={2} md={2}>
              <button
                disabled={
                  loadingHold || (disabledOnHold && disabledRequestOnHold)
                }
                className={classes["hold-button"]}
                onClick={() => {
                  if (!disabledOnHold) {
                    setOpenHoldModal(true);
                  } else if (!disabledRequestOnHold) holdingSO();
                }}
              >
                {!disabledOnHold
                  ? loadingHold
                    ? "Holding..."
                    : "Hold Order"
                  : loadingHold
                    ? "Requesting..."
                    : "Request Hold Order"}
              </button>
            </Grid>
            {successHold ? (
              <Grid
                item
                xs={8.6}
                md={3.4}
                marginTop={5}
                style={{ fontSize: "13px", fontFamily: "Muli", color: "green" }}
              >
                Succesfully Requested
              </Grid>
            ) : (
              <Grid item xs={8.6} md={3.4}></Grid>
            )}
          </>
        ) : (
          <></>
        )}

        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Sent By
          </Grid>
          <Grid item>
            <TextField
              value={props.sentBy}
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="Sent By"
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Creation Date & Time
          </Grid>
          <Grid item>
            <TextField
              value={
                props.creationDateAndTime
                  ? new Date(props.creationDateAndTime).toLocaleString()
                  : ""
              }
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="Creation Date & Time"
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.greyFields}>
            *Sub Contractor
          </Grid>
          <Grid item>
            {props.subcontractorsList.length === 0 &&
              props.subProjectCreator !== null ? (

              <>
                <TextField
                  value={props.subProjectCreator}
                  InputProps={{ style: { height: "45px" } }}
                  fullWidth
                  placeholder="Sub Contractor"
                  disabled
                />
              </>
            ) : (
              <FormControl fullWidth error={props.subcontractorError}>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  disabled={
                    props.disabledAllFields ||
                    (props.disabledEdit && props.SALES_ORDER_ID)
                  }
                  value={props.subcontractor}
                  fullWidth
                  sx={{ height: "45px" }}
                  defaultValue={0}
                  onBlur={(e) => {
                    if (!e.target.value && !props.subContractorsEmpty) {
                      props.setSubcontractorError(true);
                      setSubcontractorErrorMsg("Choose a Subcontractor");
                    } else {
                      props.setSubcontractorError(false);
                    }
                  }}
                  onChange={(e) => {
                    props.setSubcontractor(e.target.value);
                    if (!e.target.value && !props.subContractorsEmpty) {
                      props.setSubcontractorError(true);
                      setSubcontractorErrorMsg("Choose a Subcontractor");
                    } else {
                      props.setSubcontractorError(false);
                    }
                  }}
                >
                  <MenuItem value={0} key={0} disabled>
                    Sub Contractor
                  </MenuItem>
                  {props.subcontractorsList.map((r) => (
                    <MenuItem
                      value={r.Id}
                      key={r.Id}
                      style={{ display: "block" }}
                    >
                      {r.Name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {props.subcontractorError
                    ? subcontractorErrorMsg
                    : props.subContractorsEmpty
                      ? "No manufacturing contracts on this subproject"
                      : props.factoryDetailsEmpty ?
                        "This subcontractor didn't fill factory's details yet in the contract"
                        : ""}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Factory No.
          </Grid>
          <Grid item>
            <TextField
              value={props.factoryNb ? props.factoryNb : "Factory No."}
              InputProps={{ style: { height: "45px" } }}
              fullWidth
              placeholder="Factory No."
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Unit of Measures
          </Grid>
          <Grid item>
            <TextField
              value={props.unitOfMeasures}
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="mm - kg"
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={5.5} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Receipt Date
          </Grid>
          <Grid item>
            <TextField
              value={props.receiptDate}
              fullWidth
              InputProps={{ style: { height: "45px" } }}
              placeholder="dd/mm/yyyy"
              disabled
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} gap={3}>
          <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.greyFields}>
              *Bar Standard
            </Grid>
            <Grid item>
              <TextField
                disabled={
                  props.disabledAllFields ||
                  (props.disabledEdit && props.SALES_ORDER_ID) || props.ACT_ID
                }
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={props.barStandard}
                // defaultValue={props.barStandards.find((b)=> b.Id === props.barStandards)?props.barStandard:props.barStandards[0]?.Id}
                error={props.barStandardError}
                helperText={props.barStandardError ? "Enter bar standard" : ""}
                fullWidth
                InputProps={{
                  style: { height: "45px" },
                }}
                placeholder="Bar Standard"
                onBlur={(e) => {
                  if (!e.target.value) {
                    props.setBarStandardError(true);
                  } else props.setBarStandardError(false);
                }}
                onChange={(e) => {
                  props.setBarStandard(e.target.value);
                  if (!e.target.value) {
                    props.setBarStandardError(true);
                  } else props.setBarStandardError(false);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Bar Standard
                </MenuItem>
                {props.barStandards.map((r) => (
                  <MenuItem
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.greyFields}>
              *Bar Grade
            </Grid>
            <Grid item>
              <TextField
                disabled={
                  props.disabledAllFields ||
                  (props.disabledEdit && props.SALES_ORDER_ID) || props.ACT_ID
                }
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={props.barGrade}
                error={props.barGradeError}
                helperText={props.barGradeError ? "Enter bar grade" : ""}
                fullWidth
                InputProps={{
                  style: { height: "45px" },
                }}
                placeholder="Bar Grade"
                onBlur={(e) => {
                  if (!e.target.value) {
                    props.setBarGradeError(true);
                  }
                  else props.setBarGradeError(false);
                }}
                onChange={(e) => {
                  props.setBarGrade(e.target.value);
                  if (!e.target.value) {
                    props.setBarGradeError(true);
                  } else props.setBarGradeError(false);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Bar Grade
                </MenuItem>
                {props.barGrades.map((r) => (
                  <MenuItem
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item container xs={5.3} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.greyFields}>
              *Bar Coating
            </Grid>
            <Grid item>
              <TextField
                disabled={
                  props.disabledAllFields ||
                  (props.disabledEdit && props.SALES_ORDER_ID) || props.ACT_ID
                }
                value={props.barCoating}
                error={props.barCoatingError}
                helperText={props.barCoatingError ? "Enter bar coating" : ""}
                fullWidth
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                InputProps={{
                  style: { height: "45px" },
                }}
                placeholder="Bar Coating"
                onBlur={(e) => {
                  if (!e.target.value) {
                    props.setBarCoatingError(true);
                  }
                  else props.setBarCoatingError(false);
                }}
                onChange={(e) => {
                  props.setBarCoating(e.target.value);
                  if (!e.target.value) {
                    props.setBarCoatingError(true);
                  } else props.setBarCoatingError(false);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Bar Coating
                </MenuItem>
                {props.barCoatings.map((r) => (
                  <MenuItem
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5.5}
          md={2.7}
          direction="column"
          spacing={1}
          justifyContent="flex-start"
        >
          <Grid item style={styles.greyFields}>
            *Planned Delivery Date(Min {props.factoryMinDaysToDeliver})
          </Grid>
          <Grid item>
            {props.minPlannedDate ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  value={props.plannedDeliveryDate}
                  minDate={
                    new Date(props.minPlannedDate) < new Date()
                      ? new Date()
                      : new Date(props.minPlannedDate)
                  }
                  onChange={(newValue) => {
                    props.setPlannedDeliveryDate(newValue);
                  }}
                  disabled={
                    props.disabledAllFields ||
                    (props.disabledEdit && props.SALES_ORDER_ID)
                  }
                  renderInput={(params) => (
                    <TextField
                      // style={{height:"20px"}}
                      // sx={styles.Dateinput}
                      // InputProps={{ style: styles.tfinput }}
                      size={"small"}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={5.5} md={2.7} direction="column" spacing={1}>
          <Grid item style={styles.blueFields}>
            Delivery Date Status
          </Grid>
          <Grid item>
            <TextField
              value={
                props.deliveryDateStatus === salesOrderConst.NA_ID
                  ? salesOrderConst.NA
                  : props.deliveryDateStatus === salesOrderConst.REQUESTED_ID
                    ? salesOrderConst.REQUESTED
                    : props.deliveryDateStatus === salesOrderConst.VALIDATED_ID
                      ? salesOrderConst.VALIDATED
                      : ""
              }
              InputProps={{ style: { height: "40px" } }}
              fullWidth
              placeholder="N/A"
              disabled
            />
          </Grid>
        </Grid>
        {props.status !== salesOrderConst.DRAFT_ID ? (
          <>
            <Grid item xs={3} md={1} alignSelf="center" paddingTop={3}>
              {props.isvalidator ? (
                <Button
                  disabled={disabledSugestReqVal}
                  style={{
                    borderRadius: 5,
                    backgroundColor: "rgba(6, 116, 185, 0.7)",
                    fontFamily: "Muli",
                    fontSize: 12,
                    lineHeight: 1.5,
                    textAlign: "left",
                    color: "#fff",
                    textTransform: "none",
                    padding: "5px 10px 5px 10px",
                  }}
                  onClick={(e) => {
                    e.target.style.cursor = "pointer";
                    props.setOpenModal(true);
                    props.setValidate(true);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.backgroundColor = "#0674b9";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.backgroundColor = "rgba(6, 116, 185, 0.7)";
                  }}
                >
                  Validate
                </Button>
              ) : (
                <Grid
                  item
                  xs={3}
                  md={1}
                  alignSelf="center"
                  paddingTop={3}
                ></Grid>
              )}
            </Grid>

            <Grid item xs={2} md={1.5} alignSelf="center" paddingTop={3}>
              <Button
                disabled={disabledSugestReqVal}
                style={{
                  borderRadius: 5,
                  backgroundColor: "rgba(6, 116, 185, 0.7)",
                  fontFamily: "Muli",
                  fontSize: 12,
                  lineHeight: 1.5,
                  textAlign: "left",
                  color: "#fff",
                  textTransform: "none",
                  padding: "5px 10px 5px 10px",
                }}
                onClick={(e) => {
                  e.target.style.cursor = "pointer";
                  props.setOpenModal(true);
                  props.setSuggest(true);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.backgroundColor = "#0674b9";
                }}
                onMouseOut={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.backgroundColor = "rgba(6, 116, 185, 0.7)";
                }}
              >
                Suggest New Date
              </Button>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {props.deliveryDateStatus === salesOrderConst.REQUESTED_ID &&
          props.suggestedDate ? (
          <Grid item container xs={5.5} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.blueFields}>
              New Suggested Date
            </Grid>
            <Grid item>
              <TextField
                value={new Date(props.suggestedDate).toLocaleDateString()}
                fullWidth
                InputProps={{ style: { height: "45px" } }}
                placeholder="mm/dd/yyyy"
                disabled
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={5.5} md={2.7}></Grid>
        )}
        <Grid item container xs={12} gap={3}>
          <Grid item container xs={5.5} md={2.7} direction="column" spacing={1}>
            <Grid item style={styles.blueFields}>
              Sales Order Weight (kg)
            </Grid>
            <Grid item>
              <TextField
                value={props.salesOrderWeightTheoretical}
                InputProps={{ style: { height: "45px" } }}
                fullWidth
                placeholder="Sales Order Weight"
                disabled
              />
            </Grid>
          </Grid>
          {props.deliveryDateStatus === salesOrderConst.REQUESTED_ID &&
            props.commentForSuggestion ? (
            <>
              <Grid
                item
                container
                xs={5.5}
                md={2.7}
                direction="column"
                spacing={1}
              >
                <Grid item style={styles.blueFields}>
                  Comment Section
                </Grid>
                <Grid item>
                  <TextField
                    value={props.commentForSuggestion}
                    multiline
                    rows={2}
                    fullWidth
                    placeholder="Date Suggestion Comment"
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={5.3} md={5.7}></Grid>
            </>
          ) : (
            <Grid item xs={5.3} md={8.5}></Grid>
          )}
        </Grid>

        <Grid container direction={"row-reverse"} gap={2} paddingRight={5}>
          {props.status !== salesOrderConst.DRAFT_ID &&
            (localStorage.getItem("IsFreePlanActivated") === "true" ||
              localStorage.getItem("planIdManu")?.includes("Core")) &&
            !disabledPrinTLabel ? (
            <Grid
              container
              width={145.7}
              item
              className={classes["Path-1273"]}
              direction={"column"}
              gap={1}
              onClick={() => {
                props.setShowSaaSNavbar(false);
                props.setPreviewPrinting(true);
              }}
            >
              <Grid item paddingTop={1}>
                Print Labels
              </Grid>
              <Grid item>
                <img src={LabelIcon} alt={"11"} />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {(localStorage.getItem("IsFreePlanActivated") === "true" ||
            localStorage.getItem("planIdManu")?.includes("Core")) &&
            (props.status === salesOrderConst.PLANNED_ID ||
              props.status === salesOrderConst.IN_PRODUCTION_ID) ? (
            <Grid item>
              <Button
                disabled={loading}
                style={{
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  fontFamily: "Muli",
                  fontSize: 12,
                  fontWeight: "bold",
                  lineHeight: 1.5,
                  textAlign: "left",
                  color: "#2da4d0",
                  textTransform: "none",
                  padding: "15px 20px 15px 20px",
                  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                  cursor: loading ? "unset" : "pointer"
                }}
                onMouseOver={(e) => {
                  if (!loading) {
                    e.target.style.cursor = "pointer";
                    e.target.style.backgroundColor = "#0674b9";
                    e.target.style.color = "#fff";
                  }

                }}
                onMouseOut={(e) => {
                  if (!loading) {
                    e.target.style.backgroundColor = "#fff";
                    e.target.style.color = "#2da4d0";
                  }

                }}
                onClick={() => {
                  if (props.status === salesOrderConst.PLANNED_ID)
                    changeOrderStatus(
                      props.SALES_ORDER_ID,
                      salesOrderConst.IN_PRODUCTION_ID
                    );
                  else if (props.status === salesOrderConst.IN_PRODUCTION_ID)
                    changeOrderStatus(
                      props.SALES_ORDER_ID,
                      salesOrderConst.LOADING_ID
                    );
                }}
              >
                {loading
                  ? "Changing Status..."
                  : props.status === salesOrderConst.PLANNED_ID
                    ? "Move To In Production"
                    : props.status === salesOrderConst.IN_PRODUCTION_ID
                      ? "Mark as loading"
                      : ""}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      {/* {props.productCount?.some(i => i.Count > 0) ?
        <Grid item container direction='column' margin={3}>
          <Grid item style={styles.title} width='180px'> Product Series Quantity</Grid>
          <Grid item marginTop={4}
            style={{
              width: "100%",
              overflowX: "auto"
            }}>
            <TableContainer sx={{ width: "max-content" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 0px",
                  border: 0,
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>Product Series</TableCell>
                    {props.productCount?.filter((c) => c.Count >0)?.map((i, index) => (
                      <TableCell sx={styles.tableCellhead2} key={i.GradeId}>
                        {i.GradeId}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                  <TableCell sx={styles.tableCellhead}>Count</TableCell>
                    {props.productCount?.filter((c) => c.Count >0)?.map((i, index) => (
                      <TableCell sx={styles.tableCellhead2} key={i.GradeId}>
                        {i.Count}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>

              </Table>
            </TableContainer>

          </Grid>


        </Grid>
        :
        <></>
      } */}

      <Grid item>
        <HoldOrderModal
          openModal={openHoldModal}
          setOpenModal={setOpenHoldModal}
          holdingSO={holdingSO}
          loadingHold={loadingHold}
        />
      </Grid>
    </>
  );
}

export default SalesOrderDetails;
