import React, { useEffect, useState } from "react";
import classes from "./MilesStones.module.css";
import BlueCircle from "../../../Assets/Images/MileStones/blueCircle.png";
import YellowCircle from "../../../Assets/Images/MileStones/YellowCircle.png";
import DarkBlueCircle from "../../../Assets/Images/MileStones/DarkBlueCircle.png";
import GreenCircle from "../../../Assets/Images/MileStones/GreenCircle.png";
import HoveredBlueCircle from "../../../Assets/Images/MileStones/HoveredBlue.png";
import HoveredYellowCircle from "../../../Assets/Images/MileStones/HoveredYellow.png";
import HoveredDarkBlueCircle from "../../../Assets/Images/MileStones/hoveredDarkBlue.png";
import HoveredGreenCircle from "../../../Assets/Images/MileStones/HoveredGreen.png";
import YesIcon from "../../../Assets/Images/MileStones/yesIcon.png";
import YesGreenIcon from "../../../Assets/Images/MileStones/yesGreenIcon.png";
import CancelIcon from "../../../Assets/Images/MileStones/cancelIcon.png";
import MilesStonesIcon from "../../../Assets/Images/MileStones/milesStonesIcon.png";
import CautionIcon from "../../../Assets/Images/MileStones/caution.png";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid, TextField } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useDispatch, useSelector } from "react-redux";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import ErrorModal from "./ErrorModal";
import * as mileStonesServices from "../../../Services/milestones-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";



import EventRepeatIcon from "@mui/icons-material/EventRepeat";
const getMileStonesList = mileStonesServices.getMileStonesList;
const suggestMileStonesDates = mileStonesServices.suggestMileStonesDates;
const approveMileStonesDates = mileStonesServices.approveMileStonesDates;
const approveSuggestedDates = mileStonesServices.approveSuggestedDates;
const setSubprojectPredecessor = mileStonesServices.setSubprojectPredecessor;

const ListPhases = (props) => {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [loading,setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPM, setIsPM] = useState(false);
  const [isEM, setIsEM] = useState(false);
  const [popOverMessage, setPopOverMessage] = useState("");
  const [requestApproval, setRequestApproval] = useState(false);
  const [approve, setApprove] = useState(false);
  const [confilictPred, setConfilictPred] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [approveSuggestion, setApproveSuggestion] = useState(false);
  const [viewSuggestedDates, setViewSuggestedDates] = useState(false);
  const [Modify, setModify] = useState(false);
  const [isClickedYellow, setIsClickedYellow] = useState(false);
  const [isClickedBlue, setIsClickedBlue] = useState(false);
  const [isClickedDarkBlue, setIsClickedDarkBlue] = useState(false);
  const [isClickedGreen, setIsClickedGreen] = useState(false);
  const TenantId = localStorage.getItem("TenantId");
  const [predecessorId, setPredecessorId] = useState("");
  useEffect(() => {
    if (UserPermissions) {
      if (UserPermissions.find((x) => x.Name === "Admin")) {
        setIsAdmin(true);
      } else if (UserPermissions.find((x) => x.Name === "Project Manager")) {
        setIsPM(true);
      } else if (
        UserPermissions.find((x) => x.Name === "Engineering Manager")
      ) {
        setIsEM(true);
      }
    }
  }, [UserPermissions]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTypeOfRow(null);
    setAnchorEl(null);
    setChosenId(null);
    setApprove(false);
    setRequestApproval(false);
    setChosenId(null);
    setApproveSuggestion(false);
    setIsClickedGreen(false);
    setIsClickedBlue(false);
    setIsClickedDarkBlue(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [phases, setPhases] = useState([]);
  const [projectMileStones, setProjectMileStones] = useState({});
  const [chosenId, setChosenId] = useState(null);
  const [typeOfRow, setTypeOfRow] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openedCard, setopenedCard] = useState([]);
  //calendarPopover
  const [anchorElYellow, setAnchorElyellow] = useState(null);
  const handleClickYellow = (event) => {
    setAnchorElyellow(event.currentTarget);
  };

  const handleCloseYellow = () => {
    setTypeOfRow(null);
    setAnchorElyellow(null);
    setStartDate(null);
    setEndDate(null);
    setIsClickedYellow(false);
  };
  const [yesIsDisabled, setYesIsDisabled] = useState(true);
  const openCalendars = Boolean(anchorElYellow);
  const idYellow = openCalendars ? "calendars-popover" : undefined;
  useEffect(() => {
    if (props.ProjectId) {
      setLoading(true);
      getMileStonesList(props.ProjectId).then((x) => {
        setProjectMileStones(x);
        setPhases(x.Phases);
        props.setProjectName(x.Name);
        setLoading(false);
      });
    }
  }, [props.ProjectId, Modify]);
  const suggestDate = () => {
    suggestMileStonesDates(chosenId, typeOfRow, startDate, endDate).then(
      (x) => {
        setModify(!Modify);
        handleCloseYellow();
      }
    );
  };
  const requestApprovalFromHigher = () => {
    suggestMileStonesDates(chosenId, typeOfRow, null, null).then((x) => {
      setRequestApproval(false);
      setModify(!Modify);

      handleClose();
    });
  };
  const approveTheDates = () => {
    approveMileStonesDates(chosenId, typeOfRow).then((x) => {
      setModify(!Modify);
      handleClose();
    });
  };
  const approveSuggestionDates = () => {
    approveSuggestedDates(chosenId, typeOfRow).then((x) => {
      setModify(!Modify);
      handleClose();
    });
  };
  const saveSubprojectPredecessor = () => {
    setSaving(true);
    setSubprojectPredecessor(
      chosenId,
      predecessorId === 1 ? null : predecessorId
    ).then((x) => {
      if (x.status === 409) {
        setModalErrorMessage(x.error);
        setOpenErrorModal(true);
      } else {
        setTimeout(() => {
          setModify(!Modify);
        }, 1000);

        setChosenId(null);
        setPredecessorId(null);
      }
      setSaving(false);
    });
  };
  useEffect(() => {
    if (!startDate || !endDate) {
      setYesIsDisabled(true);
    } else {
      setYesIsDisabled(false);
    }
  }, [startDate, endDate]);
  //caution PopOver
  const [anchorElCaution, setAnchorElCaution] = useState(null);
  const handleClickCaution = (event) => {
    setAnchorElCaution(event.currentTarget);
  };

  const handleCloseCaution = () => {
    setAnchorElCaution(null);
    setChosenId(null);
  };
  const openCaution = Boolean(anchorElCaution);
  const idCaution = openCaution ? "simpleCaution-popover" : undefined;
  const renderSubProject = (subProject, phase, ParentSub) => {
    return subProject?.map((item) => (
      <Grid container key={item.Id}>
     
        <Grid item container xs={12} direction={"row"} padding={0.5}>
          <Grid
            item
            md={0.5}
            paddingTop={0.5}
            className={classes["plus"]}
            onMouseOver={(e) => {
              if (item.SubProjects?.length > 0 )  e.target.style.cursor = "pointer";
              else e.target.style.cursor = "unset";
            }}
            onClick={(e) => {
              if(item.SubProjects?.length > 0){
                if (openedCard.includes(item.Id)) {
                let arrayIDs = openedCard;
                arrayIDs = arrayIDs.filter(function (itemSub) {
                  return itemSub !== item.Id;
                });
                setopenedCard(arrayIDs);
              } else {
                let arrayIDs = openedCard;

                setopenedCard(arrayIDs.concat(item.Id));
              }
              }
            
            }}
          >
            {item.SubProjects?.length > 0 ? (
              openedCard?.includes(item.Id) ? (
                "-"
              ) : (
                "+"
              )
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            xs={1.8}
            className={
              openedCard?.includes(item.Id)
                ? classes["Sub-subproject-opened"]
                : classes["Subproject-Name"]
            }
          >
            {item.Name}
          </Grid>
          <Grid item xs={1.9} className={classes["Dates"]}>
            {item.StartDate?.slice(0, 10)}
          </Grid>
          <Grid
            item
            xs={1.9}
            container
            className={
              item.EndDate > phase.EndDate ||
              item.EndDate > ParentSub?.EndDate
                ? classes["Dates-surpasses"]
                : classes["Dates"]
            }
          >
            {item.EndDate?.slice(0, 10)}
            {item.EndDate > phase.EndDate ||
            item.EndDate > ParentSub?.EndDate ? (
              <Grid
                item
                paddingLeft={1}
                aria-describedby={idCaution}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (item.EndDate > phase.EndDate) {
                    setPopOverMessage(
                      item.Name +
                        " end date surpasses " +
                        phase.Name +
                        " end date."
                    );
                  } else
                    setPopOverMessage(
                      item.Name +
                        " end date surpasses " +
                        ParentSub.Name +
                        " end date."
                    );
                  handleClickCaution(e);
                }}
              >
                <img src={CautionIcon} alt={"caution"} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {item.IsManagable ? (
            <Grid
              item
              container
              direction={"row"}
              gap={1}
              xs={1.9}
              alignItems={"center"}
            >
              {phase.ResposibilityId === 1 ? (
                (item.Status === "No Status" || item.Status === "In Review") &&
                (isPM || isAdmin || isEM) &&
                (item.IsSuggestionAccepted === null ||
                  item.IsSuggestionAccepted === true) &&
                item.IsInvited === false ? (
                  <Grid
                    item
                    aria-describedby={id}
                    onClick={(e) => {
                      setPopOverMessage(
                        "You selected to Approve Dates for " +
                          item.Name +
                          ". Are you sure you want to send?"
                      );
                      handleClick(e);
                      setApprove(true);
                      setChosenId(item.Id);
                      setTypeOfRow(3);
                      setIsClickedGreen(true);
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                  >
                    <img
                      src={
                        isClickedGreen && chosenId === item.Id
                          ? HoveredGreenCircle
                          : GreenCircle
                      }
                      alt={"circle"}
                    />
                  </Grid>
                ) : (
                  <></>
                )
              ) : (item.Status === "No Status" ||
                  item.Status === "In Review") &&
                (isPM || isAdmin || isEM) &&
                (item.IsSuggestionAccepted === null ||
                  item.IsSuggestionAccepted === true) &&
                phase.OwnerTenantId === TenantId ? (
                <Grid
                  item
                  aria-describedby={id}
                  onClick={(e) => {
                    setPopOverMessage(
                      "You selected to Approve Dates for " +
                        item.Name +
                        ". Are you sure you want to send?"
                    );
                    handleClick(e);
                    setApprove(true);
                    setChosenId(item.Id);
                    setTypeOfRow(3);
                    setIsClickedGreen(true);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                >
                  <img
                    src={
                      isClickedGreen && chosenId === item.Id
                        ? HoveredGreenCircle
                        : GreenCircle
                    }
                    alt={"circle"}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {phase.ResposibilityId === 1 ? (
                item.Status === "No Status" &&
                (isEM || item.IsInvited === true) ? (
                  <Grid
                    item
                    aria-describedby={id}
                    onClick={(e) => {
                      setPopOverMessage(
                        "You selected to Request Dates Approval for " +
                          item.Name +
                          ". Are you sure you want to send?"
                      );
                      handleClick(e);
                      setRequestApproval(true);
                      setChosenId(item.Id);
                      setTypeOfRow(3);
                      setIsClickedBlue(true);
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                  >
                    <img
                      src={
                        isClickedBlue && chosenId === item.Id
                          ? HoveredBlueCircle
                          : BlueCircle
                      }
                      alt={"circle"}
                    />
                  </Grid>
                ) : item.Status === "In Review" &&
                  item.IsSuggestedByHigherLevel &&
                  !item.IsSuggestionAccepted &&
                  (isEM || item.IsInvited === true) ? (
                  <Grid
                    item
                    aria-describedby={id}
                    onClick={(e) => {
                      setPopOverMessage(
                        "You selected to Accept suggested Dates for " +
                          item.Name +
                          ". Are you sure you want to send?"
                      );
                      handleClick(e);
                      setApproveSuggestion(true);
                      setChosenId(item.Id);
                      setTypeOfRow(3);
                      setIsClickedDarkBlue(true);
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                  >
                    <img
                      src={
                        isClickedDarkBlue && chosenId === item.Id
                          ? HoveredDarkBlueCircle
                          : DarkBlueCircle
                      }
                      alt={"circle"}
                    />
                  </Grid>
                ) : (
                  <></>
                )
              ) : item.Status === "No Status" &&
                phase.OwnerTenantId !== TenantId ? (
                <Grid
                  item
                  aria-describedby={id}
                  onClick={(e) => {
                    setPopOverMessage(
                      "You selected to Request Dates Approval for " +
                        item.Name +
                        ". Are you sure you want to send?"
                    );
                    handleClick(e);
                    setRequestApproval(true);
                    setChosenId(item.Id);
                    setTypeOfRow(3);
                    setIsClickedBlue(true);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                >
                  <img
                    src={
                      isClickedBlue && chosenId === item.Id
                        ? HoveredBlueCircle
                        : BlueCircle
                    }
                    alt={"circle"}
                  />
                </Grid>
              ) : item.Status === "In Review" &&
                item.IsSuggestedByHigherLevel &&
                !item.IsSuggestionAccepted &&
                phase.OwnerTenantId !== TenantId ? (
                <Grid
                  item
                  aria-describedby={id}
                  onClick={(e) => {
                    setPopOverMessage(
                      "You selected to Accept suggested Dates for " +
                        item.Name +
                        ". Are you sure you want to send?"
                    );
                    handleClick(e);

                    setApproveSuggestion(true);
                    setChosenId(item.Id);
                    setTypeOfRow(3);

                    setIsClickedDarkBlue(true);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                >
                  <img
                    src={
                      isClickedDarkBlue && chosenId === item.Id
                        ? HoveredDarkBlueCircle
                        : DarkBlueCircle
                    }
                    alt={"circle"}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                item
                aria-describedby={idYellow}
                onClick={(e) => {
                  setViewSuggestedDates(false);
                  handleClickYellow(e);
                  setStartDate(item.StartDate);
                  setChosenId(item.Id);
                  setTypeOfRow(3);
                  setIsClickedYellow(true);
                }}
              >
                <img
                  src={
                    isClickedYellow && chosenId === item.Id
                      ? HoveredYellowCircle
                      : YellowCircle
                  }
                  alt={"circle"}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                />
              </Grid>
              {item.Status === "In Review" &&
              !item.IsSuggestionAccepted &&
              item.SuggestedStartDate ? (
                <Grid
                  item
                  aria-describedby={idYellow}
                  onClick={(e) => {
                    setViewSuggestedDates(true);
                    handleClickYellow(e);
                    setStartDate(item.SuggestedStartDate);
                    setEndDate(item.SuggestedEndDate);
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                >
                  <EventRepeatIcon htmlColor="#101650" />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <Grid
              item
              container
              direction={"row"}
              gap={1}
              xs={1.9}
              alignItems={"center"}
            ></Grid>
          )}

          <Grid item xs={1} className={classes["No-Status"]}>
            {item.Status}
          </Grid>
          <Grid item xs={1.8}>
            <CustomSelect
              defaultValue={item.PredecessorId}
              onChange={(option) => {
                setPredecessorId(option);
                setChosenId(item.Id);
              }}
            >
              <StyledOption value={0} disabled>
                Subproject Name
              </StyledOption>
              <StyledOption value={1}>No Predecessor</StyledOption>
              {ParentSub
                ? ParentSub?.SubProjects.map((s) => (
                    <StyledOption
                      key={s.Id}
                      value={s.Id}
                      disabled={s.Id === item.Id ? true : false}
                    >
                      {s.Name}
                    </StyledOption>
                  ))
                : phase?.SubProjects.map((s) => (
                    <StyledOption
                      key={s.Id}
                      value={s.Id}
                      disabled={s.Id === item.Id ? true : false}
                    >
                      {s.Name}
                    </StyledOption>
                  ))}
            </CustomSelect>
            {confilictPred && item.Id === chosenId ? (
              <Grid item fontFamily={"Muli"} fontSize={10} color={"red"}>
                One Sub project must be without predecessor
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          {predecessorId && chosenId === item.Id ? (
            <Grid
              item
              container
              xs={1}
              direction={"row"}
              gap={1}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={(e) => {
                saveSubprojectPredecessor();
              }}
            >
              <Grid item>
                <img src={YesGreenIcon} alt={"save"} />
              </Grid>
              <Grid item className={classes["Save"]} paddingTop={0.4}>
                {saving ? "Saving..." : "Save"}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            xs={11.8}
            className={classes["Line-529"]}
            marginTop={0.5}
          ></Grid>
        </Grid>
        {openedCard?.includes(item.Id) ? (
          renderSubProject(item.SubProjects, phase, item)
        ) : (
          <></>
        )}
      </Grid>
    ));
  };
  return (
    <Grid container>
      <Grid item container xs={12} direction={"row"} padding={0.5}>
      <Backdrop
              sx={{
                color: "#fff",
                backgroundColor: "transparent",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: "flex",
                flexDirection: "column",
              }}
              open={loading}
            >
              <CircularProgress />

              <Grid
                item
                fontFamily={"Muli"}
                fontSize={15}
                fontWeight={"bold"}
                color={"rgb(25, 118, 210)"}
              >
                Loading Data...
              </Grid>
            </Backdrop>
        <Grid item md={0.5}></Grid>
        <Grid item xs={1.8} className={classes["PhaseName"]}>
          {projectMileStones?.Name}
        </Grid>
        <Grid item xs={1.9} className={classes["Dates"]}>
          {projectMileStones?.StartDate?.slice(0, 10)}
        </Grid>
        <Grid item xs={1.9} className={classes["Dates"]}>
          {projectMileStones?.EndDate?.slice(0, 10)}
        </Grid>
        {projectMileStones?.IsManagable ? (
          <Grid
            item
            container
            direction={"row"}
            gap={1}
            xs={1.9}
            alignItems={"center"}
          >
            {(projectMileStones?.Status === "No Status" ||
              projectMileStones?.Status === "In Review") &&
            (isPM || isAdmin) &&
            (projectMileStones?.IsSuggestionAccepted === null ||
              projectMileStones?.IsSuggestionAccepted === true) ? (
              <Grid
                item
                aria-describedby={id}
                onClick={(e) => {
                  setPopOverMessage(
                    "You selected to Approve Dates for " +
                      projectMileStones?.Name +
                      ". Are you sure you want to send?"
                  );
                  handleClick(e);
                  setApprove(true);
                  setChosenId(projectMileStones?.Id);
                  setTypeOfRow(1);
                  setIsClickedGreen(true);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <img
                  src={
                    isClickedGreen && chosenId === projectMileStones?.Id
                      ? HoveredGreenCircle
                      : GreenCircle
                  }
                  alt={"circle"}
                />
              </Grid>
            ) : (
              <></>
            )}
            {projectMileStones?.Status === "No Status" && isEM ? (
              <Grid
                item
                aria-describedby={id}
                onClick={(e) => {
                  setPopOverMessage(
                    "You selected to Request Dates Approval for " +
                      projectMileStones?.Name +
                      ". Are you sure you want to send?"
                  );
                  handleClick(e);
                  setRequestApproval(true);
                  setChosenId(projectMileStones?.Id);
                  setTypeOfRow(1);
                  setIsClickedBlue(true);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <img
                  src={
                    isClickedBlue && chosenId === projectMileStones?.Id
                      ? HoveredBlueCircle
                      : BlueCircle
                  }
                  alt={"circle"}
                />
              </Grid>
            ) : projectMileStones?.Status === "In Review" &&
              projectMileStones?.IsSuggestedByHigherLevel &&
              !projectMileStones?.IsSuggestionAccepted &&
              isEM ? (
              <Grid
                item
                aria-describedby={id}
                onClick={(e) => {
                  setPopOverMessage(
                    "You selected to Accept suggested Dates for " +
                      projectMileStones?.Name +
                      ". Are you sure you want to send?"
                  );
                  handleClick(e);
                  setApproveSuggestion(true);
                  setChosenId(projectMileStones?.Id);
                  setTypeOfRow(1);
                  setIsClickedDarkBlue(true);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <img
                  src={
                    isClickedDarkBlue && chosenId === projectMileStones?.Id
                      ? HoveredDarkBlueCircle
                      : DarkBlueCircle
                  }
                  alt={"circle"}
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              item
              aria-describedby={idYellow}
              onClick={(e) => {
                setViewSuggestedDates(false);
                handleClickYellow(e);
                setChosenId(projectMileStones?.Id);
                setTypeOfRow(1);
                setIsClickedYellow(true);
                setStartDate(projectMileStones?.StartDate);
              }}
            >
              <img
                src={
                  isClickedYellow && chosenId === projectMileStones?.Id
                    ? HoveredYellowCircle
                    : YellowCircle
                }
                alt={"circle"}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              />
            </Grid>
            {projectMileStones?.Status === "In Review" &&
            !projectMileStones?.IsSuggestionAccepted &&
            projectMileStones?.SuggestedStartDate ? (
              <Grid
                item
                aria-describedby={idYellow}
                onClick={(e) => {
                  setViewSuggestedDates(true);
                  handleClickYellow(e);
                  setStartDate(projectMileStones?.SuggestedStartDate);
                  setEndDate(projectMileStones?.SuggestedEndDate);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <EventRepeatIcon htmlColor="#101650" />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <Grid
            item
            container
            direction={"row"}
            gap={1}
            xs={1.9}
            alignItems={"center"}
          ></Grid>
        )}

        <Grid item xs={2} className={classes["No-Status"]}>
          {projectMileStones?.Status}
        </Grid>
        <Grid
          item
          xs={11.8}
          className={classes["Line-529"]}
          marginTop={0.5}
        ></Grid>
      </Grid>
      <Grid container xs={12} item>
        {phases?.map((phase) => (
          <Grid container key={phase.Id}>
            <Grid item container xs={12} direction={"row"} padding={0.5}>
              <Grid
                item
                md={0.5}
                paddingTop={0.5}
                className={classes["plus"]}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (openedCard.includes(phase.Id)) {
                    let arrayIDs = openedCard;
                    arrayIDs = arrayIDs.filter(function (item) {
                      return item !== phase.Id;
                    });
                    setopenedCard(arrayIDs);
                  } else {
                    let arrayIDs = openedCard;

                    setopenedCard(arrayIDs.concat(phase.Id));
                  }
                }}
              >
                {openedCard?.includes(phase.Id) ? "-" : "+"}
              </Grid>
              <Grid
                item
                xs={1.8}
                className={
                  openedCard?.includes(phase.Id)
                    ? classes["openedPhases"]
                    : classes["PhaseName"]
                }
              >
                {phase.Name}
              </Grid>
              <Grid item xs={1.9} className={classes["Dates"]}>
                {phase.StartDate?.slice(0, 10)}
              </Grid>
              <Grid
                item
                xs={1.9}
                container
                className={
                  phase.EndDate > projectMileStones?.EndDate
                    ? classes["Dates-surpasses"]
                    : classes["Dates"]
                }
              >
                {phase.EndDate?.slice(0, 10)}
                {phase.EndDate > projectMileStones?.EndDate ? (
                  <Grid
                    item
                    paddingLeft={1}
                    aria-describedby={idCaution}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={(e) => {
                      setPopOverMessage(
                        phase.Name +
                          " end date surpasses " +
                          projectMileStones?.Name +
                          " end date."
                      );

                      handleClickCaution(e);
                    }}
                  >
                    <img src={CautionIcon} alt={"caution"} />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              {phase.IsManagable ? (
                <Grid
                  item
                  container
                  direction={"row"}
                  gap={1}
                  xs={1.9}
                  alignItems={"center"}
                >
                  {phase.ResposibilityId === 1 ? (
                    (phase.Status === "No Status" ||
                      phase.Status === "In Review") &&
                    (isPM || isAdmin || isEM) &&
                    (phase.IsSuggestionAccepted === null ||
                      phase.IsSuggestionAccepted === true) ? (
                      <Grid
                        item
                        aria-describedby={id}
                        onClick={(e) => {
                          setPopOverMessage(
                            "You selected to Approve Dates for " +
                              phase.Name +
                              ". Are you sure you want to send?"
                          );
                          handleClick(e);
                          setApprove(true);
                          setChosenId(phase.Id);
                          setTypeOfRow(2);
                          setIsClickedGreen(true);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                      >
                        <img
                          src={
                            isClickedGreen && chosenId === phase.Id
                              ? HoveredGreenCircle
                              : GreenCircle
                          }
                          alt={"circle"}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : (phase.Status === "No Status" ||
                      phase.Status === "In Review") &&
                    (isPM || isAdmin || isEM) &&
                    (phase.IsSuggestionAccepted === null ||
                      phase.IsSuggestionAccepted === true) &&
                    phase.OwnerTenantId === TenantId ? (
                    <Grid
                      item
                      aria-describedby={id}
                      onClick={(e) => {
                        setPopOverMessage(
                          "You selected to Approve Dates for " +
                            phase.Name +
                            ". Are you sure you want to send?"
                        );
                        handleClick(e);
                        setApprove(true);
                        setChosenId(phase.Id);
                        setTypeOfRow(2);
                        setIsClickedGreen(true);
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                    >
                      <img
                        src={
                          isClickedGreen && chosenId === phase.Id
                            ? HoveredGreenCircle
                            : GreenCircle
                        }
                        alt={"circle"}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {phase.ResposibilityId === 1 ? (
                    phase.Status === "No Status" && isEM ? (
                      <Grid
                        item
                        aria-describedby={id}
                        onClick={(e) => {
                          setPopOverMessage(
                            "You selected to Request Dates Approval for " +
                              phase.Name +
                              ". Are you sure you want to send?"
                          );
                          handleClick(e);
                          setRequestApproval(true);
                          setChosenId(phase.Id);
                          setTypeOfRow(2);
                          setIsClickedBlue(true);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                      >
                        <img
                          src={
                            isClickedBlue && chosenId === phase.Id
                              ? HoveredBlueCircle
                              : BlueCircle
                          }
                          alt={"circle"}
                        />
                      </Grid>
                    ) : phase.Status === "In Review" &&
                      phase.IsSuggestedByHigherLevel &&
                      !phase.IsSuggestionAccepted &&
                      isEM ? (
                      <Grid
                        item
                        aria-describedby={id}
                        onClick={(e) => {
                          setPopOverMessage(
                            "You selected to Accept suggested Dates for " +
                              phase.Name +
                              ". Are you sure you want to send?"
                          );
                          handleClick(e);
                          setApproveSuggestion(true);
                          setChosenId(phase.Id);
                          setTypeOfRow(2);
                          setIsClickedDarkBlue(true);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                      >
                        <img
                          src={
                            isClickedDarkBlue && chosenId === phase.Id
                              ? HoveredDarkBlueCircle
                              : DarkBlueCircle
                          }
                          alt={"circle"}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : phase.Status === "No Status" &&
                    phase.OwnerTenantId !== TenantId ? (
                    <Grid
                      item
                      aria-describedby={id}
                      onClick={(e) => {
                        setPopOverMessage(
                          "You selected to Request Dates Approval for " +
                            phase.Name +
                            ". Are you sure you want to send?"
                        );
                        handleClick(e);
                        setRequestApproval(true);
                        setChosenId(phase.Id);
                        setTypeOfRow(2);
                        setIsClickedBlue(true);
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                    >
                      <img
                        src={
                          isClickedBlue && chosenId === phase.Id
                            ? HoveredBlueCircle
                            : BlueCircle
                        }
                        alt={"circle"}
                      />
                    </Grid>
                  ) : phase.Status === "In Review" &&
                    phase.IsSuggestedByHigherLevel &&
                    !phase.IsSuggestionAccepted &&
                    phase.OwnerTenantId !== TenantId ? (
                    <Grid
                      item
                      aria-describedby={id}
                      onClick={(e) => {
                        setPopOverMessage(
                          "You selected to Accept suggested Dates for " +
                            phase.Name +
                            ". Are you sure you want to send?"
                        );
                        handleClick(e);
                        setApproveSuggestion(true);
                        setChosenId(phase.Id);
                        setTypeOfRow(2);
                        setIsClickedDarkBlue(true);
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                    >
                      <img
                        src={
                          isClickedDarkBlue && chosenId === phase.Id
                            ? HoveredDarkBlueCircle
                            : DarkBlueCircle
                        }
                        alt={"circle"}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    aria-describedby={idYellow}
                    onClick={(e) => {
                      setViewSuggestedDates(false);
                      handleClickYellow(e);
                      setChosenId(phase.Id);
                      setStartDate(phase.StartDate);
                      setTypeOfRow(2);
                      setIsClickedYellow(true);
                    }}
                  >
                    <img
                      src={
                        isClickedYellow && chosenId === phase.Id
                          ? HoveredYellowCircle
                          : YellowCircle
                      }
                      alt={"circle"}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                    />
                  </Grid>
                  {phase.Status === "In Review" &&
                  !phase.IsSuggestionAccepted &&
                  phase.SuggestedStartDate ? (
                    <Grid
                      item
                      aria-describedby={idYellow}
                      onClick={(e) => {
                        setViewSuggestedDates(true);
                        handleClickYellow(e);
                        setStartDate(phase.SuggestedStartDate);
                        setEndDate(phase.SuggestedEndDate);
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                    >
                      <EventRepeatIcon htmlColor="#101650" />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  direction={"row"}
                  gap={1}
                  xs={2.4}
                  alignItems={"center"}
                ></Grid>
              )}
              <Grid item xs={2} className={classes["No-Status"]}>
                {phase.Status}
              </Grid>
              <Grid
                item
                xs={11.8}
                className={classes["Line-529"]}
                marginTop={0.5}
              ></Grid>
            </Grid>
            {openedCard?.includes(phase.Id) ? (
              renderSubProject(phase.SubProjects, phase)
            ) : (
              <></>
            )}
          </Grid>
        ))}
      </Grid>
      <Popover
        id={id}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -150,
        }}
        className={classes["Rectangle-1449"]}
      >
        <Grid
          container
          item
          width={367}
          gap={1}
          paddingTop={1.5}
          boxShadow={"0 3px 6px 0 #5fba47"}
          style={{
            backgroundColor: "#ffffd1",
          }}
          paddingBottom={3}
        >
          <Grid item xs={12} className={classes["Milestone-Action"]}>
            Milestone Action
          </Grid>
          <Grid item xs={12} paddingLeft={2}>
            <img src={MilesStonesIcon} alt={"logo"} />
          </Grid>
          <Grid
            item
            xs={10.6}
            paddingLeft={4}
            className={classes["messageAction"]}
          >
            {popOverMessage}
          </Grid>
          <Grid item container direction={"row"} gap={7}>
            <Grid
              item
              container
              xs={5.3}
              direction={"row-reverse"}
              gap={1}
              onClick={(e) => {
                handleClose();
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <Grid item className={classes["Actions-Modal"]} paddingTop={0.2}>
                Cancel
              </Grid>
              <Grid item>
                <img src={CancelIcon} alt={"cancel"}></img>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={2}
              direction={"row"}
              gap={1}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={(e) => {
                if (requestApproval) {
                  requestApprovalFromHigher();
                } else if (approve) {
                  approveTheDates();
                } else if (approveSuggestion) {
                  approveSuggestionDates();
                }
              }}
            >
              <Grid item>
                <img src={YesIcon} alt={"Yes"}></img>
              </Grid>
              <Grid item className={classes["Actions-Modal"]} paddingTop={0.2}>
                Yes
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id={idCaution}
        open={openCaution}
        disableScrollLock
        anchorEl={anchorElCaution}
        onClose={handleCloseCaution}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -150,
        }}
        className={classes["Rectangle-1449"]}
      >
        <Grid
          container
          item
          width={367}
          gap={1}
          paddingTop={1.5}
          boxShadow={"0 3px 6px 0 #5fba47"}
          style={{
            backgroundColor: "#f0f6fb",
          }}
          paddingBottom={3}
        >
          <Grid item xs={12} container direction={"row"} paddingLeft={3}>
            <Grid item xs={6}>
              <img src={CautionIcon} alt={"caution"}></img>
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction={"row-reverse"}
              paddingRight={2}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleCloseCaution();
              }}
            >
              <CloseIcon fontSize="small" htmlColor="#101650" />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            paddingLeft={2}
            border={"solid 0.5px rgba(255, 0, 0, 0.48)"}
            height={0}
          ></Grid>
          <Grid
            item
            xs={10.6}
            paddingLeft={4}
            className={classes["messageAction"]}
          >
            {popOverMessage}
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id={idYellow}
        open={openCalendars}
        anchorEl={anchorElYellow}
        onClose={handleCloseYellow}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -150,
        }}
        disableScrollLock
        className={classes["Rectangle-1449"]}
      >
        <Grid
          container
          item
          width={367}
          gap={1}
          boxShadow={"0 3px 6px 0 #5fba47"}
          paddingTop={1.5}
          style={{
            backgroundColor: "#ffffd1",
          }}
          paddingBottom={3}
        >
          <Grid item xs={12} className={classes["Milestone-Action"]}>
            Milestone Action
          </Grid>
          <Grid item xs={12} paddingLeft={2}>
            <img src={MilesStonesIcon} alt={"logo"} />
          </Grid>
          <Grid
            item
            xs={10.6}
            paddingLeft={4}
            className={classes["messageAction"]}
          >
            {viewSuggestedDates
              ? "Suggested Dates:"
              : "Please Suggest a start Date and an End Date"}
          </Grid>
          <Grid item xs={5} container marginLeft={3}>
            <Grid item xs={12} sx={styles.label}>
              *Start Date
            </Grid>
            <Grid item xs={12} paddingTop="5%">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={viewSuggestedDates}
                  openTo="day"
                  
                  views={["year", "month", "day"]}
                  label="dd/mm/yyyy"
                  disableMaskedInput
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(newValue) => {
                    var date = new Date(newValue);
                    setStartDate(newValue);
                    date.setMonth(date.getMonth() + 1);
                    date.setDate(date.getDate() + 30);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        style: {
                          fontFamily: "Muli",
                          fontSize: "15px",
                        },
                      }}
                      error={false}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={5} container>
            <Grid item xs={12} sx={styles.label}>
              *End Date
            </Grid>
            <Grid item xs={12} paddingTop="5%">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={viewSuggestedDates}
                  openTo="day"
                  
                  minDate={new Date(startDate)}
                  views={["year", "month", "day"]}
                  label="dd/mm/yyyy"
                  disableMaskedInput
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(newValue) => {
                    var date = new Date(newValue);
                    setEndDate(newValue);
                    date.setMonth(date.getMonth() + 1);
                    date.setDate(date.getDate() + 30);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{
                        style: {
                          fontFamily: "Muli",
                          fontSize: "15px",
                        },
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item container direction={"row"} gap={7}>
            <Grid
              item
              container
              xs={viewSuggestedDates ? 12 : 5.3}
              direction={"row-reverse"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              onClick={(e) => {
                handleCloseYellow();
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <Grid item className={classes["Actions-Modal"]} paddingTop={0.2}>
                Cancel
              </Grid>
              <Grid item>
                <img src={CancelIcon} alt={"cancel"}></img>
              </Grid>
            </Grid>
            {viewSuggestedDates ? (
              <></>
            ) : (
              <Grid
                item
                container
                xs={2}
                direction={"row"}
                gap={1}
                onMouseOver={(e) => {
                  if (!yesIsDisabled) e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (!yesIsDisabled) {
                    suggestDate();
                  }
                }}
              >
                <Grid item>
                  <img
                    src={yesIsDisabled ? YesIcon : YesGreenIcon}
                    alt={"Yes"}
                  ></img>
                </Grid>
                <Grid
                  item
                  className={classes["Actions-Modal"]}
                  paddingTop={0.2}
                >
                  Yes
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Popover>
      <ErrorModal
        openModal={openErrorModal}
        setOpenModal={setOpenErrorModal}
        modalErrorMessage={modalErrorMessage}
        setChosenId={setChosenId}
        setopenedCard={setopenedCard}
      />
    </Grid>
  );
};
const styles = {
  label: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "left",
    color: "#101650",
  },
  error: {
    fontFamily: "Muli",
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "left",
    color: "#d32f2f",
    paddingTop: "1.5%",
  },
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 10px;
  box-sizing: border-box;
  min-height: 26px;
  width: 9.7rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  
  text-align: center;
 
  
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 12px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: #bcbcbc;
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  font-family:Muli;
  color:black;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default ListPhases;
