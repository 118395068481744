import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import { Grid } from "@mui/material";
import LabelControlSystem from "./LabelControlSystem";
import TagTemaplate from "./TagTemplate";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import * as ProductionLabelConsts from "../../../../productionLabelConst";
import * as productionLabelServices from "../../../Services/production-label-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import DeliveryNoteControlSystem from "./Delivery Note/DeliveryNoteControlSystem";
import DeliveryNoteTemplate from "./Delivery Note/DeliveryNoteTemplate";

const getProductionLabelInputData =
  productionLabelServices.getProductionLabelInputData;
const createProductionLabel = productionLabelServices.createProductionLabel;
const updateProductionLabel = productionLabelServices.updateProductionLabel;
const ProductionLabelCreations = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [loadingData, setLoadingData] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [labelRawsDictionary, setLabelRawsDictionary] = useState(1);
  const [modify, setModify] = useState(false);
  //#region
  const [labelTypeId, setLabelTypeId] = useState(query?.get("typeId"));
  const LABEL_ID = query?.get("id");
  const [asDefault, setAsDefault] = useState(false);
  const [labelSize, setLabelSize] = useState(null);
  const [labelSizeOptions, setLabelSizeOptions] = useState([]);
  const [labelName, setLabelName] = useState(null);
  const [labelNameError, setLabelNameError] = useState(null);
  const [labelRawsFields, setLabelRawsFields] = useState([]);
  const [selectedlabelRawsFields, setSelectedLabelRawsFields] = useState([]);
  const [savingLabel,setSavingLabel] = useState(false);

  //#endregion
  useEffect(() => {
    if (labelTypeId || LABEL_ID) {
      setLoadingData(true);

      getProductionLabelInputData(LABEL_ID ? LABEL_ID : null).then((x) => {
        if (LABEL_ID) {
          setLabelName(x.Label.Name);
          setLabelSize(x.Label.NumOfTags);
          if (x.Label.LabelSizeId === 2) {
            setLabelSize(null);
          }
          setSelectedLabelRawsFields(x.Label.LabelFields);
          setAsDefault(x.Label.IsDefault);
          setLabelRawsFields(
            x.LabelTypes?.find((lb) => lb.Id === x.Label.LabelTypeId)?.LabelRows
          );
          setLabelTypeId(JSON.stringify(x.Label.LabelTypeId));
          let temp = Object?.assign(
            {},
            ...x.LabelTypes?.find(
              (ol) => ol.Id === x.Label.LabelTypeId
            )?.LabelRows?.map((lt) => ({
              [lt.Id]: lt.Name,
            }))
          );
          setLabelRawsDictionary(temp);
        } else {
          setLabelRawsFields(
            x.LabelTypes?.find((x) => x.Id === +labelTypeId)?.LabelRows
          );
          let temp = Object?.assign(
            {},
            ...x.LabelTypes?.find((x) => x.Id === +labelTypeId)?.LabelRows?.map(
              (lt) => ({
                [lt.Id]: lt.Name,
              })
            )
          );
          setLabelRawsDictionary(temp);
        }

        setLabelSizeOptions(x.LabelSizes[0].Tags);
        setLoadingData(false);
      });
    }
  }, [labelTypeId, LABEL_ID, modify]);

  const ProductionLabelData = {
    Id: LABEL_ID ? LABEL_ID : null,
    Name: labelName,
    NumOfTags: labelSize,
    LabelSizeId: labelSize === null ? null : 1,
    LabelTypeId: +labelTypeId,
    IsDefault: asDefault,
    LabelFields: selectedlabelRawsFields,
  };
  useEffect(() => {
    if (
      !labelName ||
      labelSize === 0 ||
      labelNameError ||
      selectedlabelRawsFields.length === 0
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  const save = () => {
    if (LABEL_ID) {
      setSavingLabel(true)
      updateProductionLabel(ProductionLabelData).then((x) => {
        setSavingLabel(false);
        navigate(`/saas/admin/productionLabel/table?typeId=${+labelTypeId}`);

      });
    } else {
      setSavingLabel(true);
      createProductionLabel(ProductionLabelData).then((x) => {
        setSavingLabel(false);
        navigate(`/saas/admin/productionLabel/table?typeId=${+labelTypeId}`);
      });
    }
  };
  useEffect(() => {
    if (showMessage) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [showMessage]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {showMessage ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          md={12}
          paddingBottom={1}
          paddingTop={1}
        >
          <Grid item md={3}></Grid>
          <Grid md={5} className={classes["Upgrade-subscription-required"]}>
            Upgrade your plan to add this Field
          </Grid>
          <Grid md={2} container>
            <Grid
              item
              onClick={() => {
                navigate("/saas/profileSettings");
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <button className={classes["Rectangle-1231"]}>Subscribe</button>
            </Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setShowMessage(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid container paddingTop={4} paddingBottom={5}>
        <Grid container item xs={12} alignItems={"center"} paddingLeft={3}>
          <Grid item xs={6} className={classes["Production-Label"]}>
            {props.labelTypeId === ProductionLabelConsts.DELIVERY_NOTE_ID
              ? "Delivery Note Form"
              : "Production Label"}
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction={"row-reverse"}
            gap={2}
            paddingRight={5}
          >
            <button
              className={classes["Go-Back"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid container xs={12} item paddingTop={3}>
          {labelTypeId === ProductionLabelConsts.DELIVERY_NOTE_ID ? (
            <Grid item>
              <DeliveryNoteControlSystem
                asDefault={asDefault}
                setAsDefault={setAsDefault}
                labelTypeId={labelTypeId}
                labelSize={labelSize}
                setLabelSize={setLabelSize}
                labelSizeOptions={labelSizeOptions}
                labelName={labelName}
                setLabelName={setLabelName}
                labelNameError={labelNameError}
                setLabelNameError={setLabelNameError}
                labelRawsFields={labelRawsFields}
                setSelectedLabelRawsFields={setSelectedLabelRawsFields}
                selectedlabelRawsFields={selectedlabelRawsFields}
                setShowMessage={setShowMessage}
                isDisabled={isDisabled}
                save={save}
                labelRawsDictionary={labelRawsDictionary}
                savingLabel={savingLabel}
              />
            </Grid>
          ) : (
            <Grid item>
              <LabelControlSystem
                asDefault={asDefault}
                setAsDefault={setAsDefault}
                labelTypeId={labelTypeId}
                labelSize={labelSize}
                setLabelSize={setLabelSize}
                labelSizeOptions={labelSizeOptions}
                labelName={labelName}
                setLabelName={setLabelName}
                labelNameError={labelNameError}
                setLabelNameError={setLabelNameError}
                labelRawsFields={labelRawsFields}
                setSelectedLabelRawsFields={setSelectedLabelRawsFields}
                selectedlabelRawsFields={selectedlabelRawsFields}
                setShowMessage={setShowMessage}
                isDisabled={isDisabled}
                save={save}
                savingLabel={savingLabel}
                labelRawsDictionary={labelRawsDictionary}
              />
            </Grid>
          )}

          <Grid item paddingLeft={15}>
            {labelRawsDictionary ? (
              labelTypeId === ProductionLabelConsts.DELIVERY_NOTE_ID ? (
                <DeliveryNoteTemplate
                  setSelectedLabelRawsFields={setSelectedLabelRawsFields}
                  selectedlabelRawsFields={selectedlabelRawsFields}
                  labelSize={labelSize}
                  labelRawsFields={labelRawsFields}
                  labelRawsDictionary={labelRawsDictionary}
                />
              ) : (
                <TagTemaplate
                  setSelectedLabelRawsFields={setSelectedLabelRawsFields}
                  selectedlabelRawsFields={selectedlabelRawsFields}
                  labelSize={labelSize}
                  labelRawsFields={labelRawsFields}
                  labelRawsDictionary={labelRawsDictionary}
                />
              )
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductionLabelCreations;
