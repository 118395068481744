import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import logo2 from "../Assets/Images/Navbar/logo.png";
import loginIcon from "../Assets/Images/Navbar/firstIcon.png";
import hoveredLoginIcon from "../Assets/Images/Navbar/hoveredIcon.png";
import "../SAAS/Assets/Styles/SaasNav.css";
import Grid from "@mui/material/Grid";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import useWindowDimensions from "../Components/Navbar/WindowDimension";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Badge from "@mui/material/Badge";
import NavDrawerSaas from "../Components/Navbar/NavDrawerSaas";
import { IconButton } from "@mui/material";
import InvitesIcons from "../SAAS/Assets/Images/Notifications/invites.png";
import pileYellow from "../SAAS/Assets/Images/Notifications/pileYellow.png";
import pileBlue from "../SAAS/Assets/Images/Notifications/pileBlue.png";
import pileGreen from "../SAAS/Assets/Images/Notifications/pileGreen.png";
import contyellow from "../SAAS/Assets/Images/Notifications/contyellow.png";
import contblue from "../SAAS/Assets/Images/Notifications/contblue.png";
import contgreen from "../SAAS/Assets/Images/Notifications/contgreen.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as notificationsServices from "../Services/notification-service.proxy";
import * as userServices from "../Services/user-data-service.proxy";
import { userDataActions } from "../store/index";
import factoryIcon from "../SAAS/Assets/Images/Factory/factoryIcon.png";
import factoryIconActive from "../SAAS/Assets/Images/Factory/factoryIconActive.png";
import AssistanceModal from "../SAAS/Components/Assitance/AssistanceModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import * as permissionFunctions from "../ReusableFunctions";
import LocationInfo from "../SAAS/SharedComponents/LocationInfo";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const getNotifications = notificationsServices.getNotifications;
const getUserInvitationList = userServices.getUserInvitationList;
const viewNotifications = notificationsServices.viewNotifications;


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    color: "#0674b9",
    fontSize: "Muli",
    boxShadow: "0 3px 6px 0 #908C8C",
    position: "relative",
    bottom: 10
  },
}));

export default function SaasNavbar({
  setIsSubscribed,
  setIsVerified,
  naved,
  setNaved,
  isLoggedIn,
  setIsLoggedIn,
  accountName,
  setAccount,
  setToken,
  setAccountName,
  setProfileSrc,
  profileSrc,
  setupProfile,
  setSetupProfile,
  setSetupMenu,
  active5,
  setActive5,
  setActive,
}) {
  const { width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const IsTeamMember = useSelector((state) => state.userData.IsTeamMember);

  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [activeF, setActiveF] = useState(false);
  const [activeP, setActiveP] = useState(false);
  const [activeIM, setActiveIM] = useState(false);
  const [activeL, setActiveL] = useState(false);
  const [activeD, setActiveD] = useState(false);
  const [showNotifiacation, setShowNotifiacation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [disableTH, setDisableTH] = useState(false);

  const numOfNotification = useSelector(
    (state) => state.userData.notficationDisplayCounter
  );
  const nameOfNotifying = useSelector((state) => state.userData.userData);

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const CompanyName = useSelector((state) => state.userData.CompanyName);

  const [notificationTitle, setNotificationTitle] = useState("");

  useEffect(() => {
    if (nameOfNotifying) setNotificationTitle(nameOfNotifying.Name);
  }, [nameOfNotifying]);

  const [notifications, setNotifications] = useState([]);
  const notificationList = useSelector((state) => state.userData.userData);

  useEffect(() => {
    if (notificationList) setNotifications(notificationList);
  }, [notificationList]);

  const lisData = {
    Pagination: {
      PageSize: 5,
      PageNumber: 1
    },
    Search: "",
    SortList: [],
    IsViewed: null
  }

  const getTheNotifications = () => {
    getNotifications(lisData).then((x) => {
      setNotifications(x.Notifications);
    });
  };
  const [item, setItem] = useState(false);
  const [item1, setItem1] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Pilecubes Request Team Help",
        "View",
        setDisableTH
      );
    }
  }, [UserPermissions]);

  useEffect(() => {
    getUserInvitationList().then((x) => {
      let countInvites = 0;
      x?.map((invite) => {
        if (invite.StatusId === 1) ++countInvites;
      });
      dispatch(userDataActions.setNumOfInvitations(countInvites));
    });
  }, []);

  const numOfInvitations = useSelector(
    (state) => state.userData.numOfInvitations
  );

  const [modify, setModify] = useState(false);

  useEffect(() => {
    getNotifications(lisData).then((x) => {
      setNotifications(x.Notifications);
    });
  }, [modify]);
  const viewingNotification = (Id, IsViewed) => {
    viewNotifications(Id).then((x) => {
      setModify(!modify);
      if (!IsViewed) dispatch(userDataActions.DecrementNotificationCounter());
    });
  };

  useEffect(() => {
    if (!active5) {
      setSetupProfile(false);
      setSetupMenu(false);
    } else setSetupProfile(true);
  }, [active5, setSetupProfile, setSetupMenu]);

  useEffect(() => {
    if (window.location.pathname.toString() === "/saas/projects") {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/search"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/created"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/assigned"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/creating"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/teamMember"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/subprojects"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/dashboard"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/teammember"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/phases"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() ===
      "/saas/projects/subprojects/created"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/projects/milesstones"
    ) {
      setActive2(true);
      setActive1(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (window.location.pathname.toString() === "/saas/execution") {
      setActive3(true);
      setActive1(false);
      setActive2(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/execution/create" ||
      window.location.pathname.toString() === "/saas/execution/activitiesTable" ||
      window.location.pathname.toString() === "/saas/execution/activitiesDashboard" ||
      window.location.pathname.toString() === "/saas/execution/activitiesTimeline"

    ) {
      setActive3(true);
      setActive1(false);
      setActive2(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/sales" ||
      window.location.pathname.toString() === "/saas/salesOrderDashboard" ||
      window.location.pathname.toString() === "/saas/salesOrderList" ||
      window.location.pathname.toString() === "/saas/salesOrderListCard" ||
      window.location.pathname.toString() === "/saas/salesProjectListCard" ||
      window.location.pathname.toString() === "/saas/salesProjectList" ||
      window.location.pathname.toString() === "/saas/salesOrderForm" ||
      window.location.pathname.toString() === "/saas/salesOrdersCalender"
    ) {
      setActive4(true);
      setActive1(false);
      setActive3(false);
      setActive2(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (window.location.pathname.toString() === "/saas/notifications") {
      setActive4(false);
      setActive1(false);
      setActive3(false);
      setActive2(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (window.location.pathname.toString() === "/saas/invitations") {
      setActive4(false);
      setActive1(false);
      setActive3(false);
      setActive2(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (window.location.pathname.toString() === "/saas/profileDetails") {
      setActive5(true);
      setActive1(false);
      setActive3(false);
      setActive2(false);
      setActive4(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/profileSettings"
    ) {
      setActive5(true);
      setActive1(false);
      setActive3(false);
      setActive2(false);
      setActive4(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    }
    else if (
      window.location.pathname.toString() === "/saas/factory" ||
      window.location.pathname.toString() === "/saas/factory/create" ||
      window.location.pathname.toString() === "/saas/factory/route/create" ||
      window.location.pathname.toString() === "/saas/factory/routes" ||
      window.location.pathname.toString() === "/saas/factoriesList" ||
      window.location.pathname.toString() === "/saas/baysList" ||
      window.location.pathname.toString() === "/saas/blocksList" ||
      window.location.pathname.toString() === "/saas/factory/bay/storageBlock" ||
      window.location.pathname.toString() === "/saas/factory/bay/machineBlock" ||
      window.location.pathname.toString() === "/saas/factory/bay/storageBlock" ||
      window.location.pathname.toString() === "/saas/factory/bay/machineBlock/holdedOrders" ||
      window.location.pathname.toString() === "/saas/factory/bay/handlingBlock" ||
      window.location.pathname.toString() === "/saas/factory/manageProduct" ||
      window.location.pathname.toString() === "/saas/factory/manageRM" ||
      window.location.pathname.toString() === "/saas/factory/manageRemnant" ||
      window.location.pathname.toString() === "/saas/masterParameters"
    ) {
      setActive(false);
      setActive1(false);
      setActive2(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(true);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    }
    else if (
      window.location.pathname.toString() ===
      "/saas/productionPlanning/Assigning" ||
      window.location.pathname.toString() ===
      "/saas/productionPlanning/Calendar"
    ) {
      setActive(false);
      setActive1(false);
      setActive2(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(true);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() ===
      "/saas/admin/inventoryManagement" ||
      window.location.pathname.toString() ===
      "/saas/admin/inventoryManagement/materialReceiptTableList" ||
      window.location.pathname.toString() ===
      "/saas/admin/inventoryManagement/materialReceiptCreationForm"
    ) {
      setActive(false);
      setActive1(false);
      setActive2(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(true);
      setActiveD(false);
      setActiveL(false);
    } else if (
      window.location.pathname.toString() === "/saas/loading/ViewResult" ||
      window.location.pathname.toString() === "/saas/Loading/Calendar"
    ) {
      setActive(false);
      setActive1(false);
      setActive2(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(true);
    } else if (
      window.location.pathname.toString() === "/saas/Delivery/Calendar" ||
      window.location.pathname.toString() === "/saas/Delivery/DeliveryNote" ||
      window.location.pathname.toString() === "/saas/Delivery/SaleOrderNote"
    ) {
      setActive(false);
      setActive1(false);
      setActive2(false);
      setActive3(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveL(false);
      if (localStorage.getItem("planIdManu")?.includes("Pro")) {
        setActiveD(true);
        setActive4(false);
      } else {
        setActiveD(false);
        setActive4(true);
      }
    } else {
      setActive1(true);
      setActive(false);
      setActive2(false);
      setActive3(false);
      setActive4(false);
      setActive5(false);
      setActiveF(false);
      setActiveP(false);
      setActiveIM(false);
      setActiveD(false);
      setActiveL(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      setProfileSrc(loginIcon);
    }
  }, [setProfileSrc, isLoggedIn]);

  const [anchorElNoti, setAnchorElNoti] = React.useState(null);
  const openNoti = Boolean(anchorElNoti);
  const handleClickNoti = (event) => {
    getTheNotifications();
    setAnchorElNoti(event.currentTarget);
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };

  const result = Object.values(UserPermissions?.flatMap(up => up.Permissions).reduce((acc, curr) => {
    if (acc[curr.Name]) {
      acc[curr.Name].Actions.push(...curr.Actions);
    } else {
      acc[curr.Name] = { Name: curr.Name, Actions: [...curr.Actions] };
    }
    return acc;
  }, {}));

  if (width > 1095)
    return (
      <>
        <Grid item>
          <LocationInfo />
        </Grid>
        <AppBar
          position="sticky"
          style={{
            backgroundColor: "#fff",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          }}
        >
          <Grid container direction="column">
            <Grid
              item
              md={12}
              container
              marginTop="1%"
              marginLeft="6%"
              direction="row"
              justifyContent="space-between"
            >
              <Grid item>
                <img
                  src={logo2}
                  alt="Logo"
                  onClick={() => {
                    setNaved(true);
                    setActive3(false);
                    setActive1(true);
                    setActive2(false);
                    setActive(false);
                    setActive4(false);
                    setActive5(false);
                    setActiveF(false);
                    setActiveP(false);
                    setActiveIM(false);
                    setActiveD(false);
                    setActiveL(false);

                    navigate("/");
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={5}
                marginRight="10%"
                container
                gap={2}
                alignItems="center"
                direction="row-reverse"
              >
                <Grid item>
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <img
                      onClick={() => {
                        if (!isLoggedIn) navigate("/login");
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        if (!isLoggedIn) {
                          setProfileSrc(hoveredLoginIcon);
                        }
                      }}
                      onMouseOut={(e) => {
                        if (!isLoggedIn) {
                          setProfileSrc(loginIcon);
                        }
                      }}
                      src={profileSrc}
                      alt={"login"}
                      style={{
                        marginTop: "1%",
                        marginLeft: "3%",
                        borderRadius: "50px",
                        border: 0,
                        width: 48,
                        height: 48,
                      }}
                    ></img>
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    disableScrollLock
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: -140,
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setItem(true);
                        setItem1(false);
                        setSetupProfile(true);
                        setSetupMenu(false);
                        setActive5(true);
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActiveF(false);
                        setActiveP(false);
                        setActiveIM(false);
                        setActiveD(false);
                        setActiveL(false);
                        navigate("/saas/profileDetails");
                      }}
                      style={{
                        fontFamily: "Muli",
                        fontSize: "12px",
                        color: "#0674b9",
                        paddingRight: 140,
                        fontWeight: item ? "bold" : "normal",
                      }}
                      onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
                      onMouseLeave={(e) =>
                        (e.target.style.fontWeight = "normal")
                      }
                    >
                      Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setItem1(true);
                        setItem(false);
                        handleClose();
                        setSetupProfile(true);
                        setSetupMenu(true);
                        setActive5(true);
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActiveF(false);
                        setActiveP(false);
                        setActiveIM(false);
                        setActiveD(false);
                        setActiveL(false);
                        navigate("/saas/profileSettings");
                      }}
                      style={{
                        fontFamily: "Muli",
                        fontSize: "12px",
                        color: "#0674b9",
                        fontWeight: item1 ? "bold" : "normal",
                      }}
                      onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
                      onMouseLeave={(e) =>
                        (e.target.style.fontWeight = "normal")
                      }
                    >
                      Settings
                    </MenuItem>
                    <Divider />

                    <MenuItem
                      disabled={disableTH}
                      onClick={() => {
                        setItem1(false);
                        setItem(false);
                        handleClose();
                        setSetupProfile(false);
                        setSetupMenu(false);
                        setActive5(false);
                        setActive(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive4(false);
                        setActiveF(false);
                        setActiveP(false);
                        setActiveIM(false);
                        setActiveD(false);

                        setOpenModal(true);
                      }}
                      style={{
                        fontFamily: "Muli",
                        fontSize: "12px",
                        color: "#0674b9",
                        fontWeight: item1 ? "bold" : "normal",
                      }}
                      onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
                      onMouseLeave={(e) =>
                        (e.target.style.fontWeight = "normal")
                      }
                    >
                      Implementation Support
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        localStorage.clear();
                        setAccountName("");
                        setIsVerified("");
                        setIsSubscribed("");
                        setToken("");
                        setAccount();
                        navigate("/");
                        setIsLoggedIn(false);
                        //window.removeEventListener();
                        handleClose();
                      }}
                      style={{
                        fontFamily: "Muli",
                        fontSize: "12px",
                        color: "#0674b9",
                      }}
                      onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
                      onMouseLeave={(e) =>
                        (e.target.style.fontWeight = "normal")
                      }
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item>
                  <Link
                    to={isLoggedIn ? "" : "/login"}
                    className={isLoggedIn ? "accountName" : "login"}
                    onMouseOver={(e) => {
                      if (!isLoggedIn) {
                        e.target.style.fontWeight = "bolder";
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (!isLoggedIn) {
                        e.target.style.fontWeight = "normal";
                      }
                    }}
                  >
                    {isLoggedIn
                      ? IsTeamMember === true
                        ? `${CompanyName} - ${accountName}`
                        : accountName
                      : "login"}
                  </Link>
                </Grid>
                <Grid
                  item
                  lg={2.5}
                  md={3}
                  container
                  alignItems="center"
                  direction={"row-reverse"}
                  gap={1}
                >
                  <Grid item>
                    <IconButton
                      id="Notifi-button"
                      aria-controls={openNoti ? "noti-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openNoti ? "true" : undefined}
                      onClick={handleClickNoti}
                    >
                      {numOfNotification === 0 ? (
                        <NotificationsIcon
                          htmlColor="#e1e319"
                          sx={{ width: 22, height: 22 }}
                        />
                      ) : (
                        <Badge
                          color="error"
                          invisible={showNotifiacation}
                          badgeContent={numOfNotification}
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: 9,
                              height: 15,
                              minWidth: 15,
                              fontFamily: "Muli",
                              backgroundColor:
                                notificationTitle === "Pilecubes Team"
                                  ? "green"
                                  : "#d32f2f",
                            },
                          }}
                        >
                          <NotificationsIcon
                            htmlColor="#e1e319"
                            sx={{ width: 22, height: 22 }}
                          />
                        </Badge>
                      )}
                    </IconButton>
                    <Menu
                      disableScrollLock
                      id="noti-menu"
                      aria-labelledby="Notifi-button"
                      anchorEl={anchorElNoti}
                      open={openNoti}
                      sx={{
                        "& .MuiPaper-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                      onClose={handleCloseNoti}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: -140,
                      }}
                    >
                      {notificationList.length === 0 ? (
                        <MenuItem
                          onClick={() => {
                            navigate("/saas/notifications");
                            setActive3(false);
                            setActive1(false);
                            setActive2(false);
                            setActive(false);
                            setActive4(false);
                            setActive5(false);
                            setActiveF(false);
                            setActiveP(false);
                            setActiveIM(false);
                            setActiveD(false);
                            setActiveL(false);

                            handleCloseNoti();
                          }}
                          style={{
                            backgroundColor: "#fff",
                            fontFamily: "Muli",
                            fontSize: 13,
                            justifyContent: "center",
                            color: "#0674b9",
                            fontWeight: "bold",
                            width: 400,
                          }}
                        >
                          No Notifications.
                        </MenuItem>
                      ) : (
                        ""
                      )}
                      {notifications.map((n, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            if (n.Link) {
                              window.location.replace(n.Link);
                              viewingNotification(n.Id, n.IsViewed);
                            } else {
                              navigate("/saas/notifications");
                            }
                            handleCloseNoti();
                          }}
                          style={{
                            width: 400,
                            borderRadius: 5,
                            backgroundColor: n.IsViewed?"#e9e7e7":"#fff",
                            height: "fit-content",
                          }}
                        >
                          <img
                            src={
                              n.NotificationTypeId === 1
                                ? index % 3 === 0
                                  ? pileYellow
                                  : (index + 2) % 3 === 0
                                    ? pileBlue
                                    : pileGreen
                                : index % 3 === 0
                                  ? contyellow
                                  : (index + 2) % 3 === 0
                                    ? contblue
                                    : contgreen
                            }
                            alt={"Noti"}
                          ></img>
                          <span
                            style={{
                              whiteSpace: "initial",
                              wordWrap: "break-word",
                              fontWeight: "bold",
                              fontFamily: "Muli",
                              fontSize: 10,
                              color: "#70707",
                              display: "grid",
                              paddingLeft: 10,
                            }}
                          >
                            <span>{n.Name}</span>
                            <span
                              style={{
                                fontWeight: "normal",

                                fontFamily: "Muli",
                                fontSize: 11,
                                color: "#707070",
                              }}
                            >
                              {n.Description}
                            </span>

                            <span
                              style={{
                                fontStyle: "italic",
                                fontWeight: "normal",
                                fontFamily: "Muli",
                                fontSize: 11,
                                color: "#0674b9",
                              }}
                            >
                              {new Date(n.CreationDate).toString().slice(0, 21)}
                            </span>
                          </span>
                        </MenuItem>
                      ))}
                      <MenuItem
                        onClick={() => {
                          navigate("/saas/notifications");
                          handleCloseNoti();
                        }}
                        style={{
                          backgroundColor: "#fff",
                          fontFamily: "Muli",
                          fontSize: 13,
                          justifyContent: "center",
                          color: "#0674b9",
                          fontWeight: "bold",
                        }}
                      >
                        View All
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid
                    item
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      navigate("/saas/invitations");
                      setActive3(false);
                      setActive1(false);
                      setActive2(false);
                      setActive(false);
                      setActive4(false);
                      setActive5(false);
                      setActiveF(false);
                      setActiveP(false);
                      setActiveIM(false);
                      setActiveD(false);
                      setActiveL(false);
                    }}
                  >
                    {numOfInvitations === 0 ? (
                      <img
                        src={InvitesIcons}
                        alt={"Invites"}
                        width="19px"
                        height="19px"
                      />
                    ) : (
                      <Badge
                        color="error"
                        badgeContent={numOfInvitations}
                        sx={{
                          "& .MuiBadge-badge": {
                            fontSize: 9,
                            height: 15,
                            minWidth: 15,
                            fontFamily: "Muli",
                            backgroundColor: "#d32f2f",
                          },
                        }}
                      >
                        <img
                          src={InvitesIcons}
                          alt={"Invites"}
                          width="19px"
                          height="19px"
                          style={{ marginBottom: "5px" }}
                        />
                      </Badge>
                    )}
                  </Grid>
                  {localStorage.getItem("AccountType") === "1" ? (
                    <></>
                  ) : (
                    <Grid item padding="0.5% 1.5% 0 0">
                      <Link
                        onClick={() => {
                          setActive(false);
                          setActive1(false);
                          setActive2(false);
                          setActive3(false);
                          setActive4(false);
                          setActive5(false);
                          setActiveF(true);
                          setActiveP(false);
                          setActiveIM(false);
                          setActiveL(false);
                          setActiveD(false);
                        }}
                        to="/saas/factory"
                      >
                        <img
                          src={activeF ? factoryIconActive : factoryIcon}
                          alt="facticons"
                          width="35px"
                          height="36px"
                        />
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item md={12} container direction={"row"} height={50}> */}
            <Grid
              item
              xl={8.5}
              lg={9}
              md={12}
              container
              marginLeft={{ md: 0, lg: "1%", xl: "2%" }}
              height={56}
              alignItems={"center"}
              paddingTop={2.2}
            >
              <Grid
                // id="fade-button"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setActive1(true);
                  setActive(false);
                  setActive2(false);
                  setActive3(false);
                  setActive4(false);
                  setActive5(false);
                  setActiveF(false);
                  setActiveP(false);
                  setActiveIM(false);
                  setActiveD(false);
                  setActiveL(false);
                  //navigate("/saas/admin");
                }}
                className={active1 ? "activeItems" : "items"}
              >
                <Grid container
                // className="dropdown"
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Grid item container direction="column" backgroundColor="white" rowGap={2} padding="10px 15px 10px 15px"
                          className="dropDownContent"

                        >
                          {!UserPermissions?.find((x) => x.Name === "Admin") &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Project Manager"
                            ) ? (
                            UserPermissions?.map((up) =>
                              up.Permissions.map((p) => {
                                if (p.Name === "Client") {
                                  return (
                                    <Grid item>
                                      <NavLink key={p.Name} to="/saas/admin/client">
                                        Clients
                                      </NavLink>
                                    </Grid>
                                  );
                                } else return "";
                              })
                            )
                          ) : (
                            <NavLink to="/saas/admin/client">Clients</NavLink>
                          )}
                          {!UserPermissions?.find((x) => x.Name === "Admin") &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Project Manager"
                            ) ? (
                           <></>
                          ) : (
                            <NavLink to="/saas/admin/supplier">Suppliers</NavLink>
                          )}
                          {/* {(localStorage.getItem("planIdManu") ||  localStorage.getItem("IsFreePlanActivated") === "true") ?
                            <NavLink to="/saas/admin/supplier">Suppliers</NavLink>
                            :
                            <></>
                          } */}

                          {UserPermissions?.map((up) =>
                            up.Permissions.map((p) => {
                              if (p.Name === "Document") {
                                return (
                                  <NavLink key={p.Name} to="/saas/admin/document">
                                    Documents
                                  </NavLink>
                                );
                              } else return "";
                            })
                          )}

                          {IsTeamMember === true &&
                            !UserPermissions?.find((x) => x.Name === "Admin") &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Project Manager"
                            ) &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Engineering Manager"
                            ) ? (
                            UserPermissions?.map((up) =>
                              up.Permissions.map((p) => {
                                if (p.Name === "Sub Contractor") {
                                  return (
                                    <NavLink key={p.Name} to="/saas/admin/Sc">
                                      Sub Contractors
                                    </NavLink>
                                  );
                                } else return "";
                              })
                            )
                          ) : (
                            <>
                              <NavLink to="/saas/admin/Sc">Sub Contractors</NavLink>
                            </>
                          )}
                          {IsTeamMember === true &&
                            !UserPermissions?.find((x) => x.Name === "Admin") &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Project Manager"
                            ) &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Engineering Manager"
                            ) ? (
                            UserPermissions?.map((up) =>
                              up.Permissions.map((p) => {
                                if (p.Name === "Main Contractor") {
                                  return localStorage.getItem("AccountType") ===
                                    "2" ? (
                                    <NavLink key={p.Name} to="/saas/admin/Mc">
                                      Main Contractors
                                    </NavLink>
                                  ) : (
                                    <></>
                                  );
                                } else return "";
                              })
                            )
                          ) : (
                            <>
                              {localStorage.getItem("AccountType") === "2" ? (
                                <NavLink to="/saas/admin/Mc">
                                  Main Contractors
                                </NavLink>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          {(IsTeamMember === true &&
                            !UserPermissions?.find((x) => x.Name === "Admin") &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Project Manager"
                            ) &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Engineering Manager"
                            ) &&
                            !UserPermissions?.find(
                              (x) => x.Name === "Team Leader"
                            ))
                            /* ||
                            (!localStorage.getItem("planId") &&
                              !localStorage.getItem("planIdManu")
                            ) */
                            ?
                            (
                              /* localStorage.getItem("IsFreePlanActivated") === "true" && !localStorage.getItem("planId") ? (
                                ""
                              ) : */
                              (
                                UserPermissions?.map((up) =>
                                  up.Permissions.map((p) => {
                                    if (p.Name === "Team Member") {
                                      return (
                                        <NavLink
                                          key={p.Name}
                                          to="/saas/admin/TeamMember"
                                        >
                                          Team Members
                                        </NavLink>
                                      );
                                    } else return "";
                                  })
                                )
                              )
                            ) : (
                              <NavLink to="/saas/admin/TeamMember">
                                Team Members
                              </NavLink>
                            )}

                          {(IsTeamMember === true &&
                            !UserPermissions?.find((x) => x.Name === "Admin")) ||
                            (!localStorage.getItem("planId") &&
                              !localStorage.getItem("planIdManu")) ? (
                            localStorage.getItem("IsFreePlanActivated") === "true" &&
                              !localStorage.getItem("planId") ? (
                              ""
                            ) : (
                              UserPermissions?.map((up) =>
                                up.Permissions.map((p) => {
                                  if (p.Name === "User Role") {
                                    return (
                                      <NavLink
                                        key={p.Name}
                                        to="/saas/admin/UserRoles"
                                      >
                                        User Roles
                                      </NavLink>
                                    );
                                  } else return "";
                                })
                              )
                            )
                          ) : (
                            <NavLink to="/saas/admin/UserRoles">User Roles</NavLink>
                          )}
                          {UserPermissions?.map((up) =>
                            up.Permissions.map((p) => {
                              if (
                                p.Name === "Label Management" &&
                                localStorage.getItem("AccountType") === "2"
                                && (localStorage.getItem("planIdManu") || localStorage.getItem("IsFreePlanActivated") === "true" )
                              ) {
                                return (
                                  <NavLink to="saas/admin/productionLabel">
                                    Production Label
                                  </NavLink>
                                );
                              } else return "";
                            })
                          )}
                          {UserPermissions?.map((up) =>
                            up.Permissions.map((p) => {
                              if (
                                p.Name === "Inventory Set-Up" &&
                                localStorage.getItem("AccountType") === "2"
                                && (localStorage.getItem("planIdManu") || localStorage.getItem("IsFreePlanActivated") === "true")
                              ) {
                                return (
                                  <NavLink to="/saas/admin/inventory">
                                    Inventory Setup
                                  </NavLink>
                                );
                              } else return "";
                            })
                          )}
                          {UserPermissions?.map((up) =>
                            up.Permissions.map((p) => {
                              if (
                                p.Name === "Trucks, Trailers, Drivers" &&
                                localStorage.getItem("AccountType") === "2" &&
                                localStorage.getItem("isSubscribedManu") &&
                                !localStorage.getItem("planIdManu")?.includes("Core")
                              ) {
                                return (
                                  <NavLink to="/saas/Admin/Transport">
                                    Trucks And Trailers
                                  </NavLink>
                                );
                              } else return "";
                            })
                          )}
                        </Grid>
                      </React.Fragment>
                    }>

                    <Grid item container direction={"row"}>
                      <Grid
                        item
                        paddingLeft={1}
                        onClick={() => {
                          navigate("/saas/admin");
                        }}
                      >
                        Manage
                      </Grid>
                      <Grid item paddingLeft={0.5} paddingTop={0.1}>
                        <KeyboardArrowDownIcon
                          htmlColor="#0674b9"
                          fontSize="medium"
                        />
                      </Grid>
                    </Grid>
                  </HtmlTooltip>


                </Grid>
              </Grid>
              <Grid
                id="fade-button"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setActive2(true);
                  setActive1(false);
                  setActive(false);
                  setActive3(false);
                  setActive4(false);
                  setActive5(false);
                  setActiveF(false);
                  setActiveP(false);
                  setActiveIM(false);
                  setActiveD(false);
                  setActiveL(false);
                }}
                className={active2 ? "activeItems" : "items"}
              >
                <Grid container
                // className="dropdown"
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Grid item container direction="column" backgroundColor="white" rowGap={2} padding="10px 15px 10px 15px"
                          className="dropDownContent"
                        // className="dropdown-content"
                        >
                          <NavLink to="/saas/projects/created">
                            Created Projects
                          </NavLink>
                          <NavLink to="/saas/projects/assigned">
                            Assigned Projects
                          </NavLink>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    <Grid item container direction={"row"}>
                      <Grid
                        item
                        paddingLeft={1}
                        onClick={() => {
                          navigate("/saas/projects");
                        }}
                      >
                        Project
                      </Grid>
                      <Grid item paddingLeft={0.5} paddingTop={0.1}>
                        <KeyboardArrowDownIcon
                          htmlColor="#0674b9"
                          fontSize="medium"
                        />
                      </Grid>
                    </Grid>
                  </HtmlTooltip>


                </Grid>
              </Grid>
              {result.map((p) => {
                if (p.Name === "Drawing Activity") {
                  return (
                    <Link
                      key={p.Name}
                      onClick={() => {
                        setActive3(true);
                        setActive1(false);
                        setActive2(false);
                        setActive(false);
                        setActive4(false);
                        setActive5(false);
                        setActiveF(false);
                        setActiveP(false);
                        setActiveIM(false);
                        setActiveD(false);
                        setActiveL(false);
                      }}
                      className={active3 ? "activeItems" : "items"}
                      to="/saas/execution"
                    >
                      <Grid item paddingBottom={1}>
                        Activity
                      </Grid>
                    </Link>
                  );
                } else return "";
              })}
              {result.map((p) => {
                if (p.Name === "Sales Order") {
                  return (
                    <Link
                      onClick={() => {
                        setActive4(true);
                        setActive5(false);
                        setActive1(false);
                        setActive2(false);
                        setActive3(false);
                        setActive(false);
                        setActiveF(false);
                        setActiveP(false);
                        setActiveIM(false);
                        setActiveD(false);
                        setActiveL(false);
                      }}
                      className={active4 ? "activeItems" : "items"}
                      to="/saas/sales"
                    >
                      <Grid item paddingBottom={1}>
                        Sales Order
                      </Grid>
                    </Link>
                  );
                } else return "";
              })}
              {localStorage.getItem("planIdManu")?.includes("Core") ||
                localStorage.getItem("planIdManu")?.includes("Pro") ? (
                <Link
                  onClick={() => {
                    setActive(false);
                    setActive1(false);
                    setActive2(false);
                    setActive3(false);
                    setActive4(false);
                    setActive5(false);
                    setActiveF(false);
                    setActiveP(true);
                    setActiveIM(false);
                    setActiveD(false);
                    setActiveL(false);
                  }}
                  className={activeP ? "activeItems" : "items"}
                  to="/saas/productionPlanning/Calendar"
                >
                  {result?.map((p) => {
                    if (
                      p.Name === "Production Planning" &&
                      localStorage.getItem("AccountType") === "2"
                    ) {
                      return (
                        <Grid item paddingBottom={1}>
                          Production
                        </Grid>
                      );
                    } else return "";
                  })
                  }
                </Link>
              ) : (
                <>
                  {/* {localStorage.getItem("AccountType") === "2" ? (
                    <Tooltip title="upgrade is required" placement="top-end">
                      <Grid item padding="0 0 0.7% 3%" color="#707070">
                        Production
                      </Grid>
                    </Tooltip>
                  ) : (
                    ""
                  )} */}
                </>
              )}
              {localStorage.getItem("AccountType") === "2" ? (
                <>
                  {localStorage.getItem("planIdManu")?.includes("Pro") ? (
                    <>
                      <Link
                        onClick={() => {
                          setActive(false);
                          setActive1(false);
                          setActive2(false);
                          setActive3(false);
                          setActive4(false);
                          setActive5(false);
                          setActiveF(false);
                          setActiveP(false);
                          setActiveIM(false);
                          setActiveD(false);
                          setActiveL(true);
                        }}
                        className={activeL ? "activeItems" : "items"}
                        to="/saas/Loading/Calendar"
                      >
                        {result?.map((p) => {
                          if (
                            p.Name === "Loading" &&
                            p.Actions.find((e) => e.Name === "View")
                          ) {
                            return (
                              <Grid item paddingBottom={1}>
                                Loading
                              </Grid>
                            );
                          } else return "";
                        })
                        }
                      </Link>
                      <Link
                        onClick={() => {
                          setActive(false);
                          setActive1(false);
                          setActive2(false);
                          setActive3(false);
                          setActive4(false);
                          setActive5(false);
                          setActiveF(false);
                          setActiveP(false);
                          setActiveIM(false);
                          setActiveL(false);
                          setActiveD(true);
                        }}
                        className={activeD ? "activeItems" : "items"}
                        to="/saas/Delivery/Calendar"
                      >
                        {result?.map((p) => {
                          if (
                            p.Name === "Delivery" &&
                            p.Actions.find((e) => e.Name === "View")
                          ) {
                            return (
                              <Grid item paddingBottom={1}>
                                Delivery
                              </Grid>
                            );
                          } else return "";
                        })
                        }
                      </Link>

                      <Link
                        onClick={() => {
                          setActive(false);
                          setActive1(false);
                          setActive2(false);
                          setActive3(false);
                          setActive4(false);
                          setActive5(false);
                          setActiveF(false);
                          setActiveP(false);
                          setActiveIM(true);
                          setActiveL(false);
                          setActiveD(false);
                        }}
                        className={activeIM ? "activeItems" : "items"}
                        to="/saas/admin/inventoryManagement"
                      >
                        {result?.map((p) => {
                          if (
                            p.Name === "Inventory Management" &&
                            localStorage.getItem("AccountType") === "2"
                          ) {
                            return (
                              <Grid item paddingBottom={1}>
                                Inventory
                              </Grid>
                            );
                          } else return "";
                        })
                        }
                      </Link>
                    </>
                  ) : (
                    <>
                      {/* <Tooltip title="upgrade is required" placement="top-end">
                        <Grid item padding="0 0 0.7% 3%" color="#707070">
                          Loading
                        </Grid>
                      </Tooltip>
                      <Tooltip title="upgrade is required" placement="top-end">
                        <Grid item padding="0 0 0.7% 3%" color="#707070">
                          Delivery
                        </Grid>
                      </Tooltip>
                      <Tooltip title="upgrade is required" placement="top-end">
                        <Grid item padding="0 0 0.7% 3%" color="#707070">
                          Inventory
                        </Grid>
                      </Tooltip> */}
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {setupProfile ? (
                <Link
                  onClick={() => {
                    setActive4(false);
                    setActive5(true);
                    setActive1(false);
                    setActive2(false);
                    setActive3(false);
                    setActive(false);
                    setActiveF(false);
                    setActiveP(false);
                    setActiveIM(false);
                    setActiveD(false);
                    setActiveL(false);
                  }}
                  className={active5 ? "activeItems" : "items"}
                  to="/saas/profileDetails"
                >
                  <Grid item paddingBottom={0.8}>
                    {accountName}
                  </Grid>
                </Link>
              ) : (
                <></>
              )}
            </Grid>
            {/* </Grid> */}
          </Grid>
          {naved ? (
            <div
              style={{
                height: 1,
                backgroundColor: "#101650",
                border: "solid 1px #101650",
                boxShadow: "0 9px 12px 0 rgba(0,0,0,0.16)",
              }}
            ></div>
          ) : (
            <div></div>
          )}
        </AppBar>
        <Grid item>
          <AssistanceModal openModal={openModal} setOpenModal={setOpenModal} />
        </Grid>
      </>
    );
  else
    return (
      <>
        <NavDrawerSaas
          setSetupMenu={setSetupMenu}
          setSetupProfile={setSetupProfile}
          setAccountName={setAccountName}
          setIsVerified={setIsVerified}
          setIsSubscribed={setIsSubscribed}
          setToken={setToken}
          setAccount={setAccount}
          setIsLoggedIn={setIsLoggedIn}
          numOfNotification={numOfNotification}
          notificationTitle={notificationTitle}
          numOfInvitations={numOfInvitations}
        />
        <Grid item>
          <AssistanceModal openModal={openModal} setOpenModal={setOpenModal} />
        </Grid>
      </>
    );
}
