import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as activityConst from "../../../StatusConst";
import classes from "../../Assets/Styles/Activities.module.css";
import EyeIcon from "../../Assets/Images/Projects/Eye.png";
import ReviseIcon from "../../Assets/Images/Activity/ReviseIcon.png";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteActivityModal from "./DeleteActivityModal";
import * as activityServices from "../../Services/activities-services.proxy";

const deleteActivity = activityServices.deleteActivity;

export default function BasicCard(props) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);


  const [modalMessage, setModalMessage] = useState();
  const [deletingAct, setDeletingAct] = useState(false);


  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteTheActivity = () => {
    setDeletingAct(true);
    deleteActivity(props.data.Id).then(() => {
      setDeletingAct(false);
      props.setModify(!props.modify);
    });
  };

  const handleClick = async (event) => {
    setAnchorEl(event.target);
  };

  return (
    <Grid
      container
      sx={{
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        // width: 295,
        // minHeight: 168,
        opacity: props.assigned ? 0.5 : 1,
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          minHeight: 160,
          backgroundColor: "#101650",
          borderBottomLeftRadius: 5,
          borderTopLeftRadius: 5,
        }}
      ></Grid>
      <Grid item xs={11}>
        <Card
          sx={{
            boxShadow: 0,
            minHeight: 168,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <CardContent
          // onMouseOver={(e) => {
          //   e.target.style.cursor = "pointer";
          // }}
          // onClick={() => {
          //   if (props.data.CreatorId === localStorage.getItem("UserId")) {
          //     navigate("/saas/execution/create", {
          //       state: { id: props.data.Id, edit: true, from: "card" },
          //     });
          //   } else {
          //     navigate("/saas/execution/create", {
          //       state: { id: props.data.Id, edit: false, from: "card" },
          //     });
          //   }
          // }}
          >
            <Grid container>
              <Grid item xs={12} textAlign="right">
                <MoreHorizIcon
                  onMouseOver={(e) => e.target.style.cursor = "pointer"}
                  onClick={(e) => handleClick(e)} />
              </Grid>
              <Grid container
                onMouseOver={(e) => e.target.style.cursor = "pointer"}
              onClick={() => {
                if (props.data.CreatorId === localStorage.getItem("UserId")) {
                  navigate("/saas/execution/create", {
                    state: { id: props.data.Id, edit: true, from: "card" },
                  });
                } else {
                  navigate("/saas/execution/create", {
                    state: { id: props.data.Id, edit: false, from: "card" },
                  });
                }
              }}
              >
                <Grid item xs={12} paddingTop={1}>
                  <Typography
                    fontFamily="Muli"
                    fontSize={10}
                    fontWeight="bold"
                    textAlign="left"
                    color="#101650"
                    gutterBottom
                  >
                    <Grid item container direction="row" columnGap={0.5}>
                      <Grid item container direction="column" xs={5.5} >
                        <Grid item>Activity Code</Grid>
                        <Grid item fontWeight="normal"> {props.data.Code} </Grid>
                      </Grid>
                      <Grid item container direction="column" xs={5.5} >
                        <Grid item>Activity Type</Grid>
                        <Grid item fontWeight="normal"> {props.data.Type} </Grid>
                      </Grid>
                      <Grid item container direction="column" xs={12} >
                        <Grid item>Assignee</Grid>
                        <Grid item fontWeight="normal"> {props.data.Assignee ? props.data.Assignee : "N/A"} </Grid>
                      </Grid>
                    </Grid>

                  </Typography>
                </Grid>
                <Grid item container direction="column" xs={12}
                  fontFamily="Muli"
                  fontSize={10}
                  fontWeight="bold"
                  textAlign="left"
                  color="#101650" >
                  <Grid item>Drawing No</Grid>
                  <Grid item fontWeight="normal"> {props.data.DrawingsNumber ? props.data.DrawingsNumber : "N/A"} </Grid>
                </Grid>
                {props.data.RevisionNumber > 0 ? (
                  <Grid item xs={2} container direction={"row-reverse"}>
                    <img src={ReviseIcon} alt='revisIcon' />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <div
              style={{
                fontFamily: "Muli",
                textAlign: "left",
                color: "#707070",
                paddingTop: 5,
              }}
              onMouseOver={(e) => e.target.style.cursor = "pointer"}
              onClick={() => {
                if (props.data.CreatorId === localStorage.getItem("UserId")) {
                  navigate("/saas/execution/create", {
                    state: { id: props.data.Id, edit: true, from: "card" },
                  });
                } else {
                  navigate("/saas/execution/create", {
                    state: { id: props.data.Id, edit: false, from: "card" },
                  });
                }
              }}
            >
              <Grid item direction="column" xs={12}
                fontFamily="Muli"
                fontSize={10}
                fontWeight="bold"
                textAlign="left"
                color="#101650"
                paddingBottom={0.5} >Drawing Description</Grid>
              <Typography
                sx={{
                  fontFamily: "Muli",
                  fontSize: 11,
                  fontStyle: "italic",
                  lineHeight: 1.18,
                  textAlign: "left",
                  color: "#5fba47",
                  height: 50,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                paddingBottom={0.5}
              >
                {props.data.Description ? props.data.Description : "N/A"}
              </Typography>
              <Grid container direction={"row"}>
                <Grid item xs={12} height="45px">
                  <Typography

                    fontSize={11}
                    width={"fit-content"}
                    bgcolor={
                      props.data.Status === activityConst.DRAFT_ACTIVITY
                        ? "#d9d2d2"
                        : props.data.Status === activityConst.PLANNED
                          ? "rgba(95, 186, 71, 0.7)"
                          : props.data.Status === activityConst.IN_PROGRESS
                            ? "rgba(45, 164, 208, 0.5)"
                            : props.data.Status ===
                              activityConst.SUBMITTED_INTERNAL_APPROVAL_1
                              ? "rgba(225, 227, 25, 0.7)"
                              : activityConst.SUBMITTED_INTERNAL_APPROVAL_2
                                ? "rgba(16, 22, 80, 0.3)"
                                : activityConst.SUBMITTED_FOR_CONSULTANT
                                  ? "rgba(16, 22, 80, 0.7)"
                                  : ""
                    }
                    borderRadius={"2px"}
                    padding={0.4}
                    color={"#101650"}
                  >
                    {props.data.Status}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Typography fontSize={11}>
                  {props.data.SubmittalStatus}
                </Typography>
              </Grid>
            </div>
          </CardContent>
          <Grid
            sx={{
              borderTop: "solid 1px #e1e319",
            }}
          ></Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            padding="1% 10% 1% 5%"
          >
            {props.data.RevisionNumber > 0 ? (
              <Grid item container padding={0.2}>
                <Grid item xs={6} className={classes["Activity-Versions"]}>
                  Activity Versions
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction={"row-reverse"}
                  className={classes["Activity-Versions"]}
                  gap={1}
                >
                  <Grid item className={classes["Rectangle-1348"]}>
                    {props.data.RevisionNumber}
                  </Grid>
                  <Grid
                    item
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      navigate("/saas/execution/RevisionsActivitiesTable", {
                        state: { id: props.data.Id, code: props.data.Code },
                      });
                    }}
                  >
                    <img src={EyeIcon} alt="view" />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}
            fontFamily="Muli"
            fontSize={10}
            fontWeight="bold"
            textAlign="right"
            color="#101650"
            padding="0 2% 2% 0"
          >
            Activity Progress :
            {props.data.Progress ? props.data.Progress === "Delayed" ?
              <span style={{ color: "red" }}> Delayed</span> :
              <span> {props.data.Progress} %</span> :
              <span> 0 %</span>
            }</Grid>
        </Card>
      </Grid>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/saas/execution/create", {
              state: { id: props.data.Id, edit: false, from: "card" },
            });
          }}
          style={{
            fontFamily: "Muli",
            fontSize: "13px",
            color: "#444",
          }}
        >
          View Activity
        </MenuItem>
        <Grid>
          <MenuItem
            onClick={() => {
              navigate("/saas/execution/RevisionsActivitiesTable", {
                state: { id: props.data.Id, code: props?.data?.Code, from: "card" },
              });
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            View Activity Versions
          </MenuItem>
        </Grid>

        {props?.data?.CreatorId === localStorage.getItem("UserId") ? (
          <Grid>
            <MenuItem
              onClick={() => {
                navigate("/saas/execution/create", {
                  state: { id: props.data.Id, edit: true, from: "card" },
                });
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (props.data.Status !== "Draft") {
                  setModalMessage(
                    "You can't delete this activity because it is not draft "
                  );
                }
                setOpen(true);
                handleClose();
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Delete
            </MenuItem>
          </Grid>
        ) : (
          ""
        )}
      </Menu>
      <DeleteActivityModal
        openModal={open}
        setOpenModal={setOpen}
        deleteTheActivity={deleteTheActivity}
        modalMessage={modalMessage}
        setModalMessage={setModalMessage}
        deletingAct={deletingAct}
      />
    </Grid>
  );
}
