//iotlogin
export const IOT_LOGIN = "/auth/IOT/signIn";

//machinelogin
export const MACHINE_BY_ID = '/machine-dashboard/machine-by-id';
export const HUMAN_RESOURCE_LOGIN = '/machine-dashboard/human-resource-login';

//machineDashboard
export const MACHINE_DATA = '/machine-dashboard/machine-data';
export const MACHINE_BY_DATE = '/machine-dashboard/machine-data-by-date';
export const SALES_ORDERS_DATA_BY_GROUP_ID = '/machine-dashboard/get-salesOrders-by-optimization-group-id';
export const CYCLE_REQUIRED_ITEM = '/machine-dashboard/cycle-required-item';
export const CYCLE_BUNDLE_DETAILS = '/machine-dashboard/cycle-bundle-details';
export const UPDATE_USED_BUNDLE = '/machine-dashboard/update-used-bundle';
export const UPDATE_USED_BOX = '/machine-dashboard/used-box';
export const GET_CURRENT_CYCLE = '/machine-dashboard/current-cycle';
export const UPDATE_CYCLE_STATUS = '/machine-dashboard/update-cycle-status';
export const GET_NEXT_CYCLE_BUNDLE = '/machine-dashboard/next-cycle-bundle';
export const GET_PRINT_DATA = '/machine-dashboard/get-print-data';
export const CYCLE_SUMMARY = '/machine-dashboard/cycle-summary';
export const UPDATE_ZONE = '/machine-dashboard/cycle-finish';
export const HOLD_MACHINE = '/machine-dashboard/hold-machine';

export const PRODUCTION_CELL_DASHBOARD = '/machine-dashboard/production-cell-dashboard';


//demoRequest
export const EMAIL_DEMO = "/email/request-demo";

//GetInputData
export const INPUT_DATA = "/main-contractor-company/input-data";
export const INPUT_DATA2 = "/sub-contractor-company/input-data";

//Register
export const MC_REGISTER = "/main-contractor-company/create";
export const SC_REGISTER = "/sub-contractor-company/create";

//VerifyCode
export const VERIFY_CODE = "/user/verify-user";

//products
export const PRICING_PLANS = "/product/get-filtered-products";
export const CREATE_CHECKOUT_SESSION =
  "/product/create-product-checkout-session";

//Login
export const LOG_IN = "/auth/signIn";

//ResetPass
export const REQUEST_PASS = "/user/request-reset-password";
export const VALID_TOKEN = "/user/is-password-token-valid";
export const RESET_PASS = "/user/reset-password";

//profile services

//GET MAIN CONTRACTOR DATA
export const MAIN_CONTRACTOR_DATA = "/main-contractor-company";
//GET MAIN CONTRACTOR EDITABLE DATA
export const MAIN_CONTRACTOR_EDIT_DATA =
  "/main-contractor-company/company-edit-data";
export const UPLOAD_PROFILE = "/main-contractor-company/upload-profile";
//UPDATE MAIN CONTRACTOR
export const UPDATE_PROFILE = "/main-contractor-company/update";
//Delete Previous file on edit (MAIN)
export const DELETE_OLD_FILE = "/main-contractor-company/delete-profile";

//UPDATE SLOGAN WEBSITE MC
export const UPDATE_ADDITIONAL_INFO_MC =
  "/main-contractor-company/update-additional-info";
//UPDATE SLOGAN WEBSITE SC
export const UPDATE_ADDITIONAL_INFO_SC =
  "/sub-contractor-company/update-additional-info";
//MC UPLOAD PROFILE
export const UPDATE_PROFILE_PICTURE_MC = "/user/upload-profile-picture";
//MC SAVE PROFILE
export const SAVE_PROFILE_PICTURE_MC = "/user/save-profile-picture";
//MC DELETE PROFILE
export const DELETE_PROFILE_PICTURE_MC =
  "/main-contractor-company/delete-profile";
//CHANGE PASSWORD
export const CHANGE_PASSWORD = "/user/change-password";

//GET SUB CONTRACTOR DATA
export const SUB_CONTRACTOR_DATA = "/sub-contractor-company";
//GET SUB CONTRACTOR EDITABLE DATA
export const SUB_CONTRACTOR_EDIT_DATA =
  "/sub-contractor-company/company-edit-data";
export const UPLOAD_SUB_PROFILE = "/sub-contractor-company/upload-profile";
//UPDATE SUB CONTRACTOR
export const UPDATE_SUB_PROFILE = "/sub-contractor-company/update";
//Delete Previous file on edit (sub)
export const DELETE_OLD_SUB_FILE = "/sub-contractor-company/delete-profile";
// remove PROFILE
export const REMOVE_PROFILE_PICTURE = "/user/remove--bucket-profile-picture";
// Manage Subscription
export const MANAGED_PRODUCTS = "/product/managed-products";
// Cancel Subscription
export const CANCEL_SUBSCRIPTION = "/product/cancel-subscription";
// CHANGE Subscription
export const CHANGE_SUBSCRIPTION =
  "/product/change-subscription-checkout-session";
// resquest subscription input data
export const REQUEST_SUBSCRIPTION_INPUT_DATA =
  "/product/request-custom-subscription-input-data";
// resquest subscription
export const REQUEST_SUBSCRIPTION = "/product/request-custom-subscription";
//GET NOTIFICATIONS
export const GET_NOTIFICATIONS = "/notification/get-notifications";
//user viewed NOTIFICATIONS
export const VIEW_NOTIFICATIONS = "/notification/view-notification";
//PUBLIC EVENTS NOTIFICATIONS
export const PUBLIC_EVENTS_NOTIFICATIONS = "/notification/public-events";
//SYSTEM EVENTS NOTIFICATIONS
export const SYSTEM_EVENTS_NOTIFICATIONS = "/notification/system-admin-events";
//USER EVENTS NOTIFICATIONS
export const USER_EVENTS_NOTIFICATIONS = "/notification/user-events";
//GET User Data
export const GET_USER_DATA = "/user/get-data";
// snooze Tips
export const SNOOZE_TIP = "/tip/snooze";
// delete notifications
export const DELETE_NOTIFICATION = "/notification";
// User Invitaion List
export const USER_INVITATION_LIST = "/user/invitation-list";
// User Accept Invitaion
export const USER_ACCEPT_INVITATION = "/user/accept-invitation"; // User Accept Invitaion
export const TIPS_EVENTS = "/notification/tips-events";
export const NOTIFICATIONS_EVENTS = "/notification/public-events";
export const USER_NOTIFICATIONS_EVENTS = "/notification/user-events";
//activate free plan
export const ACTIVATE_FREE_PLAN = "/product/activate-free-plan";
