import React, { useEffect, useState } from "react";
import { Grid, Modal, Box, TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import classes from "../../../Assets/Styles/Transport.module.css";
import * as transportServices from "../../../Services/transport-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
const getTrailerInputData = transportServices.getTrailerInputData;
const createTrailer = transportServices.createTrailer;
const updateTrailer = transportServices.updateTrailer;

function TrailerModal(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    setName(null);
    setNameError(false);
    setCode(null);
    setDescription(null);
    setDescriptionError(false);
    setMaxLoadWeight(null);
    setMaxLoadWeightError(false);
    setMaxLoadHeight(null);
    setMaxLoadHeightError(false);
    setLength(null);
    setLengthError(false);
    setIsDisabled(true);
    setHeight(null);
    setHeightError(false);
    setWidth(null);
    setWidthError(false);
    setCompanyName(null);
    setCompanyNameError(false);
    props.setTrailerId(null);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  //fiedls

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [code, setCode] = useState(null);
  const [description, setDescription] = useState(null);
  const [descriptionError, setDescriptionError] = useState(false);
  const [maxLoadWeight, setMaxLoadWeight] = useState(null);
  const [maxLoadWeightError, setMaxLoadWeightError] = useState(false);
  const [status, setStatus] = useState("Active");
  const [maxLoadHeight, setMaxLoadHeight] = useState(null);
  const [maxLoadHeightError, setMaxLoadHeightError] = useState(false);
  const [length, setLength] = useState(null);
  const [lengthError, setLengthError] = useState(false);
  const [height, setHeight] = useState(null);
  const [heightError, setHeightError] = useState(false);
  const [width, setWidth] = useState(null);
  const [widthError, setWidthError] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      !name ||
      !description ||
      !maxLoadWeight ||
      !maxLoadHeight ||
      !length ||
      !height ||
      !width ||
      nameError ||
      descriptionError ||
      maxLoadWeightError ||
      maxLoadHeightError ||
      lengthError ||
      heightError ||
      widthError ||
      !companyName ||
      companyNameError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoadingData(true);
      getTrailerInputData(props.trailerId ? props.trailerId : null).then(
        (x) => {
          setLoadingData(false);
          setCode(x.Code);
          if (props.trailerId) {
            setCode(x.Code);
            setName(x.Name);
            setDescription(x.Description);
            setMaxLoadWeight(x.MaxLoadWeight);
            setMaxLoadHeight(x.MaxLoadHeight);
            setLength(x.Dimensions.Length);
            setHeight(x.Dimensions.Height);
            setWidth(x.Dimensions.Width);
            setCompanyName(x.CompanyName);
          }
        }
      );
    }
  }, [open]);
  const trailerDetails = {
    Id: props.trailerId ? props.trailerId : null,
    Name: name,
    Code: code,
    Description: description,
    MaxLoadWeight: maxLoadWeight,
    MaxLoadHeight: maxLoadHeight,
    Dimensions: {
      Length: length,
      Width: width,
      Height: height,
    },
    CompanyName: companyName,
  };
  const save = () => {
    setLoading(true);
    if (props.trailerId) {
      updateTrailer(trailerDetails).then((x) => {
        setLoading(false);
        handleClose();
        props.setModify(!props.modify);
      });
    } else {
      createTrailer(trailerDetails).then((x) => {
        setLoading(false);
        handleClose();
        props.setModify(!props.modify);
      });
    }
  };

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        width={{ xs: "90%", sm: "65%", md: "50%" }}
        height={{ xs: "80%", sm: "70%", md: "60%" }}
        paddingTop={4}
        overflow={"auto"}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item className={classes["Truck-Detail"]}>
          Trailer Detail
        </Grid>
        <Grid
          marginTop={2}
          xs={12}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item width={"90%"} className={classes["Path-4142"]}></Grid>
        </Grid>
        <Grid
          item
          container
          paddingTop={2}
          paddingLeft={8}
          paddingRight={8}
          rowGap={1.5}
        >
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Trailer Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={name}
                variant={"standard"}
                fullWidth
                error={nameError}
                helperText={nameError ? "Enter a Name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setNameError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setName(e.target.value);
                  if (e.target.value) setNameError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Trailer Code
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={code}
                variant={"standard"}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Trailer Status
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={status}
                variant={"standard"}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={11.2}>
            <Grid item className={classes["FieldLa"]}>
              Description
            </Grid>
            <Grid item xs={12} paddingTop={0.5}>
              <TextField
                value={description}
                variant={"standard"}
                fullWidth
                error={descriptionError}
                helperText={descriptionError ? "Enter description" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setDescriptionError(true);
                }}
                size={"medium"}
                InputProps={{
                  style: {
                    height: "74px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                multiline
                rows={4}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (e.target.value) setDescriptionError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={3.5}>
            <Grid item className={classes["FieldLa"]}>
              Max Load Weight (kg)
            </Grid>
            <Grid item xs={8} paddingTop={0.5}>
              <TextField
                value={maxLoadWeight}
                variant={"standard"}
                fullWidth
                type={"number"}
                error={maxLoadWeightError}
                helperText={maxLoadWeightError ? "Enter a number" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setMaxLoadWeightError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                  inputProps: { min: 0 },
                }}
                onChange={(e) => {
                  setMaxLoadWeight(e.target.value);
                  if (e.target.value) setMaxLoadWeightError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item xs={3.5}>
            <Grid item className={classes["FieldLa"]}>
              Max Load Height (m)
            </Grid>
            <Grid item xs={8} paddingTop={0.5}>
              <TextField
                value={maxLoadHeight}
                variant={"standard"}
                fullWidth
                error={maxLoadHeightError}
                helperText={maxLoadHeightError ? "Enter number" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setMaxLoadHeightError(true);
                }}
                type={"number"}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                  inputProps: { min: 0 },
                }}
                onChange={(e) => {
                  setMaxLoadHeight(e.target.value);
                  if (e.target.value) setMaxLoadHeightError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item className={classes["FieldLa"]}>
              Dimensions (LxWxH) in meters
            </Grid>
            <Grid item container direction={"row"} xs={12} gap={1}>
              <Grid item xs={3} paddingTop={0.5}>
                <TextField
                  value={length}
                  variant={"standard"}
                  fullWidth
                  error={lengthError}
                  helperText={lengthError ? "Enter Length" : ""}
                  onBlur={(e) => {
                    if (!e.target.value) setLengthError(true);
                  }}
                  type={"number"}
                  InputProps={{
                    style: {
                      height: "26px",
                      fontSize: 12,
                      fontFamily: "Muli",
                      backgroundColor: "#fff",
                      border: "solid 0.3px #707070",
                      paddingLeft: 10,
                    },
                    inputProps: { min: 0 },
                  }}
                  onChange={(e) => {
                    setLength(e.target.value);
                    if (e.target.value) setLengthError(false);
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={3} paddingTop={0.5}>
                <TextField
                  value={width}
                  variant={"standard"}
                  fullWidth
                  error={widthError}
                  helperText={widthError ? "Enter width" : ""}
                  onBlur={(e) => {
                    if (!e.target.value) setWidthError(true);
                  }}
                  type={"number"}
                  InputProps={{
                    style: {
                      height: "26px",
                      fontSize: 12,
                      fontFamily: "Muli",
                      backgroundColor: "#fff",
                      border: "solid 0.3px #707070",
                      paddingLeft: 10,
                    },
                    inputProps: { min: 0 },
                  }}
                  onChange={(e) => {
                    setWidth(e.target.value);
                    if (e.target.value) setWidthError(false);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={3} paddingTop={0.5}>
                <TextField
                  value={height}
                  variant={"standard"}
                  fullWidth
                  error={heightError}
                  helperText={heightError ? "Enter Height" : ""}
                  onBlur={(e) => {
                    if (!e.target.value) setHeightError(true);
                  }}
                  type={"number"}
                  InputProps={{
                    style: {
                      height: "26px",
                      fontSize: 12,
                      fontFamily: "Muli",
                      backgroundColor: "#fff",
                      border: "solid 0.3px #707070",
                      paddingLeft: 10,
                    },
                    inputProps: { min: 0 },
                  }}
                  onChange={(e) => {
                    setHeight(e.target.value);
                    if (e.target.value) setHeightError(false);
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Company Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={companyName}
                variant={"standard"}
                fullWidth
                error={companyNameError}
                helperText={companyNameError ? "Enter a company name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setCompanyNameError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  if (e.target.value) setCompanyNameError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item container xs={11} direction={"row-reverse"} paddingTop={5}>
            <button
              className={classes["SaveBut"]}
              disabled={isDisabled}
              onClick={() => {
                save();
              }}
            >
              {props.trailerId
                ? loading
                  ? "Updating..."
                  : "update"
                : loading
                ? "saving..."
                : "save"}
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
  border: "solid 1px #0674b9",
  backgroundColor: "#fff",
};
export default TrailerModal;
