import React, { useEffect, useState } from "react";
import { Grid, Modal, Box, TextField, MenuItem } from "@mui/material";
import classes from "../../../../../Assets/Styles/factory.module.css";

function TransferModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "30%",
    width: "32%",
    borderRadius: "17px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.36)",
    border: "solid 1px #0674b9",
    backgroundColor: "#101650",
  };

  const [open, setOpen] = useState(false);
  const [notEnough, setNotEnough] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    props.setZoneId(0);
    props.setStorageId(0);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
      console.log(props.quantityToTransfer);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ style: { backgroundColor: "white", opacity: 0.5 } }}
    >
      <Box sx={style}>
        {props.transfer ? (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems={"center"}
            direction="column"
            padding={5}
          >
            <Grid item className={classes["Transfer-title"]}>
              Transfer bundle(s) to:
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              gap={2}
            >
              <Grid item xs={7}>
                <Grid item className={classes["drop-title"]} fontSize={"10px"}>
                  Storage Block
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    value={props.storageId}
                    fullWidth
                    select
                    InputProps={{
                      style: {
                        height: "36px",
                        fontSize: 12,
                        fontFamily: "Muli",
                        backgroundColor: "#c6e1d0",
                      },
                    }}
                    onChange={(e) => {
                      props.setStorageId(e.target.value);
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Block
                    </MenuItem>
                    {props.storageBlocks?.map((r) => (
                      <MenuItem value={r.Id} key={r.Id}>
                        {r.Code + "/" + r.TypeName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item className={classes["drop-title"]} fontSize={"10px"}>
                  Zone
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    value={props.zoneId}
                    fullWidth
                    select
                    InputProps={{
                      style: {
                        height: "36px",
                        fontSize: 12,
                        fontFamily: "Muli",
                        backgroundColor: "#c6e1d0",
                      },
                    }}
                    onChange={(e) => {
                      if (
                        props.quantityToTransfer >
                        props.storageBlocks
                          ?.filter((x) => x.Id === props.storageId)[0]
                          ?.Zone?.filter((x) => x.Id === e.target.value)[0]
                          .AvailableCapacity
                      ) {
                        setNotEnough(true);
                      } else {
                        props.setZoneId(e.target.value);
                        setNotEnough(false);
                      }
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Zone
                    </MenuItem>
                    {props.storageBlocks
                      ?.filter((x) => x.Id === props.storageId)[0]
                      ?.Zone?.filter((x) => x.Id !== props.ZONE_ID)
                      ?.map((r) => (
                        <MenuItem value={r.Id} key={r.Id}>
                          {r.Code}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              fontFamily={"Muli"}
              fontSize={12}
              fontWeight={"bold"}
              color={"#ea001e"}
            >
              {notEnough
                ? "Capacity to Transfer is bigger than the available capcity of the zone."
                : ""}
            </Grid>
            <Grid
              marginTop={2}
              item
              xs={12}
              container
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              gap={10}
            >
              <button
                className={classes["TransfeMB"]}
                onClick={() => handleClose()}
              >
                Cancel
              </button>
              <button
                className={classes["TransfeMB"]}
                disabled={props.zoneId === 0 || props.loading ? true : false}
                onClick={() => {
                  props.TransferTheBundles();
                  handleClose();
                }}
              >
               {props.loading? "Confirming ... ":"Confirm"}
              </button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems={"center"}
            direction="column"
            padding={5}
          >
            <Grid item className={classes["Transfer-title"]}>
              Are you sure you want to delete this bundle?
            </Grid>

            <Grid
              marginTop={2}
              item
              xs={12}
              container
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              gap={10}
            >
              <button
                className={classes["deleteMB"]}
                onClick={() => {
                  props.DelteTheBundle();
                  setTimeout(() => {
                    handleClose();
                  }, 500);
                }}
              >
                Yes
              </button>
              <button
                onClick={() => handleClose()}
                className={classes["deleteMB"]}
              >
                No
              </button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
}

export default TransferModal;
