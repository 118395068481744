import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../Assets/Styles/projects.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../Assets/Images/Table/menuPic.png";
import { useSelector } from "react-redux";
import * as salesOrderServices from "../../Services/sales-order-services.proxy";

import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 12px;
  box-sizing: border-box;
  min-height: 32px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  max-height:250px !important;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const getProjectOrderBooksTableList =
  salesOrderServices.getProjectOrderBooksTableList;

export default function SalesProjectList(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [projectTable, setProjectTable] = useState([]);

  const [dataLength, setdataLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [modify, setModify] = useState(false);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState(null);
  const [customers, setCustomers] = useState([]);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    CustomerId: customerId
  };

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getProjectOrderBooksTableList(listData).then((x) => {
      if (apiTest) {
        setProjectTable(x.Projects);
        setdataLength(x.Total);
        setCustomers(x.CustomerNames)
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [searchText, pageNumber, pageSize, modify, customerId]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      headerName: "Project Name",
      width: 400,
      renderCell: (params) => (
        <span>
          {params.row?.Name}
        </span>
      ),
    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 350,
      hide: localStorage.getItem("AccountType") === "1" ? true : false,
      renderCell: (params) => (
        <span>
          {params.row?.CustomerName}
        </span>
      ),
    },
    {
      field: "SalesOrders",
      headerName: "No. of Sales Orders",
      width: 250,
      renderCell: (params) => (
        <span>
          {params.row?.SalesOrders}
        </span>
      ),
    },
    {
      field: "TotalWeight",
      headerName: "Total Weight (ton)",
      width: 250,
      renderCell: (params) => (
        <span>
          {params.row?.TotalWeight}
        </span>
      ),
    },
  ];
  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {/* {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          marginTop={1}
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("IsSubscribedBefore") === "true" ?
                "Please subscribe before you start working"
                :
                planType === "Pro" &&
                  localStorage.getItem("isTeamMember") === "false"
                  ? "Upgrade subscription required (Pro+)"
                  : planType === "Core" &&
                    localStorage.getItem("isTeamMember") === "false"
                    ? "Upgrade subscription required (Pro or Pro+)"
                    : localStorage.getItem("isGuest") === "true"
                      ? "Please subscribe to create/edit or archive/re-activate project"
                      : localStorage.getItem("isTeamMember") === "true" &&
                        !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                        !UserPermissions?.find((x) => x.Name === "Admin")
                        ? "You don't have permission to create/edit or archive/re-activate a project"
                        : localStorage.getItem("isTeamMember") === "true" &&
                          (UserPermissions?.find((x) => x.Name === "Project Manager") ||
                            UserPermissions?.find((x) => x.Name === "Admin"))
                          ? companyPlan === "Core"
                            ? "Upgrade subscription for Company required (Pro or Pro+)"
                            : companyPlan === "Pro"
                              ? "Upgrade subscription for Company required (Pro+)"
                              : ""
                          : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            {localStorage.getItem("isTeamMember") &&
              localStorage.getItem("IsSubscribedBefore") !== "true" ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )} */}

      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid item container md={11.95} paddingBottom={2} paddingLeft={3.5}>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Project Order Books
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sm={5}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 2 }}
          >
            <Grid item md={1.5} xs={3}>
              <button
                disabled={localStorage.getItem("IsSubscribedBefore") === "true"}
                className={classes["AddButton"]}
                onClick={()=> navigate("/saas/salesOrderForm")}
              >
                Add
              </button>
            </Grid>
            <Grid item md={1.5} xs={3}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  navigate(-1);
                  //   localStorage.removeItem("idp");
                  //   localStorage.removeItem("proname");
                }}
              >
                Go back
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 2, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
          {
            localStorage.getItem("AccountType") === "1" ?
              <></>
              :
              <>
                <Grid item>
                  <CustomSelect
                    value={customerId}
                    onChange={(option) => {
                      setCustomerId(option);
                    }}
                  >
                    <StyledOption value={null} disabled>
                      Customer Name
                    </StyledOption>
                    {customers?.map((s) => (
                      <StyledOption key={s.Id} value={s.Id}>
                        {s.Name}
                      </StyledOption>
                    ))}
                  </CustomSelect>
                </Grid>
                {customerId ? (
                  <Grid item marginTop={0.6}>
                    <button
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        setCustomerId(null);
                      }}
                      className={classes["Rectangle-1224"]}
                    >
                      Clear filters
                    </button>
                  </Grid>
                ) : (
                  ""
                )}
              </>
          }
        </Grid>
        <div
          style={{
            height: 500,
            width: "96.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={projectTable ? projectTable : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            //rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No order books to display
                </Stack>
              ),
            }}
          />
        </div>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
          disableScrollLock
        >
          <MenuItem
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              textAlign: "center",
              display: "block",
            }}
            onClick={() => {
              navigate(`/saas/salesOrderList?id=${rowData?.row.Id}`);
              localStorage.setItem("ProjectName", rowData?.row.Name);
            }}
          >
            View Sales Orders
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  );
}
