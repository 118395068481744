import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import archivedIcon from "../../../../Assets/Images/Navbar/loginIcon.png";
import classes from "../../Table/Table.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as saasServices from "../../../Services/saas-services.proxy";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import noFileimg from "../../../Assets/Images/Table/TeamMemberProfile.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import * as constFile from "../../../../StatusConst";
import { useSelector } from "react-redux";
//import * as permissionFunction from "../../../../ReusableFunctions";
import CloseIcon from "@mui/icons-material/Close";
const getTeamMemberInputData = saasServices.getTeamMemberInputData;
const getTeamMemberList = saasServices.getTeamMemberList;
const changeTMStatus = saasServices.changeTMStatus;
const resendTMInvitation = saasServices.resendTMInvitation;
const deleteTM = saasServices.deleteTM;

//Statuses
const INVITED = constFile.INVITED;
const ACTIVE = constFile.ACTIVE;
const SUSPENDED = constFile.SUSPENDED;
const ARCHIVED = constFile.ARCHIVED;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 12px;
  box-sizing: border-box;
  min-height: 32px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  max-height:250px !important;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

function renderValue(option) {
  if (option === null) {
    return <span style={{ fontFamily: "Muli", fontSize: 12 }}></span>;
  }

  return <span>{option.label}</span>;
}
export default function TeamMember(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  const [teamMemberTable, setTeamMemberTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [discipline] = useState(null);
  const [disciplines, setDisciplines] = useState([]);

  const [role, setRole] = useState(0);
  const [roles, setRoles] = useState([]);
  const [project] = useState(null);
  //const [projects, setProjects] = useState([]);

  const [preInvite, setPreInvite] = useState(false);

  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [trade] = useState(null);
  const [trades, setTrades] = useState([]);
  const [subProject] = useState(null);
  //const [subProjects, setSubProjects] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const navigate = useNavigate();
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    GroupPermissionId: role === 0 ? null : role,
    ProjectId: project,
    SubProjectId: subProject,
    DisciplineId: discipline,
    TradeId: trade,
    StatusId: status === 0 ? null : status,
  };

  const [loadingData, setLoadingData] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [modify,setModify] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getTeamMemberInputData().then((x) => {
      if (apiTest) {
        setDisciplines(x.Disciplines);
        setStatuses(x.Statuses);
        props.setCode(x.TeamMember.Code);
        //setProjects(x.Projects);
        setRoles(x.GroupPermission);
        setTrades(x.Trades);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, []);

  useEffect(() => {
    let apiTest = true;
    setloadingTable(true);
    getTeamMemberList(listData).then((x) => {
      if (apiTest) {
        setTeamMemberTable(x.TeamMembers);
        setdataLength(x.Total);
        setloadingTable(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [
    pageSize,
    pageNumber,
    searchText,
    props.modify,
    project,
    status,
    discipline,
    role,
    trade,
    props.create,
    modify
  ]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Name}
        </span>
      ),
    },
    {
      field: "Profile",
      headerName: "Profile",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <img
          src={
            params.row.StatusId === ARCHIVED
              ? params.row?.ProfilePicture
                ? params.row.ProfilePicture.URL
                : archivedIcon
              : noFileimg
          }
          style={{ width: "15%" }}
          alt={"null"}
        />
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      width: 280,
      resizeable: true,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Email}
        </span>
      ),
    },
    // {
    //   field: "RoleCategory",
    //   headerName: "Role Category",
    //   width: 200,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
    //       }}
    //     >
    //       {/* {params.row?.UserGroupPermissions} */}
    //     </span>
    //   ),
    // },
    {
      field: "UserGroupPermissions",
      headerName: "Role",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.UserGroupPermissions}
        </span>
      ),
    },
    {
      field: "FactoryName",
      headerName: "Factory Name",
      width: 200,
      sortable: false,

    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.StatusId === ACTIVE
                ? "green"
                : params.row.StatusId === INVITED
                  ? "#0674b9"
                  : params.row.StatusId === SUSPENDED
                    ? "red"
                    : "grey",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
    {
      field: "Creator",
      headerName: "Creator",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Creator}
        </span>
      ),
    },
    {
      field: "CreationTime",
      headerName: "Created Date",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.CreationTime.slice(0, 10)}
        </span>
      ),
    },
  ];
  return (
    <Grid container>
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          // marginLeft="5%"
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Engineering Manager") &&
                !UserPermissions?.find((x) => x.Name === "Team Leader")
                ? "You only have permission to view team member records"
                : localStorage.getItem("isTeamMember") === "true" &&
                  (UserPermissions?.find((x) => x.Name === "Project Manager") ||
                    UserPermissions?.find((x) => x.Name === "Admin") ||
                    UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ) ||
                    UserPermissions?.find((x) => x.Name === "Team Leader"))
                  ? "You don't have permission to archive or restore a team member"
                  : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            <Grid item xs={8}></Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container>
        <Grid
          item
          container
          direction={"row"}
          md={12}
          paddingBottom={2}
          alignItems="center"
        >
          <Grid item xs={3} md={4}>
            <span className={classes["Table-Title"]}>Team Members</span>
          </Grid>
          <Grid
            item
            xs={8.5}
            md={7.8}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 2 }}
            paddingLeft={3}
          >
            <button
              className={classes["AddButton"]}
              disabled={
                localStorage.getItem("IsSubscribedBefore") === "true"
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                  !UserPermissions?.find((x) => x.Name === "Admin") &&
                  !UserPermissions?.find(
                    (x) => x.Name === "Engineering Manager"
                  ) &&
                  !UserPermissions?.find((x) => x.Name === "Team Leader")
                )
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Team Member"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) =>
                              a.Name === "Invite Admin/special roles" ||
                              a.Name === "Invite Project Manager" ||
                              a.Name === "Invite Engineering Manager" ||
                              a.Name === "Invite Team Leader" ||
                              a.Name === "Invite Engineering" ||
                              a.Name === "Invite Document Controller"
                          )
                        ) {
                          props.setRowData(null);
                          props.setOpenModal(true);
                          setDisabled(false);
                          break;
                        } else {
                          setDisabled(true);
                        }
                      } else {
                        setDisabled(true);
                      }
                    }
                  }
                else {
                  props.setRowData(null);
                  props.setOpenModal(true);
                }
              }}
            >
              Add
            </button>
            {props.ShowGoBack ? (
              <button
                className={classes["back-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        {props.viewFilters ? (
          <Grid item md={10} container direction={"row-reverse"}>
            <Grid
              item
              md={12}
              lg={12}
              marginTop={1}
              container
              direction={"row-reverse"}
            >
              <Grid item>
                <CustomSelect
                  value={status}
                  onChange={(option) => {
                    setStatus(option);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Status
                  </StyledOption>
                  {statuses.map((s) => (
                    <StyledOption key={s.Id} value={s.Id}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item>
                <CustomSelect
                  value={role}
                  onChange={(option) => {
                    setRole(option);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Roles
                  </StyledOption>
                  {roles.map((s) => (
                    <StyledOption key={s.Id} value={s.Id}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
              {role || status ? (
                <Grid item marginTop={0.6}>
                  <button
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setRole(0);
                      setStatus(0);
                    }}
                    className={classes["Rectangle-1224"]}
                  >
                    Clear filters
                  </button>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {props.viewSearch ? (
          <Grid
            item
            md={2}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 4, xs: 0 }}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                onChange={inputHandler}
                placeholder="Search this list…"
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      fontSize="small"
                      htmlColor={"#707070"}
                      style={{ paddingLeft: 5 }}
                    />
                  ),
                }}
                sx={{
                  fontFamily: "Muli",
                  backgroundColor: "white",
                  borderRadius: 1,
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 0,
                    borderColor: "white",
                  },
                }}
              />
            </Box>
          </Grid>
        ) : (
          <></>
        )}

        <div
          style={{
            height: props.tableHeight,
            width: "98%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={teamMemberTable ? teamMemberTable : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "hidden",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(172 201 224)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingTable}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No Team Members to display
                </Stack>
              ),
            }}
          />
        </div>
        {props.showViewAll ? (
          <Grid
            item
            xs={11.75}
            container
            bgcolor={"white"}
            justifyContent={"center"}
          >
            <span
              style={{
                color: "#0674b9",
                fontFamily: "Muli",
                fontSize: "13px",
              }}
            >
              View All
            </span>
          </Grid>
        ) : (
          ""
        )}

        {localStorage.getItem("IsSubscribedBefore") === "true" ? (
          ""
        ) : (
          <Menu
            id="more-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock
          >
            <MenuItem
              onClick={(event) => {
                if (
                  localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                  !UserPermissions?.find((x) => x.Name === "Admin") &&
                  !UserPermissions?.find(
                    (x) => x.Name === "Engineering Manager"
                  ) &&
                  !UserPermissions?.find((x) => x.Name === "Team Leader")
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Team Member"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Edit"
                          )
                        ) {
                          props.setRowData(rowData);
                          props.setOpenModal(true);
                          handleClose();
                          setDisabled(false);
                          break;
                        } else {
                          setDisabled(true);
                        }
                      } else {
                        setDisabled(true);
                      }
                    }
                  }
                } else {
                  props.setRowData(rowData);
                  props.setOpenModal(true);
                  handleClose();
                }
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
                display: "block",
              }}
            >
              Edit Role
            </MenuItem>
            {rowData?.row.StatusId === INVITED ? (
              <MenuItem
                onClick={() => {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Team Member"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Resend Invite"
                          )
                        ) {
                          resendTMInvitation(rowData.id).then(() => {
                            setPreInvite(true);
                            props.setModify(!props.modify);
                            handleClose();
                          })
                          setDisabled(false);
                          break;
                        } else {
                          setDisabled(true);
                        }
                      } else {
                        setDisabled(true);
                      }
                    }
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 150,
                  display: "block",
                }}
              >
                Re-Invite
              </MenuItem>
            ) : rowData?.row.StatusId === ACTIVE ? (
              <MenuItem
                onClick={() => {
                  if (
                    localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Team Leader")
                  )
                    for (let i = 0; i < UserPermissions?.length; i++) {
                      for (
                        let j = 0;
                        j < UserPermissions[i].Permissions.length;
                        j++
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Name ===
                          "Team Member"
                        ) {
                          if (
                            UserPermissions[i].Permissions[j].Actions.find(
                              (a) => a.Name === "Suspend"
                            )
                          ) {
                            changeTMStatus(rowData.id, SUSPENDED).then(() => {
                              props.setModify(!props.modify);
                              handleClose();
                            })
                            setDisabled(false);
                            break;
                          } else {
                            setDisabled(true);
                          }
                        } else {
                          setDisabled(true);
                        }
                      }
                    }
                  else {
                    changeTMStatus(rowData.id, SUSPENDED).then(() => {
                      props.setModify(!props.modify);
                      handleClose();
                    })
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                }}
              >
                Suspend
              </MenuItem>
            ) : rowData?.row.StatusId === SUSPENDED ||
              (rowData?.row.StatusId === ARCHIVED && preInvite === false) ? (
              <MenuItem
                onClick={() => {
                  if (
                    localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Team Leader")
                  )
                    for (let i = 0; i < UserPermissions?.length; i++) {
                      for (
                        let j = 0;
                        j < UserPermissions[i].Permissions.length;
                        j++
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Name ===
                          "Team Member"
                        ) {
                          if (
                            UserPermissions[i].Permissions[j].Actions.find(
                              (a) => a.Name === "Reactivate"
                            )
                          ) {
                            changeTMStatus(rowData.id, ACTIVE).then(() => {
                              props.setModify(!props.modify);
                              handleClose();
                            })
                            setDisabled(false);
                            break;
                          } else {
                            setDisabled(true);
                          }
                        } else {
                          setDisabled(true);
                        }
                      }
                    }
                  else {
                    changeTMStatus(rowData.id, ACTIVE).then(() => {
                      props.setModify(!props.modify);
                      handleClose();
                    })
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                }}
              >
                Re-Activate
              </MenuItem>
            ) : rowData?.row.StatusId === ARCHIVED && preInvite === true ? (
              <MenuItem
                onClick={() => {
                  if (
                    localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Team Leader")
                  )
                    for (let i = 0; i < UserPermissions?.length; i++) {
                      for (
                        let j = 0;
                        j < UserPermissions[i].Permissions.length;
                        j++
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Name ===
                          "Team Member"
                        ) {
                          if (
                            UserPermissions[i].Permissions[j].Actions.find(
                              (a) => a.Name === "Resend Invite"
                            )
                          ) {
                            changeTMStatus(rowData.id, INVITED).then(() => {
                              resendTMInvitation(rowData.id).then(() => {
                                props.setModify(!props.modify);
                                setPreInvite(false);
                                handleClose();
                              })
                            });
                            setDisabled(false);
                            break;
                          } else {
                            setDisabled(true);
                          }
                        } else {
                          setDisabled(true);
                        }
                      }
                    }
                  else {
                    changeTMStatus(rowData.id, INVITED).then(() => {
                      resendTMInvitation(rowData.id).then(() => {
                        props.setModify(!props.modify);
                        setPreInvite(false);
                        handleClose();
                      })
                    });
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                }}
              >
                Re-Invite
              </MenuItem>
            ) : (
              <div></div>
            )}
            {rowData?.row.StatusId === ARCHIVED ? (
              <div></div>
            ) : (
              <MenuItem
                onClick={() => {
                  if (localStorage.getItem("isTeamMember") === "true")
                    for (let i = 0; i < UserPermissions?.length; i++) {
                      for (
                        let j = 0;
                        j < UserPermissions[i].Permissions.length;
                        j++
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Name ===
                          "Team Member"
                        ) {
                          if (
                            UserPermissions[i].Permissions[j].Actions.find(
                              (a) => a.Name === "Archive/Close"
                            )
                          ) {
                            if (rowData?.row.StatusId === INVITED) {
                              changeTMStatus(rowData.id, ARCHIVED).then(() => {
                                setPreInvite(true);
                                props.setModify(!props.modify);
                                handleClose();
                              })
                            } else {
                              changeTMStatus(rowData.id, ARCHIVED).then(() => {
                                setPreInvite(false);
                                props.setModify(!props.modify);
                                handleClose();
                              })
                            }
                            setDisabled(false);
                            break;
                          } else {
                            setDisabled(true);
                          }
                        } else {
                          setDisabled(true);
                        }
                      }
                    }
                  else {
                    if (rowData?.row.StatusId === INVITED) {
                      changeTMStatus(rowData.id, ARCHIVED).then(() => {
                        setPreInvite(true);
                        props.setModify(!props.modify);
                        handleClose();
                      })
                    } else {
                      changeTMStatus(rowData.id, ARCHIVED).then(() => {
                        setPreInvite(false);
                        props.setModify(!props.modify);
                        handleClose();
                      })
                    }
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                }}
              >
                Archive
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                props.setRowData(rowData);
                props.setViewAssignmentLog(true);
                handleClose();
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
                display: "block",
              }}
            >
              View Assignment Log
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.setRowData(rowData);
                props.setViewDetails(true);
                handleClose();
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
                display: "block",
              }}
            >
              View Details
            </MenuItem>
            {UserPermissions?.find((x) => x.Name === "Admin" )?
              <MenuItem
                onClick={() => {
                  deleteTM(rowData.id).then((x) => {
                    setModify(!modify)
                  })
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                }}
              >
                Delete
              </MenuItem>
              :
              <></>}

          </Menu>
        )}
      </Grid>
    </Grid>
  );
}
