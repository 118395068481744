import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import uploadIcon from "../../../Assets/Images/Settings/uploadImg.png";
import FileIcon from "../../../Assets/Images/Table/file.png";
import DeleteIcon from "../../../Assets/Images/Projects/delete.png";
import EyeIcon from "../../../Assets/Images/Projects/Eye.png";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownLoadIcon from "../../../Assets/Images/Projects/DownloadIcon.png";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteFileModal from "./DeleteFileModal";
import * as pileCubesConst from "../../../../StatusConst";
import * as activityServices from "../../../Services/activities-services.proxy";
import { Tooltip } from "@mui/material";
import * as forgeServices from "../../../Services/forge-services.proxy";
import { useNavigate } from "react-router-dom";
const finalizeUpload = forgeServices.finalizeUpload;
const getSignedUrl = forgeServices.getSignedUrl;
const binaryFile = forgeServices.binaryFile;
const getTranslationStatus = forgeServices.getTranslationStatus;
const updateForgeFile = activityServices.updateForgeFile;
const tMStartActivity = activityServices.tMStartActivity;
const tMUploadFile = activityServices.tMUploadFile;
const tMDeleteFile = activityServices.tMDeleteFile;
const saveActivitiesFiles = activityServices.saveActivitiesFiles;
const tMDeleteFileAfterSave = activityServices.tMDeleteFileAfterSave;
const tMSubmitActivity = activityServices.tMSubmitActivity;
const re = /(?:.([^.]+))?$/;
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const TMActivity = (props) => {
  const navigate = useNavigate();
  const [fileCategory, setFileCategory] = useState(0);
  const [fileCategoryError, setFileCategoryError] = useState(null);
  const [addFile, setAddFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosenId, setChosenId] = useState("");
  const [open, setOpen] = useState(false);
  const [filesSaved, setFilesSaved] = useState(false);
  const [filesIDS, setFilesIDS] = useState([]);
  const [progress, setProgress] = useState(10);
  const [categoryChosen, setCategoryChosen] = useState(true);
  const [cantStartActivity, setCantStartActivity] = useState(false);
  const [translationNotCompleted, setTranslationNotCompleted] = useState(false);
  const [translationProgess, setTranslationProgess] = useState(null);

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  const tMStartTheActivity = () => {
    tMStartActivity(props.activityId).then((x) => {
      if (x.status === 409) {
        setCantStartActivity(true);
      } else {
        props.setModify(!props.modify);
      }
    });
  };
  const uploadForge = (file) => {
    getSignedUrl(file.name).then((res) => {
      binaryFile(res.urls[0], file, setProgress).then((x) => {
        finalizeUpload(file.name, res.uploadKey).then((response) => {
          updateForgeFile(file.name, response.data, file.size).then(
            (FileId) => {
              let tempIDS = filesIDS;
              setFilesIDS(tempIDS.concat(file.name));
              let tempFiles = props.files;
              props.setFiles(
                tempFiles.concat({
                  FileId: FileId,
                  CategoryId: fileCategory,
                  DisplayName: file.name,
                  size: file.size,
                })
              );
              // setCategoryChosen(fal);
              setLoading(false);
            }
          );
        });
      });
    });
  };
  const GetFileExtension = (fileName) => {
    return fileName.substring(fileName.lastIndexOf(".")).toLowerCase();
  };
  const handleFileChange = (e) => {
    let fileext = GetFileExtension(e.target.files[0].name);

    if (e.target.files[0]) {
      if ((fileCategory === 1 || fileCategory === 2) && fileext !== ".sdi") {
        uploadForge(e.target.files[0]);
      } else {
        tMUploadFile(e.target.files[0], props.subProjectId, setProgress).then(
          (x) => {
            setLoading(false);
            let tempIDS = filesIDS;
            setFilesIDS(tempIDS.concat(x.FileId));
            let tempFiles = props.files;
            props.setFiles(
              tempFiles.concat({
                FileId: x.FileId,
                CategoryId: fileCategory,
                DisplayName: x.DisplayName,
                Type: re.exec(e.target.files[0].name)[1],
                Size: e.target.files[0].size,
              })
            );
            // setCategoryChosen(true);
          }
        );
      }
    }
  };
  const handleFileDelete = (fileIdToDelete) => {
    tMDeleteFileAfterSave(fileIdToDelete).then((x) => {
      let tempIDS = filesIDS;
      tempIDS = tempIDS.filter(function (item) {
        return item.Id !== fileIdToDelete;
      });
      setFilesIDS(tempIDS);
      let tempFiles = props.files;
      tempFiles = tempFiles.filter(function (item) {
        return item.Id !== fileIdToDelete;
      });
      props.setFiles(tempFiles);
    });
  };
  const saveFiles = () => {
    saveActivitiesFiles(props.activityId, props.files).then((x) => {
      setFilesSaved(true);
      setTimeout(() => {
        setFilesSaved(false);
        setFilesIDS([]);
        setAddFile(false);
        props.setModify(!props.modify);
      }, 2000);
    });
  };
  const handleFileDeleteOnCancel = () => {
    let tempIDS = filesIDS;
    let tempFiles = props.files;
    filesIDS.map((fileID1) => {
      tMDeleteFile(fileID1, props.subProjectId).then((x) => { });

      tempIDS = tempIDS.filter(function (item) {
        return item !== fileID1;
      });

      tempFiles = tempFiles.filter(function (item) {
        return item.FileId !== fileID1;
      });
    });
    setFilesIDS(tempIDS);
    props.setFiles(tempFiles);
  };
  const submitTheActivity = () => {
    // saveFiles();
    saveActivitiesFiles(props.activityId, props.files).then((x) => {
      if (!x.status) {
        setFilesSaved(true);
        setTimeout(() => {
          setFilesSaved(false);
          setFilesIDS([]);
          setAddFile(false);
        }, 2000);
        tMSubmitActivity(
          props.activityId,
          null,
          pileCubesConst.ACTIVITY_SUBMIT_TO_TEAM_MEMBER
        ).then((x) => {
          props.setModify(!props.modify);
        });
      }

    });

  };
  return (
    <Grid container paddingBottom={5} paddingTop={2} direction={"column"}>
      {!props.actualStartDate &&
        localStorage.getItem("UserId") === props.assignedUserId ? (
        <Grid container>
          <Grid
            item
            className={classes["Path-1278"]}
            onClick={() => {
              tMStartTheActivity();
            }}
          >
            <button className={classes["startActivity"]}>
              Start the activity
            </button>
          </Grid>
          {cantStartActivity ? (
            <Grid
              item
              xs={6}
              paddingLeft={1}
              paddingTop={2}
              fontSize={15}
              fontFamily={"Muli"}
              color={"#ff0000"}
            >
              You can start the activity within the standard working hours and
              days that are set by your company.
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ) : (
        <></>
      )}
      {(props.actualStartDate &&
        localStorage.getItem("UserId") === props.assignedUserId) ||
        props.actualEndDate ? (
        <Grid item container direction={"column"} paddingTop={4}>
          <Grid item container direction={"row"}>
            {props.actualEndDate ? (
              <></>
            ) : (
              <Grid item xs={3}>
                <BlueTip
                  title={"You can upload multiple files"}
                  placement="right"
                  interactive
                >
                  <button
                    className={classes["UploadFile"]}
                    onClick={() => {
                      setAddFile(true);
                    }}
                  >
                    Upload File +
                  </button>
                </BlueTip>
              </Grid>
            )}

            {loading ? (
              <Grid
                item
                xs={6.5}
                paddingTop={2}
                borderRadius={2}
                border={"solid 1px #f3f3f3"}
                padding={2}
              >
                <LinearProgressWithLabel value={progress} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {addFile ? (
            <Grid item container direction={"row"} marginTop={4}>
              <Grid item md={2.93} xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Category
                </Grid>
                <Grid item xs={11} paddingTop={1}>
                  <CustomSelect
                    style={{ color: "#707070" }}
                    value={fileCategory}
                    onChange={(option) => {
                      setFileCategory(option);
                      setFileCategoryError(false);
                      setCategoryChosen(false);
                    }}
                    onBlur={(e) => {
                      setFileCategoryError(true);
                    }}
                  >
                    <StyledOption value={0} disabled>
                      Category
                    </StyledOption>
                    {props.fileCategories?.map((s) => (
                      <StyledOption key={s.Id} value={s.Id}>
                        {s.Name}
                      </StyledOption>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item className={classes["errorMessage"]}>
                  {fileCategoryError ? "Select a category" : <></>}
                </Grid>
              </Grid>
              <Grid item md={4.5} xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Upload File
                </Grid>
                <Grid item xs={11} marginTop={1}>
                  <BlueTip
                    title={"Please select category first"}
                    placement="bottom-start"
                    interactive={categoryChosen ? true : false}
                  >
                    <Button
                      fullWidth
                      component="label"
                      className={classes["fieldDesign"]}
                    >
                      <Grid item className={classes["fileText"]}>
                        <img
                          src={uploadIcon}
                          alt={"Upload"}
                          style={{ paddingRight: 20 }}
                        />
                        Upload File
                      </Grid>
                      <input
                        type="file"
                        accept={props.fileCategories?.find((c)=> c.Id === fileCategory)?.Name === "PDF"?".pdf":props.fileCategories?.find((c)=> c.Id === fileCategory)?.Name === "CAM"?".sdi":props.fileCategories?.find((c)=> c.Id === fileCategory)?.Name === "CAD"?".dwg,.dxf,.rvt":""}
                        hidden
                        disabled={categoryChosen}
                        onChange={(e) => {
                          handleFileChange(e);
                          setProgress(0);
                          setLoading(true);
                        }}
                      />
                    </Button>
                  </BlueTip>
                </Grid>
              </Grid>

              <Grid item md={2.5} xs={12}>
                <Grid item className={classes["field-title-disabled"]} xs={12}>
                  Type
                </Grid>
                <Grid
                  item
                  className={classes["Rectangle-1464"]}
                  xs={11}
                  marginTop={1}
                  height={"50px"}
                ></Grid>
              </Grid>
              <Grid item md={2} xs={12}>
                <Grid item className={classes["field-title-disabled"]} xs={12}>
                  File Size
                </Grid>
                <Grid
                  item
                  className={classes["Rectangle-1464"]}
                  xs={11}
                  marginTop={1}
                  height={"50px"}
                ></Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {props.actualEndDate ? (
            <Grid item className={classes["Activity-Details"]} paddingTop={3}>
              Submittals
            </Grid>
          ) : (
            ""
          )}
          {props.files?.map((file) => (
            <Grid
              item
              container
              direction={"row"}
              marginTop={4}
              key={file.FileId}
            >
              <Grid item md={2.93} xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Category
                </Grid>
                <Grid item xs={11} paddingTop={1}>
                  <CustomSelect
                    style={{ color: "#707070" }}
                    value={file.CategoryId}
                    onChange={(option) => {
                      file.CategoryId = option;
                    }}
                    disabled
                  >
                    <StyledOption value={0} disabled>
                      Category
                    </StyledOption>
                    {props.fileCategories?.map((s) => (
                      <StyledOption key={s.Id} value={s.Id}>
                        {s.Name}
                      </StyledOption>
                    ))}
                  </CustomSelect>
                </Grid>
              </Grid>
              <Grid item md={4.5} xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Uploaded File
                </Grid>
                <Grid
                  item
                  className={classes["Rectangle-1464"]}
                  xs={11}
                  marginTop={1}
                  height={"50px"}
                  container
                  direction={"row"}
                >
                  <Grid item xs={1}>
                    <img src={FileIcon} alt={"file"} />
                  </Grid>
                  <Grid
                    item
                    xs={7.5}
                    className={classes["FileName"]}
                    paddingTop={1}
                  >
                    {file.DisplayName}
                  </Grid>
                  {props.actualEndDate ? (
                    <></>
                  ) : (
                    <Grid
                      item
                      xs={1}
                      paddingTop={0.5}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        setChosenId(file.Id);
                        setOpen(true);
                      }}
                    >
                      <img src={DeleteIcon} alt={"file"} />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={1}
                    paddingTop={0.5}
                    onMouseOver={(e) => {
                      if (file.Extension !== '.sdi') {
                        e.target.style.cursor = "pointer";
                      }
                    }}
                    onClick={() => {
                      if (file.Extension !== '.sdi') {
                        if (file.Urn) {
                          getTranslationStatus(file.Urn, file.DisplayName).then(
                            (x) => {
                              if (x.status === "success") {
                                setTranslationNotCompleted(null);
                                navigate("/ForgeViewer", {
                                  state: {
                                    Urn: file.Urn,
                                    accessToken: props.viewAccessToken,
                                  },
                                });
                              } else {
                                setChosenId(file.FileId);
                                setTranslationProgess(x.progress);
                                setTranslationNotCompleted(true);
                              }
                            }
                          );
                        } else {
                          window.open(file.URL);
                        }
                      }

                    }}
                  >
                    {file.Extension !== '.sdi' ?
                      <VisibilityIcon htmlColor="#9fc5de" fontSize="small" />

                      :
                      <VisibilityOffIcon htmlColor="#9fc5de" fontSize="small" />
                    }

                  </Grid>
                  <Grid
                    item
                    xs={1}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      window.open(file.URL);
                    }}
                    paddingTop={0.5}
                  >
                    <img src={DownLoadIcon} alt={"file"} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={2.5} xs={12}>
                <Grid item className={classes["field-title-disabled"]} xs={12}>
                  Type
                </Grid>
                <Grid
                  item
                  className={classes["Rectangle-1464"]}
                  xs={11}
                  marginTop={1}
                  height={"50px"}
                >
                  {file.Extension}
                </Grid>
              </Grid>
              <Grid item md={2} xs={12}>
                <Grid item className={classes["field-title-disabled"]} xs={12}>
                  File Size
                </Grid>
                <Grid
                  item
                  className={classes["Rectangle-1464"]}
                  xs={11}
                  marginTop={1}
                  height={"50px"}
                >
                  {bytesToSize(file.Size)}
                </Grid>
              </Grid>
              {translationNotCompleted && file.FileId === chosenId ? (
                <Grid
                  item
                  xs={12}
                  fontFamily={"Muli"}
                  fontSize={"15px"}
                  color={"rgba(45, 164, 208, 0.5)"}
                >
                  {"The file is being translated by our engines. Progress: " +
                    translationProgess}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          ))}
          <Grid item container paddingTop={3}>
            {props.actualEndDate ? (
              <></>
            ) : (
              <Grid xs={1.5} item className={classes["Path-1278"]}>
                <button
                  className={classes["submitActivity"]}
                  disabled={props.files.length === 0}
                  onClick={() => {
                    submitTheActivity();
                  }}
                >
                  Submit
                </button>
              </Grid>
            )}

            <Grid
              xs={10.5}
              item
              container
              direction={"row-reverse"}
              gap={2}
              paddingTop={1}
              paddingRight={5.5}
            >
              {filesIDS.length > 0 ? (
                <>
                  <Grid item>
                    <button
                      className={classes["SaveButton"]}
                      disabled={props.files.length > 0 ? false : true}
                      onClick={() => {
                        saveFiles();
                      }}
                    >
                      Save
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      className={classes["BackButton"]}
                      onClick={() => {
                        handleFileDeleteOnCancel();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {filesSaved ? (
                <Grid
                  item
                  fontFamily={"Muli"}
                  fontSize={13}
                  color={"#49cc90"}
                  paddingTop={1}
                >
                  Files has been Saved
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <DeleteFileModal
        openModal={open}
        setOpenModal={setOpen}
        chosenId={chosenId}
        handleFileDelete={handleFileDelete}
      />
    </Grid>
  );
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color:#0674b9;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 17px;
    box-sizing: border-box;
    width: 100%;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    text-align: left;
    color: #bcbcbc;
    padding:13px;
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default TMActivity;
