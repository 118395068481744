import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import UserRolesTable from "./UserRolesTable";
import PermissionModal from "./PermissionModal";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import AddNewUser from "./AddNewUser";
import AddedUserRoles from "./AddedUserRoles";
import * as permissionServices from "../../../Services/permissions-group.proxy";
import * as permissionFunction from "../../../../ReusableFunctions";
import { useSelector } from "react-redux";

const getUserRolesList = permissionServices.getUserRolesList;
const getPermissionList = permissionServices.getPermissionList;
const CheckIfPermitted = permissionFunction.CheckIfPermitted;

const UserRolesTableView = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  useEffect(() => {
    CheckIfPermitted(UserPermissions, "User Role", "Add", setAddPermission);
    CheckIfPermitted(UserPermissions, "User Role", "Edit", setEditPermission);
  }, [UserPermissions]);
  const [openModal, setOpenModal] = useState(false);
  const [userRoleId, setUserRoleId] = useState(false);
  const [permissionList, setpermissionList] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [userRoleDescription, setuserRoleDescription] = useState("");
  const [role, setRole] = useState("");
  const [usersRoles, setUsersRoles] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [search, setSearch] = useState(null);
  const [field, setField] = useState(null);
  const [asc, setAsc] = useState(null);
  const [modify, setModify] = useState(false);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    let apiTest = true;
    if (userRoleId) {
      setLoading(true);
      getPermissionList(userRoleId).then((x) => {

        if (apiTest) {
          setModalTitle(x.Name);
          setuserRoleDescription(x.Description);
          if (x.Name === "Admin") {
            let tmp = x.Permissions
            tmp?.map((p) => {
              if (p.Name === "Team Member") {
                p.Actions?.map((a) => {
                  if (a.Name === "Delete")
                    a.IsSelected = false
                  forceUpdate();
                })
              }
            })
            setpermissionList(tmp);

          }
          else setpermissionList(x.Permissions)
        }
        setLoading(false);

      });
    }
    return () => {
      apiTest = false;
    };
  }, [userRoleId]);
  const data = {
    Search: search,
    SortString: {
      Field: field,
      Asc: asc,
    },
  };
  useEffect(() => {
    let apiTest = true;

    getUserRolesList(data).then((x) => {
      if (apiTest) {
        setUsersRoles(x.PermissionGroups);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [field, search, asc, modify]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid paddingLeft={5} paddingTop={5}>
        <UserRolesTable
          setOpenModal={setOpenModal}
          CardWidth={"80%"}
          RoleFontSize={"18px"}
          bgColorRoles={"transparent"}
          setUserRoleId={setUserRoleId}
          setShowEdit={setShowEdit}
        />
      </Grid>
      <Grid paddingTop={5} paddingLeft={"12%"}>
        <AddNewUser
          setOpenModal={setOpenModal}
          setUserRoleId={setUserRoleId}
          permissionList={permissionList}
          setShowAdd={setShowAdd}
          setModify={setModify}
          setpermissionList={setpermissionList}
          role={role}
          setRole={setRole}
          addPermission={addPermission}
          modify={modify}
        />
      </Grid>
      <Grid paddingTop={5} paddingLeft={"12%"}>
        <AddedUserRoles
          setOpenModal={setOpenModal}
          setUserRoleId={setUserRoleId}
          usersRoles={usersRoles}
          setShowEdit={setShowEdit}
          userRoleId={userRoleId}
          search={search}
          setSearch={setSearch}
          setField={setField}
          setAsc={setAsc}
          modify={modify}
          editPermission={editPermission}
          permissionList={permissionList}
        />
      </Grid>
      <PermissionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        userRoleId={userRoleId}
        permissionList={permissionList}
        setpermissionList={setpermissionList}
        setUserRoleId={setUserRoleId}
        modalTitle={modalTitle}
        showEdit={showEdit}
        userRoleDescription={userRoleDescription}
        setModalTitle={setModalTitle}
        setuserRoleDescription={setuserRoleDescription}
        modify={modify}
        setModify={setModify}
        showAdd={showAdd}
        setRole={setRole}
        loading={loading}
      />
    </div>
  );
};

export default UserRolesTableView;
