import React from 'react'
import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Grid, Menu, MenuItem, TextField } from '@mui/material';
import * as activityServices from "../../../Services/activities-services.proxy";
import ErrorModal from '../../../SharedComponents/ErrorModal';
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../Assets/Styles/ActivitiesTimeline.module.css";
import ActivityTimeline from './ActivityTimeline';
import * as StatusConst from "../../../../StatusConst";
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as scheduleServices from "../../../Services/schedule-services.proxy";
import { useSelector } from "react-redux";
import windowDimensions from "../../../SharedComponents/WindowDimension";

const getActivitiesTimeline = activityServices.getActivitiesTimeline;
const getUserSchedule = scheduleServices.getUserSchedule;

const ActivitiesTimelineView = (props) => {

  const navigate = useNavigate();
  const { windowWidth } = windowDimensions();
  const isMobile = windowWidth < 650 ? true : false

  //timeline Data
  const [groups, setGroups] = useState([]);
  const [nestedGroups, setNestedGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [updateNestedSubs, setUpdateNestedSubs] = useState(false);
  const [canCreateAct, setCanCreateAct] = useState(null);
  const [milestones, setMilestones] = useState([]);

  //lists
  const [allProjects, setAllProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [submittedToList, setSubmittedToList] = useState([]);
  const [statusesList, setStatusesList] = useState([]);

  //working days and hours
  const [weekendDays, setWeekendDays] = useState([]);
  const [nonWorkingHours, setNonWorkingHours] = useState([]);

  //filters
  const [selectedAssigneesIds, setSelectedAssigneesIds] = useState([]);
  const [submittedToIds, setSubmittedToIds] = useState([]);
  const [selectedStatusesIds, setSelectedStatusesIds] = useState([]);
  const [selectedProjectsIds, setSelectedProjectIds] = useState([]);
  const [openedCard, setopenedCard] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  //loading and error handling
  const [loading, setLoading] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modify, setModify] = useState(false);
  const [reRenderItems, setReRenderItems] = useState(false);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [subId, setSubId] = useState(null);
  const [phaseId, setPhaseId] = useState(null);
  const [phaseObject, setPhaseObject] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  useEffect(() => {
    if (UserPermissions?.find((x) => (x.Name === "Engineer" || x.Name === "Document Controller"))) {
      setCanCreateAct(false);
    }
    else setCanCreateAct(true)
  })

  useEffect(() => {
    props.setNaved(false);
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const listData = {
    ProjectIds: selectedProjectId ? [selectedProjectId] : [],
    StatusIds: selectedStatusesIds,
    AssigneeIds: selectedAssigneesIds,
    SubmittedTo: submittedToIds,
  }

  useEffect(() => {
    setLoading(true);
    getActivitiesTimeline(listData).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setAllProjects(x.AllProjects)
        setProjects(x.Projects);
        setStatuses(x.Statuses);
        setAssignees(x.Assignees);
        setSubmittedToList(x.SubmittedTo);
        setStatusesList(x.Statuses);
        setItems([]);
        setNestedGroups([]);
        setGroups([]);
        setMilestones([]);
        setReRenderItems(!reRenderItems);
        let arrayIDs = openedCard;
        // setopenedCard(arrayIDs.concat(x.Projects[0]?.Sections[0]?.Id));
        // setSubId(x.Projects[0]?.Phases[0]?.Subprojects[0]?.Id);
        if (!selectedProjectId) {
          console.log("tetsttt")
          setSelectedProjectId(x.Projects[0]?.Id);
          setSelectedProject(x.Projects[0]);
          setPhaseId(x.Projects[0]?.Sections[0]?.Id);
          setPhaseObject(x.Projects[0]?.Sections[0]);
          setReRenderItems(!reRenderItems)
        }
        else {
          let tmp = x.Projects?.find((p) => p.Id === selectedProjectId)
          setSelectedProject(tmp);
          // setPhaseId(tmp?.Sections[0]?.Id);
          // setPhaseObject(tmp?.Sections[0]);
          setReRenderItems(!reRenderItems)
        }

        let tmp = x.Statuses?.map((s) => ({
          id: s.Id,
          content: s.Name === "InProgress" ? "In Progress" : s.Name,
          style: "color: #101650; background-color: #d9d2d2;font-weight:bold;font-size:15px;text-align:center;"
        }))

        // const groupsMap = x.Projects[0].Sections[0].Sections?.map(group => {
        //   let backgroundColor;
        //   if (group.Name === StatusConst.DRAFT_ACTIVITY) {
        //     backgroundColor = '#d9d2d2';
        //   } else if (group.Name === StatusConst.PLANNED) {
        //     backgroundColor = 'rgba(95, 186, 71, 0.7)';
        //   } else if (group.Name === StatusConst.IN_PROGRESS) {
        //     backgroundColor = 'rgba(45, 164, 208, 0.5)';
        //   } else if (group.Name === StatusConst.SUBMITTED_INTERNAL_APPROVAL_1) {
        //     backgroundColor = 'rgba(225, 227, 25, 0.7)';
        //   } else if (group.Name === StatusConst.SUBMITTED_INTERNAL_APPROVAL_2) {
        //     backgroundColor = 'rgba(16, 22, 80, 0.3)';
        //   }
        //   else backgroundColor = 'rgba(16, 22, 80, 0.7)';

        //   // Return group object with custom style
        //   return {
        //     id: group.Id,
        //     content: group.Name,
        //     // style: `color: #101650; background-color: ${backgroundColor}; font-weight: bold; font-size: 15px; text-align: center;`
        //     style: `color: #101650; font-weight: bold; font-size: 13px; text-align: center;min-height:70px;padding-top:20px`
        //   };
        // });
        // console.log("groups map", groupsMap)
        // setGroups(groupsMap)
      }
      setLoading(false);

    })
  }, [selectedAssigneesIds, selectedProjectId, modify, submittedToIds, selectedStatusesIds])

  useEffect(() => {
    setLoading(true);
    getUserSchedule().then((x) => {
      setWeekendDays(x.WorkingDays?.filter((d) => d.IsSelected === false)?.map((dy) => dy.Name));

      if (x.StartTime && x.EndTime) {

        let startTime = parseInt(x.StartTime.charAt(0) === "0" ? x.StartTime.slice(1, 2) : x.StartTime.slice(0, 2));
        let endTime = parseInt(x.EndTime.charAt(0) === "0" ? x.EndTime.slice(1, 2) : x.EndTime.slice(0, 2));

        let workingHoursAr;
        workingHoursAr = Array.from({ length: endTime - (startTime) + 1 }, (_, index) => index + startTime);

        const nonWorkingHoursAr = Array.from({ length: 25 }, (_, index) => index)
          .filter(hour => !workingHoursAr.includes(hour));
        setNonWorkingHours(nonWorkingHoursAr);
      }
    })
  }, [])

  useEffect(() => {
    let phase = projects?.find((p) => p.Id === selectedProjectId)?.Sections?.find((ph) => ph.Id === phaseId);
    setPhaseObject(phase);
    mapGroupsAndItems(phase);

  }, [phaseId, reRenderItems])

  useEffect(() => {
    if (updateNestedSubs) {
      setNestedGroups(groups);
      setUpdateNestedSubs(false);
    }
  }, [groups, updateNestedSubs])

  // const mapItems = (subproject) => {
  //   let tmp = subproject.Activities?.map((a) => {
  //     let backgroundColor;
  //     if (a.StatusId === StatusConst.DRAFT_ID) {
  //       backgroundColor = '#d9d2d2';
  //     } else if (a.StatusId === StatusConst.PLANNED_ID) {
  //       backgroundColor = 'rgba(95, 186, 71, 0.7)';
  //     } else if (a.StatusId === StatusConst.IN_PROGRESS_ID) {
  //       backgroundColor = 'rgba(45, 164, 208, 0.5)';
  //     } else if (a.StatusId === StatusConst.SUBMITTED_INTERNAL_APPROVAL_1_ID) {
  //       backgroundColor = 'rgba(225, 227, 25, 0.7)';
  //     } else if (a.StatusId === StatusConst.SUBMITTED_INTERNAL_APPROVAL_2_ID) {
  //       backgroundColor = 'rgba(16, 22, 80, 0.3)';
  //     }
  //     else backgroundColor = 'rgba(16, 22, 80, 0.7)';
  //     return {
  //       id: a.Id,
  //       group: a.StatusId,
  //       content: a.Code,
  //       // 2024-03-26T08:00:00
  //       start: new Date(a.StartDate),
  //       end: new Date(a.EndDate),
  //       style: `color: #101650; background-color: ${backgroundColor}; font-size: 13px; text-align: center;border-radius:10px;font-weight:bold;border:0;`
  //     }
  //   })
  //   return tmp;
  // }

  const mapSubGroups = (subgroups) => {
    subgroups?.map((sg, index) => {
      if (sg.Sections.length > 0) {
        if (!groups.find((gr) => gr.id === sg.Id)) {
          // let tmp = groups;
          // tmp.push({
          //   id: sg.Id,
          //   content: sg.Name,
          //   style: `color: #101650; font-weight: bold; font-size: 13px; text-align: center;min-height:70px;padding-top:20px`,
          //   nestedGroups: sg.Sections?.map((i) => i.Id)

          // })
          // setGroups(tmp)
          let groupObject = {
            id: sg.Id,
            content: sg.Name + " (" + sg.ActivitiesCount + ")",
            style: `color: #101650; font-weight: bold; font-size: 13px; text-align:center;min-height:70px;padding:20px;border-color:#f5f5f5`,
            nestedGroups: sg.Sections?.map((i) => i.Id),
            showNested: true,
          }
          setGroups(prevItems => [...prevItems, groupObject]);
          let milestoneObject = {
            id: sg.Id + 'M',
            date: sg.EndDate ? sg.EndDate.slice(0, 9) : null,
            message: "End Date of " + sg.Name
          }
          if (!milestones.find((m) => m.date === milestoneObject.date)) setMilestones(prevItems => [...prevItems, milestoneObject])
          mapItems(sg)
        }
        mapSubGroups(sg.Sections)
      }
      else {
        if (!groups.find((gr) => gr.id === sg.Id)) {
          // let tmp = groups;
          // tmp.push({
          //   id: sg.Id,
          //   content: sg.Name,
          //   style: `color: #101650; font-weight: bold; font-size: 13px; text-align: center;min-height:70px;padding-top:20px`,
          // })
          // setGroups(tmp)
          let groupObject = {
            id: sg.Id,
            content: sg.Name + " (" + sg.ActivitiesCount + ")",
            style: `color: #101650; font-weight: bold; font-size: 13px; text-align:center;min-height:70px;padding:20px;border-color:#f5f5f5`,
            // nestedGroups: sg.Sections?.map((i) => i.Id)
          }
          setGroups(prevItems => [...prevItems, groupObject]);
          let milestoneObject = {
            id: sg.Id + 'M',
            date: sg.EndDate?.slice(0, 9),
            message: "End Date of " + sg.Name
          }
          if (!milestones.find((m) => m.date === milestoneObject.date)) setMilestones(prevItems => [...prevItems, milestoneObject])
          mapItems(sg)
        }
        if (index === subgroups.length - 1) {
          setUpdateNestedSubs(true);
        }
      }
    })
  }

  const mapItems = (subproject) => {
    subproject.Activities?.map((a) => {
      let backgroundColor;
      if (a.StatusId === StatusConst.DRAFT_ID) {
        backgroundColor = '#d9d2d2';
      } else if (a.StatusId === StatusConst.PLANNED_ID) {
        backgroundColor = 'rgba(95, 186, 71, 0.7)';
      } else if (a.StatusId === StatusConst.IN_PROGRESS_ID) {
        backgroundColor = 'rgba(45, 164, 208, 0.5)';
      } else if (a.StatusId === StatusConst.SUBMITTED_INTERNAL_APPROVAL_1_ID) {
        backgroundColor = 'rgba(225, 227, 25, 0.7)';
      } else if (a.StatusId === StatusConst.SUBMITTED_INTERNAL_APPROVAL_2_ID) {
        backgroundColor = 'rgba(16, 22, 80, 0.3)';
      }
      else backgroundColor = 'rgba(16, 22, 80, 0.7)';
      if (!isNaN(new Date(a.StartDate)) && !isNaN(new Date(a.EndDate))) {
        let itemObject = {
          id: a.Id,
          group: subproject.Id,
          content: a.Code,
          statusId: a.StatusId,
          // 2024-03-26T08:00:00
          // start: new Date(a.StartDate),
          isCreator: a.IsCreator,
          start: a.StartDate,
          end: a.EndDate,
          style: `color: #101650; background-color: ${backgroundColor}; font-size: 13px; text-align: center;border-radius:10px;font-weight:bold;border:0;cursor:pointer`,
        }
        setItems(prevItems => [...prevItems, itemObject]);
      }
    })
  }

  const mapGroupsAndItems = (phase) => {
    phase?.Sections?.map((group, index) => {
      if (group.Sections.length > 0) {
        if (!groups.find((gr) => gr.id === group.Id)) {
          let groupObject = {
            id: group.Id,
            content: group.Name + " (" + group.ActivitiesCount + ")",
            style: `color: #101650; font-weight: bold; font-size: 13px; text-align:center;min-height:70px;padding:20px;border-color:#f5f5f5`,
            nestedGroups: group.Sections?.map((i) => i.Id),
            showNested: true,
          }
          setGroups(prevItems => [...prevItems, groupObject]);
          let milestoneObject = {
            id: group.Id + 'M',
            date: group.EndDate ? group.EndDate.slice(0, 9) : null,
            message: "End Date of " + group.Name
          }
          if (!milestones.find((m) => m.date === milestoneObject.date)) setMilestones(prevItems => [...prevItems, milestoneObject])
          mapItems(group)
        }
        mapSubGroups(group.Sections);
      }
      else {
        if (!groups.find((gr) => gr.id === group.Id)) {
          let groupObject = {
            id: group.Id,
            content: group.Name + " (" + group.ActivitiesCount + ")",
            style: `color: #101650; font-weight: bold; font-size: 13px; text-align:center;min-height:70px;padding:20px;border-color:#f5f5f5`,
          }
          setGroups(prevItems => [...prevItems, groupObject]);
          let milestoneObject = {
            id: group.Id + "M",
            date: group.EndDate?.slice(0, 9),
            message: "End Date of " + group.Name
          }
          if (!milestones.find((m) => m.date === milestoneObject.date)) setMilestones(prevItems => [...prevItems, milestoneObject])
          mapItems(group)
        }
        if (index === phase?.Sections?.length - 1) {
          setUpdateNestedSubs(true);
        }
      }
    });
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        width: isMobile ? "200%" : "100%"

      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loading}
      >
        <CircularProgress />
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading ...
        </Grid>
      </Backdrop>
      <Grid container direction="column" padding={{ xs: 1, sm: 3 }} rowGap={1.5} >
        <Grid item container direction={{ xs: "column", sm: "row" }} >
          <Grid item className={classes["pageTitle"]} xs={6}>Timeline View</Grid>
          <Grid item container direction={{ xs: "row", sm: "row-reverse" }} xs={12} md={6} columnGap={1} alignItems={{ xs: "flex-start", sm: "center" }} rowGap={2}>
            <Grid item xs={12} textAlign={{ xs: "left", sm: "right" }}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </button>
            </Grid>
            <Grid item xs={2.5} md={2} >
              <TextField
                select
                fullWidth
                label="Assignee"
                value={selectedAssigneesIds}
                InputProps={{
                  style: {
                    fontFamily: "Muli",
                    height: "45px",
                    color: "#707070"
                  },
                }}
                SelectProps={{
                  multiple: true,
                  MenuProps: {
                    disableScrollLock: true, sx: { height: "150px", fontFamily: "Muli", color: "#707070" },
                  },
                }}
                onChange={(e, addedValue) => {
                  setSelectedAssigneesIds(prevState => {
                    const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                    if (currentIndex !== -1) {
                      return prevState.filter((_, index) => index !== currentIndex);
                    } else {
                      return [...prevState, addedValue.props.value];
                    }
                  });
                  forceUpdate();
                }}
              >
                {assignees?.map((s, index) => (
                  <MenuItem key={index} value={s.Id} tyle={{ fontFamily: "Muli", fontSize: "12px", color: "#707070" }}>{s.Name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2.5} md={2.5} >
              <TextField
                select
                fullWidth
                value={submittedToIds}
                label="Submitted To"
                InputProps={{
                  style: {
                    fontFamily: "Muli",
                    height: "45px",
                    color: "#707070"
                  },
                }}
                SelectProps={{
                  multiple: true,
                  MenuProps: {
                    disableScrollLock: true, sx: { height: "150px" },
                  },
                }}
                onChange={(e, addedValue) => {
                  setSubmittedToIds(prevState => {
                    const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                    if (currentIndex !== -1) {
                      return prevState.filter((_, index) => index !== currentIndex);
                    } else {
                      return [...prevState, addedValue.props.value];
                    }
                  });
                  forceUpdate();
                }}
              >
                {submittedToList?.map((s, index) => (
                  <MenuItem key={index} value={s.Id} tyle={{ fontFamily: "Muli", fontSize: "12px", color: "#707070" }}>{s.Name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {isMobile ?
              <Grid item xs={10}></Grid>
              :
              <></>}
            <Grid item xs={2.5} md={2.5} >
              <TextField
                select
                fullWidth
                label="Status"
                value={selectedStatusesIds}
                InputProps={{
                  style: {
                    fontFamily: "Muli",
                    height: "45px",
                    color: "#707070"
                  },
                }}
                SelectProps={{
                  multiple: true,
                  MenuProps: {
                    disableScrollLock: true, sx: { height: "150px" },
                  },
                }}
                onChange={(e, addedValue) => {
                  setSelectedStatusesIds(prevState => {
                    const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                    if (currentIndex !== -1) {
                      return prevState.filter((_, index) => index !== currentIndex);
                    } else {
                      return [...prevState, addedValue.props.value];
                    }
                  });
                  forceUpdate();
                }}
              >
                {statusesList?.map((s, index) => (
                  <MenuItem key={index} value={s.Id} tyle={{ fontFamily: "Muli", fontSize: "12px", color: "#707070" }}>{s.Name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* {allProjects.length> 0? */}
            <Grid item xs={2.5} md={2.5} >
              <TextField
                select
                fullWidth
                label={"Project"}
                value={selectedProjectId || ""} InputProps={{
                  style: {
                    fontFamily: "Muli",
                    height: "45px",
                    color: "#707070"
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true, sx: { maxHeight: "200px" },
                  },
                }}
                onChange={(e, addedValue) => {
                  setSelectedProjectId(e.target.value)
                  setSelectedProject(projects?.find((p) => p.Id === e.target.value))
                  setPhaseId(projects?.find((p) => p.Id === e.target.value)?.Sections[0]?.Id);
                  setReRenderItems(!reRenderItems)
                  // setSelectedProjectIds(prevState => {
                  //   const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                  //   if (currentIndex !== -1) {
                  //     return prevState.filter((_, index) => index !== currentIndex);
                  //   } else {
                  //     return [...prevState, addedValue.props.value];
                  //   }
                  // });
                  // forceUpdate();
                }}
              >
                <MenuItem value={0} disabled style={{ fontFamily: "Muli" }}>Projects</MenuItem>
                {allProjects?.map((s, index) => (
                  <MenuItem key={index} value={s.Id} style={{ fontFamily: "Muli" }}>{s.Name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* :
            <></>
              } */}

            {selectedAssigneesIds.length > 0 || selectedProjectsIds.length > 0 || submittedToIds.length > 0 || selectedStatusesIds.length > 0 ?
              <Grid item
                style={{ fontFamily: "Muli", fontSize: "12px" }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.fontWeight = "bold"
                }}

                onMouseOut={(e) => {
                  e.target.style.fontWeight = "normal"
                }}
                onClick={() => {
                  setSelectedAssigneesIds([]);
                  setSelectedProjectIds([]);
                  setSubmittedToIds([]);
                  setSelectedStatusesIds([]);
                  setModify(!modify)
                }}>Clear Filters</Grid> :
              <></>}
          </Grid>
        </Grid>
        {/* {projects.map((p) => ( */}
        <Grid item container direction="column" rowGap={2} fontFamily="Muli" xs={12}>
          <Grid item style={{ boxShadow: "0 7px 19px 0 #2da4d0", padding: 7, borderRadius: 5 }} width="fit-content">{selectedProject?.Name}</Grid>
          {selectedProject?.Sections?.map((ph) => (
            <>
              <Grid item container direction="row" key={ph.Id} style={{ fontFamily: "Muli", color: "#101615" }} alignItems="center" columnGap={0.5}>
                <Grid item> {ph.Name}</Grid>
                {phaseId === ph.Id ?
                  <Grid item marginTop={0.5}
                    onMouseOver={(e) => e.target.style.cursor = "pointer"}
                    onClick={() => {
                      setGroups([]);
                      setNestedGroups([]);
                      setItems([]);
                      setPhaseId(null)
                    }}><VisibilityOffIcon htmlColor='#80acc8' fontSize='small' /></Grid>
                  :
                  <Grid item
                    marginTop={0.5}
                    onMouseOver={(e) => e.target.style.cursor = "pointer"}
                    onClick={() => {
                      setGroups([]);
                      setNestedGroups([]);
                      setItems([]);
                      setMilestones([]);
                      setPhaseId(ph.Id)
                    }}><VisibilityIcon htmlColor='#80acc8' fontSize='small' /></Grid>
                }
                {/* <Grid
                    item
                    className={classes["plus"]}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={(e) => {
                      if (openedCard?.includes(ph.Id)) {
                        let arrayIDs = openedCard;
                        arrayIDs = arrayIDs.filter(function (sub) {
                          return sub !== ph.Id;
                        });
                        setopenedCard(arrayIDs);
                      } else {
                        let arrayIDs = openedCard;

                        setopenedCard(arrayIDs.concat(ph.Id));
                      }
                    }}
                  >
                    {ph.Subprojects?.length > 0 ? (
                      openedCard?.includes(ph.Id) ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )
                    ) : (
                      <></>
                    )}
                  </Grid> */}

              </Grid>
              {/* {openedCard?.includes(ph.Id) && ph.Sections.length > 0 ?
                  <> */}
              {phaseId === ph.Id && phaseObject ?
                <Grid item overflowX="auto">
                  <ActivityTimeline
                    // subproject={s}
                    phase={phaseObject}
                    items={items}
                    groups={nestedGroups}
                    modify={modify}
                    setModify={setModify}
                    nonWorkingHours={nonWorkingHours}
                    weekendDays={weekendDays}
                    setGroups={setGroups}
                    setNestedGroups={setNestedGroups}
                    setItems={setItems}
                    canCreateAct={canCreateAct}
                    milestones={milestones} />
                </Grid>
                :
                <></>
              }
              {/* ph.Subprojects.map((s) => (
                  <>
                    <Grid item container direction="row" columnGap={1}>
                      <Grid item key={s.Id}> {s.Name}</Grid>
                      {subId === s.Id ?
                        <Grid item
                          onMouseOver={(e) => e.target.style.cursor = "pointer"}
                          onClick={() => setSubId(null)}><VisibilityOffIcon /></Grid>
                        :
                        <Grid item
                          onMouseOver={(e) => e.target.style.cursor = "pointer"}
                          onClick={() => setSubId(s.Id)}><VisibilityIcon /></Grid>
                      }
                    </Grid>
                   
                      <Grid item>
                        <ActivityTimeline
                          subproject={s}
                          items={[]}
                          groups={groups}
                          modify={modify}
                          setModify={setModify}
                          nonWorkingHours={nonWorkingHours}
                          weekendDays={weekendDays} />
                      </Grid>
                 
                  </>
                )) */}
              {/* </>
                  :
                  <></>} */}
            </>
          ))}
        </Grid>
        {/* ))} */}
        <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
      </Grid>
    </div>
  )
}

export default ActivitiesTimelineView