import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

import { styled } from "@mui/material/styles";
import { MenuItem, IconButton } from "@mui/material";

import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      height: "40px",
    },
  },
});

function Form2({
  continues,
  back,
  specialities,
  employeesRanges,
  countries,
  info,
}) {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [address, setAddress] = useState("");
  const [AddressError, setAddressError] = useState(false);
  const [AddressInvalid, setAddressInvalid] = useState(false);

  const [speciality, setSpeciality] = useState("");

  const [specialityError, setspecialityError] = useState(false);
  const [specialityInvalid, setspecialityInvalid] = useState(false);

  const [numOfEmployees, setNumOfEmployees] = useState("");

  const [numOfEmployeesError, setNumOfEmployeesError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [others, setOthers] = useState(info.ShowSpecialityOther);
  const [isoCodes, setisoCodes] = useState([]);
  const [fulled, setFulled] = useState(false);

  useEffect(() => {
    if (
      info.CompanyPhoneNumber === "+961" ||
      info.SpecialityId === "" ||
      info.Address === "" ||
      info.EmployeesRangeId === "" ||
      specialityError === true ||
      countryError === true ||
      AddressError === true ||
      AddressInvalid === true ||
      numOfEmployeesError === true ||
      specialityInvalid === true ||
      (info.ShowSpecialityOther === true && info.SpecialityOther === "")
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    for (var i = 0; i < countries.length; i++) {
      isoCodes[i] = countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });
  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox">
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12} direction={"row-reverse"}>
          <IconButton
            onClick={(e) => {
              navigate("/");
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              size="small"
              label="Speciality"
              inputProps={{ maxLength: 64 }}
              className="textField"
              value={info.SpecialityId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={specialityError}
              helperText={specialityError ? "Select a Speciality" : ""}
              onChange={(e) => {
                setSpeciality(e.target.value);
                info.SpecialityId = e.target.value;
                if (info.SpecialityId === "") {
                  setspecialityError(true);
                } else if (
                  info.SpecialityId ===
                  specialities.find((x) => x.Name === "Others").Id
                ) {
                  info.ShowSpecialityOther = true;

                  setSpeciality("");
                  setspecialityInvalid(true);
                } else {
                  setspecialityError(false);
                  setOthers(false);
                  setspecialityInvalid(false);
                  info.SpecialityOther = "";

                  info.ShowSpecialityOther = false;
                }
              }}
            >
              {specialities.map((option) => (
                <MenuItem key={option.Id} value={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {info.ShowSpecialityOther ? (
            <Grid item>
              <CssTextField
                label="Please Enter your Speciality"
                size="small"
                className="textField"
                value={info.SpecialityOther}
                InputLabelProps={{
                  style: {
                    fontFamily: "Muli",
                    fontSize: "16px",
                  },
                }}
                error={specialityError}
                helperText={specialityError ? "please enter a speciality" : ""}
                onChange={(e) => {
                  setSpeciality(e.target.value);
                  info.SpecialityOther = e.target.value;
                  if (info.SpecialityOther === "") {
                    setspecialityError(true);
                  } else {
                    setspecialityError(false);
                    setspecialityInvalid(false);
                    info.ShowSpecialityOther = true;
                  }
                }}
              />
            </Grid>
          ) : null}

          <Grid item>
            <CssTextField
              size="small"
              label="Employees"
              className="textField"
              value={info.EmployeesRangeId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={numOfEmployeesError}
              helperText={numOfEmployeesError ? "Select a country" : ""}
              onChange={(e) => {
                setNumOfEmployees(e.target.value);
                info.EmployeesRangeId = e.target.value;
                if (info.EmployeesRangeId === "") {
                  setNumOfEmployeesError(true);
                } else {
                  setNumOfEmployeesError(false);
                }
              }}
            >
              {employeesRanges.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid item>
            <CssTextField
              label="Address"
              size="small"
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={AddressError}
              value={info.Address}
              helperText={
                AddressError
                  ? AddressInvalid
                    ? "Adddress  must be less than 150 characters"
                    : "Enter Company Address"
                  : ""
              }
              onChange={(e) => {
                setAddress(e.target.value);
                info.Address = e.target.value;
                if (info.Address === "") {
                  setAddressError(true);
                  setAddressInvalid(false);
                } else if (
                  (info.Address !== "") &
                  (info.Address.length > 150)
                ) {
                  setAddressError(true);
                  setAddressInvalid(true);
                } else {
                  setAddressError(false);
                  setAddressInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item height={"5%"}>
            {fulled ? (
              <>
                <PhoneInput
                  placeholder="Enter phone number"
                  onlyCountries={isoCodes}
                  enableAreaCodes={true}
                  value={info.CompanyPhoneNumber}
                  onChange={(phone) => {
                    setCountry(phone);
                    info.CompanyPhoneNumber = phone;
                    if (
                      info.CompanyPhoneNumber === "" ||
                      info.CompanyPhoneNumber.length < 7 ||
                      info.CompanyPhoneNumber.length > 20
                    ) {
                      setCountryError(true);
                    } else {
                      setCountryError(false);
                    }
                  }}
                  country={localStorage.getItem("userCountry")?.toLowerCase()}
                  inputStyle={{
                    borderColor: "#707070",
                    width: "100%",
                    height: 40,
                    fontFamily: "Muli",
                    fontsize: "16px",
                  }}
                />
                {countryError ? (
                  <span
                    style={{
                      fontFamily: "Muli",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    Enter a valid Phone Number
                  </span>
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"} marginTop={16}>
          <Grid item md={8} xs={8}>
            <Button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#CED2E1";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
              }}
              onClick={(e) => {
                back(e);
                e.target.style.backgroundColor = "#fff";
              }}
              variant="contained"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                width: "71px",
                height: "32px",
                fontSize: "19px",
                fontFamily: "Muli",
                backgroundColor: "#fff",
                color: "rgba(16, 22, 80, 0.7)",
                border: "0px",
                marginBottom: 6,
              }}
            >
              Back
            </Button>
            <button
              className="buttonNext1"
              disabled={isDisabled}
              style={{ marginLeft: 20 }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                continues(e);
              }}
              variant="contained"
            >
              Next
            </button>
          </Grid>
          <Grid item md={4} xs={4} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 2 of 3</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form2;
