import { Backdrop, CircularProgress, Grid, MenuItem, TextField, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../Assets/Styles/home.css";
import pileYellow from "../../SAAS/Assets/Images/Notifications/pileYellow.png";
import pileBlue from "../../SAAS/Assets/Images/Notifications/pileBlue.png";
import pileGreen from "../../SAAS/Assets/Images/Notifications/pileGreen.png";
import contyellow from "../../SAAS/Assets/Images/Notifications/contyellow.png";
import contblue from "../../SAAS/Assets/Images/Notifications/contblue.png";
import contgreen from "../../SAAS/Assets/Images/Notifications/contgreen.png";
import DeleteNoti from "../../SAAS/Assets/Images/Notifications/deleteNoti.png";
import UnseenNoti from "../../SAAS/Assets/Images/Notifications/useenNoti.png";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import * as notificationsServices from "../../Services/notification-service.proxy";
import { useNavigate } from "react-router-dom";
import { userDataActions } from "../../store/index";
import { useDispatch } from "react-redux";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';

//import * as userDataServices from "../../Services/user-data-service.proxy";
//const getUserData = userDataServices.getUserData;
const getNotifications = notificationsServices.getNotifications;
const viewNotifications = notificationsServices.viewNotifications;
const deleteNoti = notificationsServices.deleteNoti;

const styles = {
  yourNotifications: {
    fontFamily: "Muli",
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.44,
    textAlign: "left",
    color: "#0674b9",
  },
  title: {
    fontFamily: "Muli",
    fontSize: 14,
    lineheight: 1.36,
    fontWeight: "bold",
    textAlign: "left",
    color: "#707070",
  },
  msg: {
    fontFamily: "Muli",
    fontSize: 14,
    lineheight: 1.36,
    overflow: "visible",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    textAlign: "left",
    color: "#707070",
  },
  date: {
    fontSize: 11,
    fontFamily: "Muli",
    fontStyle: "italic",
    lineHeight: 1.36,
    textAlign: "left",
    color: "#0674b9",
  },
};

export default function Notifications({ setRegister, setNaved }) {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    } else navigate("/login");
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const [modify, setModify] = useState(false);

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState([]);
  const [currentLine, setCurrentLine] = useState();
  const [allSelected, setAllSelected] = useState(false);

  const [loading, setLoading] = useState(false);
  const [deleteOnHover, setDeleteOnHover] = useState(false);
  const [notificationOnHover, setNotificationOnHover] = useState(false);
  const [filterOnHover, setFilterOnHover] = useState(false);

  const [pageSizes, setPageSizes] = useState([25, 50, 100])
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [isViewed, setIsViewed] = useState(null);
  const [total, setTotal] = useState(null);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber
    },
    Search: "",
    SortList: [],
    IsViewed: isViewed
  }

  useEffect(() => {
    setLoading(true);
    setSelectedNotificationsIds([]);
    getNotifications(listData).then((x) => {
      setNotifications(x.Notifications);
      setTotal(x.Total)
      setLoading(false);

    });
  }, [modify]);
  const viewingNotification = (idsAr, Id, IsViewed) => {
    const notViewedNoti = notifications.filter(obj => selectedNotificationsIds.includes(obj.Id) && !obj.IsViewed);
    let data = {
      Read: notViewedNoti?.length > 0 || idsAr?.length>0? true : false,
      NotificationsIds: idsAr ? idsAr : selectedNotificationsIds
    }
    viewNotifications(data).then((x) => {
      setModify(!modify);
      if (idsAr) dispatch(userDataActions.DecrementNotificationCounter());
      else {
        notViewedNoti?.map((n) => {
          dispatch(userDataActions.DecrementNotificationCounter());
        })
      }
      // if (!IsViewed) dispatch(userDataActions.DecrementNotificationCounter());
    });
  };
  const deleteNotification = (tmp, Id, IsViewed) => {
    setLoading(true);
    if (tmp) {
      let data = {
        Read: true,
        NotificationsIds: tmp
      }
      const notViewedNoti = notifications.filter(obj => tmp.includes(obj.Id) && !obj.IsViewed);
      viewNotifications(data).then((x) => {
        setLoading(false);
        notViewedNoti?.map((n) => {
          dispatch(userDataActions.DecrementNotificationCounter());
        })
        // if (!IsViewed) {
        // dispatch(userDataActions.DecrementNotificationCounter());
        deleteNoti(tmp).then(() => {
          setModify(!modify);
        });
        // }
        // else setModify(!modify);

      });
    }
    else {
      const notViewedNoti = notifications.filter(obj => selectedNotificationsIds.includes(obj.Id) && !obj.IsViewed);

      let data = {
        Read: true,
        NotificationsIds: selectedNotificationsIds
      }
      viewNotifications(data).then((x) => {
        setLoading(false);
        notViewedNoti?.map((n) => {
          dispatch(userDataActions.DecrementNotificationCounter());
        })
        // if (!IsViewed) {
        // dispatch(userDataActions.DecrementNotificationCounter());
        deleteNoti(selectedNotificationsIds).then(() => {
          setModify(!modify);
        });
        // }
        // else setModify(!modify);

      });
    }

  };
  // const updateNotification = () => {
  //   getUserData().then((x) => {
  //     dispatch(userDataActions.setUserData(x.Notifications));
  //     dispatch(
  //       userDataActions.setNotificationDisplayCounter(
  //         x.NotificationDisplayCounter
  //       )
  //     );
  //   });
  // };

  return (
    <div className="Rectangle779">
      <Grid container padding="3% 0 0 10%" spacing={1}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loading}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item xs={12} sx={styles.yourNotifications}>
          Your Notifications {loading ? <CircularProgress size="15px" /> : <></>}
        </Grid>
        <Grid item container xs={12} direction="row" columnGap={2} marginLeft={1.5} alignItems="center">
          <Grid
            item
            className={
              allSelected
                ? "CheckedBox"
                : "notCheckedBox"
            }
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer"
            }}
            onClick={(e) => {
              if (!allSelected) {
                setSelectedNotificationsIds(notifications.map((i => i.Id)))
                setAllSelected(true)
              }
              else {
                setSelectedNotificationsIds([])
                setAllSelected(false)
              }

            }}
          >
          </Grid>
          <Grid item
            onClick={(e) => {
              viewingNotification()
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer"
              setNotificationOnHover(true);
            }}
            onMouseOut={(e) => {
              setNotificationOnHover(false);
            }}>

            <Tooltip title=
              {
                (notifications.filter(obj => selectedNotificationsIds.includes(obj.Id) && !obj.IsViewed)?.length > 0) ? "Mark as Read" :
                  "Mark as Unread"}
            >
              {notificationOnHover ?
                <NotificationsIcon htmlColor="#0674B9" />
                :
                <NotificationsNoneIcon htmlColor="#0674B9" />

              }
            </Tooltip>
          </Grid>
          <Grid item
            onClick={(e) => {
              deleteNotification()
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              setDeleteOnHover(true)
            }}
            onMouseOut={() => {
              setDeleteOnHover(false);
            }}
          >
            {deleteOnHover ?
              <DeleteIcon htmlColor="#0674B9" />
              :
              <DeleteOutlineIcon htmlColor="#0674B9" />
            }
          </Grid>
          <Grid item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer"
              setFilterOnHover(true);
            }}
            onMouseOut={(e) => {
              setFilterOnHover(false);
            }}>
            <Tooltip title={
              <React.Fragment>
                <Grid item container direction="column" rowGap={1.5} marginTop={0.5}  >
                  <Grid item
                    onClick={(e) => {
                      setIsViewed(true);
                      setModify(!modify)
                    }}
                  >Read</Grid>
                  <Grid item
                    onClick={(e) => {
                      setIsViewed(false);
                      setModify(!modify)
                    }}
                  >Unread</Grid>
                </Grid></React.Fragment>
            }>


              {filterOnHover ?
                <FilterAltIcon htmlColor="#0674B9" />
                :
                <FilterAltOutlinedIcon htmlColor="#0674B9" />
              }
            </Tooltip>
          </Grid>
          <Grid item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer"
            }}
            onClick={() => {
              setIsViewed(null);
              setModify(!modify)
            }}
          >
            <RefreshIcon htmlColor="#0674B9" /></Grid>
        </Grid>

        <Grid item xs={10} container alignItems="center">
          {notifications.length > 0 ?
            notifications.map((n, index) => (
              <Grid
                item
                xs={12}
                key={index}
                gap={2}
                container
                bgcolor={n.IsViewed ? "#e9e7e7" : "#fff"}
                padding={1.5}
                alignItems="center"
              >
                <Grid
                  item
                  key={n.Id}
                  className={
                    selectedNotificationsIds.includes(n.Id)
                      ? "CheckedBox"
                      : "notCheckedBox"
                  }
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer"
                  }}
                  onClick={(e) => {
                    setCurrentLine(index);
                    if (selectedNotificationsIds.includes(n.Id)) {
                      setSelectedNotificationsIds(selectedNotificationsIds.filter(i => i !== n.Id))
                    }
                    else {
                      let tmp = selectedNotificationsIds;
                      tmp.push(n.Id)
                      // setSelectedNotificationsIds(tmp);
                      setSelectedNotificationsIds([...selectedNotificationsIds, n.Id]);
                    }

                  }}
                >
                </Grid>
                {
                  n.IsViewed === false ? (
                    <Grid item md={0.2} sm={0.5} xs={0.8}>
                      <img src={UnseenNoti} alt={"unseen"} />
                    </Grid>
                  ) : (
                    <Grid item md={0.2} sm={0.5} xs={0.8}></Grid>
                  )
                }
                <Grid item md={0.8} sm={1.5} xs={1.5}>
                  <img
                    src={
                      n.Link === null
                        ? index % 3 === 0
                          ? pileYellow
                          : (index + 2) % 3 === 0
                            ? pileBlue
                            : pileGreen
                        : index % 3 === 0
                          ? contyellow
                          : (index + 2) % 3 === 0
                            ? contblue
                            : contgreen
                    }
                    width={"40px"}
                    alt={"Noti"}
                  ></img>
                </Grid>
                <Grid
                  item
                  sm={7}
                  xs={5.5}
                  md={9}
                  container
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    if (n.Link) {
                      window.location.replace(n.Link);
                    }
                    if (n.IsViewed === false) {
                      console.log("fhjbklk")
                      viewingNotification([n.Id]);
                    }
                  }}
                >
                  <Grid item xs={12} sx={styles.title}>
                    {n.Name}
                  </Grid>
                  <Grid item xs={12} sx={styles.msg}>
                    {n.Description}
                  </Grid>
                  <Grid item xs={12} sx={styles.date}>
                    {new Date(n.CreationDate).toString().slice(0, 21)}
                  </Grid>
                </Grid>

                <Grid
                  item
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    let tmp = [];
                    tmp.push(n.Id)
                    console.log("tmpp", tmp)
                    setSelectedNotificationsIds(new Array(1).fill(n.Id))
                    console.log("ss", selectedNotificationsIds)
                    // deleteNotification(n.Id, n.IsViewed);
                    deleteNotification(tmp);
                  }}
                  md={1}
                  sm={1.5}
                  xs={2}
                >
                  <img src={DeleteNoti} alt={"delete"} />
                </Grid>
              </Grid>
            ))
            :
            <Grid item container fontFamily="Muli" justifyContent="center" alignItems="center" height="30vh"> No available notifications</Grid>}
        </Grid>

        <Grid item container direction="row" justifyContent="flex-end" xs={10} columnGap={1.5} alignItems="center" fontFamily="Muli" marginTop={1}>
          {/* <Grid item style={{
            fontFamily: "Muli",
            fontSize: "12px"
          }}>Rows per page</Grid>
          <Grid item>
            <TextField
              select
              value={pageSize}
              id="standard-basic" variant="standard"
              fullWidth
              InputProps={{
                style: {
                  color: "#0674b9",
                  height: "35px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              SelectProps={{
                MenuProps: {
                  // sx: { height: "150px" },
                  disableScrollLock: true, sx: { height: "150px" },

                },
              }}
              onChange={(e) => {
                setPageSize(e.target.value)
                setModify(!modify)
              }}
            >
              {pageSizes?.map((i) => (
                <MenuItem key={i} value={i} style={{ alignSelf: "center", justifySelf: "center" }}>{i}</MenuItem>

              ))}
            </TextField>
          </Grid> */}
          <Grid item>Page</Grid>
          <Grid item
            onMouseOver={(e) => {
              if (pageNumber > 1) e.target.style.cursor = "pointer"
              else e.target.style.cursor = "unset"
            }}
            onClick={() => {
              if (pageNumber > 1) {
                setPageNumber(pageNumber - 1)
                setModify(!modify)
              }

            }}
          >
            <KeyboardArrowLeftIcon fontSize="medium" style={{ color: "grey" }} />
          </Grid>
          <Grid item>{pageNumber} of {Math.ceil(total / pageSize)} </Grid>

          <Grid item
            onMouseOver={(e) => {
              if (total > pageSize && pageNumber < Math.ceil(total / pageSize)) e.target.style.cursor = "pointer"
              else e.target.style.cursor = "unset"
            }}
            onClick={() => {
              if (total > pageSize && pageNumber < Math.ceil(total / pageSize)) {
                setPageNumber(pageNumber + 1)
                setModify(!modify)
              }
            }}>
            <KeyboardArrowRightIcon fontSize="medium" style={{ color: "grey" }} /></Grid>
        </Grid>

      </Grid>
    </div >
  );
}