import { Grid, Modal, Box, Select, MenuItem, TextField, CircularProgress, Backdrop } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import classes from "../../../Assets/Styles/Sales-Order.module.css";

import {
  Excalidraw,
  exportToCanvas,
  exportToSvg,
  exportToBlob,
} from "@excalidraw/excalidraw";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";

const uploadFile = salesOrderServices.uploadFile;
const deleteFile = salesOrderServices.deleteFile;
const getLineShapeInputData = salesOrderServices.getLineShapeInputData

const resolvablePromise = () => {
  let resolve;
  let reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  promise.resolve = resolve;
  promise.reject = reject;
  return promise;
};
function ExcalidrawModal(props) {
  const [open, setOpen] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [shapeInputData, setShapeInputData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [syncingLoading, setSyncingLoading] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [requiredHelperMsg, setRequiredHelperMsg] = useState(null)
  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(true);
  };

  const handleClose = () => {
    setAddedProducts([])
    props.setOpenModal(false);
    props.setLineToAddproductOn(null);
    setOpen(false);
  };




  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  useEffect(() => {
    console.log("linee to change", props.lineToChange)
    console.log("linee to add product on", props.lineToAddproductOn)

    setAddedProducts(props.lineToChange?.Products)
    // if (props.factory && props.lineToChange) {
      setLoading(true);
      getLineShapeInputData(props.factoryId, props.lineToChange?.BarDiameter).then((x) => {
        setShapeInputData(x);
        setLoading(false);
      })
    // }


  }, [])


  const sceneDataNew = {
    elements: props.lineToAddproductOn?.elements,
    appState: {
      viewBackgroundColor: "#ffffff",
      isLibraryMenuDocked: true,
      isLibraryOpen: true,
      activeTool: {
        customType: null,
        lastActiveToolBeforeEraser: null,
        locked: false,
        type: "line",
        currentChartType: "bar",
        currentItemBackgroundColor: "transparent",
        currentItemEndArrowhead: "arrow",
        currentItemFillStyle: "hachure",
        currentItemFontFamily: 1,
        currentItemFontSize: 20,
        currentItemLinearStrokeSharpness: "round",
        currentItemOpacity: 100,
        currentItemRoughness: 1,
        currentItemStartArrowhead: null,
        currentItemStrokeColor: "#000000",
        currentItemStrokeSharpness: "sharp",
        currentItemStrokeStyle: "solid",
        currentItemStrokeWidth: 1,
      },
    },
    files: props.lineToAddproductOn?.files,
    scrollToContent: true,

    libraryItems: props.lineToAddproductOn?.libraryItems,
  };

  console.log("Library Items", props.lineToAddproductOn?.libraryItems)
  console.log("products ", props.products)

  const excalidrawRef = useRef(null);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    overflow: "auto",
    width: "75%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };
  const [viewModeEnabled, setViewModeEnabled] = useState(false);
  const [zenModeEnabled, setZenModeEnabled] = useState(false);
  const [gridModeEnabled, setGridModeEnabled] = useState(true);
  const [theme, setTheme] = useState("light");

  const initialStatePromiseRef = useRef({ promise: null });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/rocket.jpeg");
      const imageData = await res.blob();
      const reader = new FileReader();
      reader.readAsDataURL(imageData);

      reader.onload = function () {
        initialStatePromiseRef.current.promise.resolve(sceneDataNew);
      };
    };
    fetchData();

    const onHashChange = () => {
      const hash = new URLSearchParams(window.location.hash.slice(1));

      const libraryUrl = hash.get("addLibrary");
      if (libraryUrl) {
        excalidrawRef.current.importLibrary(libraryUrl, hash.get("token"));
      }
    };
    window.addEventListener("hashchange", onHashChange, false);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  const onLinkOpen = useCallback((element, event) => {
    const link = element.link;
    const { nativeEvent } = event.detail;
    const isNewTab = nativeEvent.ctrlKey || nativeEvent.metaKey;
    const isNewWindow = nativeEvent.shiftKey;
    const isInternalLink =
      link.startsWith("/") || link?.includes(window.location.origin);
    if (isInternalLink && !isNewTab && !isNewWindow) {
      // signal that we're handling the redirect ourselves
      event.preventDefault();
      // do a custom redirect, such as passing to react-router
      // ...
    }
  }, []);

  useEffect(() => {
    if (excalidrawRef.current) {
      excalidrawRef.current.updateScene(sceneDataNew);
    }
  }, [excalidrawRef]);



  const checkRequiredFields = () => {
    let empty = false;
    if (addedProducts?.length > 2) {
      empty = true;
      setRequiredHelperMsg(
        "You can add only 2 products"
      );
    }

    else {
      addedProducts.map((a) => {
        if (!a.SpliceTypeId) {
          empty = true;
          setRequiredHelperMsg(
            "You must fill all products' fields"
          );
        }
        if (a.Name === "Coupler") {
          if (!a.Id) {
            empty = true;
            setRequiredHelperMsg(
              "You must fill all products' fields"
            );
          }

        }
      })
    }


    if (!empty) {
      setRequiredHelperMsg(null);
      props.lineToChange.Products = addedProducts;
      forceUpdate();

      Sync();

    }

  }

  const Sync = async () => {
    setSyncingLoading(true);
    props.lineToChange.Shape.DataScene = JSON.stringify({
      elements: excalidrawRef.current.getSceneElements(),
      appState: excalidrawRef.current.appState,
      files: excalidrawRef.current.getFiles(),
      scrollToContent: true,

      libraryItems: props.lineToAddproductOn?.libraryItems,
    });
    forceUpdate();
    let tmp = JSON.stringify({
      elements: excalidrawRef.current.getSceneElements(),
      appState: excalidrawRef.current.appState,
      files: excalidrawRef.current.getFiles(),
      scrollToContent: true,

      libraryItems: props.lineToAddproductOn?.libraryItems,
    });
    const blob = await exportToBlob({
      elements: excalidrawRef.current.getSceneElements(),
      mimeType: "image/png",
      appState: excalidrawRef.current.appState,
      files: excalidrawRef.current.getFiles(),
    });
    // console.log(props.chosenId, props.chosenMemberId);
    props.members.map((mem) => {
      mem.Lines.map((line) => {
        if (
          line.OrderId === props.chosenId &&
          mem.OrderId === props.chosenMemberId
        ) {
          if (line.FileId) {
            deleteFile(line.FileId, props.projectId);
          }

          uploadFile(blob, props.projectId).then((x) => {
            setSyncingLoading(false);
            line.FileId = x.FileId;
            line.ShapeBlob = x.URL;

            forceUpdate();
            handleClose();
            console.log("memberss", props.members)
          });
        }
      });
    });


  };

  const checkItemsAdded = (currentElements) => {
    console.log("current Elements", currentElements)
    setAddedProducts([])

    const array1 = currentElements;
    const array2 = [];

    props.lineToAddproductOn?.libraryItems?.map(item => {
      array2.push(item.elements?.find((e) => e.type === 'text'))
    });
    let tmpPr = [];
    array1?.map((item1) => {
      let tmp = array2.find((item2) => (item2.type === item1.type) && (item2.originalText === item1.originalText))
      if (tmp) {
        if (tmp.originalText === 'CP') {
          let productObject = {
            Id: null,
            SpliceTypeId: null,
            ProductId: null,
          }
          tmpPr.push(productObject)
        }
        if (tmp.originalText === 'Thr') {
          let productObject = {
            Id: null,
            SpliceTypeId: null,
            ProductId: shapeInputData?.find((p) => p.Name === "Thread")?.Id,
          }
          tmpPr.push(productObject)
        }

      }
    })
    console.log("tmppR", tmpPr)
    setAddedProducts(tmpPr)
    if(tmpPr.length === 0) {
      props.lineToChange.Products = addedProducts;
      forceUpdate();
      Sync()
    }


    // props.lineToChange.Products = addedProducts;
    // forceUpdate();

    // Sync();

    // Fields to ignore for comparison
    // const fieldsToCheck = ['type', 'originalText'];

    // Function to check if two objects match on specified fields
    // function objectsMatchOnFields(obj1, obj2, fieldsToCheck) {
    //   for (const key in obj1) {
    //     if (fieldsToCheck.includes(key) && obj1[key] !== obj2[key]) {
    //       return false;
    //     }
    //   }

    //   return true;
    // }
    // Find matching items in array1 and array2
    // matchingItems = array1.map(item1 => {
    //   return array2.some(item2 => objectsMatchOnFields(item1, item2, fieldsToCheck));
    // });

    // matchingItems?.map((m) => {
    //   if (m.originalText === 'CP') addedProducts.push(props.products?.find((p) => p.Name === 'Coupler'))
    //   if (m.originalText === 'Thr') addedProducts.push(props.products?.find((p) => p.Name === 'Thread'))
    // })
    // console.log("addedProducts", addedProducts)


    // Fields to ignore for comparison
    // const fieldsToIgnore = ['id', 'seed', 'updated', 'version', 'x', 'y', 'boundElements', 'groupIds', 'points'];

    // Function to check if two objects match while ignoring specified fields
    // function objectsMatchIgnoringFields(obj1, obj2, fieldsToIgnore) {
    //   console.log("obj1", obj1)
    //   console.log("obj2", obj2)

    //   for (const key in obj1) {
    //     if (!fieldsToIgnore.includes(key) && obj1[key] !== obj2[key]) {
    //       console.log("false on", obj1 + " and " + obj2)
    //       return false;
    //     }
    //   }
    //   return true;
    // }

    // Find matching items in array1 and array2
    // const matchingItems = array1.filter(item1 => {
    //   return array2.some(item2 => objectsMatchIgnoringFields(item1, item2.elements[0], fieldsToIgnore));
    // });

    // console.log("matchingg", matchingItems);
  }

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} paddingBottom={10}>
        <Grid
          container
          padding={3}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
            }}
            open={loading}
          >
            <CircularProgress />

            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"rgb(25, 118, 210)"}
            >
              Loading Data...
            </Grid>
          </Backdrop>
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "18px",
              color: "#101650",
              fontWeight: "bold",
              alignContent: "center",
            }}
          >
            Add Product
          </Grid>
          <div
            className="excalidraw-wrapper"
            style={{ backgroundColor: "rgb(252 250 250)" }}
          >
            <Excalidraw
              ref={excalidrawRef}
              initialData={initialStatePromiseRef.current.promise}
              onChange={(elements, state, files) => {
                // console.log(elements)
                // console.log(excalidrawRef.current.getSceneElements());
                // checkItemsAdded(excalidrawRef.current.getSceneElements());

                // props.LineToChange.Shape.DataScene.elements = JSON.stringify(
                //   excalidrawRef.current.getSceneElements()
                // );
                // props.setDataElements(excalidrawRef.current.getSceneElements());
                // props.setDataFiles(excalidrawRef.current.getFiles());
              }}
              onLibraryChange={(libraryItems) => {
                // console.log(libraryItems);
              }}
              viewModeEnabled={viewModeEnabled}
              zenModeEnabled={zenModeEnabled}
              gridModeEnabled={gridModeEnabled}
              theme={theme}
              name="Shape"
              UIOptions={{
                canvasActions: { loadScene: false, export: false },
              }}
              onLinkOpen={onLinkOpen}
            />
          </div>

          <Grid
            item
            container
            xs={12}
            direction={"row-reverse"}
          >
            <button
              // disabled={
              //   props.lineToChange?.Products?.length === 0 ||
              //     !props.lineToChange?.Products || syncingLoading
              //     ? true
              //     : false
              // }
              disabled={syncingLoading}
              className={classes["Place-Order"]}
              onClick={() => {
                // Sync();
                setRequiredHelperMsg(null)
                checkItemsAdded(excalidrawRef.current.getSceneElements());

              }}
            >
              {syncingLoading ? "Loading ..." : "Sync Changes"}
            </button>
          </Grid>

          <Grid item container direction="row" columnGap={3}>
            {addedProducts ?
              addedProducts?.map((item, index) => (
                <Grid item container direction="column" key={index} rowGap={1} xs={4}>
                  <Grid item> Product {index + 1}</Grid>
                  {item.ProductId ?
                    <></>
                    :

                    <Grid item container direction="column" rowGap={0.5}>
                      <Grid item>
                        Coupler Series
                      </Grid>
                      <Grid item>
                        <TextField
                          value={item.Id}
                          fullWidth
                          select
                          SelectProps={{
                            MenuProps: { disableScrollLock: true },
                          }}
                          InputProps={{
                            style: {
                              color: "#707070",
                              height: "28px",
                              fontSize: 12,
                              fontFamily: "Muli",
                            },
                          }}
                          onChange={(e) => {
                            item.Id = e.target.value;
                            forceUpdate();

                          }}
                        >
                          {item.ProductId ? shapeInputData?.find((s) => s.Id === item.ProductId)?.Grades?.map((r) => (
                            <MenuItem value={r.Id} key={r.Id}>
                              {r.Name}
                            </MenuItem>
                          ))
                            :
                            shapeInputData?.find((s) => s.Name === "Coupler")?.Grades?.map((r) => (
                              <MenuItem value={r.Id} key={r.Id}>
                                {r.Name}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </Grid>
                    </Grid>
                  }

                  <Grid item container direction="column" rowGap={0.5}>
                    <Grid item>
                      Splicing Type
                    </Grid>
                    <Grid item>
                      <TextField
                        value={item.SpliceTypeId}
                        fullWidth
                        select
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onChange={(e) => {
                          item.SpliceTypeId = e.target.value;
                          forceUpdate();

                        }}
                      >
                        {item.ProductId ? shapeInputData?.find((s) => s.Id === item.ProductId)?.Grades?.find((g) => g.Id === item.Id)?.SpliceTypes?.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))
                          :
                          shapeInputData?.find((s) => s.Name === "Coupler")?.Grades?.find((g) => g.Id === item.Id)?.SpliceTypes?.map((r) => (
                            <MenuItem value={r.Id} key={r.Id}>
                              {r.Name}
                            </MenuItem>
                          ))
                        }
                        {/* {shapeInputData?.find((s) => s.Id === item.ProductId)?.Grades?.find((g) => g.Id === item.Id)?.SpliceTypes?.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))} */}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              ))
              :
              <></>}
            {addedProducts?.length > 0 ?
              <Grid
                item
                container
                xs={12}
                direction={"row-reverse"}
                alignItems="center"
              >
                <Grid item>
                  <button
                    // disabled={
                    //   props.lineToChange?.Products?.length === 0 ||
                    //     !props.lineToChange?.Products || syncingLoading
                    //     ? true
                    //     : false
                    // }
                    disabled={syncingLoading || disableConfirm}
                    className={classes["Place-Order"]}
                    onClick={() => {
                      checkRequiredFields();
                      // Sync();
                      // checkItemsAdded(excalidrawRef.current.getSceneElements());

                    }}
                  >
                    {syncingLoading ? "Loading ..." : "Confirm"}
                  </button>
                </Grid>
                {requiredHelperMsg ?
                  <Grid item style={{ fontFamily: "Muli", color: "red", fontSize: "14px", fontWeight: "normal", marginRight: "10px" }}>{requiredHelperMsg}</Grid>
                  :
                  <></>}
              </Grid>
              :
              <></>
            }
            {/* {requiredHelperMsg?
              <Grid
                item
                container
                xs={12}
                marginTop={2}
                direction={"row-reverse"} style={{fontFamily:"Muli",color:"red",fontSize:"14px",fontWeight:"normal"}}>{requiredHelperMsg}</Grid>
              :
              <></>
              } */}

          </Grid>
          <Grid item container xs={12} direction={"row"}>
            {/* <Grid item xs={6} md={4}>
              <Grid
                item
                xs={12}
                sx={{ fontFamily: "Muli", color: "#707070", color: "#101650" }}
              >
                Select the Products you added on the shape
              </Grid>
              <Select
                MenuProps={{ disableScrollLock: true }}
                value={props.lineToChange?.Products}
                fullWidth
                sx={{ height: "45px" }}
                multiple
                onChange={(e) => {
                  props.lineToChange.Products = e.target.value;
                  console.log("lineToChange", props.lineToChange)
                  console.log("lines", e.target.value)
                  forceUpdate();
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Added Products
                </MenuItem>
                {props.products?.map((r) => (
                  <MenuItem
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.Name}
                  </MenuItem>
                ))}
              </Select>
            </Grid> */}

          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ExcalidrawModal;
