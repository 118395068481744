import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, MenuItem, Select, FormControl, Button, CircularProgress, Backdrop } from "@mui/material";
import classes from "./SupplierModal.module.css";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Tooltip } from "@mui/material";
import * as SaasServices from "../../../Services/saas-services.proxy";
import plusYellow from "../../../Assets/Images/Inventory/plusYellow.png";
import infoTip from "../../../../Assets/Images/form/info.png";
import fileIcon from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const createSupplier = SaasServices.createSupplier;
const uploadProfileSupplier = SaasServices.uploadProfileSupplier;
const deleteFileSupplier = SaasServices.deleteFileSupplier;
const getSupplierInputData = SaasServices.getSupplierInputData;
const updateSupplier = SaasServices.updateSupplier;

const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "58%",
  height: "82%",
  overflow: "scroll",
  display: "block",
};

const SupplierModalModal = (props) => {

  const [open, setOpen] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const AccountType = localStorage.getItem("AccountType");
  const [supplierId, setSupplierId] = useState(null);

  const [code, setCode] = useState(null);
  const [codeError, setCodeError] = useState(false);

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(false);

  const [brandIds, setBrandIds] = useState([]);
  const [brandDataObject, setBrandDataObject] = useState();
  const [brandsList, setBrandsList] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [initialSelectedBrands, setInitialSelectedBrands] = useState([]);

  const [openBrandForm, setOpenBrandForm] = useState(false);
  const [brandName, setBrandName] = useState(null);
  const [brandCode, setBrandCode] = useState(null);

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState(false);

  const [website, setWebsite] = useState(null);
  const [websiteError, setWebsiteError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [countryError, setCountryError] = useState(false);

  const [description, setDescription] = useState(null);

  const [suppliesTypes, setSuppliesTypes] = useState([]);
  const [supplyError, setSupplyError] = useState(false);
  const [suppliesTypeId, setSuppliesTypeId] = useState(null);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);

  const [logo, setLogo] = useState(null);
  const [logoId, setLogoId] = useState(null);
  const [previousLogoId, setPreviousLogoId] = useState(null);

  const [creator, setCreator] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);

  const [isDisabled, setIsDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [uploadBeError, setUploadBeError] = useState(null);

  const [saveBeError, setSaveBeError] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.setRowData(null);
    setCode(null);
    setCodeError(false);
    setName(null);
    setNameError(false);
    setBrandIds([]);
    setEmail(null);
    setEmailError(false);
    setAddress(null);
    setAddressError(false);
    setWebsite(null);
    setWebsiteError(false);
    setPhoneNumber(null);
    setPhoneNumberError(false);
    setCountry(null);
    setCountryError(false);
    setDescription(null)
    setOpen(false);
    setLogoId(null);
    setFiles([]);
    setFileIds([]);
    setFile("");
    setLogo("");
    setSaveBeError(null);
    setSuppliesTypeId(null)
    props.setOpenModal(false);
  };

  useEffect(() => {
    console.log("testtt", supplierId)
    let apiTest = true;
    if (apiTest) {
      setLoadingData(true);
      if (supplierId) {
        getSupplierInputData(supplierId).then((x) => {
          if (apiTest) {
            setName(x.Supplier.Name);
            setCode(x.Supplier.Code);
            setAddress(x.Supplier.Address);
            setPhoneNumber(x.Supplier.PhoneNumber);
            setPhoneCode(x.Supplier.PhoneCode);
            setWebsite(x.Supplier.Website);
            setDescription(x.Supplier.Description);
            setEmail(x.Supplier.Email);
            setCountries(x.Countries);
            setBrandsList(x.Brands);
            setSuppliesTypes(x.SuppliesTypes);
            setSuppliesTypeId(x.Supplier.SuppliesTypeId)
            setBrandIds(x.Supplier.Brands?.map((obj) => obj.BrandId))
            setInitialSelectedBrands(x.Supplier.Brands);
            setCountry(x.Supplier.CountryId);
            setCreator(x.Supplier.CreatorName);
            setCreatedDate(x.Supplier.CreationTime);
            // if (x.Document) setFile(x.Supplier.Document);
            if (x.Supplier.Logo) {
              setLogo(x.Supplier.Logo)
              setLogoId(x.Supplier.Logo.FileId)
            }
            setFiles(x.Supplier?.Documents);
            x.Supplier.Documents?.map((d) => {
              let tempFileId = null;
              tempFileId = d.FileId;
              let fileData = { FileId: tempFileId };
              fileIds.push(fileData);
            });
          }
          setLoadingData(false);
        });
      } else {
        getSupplierInputData(null).then((x) => {
          if (apiTest) {
            setCountries(x.Countries);
            setBrandsList(x.Brands);
            setSuppliesTypes(x.SuppliesTypes)
          }
          setLoadingData(false);
        });
        setName(null);
        setCountry(null);
        setAddress(null);
        setFile(null);
        setLogo(null);
        setPhoneNumber(null);
        setWebsite(null);
        setDescription(null);
        setEmail(null);
        setCreator(null);
        setCreatedDate(null);
        setFiles([]);
        setFileIds([]);

      }
    }

    return () => {
      apiTest = false;
      setLoadingData(false)
    };
  }, [supplierId]);

  useEffect(() => {
    if (
      !code || !name || !phoneNumber || !phoneCode || !address || !website || !email || !country || !logoId || !suppliesTypeId
      || fileIds.length === 0 || brandIds.length === 0
      || codeError || nameError || addressError || websiteError || addressError || emailError || countryError || supplyError
    )
      setIsDisabled(true)
    else setIsDisabled(false);
  }, [code, name, phoneNumber, phoneCode, address, website, email, country, logoId, fileIds, brandIds, suppliesTypeId])

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  useEffect(() => {
    if (props.rowData) {
      console.log("supplierId", props.rowData.id)
      setSupplierId(props.rowData.id);
    } else {
      setSupplierId(null);
    }
  }, [props.rowData]);



  const listData =
  {
    Id: supplierId,
    Name: name,
    Code: code,
    PhoneNumber: phoneNumber,
    PhoneCode: phoneCode,
    Address: address,
    Website: website,
    Email: email,
    CountryId: country,
    Description: description,
    SuppliesTypeId: suppliesTypeId,
    Logo: {
      FileId: logoId,
    },
    Documents: fileIds,
    Brands: selectedBrands
  }

  const create = () => {
    setLoading(true);
    createSupplier(listData).then((x) => {
      if (x.status || x.statusCode) {
        setSaveBeError(x.error)
      }
      else {
        props.setModify(!props.modify);
        handleClose();
      }
      setLoading(false);
    })

  }

  const update = () => {
    setLoading(true);
    updateSupplier(listData).then((x) => {
      if (x.status || x.statusCode) {
        setSaveBeError(x.error)
      }
      else {
        props.setModify(!props.modify);
        handleClose();
      }
      setLoading(false);
    })

  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setBrandIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // useEffect(() => {
  //   console.log("brandsidss", brandIds)
  //   let tmp = brandsList.filter(item => brandIds.includes(item.Id))
  // }, [brandIds])



  const handleLogoChange = (e) => {
    setUploadBeError(null);
    if (previousLogoId) {
      deleteFileSupplier(previousLogoId);
    }
    if (e.target.files[0]) {
      if (logoId) setPreviousFileId(logoId);
      setLoadingFile(true);
      uploadProfileSupplier(e.target.files[0]).then((x) => {
        console.log(x);
        if (x.status || x.statusCode) {
          setUploadBeError(x.error)
        }
        else {
          setUploadBeError(null)
          setLogo(x);
          setLogoId(x.FileId);
        }
        setLoadingFile(false);


      });
    }
  };

  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteFileSupplier(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);
      setLoadingFile(true);
      uploadProfileSupplier(e.target.files[0]).then((x) => {
        if (x.status || x.statusCode) {
          setUploadBeError(x.error)
        }
        else {
          setUploadBeError(null)
          setFile(x);
          setFileId(x.FileId);
        }
        setLoadingFile(false);


      });
    }
  };

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1); // 2nd parameter means remove one item only
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
            }}
            open={loadingData}
          >
            <CircularProgress />

            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"rgb(25, 118, 210)"}
            >
              Loading Data...
            </Grid>
          </Backdrop>
          <Grid
            item
            alignSelf={"flex-end"}
            paddingRight="20.5%"
            paddingTop="2.5%"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Box sx={style}>
              <Grid container alignItems="center" direction="column" gap={1}>
                <Grid item className={classes.newClient} paddingTop={2}>
                  {supplierId ? "Edit Supplier" : "New Supplier"}
                </Grid>

                <Grid
                  item
                  className={classes["Line-402"]}
                  alignSelf={"center"}
                  justifySelf={"center"}
                  width="92%"
                ></Grid>
                <Grid item container paddingLeft={"4%"} gap={1}>
                  <Grid
                    item
                    xs={12}
                    className={classes["Client-Information"]}
                    alignSelf="flex-start"
                  >
                    Supplier Information
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="140px"
                      alignSelf="flex-start"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="140px"
                      alignSelf="flex-start"
                    />
                  )}

                  <Grid item container direction={"row"}>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>*Code</span>
                      </Grid>
                      <Grid item>
                        <TextField
                          className={classes["Field-input"]}
                          value={code}
                          error={codeError}
                          id="outlined-name"
                          size="small"
                          helperText={nameError ? "Enter a code" : ""}
                          InputProps={{ style: { height: "38px" } }}
                          inputProps={{ maxLength: 150 }}
                          onBlur={(e) => {
                            if (!e.target.value) setCodeError(true);
                          }}
                          onChange={(e) => {
                            setCodeError(false);
                            setCode(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>*Name</span>
                      </Grid>
                      <Grid item>
                        <TextField
                          className={classes["Field-input"]}
                          value={name}
                          error={nameError}
                          id="outlined-name"
                          size="small"
                          helperText={nameError ? "Enter a client name" : ""}
                          InputProps={{ style: { height: "38px" } }}
                          inputProps={{ maxLength: 150 }}
                          onBlur={(e) => {
                            if (!e.target.value) setNameError(true);
                          }}
                          onChange={(e) => {
                            setNameError(false);

                            setName(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      xs={12}
                      md={6}
                      columnGap={1}
                    >
                      <Grid item >
                        <span className={classes["field-label"]}>*Brands &nbsp;</span>
                        <Tooltip placement="right" title="Click to add a new brand">
                          <img
                            src={plusYellow}
                            alt={"close"}
                            width="13px"
                            height="13px"
                            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                            onClick={(e) => setOpenBrandForm(true)}
                          ></img>
                        </Tooltip>
                      </Grid>

                      <Grid item width="90%">
                        <FormControl fullWidth>
                          <Select
                            multiple
                            MenuProps={{
                              disableScrollLock: true,
                            }}

                            value={brandIds}
                            fullWidth
                            sx={{
                              fontFamily: "Muli",
                              fontSize: "13px",
                              height: "38px"
                            }}
                            onChange={handleChange}
                          >

                            {brandsList?.map((r) => (
                              <MenuItem
                                value={r.Id}
                                key={r.Id}
                                style={{
                                  display: "block",
                                  fontFamily: "Muli",
                                  fontSize: "13px",
                                }}
                              >
                                {r.Name}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* <FormHelperText sx={{ color: "#d32f2f" }}>
                            {currencyIdError ? "Choose a Currency" : ""}
                          </FormHelperText> */}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>
                          *Supplies Type
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          disabled={supplierId}
                          select
                          error={supplyError}
                          className={classes["Field-input"]}
                          value={suppliesTypeId}
                          helperText={supplyError ? "Select a country" : ""}
                          id="outlined-name"
                          size="small"
                          InputProps={{ style: { height: "38px" } }}
                          onBlur={(e) => {
                            if (!e.target.value) setSupplyError(true);
                          }}
                          onChange={(e) => {
                            setSuppliesTypeId(e.target.value);
                            setSupplyError(false);
                          }}
                        >
                          {suppliesTypes?.map((option) => (
                            <MenuItem
                              value={option.Id}
                              key={option.Id}
                              style={{ display: "block" }}
                            >
                              {option.Name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    {openBrandForm ? (
                      <Grid item xs={12}
                        md={6}>

                        <Grid
                          marginTop={1}
                          item
                          container
                          direction="column"
                          rowGap={0.5}
                          style={{ backgroundColor: "rgba(6, 116, 185, 0.3)" }}
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          padding={1}
                          borderRadius="5px"
                          paddingTop={2}
                          width="80%"
                        >
                          <Grid item><span className={classes["field-label"]}>*Brand Name</span> </Grid>
                          <Grid item>
                            <TextField
                              value={brandName}
                              placeholder="Brand Name"
                              InputProps={{ style: { height: "38px" } }}
                              onChange={(e) => {
                                setBrandName(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item><span className={classes["field-label"]}>*Brand Code</span></Grid>
                          <Grid item>
                            <TextField
                              value={brandCode}
                              placeholder="Brand Code"
                              InputProps={{ style: { height: "38px" } }}
                              onChange={(e) => {
                                setBrandCode(e.target.value);
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            container
                            direction="row-reverse"
                            spacing={2}
                            paddingTop={4}
                            alignItems="center"
                          >
                            <Grid item>
                              <button
                                disabled={!brandName || !brandCode}
                                className={classes["save-button"]}
                                onClick={() => {
                                  let tmpCurrenciesList = brandsList;
                                  let currencyObject = {
                                    Id: brandsList?.length + 1,
                                    Name: brandName,
                                    Code: brandCode,
                                    BrandId: null,
                                  };
                                  setBrandDataObject(currencyObject);
                                  setBrandsList(
                                    tmpCurrenciesList.concat(currencyObject)
                                  );
                                  setBrandIds(brandIds.concat(currencyObject.Id));
                                  forceUpdate();
                                  setBrandName(null);
                                  setBrandCode(null);
                                  setOpenBrandForm(false);
                                }}
                              >
                                Add
                              </button>
                            </Grid>
                            <Grid item>
                              <button
                                className={classes["cancel-button"]}
                                onClick={() => {
                                  setBrandName(null);
                                  setBrandCode(null);
                                  setOpenBrandForm(false);
                                }}
                              >
                                Cancel
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>*Email</span>
                      </Grid>
                      <Grid item>
                        <TextField
                          error={emailError}
                          className={classes["Field-input"]}
                          value={email}
                          id="outlined-name"
                          size="small"
                          helperText={
                            emailError
                              ? emailInvalid
                                ? "Enter a valid email address"
                                : "Enter an email address"
                              : ""
                          }
                          InputProps={{ style: { height: "38px" } }}
                          inputProps={{ maxLength: 64 }}
                          onBlur={(e) => {
                            if (!e.target.value) setEmailError(true);
                          }}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (
                              !e.target.value ||
                              !e.target.value.match(
                                /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                              )
                            ) {
                              setEmailError(true);
                              setEmailInvalid(true);
                            } else {
                              setEmailError(false);
                              setEmailInvalid(false);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>
                          *Address{" "}
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          className={classes["Field-input"]}
                          error={addressError}
                          value={address}
                          id="outlined-name"
                          size="small"
                          InputProps={{ style: { height: "38px" } }}
                          inputProps={{ maxLength: 150 }}
                          helperText={addressError ? " Enter an address" : ""}
                          onBlur={(e) => {
                            if (!e.target.value) setAddressError(true);
                          }}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            setAddressError(false);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>
                          *Website
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          className={classes["Field-input"]}
                          value={website}
                          error={websiteError}
                          id="outlined-name"
                          size="small"
                          helperText={websiteError ? " Enter a website" : ""}
                          InputProps={{ style: { height: "38px" } }}
                          inputProps={{ maxLength: 150 }}
                          onBlur={(e) => {
                            if (!e.target.value) setWebsiteError(true);
                          }}
                          onChange={(e) => {
                            setWebsite(e.target.value);
                            setWebsiteError(false)
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>
                          *Phone Number
                        </span>
                      </Grid>
                      <Grid item>
                        <PhoneInput
                          placeholder="Enter phone number"
                          // onlyCountries={isoCodes}
                          enableAreaCodes={true}
                          onBlur={(e) => {
                            console.log("emhnn")
                            if (!e.target.value) setPhoneNumberError(true);
                          }}
                          value={phoneNumber}
                          onChange={(phone, country) => {
                            setPhoneNumber(phone);
                            setPhoneCode(country.dialCode)
                            if (
                              phone === "" ||
                              phone.length < 7 ||
                              phone.length > 25
                            ) {
                              setPhoneNumberError(true);
                            } else {
                              setPhoneNumberError(false);
                            }
                          }}
                          country={localStorage.getItem("userCountry")?.toLowerCase()}
                          style={{
                            borderColor: phoneNumberError ? "#ea001e" : "",
                          }}
                          inputStyle={{
                            borderColor: phoneNumberError ? "#ea001e" : "",
                            width: "90%",
                            height: 15,
                            fontFamily: "Muli",
                            fontsize: "16px",
                          }}
                        />
                        {phoneNumberError ? (
                          <span
                            style={{
                              fontFamily: "Muli",
                              color: "#ea001e",
                              fontSize: "12px",
                            }}
                          >
                            Phone number must be between 7 and 25 characters
                          </span>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>
                          *Country
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          select
                          error={countryError}
                          className={classes["Field-input"]}
                          value={country}
                          helperText={countryError ? "Select a country" : ""}
                          id="outlined-name"
                          size="small"
                          InputProps={{ style: { height: "38px" } }}
                          onBlur={(e) => {
                            if (!e.target.value) setCountryError(true);
                          }}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            setCountryError(false);
                          }}
                        >
                          {countries?.map((option) => (
                            <MenuItem
                              value={option.Id}
                              key={option.Id}
                              style={{ display: "block" }}
                            >
                              {option.Name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>
                          Description
                        </span>
                      </Grid>
                      <Grid item>
                        <TextField
                          className={classes["Field-input"]}
                          value={description}
                          id="outlined-name"
                          size="small"
                          InputProps={{ style: { height: "80px" } }}
                          inputProps={{ maxLength: 255 }}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container direction={"row"} md={6} xs={12} marginTop={2.5}>
                      {files?.length === 0 && !loadingFile ?
                        (
                          <Grid item xs={12} alignSelf="flex-end">
                            <Tooltip
                              width="400px"
                              title={
                                "You can  upload any document related to the supplier."
                              }
                              placement="right"
                            >
                              <img
                                width={"20px"}
                                src={infoTip}
                                alt={"close"}
                              ></img>
                            </Tooltip>
                          </Grid>
                        ) : <></>}
                      {supplierId && files?.length > 0 && !loadingFile ? (
                        <Grid item xs={12} alignSelf="flex-end">
                          <Tooltip
                            width="400px"
                            title={"Click on file to download"}
                            placement="right"
                          >
                            <img
                              width={"15px"}
                              src={infoTip}
                              alt={"close"}
                            ></img>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {loadingFile ?
                        <Grid item xs={12} alignSelf="flex-end">
                          <CircularProgress size={14} />
                        </Grid> :
                        <></>
                      }
                      {uploadBeError ?
                        <Grid item xs={12} style={{ color: "red", fontFamily: "Muli", fontSize: "13px" }}>{uploadBeError}</Grid>
                        :
                        <></>}
                      <Grid item container direction={"column"} xs={6}>
                        <Grid item>
                          <Button
                            component="label"
                            style={{
                              width: "80%",
                              textTransform: "none",
                              fontFamily: "Muli",
                              fontSize: "12px",
                              lineHeight: 1.45,
                              textAlign: "left",
                              color: "#101650",
                              backgroundColor: "white",
                              borderRadius: "6px",
                              border: "solid 1px #b8b8b8",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "38px",
                              gap: "10px"
                            }}
                          >
                            <FileUploadOutlinedIcon htmlColor="#529bd7" />
                            <span>
                              *Upload File
                            </span>
                            <input
                              type="file"
                              hidden
                              onChange={handleFileChange}
                            />
                          </Button>
                        </Grid>
                        {files?.map((item) => (
                          <Grid
                            item
                            container
                            paddingTop={1}
                            key={item.FileId}
                          >
                            <Grid
                              item
                              xs={2}
                              paddingLeft={1}
                              paddingTop={0.5}
                            >
                              <img
                                src={
                                  AccountType === "1" ? fileIcon : fileIconSub
                                }
                                alt="file"
                                width={15}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              paddingTop={0.5}
                              onMouseOver={(e) => {
                                e.target.style.color = "grey";
                                e.target.style.cursor = "pointer";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.color = "black";
                              }}
                              onClick={() => {
                                window.open(item.URL);
                              }}
                            >
                              {item.DisplayName?.slice(0, 10)}
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              paddingTop={0.8}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                deleteItem(item);

                                setFile(null);
                                setFileId(null);
                                forceUpdate();
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item container direction={"column"} xs={6}>
                        <Grid item>
                          <Button
                            component="label"
                            style={{
                              width: "80%",
                              textTransform: "none",
                              fontFamily: "Muli",
                              fontSize: "12px",
                              lineHeight: 1.45,
                              textAlign: "left",
                              color: "#101650",
                              backgroundColor: "white",
                              borderRadius: "6px",
                              border: "solid 1px #b8b8b8",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "38px",
                              gap: "10px"
                            }}
                          >
                            <FileUploadOutlinedIcon
                              htmlColor="#529bd7"
                            />
                            <span>
                              *Upload Logo
                            </span>
                            <input
                              type="file"
                              hidden
                              onChange={handleLogoChange}
                            />
                          </Button>
                        </Grid>
                        {logo ? (
                          <Grid item container paddingTop={1}>
                            <Grid
                              item
                              xs={2}
                              paddingLeft={1}
                              paddingTop={0.8}
                            >
                              <img
                                src={logo.URL}
                                alt="logo"
                                width={18}
                                height={18}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              paddingTop={0.8}
                              onMouseOver={(e) => {
                                e.target.style.color = "grey";
                                e.target.style.cursor = "pointer";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.color = "black";
                              }}
                              onClick={() => {
                                window.open(logo.URL);
                              }}
                            >
                              {logo.DisplayName?.slice(0, 5)}
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              paddingTop={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                setLogo(null);
                                setLogoId(null);
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </Grid>
                          </Grid>
                        ) : (
                          <></>)}
                      </Grid>
                    </Grid>
                    <Grid item container direction={"row"} md={6} xs={12}>
                      <Grid item container direction={"column"} xs={6}>
                        <Grid item>
                          <span className={classes["field-label2"]}>
                            Creator
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            value={creator}
                            disabled
                            className={classes["Field-input2"]}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "38px" } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"} xs={6}>
                        <Grid item>
                          <span className={classes["field-label2"]}>
                            Created Date
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            value={createdDate?.slice(0, 10)}
                            disabled
                            className={classes["Field-input2"]}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "38px" } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>


                  </Grid>
                  {saveBeError ?
                    <Grid item style={{ fontFamily: "Muli", color: "red" }}> {saveBeError}</Grid>
                    :
                    <></>}
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"flex-end"}
                  direction="row"
                  alignItems={"flex-end"}
                  paddingRight={5}
                  paddingBottom={1}
                  paddingTop={4}
                >
                  <Grid item paddingRight={2}>
                    <button
                      className={classes["cancel-button"]}
                      onClick={() => {
                        props.setRowData(null);
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={loading || isDisabled}
                      className={classes["save-button"]}
                      onClick={() => {
                        setSelectedBrands([])
                        let tmp = brandsList.filter(item => brandIds.includes(item.Id))
                        tmp.map((item) => {
                          if (item.BrandId === null) {
                            let tmpObject = {
                              Name: item.Name,
                              Code: item.Code
                            }
                            selectedBrands.push(tmpObject)
                          }
                          else {
                            let tmpObject = {
                              Id: initialSelectedBrands?.find((i) => i.BrandId === item.Id) ? initialSelectedBrands?.find((i) => i.BrandId === item.Id)?.Id : null,
                              Name: item.Name,
                              BrandId: item.Id
                            }
                            selectedBrands.push(tmpObject)
                          }
                        })
                        console.log("listData", listData)
                        if (supplierId) update();
                        else create();
                      }}
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default SupplierModalModal;
