import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../Assets/Styles/home.css";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import classing from "../../Assets/Styles/factory.module.css";
import { withStyles } from "@material-ui/core/styles";
import tooltipbg from "../../Assets/Images/tootltipbg.png";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as productionServices from "../../Services/production-planning-services";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ErrorModal from "../../SharedComponents/ErrorModal";



const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getCombinationSalesOrder = productionServices.getCombinationSalesOrder;
const assignRoute = productionServices.assignRoute;
const deleteCombination = productionServices.deleteCombination;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    backgroundImage: `url(${tooltipbg})`,
    backgroundSize: "contain",
    color: "#707070",
    fontSize: "Muli",
    width: 205,
    height: 170,
  },
}));

const styles = {
  blue: {
    fontFamily: "Muli",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "#101650",
    padding: 0,
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  errorCell: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    color: "#ea001e",
    padding: 0,
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  grey: {
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
    color: "#707070",
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  miniBlue: {
    fontFamily: "Muli",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "#0674b9",
  },
  minGrey: {
    fontFamily: "Muli",
    fontSize: 11,
    textAlign: "center",
    color: "#707070",
    padding: 0,
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  miniminiGrey: {
    fontFamily: "Muli",
    fontSize: 11,
    textAlign: "center",
    color: "#707070",
    padding: 0,
    border: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    height: 58,
  },
}))(TableRow);

export default function Assigning(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loadingData, setLoadingData] = useState(false);

  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop];
    }
    return total;
  };

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const COMBINATION_ID = query?.get("id");
  const [assign, setAssign] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const [shrink, setShrink] = useState(false);
  const [lineId, setLineId] = useState(null);
  const [overloaded, setOverloaded] = useState(false);
  const [modify, setModify] = useState(false);
  const [chosenLineId, setChosenLineId] = useState(null);
  const [assignError, setAssignError] = useState(false);

  const [salesOrderPlanning, setSalesOrderPlanning] = useState([]);
  const [dateOfAllocation, setDateOfAllocation] = useState("dates");

  const [Codes, setCodes] = useState([]);

  const [assignLoading, setAssignLoading] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (COMBINATION_ID) {
      setLoadingData(true);
      getCombinationSalesOrder(COMBINATION_ID).then((x) => {
        setIsValidated(x.IsValidated);
        setSalesOrderPlanning(x.SalesOrders);
        setCodes(
          x.SalesOrders?.flatMap((order) => order.Members)
            ?.flatMap((member) => member.Lines)
            ?.flatMap((line) => line.Routes)[
            x.SalesOrders.flatMap((order) => order.Members)
              .flatMap((member) => member.Lines)
              .flatMap((line) => line.Routes)
              ?.map((a) => a?.RouteMachines.length)
              .indexOf(
                Math.max(
                  ...x.SalesOrders.flatMap((order) => order.Members)
                    .flatMap((member) => member.Lines)
                    .flatMap((line) => line.Routes)
                    ?.map((a) => a?.RouteMachines.length)
                )
              )
          ]?.RouteMachines
        );
        setDateOfAllocation(x.Date);
        setLoadingData(false);
      });
    }
  }, [COMBINATION_ID, modify]);
  const AddBlankSpace = (MachineRoutes) => {
    if (MachineRoutes.length === Codes.length) {
      return <></>;
    } else {
      let spaceWidth = Codes.length - MachineRoutes.length;
      var indents = [];
      for (var i = 0; i < spaceWidth; i++) {
        indents.push(
          <TableCell sx={styles.grey} width="50px">
            &nbsp;
          </TableCell>
        );
      }
      return indents;
    }
  };
  const AssignTheRoute = (LineId, RouteId) => {
    setAssignLoading(true);
    assignRoute(RouteId, LineId, COMBINATION_ID).then((x) => {
      setAssignLoading(false);
      if (x.status === 409) {
        setAssignError(true);
      } else {
        setModify(!modify);
        setLineId(null);
      }
    });
  };
  const sumEstimatedTimePerSo = (row) => {
    let Time = 0;
    row?.Members.map((mem) => {
      mem.Lines.map((l) => {
        l.Routes.map((r) => {
          if (r.IsAssigned === true) {
            Time = Time + r.EstimatedTime;
          }
        });
      });
    });
    return (Time / 60).toFixed(2) + "min";
  };
  const [disabledS, setDisabledS] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Production Planning",
        "Production run",
        setDisabledS
      );
    }
  }, [UserPermissions]);
  return (
    <>
      <Grid className="Rectangle779">
        <Grid
          item
          xs={12}
          container
          alignItems="flex-start"
          justifyContent="center"
          paddingTop="2.5%"
        >
          <Grid item container xs={6}>
            <Grid
              item
              xs={12}
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              Production Planning
            </Grid>
            <Grid item fontWeight={"normal"} className={classing["Title"]}>
              Allocation of Machines on &nbsp;{dateOfAllocation}
            </Grid>
          </Grid>
          <Grid item container direction={"row-reverse"} xs={4} marginTop="1%">
            <Grid item paddingLeft="2%">
              <button
                className={classing["back-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>
            </Grid>
            {localStorage.getItem("IsFreePlanActivated") === "true" ||
              localStorage.getItem("planIdManu")?.includes("Core") ? (
              <Grid item>
                <button
                  disabled={disabledS}
                  className={classing["back-button"]}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setLoadingData(true)
                    deleteCombination(COMBINATION_ID).then((x) => {
                      setLoadingData(false);
                      if (x.status || x.statusCode) {
                        if (x.status) setBEerror(x.error)
                        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                        setOpenErrorModal(true);
                      } else {
                        navigate("/saas/productionPlanning/Calendar");

                      }
                    });
                  }}
                >
                  Replan
                </button>
              </Grid>
            ) : !disabledS ? (
              <Grid item>
                <button
                  disabled={disabledS}
                  className={classing["back-button"]}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    navigate(
                      `/saas/productionPlanning/machinesOptimization?id=${COMBINATION_ID}&validated=${isValidated}`
                    );
                  }}
                >
                  Production Run
                </button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid
          item
          xs={10}
          sx={{
            margin: "1.5% 0 0 8%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundColor: "#fff",
          }}
        >
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0px 0px",
                border: "solid 0.5px ",
                borderColor: "rgba(225, 227, 25, 0.5)",
                maxWidth: 1550,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.blue}>Bar Mark No.</TableCell>
                  <TableCell sx={styles.blue}>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>Qty</Grid>
                      <Grid item width={50} sx={styles.miniBlue}>
                        Total No. of Bars{" "}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={styles.blue}>Bar Dia.</TableCell>
                  <TableCell sx={styles.blue}>Shape</TableCell>
                  <TableCell sx={styles.blue}>Length</TableCell>
                  <TableCell sx={styles.blue}>Weight</TableCell>
                  {localStorage.getItem("planIdManu")?.includes("Pro +") ? (
                    <TableCell sx={styles.blue}>Est. Production Time    <Tooltip title="This is Beta version and the data will be more accurate in the future." placement="top-start">
                      <IconButton>
                        <InfoIcon sx={{ width: "14px", height: "14px" }} />
                      </IconButton>

                    </Tooltip></TableCell>
                  ) : (
                    <></>
                  )}

                  <TableCell sx={styles.grey}>Status</TableCell>
                  <TableCell sx={styles.grey}>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>Route Code</Grid>
                      <Grid
                        item
                        width={50}
                        sx={styles.miniBlue}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => setShrink(!shrink)}
                      >
                        {shrink ? "> Expand" : "< Shrink"}
                      </Grid>
                    </Grid>
                  </TableCell>
                  {!shrink
                    ? Codes?.map((d, index) => (
                      <TableCell key={index} sx={styles.grey} width="50px">
                        Machine code
                      </TableCell>
                    ))
                    : ""}
                  <TableCell sx={styles.grey}>Route Capacity</TableCell>
                  <TableCell sx={styles.grey}>Action</TableCell>
                </TableRow>
              </TableHead>
              {salesOrderPlanning?.map((row, index) => (
                <TableBody>
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: "rgba(225, 227, 25, 0.7)",
                    }}
                  >
                    <TableCell sx={styles.blue}>
                      Sales Order #{row.Code}
                    </TableCell>
                    <TableCell sx={styles.blue}></TableCell>
                    <TableCell sx={styles.blue}></TableCell>
                    <TableCell sx={styles.blue}></TableCell>
                    <TableCell sx={styles.blue}></TableCell>
                    <TableCell sx={styles.blue}></TableCell>
                    {localStorage.getItem("planIdManu")?.includes("Pro +") ? (
                      <TableCell sx={styles.blue}></TableCell>
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                  {row?.Members?.map((member, index) => (
                    <>
                      <StyledTableRow
                        key={index}
                        sx={{ backgroundColor: "rgba(225, 227, 25, 0.3)" }}
                      >
                        <TableCell sx={styles.minGrey}>
                          {member.OrderId}
                        </TableCell>
                        <TableCell sx={styles.minGrey}>
                          {member.Quantity}
                        </TableCell>
                        <TableCell sx={styles.minGrey}>&nbsp;</TableCell>
                        <TableCell sx={styles.minGrey}>
                          <Grid container>
                            <Grid item xs={3} textAlign={"left"}>
                              {member.Name}
                            </Grid>
                            <Grid item xs={3} textAlign={"center"}>
                              {member.Type}
                            </Grid>
                            <Grid item xs={6} textAlign={"right"}>
                              {member.BarMarkType}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.minGrey}>&nbsp;</TableCell>
                        <TableCell sx={styles.minGrey}>
                          {member.Lines.sum("ActualWeight").toFixed(3)}
                        </TableCell>
                      </StyledTableRow>
                      {member?.Lines?.map((line, i) => (
                        <>
                          <StyledTableSubRow key={i}>
                            <TableCell sx={styles.miniminiGrey}>
                              {line.BarMarkNo}
                            </TableCell>
                            <TableCell sx={styles.miniminiGrey}>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                              >
                                <Grid item>{line.BarsQuantity}</Grid>
                                <Grid item width={50} sx={styles.miniBlue}>
                                  {line.TotalNumberOfBars}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.miniminiGrey}>
                              {line.BarDiameter}
                            </TableCell>
                            <TableCell sx={styles.miniminiGrey}>
                              <img
                                src={line.ShapeBlob?line.ShapeBlob:line?.Shape?.File?.URL}
                                alt={"shape"}
                                width={150}
                              />
                            </TableCell>
                            <TableCell sx={styles.miniminiGrey}>
                              {line.ActualLength}
                            </TableCell>
                            <TableCell sx={styles.miniminiGrey}>
                              {line.ActualWeight}
                            </TableCell>
                            {localStorage
                              .getItem("planIdManu")
                              ?.includes("Pro +") ? (
                              <TableCell sx={styles.blue}>
                                {line?.Routes?.filter(
                                  (x) => x.IsAssigned === true
                                )[0]?.EstimatedTime
                                  ? (
                                    line?.Routes?.filter(
                                      (x) => x.IsAssigned === true
                                    )[0]?.EstimatedTime / 60
                                  ).toFixed(1)
                                  : 0}
                                mins
                              </TableCell>
                            ) : (
                              <></>
                            )}
                            <TableCell
                              sx={styles.blue}
                              style={{ fontSize: "12px" }}
                            >
                              Planned
                            </TableCell>
                            <TableCell
                              sx={styles.minGrey}
                              style={{ fontSize: "12px" }}
                            >
                              {!line.Routes || line?.Routes.length === 0 || line.IsNoRoutes
                                ? "No Routes"
                                : line.Routes.find((r) => r.IsAssigned === true)
                                  ?.Code}
                            </TableCell>
                            {!shrink ? (
                              <>
                                {line?.Routes?.find(
                                  (r) => r.IsAssigned === true
                                ) ? (
                                  <>
                                    {line?.Routes?.find(
                                      (r) => r.IsAssigned === true
                                    )?.RouteMachines?.map((d, indle) => (
                                      <HtmlTooltip
                                        placement="right"
                                        title={
                                          <React.Fragment>
                                            <Grid container padding={1} gap={1}>
                                              <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                  backgroundColor: overloaded
                                                    ? "rgba(255, 0, 0, 0.48)"
                                                    : "rgba(6, 116, 185, 0.3)",
                                                  fontWeight: "bold",
                                                  textAlign: "left",
                                                  color: "#101650",
                                                  padding: 1.5,
                                                }}
                                              >
                                                {overloaded ? (
                                                  <Grid
                                                    container
                                                    alignItems="center"
                                                  >
                                                    <Grid item>
                                                      Machine ({d.Name})
                                                    </Grid>
                                                    <Grid item>
                                                      <WarningAmberRoundedIcon
                                                        htmlColor="#fff"
                                                        style={{
                                                          width: 20,
                                                          height: 18,
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                ) : (
                                                  `Machine (${d.Code})`
                                                )}
                                              </Grid>
                                              <Grid item xs={12} paddingLeft={1}>
                                                Production run
                                                <Grid item container direction="column" height="70px" wrap="nowrap" overflow="auto">
                                                  {d?.ProductionRuns?.map((pr) => (
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      key={pr.Id}
                                                      style={{ fontWeight: "bold" }}
                                                    >
                                                      {pr.Code}
                                                    </Grid>
                                                  ))}
                                                </Grid>
                                              </Grid>

                                              <Grid item xs={12} paddingLeft={1}>
                                                Reserved Capcity:&nbsp;
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {d.ReservedCapacity}
                                                </span>
                                              </Grid>
                                            </Grid>
                                          </React.Fragment>
                                        }
                                      >
                                        <TableCell
                                          onMouseOver={(e) =>
                                            (e.target.style.cursor = "pointer")
                                          }
                                          sx={styles.minGrey}
                                          style={{
                                            backgroundColor:
                                              line.Routes?.find(
                                                (r) => r.IsAssigned === true
                                              )?.RouteTypeId === 1
                                                ? "rgba(225, 227, 25, 0.3)"
                                                : line.Routes?.find(
                                                  (r) => r.IsAssigned === true
                                                )?.RouteTypeId === 2
                                                  ? "rgba(225, 227, 25, 0.5)"
                                                  : line.Routes?.find(
                                                    (r) => r.IsAssigned === true
                                                  )?.RouteTypeId === 3
                                                    ? "rgba(45, 164, 208, 0.3)"
                                                    : line.Routes?.find(
                                                      (r) => r.IsAssigned === true
                                                    )?.RouteTypeId === 4
                                                      ? "rgba(95, 186, 71, 0.3)"
                                                      : line.Routes?.find(
                                                        (r) => r.IsAssigned === true
                                                      )?.RouteTypeId === 5
                                                        ? "rgba(95, 186, 71, 0.5)"
                                                        : line.Routes?.find(
                                                          (r) => r.IsAssigned === true
                                                        )?.RouteTypeId === 6
                                                          ? "rgba(0, 0, 0, 0.3)"
                                                          : "rgba(45, 164, 208, 0.5)",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {d.Name}
                                        </TableCell>
                                      </HtmlTooltip>
                                    ))}

                                    {AddBlankSpace(
                                      line.Routes?.find(
                                        (r) => r.IsAssigned === true
                                      )?.RouteMachines
                                    )}
                                  </>
                                ) : (
                                  Codes?.map((d, index) => (
                                    <TableCell
                                      key={index}
                                      sx={styles.grey}
                                      width="50px"
                                    >
                                      &nbsp;
                                    </TableCell>
                                  ))
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <TableCell sx={styles.minGrey} width={75} height={60}>
                              <Grid
                                sx={{
                                  borderRadius: 2,
                                  border: "solid 1px #f3f3f3",
                                  backgroundColor:
                                    line.Routes?.find(
                                      (r) => r.IsAssigned === true
                                    )?.ReservedCapacity > 100
                                      ? "rgba(255, 0, 0, 0.3)"
                                      : "rgba(45, 164, 208, 0.3)",
                                  width: 70,
                                  height: 55,
                                  fontSize: 17,
                                  color: "#101650",
                                  marginLeft: "5px",
                                  paddingTop: "15%",
                                }}
                              >
                                {
                                  line.Routes?.find((r) => r.IsAssigned === true)
                                    ?.ReservedCapacity
                                }
                                %
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.minGrey} width="110   px">
                              <Button
                                sx={{
                                  height: 35,
                                  borderRadius: 2,
                                  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                  backgroundColor: "#fff",
                                  fontFamily: "Muli",
                                  fontSize: 13,
                                  textAlign: "left",
                                  color: "#0674b9",
                                  textTransform: "none",
                                }}
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                  e.target.style.color = "#fff";
                                  e.target.style.backgroundColor = "#0674b9";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "#0674b9";
                                  e.target.style.backgroundColor = "#fff";
                                }}
                                onClick={() => {
                                  if (!isValidated) {
                                    setLineId(line.Id);
                                    setAssignError(false);
                                  }
                                }}
                              >
                                {line.Routes.find((r) => r.IsAssigned === true)
                                  ? "Re-assign"
                                  : "View Routes"}
                              </Button>
                            </TableCell>
                          </StyledTableSubRow>
                          {line.Id === lineId ? (
                            line?.Routes?.filter(
                              (r) => r.IsAssigned === false
                            )?.map((r, index) => (
                              <StyledTableSubRow key={index}>
                                <TableCell sx={styles.errorCell}>
                                  {assignError && chosenLineId === r.Id ? (
                                    "Error Occured can't assign route."
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                                <TableCell sx={styles.blue}></TableCell>
                                <TableCell sx={styles.blue}></TableCell>
                                <TableCell sx={styles.blue}></TableCell>
                                <TableCell sx={styles.blue}></TableCell>
                                <TableCell sx={styles.blue}></TableCell>
                                <TableCell sx={styles.blue}></TableCell>
                                <TableCell
                                  sx={styles.minGrey}
                                  style={{ fontSize: "12px" }}
                                >
                                  {r.Code}
                                </TableCell>

                                {!shrink ? (
                                  <>
                                    {r?.RouteMachines?.map((d, i) => (
                                      <TableCell
                                        key={i}
                                        sx={styles.minGrey}
                                        style={{
                                          backgroundColor:
                                            r.RouteTypeId === 1
                                              ? "rgba(225, 227, 25, 0.3)"
                                              : r.RouteTypeId === 2
                                                ? "rgba(225, 227, 25, 0.5)"
                                                : r.RouteTypeId === 3
                                                  ? "rgba(45, 164, 208, 0.3)"
                                                  : r.RouteTypeId === 4
                                                    ? "rgba(95, 186, 71, 0.3)"
                                                    : r.RouteTypeId === 5
                                                      ? "rgba(95, 186, 71, 0.5)"
                                                      : r.RouteTypeId === 6
                                                        ? "rgba(0, 0, 0, 0.3)"
                                                        : "rgba(45, 164, 208, 0.5)",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {d.Name}
                                      </TableCell>
                                    ))}
                                    {AddBlankSpace(r.RouteMachines)}
                                  </>
                                ) : (
                                  <></>
                                )}

                                <TableCell
                                  sx={styles.minGrey}
                                  width={75}
                                  height={60}
                                >
                                  <Grid
                                    sx={{
                                      borderRadius: 2,
                                      border: "solid 1px #f3f3f3",
                                      backgroundColor:
                                        r?.ReservedCapacity > 100
                                          ? "rgba(255, 0, 0, 0.3)"
                                          : "rgba(45, 164, 208, 0.3)",
                                      width: 70,
                                      height: 55,
                                      fontSize: 17,
                                      color: "#101650",
                                      marginLeft: "5px",
                                      paddingTop: "15%",
                                    }}
                                  >
                                    {r?.ReservedCapacity}%
                                  </Grid>
                                </TableCell>
                                <TableCell sx={styles.minGrey} width="90px">
                                  <Button
                                    disabled={assignLoading}
                                    sx={{
                                      height: 35,
                                      borderRadius: 2,
                                      boxShadow:
                                        "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#fff",
                                      fontFamily: "Muli",
                                      fontSize: 13,
                                      textAlign: "left",
                                      color: "#0674b9",
                                      textTransform: "none",
                                    }}
                                    onMouseOver={(e) => {
                                      e.target.style.cursor = "pointer";
                                      e.target.style.color = "#fff";
                                      e.target.style.backgroundColor = "#0674b9";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.color = "#0674b9";
                                      e.target.style.backgroundColor = "#fff";
                                    }}
                                    onClick={() => {
                                      if (!isValidated) {
                                        setAssignError(false);
                                        setChosenLineId(r.Id);
                                        AssignTheRoute(line.Id, r.Id);
                                      }
                                    }}
                                  >
                                    {assignLoading && chosenLineId === r.Id ? <span><CircularProgress size={20} /></span> : "Assign"

                                    }
                                  </Button>
                                </TableCell>
                              </StyledTableSubRow>
                            ))
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </>
  );
}
