import * as constFile from "../pileCubesConst";
import axios from "axios";
const GET_NOTIFICATIONS = constFile.GET_NOTIFICATIONS;

export const getNotifications = (listData) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_NOTIFICATIONS}`, listData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: {
      //   toBeTaken: toBeTaken,
      // },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const VIEW_NOTIFICATIONS = constFile.VIEW_NOTIFICATIONS;

export const viewNotifications = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .put(`${process.env.REACT_APP_URL + VIEW_NOTIFICATIONS}`, data ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: {
      //   ids: idsArray,
      // },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const SNOOZE_TIP = constFile.SNOOZE_TIP;

export const snoozeTip = (PeriodId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SNOOZE_TIP}`,
      { PeriodId: PeriodId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const DELETE_NOTIFICATION = constFile.DELETE_NOTIFICATION;
export const deleteNoti = (idsString) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_NOTIFICATION + "/" + idsString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: { Id: Id },
    })
    .then((response) => {

      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};