import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as constFile from "../../../pileCubesConst";
import jwt from "jwt-decode";

const SC_REGISTER = constFile.SC_REGISTER;
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      height: "40px",
    },
  },
});

function Form3({
  continues,
  back,
  countries,
  info,
  setLoading,
  setHighlightMsg,
  setHighlightError,
  setToken,
  setAccountName,
  registrationEmail,
  tenantId,
  inviterTenantId,
  projectId,
  subProjectId,
}) {
  const [errMsg, setErrMsg] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState(false);
  const [positionInvalid, setPositionInvalid] = useState(false);

  const [contactName, setContactName] = useState("");
  const [contactNameError, setcontactNameError] = useState(false);
  const [contactNameInvalid, setcontactNameInvalid] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState("");
  const [agreeCheckError, SetAgreeCheckError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isoCodes, setisoCodes] = useState([]);
  const [fulled, setFulled] = useState(false);
  const [submittingLoading, setSubmittingLoading] = useState(false);

  useEffect(() => {
    for (var i = 0; i < countries.length; i++) {
      isoCodes[i] = countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });

  useEffect(() => {
    if (
      !info.User.UserDetails.PhoneNumber ||
      !info.User.UserDetails.Position ||
      !info.User.UserDetails.PersonalEmail ||
      !info.User.UserDetails.Name ||
      !info.AgreementAcceptance ||
      positionError ||
      countryError ||
      emailError ||
      positionInvalid ||
      emailInvalid ||
      contactNameError ||
      contactNameInvalid
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  
  function register(e) {
    setSubmittingLoading(true);
    axios
      .post(`${process.env.REACT_APP_URL + SC_REGISTER}`, {
        TenantId: tenantId,
        Name: info.Name,
        RegistrationNumber: info.RegistrationNumber,
        Address: info.Address,
        CompanyPhoneNumber: info.CompanyPhoneNumber,
        AgreementAcceptance: true,
        CountryId: info.CountryId,
        DisciplineCoveredId: info.DisciplineCoveredId,
        DisciplineCoveredOther: info.DisciplineCoveredOther,
        TradedId: info.TradedId,
        TradeOther: info.TradeOther,
        IndustryTypeId: info.IndustryTypeId,
        SubIndustryTypeId: info.SubIndustryTypeId,
        IndustryTypeOther: info.IndustryTypeOther,
        EmployeesRangeId: info.EmployeesRangeId,
        User: {
          UserId: info.User.UserId,
          Password: info.User.Password,
          Email: tenantId ? null : info.User.Email,
          ConfirmPassword: info.User.ConfirmPassword,
          UserDetails: {
            Name: info.User.UserDetails.Name,
            PersonalEmail: info.User.UserDetails.PersonalEmail,
            Position: info.User.UserDetails.Position,
            PhoneNumber: info.User.UserDetails.PhoneNumber,
            UserId: info.User.UserDetails.UserId,
          },
        },
        InviterTenantId: inviterTenantId,
        ProjectId: projectId,
        SubProjectId: subProjectId,
      })
      .then((res) => {
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("companyName", info.Name);
        localStorage.setItem("isVerified", "false");
        localStorage.setItem("AccountType", 2);
        localStorage.setItem(
          "isGuest",
          jwt(localStorage.getItem("accessToken"))?.IsGuest
        );
        localStorage.setItem(
          "UserId",
          jwt(localStorage.getItem("accessToken"))?.Id
        );
        setAccountName(info.Name);
        setSubmittingLoading(false);
        continues(e);
      })
      .catch((err) => {
        setOpen(true);
        setErrMsg(err.response.data.error);
      });
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox">
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12}>
          <Grid item md={9} xs={11}>
            <div className="contactInfo"> Primary Contact Information </div>
          </Grid>

          <Grid item md={3} xs={1} container direction={"row-reverse"}>
            <IconButton
              onClick={(e) => {
                navigate("/");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              label="Contact Name"
              size="small"
              value={info.User.UserDetails.Name}
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={contactNameError}
              helperText={
                contactNameError
                  ? contactNameInvalid
                    ? "Name must be less than 64 characters"
                    : "Enter your name"
                  : ""
              }
              onChange={(e) => {
                setContactName(e.target.value);
                info.User.UserDetails.Name = e.target.value;
                if (info.User.UserDetails.Name === "") {
                  setcontactNameError(true);
                  setcontactNameInvalid(false);
                } else if (
                  (info.User.UserDetails.Name !== "") &
                  (info.User.UserDetails.Name.length > 64)
                ) {
                  setcontactNameError(true);
                  setcontactNameInvalid(true);
                } else {
                  setcontactNameError(false);
                  setcontactNameInvalid(false);
                }
              }}
            />
          </Grid>

          <Grid item>
            <CssTextField
              label="Position"
              size="small"
              className="textField"
              value={info.User.UserDetails.Position}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={positionError}
              helperText={
                positionError
                  ? positionInvalid
                    ? "Postion must be less than 20 characters"
                    : "Enter your Position"
                  : ""
              }
              onChange={(e) => {
                setPosition(e.target.value);
                info.User.UserDetails.Position = e.target.value;
                if (info.User.UserDetails.Position === "") {
                  setPositionError(true);
                  setPositionInvalid(false);
                } else if (
                  (info.User.UserDetails.Position !== "") &
                  (info.User.UserDetails.Position.length > 20)
                ) {
                  setPositionError(true);
                  setPositionInvalid(true);
                } else {
                  setPositionError(false);
                  setPositionInvalid(false);
                }
              }}
            />
          </Grid>

          <Grid item>
            <CssTextField
              label="Primary Email"
              size="small"
              className="textField"
              value={info.User.UserDetails.PersonalEmail}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={emailError}
              helperText={
                emailError
                  ? emailInvalid
                    ? "Enter a valid email"
                    : "Enter an email adress"
                  : ""
              }
              onChange={(e) => {
                setEmail(e.target.value);
                info.User.UserDetails.PersonalEmail = e.target.value;
                if (info.User.UserDetails.PersonalEmail === "") {
                  setEmailError(true);
                  setEmailInvalid(false);
                } else if (
                  (info.User.UserDetails.PersonalEmail !== "") &
                    !info.User.UserDetails.PersonalEmail.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    ) ||
                  info.User.UserDetails.PersonalEmail > 64
                ) {
                  setEmailError(true);
                  setEmailInvalid(true);
                } else {
                  setEmailError(false);
                  setEmailInvalid(false);
                }
              }}
            ></CssTextField>
          </Grid>

          <Grid item height={"5%"}>
            {fulled ? (
              <>
                <PhoneInput
                  placeholder="Enter phone number"
                  onlyCountries={isoCodes}
                  enableAreaCodes={true}
                  value={info.User.UserDetails.PhoneNumber}
                  onChange={(phone) => {
                    setCountry(phone);
                    info.User.UserDetails.PhoneNumber = phone;
                    if (
                      info.User.UserDetails.PhoneNumber === "" ||
                      info.User.UserDetails.PhoneNumber.length < 4 ||
                      info.User.UserDetails.PhoneNumber.length > 25
                    ) {
                      setCountryError(true);
                    } else {
                      setCountryError(false);
                    }
                  }}
                  country={localStorage.getItem("userCountry")?.toLowerCase()}
                  inputStyle={{
                    borderColor: "#707070",
                    width: "100%",
                    height: 40,
                    fontFamily: "Muli",
                    fontsize: "16px",
                  }}
                />
                {countryError ? (
                  <span
                    style={{
                      fontFamily: "Muli",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    Enter a valid Phone Number
                  </span>
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"}>
          <Grid item md={1}>
            <input
              className="checkBox"
              type="checkbox"
              defaultChecked={info.AgreementAcceptance}
              onChange={(e) => {
                setAgreeCheck(e.target.checked);
                info.AgreementAcceptance = e.target.checked;
                SetAgreeCheckError(!e.target.checked);
              }}
            ></input>
          </Grid>

          <Grid item md={11}>
            <span className="agreement">
              <span className="text-style-1">I agree to the </span>
              <a href="/#" style={{ textDecoration: "none", color: "#0b5cab" }}>
                Terms and Conditions.
              </a>
            </span>
          </Grid>
          {agreeCheckError ? (
            <span class="agreeError">
              Please read and agree to the terms and conditions.
            </span>
          ) : (
            ""
          )}
        </Grid>
        <Grid item md={12}>
          <span className="policy">
            By registering, you agree to the processing of your personal data by
            Pilecubes as described in the
            <span className="text-style-1"> Privacy Statement</span>
            <span className="text-style-2">.</span>
          </span>
        </Grid>
        <Grid item container md={12} direction={"row"} marginTop={3.5}>
          <Grid item md={8} xs={8}>
            <Button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#CED2E1";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
              }}
              onClick={(e) => {
                back(e);
                e.target.style.backgroundColor = "#fff";
                e.target.style.cursor = "pointer";
              }}
              variant="contained"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                width: "71px",
                height: "32px",
                fontSize: "19px",
                fontFamily: "Muli",
                backgroundColor: "#fff",
                color: "rgba(16, 22, 80, 0.7)",
                marginBottom: 4,
              }}
            >
              Back
            </Button>
            <button
              className="buttonNext"
              disabled={isDisabled || submittingLoading}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                e.target.style.cursor = "pointer";
                register(e);
              }}
              variant="contained"
              style={{
                marginLeft: "8%",
                textTransform: "none",
                borderRadius: "5px",
                width: "fit-content",
                height: "32px",
                fontSize: "19px",
                fontFamily: "Muli",
                backgroundColor: "#0b5cab",
              }}
            >
              {submittingLoading ? "Submitting..." : "Submit"}
            </button>
          </Grid>
          <Grid item md={4} xs={4} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 3 of 3</div>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          color="#101650"
          style={{ margin: "5px 10px 5px 10px" }}
        >
          {"Error Message"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color="#181818"
            style={{ margin: "5px 10px 5px 10px" }}
          >
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setHighlightError(true);
              setHighlightMsg(errMsg);
            }}
            autoFocus
            variant="contained"
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#101650";
              e.target.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#0b5cab";
            }}
            style={{
              margin: "5px 10px 5px 10px",
              textTransform: "none",
              borderRadius: 5,
              fontSize: 15,
              fontFamily: "Muli",
              backgroundColor: "#0b5cab",
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Form3;
