import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import { Grid, MenuItem, TextField, Checkbox, ListItemText } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import CreateForm from "./CreateForm";
import MachineSetup from "./MachineSetup";
import CuttingLength from "./CuttingLength";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import HumanResources from "../Storage Block/HumanResources";
import EquipmentResources from "../Storage Block/EquipmentResources";
import * as machineBlockServices from "../../../../Services/machine-block-services.proxy";
import * as MACHINECONSTS from "../../../../../FactoryConsts";
import MachineSequence from "./MachineSequence";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../../ReusableFunctions";
import * as salesOrderConst from "../../../../../salesOrderConst";
import * as productionCellServices from "../../../../Services/production-cell.proxy";


const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getMachineBlockInputData = machineBlockServices.getMachineBlockInputData;
const createMachine = machineBlockServices.createMachine;
const updateMachine = machineBlockServices.updateMachine;

const getProductionCellData = productionCellServices.getProductionCellData;
const createProductionCell = productionCellServices.addProductionCell;
const updateProductionCell = productionCellServices.updateProductionCell;

const MachineCreation = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [successMsgSaved, setSuccessMsgSaved] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [BAY_ID, setBAY_ID] = useState(query?.get("id"));
  const [MACHINE_ID, setMACHINE_ID] = useState(query?.get("machineId"));
  const [productionCellId, setProductionCellId] = useState(query?.get("pcId"));
  const [BAY_Nb, setBAY_Nb] = useState(query?.get("bayNb"));

  const [modify, setModify] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");

  //#region
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(0);
  const [subcategories, setSubCategories] = useState([]);
  const [subcategory, setSubCategory] = useState(null);
  const [subcategoryName, setSubCategoryName] = useState(null);

  const [code, setCode] = useState(null);
  const [name, setName] = useState(null);

  const [files, setFiles] = useState([]);
  const [filesIDS, setFilesIDS] = useState([]);
  const [status, setStatus] = useState("Active");
  const [statusId, setStatusId] = useState(1);

  //production cell
  const [productionCellMachines, setProductionCellMachines] = useState([]);
  const [machinesList, setMachinesList] = useState([]);

  //iot device
  const [iOTcode, setIOTCode] = useState(null);
  const [iOTPassword, setIOTPassword] = useState(null);

  const [iOTName, setIOTName] = useState(null);
  const [iOTOS, setIOTOS] = useState(null);
  const [isLabelPrinting, setIsLabelPrinting] = useState(null);
  const [printercode, setPrinterCode] = useState(null);
  const [printerName, setPrinterName] = useState(null);
  const [printerModel, setPrinterModel] = useState(null);
  //Machine Setup
  const [inputs, setInputs] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [inputsSelected, setInputsSelected] = useState([
    { BlockId: null, Zones: [] },
  ]);
  const [outputsSelected, setOutputsSelected] = useState([{ BlockId: null }]);
  const [inventories, setInventories] = useState([]);
  const [itemCategories, setItemCategories] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [standards, setStandards] = useState([]);
  const [grade, setGrade] = useState([]);
  const [coating, setCoating] = useState([]);
  const [spliceTypes, setSpliceTypes] = useState([])

  //cuttingLength
  const [minCuttingLength, setMinCuttingLength] = useState(null);
  const [maxCuttingLength, setMaxCuttingLength] = useState(null);
  const [minBendingLength, setMinBendingLength] = useState(null);
  const [maxBendingLength, setMaxBendingLength] = useState(null);
  const [minLengthBtwBendingPinsLength, setMinLengthbtwBendingPinsLength] =
    useState(null);
  const [minBendingNo, setMinBendingNo] = useState(null);
  const [maxBendingNo, setMaxBendingNo] = useState(null);
  const [bendingNo, setBendingNo] = useState(1);
  const [shapeTypes, setShapeTypes] = useState([]);
  const [shapeTypesSelected, setShapeTypesSelected] = useState([]);
  const [maxXLength, setMaxXLength] = useState(null);
  const [maxYLength, setMaxYLength] = useState(null);
  const [maxZLength, setMaxZLength] = useState(null);
  const [Bidirectional, setBidirectional] = useState(true);
  const [rMCategoryId, setRMCategoryId] = useState(null);

  //Resource Group
  const [resourceType, setResourceType] = useState(0);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [humanResources, setHumanResources] = useState([]);
  const [humanResourcesObject, setHumanResourcesObject] = useState();
  const [humanResourcesObjectChosen, setHumanResourcesObjectChosen] =
    useState(0);
  const [equipmentResources, setEquipmentResources] = useState([]);
  const [equipmentResourcesObject, setEquipmentResourcesObject] = useState();
  const [equipmentResourcesObjectChosen, setEquipmentResourcesObjectChosen] =
    useState(0);

  const [roleGroups, setRoleGroups] = useState([]);
  const [shiftCodes, setShiftCodes] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [nbOfResources, setNbOfResources] = useState(0);
  //Machine Squence
  const [MachineOutputCapicity, setMachineOutputCapicity] = useState(null);
  const [machineSequenceId, setMachineSequenceId] = useState(null);
  const [machinePredecessors, setMachinePredecessors] = useState([]);
  const [machineSucessors, setMachineSucessors] = useState([]);
  const [machinesAvailable, setMachinesAvailable] = useState([]);
  const [machinesSequences, setMachinesSequences] = useState([]);

  const tableBlockType = query.get("blockType");
  const isProductionCell = query.get("isPC");
  const [factoryId, setFactoryId] = useState(query.get("FactoryId"));
  const [noBlockError, setNoBlockError] = useState(false);
  const [BeErrorMsg, setBeErrorMsg] = useState("");

  //nvent new fields

  const [typeId, setTypeId] = useState(null);
  const [typeIds, setTypeIds] = useState([]); // Change to an array to store multiple selected values
  const [typeIdError, setTypeIdError] = useState(false);

  const [endType, setEndType] = useState(null);
  const [endTypeError, setEndTypeError] = useState(false)
  const [isDoubleEnd, setIsDoubleEnd] = useState(false);

  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(0);


  const machineEndTypes = [
    {
      Id: 1,
      Name: 'Single End'

    },
    {
      Id: 2,
      Name: 'Inline Double End Sample'

    }
  ]



  //#endregion

  useEffect(() => {
    if (isProductionCell === 'true') {
      setLoading(true);
      getProductionCellData(factoryId, productionCellId ? productionCellId : null).then(
        (x) => {
          if (x.status === 404) {
            setNoBlockError(true);
            setBeErrorMsg(x.error)
            setLoading(false);
          } else {
            if (x.ProductionCell) {
              setProductionCellMachines(x.ProductionCell?.Machines?.map((m) => m.Id))
              setIOTName(x.ProductionCell.IOTDeviceName);
              setName(x.ProductionCell.Name);
              setIOTOS(x.ProductionCell.IOTDeviceOS);
              setIOTCode(x.ProductionCell.IOTCode)
              setIOTPassword(x.ProductionCell.IOTDeviceName);
              setMachinesList(x.Machines)
            }
            else setMachinesList(x)

          }

          setLoading(false);
        }
      );

    }
    else {
      setLoading(true);
      getMachineBlockInputData(MACHINE_ID ? MACHINE_ID : null, BAY_ID).then(
        (x) => {
          if (x.status === 404) {
            setNoBlockError(true);
            setLoading(false);
          } else {
            setCategories(x.Categories);
            setSubCategories(x.SubCategories);
            setInputs(x.InputBlocks);
            setOutputs(x.OutputBlocks);
            setShapeTypes(x.ShapeTypes);
            setResourceTypes(x.ResourceTypes);
            setShiftCodes(x.Shifts);
            setEquipmentTypes(x.EquipmentTypes);
            setRoleGroups(x.RoleGroupTeamMembers);
            setMachinesAvailable(x.Machines);
            setMachinesSequences(x.Sequences);
            setSpliceTypes(x.SpliceTypes);
            setItemCategories(x.Inventory.Categories);
            setRMCategoryId(
              x?.Inventory?.Categories?.filter(
                (x) => x.Name === "Raw Material"
              )[0]?.Id
            );
            let ItemTypesBe = x?.Inventory?.Categories?.filter(
              (x) => x.Name === "Raw Material"
            )[0]?.Types;
            setItemTypes(ItemTypesBe);
            setStandards(
              x.Inventory?.References?.filter((x) => x.ReferenceId === 1)[0]
                ?.Values
            );
            setCoating(
              x.Inventory?.References?.filter((x) => x.ReferenceId === 3)[0]
                ?.Values
            );
            setGrade(
              x.Inventory?.References?.filter((x) => x.ReferenceId === 2)[0]
                ?.Values
            );

            if (MACHINE_ID) {
              setCode(x.Machine?.Code);
              setName(x.Machine?.Name);
              setStatus(x.Machine?.Status);
              setStatusId(x.Machine?.StatusId);

              setCategory(x.Machine.CategoryId);
              setSubCategory(x.Machine.SubCategoryId);
              setSubCategoryName(
                x.Machine.SubCategoryId
                  ? x.SubCategories.filter(
                    (x2) => x2.Id === x.Machine.SubCategoryId
                  )[0].Name
                  : null
              );
              setFiles(x.Machine.Files);
              let tempFileIds = [];
              x.Machine.Files.map((File) => {
                tempFileIds.push({ FileId: File.FileId });
              });
              setFilesIDS(tempFileIds);
              setIOTCode(x.Machine.IOTCode);
              setIOTName(x.Machine.IOTDeviceName);
              setIOTPassword(x.Machine.IOTDeviceName);
              setIOTOS(x.Machine.IOTDeviceOS);
              if (x.Machine.PrinterCode) {
                setIsLabelPrinting(true);
                setPrinterCode(x.Machine.PrinterCode);
                setPrinterName(x.Machine.PrinterDeviceName);
                setPrinterModel(x.Machine.Model);
              }

              setInputsSelected(
                x.Machine.Inputs.length > 0
                  ? x.Machine.Inputs
                  : [{ BlockId: null, Zones: [] }]
              );
              setOutputsSelected(
                x.Machine.Outputs.length > 0
                  ? x.Machine.Outputs
                  : [{ BlockId: null }]
              );
              setHumanResources(
                x.Machine.Resources
                  ? x.Machine.Resources?.filter((x) => x.TypeId === 1)
                  : []
              );
              setEquipmentResources(
                x.Machine.Resources
                  ? x.Machine.Resources?.filter((x) => x.TypeId === 2)
                  : []
              );
              setMinCuttingLength(x.Machine.MinCuttingLength);
              setMaxCuttingLength(x.Machine.MaxCuttingLength);
              setMinBendingLength(x.Machine.MinBendingLength);
              setMaxBendingLength(x.Machine.MaxBendingLength);
              setMinBendingNo(x.Machine.BendsMinNumber);
              setBendingNo(x.Machine.BendingHeadsNumber);
              setMaxBendingNo(x.Machine.BendsMaxNumber);
              setMaxXLength(x.Machine.MaxXLength);
              setMaxYLength(x.Machine.MaxYLength);
              setMaxZLength(x.Machine.MaxZLength);
              setBidirectional(x.Machine.Bidirectional);
              setShapeTypesSelected(x.Machine.ShapeTypes);
              setMinLengthbtwBendingPinsLength(
                x.Machine.BendingPinsMinDifferenceLength
              );
              setInventories(x.Machine.InputSetups);
              setMachinePredecessors(x.Machine.Predecessors);
              setMachineSucessors(x.Machine.Successors);
              setMachineOutputCapicity(x.Machine.OutputCapacityPerDay);
              setMachineSequenceId(x.Machine.SequenceId);
              setEndType(x.Machine.IsDoubleEnd ? 2 : 1)
              // setTypeId(x.Machine.IsTest ? 2 : 1)
              let tmp = [];
              if (x.Machine.IsTest) tmp.push(salesOrderConst.TEST_SAMPLE_ID)
              if (x.Machine.IsProduction) tmp.push(salesOrderConst.PRODUCTION_ID)
              setTypeIds(tmp)
              setMinLength(x.Machine.MinLength)
              setMaxLength(x.Machine.MaxLength)
            }
          }

          setLoading(false);
        }
      );
    }

  }, [modify]);
  const MachineData = {
    Id: MACHINE_ID ? MACHINE_ID : null,
    BayId: BAY_ID ? BAY_ID : null,
    Code: code,
    Name: name,
    CategoryId: category,
    SubCategoryId: subcategory,
    SubCategory: subcategoryName,
    SubCategories: subcategories,
    Files: filesIDS,
    IOTCode: iOTcode,
    IOTDeviceName: iOTName,
    IOTDevicePassword: iOTPassword,
    IOTDeviceOS: iOTOS,
    PrinterCode: printercode,
    PrinterDeviceName: printerName,
    Model: printerModel,
    MinBendingLength: minBendingLength,
    MaxBendingLength: maxBendingLength,
    BendingPinsMinDifferenceLength: minLengthBtwBendingPinsLength,
    BendsMinNumber: minBendingNo,
    BendsMaxNumber: maxBendingNo,
    BendingHeadsNumber: bendingNo,
    MinCuttingLength: minCuttingLength,
    MaxCuttingLength: maxCuttingLength,
    MaxXLength: maxXLength,
    MaxYLength: maxYLength,
    MaxZLength: maxZLength,
    Bidirectional: Bidirectional ? 1 : 0,
    OutputCapacityPerDay: MachineOutputCapicity,
    ShapeTypes: !MACHINE_ID ? null : shapeTypesSelected,
    Inputs: !MACHINE_ID ? null : inputsSelected,
    Outputs: !MACHINE_ID ? null : outputsSelected,
    InputSetups: !MACHINE_ID ? null : inventories,
    Resources: !MACHINE_ID ? null : humanResources?.concat(equipmentResources),
    Predecessors: !machinePredecessors ? null : machinePredecessors,
    Successors: !machineSucessors ? null : machineSucessors,
    SequenceId: machineSequenceId,
    IsTest: typeIds.find((t) => t === salesOrderConst.TEST_SAMPLE_ID) ? true : false,
    IsProduction: typeIds.find((t) => t === salesOrderConst.PRODUCTION_ID) ? true : false,
    MinLength: minLength,
    MaxLength: maxLength,
    IsDoubleEnd: endType === 1 ? false : true
  };

  const productionCellData = {
    Name: name,
    IOTCode: iOTcode,
    IOTDeviceName: iOTName,
    IOTDevicePassword: iOTPassword,
    IOTDeviceOS: iOTOS,
    FactoryId: factoryId,
    Machines: productionCellMachines
  }

  const saveProductionCell = () => {
    if (productionCellId) {
      setSaveLoading(true);
      updateProductionCell(productionCellId, productionCellData).then((x) => {
        setModify(!modify);
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
      });
    } else {
      setSaveLoading(true);
      createProductionCell(productionCellData).then((x) => {
        // navigate(
        //   `/saas/factory/bay/machineBlock?machineId=${x}&bayNb=${BAY_Nb}&id=${BAY_ID}&FactoryId=${factoryId}`
        // );
        // setMACHINE_ID(x);
        // setModify(!modify);
        setSaveLoading(false);
        navigate(
          `/saas/blocksList?BayId=${BAY_ID}&BayNb=${
            BAY_Nb
          }&FactoryId=${factoryId}&blockType=4`
        );
        // setSuccessMsgSaved(true);
        // setTimeout(() => {
        //   setSuccessMsgSaved(false);
        // }, 3000);
      });
    }

  }

  const saveMachine = () => {
    setSaveLoading(true);
    if (MACHINE_ID) {
      updateMachine(MachineData).then((x) => {
        setModify(!modify);
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
      });
    } else {
      createMachine(MachineData).then((x) => {
        navigate(
          `/saas/factory/bay/machineBlock?machineId=${x}&bayNb=${BAY_Nb}&id=${BAY_ID}&FactoryId=${factoryId}`
        );
        setMACHINE_ID(x);
        setModify(!modify);
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
      });
    }
  };
  const CheckEmptyFields = () => {
    let inputOrOutputEmpty = false;
    inputsSelected.map((inp) => {
      if (inp.BlockId === null || inp.BlockId === 0) {
        inputOrOutputEmpty = true;
        setEmptyField(true);
        setEmptyMessage("Empty Field in the Input Block");
      } else if (
        inputs.filter((x) => x.BlockId === inp.BlockId)[0]?.Zones &&
        inp.Zones.length === 0
      ) {
        inputOrOutputEmpty = true;
        setEmptyField(true);
        setEmptyMessage("Empty Field in the Input Block");
      }
    });
    if (!inputOrOutputEmpty) {
      outputsSelected.map((outp) => {
        if (outp.BlockId === null || outp.BlockId === 0) {
          inputOrOutputEmpty = true;
          setEmptyField(true);
          setEmptyMessage("Empty Field in the Output Block");
        }
      });
    }
    if (
      !inputOrOutputEmpty &&
      (inventories.length === 0 || inventories === null)
    ) {
      inputOrOutputEmpty = true;
      setEmptyField(true);
      setEmptyMessage("Add a machine input setup");
    }
    if (!inputOrOutputEmpty && !MachineOutputCapicity) {
      inputOrOutputEmpty = true;
      setEmptyField(true);
      setEmptyMessage("Add a machine output capacity");
    }

    if (
      !inputOrOutputEmpty &&
      (category === MACHINECONSTS.CUTTING_MACHINE_ID ||
        category === MACHINECONSTS.STIRRUP_MACHINE_ID)
    ) {
      if (
        minCuttingLength === 0 ||
        !minCuttingLength ||
        !maxCuttingLength ||
        maxCuttingLength === 0
      ) {
        inputOrOutputEmpty = true;
        setEmptyField(true);
        setEmptyMessage("Empty Field in Cutting Length");
      } else {
        setEmptyField(false);
      }
    }
    if (
      !inputOrOutputEmpty &&
      (category === MACHINECONSTS.BENDING_2D_MACHINE_ID ||
        category === MACHINECONSTS.STIRRUP_MACHINE_ID ||
        category === MACHINECONSTS.BENDING_3D_MACHINE_ID)
    ) {
      if (
        minBendingLength === 0 ||
        !minBendingLength ||
        !maxBendingLength ||
        maxBendingLength === 0 ||
        !minBendingNo ||
        minBendingNo === 0 ||
        !maxBendingNo ||
        maxBendingNo === 0 ||
        (bendingNo > 1 && !minLengthBtwBendingPinsLength) ||
        minLengthBtwBendingPinsLength === 0 ||
        shapeTypesSelected.length === 0 ||
        maxXLength === 0 ||
        !maxXLength ||
        maxYLength === 0 ||
        !maxYLength
      ) {
        inputOrOutputEmpty = true;
        setEmptyField(true);
        setEmptyMessage("Empty Field in Bending Length");
      } else {
        setEmptyField(false);
      }
    }
    if (
      !inputOrOutputEmpty &&
      category === MACHINECONSTS.BENDING_3D_MACHINE_ID
    ) {
      if (!maxZLength || maxZLength === 0) {
        setEmptyField(true);
        setEmptyMessage("Empty Field in Bending Length");
      } else {
        setEmptyField(false);
      }
    }
    if (!inputOrOutputEmpty) {
      setEmptyField(false);
      setEmptyMessage("");
      saveMachine();
    }
  };
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledAddResource, setDisabledAddResource] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions && MACHINE_ID) {
      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "Edit",
        setDisabledEdit
      );

      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "Add resource",
        setDisabledAddResource
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={2.5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              Bay # {BAY_Nb}
            </Grid>
          </Grid>

          <Grid
            item
            lg={statusId === 2 ? 7 : 8.6}
            sm={statusId === 2 ? 6 : 7.3}
            xs={3}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          {statusId === 2 ? (
            <Grid item lg={1.5} sm={1.5}>
              <button
                className={classing["SaveBut"]}
                onClick={() => {
                  navigate(
                    `/saas/factory/bay/machineBlock/holdedOrders?id=${MACHINE_ID}&name=${name}`
                  );
                }}
              >
                View Onhold Orders
              </button>
            </Grid>
          ) : (
            ""
          )}

          <Grid item lg={0.9} sm={1}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                if (tableBlockType)
                  navigate(
                    `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=2`
                  );
                else navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          paddingLeft={{ xs: "0%", sm: "1%", md: "3.2%" }}
          fontWeight={"normal"}
          direction={"row"}
        >
          <Grid
            item
            md={1.5}
            xs={2.5}
            paddingLeft={{ xs: 2, md: 0 }}
            className={classing["Title"]}
          >
            {isProductionCell === 'true' ?
              "Production Cell" : "Machine Block"
            }

          </Grid>
          {humanResources?.length > 0 ? (
            <Grid item container xs={3} direction={"row"} gap={0.5}>
              <Grid item className={classing["Title"]}>
                Human Resources
              </Grid>
              <Grid item>
                <CustomSelect2
                  style={{ color: "#707070" }}
                  value={humanResourcesObjectChosen}
                  onChange={(option) => {
                    setHumanResourcesObjectChosen(option);
                    const objectToView = humanResources.filter(
                      (x) => x.Code === option
                    );
                    setHumanResourcesObject(objectToView[0]);
                  }}
                >
                  <StyledOption value={0} disabled>
                    code
                  </StyledOption>
                  {humanResources?.map((s) => (
                    <StyledOption key={s.Id} value={s.Code}>
                      {s.Code}
                    </StyledOption>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {equipmentResources?.length > 0 ? (
            <Grid item container xs={3} direction={"row"} gap={0.5}>
              <Grid item className={classing["Title"]}>
                Equipment Resources
              </Grid>
              <Grid item>
                <CustomSelect2
                  style={{ color: "#707070" }}
                  value={equipmentResourcesObjectChosen}
                  onChange={(option) => {
                    setEquipmentResourcesObjectChosen(option);
                    const objectToView = equipmentResources.filter(
                      (x) => x.Name === option
                    );
                    setEquipmentResourcesObject(objectToView[0]);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Name
                  </StyledOption>
                  {equipmentResources?.map((s) => (
                    <StyledOption key={s.Id} value={s.Name}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {noBlockError ? (
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={12}
            fontWeight={"bold"}
            color={"#ea001e"}
          >
            {isProductionCell === 'true' ?
              <>{BeErrorMsg}</> :
              "PLease create handling blocks and storage blocks before creating machines"
            }

          </Grid>
        ) : (
          <Grid
            item
            container
            xs={11.3}
            paddingTop="1%"
            alignItems="flex-start"
            gap={2}
            marginBottom={2}
          >
            {loading ? (
              <Grid item xs={12} container justifyContent="center">
                <Grid item paddingTop="5%">
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid item container xs={12} gap={2}>
                <Grid
                  item
                  sm={11}
                  xs={12}
                  md={8}
                  lg={6.5}
                  gap={1}
                  bgcolor={"#fff"}
                  paddingBottom={2}
                >
                  <CreateForm
                    isPC={isProductionCell}
                    machinesList={machinesList}
                    productionCellMachines={productionCellMachines}
                    setProductionCellMachines={setProductionCellMachines}
                    subcategories={subcategories}
                    setSubCategories={setSubCategories}
                    subcategory={subcategory}
                    setSubCategory={setSubCategory}
                    categories={categories}
                    category={category}
                    setCategory={setCategory}
                    code={code}
                    setCode={setCode}
                    files={files}
                    setFiles={setFiles}
                    filesIDS={filesIDS}
                    setFilesIDS={setFilesIDS}
                    BAY_ID={BAY_ID}
                    status={status}
                    iOTcode={iOTcode}
                    setIOTCode={setIOTCode}
                    iOTName={iOTName}
                    setIOTName={setIOTName}
                    iOTOS={iOTOS}
                    setIOTOS={setIOTOS}
                    isLabelPrinting={isLabelPrinting}
                    setIsLabelPrinting={setIsLabelPrinting}
                    printercode={printercode}
                    setPrinterCode={setPrinterCode}
                    printerName={printerName}
                    setPrinterName={setPrinterName}
                    printerModel={printerModel}
                    setPrinterModel={setPrinterModel}
                    saveMachine={saveMachine}
                    saveProductionCell={saveProductionCell}
                    isdisabled={isdisabled}
                    setIsDisabled={setIsDisabled}
                    MACHINE_ID={MACHINE_ID}
                    setSubCategoryName={setSubCategoryName}
                    name={name}
                    setName={setName}
                    iOTPassword={iOTPassword}
                    setIOTPassword={setIOTPassword}
                    disabledEdit={disabledEdit}
                    productionCellId={productionCellId}
                    saveLoading={saveLoading}
                  />
                  {MACHINE_ID && isProductionCell !== 'true' ? (
                    <>
                      <Grid xs={12} item marginTop={2}>
                        <MachineSetup
                          inputs={inputs}
                          outputs={outputs}
                          inputsSelected={inputsSelected}
                          setInputsSelected={setInputsSelected}
                          outputsSelected={outputsSelected}
                          setOutputsSelected={setOutputsSelected}
                          inventories={inventories}
                          setInventories={setInventories}
                          itemCategories={itemCategories}
                          itemTypes={itemTypes}
                          standards={standards}
                          grade={grade}
                          coating={coating}
                          category={category}
                          rMCategoryId={rMCategoryId}
                          disabledEdit={disabledEdit}
                          spliceTypes={spliceTypes}
                        />
                      </Grid>
                      <Grid xs={12} item marginTop={2}>
                        <MachineSequence
                          MachineOutputCapicity={MachineOutputCapicity}
                          setMachineOutputCapicity={setMachineOutputCapicity}
                          machinePredecessors={machinePredecessors}
                          setMachinePredecessors={setMachinePredecessors}
                          machineSucessors={machineSucessors}
                          setMachineSucessors={setMachineSucessors}
                          machinesAvailable={machinesAvailable}
                          setMachinesAvailable={setMachinesAvailable}
                          machineSequenceId={machineSequenceId}
                          setMachineSequenceId={setMachineSequenceId}
                          machinesSequences={machinesSequences}
                          MACHINE_ID={MACHINE_ID}
                          disabledEdit={disabledEdit}
                          category={category}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CuttingLength
                          category={category}
                          minCuttingLength={minCuttingLength}
                          setMinCuttingLength={setMinCuttingLength}
                          maxCuttingLength={maxCuttingLength}
                          setMaxCuttingLength={setMaxCuttingLength}
                          minBendingLength={minBendingLength}
                          setMinBendingLength={setMinBendingLength}
                          maxBendingLength={maxBendingLength}
                          setMaxBendingLength={setMaxBendingLength}
                          minLengthBtwBendingPinsLength={
                            minLengthBtwBendingPinsLength
                          }
                          setMinLengthbtwBendingPinsLength={
                            setMinLengthbtwBendingPinsLength
                          }
                          maxBendingNo={maxBendingNo}
                          setMaxBendingNo={setMaxBendingNo}
                          minBendingNo={minBendingNo}
                          setMinBendingNo={setMinBendingNo}
                          bendingNo={bendingNo}
                          setBendingNo={setBendingNo}
                          shapeTypes={shapeTypes}
                          setShapeTypes={setShapeTypes}
                          shapeTypesSelected={shapeTypesSelected}
                          setShapeTypesSelected={setShapeTypesSelected}
                          maxXLength={maxXLength}
                          setMaxXLength={setMaxXLength}
                          maxYLength={maxYLength}
                          setMaxYLength={setMaxYLength}
                          maxZLength={maxZLength}
                          setMaxZLength={setMaxZLength}
                          Bidirectional={Bidirectional}
                          setBidirectional={setBidirectional}
                          disabledEdit={disabledEdit}
                        // typeId = {typeId}
                        // setTypeId = {setTypeId}
                        // typeIdError ={typeIdError}
                        // setTypeIdError = {setTypeIdError}
                        // endType = {endType}
                        />
                      </Grid>

                      {
                        category === MACHINECONSTS.CUTTING_MACHINE_ID ?

                          <Grid item container xs={12} md={3} direction="column" spacing={1} margin={2}>
                            <Grid item className={classing["TFTitle"]}>
                              *Type (Production/Test Sample)
                            </Grid>
                            <Grid item>
                              {/* <TextField
                                disabled={
                                  disabledEdit
                                }
                                select
                                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                value={typeId}

                                error={typeIdError}
                                helperText={typeIdError ? "Choose type" : ""}
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: "40px",
                                    fontSize: 12,
                                    fontFamily: "Muli",
                                  },
                                }}
                                placeholder="Type"
                                onBlur={(e) => {
                                  if (!e.target.value) {
                                    setTypeIdError(true);
                                  }
                                  else setTypeIdError(false);
                                }}
                                onChange={(e) => {
                                  setTypeId(e.target.value);
                                  if (!e.target.value) {
                                    setTypeIdError(true);
                                  } else setTypeIdError(false);
                                }}
                              >

                                {salesOrderConst.salesOrderTypes?.map((r) => (
                                  <MenuItem
                                    value={r.Id}
                                    key={r.Id}
                                    style={{ display: "block" }}
                                  >
                                    {r.Name}
                                  </MenuItem>
                                ))}
                              </TextField> */}

                              <TextField
                                disabled={disabledEdit}
                                select
                                SelectProps={{
                                  multiple: true, // Enable multi-select
                                  MenuProps: { disableScrollLock: true },
                                }}
                                value={typeIds}
                                error={typeIds.length === 0 && typeIdError} // Check if no items are selected and if there's an error
                                helperText={typeIds.length === 0 && typeIdError ? "Choose type" : ""}
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: "40px",
                                    fontSize: 12,
                                    fontFamily: "Muli",
                                  },
                                }}
                                placeholder="Type"
                                onBlur={(e) => {
                                  if (typeIds.length === 0) {
                                    setTypeIdError(true);
                                  } else {
                                    setTypeIdError(false);
                                  }
                                }}
                                onChange={(e) => {
                                  const selectedValues = e.target.value;
                                  setTypeIds(selectedValues);
                                  if (selectedValues.length === 0) {
                                    setTypeIdError(true);
                                  } else {
                                    setTypeIdError(false);
                                  }
                                }}
                              >
                                {salesOrderConst.salesOrderTypes?.map((r) => (
                                  <MenuItem value={r.Id} key={r.Id}>
                                    {r.Name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          :
                          <></>
                      }
                      {
                        category === MACHINECONSTS.FORGING_MACHINE_ID ||
                          category === MACHINECONSTS.FRICTION_WELDING_MACHINE_ID ||
                          category === MACHINECONSTS.THREADING_MACHINE_ID ||
                          category === MACHINECONSTS.TRIMMING_MACHINE_ID


                          ? (
                            <>
                              <Grid container direction='row' margin={2} columnGap={2}>
                                <Grid item container xs={12} md={3} direction="column" spacing={1}>
                                  <Grid item className={classing["TFTitle"]}>
                                    *Type (Production/Test Sample)
                                  </Grid>
                                  <Grid item>

                                    <TextField
                                      disabled={disabledEdit}
                                      select
                                      SelectProps={{
                                        multiple: true, // Enable multi-select
                                        MenuProps: { disableScrollLock: true },
                                      }}
                                      value={typeIds}
                                      error={typeIds.length === 0 && typeIdError} // Check if no items are selected and if there's an error
                                      helperText={typeIds.length === 0 && typeIdError ? "Choose type" : ""}
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          height: "40px",
                                          fontSize: 12,
                                          fontFamily: "Muli",
                                        },
                                      }}
                                      placeholder="Type"
                                      onBlur={(e) => {
                                        if (typeIds.length === 0) {
                                          setTypeIdError(true);
                                        } else {
                                          setTypeIdError(false);
                                        }
                                      }}
                                      onChange={(e) => {
                                        const selectedValues = e.target.value;
                                        setTypeIds(selectedValues);
                                        if (selectedValues.length === 0) {
                                          setTypeIdError(true);
                                        } else {
                                          setTypeIdError(false);
                                        }
                                      }}
                                    >
                                      {salesOrderConst.salesOrderTypes?.map((r) => (
                                        <MenuItem value={r.Id} key={r.Id}>
                                          {r.Name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>

                                <Grid item container xs={12} md={3} direction="column" spacing={1}>
                                  <Grid item className={classing["TFTitle"]}>
                                    *Type (Single/ Inline Double End)
                                  </Grid>
                                  <Grid item>
                                    <TextField
                                      disabled={
                                        disabledEdit
                                      }
                                      select
                                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                      value={endType}

                                      error={endTypeError}
                                      helperText={endTypeError ? "Choose type" : ""}
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          height: "40px",
                                          fontSize: 12,
                                          fontFamily: "Muli",
                                        },
                                      }}
                                      placeholder="Type"
                                      onBlur={(e) => {
                                        if (!e.target.value) {
                                          setEndTypeError(true);
                                        }
                                        else setEndTypeError(false);
                                      }}
                                      onChange={(e) => {
                                        setEndType(e.target.value);
                                        if (!e.target.value) {
                                          setEndTypeError(true);
                                        } else setEndTypeError(false);
                                      }}
                                    >

                                      {machineEndTypes?.map((r) => (
                                        <MenuItem
                                          value={r.Id}
                                          key={r.Id}
                                          style={{ display: "block" }}
                                        >
                                          {r.Name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>

                              </Grid>
                              <Grid item container margin={2}>
                                <Grid item xs={12} paddingTop={1} className={classing["Title"]}>
                                  Min/Max Length
                                </Grid>

                                <Grid container gap={2} direction={"row"}>
                                  <Grid item sm={3} xs={12}>
                                    <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                                      *Min (mm)
                                    </Grid>
                                    <Grid item>
                                      <TextField
                                        disabled={disabledEdit}
                                        value={minLength}
                                        fullWidth
                                        type="number"
                                        InputProps={{
                                          style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                                          inputProps: { min: 0 },
                                        }}
                                        placeholder="Number"
                                        onChange={(e) => {
                                          setMinLength(e.target.value);
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item sm={3} xs={12}>
                                    <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                                      *Max (mm)
                                    </Grid>
                                    <Grid item>
                                      <TextField
                                        disabled={disabledEdit}
                                        value={maxLength}
                                        fullWidth
                                        type="number"
                                        InputProps={{
                                          style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                                          inputProps: { min: 0 },
                                        }}
                                        placeholder="Number"
                                        onChange={(e) => {
                                          setMaxLength(e.target.value);
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            ""
                          )}

                      <Grid
                        item
                        container
                        xs={12}
                        direction={"row"}
                        paddingLeft={3}
                        paddingRight={3}
                        gap={2}
                      >
                        <Grid item>
                          <CustomSelect
                            style={{ color: "#707070" }}
                            value={resourceType}
                            disabled={
                              disabledAddResource ||
                                localStorage
                                  ?.getItem("planIdManu")
                                  ?.includes("Core")
                                ? true
                                : humanResourcesObject &&
                                  equipmentResourcesObject
                                  ? true
                                  : false
                            }
                            onChange={(option) => {
                              setResourceType(option);
                              if (option === 1) {
                                setHumanResourcesObject({
                                  Id: humanResources.length + 1,
                                  Code: null,
                                  GroupPermissionId: 0,
                                  TeamMemberId: 0,
                                  ShiftId: 0,
                                  TypeId: 1,
                                  Barcode: "",
                                });
                              } else if (option === 2) {
                                setEquipmentResourcesObject({
                                  Id: equipmentResources.length + 1,
                                  Code: null,
                                  Name: null,
                                  EquipmentTypeId: null,
                                  TypeId: 2,
                                  Manufacturer: null,
                                  ManufacturerProductId: null,
                                  ModelYear: null,
                                  ManufacturerSerialNumber: null,
                                });
                              }
                              window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                          >
                            <StyledOption value={0} disabled>
                              + Add a Resource
                            </StyledOption>
                            {resourceTypes?.map((s) => (
                              <StyledOption key={s.Id} value={s.Id}>
                                {s.Name}
                              </StyledOption>
                            ))}
                          </CustomSelect>
                        </Grid>
                        <Grid
                          item
                          className={classing["titlesForResources"]}
                          paddingTop={1}
                        >
                          Human Resources: &nbsp;
                          {+" " + humanResources?.length
                            ? humanResources.length
                            : 0}
                        </Grid>
                        <Grid
                          item
                          className={classing["titlesForResources"]}
                          paddingTop={1}
                        >
                          Equipment Resources: &nbsp;
                          {equipmentResources?.length
                            ? equipmentResources.length
                            : 0}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        direction={"row-reverse"}
                        gap={2}
                        paddingRight={3}
                        alignItems="center"
                      >
                        <button
                          className={classing["SaveBut"]}
                          disabled={isdisabled || saveLoading}
                          onClick={() => {
                            CheckEmptyFields();
                          }}
                        >
                          {saveLoading ? "Saving" : "Save"}
                        </button>
                        <button
                          onClick={() => {
                            // if (tableBlockType)
                            navigate(
                              `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=2`
                            );
                            //else navigate(-1);
                          }}
                          className={classing["cancelbut"]}
                        >
                          Cancel
                        </button>
                        <Grid
                          item
                          fontFamily={"Muli"}
                          fontSize={12}
                          fontWeight={"bold"}
                          color={"#ea001e"}
                        >
                          {emptyField ? emptyMessage : ""}
                        </Grid>
                        {successMsgSaved ? (
                          <>
                            <Grid item className={classing["TFTitle"]}>
                              Succesfully Saved
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item md={4.5} gap={2}>
                  {humanResourcesObject ? (
                    <HumanResources
                      humanResourcesObject={humanResourcesObject}
                      setHumanResourcesObject={setHumanResourcesObject}
                      humanResources={humanResources}
                      setHumanResources={setHumanResources}
                      roleGroups={roleGroups}
                      shiftCodes={shiftCodes}
                      nbOfResources={nbOfResources}
                      setNbOfResources={setNbOfResources}
                      setHumanResourcesObjectChosen={
                        setHumanResourcesObjectChosen
                      }
                      setResourceType={setResourceType}
                    />
                  ) : (
                    ""
                  )}
                  {equipmentResourcesObject ? (
                    <Grid item marginTop={2}>
                      <EquipmentResources
                        equipmentResourcesObject={equipmentResourcesObject}
                        setEquipmentResourcesObject={
                          setEquipmentResourcesObject
                        }
                        equipmentResources={equipmentResources}
                        nbOfResources={nbOfResources}
                        setNbOfResources={setNbOfResources}
                        setEquipmentResources={setEquipmentResources}
                        equipmentTypes={equipmentTypes}
                        setEquipmentResourcesObjectChosen={
                          setEquipmentResourcesObjectChosen
                        }
                        setResourceType={setResourceType}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MachineCreation;
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 12px;
        box-sizing: border-box;
        width: 150px;
        height:35px;
        background: #0674b9;
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        text-align: left;
        color: #fff !important;
        padding:5px 7px 5px 5px ;
        border: solid 1px #bcbcbc;
        &:hover {
          background: #fff;
          color:#0674b9 !important;
          cursor: pointer;
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
          box-shadow: 0 3px 6px 0 #0674b9;
          background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
          border: solid 1px #0674b9;
          color:#0674b9 !important;
        }
      
        &::after {
          content: '▾';
          float: right;
          padding-left:8px;
        }
        `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        width:150px;
        max-height:250px !important;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
        border-radius: 0.75em;
        color: #444;
        overflow: auto;
        outline: 0px;
        `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: pointer;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #fff;
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected
    } {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #0674b9;
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color:#fff;
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
const CustomSelect2 = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton2,
    Listbox: StyledListbox2,
    Popper: StyledPopper2,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
const StyledButton2 = styled("button")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 12px;
        box-sizing: border-box;
        width: 75px;
        height:20px;
        background: #f1f1f1;
       
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        text-align: left;
        color:#0674b9 !important;
        padding:5px 7px 20px 7px ;
        border: solid 1px #bcbcbc;
        &:hover {
          background: #fff;
          color:#0674b9 !important;
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
          box-shadow: 0 3px 6px 0 #0674b9;
          background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
          border: solid 1px #0674b9;
          color:#0674b9 !important;
        }
      
        &::after {
          content: '▾';
          float: right;
          padding-left:8px;
          color:#0674b9 !important;
        }
        `
);

const StyledListbox2 = styled("ul")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        width:150px;
        max-height:250px !important;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
        border-radius: 0.75em;
        color: #444;
        overflow: auto;
        outline: 0px;
        `
);

const StyledOption2 = styled(OptionUnstyled)(
  ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: default;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #fff;
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected
    } {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #0674b9;
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color:#fff;
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
);

const StyledPopper2 = styled(PopperUnstyled)`
  z-index: 1;
`;
