import React, { useState, useEffect } from 'react';
import { Modal, Grid, Dialog, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";


const DatePickerModal = (props) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);

        props.setOpenDatePicker(true);
    };

    const handleClose = () => {
        props.setOpenDatePicker(false);
        setOpen(false);
    };

    useEffect(() => {
        if (props.openDatePicker === true) handleOpen();
        else handleClose();
    }, [props.openDatePicker]);

    return (
        <Dialog
            disableScrollLock
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid item container
                style={{
                    top: '80%',
                    left: '50%',
                    backgroundColor: '#ffffff',
                    // border: 'solid 1px #518de1',
                    padding: '10px',
                    borderRadius: '5px',
                    fontFamily: "Muli"
                }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={props.selectedDate}
                        onChange={(newValue) => {
                            console.log("new value", newValue)
                            props.setSelectedDate(newValue);
                            handleClose();
                        }}
                        renderInput={(params) => (
                            <TextField {...params} size={"small"} />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
        </Dialog>
    )
}

export default DatePickerModal