import React, { useRef, useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';

const MapContainer = (props) => {
  const mapRef = useRef(null);
  const searchBox = useRef(null);
  const [mapCenter, setMapCenter] = useState({ lat: +props.latitude, lng: +props.longitude }); // Default center (New York)
  const [markers, setMarkers] = useState([]);



  const onPlacesChanged = () => {
    const places = searchBox.current.getPlaces();

    if (places.length === 0) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    const newMarkers = places.map((place) => {
      if (!place.geometry) {
        console.log('Returned place contains no geometry');
        return null;
      }

      bounds.extend(place.geometry.location);
      console.log("test", place.geometry.location.toJSON())
      let tmp = place.geometry.location.toJSON()
      props.setLatitude(tmp.lat);
      props.setLongitude(tmp.lng);
      return {
        position: place.geometry.location.toJSON(),
        title: place.name,
      };
    });

    setMarkers(newMarkers.filter((marker) => marker !== null));

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (props.latitude && props.longitude) {
      setMapCenter({ lat: +props.latitude, lng: +props.longitude });
    } else {
      setMapCenter({ lat: 33.20452102259101, lng: 35.04299445350869 });
    }
  }, [props.latitude, props.longitude]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyC9tGsUWxcba0n7MsBEDOU34dssrO31KV0" libraries={['places']} > {/* Replace with your actual API key */}
      <GoogleMap
        center={mapCenter}
        zoom={12}
        mapContainerStyle={{ width: '100%', height: '400px' }}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        <StandaloneSearchBox
          onLoad={(searchBoxInstance) => {
            searchBox.current = searchBoxInstance;
          }}
          onPlacesChanged={onPlacesChanged}
          options={{ bounds: null, strictBounds: false }}
        >
          <input
            type="text"
            placeholder="Search for a location"
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '240px',
              height: '32px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipses',
              position: 'absolute',
              left: '50%',
              marginLeft: '-120px',
            }}
          />
        </StandaloneSearchBox>

        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position} title={marker.title} />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
