import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import * as cartesian from "cartesian";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import RawMaterialSection from "./RawMaterialSection";
import ProductFirstSection from "./Product/ProductFirstSection";
import ProductSecondSection from "./Product/ProductSecondSection";
import { active } from "sortablejs";

const getReferenceInputData = inventoryServices.getReferenceInputData;
const createInventoryReference = inventoryServices.createInventoryReference;
const updateInventoryReference = inventoryServices.updateInventoryReference;

const getProductReferenceData = inventoryServices.getProductReferenceData;
const createProductReference = inventoryServices.createProductReference;
const updateProductReference = inventoryServices.updateProductReference;
const getProductGradeDiameterData = inventoryServices.getProductGradeDiameterData;
const addProductReferenceGradeDiameter = inventoryServices.addProductReferenceGradeDiameter;

const ItemReference = (props) => {


  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [activeTab, setActiveTab] = useState("RM")

  //Raw Material
  const [emptyField, setEmptyField] = useState(false);
  const [emptyFieldMessage, setEmptyFieldMessage] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [loadingProductData, setLoadingProductData] = useState(false);

  const [saving, setSaving] = useState(false);
  const [modify, setModify] = useState(false);

  const [nbOfReferences, setNbOfReferences] = useState("3");

  const [isdisabled, setIsDisabled] = useState(false);

  const [referencesNames, setReferencesNames] = useState([]);

  const [references, setReferences] = useState([
    {
      Id: null,
      ReferenceId: 1,
      Values: [{ value: null }],
    },
    {
      Id: null,
      ReferenceId: 2,
      Values: [{ value: null }],
    },
    {
      Id: null,
      ReferenceId: 3,
      Values: [{ value: null }],
    },
  ]);
  const [referenceGroup, setReferenceGroup] = useState([]);

  //Product
  const [inputSetups, setInputSetups] = useState([
    // {
    //   Id: "3A1F8F69-3090-EE11-ABA9-0272353759A1",
    //   ItemTypeId: "83A8A7DB-E189-EE11-ABA9-0272353759A1",
    //   SupplierId: "332B5135-DD89-EE11-ABA9-0272353759A1",
    //   BrandId: 40,
    //   SubCategories: [
    //     {
    //       Id: "1601D6D4-3090-EE11-ABA9-0272353759A1",
    //       SubCategoryId: 1,
    //       SpliceTypeNumber: 1,
    //       Treatments: [
    //         {
    //           Id: "1901D6D4-3090-EE11-ABA9-0272353759A1",
    //           TreatmentId: 1,
    //           Grades: [
    //             {
    //               "Id": "1C01D6D4-3090-EE11-ABA9-0272353759A1",
    //               "GradeId": 1,
    //               "ItemSeries": "Erico Coupler Standard Plain A11"
    //             }
    //           ]
    //         }
    //       ],
    //       Grades: [1]

    //     }
    //   ]
    // },
    // {
    //   Id: "3B1F8F69-3090-EE11-ABA9-0272353759A1",
    //   ItemTypeId: "83A8A7DB-E189-EE11-ABA9-0272353759A1",
    //   SupplierId: "332B5135-DD89-EE11-ABA9-0272353759A1",
    //   BrandId: 39,
    //   SubCategories: [
    //     {
    //       Id: "1701D6D4-3090-EE11-ABA9-0272353759A1",
    //       SubCategoryId: 1,
    //       SpliceTypeNumber: 1,
    //       Treatments: [
    //         {
    //           Id: "1A01D6D4-3090-EE11-ABA9-0272353759A1",
    //           TreatmentId: 1,
    //           Grades: [
    //             {
    //               Id: "1D01D6D4-3090-EE11-ABA9-0272353759A1",
    //               GradeId: 1,
    //               ItemSeries: "Erico Coupler Standard Plain A1"
    //             }
    //           ]
    //         }
    //       ],
    //       Grades: [1]

    //     }
    //   ]
    // },
    // {
    //   Id: "1501D6D4-3090-EE11-ABA9-0272353759A1",
    //   ItemTypeId: "83A8A7DB-E189-EE11-ABA9-0272353759A1",
    //   SupplierId: "332B5135-DD89-EE11-ABA9-0272353759A1",
    //   BrandId: 37,
    //   SubCategories: [
    //     {
    //       Id: "1801D6D4-3090-EE11-ABA9-0272353759A1",
    //       SubCategoryId: 1,
    //       SpliceTypeNumber: 1,
    //       Treatments: [
    //         {
    //           Id: "1B01D6D4-3090-EE11-ABA9-0272353759A1",
    //           TreatmentId: 1,
    //           Grades: [
    //             {
    //               Id: "1E01D6D4-3090-EE11-ABA9-0272353759A1",
    //               GradeId: 1,
    //               ItemSeries: "Erico Coupler Standard Plain "
    //             }
    //           ]
    //         }
    //       ],
    //       Grades: [1]
    //     }
    //   ]
    // }

    // {
    //   Id: 1,
    //   SupplierId: "332B5135-DD89-EE11-ABA9-0272353759A1",
    //   BrandId: 37,
    //   ProductCategoryId: "83A8A7DB-E189-EE11-ABA9-0272353759A1",
    //   SubCategories: [{
    //     Id: 1,
    //     SubCategoryId: 1,
    //     SpliceTypeNumber: 1,
    //     Treatments: [
    //       {
    //         Id: 1,
    //         TreatmentId: 1,
    //         Grades: [
    //           {
    //             Id: 1,
    //             GradeId: 1,
    //             ItemSeries: 'Erico Coupler Standard Plain A1'
    //           },
    //           {
    //             Id: 2,
    //             GradeId: 2
    //           },

    //         ],
    //       }
    //     ],
    //     Grades: [1, 2]


    //   }],
    // }
  ]);

  const [initialInputSetups, setInitialInputSetups] = useState([]);
  const [suppliersList, setSuppliersList] = useState([
    //   {
    //     "Id": "332B5135-DD89-EE11-ABA9-0272353759A1",
    //     "Name": "nVent Lenton",
    //     "Brands": [
    //         {
    //             "Id": 37,
    //             "Name": "Erico"
    //         },
    //         {
    //             "Id": 38,
    //             "Name": "Hoffman"
    //         },
    //         {
    //             "Id": 39,
    //             "Name": "Schroff"
    //         },
    //         {
    //             "Id": 40,
    //             "Name": "Tracer"
    //         },
    //         {
    //             "Id": 41,
    //             "Name": "Raychem"
    //         }
    //     ]
    // }
  ]);
  const [productTypes, setProductTypes] = useState([
    // {
    //   "Id": "83A8A7DB-E189-EE11-ABA9-0272353759A1",
    //   "Name": "Coupler",
    //   "SubCategories": [
    //     {
    //       "Id": 1,
    //       "Name": "Standard"
    //     },
    //     {
    //       "Id": 2,
    //       "Name": "Transition"
    //     },
    //     {
    //       "Id": 3,
    //       "Name": "Position"
    //     },
    //     {
    //       "Id": 4,
    //       "Name": "Position Transition"
    //     }
    //   ]
    // }
  ]);
  const [treatmentsList, setTreatmentsList] = useState([
    // {
    //   "Id": 1,
    //   "Name": "Plain"
    // },
    // {
    //   "Id": 2,
    //   "Name": "Coated"
    // }
  ]);
  const [gradesList, setGradesList] = useState([
    // {
    //   "Id": 1,
    //   "Name": "A1"
    // },
    // {
    //   "Id": 2,
    //   "Name": "A2"
    // },
    // {
    //   "Id": 3,
    //   "Name": "A3"
    // },
    // {
    //   "Id": 4,
    //   "Name": "A4"
    // }
  ]);

  const [savingProducts, setSavingProducts] = useState(false);
  const [productSectionNb, setProductSectionNb] = useState(1)


  const generateReferenceGroup = () => {
    let a = references[0].Values.map((val) => val.value.toString());
    let b = references[1].Values.map((val) => val.value.toString());
    let c = references[2].Values.map((val) => val.value.toString());
    let x = cartesian([
      references[0].Values.map((val) => val.value.toString()),
      references[1].Values.map((val) => val.value.toString()),
      references[2].Values.map((val) => val.value.toString()),
    ]);
    x = x.map((z) => z.join(" "));

    console.log(x);
    let tempArray = referenceGroup;
    x.map((refGrp) => {
      if (!referenceGroup.filter((rfg) => rfg.Code === refGrp)[0])
        tempArray = tempArray.concat({ Code: refGrp, Abbreviation: null });
    });
    tempArray.map((rf) => {
      if (!x.filter((rfs) => rfs === rf.Code)[0]) {
        tempArray = tempArray.filter((rfk) => rfk.Code !== rf.Code);
      }
    });
    setReferenceGroup(tempArray);
    console.log(referenceGroup);
  };

  const checkIfFieldsNotEmpty = () => {
    let empty = false;
    references.map((ref) => {
      ref.Values.map((val) => {
        if (val.value === null) {
          empty = true;
          setEmptyField(true);
          setEmptyFieldMessage(
            "There is empty fields in the table, Please check."
          );
        }
      });
    });
    if (!empty) {
      if (referenceGroup.length === 0) {
        empty = true;
        setEmptyField(true);
        setEmptyFieldMessage("Click the generate reference group button.");
      }
    }
    if (!empty) {
      referenceGroup.map((refGrp) => {
        if (!refGrp.Abbreviation) {
          empty = true;
          setEmptyField(true);
          setEmptyFieldMessage(
            "There are an empty field in Ref Group Abbreviation."
          );
        }
      });
    }
    if (!empty) {
      save();
    }
  };

  const addGradeIdsField = (pr, grades) => {
    console.log("grades",grades)
    let tmp = []
    pr?.SubCategories?.map((sc) => {
      sc.Treatments?.map((tr) => {
        tr.Grades?.map((gr) => {
          if (!tmp.find((i)=> i.GradeId === gr.GradeId)) {
            // tmp.push(gr.GradeId)
            tmp.push(  {
              Id: null,
              GradeId: gr.GradeId,
              GradeName: grades?.find((i)=> i.Id === gr.GradeId)?.Name,
              Name: grades?.find((i)=> i.Id === gr.GradeId)?.Name ,
            })

      }

        })
  })
    sc["Grades"] = tmp;
  forceUpdate();
})
  }

useEffect(() => {
  if (props.inventoryId) {
    getReferenceInputData(props.inventoryId).then((x) => {
      setLoadingData(false);
      setReferencesNames(x.References);
      if (x.Inventory) {
        setReferences(x.Inventory.References);
        setReferenceGroup(x.Inventory.ReferenceGroups);
      }
    });
    setLoadingProductData(true);
    getProductReferenceData(props.inventoryId).then((x) => {
      setLoadingProductData(false);
      setSuppliersList(x.Suppliers);
      setProductTypes(x.ProductTypes);
      setTreatmentsList(x.Treatments);
      setGradesList(x.Grades);
      if (x.ProductReferences) {
        let tmp = x.ProductReferences;
        // tmp.forEach(obj => {
        //   obj['Grades'] = getGradeIds();
        // });
        x.ProductReferences?.map((pr) => {
          addGradeIdsField(pr, x.Grades)
        })
        setInputSetups(tmp)
        setInitialInputSetups(tmp)
      }
      else {
        let inputObject = {
          Id: null,
          SupplierId: null,
          BrandId: null,
          ItemTypeId: null,
          SubCategories: [],
        }
        inputSetups.push(inputObject)
      }

    })
  }
}, [props.inventoryId, modify]);

const InventoryReferenceData = {
  Id: props.inventoryId,
  References: references,
  ReferenceGroups: referenceGroup,
};

const ProductReferenceData = {
  InventoryId: props.inventoryId,
  ProductReferences: inputSetups,
}

const save = () => {
  setSaving(true);
  if (references[0].Id) {
    updateInventoryReference(InventoryReferenceData).then((x) => {
      setSaving(false);
      props.setActiveTab(props.activeTab + 1);
    });
  } else {
    createInventoryReference(InventoryReferenceData).then((x) => {
      setSaving(false);
      props.setActiveTab(props.activeTab + 1);
    });
  }
  console.log(references);
};

const saveProductReferences = () => {
  console.log("inputSetups heree", inputSetups)
  setSavingProducts(true);
  // const temp = [];
  // temp.push(inputSetups[1])
  updateProductReference(inputSetups, props.inventoryId).then((res) => {
    setSavingProducts(false);
    setModify(!modify);
    setProductSectionNb(2)
  })

}
return (
  <>
    <Backdrop
      sx={{
        color: "#fff",
        backgroundColor: "transparent",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
      }}
      open={loadingData}
    >
      <CircularProgress />

      <Grid
        item
        fontFamily={"Muli"}
        fontSize={15}
        fontWeight={"bold"}
        color={"rgb(25, 118, 210)"}
      >
        Loading Data...
      </Grid>
    </Backdrop>

    <Grid item container direction="row" justifyContent="center" columnGap={30} style={{ fontFamily: "Muli", fontSize: "20px", color: "#101650", cursor: "pointer" }}>
      <Grid item
        style={{ textDecoration: activeTab === "RM" ? "underline" : "none", fontWeight: activeTab === "RM" ? "bold" : "normal" }}
        onClick={() => setActiveTab("RM")}
      >
        Raw Material / Precut Bars</Grid>
      {/* <Grid item
          style={{ textDecoration: activeTab === "Precut Bars" ? "underline" : "none", fontWeight: activeTab === "Precut Bars" ? "bold" : "normal" }}
          onClick={() => setActiveTab("Precut Bars")}
        >
          Precut Bars
        </Grid> */}
      <Grid item
        style={{ textDecoration: activeTab === "Product" ? "underline" : "none", fontWeight: activeTab === "Product" ? "bold" : "normal" }}
        onClick={() => setActiveTab("Product")}
      >
        Product
      </Grid>

    </Grid>

    {activeTab === "RM" ?
      <RawMaterialSection
        nbOfReferences={nbOfReferences}
        references={references}
        referencesNames={referencesNames}
        loadingData={loadingData}
        generateReferenceGroup={generateReferenceGroup}
        referenceGroup={referenceGroup}
        setReferenceGroup={setReferenceGroup}
        isdisabled={isdisabled}
        checkIfFieldsNotEmpty={checkIfFieldsNotEmpty}
        saving={saving}
        emptyField={emptyField}
        emptyFieldMessage={emptyFieldMessage}
        setActiveTab={props.setActiveTab}
        activeTab={props.activeTab}
      />
      : productSectionNb === 1 ?
        <ProductFirstSection
          initialInputSetups={initialInputSetups}
          inputSetups={inputSetups}
          setInputSetups={setInputSetups}
          productTypes={productTypes}
          setProductTypes={setProductTypes}
          suppliersList={suppliersList}
          treatmentsList={treatmentsList}
          setTreatmentsList={setTreatmentsList}
          gradesList={gradesList}
          setGradesList={setGradesList}
          savingProducts={savingProducts}
          saveProductReferences={saveProductReferences}
          loadingProductData={loadingProductData}
          setProductSectionNb={setProductSectionNb}
          setActiveTab={props.setActiveTab}
          activeTab={props.activeTab}
        />
        :
        <ProductSecondSection
          setProductSectionNb={setProductSectionNb}
          getProductGradeDiameterData={getProductGradeDiameterData}
          inventoryId={props.inventoryId}
          addProductReferenceGradeDiameter={addProductReferenceGradeDiameter}
          setActiveTab={props.setActiveTab}
          activeTab={props.activeTab}
        />
    }


  </>
);
};

export default ItemReference;
