import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/Delivery.module.css";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import DeliveryNoteForm from "./DeliveryNoteForm";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as DeliveryServices from "../../../Services/delivery-services.proxy";
import ErrorModal from "../../../SharedComponents/ErrorModal";

const getDeliveryNotePrintData = DeliveryServices.getDeliveryNotePrintData;
const nextdeliveryNote = DeliveryServices.nextdeliveryNote;
const setDeliveryNoteDate = DeliveryServices.setDeliveryNoteDate;

const DeliverNotePrint = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    props.setShowSaaSNavbar(false);
  }, [props.setShowSaaSNavbar]);

  const navigate = useNavigate();
  const ref = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [deliveryNoteFields, setDeliveryNoteFields] = useState({});
  const [firstSectionFields, setfirstSectionFields] = useState([]);
  const [secondSectionFields, setsecondSectionFields] = useState([]);
  const [thirdSectionFields, setthirdSectionFields] = useState([]);
  const [fourthSectionFields, setfourthSectionFields] = useState([]);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const navigateToOnClose = -1


  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const labelId = query.get("labelId");
  const deliveryNoteId = query.get("deliveryNoteId");
  const HasNext = query.get("HasNext");
  const shipmentId = query.get("shipmentId");
  useEffect(() => {
    if (labelId && deliveryNoteId) {
      setLoadingData(true)
      getDeliveryNotePrintData(deliveryNoteId, labelId).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setDeliveryNoteFields(x);
          let firstSection = [];
          let secondSection = [];
          let thirdSection = [];
          let fourthSection = [];

          if (x.DeliveryNote) {
            firstSection = firstSection.concat(x.DeliveryNote.Fields);
          }
          if (x.SalesOrder) {
            firstSection = firstSection.concat(x.SalesOrder.Fields);
          }
          if (x.CompanyDetails) {
            firstSection = firstSection.concat(x.CompanyDetails.Fields);
          }
          if (x.Members) {
            secondSection = secondSection.concat(x.Members);
          }
          if (x.Summary) {
            thirdSection = thirdSection.concat(x.Summary);
          }
          if (x.Delivery) {
            fourthSection = fourthSection.concat(x.Delivery.Fields);
          }
          setfirstSectionFields(firstSection);
          setsecondSectionFields(secondSection);
          setthirdSectionFields(thirdSection);
          setfourthSectionFields(fourthSection);
        }
        setLoadingData(false)

      });
    }
  }, [labelId, deliveryNoteId]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        // objectFit: "100%",
        backgroundRepeat:"no-repeat",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid container direction={"row"}>
        <Grid item width={835}>
          <DeliveryNoteForm
            myRef={ref}
            deliveryNoteFields={deliveryNoteFields}
            firstSectionFields={firstSectionFields}
            secondSectionFields={secondSectionFields}
            thirdSectionFields={thirdSectionFields}
            fourthSectionFields={fourthSectionFields}
          />
        </Grid>
        <Grid item xs={5} paddingTop={5}>
          <Grid item className={classes["Delivery-Note"]}>
            Delivery Note
          </Grid>

          <Grid
            item
            container
            direction={"row"}
            gap={3}
            justifyContent={"center"}
            paddingTop={2}
          >
            <button
              className={classes["cancel-button"]}
              onClick={() => {
                props.setShowSaaSNavbar(true);
                navigate(-1);
              }}
            >
              Cancel
            </button>
            <ReactToPrint content={() => ref.current}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <button
                    className={classes["save-button"]}
                    onClick={() => {
                      setDeliveryNoteDate(deliveryNoteId).then(() => {
                        handlePrint();
                      });
                    }}
                  >
                    Print
                  </button>
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </Grid>
          {HasNext === "true" ? (
            <Grid
              item
              className={classes["View-next"]}
              paddingTop={2}
              onClick={() => {
                nextdeliveryNote(shipmentId).then(() => {
                  navigate(
                    `/saas/Delivery/DeliveryNote?shipmentId=${shipmentId}`
                  );
                });
              }}
            >
              View Next Delivery Note
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose}  />

    </div>
  );
};

export default DeliverNotePrint;
