import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import classes from "../../Table/Table.module.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as saasServices from "../../../Services/saas-services.proxy";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import logoimg from "../../../Assets/Images/Table/clientlogo.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
const getSuppliersList = saasServices.getSuppliersList;

export default function SupplierTable(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  const [supplierTableData, setSupplierTableData] = useState([]);
  const [dataLength, setdataLength] = useState();
  const isGuest = localStorage.getItem("isGuest");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);

  const navigate = useNavigate();
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
  };

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getSuppliersList(listData).then((x) => {
      if (apiTest) {
        setSupplierTableData(x.Suppliers);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, searchText, props.modify]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      position: "sticky",
      renderCell: (params) => {
        return (
          //   <IconButton  label={"movealll"}>
          //     <ArrowDropDownIcon />
          //   </IconButton>
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Code",
      width: 150,
    },
    {
      field: "Name",
      width: 150,
    },
    {
      field: "Email",
      width: 250,
      resizeable: true,
    },
    {
      field: "Address",
      width: 150,
    },
    {
      field: "Website",
      headerName: "Website",
      width: 200,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 200,
    },
    { field: "CreatorName", headerName: "Creator", width: 150 },
    {
      field: "CreationTime",
      headerName: "Created Date",
      width: 150,
      renderCell: (params) => params.row.CreationTime?.slice(0, 10),
    },
  ];
  return (
    <Grid container>
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={10}
          marginLeft="5%"
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("isTeamMember") === "true" &&
              !UserPermissions?.find((x) => x.Name === "Project Manager") &&
              !UserPermissions?.find((x) => x.Name === "Admin")
                ? "You don't have permission to create/edit a client"
                : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            <Grid item xs={8}></Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item container direction={"row"} md={12} paddingBottom={2}>
        <Grid item lg={2} md={4}>
          <span className={classes["Table-Title"]}>Suppliers</span>
        </Grid>

        <Grid item lg={9.7} container direction={"row-reverse"}>
          <button
            disabled={
              localStorage.getItem("IsSubscribedBefore") === "true" ||
              (localStorage.getItem("IsFreePlanActivated") === "true" &&
                localStorage.getItem("isSubscribed") === "false")
                ? true
                : false
            }
            className={classes["AddButton"]}
            onClick={() => {
              if (
                localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin")
              )
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (UserPermissions[i].Permissions[j].Name === "Client") {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "Add"
                        )
                      ) {
                        props.setRowData(null);
                        props.setOpenModal(true);
                        handleClose();
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              else {
                props.setRowData(null);
                props.setOpenModal(true);
                handleClose();
              }
            }}
          >
            Add
          </button>
          {props.ShowGoBack ? (
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item md={7}></Grid>
      {props.viewSearch ? (
        <Grid
          item
          md={5}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 4, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}

      <div
        style={{
          height: props.tableHeight,
          width: "98%",
          fontFamily: "Muli",
          backgroundColor: "white",
          marginTop: 10,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          pagination
          paginationMode="server"
          rows={supplierTableData ? supplierTableData : []}
          columns={columns}
          headerHeight={40}
          density={"compact"}
          showColumnRightBorder
          sx={{
            [`& .${gridClasses.row}`]: {
              color: "#0674b9",
              overflow: "auto",
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[0] : grey[500],
            },

            [`& .${gridClasses.columnHeader}`]: {
              bgcolor: "rgb(172 201 224)",
              color: "rgb(17 24 84)",
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontFamily: "Muli",
              fontWeight: "bold",
            },

            [`& .${gridClasses.footerContainer}`]: {
              bgcolor: "rgb(216 235 247)",
              minHeight: "30px",
            },
            fontFamily: "Muli",
            fontSize: "13px",
          }}
          disableColumnFilter
          getRowId={(row) => row.Id}
          rowCount={dataLength}
          hideFooter={props.paging}
          onPageChange={(newPage) => {
            if (pageSize > dataLength) {
              setPageNumber(1);
            } else setPageNumber(newPage + 1);
          }}
          onPageSizeChange={(newPage) => {
            setPageSize(newPage);
          }}
          pageSize={pageSize}
          loading={loadingData}
          components={{
            NoRowsOverlay: () => (
              <Stack className={classes["EmptyMessage"]}>
                {isGuest === "true"
                  ? "Please Subscribe to access clients"
                  : "No Suppliers to display."}
              </Stack>
            ),
          }}
        />
      </div>
      {props.showViewAll ? (
        <Grid
          item
          xs={11.75}
          container
          bgcolor={"white"}
          justifyContent={"center"}
        >
          <span
            style={{
              color: "#0674b9",
              fontFamily: "Muli",
              fontSize: "13px",
            }}
          >
            View All
          </span>
        </Grid>
      ) : (
        ""
      )}
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        {localStorage.getItem("IsSubscribedBefore") === "true" ? (
          ""
        ) : (
          <MenuItem
            onClick={(event) => {
                props.setRowData(rowData);
                props.setOpenModal(true);
                handleClose();
            
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              width: 100,
              display: "block",
              paddingLeft: "30%",
            }}
          >
            Edit
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
}
