import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import Form1 from "./Form1";
import Form2 from "./Form2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";

const getValueInputData = inventoryServices.getValueInputData;

const ItemValues = (props) => {
  const [loadingData, setLoadingData] = useState(true);

  const [isdisabled, setIsDisabled] = useState(true);
  const [emptyField, setEmptyField] = useState(false);
  const [itemCategory, setItemCategory] = useState(null);
  const [itemSelectedId, setItemSelectedId] = useState(0);
  const [itemSelectedData, setItemSelectedData] = useState();
  const [itemSelectedDataRemants, setItemSelectedDataRemants] = useState();
  const [itemSelectedDataRemantsId, setItemSelectedDataRemantsId] =
    useState(null);

  const [diaValuesSelected, setDiaValuesSelected] = useState([]);
  const [diaValuesSelectedInRemnants, setDiaValuesSelectedInRemnants] =
    useState([]);

  const [diaLengthSelected, setDiaLengthSelected] = useState([
    { Length: 0, Weight: null },
  ]);

  const [itemValuesData, setItemValuesData] = useState([]);
  const [listOfDiaValues, setListOfDiaValues] = useState([]);
  useEffect(() => {
    if (props.inventoryId) {
      getValueInputData(props.inventoryId).then((x) => {
        setListOfDiaValues(x.Diameters);
        setItemValuesData(x.Data);

        if (x.Data.filter((y) => y.Name === "Raw Material")[0]) {
          let obj = x.Data.filter((y) => y.Name === "Raw Material")[0];
          setItemCategory(obj.Id);
          setItemSelectedId(obj.Types[0].Id);
          setItemSelectedData(obj.Types[0]);

          setDiaValuesSelected(
            obj.Types[0].Diameters?.map((val) => val.DiameterId)
          );
          setDiaLengthSelected(
            obj.Types[0].Diameters[0]?.Values
              ? obj.Types[0].Diameters[0]?.Values?.map((val) => {
                  let item = { Length: val.Length, Weight: 0 };
                  return item;
                })
              : [{ Length: 0, Weight: 0 }]
          );
        } 
        else  if (x.Data.filter((y) => y.Name === "PreCut")[0]) {
          let obj = x.Data.filter((y) => y.Name === "PreCut")[0];
          setItemCategory(obj.Id);
          setItemSelectedId(obj.Types[0].Id);
          setItemSelectedData(obj.Types[0]);

          setDiaValuesSelected(
            obj.Types[0].Diameters?.map((val) => val.DiameterId)
          );
          setDiaLengthSelected(
            obj.Types[0].Diameters[0]?.Values
              ? obj.Types[0].Diameters[0]?.Values?.map((val) => {
                  let item = { Length: val.Length, Weight: 0 };
                  return item;
                })
              : [{ Length: 0, Weight: 0 }]
          );
        } else if (x.Data.filter((y) => y.Name === "Remnant")[0]) {
          setItemCategory(x.Data.filter((y) => y.Name === "Remnant")[0].Id);
          props.setForm1Showed(false);
        } else if (x.Data.length === 0) {
          props.setActiveTab(props.activeTab + 1);
        }
        setLoadingData(false);
      });
    }
  }, [props.inventoryId]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      {props.form1Showed ? (
        <Form1
          itemValuesData={itemValuesData}
          itemCategory={itemCategory}
          setItemCategory={setItemCategory}
          itemSelectedId={itemSelectedId}
          setItemSelectedId={setItemSelectedId}
          itemSelectedData={itemSelectedData}
          setItemSelectedData={setItemSelectedData}
          listOfDiaValues={listOfDiaValues}
          setListOfDiaValues={setListOfDiaValues}
          diaValuesSelected={diaValuesSelected}
          setDiaValuesSelected={setDiaValuesSelected}
          diaLengthSelected={diaLengthSelected}
          setDiaLengthSelected={setDiaLengthSelected}
          setForm1Showed={props.setForm1Showed}
          setActiveTab={props.setActiveTab}
          activeTab={props.activeTab}
        />
      ) : itemCategory ? (
        <Form2
          itemValuesData={itemValuesData}
          listOfDiaValues={listOfDiaValues}
          setListOfDiaValues={setListOfDiaValues}
          itemSelectedDataRemants={itemSelectedDataRemants}
          setItemSelectedDataRemants={setItemSelectedDataRemants}
          itemCategory={itemCategory}
          setItemCategory={setItemCategory}
          diaValuesSelectedInRemnants={diaValuesSelectedInRemnants}
          setDiaValuesSelectedInRemnants={setDiaValuesSelectedInRemnants}
          itemSelectedDataRemantsId={itemSelectedDataRemantsId}
          setItemSelectedDataRemantsId={setItemSelectedDataRemantsId}
          inventoryId={props.inventoryId}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
          setActiveTab={props.setActiveTab}
          activeTab={props.activeTab}
          setForm1Showed={props.setForm1Showed}
          setPrintingRemnant={props.setPrintingRemnant}
          manageStocks={props.manageStocks}
          setManageStocks={props.setManageStocks}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ItemValues;
