import { Grid, Button, Modal } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import ProjectDetails from "./ProjectDetails";
import PhaseDetails from "./PhaseDetails";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "../../../Assets/Styles/projects.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import edit from "../../../Assets/Images/Projects/edit.png";
import noedit from "../../../Assets/Images/Projects/not edit.png";
import * as projectServices from "../../../Services/projects-services.proxy";
import moment from "moment";

const createProject = projectServices.createProject;
const getprojectById = projectServices.getProjectById;
const getProjectInputData = projectServices.getProjectInputData;
const updateProject = projectServices.updateProject;
const joinProject = projectServices.joinProject;
const getAssignedPhases = projectServices.getAssignedPhases;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: "500px",
  transform: 'translate(-50%, -50%)',
  p: 4,
  borderRadius: "12px",
  border: "solid 1px #707070",
  backgroundColor: "#fff",
  outline: "none"
}

const type = localStorage.getItem("AccountType");

const styles = {
  tabs: {
    fontFamily: "Muli",
    fontSize: 21,
    lineHeight: 1.48,
    textAlign: "left",
    color: "#0674b9",
    padding: "0 1.5% 1.5% 0",
  },
  activeTabs: {
    fontFamily: "Muli",
    fontSize: 21,
    lineHeight: 1.48,
    textAlign: "left",
    color: "#101650",
    backgroundColor: "#fff",
    padding: "0 1.5% 1.5% 0",
  },
};

export default function Create(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [item, setItem] = useState(true);
  const [item1, setItem1] = useState(false);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (props.addPhase === true) {
      setItem1(true);
      setItem(false);
    }
  }, [props.addPhase, props.join]);

  //ProjectDetails
  const [projectMileStoned, setProjectMilstoned] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectTypeOther, setProjectTypeOther] = useState("");
  const [projectVisibility, setProjectVisibility] = useState(2);
  const [projectCountry, setProjectCountry] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [clientName, setClientName] = useState(null);
  const [projectStarts, setProjectStarts] = useState(null);
  const [projectEnds, setProjectEnds] = useState(null);
  const [client, setClient] = useState(null);

  //PhaseDetails
  const [phases, setPhases] = useState([]);
  const [phaseIndex, setPhaseIndex] = useState(0);

  // show drop down menu
  const [showDropDown, setShowDropDown] = useState(true);
  const [projectID, setProjectID] = useState(null);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (apiTest) {
      setProjectID(query.get("id"));
    }

    // cleanup
    return () => {
      apiTest = false;
    };
  }, []);

  const [clients, setClients] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [contractBillingTypes, setContractBillingTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [contractStatuses, setContractStatuses] = useState([]);
  const [salesCreators, setSalesCreators] = useState([]);
  const [factoryNumbers, setFactoryNumbers] = useState([]);
  const [barStandards, setBarStandards] = useState([]);
  const [barGrades, setBarGrades] = useState([]);
  const [barCoatings, setBarCoatings] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [countries, setCountries] = useState([]);
  const [defaultSheetSizes, setDefaultSheetSizes] = useState([]);
  const [types, setTypes] = useState([]);
  const [visibilities, setVisibilities] = useState([]);
  const [editPhase, setEditPhase] = useState(false);
  const [editPhaseId, setEditPhaseId] = useState(false);
  const [mainContractors, setMainContractors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [viewdisable, setViewdisable] = useState(false);

  useEffect(() => {
    if (props.view === true && props.edit === false) {
      setViewdisable(true);
    } else setViewdisable(false);
    if (query.get("edit") === "false") {
      setViewdisable(true);
    }
  }, [props.view, props.edit]);

  useEffect(() => {
    let apiTest = true;

    getProjectInputData(projectID).then((x) => {
      if (apiTest) {
        setClients(x.Clients);
        setConsultants(x.Consultants);
        setContractBillingTypes(x.ContractBillingTypes);
        setContractTypes(x.ContractTypes);
        setAccountTypes(x.AccountTypes);
        setContractStatuses(x.ContractStatuses);
        setSalesCreators(x.SalesOrderCreators);
        setFactoryNumbers(x.Factories);
        setMeasurements(x.MeasurementSystems);
        setCountries(x.Countries);
        setDefaultSheetSizes(x.DefaultSheetSizes);
        setTypes(x.Types);
        setVisibilities(x.Visibilities);
        setMainContractors(x.MainContractorCompanies);
        if (projectID) {
          //Projects
          setProjectMilstoned(x.Project.IsMilestoned);
          setProjectName(x.Project.Name);
          setProjectType(x.Project.TypeId);
          if (x.Project.TypeOthers) setProjectTypeOther(x.Project.TypeOthers);
          setProjectVisibility(x.Project.VisibilityId);
          setProjectCountry(x.Project.CountryId);
          setProjectCode(x.Project.Code);
          setProjectAddress(x.Project.Address);
          setProjectDescription(x.Project.Description);
          setClientName(x.Project.Client);
          if (props.join && !props.addPhase) {
            setProjectStarts(null);
            setProjectEnds(null);
          } else {
            setProjectStarts(moment(x.Project.StartDate).format("YYYY-MM-DD"));
            setProjectEnds(moment(x.Project.EndDate).format("YYYY-MM-DD"));
          }
          setClient(x.Project.Client);
          //Phases//Contracts
          // setPhases(x.Project.Phases);
        }
      }
    });
    getAssignedPhases(projectID).then(x => {
      if (apiTest) {
        setPhases(x);
      }
    })
    return () => {
      apiTest = false;
    };
  }, [projectID]);

  const data = {
    Id: projectID,
    Name: projectName,
    TypeId: projectType,
    TypeOthers: projectTypeOther ? projectTypeOther : null,
    VisibilityId: projectVisibility,
    CountryId: projectCountry,
    Code: projectCode,
    Address: projectAddress,
    Description: projectDescription,
    Client: clientName?.Id ? { Id: clientName.Id } : clientName,
    StartDate: projectStarts,
    EndDate: projectEnds,
    Phases: phases,
  };

  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const create = () => {
    if (data?.Id === null) {
      createProject(data).then((x) => {
        if (x.status) {
          setErrorMsg(x.error);
          handleOpenModal()
        }
        else {
          setLoading(false);
          navigate("/saas/projects");
        }
      });
    } else {
      if (props.join === true) {
        joinProject(data).then((x) => {
          if (x.status) {
            setErrorMsg(x.error);
            handleOpenModal()
          }
          localStorage.removeItem("notCreator");
          setLoading(false);
        });
        navigate("/saas/projects");
        props.setJoin(false);
      } else
        updateProject(data).then((x) => {
          if (x.status) {
            setErrorMsg(x.error);
            handleOpenModal()
          }
          else {
            setLoading(false);
            navigate("/saas/projects");
          }
        });
    }
    setTimeout(() => {
      setProjectAddress("");
      setProjectCode("");
      setProjectCountry("");
      setProjectDescription("");
      setProjectName("");
      setProjectMilstoned(false);
      setProjectType("");
      setProjectVisibility(1);
      setProjectStarts(null);
      setProjectEnds(null);
      setClient(null);
      if (props.addPhase === true) props.setAddPhase(false);
    }, 1000);
  };


  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        padding: "3% 1% 1% 1%",
      }}
    >
      <Grid container >
        <Grid item xs={12} container alignItems="center" padding="0 0 5% 10%">
          <Grid
            item
            xs={1.3}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Your Project
          </Grid>
          <Grid
            item
            md={6.6}
            xs={5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item xs={3.5} md={2} paddingLeft="1.5%">
            <button
              className={classes["view-dashboard"]}
              onClick={() => {
                navigate(`/saas/projects/dashboard?id=${projectID}`);
              }}
            >
              View project tree
            </button>
          </Grid>
          <Grid item xs={1} paddingLeft={{ xs: "5%", md: "1.5%" }}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
                if (localStorage.getItem("notCreator"))
                  localStorage.removeItem("notCreator");
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid item xs={12} container direction="column" alignItems={"center"}>
          <Grid
            item
            container
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="flex-start"
            paddingTop={{ sm: "5%", md: "3%" }}
          >
            <Grid item xl={1.25} lg={1.3} md={1.315} sm={1.4} xs={1.55}></Grid>
            <Grid
              item
              lg={props.view === true || projectID ? 1.5 : 1.5}
              md={props.view === true || projectID ? 2 : 1.5}
              sm={props.view === true || projectID ? 3.2 : 2}
              xs={props.view === true || projectID ? 3.2 : 2.5}
              container
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={(e) => {
                setItem(true);
                setItem1(false);
              }}
              sx={item ? styles.activeTabs : styles.tabs}
              style={{
                borderLeft:
                  item ?
                    localStorage.getItem("AccountType") === "1"
                      ? "7px solid #e1e319"
                      : localStorage.getItem("AccountType") === "2"
                        ? "7px solid #2da4d0"
                        : "7px solid lightgrey"
                    : 0,
              }}
              alignItems="center"
              gap={1}
            >
              <Grid item xl={2}>
                {props.view === true && !props.join ? (
                  <img
                    src={viewdisable === true ? edit : noedit}
                    alt="view"
                    onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    onClick={() => setViewdisable(!viewdisable)}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xl={3.5}>
                Project
              </Grid>
            </Grid>
            <Grid
              item
              xl={1.8}
              lg={props.view === true || projectID ? 1.8 : 1.3}
              md={props.view === true || projectID ? 2.3 : 1.5}
              sm={props.view === true || projectID ? 3.5 : 2}
              xs={props.view === true || projectID ? 3.5 : 2.5}
              sx={item1 ? styles.activeTabs : styles.tabs}
              container
              alignItems="center"
              gap={1}
            >
              <Grid item xl={2}>
                {props.view === true && !props.join ? (
                  <img
                    src={viewdisable === true ? edit : noedit}
                    alt="view"
                    onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    onClick={() => setViewdisable(!viewdisable)}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                item
                xl={3.5}
                onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                onClick={(e) => {
                  if (!isDisabled) {
                    setItem1(true);
                    setItem(false);
                  }
                }}
              >
                Phase
              </Grid>
              {phases.length > 0 ? (
                <Grid
                  item
                  xl={2}
                  id="Notifi-button"
                  aria-controls={open ? "noti-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                >
                  <KeyboardArrowDownIcon
                    htmlColor="#0674b9"
                    fontSize="medium"
                    style={{ height: 18 }}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Menu
                id="noti-menu"
                aria-labelledby="Notifi-button"
                anchorEl={anchorEl}
                open={open}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "#fff",
                  },
                }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                }}
                disableScrollLock
              >
                {phases?.map((phase, index) => (
                  <MenuItem
                    key={phase.Id}
                    onClick={(e) => {
                      setEditPhaseId(phase.Id);
                      setPhaseIndex(index);
                      setEditPhase(true);
                      setItem1(true);
                      setItem(false);
                      handleClose();
                    }}
                    style={{
                      height: "60px",
                      fontWeight: "bold",
                      fontFamily: "Muli",
                      fontSize: 13,
                      borderRadius: 5,
                      color: "#0674b9",
                      backgroundColor: "#fff",
                    }}
                  >
                    {phase.Name}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
          <Grid item container paddingLeft={item1 ? "13%" : "9%"}>
            <Grid
              item
              xl={0.2}
              lg={0.25}
              md={0.28}
              sm={0.37}
              xs={0.55}
              sx={{
                backgroundColor:
                  localStorage.getItem("AccountType") === "1"
                    ? "#e1e319"
                    : localStorage.getItem("AccountType") === "2"
                      ? "#2da4d0"
                      : "lightgrey",
              }}
            ></Grid>
            <Grid item xs={10.5} width={item1 ? "72%" : "80%"}>
              {item1 ? (
                <PhaseDetails
                  loading={loading}
                  setLoading={setLoading}
                  projectName={projectName}
                  addPhase={props.addPhase}
                  setAddPhase={props.setAddPhase}
                  join={props.join}
                  countries={countries}
                  consultants={consultants}
                  setConsultants={setConsultants}
                  visibilities={visibilities}
                  setVisibilities={setVisibilities}
                  phases={phases}
                  projectCountry={projectCountry}
                  projectAddress={projectAddress}
                  projectVisibility={projectVisibility}
                  setPhases={setPhases}
                  editPhase={editPhase}
                  setEditPhase={setEditPhase}
                  editPhaseId={editPhaseId}
                  contractTypes={contractTypes}
                  accountTypes={accountTypes}
                  salesCreators={salesCreators}
                  factoryNumbers={factoryNumbers}
                  barStandards={barStandards}
                  barGrades={barGrades}
                  barCoatings={barCoatings}
                  setBarStandards={setBarStandards}
                  setBarGrades={setBarGrades}
                  setBarCoatings={setBarCoatings}
                  measurements={measurements}
                  contractStatuses={contractStatuses}
                  contractBillingTypes={contractBillingTypes}
                  defaultSheetSizes={defaultSheetSizes}
                  mainContractors={mainContractors}
                  setMainContractors={setMainContractors}
                  create={create}
                  edit={props.edit}
                  viewdisable={viewdisable}
                  setViewdisable={setViewdisable}
                  phaseIndex={phaseIndex}
                  setPhaseIndex={setPhaseIndex}
                  projectId={projectID}
                />
              ) : (
                <ProjectDetails
                  addPhase={props.addPhase}
                  join={props.join}
                  setItem={setItem}
                  setItem1={setItem1}
                  projectMileStoned={projectMileStoned}
                  setProjectMilstoned={setProjectMilstoned}
                  projectName={projectName}
                  setProjectName={setProjectName}
                  projectType={projectType}
                  setProjectType={setProjectType}
                  projectTypeOther={projectTypeOther}
                  setProjectTypeOther={setProjectTypeOther}
                  projectVisibility={projectVisibility}
                  setProjectVisibility={setProjectVisibility}
                  projectCountry={projectCountry}
                  setProjectCountry={setProjectCountry}
                  projectCode={projectCode}
                  setProjectCode={setProjectCode}
                  projectAddress={projectAddress}
                  setProjectAddress={setProjectAddress}
                  projectDescription={projectDescription}
                  setProjectDescription={setProjectDescription}
                  clientName={clientName}
                  setClientName={setClientName}
                  projectStarts={projectStarts}
                  setProjectStarts={setProjectStarts}
                  projectEnds={projectEnds}
                  setProjectEnds={setProjectEnds}
                  clients={clients}
                  setClients={setClients}
                  countries={countries}
                  types={types}
                  visibilities={visibilities}
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  client={client}
                  setClient={setClient}
                  viewdisable={viewdisable}
                  setViewdisable={setViewdisable}
                  projectId={projectID}
                  setProjectId={setProjectID}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container sx={modalStyle} justifyContent="center" alignItems="center" gap={10}>
            <Grid item sx={{
              fontFamily: "Muli",
              fontSize: 21,
              lineHeight: 1.48,
              textAlign: "center",
              color: "#0674b9",
            }}>
              Error: {errorMsg}
            </Grid>

            <Grid item container justifyContent="center" direction="row" gap={3}>
              <Grid item onClick={() => { handleCloseModal(); navigate("/saas/projects"); }}
                className={classes["view-dashboard"]}
                sx={{ backgroundColor: '#0674b9', color: '#fff', fontSize: 20 }}
              >
                Ok
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </div >
  );
}
