import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { CircularProgress, Grid } from "@mui/material";
import dots from "../../Assets/Images/Dashboard/dots.png";
import MyChart from "./MyChart";
import * as services from "../../Services/machine-dashboard-services.proxy";
import ErrorModal from "../../SharedComponents/ErrorModal";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const getMachineDashboardData = services.getMachineDashboardData;
const getProductionCellDashboard = services.getProductionCellDashboard;


export default function Analysis(props) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [workingHours, setWorkingHours] = useState("");
  const [idleTime, setIdleTime] = useState("");
  const [day, setDay] = useState(
    new Date().toLocaleDateString("en-US", { weekday: "long" })
  );
  const [date, setDate] = useState(
    Moment().format("DD") +
    " " +
    monthNames[Moment().format("M") - 1] +
    " " +
    Moment().format("YYYY")
  );
  const [utilizationRate, setUtilizationRate] = useState();

  const [machine, setMachine] = useState(null);
  const [data, setData] = useState([]);
  const [delayedOrders, setDelayedOrders] = useState([]);
  const [todaysOrders, setTodaysOrders] = useState([]);
  const [futurePlannedOrders, setFuturePlannedOrders] = useState([]);
  const [diameters, setDiameters] = useState([]);

  const [prodCell, setProdCell] = useState(null);
  const [machines, setMachines] = useState([]);

  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += +this[i][prop];
    }
    return total;
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const [loading, setLoading] = useState(true);



  useEffect(() => {
    let apiTest = true;
    // if (localStorage.getItem("machineId"))
    //   getMachineDashboardData(localStorage.getItem("machineId")).then((x) => {
    //     setLoading(false);
    //     if (apiTest) {
    //       if (x.status || x.statusCode) {
    //         if (x.status) setBEerror(x.error)
    //         if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
    //         setOpenErrorModal(true);
    //       } else {
    //         setMachine(x);
    //         if (x.StatusId === 2) props.setDisableOnHold(true);

    //         let workHours = parseInt(Number(x.WorkingHours));
    //         let workMinutes = Math.round((Number(x.WorkingHours) - workHours) * 60)
    //         workHours = workHours.toString().length === 1 ? "0" + workHours : workHours
    //         workMinutes = workMinutes.toString().length === 1 ? "0" + workMinutes : workMinutes
    //         setWorkingHours(workHours + ":" + workMinutes);

    //         let dayStart = moment().startOf('day');
    //         let currentTime = moment();
    //         let frontIdelTime = currentTime.diff(dayStart, 'hours', true).toFixed(2) - x.WorkingHours;

    //         let IdleHours = parseInt(Number(frontIdelTime));
    //         let IdleMinutes = Math.round((Number(frontIdelTime) - IdleHours) * 60);
    //         IdleHours = IdleHours.toString().length === 1 ? "0" + IdleHours : IdleHours;
    //         IdleMinutes = IdleMinutes.toString().length === 1 ? "0" + IdleMinutes : IdleMinutes
    //         setIdleTime(IdleHours + ":" + IdleMinutes);


    //         setData(x.BarDiagram);
    //         setUtilizationRate(
    //           ((x.WorkingHours / (+x.WorkingHours + +x.IdleTime)) * 100).toFixed(
    //             2
    //           )
    //         );
    //         if (x.DelayedOrders) setDelayedOrders(x.DelayedOrders);
    //         if (x.TodaysOrders) setTodaysOrders(x.TodaysOrders);
    //         if (x.FutureOrders) setFuturePlannedOrders(x.FutureOrders);
    //         let uniqueDias = x?.BarDiagram?.flatMap((x) => x.Diameters).flatMap(
    //           (x) => x.Diameter
    //         );
    //         setDiameters([...new Set(uniqueDias)]);
    //         let arr = [];
    //         x.BarDiagram.map((month) => {
    //           let obj = {
    //             name: month.Name,
    //           };
    //           month.Diameters.map((dia) => {
    //             obj[dia.Diameter] = dia.Weight / 1000;
    //           });
    //           arr.push(obj);
    //         });
    //         setData(arr);
    //       }
    //     }
    //   });

    if (localStorage.getItem("ProductionCellId"))
      getProductionCellDashboard(localStorage.getItem("ProductionCellId")).then((x) => {
        setLoading(false);
        if (apiTest) {
          if (x.status || x.statusCode) {
            if (x.status) setBEerror(x.error)
            if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
            setOpenErrorModal(true);
          } else {
            setProdCell(x);
            // if (x.StatusId === 2) props.setDisableOnHold(true);
            setData(x.BarDiagram);

            if (x.DelayedOrders) setDelayedOrders(x.DelayedOrders);
            if (x.TodaysOrders) setTodaysOrders(x.TodaysOrders);
            if (x.FutureOrders) setFuturePlannedOrders(x.FutureOrders);

          }
        }
      });
    return () => {
      apiTest = false;
    };
  }, []);




  // useEffect(() => {
  //   console.log("day Start", dayStart);
  //   console.log("current Date", currentTime)
  //   console.log("idle ", idelTime)
  // },[])

  const navigate = useNavigate();

  return (
    <>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justifyContent="center"
        paddingTop="3%"
        gap={3}
      >
        <Backdrop
          sx={{
            color: "#101650",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          padding="0 2% 2% 2%"
          direction="row-reverse"
        >
          <Grid
            item
            xs={5}
            container
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Grid
              item
              xs={12}
              container
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={5.5}
                lg={5.7}
                xl={5.9}
                sx={{
                  border: "5px solid #e1e319",
                  borderRadius: 3,
                  height: 40,
                  fontFamily: "Muli",
                  fontSize: { xs: 10, lg: 15 },
                  fontWeight: "bold",
                  lineHeight: 1.25,
                  textAlign: "center",
                  color: "#101650",
                  paddingTop: { xs: "2.5%", md: "2.5%", lg: "1%" },
                }}
              >
                {/* {machine?.Code} */} Production Cell Code
              </Grid>
              <Grid
                item
                xs={5.5}
                lg={5.7}
                xl={5.9}
                sx={{
                  borderRadius: 3,
                  height: 40,
                  backgroundColor: "#101650",
                  fontFamily: "Muli",
                  fontSize: { xs: 10, lg: 15 },
                  fontWeight: "bold",
                  lineHeight: 1.25,
                  textAlign: "center",
                  color: "#e1e319",
                  paddingTop: { xs: "4.5%", md: "5%", lg: "2%" },
                }}
              >
                {prodCell?.Code}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              direction='row'
              alignItems='center'
              justifyContent='center'
              sx={{
                borderRadius: 4,
                backgroundColor: "#101650",
                fontFamily: "Muli",
                fontSize: 30,
                fontWeight: "bold",
                lineHeight: 1.27,
                color: "#fff",
                cursor: 'pointer'
              }}
              container
              padding={4}
              columnGap={6}
              onClick={() => {

              }}
            >
              <Grid
                item

              >
                START <br />PRODUCTION <br />HERE
              </Grid>
              <Grid item> <PlayCircleFilledIcon style={{ width: '100px', height: '100px' }} /></Grid>

            </Grid>
            {/* <Grid
              item
              xs={12}
              sx={{
                borderRadius: 4,
                height: 113,
                backgroundColor: "#fff",
                fontFamily: "Muli",
                fontWeight: "bold",
                textAlign: "center",
                color: "#101650",
                padding: "3% 0 3% 0",
              }}
              container
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sx={{ fontSize: 15 }}>
                UTILIZATION RATE:
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 25 }}>
                {utilizationRate}%
              </Grid>
            </Grid> */}
          </Grid>
          {/* <Grid
            item
            xs={5}
            md={1.8}
            alignItems="center"
            justifyContent="center"
            container
            gap={2}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: 5,
                height: 130,
                backgroundColor: "#e1e319",
              }}
              container
              alignItems="center"
              justifyContent="center"
              padding={{ xs: "10% 2% 10% 2%", md: "5% 0 5% 0" }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 20,
                  fontWeight: "bold",
                  lineHeight: 1.25,
                  textAlign: "center",
                  color: "#101650",
                }}
              >
                WORKING HOURS
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  borderRadius: 3,
                  backgroundColor: "#fff",
                  fontFamily: "Muli",
                  fontSize: { xs: 25, lg: 45 },
                  fontWeight: "bold",
                  lineHeight: 1.26,
                  textAlign: "center",
                  color: "#101650",
                }}
              >
                {workingHours}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: 5,
                height: 130,
                backgroundColor: "#e1e319",
              }}
              container
              alignItems="center"
              justifyContent="center"
              padding={{ xs: "10% 2% 10% 2%", md: "5% 0 5% 0" }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 20,
                  fontWeight: "bold",
                  lineHeight: 1.25,
                  textAlign: "center",
                  color: "#101650",
                }}
              >
                IDLE TIME
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  borderRadius: 3,
                  backgroundColor: "#fff",
                  fontFamily: "Muli",
                  fontSize: { xs: 25, lg: 45 },
                  fontWeight: "bold",
                  lineHeight: 1.26,
                  textAlign: "center",
                  color: "#101650",
                }}
              >
                {idleTime}
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              border: "10px solid #101650",
              borderRadius: 7,
              height: 320,
              backgroundColor: "#fff",
            }}
            container
          >
            <Grid
              item
              xs={12}
              sx={{
                fontfamily: "Muli",
                textAlign: "left",
                color: "#101650",
                fontSize: 14,
                lineHeight: 1.29,
                padding: "1% 0 0 1%",
              }}
              container
              direction="column"
              gap={1}
            >
              <Grid item fontWeight="bold" paddingLeft="2%">
                Productivity Diagram
              </Grid>
              <Grid item paddingLeft="6%">
                Nb of pcs
              </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" alignItems="flex-end">
              <Grid item xs={10.6}>
                <MyChart data={data} diameters={diameters} machines={machines} />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  fontfamily: "Muli",
                  textAlign: "left",
                  color: "#101650",
                  fontSize: 14,
                  lineHeight: 1.29,
                  paddingBottom: "4%",
                }}
              >
                Machines
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {machine?.StatusId === 2 ? (
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={25}
            fontWeight={"bold"}
            color={"#ea001e"}
          >
            This machine is onhold.
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Grid
              item
              xs={3.8}
              sx={{
                borderRadius: 5,
                height: { xs: 350, lg: 450 },
                backgroundColor: "#ff5b5b",
              }}
              container
              alignItems="center"
              justifyContent="center"
              gap={1}
              padding="0 2% 2% 2%"
            >
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid
                  item
                  sx={{
                    borderRadius: { xs: 1, lg: 2 },
                    backgroundColor: "#fff",
                    fontFamily: "Muli",
                    fontSize: { xs: 13, lg: 18 },
                    fontWeight: "bold",
                    lineHeight: 1.28,
                    textAlign: "left",
                    color: "#101650",
                    padding: "2%",
                    marginTop: "4%",
                  }}
                >
                  DELAYED ORDERS
                </Grid>
                <Grid
                  item
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                  onClick={() =>
                    navigate(
                      `/machine/orders?machineId=${localStorage.getItem(
                        "machineId"
                      )}&dateId=${1}`
                    )
                  }
                >
                  <img src={dots} alt="dots" />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: { xs: 250, lg: 300 },
                  overflow: "auto",
                  backgroundColor: "#fff",
                  padding: "1%",
                }}
                // container
                alignItems="flex-start"
                justifyContent="center"
              // gap={1}
              >
                {delayedOrders.length > 0 ?
                  delayedOrders?.map((d, index) => (
                    <Grid
                      marginTop={index !== 0 ? 1 : 0}
                      item
                      key={index}
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                      gap={1}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() =>
                        navigate(`/machine/assignedLines?groupId=${d?.Id}`)
                      }
                      sx={{
                        fontFamily: "Muli",
                        textAlign: "left",
                        color: "#000",
                        fontSize: 14,
                        padding: { xs: "2%", md: "4%" },
                        boxShadow: "0 3px 6px 0 #ff5b5b",
                      }}
                    >
                      <Grid item xs={12} fontSize={18}>
                        {d?.OrderCode}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>{d?.ProductionDate}</Grid>
                        <Grid item>{d?.Diameter}</Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>Total {d?.TotalNbOfPieces} Pcs</Grid>
                        <Grid item>{d?.TotalWeight} Kgs</Grid>
                        <Grid item>{d?.PercentageOfAccomplishment}</Grid>
                      </Grid>
                      <Grid item container justifyContent='flex-end'>
                        {parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) === 0 ? 'Not Initiated' :
                          parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) > 1 && parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) <= 80 ? 'In Progress' :
                            parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) > 80 && parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) <= 99 ? 'Near Completion' :
                              ""
                        }
                      </Grid>
                      {d.PreviousMachineName ? (
                        <Grid item container textAlign={"left"}>
                          Replaced from : &nbsp;
                          <Grid item color={"#101650"}>
                            {d.PreviousMachineName}
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ))
                  :
                  <Grid item container justifyContent='center' marginTop={8}>No orders found</Grid>
                }
              </Grid>
            </Grid>
            <Grid
              item
              xs={3.8}
              sx={{
                borderRadius: 5,
                height: { xs: 350, lg: 450 },
                backgroundColor: "#2da4d0",
              }}
              container
              alignItems="center"
              justifyContent="center"
              gap={1}
              padding="0 2% 2% 2%"
            >
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid
                  item
                  sx={{
                    borderRadius: { xs: 1, lg: 2 },
                    backgroundColor: "#fff",
                    fontFamily: "Muli",
                    fontSize: { xs: 13, lg: 18 },
                    fontWeight: "bold",
                    lineHeight: 1.28,
                    textAlign: "left",
                    color: "#101650",
                    padding: "2%",
                    marginTop: "4%",
                  }}
                >
                  TODAY'S ORDERS
                </Grid>
                <Grid
                  item
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                  onClick={() =>
                    navigate(
                      `/machine/orders?machineId=${localStorage.getItem(
                        "machineId"
                      )}&dateId=${2}`
                    )
                  }
                >
                  <img src={dots} alt="dots" />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: { xs: 250, lg: 300 },
                  overflow: "auto",
                  backgroundColor: "#fff",
                  padding: "1%",
                }}
                // container
                alignItems="flex-start"
                justifyContent="center"
              // gap={1}
              >
                {todaysOrders.length > 0 ?
                  todaysOrders?.map((d, index) => (
                    <Grid
                      marginTop={index !== 0 ? 1 : 0}
                      item
                      key={index}
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                      gap={1}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() =>
                        navigate(`/machine/assignedLines?groupId=${d?.Id}`)
                      }
                      sx={{
                        fontFamily: "Muli",
                        textAlign: "left",
                        color: "#000",
                        fontSize: 14,
                        padding: { xs: "2%", md: "4%" },
                        boxShadow: "0 3px 6px 0 #2da4d0",
                      }}
                    >
                      <Grid item xs={12} fontSize={18}>
                        {d?.OrderCode}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>{d?.ProductionDate}</Grid>
                        <Grid item>{d?.Diameter}</Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>Total {d?.TotalNbOfPieces} Pcs</Grid>
                        <Grid item>{d?.TotalWeight} Kgs</Grid>
                        <Grid item>{d?.PercentageOfAccomplishment}</Grid>
                      </Grid>
                      <Grid item container justifyContent='flex-end'>
                        {parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) === 0 ? 'Not Initiated' :
                          parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) > 1 && parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) <= 80 ? 'In Progress' :
                            parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) > 80 && parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) <= 99 ? 'Near Completion' :
                              ""
                        }
                      </Grid>
                                          
                      {d.PreviousMachineName ? (
                        <Grid item container textAlign={"left"}>
                          Replaced from : &nbsp;
                          <Grid item color={"#101650"}>
                            {d.PreviousMachineName}
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )) :
                  <Grid item container justifyContent='center' marginTop={8}>No orders found</Grid>
                }

              </Grid>
            </Grid>
            <Grid
              item
              xs={3.8}
              sx={{
                borderRadius: 5,
                height: { xs: 350, lg: 450 },
                backgroundColor: "#e1e319",
              }}
              container
              alignItems="center"
              justifyContent="center"
              gap={1}
              padding="0 2% 2% 2%"
            >
              <Grid item xs={12} container alignItems="center">
                <Grid
                  item
                  sx={{
                    borderRadius: { xs: 1, lg: 2 },
                    backgroundColor: "#fff",
                    fontFamily: "Muli",
                    fontSize: { xs: 10, lg: 18 },
                    fontWeight: "bold",
                    lineHeight: 1.28,
                    textAlign: "left",
                    color: "#101650",
                    padding: "2%",
                    marginTop: "4%",
                  }}
                >
                  FUTURE PLANNED ORDERS
                </Grid>
                <Grid
                  item
                  xs={1}
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                  onClick={() =>
                    navigate(
                      `/machine/orders?machineId=${localStorage.getItem(
                        "machineId"
                      )}&dateId=${3}`
                    )
                  }
                  paddingLeft={{ xs: "1.5%", md: "15%", lg: "2%", xl: "20%" }}
                >
                  <img src={dots} alt="dots" />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: { xs: 250, lg: 300 },
                  overflow: "auto",
                  backgroundColor: "#fff",
                  padding: "1%",
                }}
                // container
                alignItems="flex-start"
                justifyContent="center"
              // gap={1}
              >
                {futurePlannedOrders.length > 0 ?
                  futurePlannedOrders?.map((d, index) => (
                    <Grid
                      marginTop={index !== 0 ? 1 : 0}
                      item
                      key={index}
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                      gap={1}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() =>
                        navigate(`/machine/assignedLines?groupId=${d?.Id}`)
                      }
                      sx={{
                        fontFamily: "Muli",
                        textAlign: "left",
                        color: "#000",
                        fontSize: 14,
                        padding: { xs: "2%", md: "4%" },
                        boxShadow: "0 3px 6px 0 #e1e319",
                      }}
                    >
                      <Grid item xs={12} fontSize={18}>
                        {d?.OrderCode}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>{d?.ProductionDate}</Grid>
                        <Grid item>{d?.Diameter}</Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>Total {d?.TotalNbOfPieces} Pcs</Grid>
                        <Grid item>{d?.TotalWeight} Kgs</Grid>
                        <Grid item>{d?.PercentageOfAccomplishment}</Grid>
                      </Grid>
                      <Grid item container justifyContent='flex-end'>
                        {parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) === 0 ? 'Not Initiated' :
                          parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) > 1 && parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) <= 80 ? 'In Progress' :
                            parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) > 80 && parseFloat(d?.PercentageOfAccomplishment?.replace('%', '')) <= 99 ? 'Near Completion' :
                              ""
                        }
                      </Grid>
                      {d.PreviousMachineName ? (
                        <Grid item container textAlign={"left"}>
                          Replaced from : &nbsp;
                          <Grid item color={"#101650"}>
                            {d.PreviousMachineName}
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ))
                  :
                  <Grid item container justifyContent='center' marginTop={8}>No orders found</Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </>
  );
}
