import React, { useState, useEffect } from "react";
import classing from "../../../../Assets/Styles/factory.module.css";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import PlusRow from "../../../../Assets/Images/Factory/plusRow.png";
import RemoveIcon from "../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import * as MACHINECONSTS from "../../../../../FactoryConsts";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);
const MachineSetup = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorEmpty, setErrorEmpty] = useState(false);

  const [ItemSelected, setItemSelected] = useState(props.inventories.length > 0 ?
    props.inventories[0]
    : {
      Id: 1,
      ItemCategoryId: props.rMCategoryId ? props.rMCategoryId : null,
      ItemTypeId: null,
      SpliceTypeId: null,
      BarStandardId: null,
      BarGradeId: null,
      BarCoatingId: null,
      Diameters: [],
    });
  const [ItemSelectedId, setItemSelectedId] = useState(props.inventories.length > 0 ? props.inventories[0]?.Id : 0);
  const [diasSelected, setDiasSelected] = useState(props.inventories.length > 0 ?
    props.inventories[0]?.Diameters.flatMap((x) => x.DiameterId) : []);

  const handleZone = (event, inptZone) => {
    const {
      target: { value },
    } = event;
    inptZone =
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value;
    forceUpdate();
  };
  useEffect(() => {
    if (
      !ItemSelected.BarCoatingId ||
      !ItemSelected.BarStandardId ||
      !ItemSelected.BarGradeId ||
      !ItemSelected.ItemTypeId ||
      !ItemSelected.ItemCategoryId ||
      !ItemSelected?.Diameters?.length === 0 ||
      // (ItemSelected.ItemCategoryId === props.itemCategories?.find((i) => i.Name === "PreCut")?.Id && !ItemSelected?.SpliceTypeId && (props.category === MACHINECONSTS.FORGING_MACHINE_ID || props.category === MACHINECONSTS.THREADING_MACHINE_ID))
      (!ItemSelected?.SpliceTypeId && (props.category === MACHINECONSTS.FORGING_MACHINE_ID || props.category === MACHINECONSTS.THREADING_MACHINE_ID))

    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

  });

  const CheckAllFilled = () => {
    let empty = false;
    ItemSelected.Diameters.map((dia) => {
      if (
        !dia.MinCapacity ||
        !dia.MaxCapacity ||
        !dia.TargetOutputCapacity ||
        (!dia.OptimalCycleCapacity &&
          props.category !== MACHINECONSTS.STIRRUP_MACHINE_ID) ||
        (!dia.FormerDiameter &&
          (props.category === MACHINECONSTS.BENDING_2D_MACHINE_ID ||
            props.category === MACHINECONSTS.BENDING_3D_MACHINE_ID ||
            props.category === MACHINECONSTS.STIRRUP_MACHINE_ID 
      
          ))
      ) {
        empty = true;
        setErrorEmpty(true);
      }
    });
    if (!empty) {
      setErrorEmpty(false);
      const tobeReplaced = props.inventories.find(
        (x) => x.Id === ItemSelected.Id
      );
      if (tobeReplaced) {
        ItemSelected.Id = tobeReplaced.Id;
        let newInventory = props.inventories.filter(
          (obj) => obj.Id !== tobeReplaced.Id
        );
        newInventory = newInventory.concat(
          structuredClone({
            Id: ItemSelected.Id,
            ItemCategoryId: ItemSelected.ItemCategoryId,
            ItemTypeId: ItemSelected.ItemTypeId,
            SpliceTypeId: ItemSelected.SpliceTypeId,
            BarStandardId: ItemSelected.BarStandardId,
            BarGradeId: ItemSelected.BarGradeId,
            BarCoatingId: ItemSelected.BarCoatingId,
            Diameters: ItemSelected.Diameters,
          })
        );
        props.setInventories(newInventory);
      } else {
        props.setInventories(
          props.inventories.concat(
            structuredClone({
              Id: props.inventories.length + 1,
              ItemCategoryId: ItemSelected.ItemCategoryId,
              ItemTypeId: ItemSelected.ItemTypeId,
              SpliceTypeId: ItemSelected.SpliceTypeId,
              BarStandardId: ItemSelected.BarStandardId,
              BarGradeId: ItemSelected.BarGradeId,
              BarCoatingId: ItemSelected.BarCoatingId,
              Diameters: ItemSelected.Diameters,
            })
          )
        );
      }

      setItemSelected({
        Id: null,
        ItemCategoryId: null,
        ItemTypeId: null,
        SpliceTypeId: null,
        BarStandardId: null,
        BarGradeId: null,
        BarCoatingId: null,
        Diameters: [],
      });
      setDiasSelected([]);
      setItemSelectedId(null);
    }
  };
  return (
    <Grid
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={3}
      paddingBottom={1}
    >
      <Grid item container>
        <Grid item xs={12} paddingTop={1} className={classing["Title"]}>
          Machine Setup
        </Grid>
        <Grid item xs={12} className={classing["plus-hint"]}>
          Press Plus Icon to add new input and output fields
        </Grid>
        <Grid item container direction={"row"} gap={2} paddingTop={0.5}>
          <Grid item xs={7}>
            <Grid
              item
              xs={12}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                props.setInputsSelected(
                  props.inputsSelected.concat({ BlockId: 0, Zones: [] })
                );
              }}
            >
              <img src={PlusRow} alt={"plus"} />
            </Grid>

            {props.inputsSelected?.map((inputZone, index) => (
              <Grid item sm={12} container xs={12} key={index} gap={2}>
                <Grid
                  item
                  sm={1}
                  paddingTop={3}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    if (!props.disabledEdit) {
                      if (props.inputsSelected.length > 1) {
                        props.setInputsSelected(
                          props.inputsSelected.filter(
                            (inp, indx) => index !== indx
                          )
                        );
                      }
                    }
                  }}
                >
                  <img src={RemoveIcon} width={15} alt="rmove" />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid
                    item
                    className={classing["TFTitle"]}
                    paddingLeft={1}
                    xs={12}
                  >
                    *Input Block Code
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={props.disabledEdit}
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      value={inputZone.BlockId}
                      fullWidth
                      select
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      onChange={(e) => {
                        inputZone.BlockId = e.target.value;
                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Input
                      </MenuItem>
                      {props.inputs.map((r) => (
                        <MenuItem value={r.BlockId} key={r.BlockId}>
                          {r.CategoryId
                            ? r.CategoryId === 1
                              ? r.Code + "/Raw Material"
                              : r.CategoryId === 2
                                ? r.Code + "/Remnant"
                                : r.CategoryId === 3
                                  ? r.Code + "/Products"
                                  : r.CategoryId === 4
                                    ? r.Code + "/WIP"
                                    : r.CategoryId === 5
                                      ? r.Code + "/FGS"
                                      : r.Code + ""
                            : r.Code + "/" + r.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {inputZone.BlockId !== 0 || !inputZone.BlockId ? (
                  props.inputs.filter((x) => x.BlockId === inputZone.BlockId)[0]
                    ?.Zones ? (
                    <Grid item sm={5} xs={12}>
                      <Grid
                        item
                        xs={12}
                        className={classing["TFTitle"]}
                        paddingLeft={1}
                      >
                        *Storage Zone Code
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          disabled={props.disabledEdit}
                          SelectProps={{
                            MenuProps: { disableScrollLock: true },
                            multiple: true,
                          }}
                          value={inputZone.Zones}
                          fullWidth
                          select
                          InputProps={{
                            style: {
                              height: "40px",
                              fontSize: 12,
                              fontFamily: "Muli",
                            },
                          }}
                          onChange={(e) => {
                            inputZone.Zones = e.target.value;
                            console.log(inputZone.Zones);
                            forceUpdate();
                          }}
                        >
                          <MenuItem value={0} key={0} disabled>
                            Input
                          </MenuItem>
                          {props.inputs
                            .filter((x) => x.BlockId === inputZone.BlockId)[0]
                            .Zones.map((r) => (
                              <MenuItem value={r.Id} key={r.Id}>
                                {r.Code}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              xs={12}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                props.setOutputsSelected(
                  props.outputsSelected.concat({ BlockId: 0 })
                );
              }}
            >
              <img src={PlusRow} alt={"plus"} />
            </Grid>

            {props.outputsSelected?.map((outputZone, index) => (
              <Grid item container xs={12} key={index} gap={2}>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    className={classing["TFTitle"]}
                    paddingLeft={1}
                  >
                    *Output Block Code
                  </Grid>
                  <Grid item container direction="row" sm={9} xs={12} gap={1}>
                    <Grid item xs={10}>
                      <TextField
                        disabled={props.disabledEdit}
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                        value={outputZone.BlockId}
                        fullWidth
                        select
                        InputProps={{
                          style: {
                            height: "40px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onChange={(e) => {
                          outputZone.BlockId = e.target.value;
                          forceUpdate();
                        }}
                      >
                        <MenuItem value={0} key={0} disabled>
                          Output
                        </MenuItem>
                        {props.outputs.map((r) => (
                          <MenuItem value={r.BlockId} key={r.BlockId}>
                            {r.CategoryId
                              ? r.CategoryId === 1
                                ? r.Code + "/Raw Material"
                                : r.CategoryId === 2
                                  ? r.Code + "/Remnant"
                                  : r.CategoryId === 3
                                    ? r.Code + "/Products"
                                    : r.CategoryId === 4
                                      ? r.Code + "/WIP"
                                      : r.CategoryId === 5
                                        ? r.Code + "/FGS"
                                        : r.Code + ""
                              : r.Code + "/" + r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      paddingTop={1}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        if (!props.disabledEdit) {
                          if (props.outputsSelected.length > 1) {
                            props.setOutputsSelected(
                              props.outputsSelected.filter(
                                (inp, indx) => index !== indx
                              )
                            );
                          }
                        }
                      }}
                    >
                      <img src={RemoveIcon} width={15} alt="rmove" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingTop={4} className={classing["Title"]}>
        Inputs Setup
      </Grid>
      <Grid item container xs={12}>
        {props.inventories.length > 0 ? (
          <Grid item container xs={3}>
            <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
              Inputs Setups
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                value={ItemSelectedId}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                }}
                fullWidth
                select
                InputProps={{
                  style: {
                    height: "40px",
                    fontSize: 12,
                    fontFamily: "Muli",
                  },
                }}
                onChange={(e) => {
                  setItemSelectedId(e.target.value);
                  setItemSelected(
                    props.inventories.filter((x) => x.Id === e.target.value)[0]
                  );
                  setDiasSelected(
                    props.inventories
                      .filter((x) => x.Id === e.target.value)[0]
                      ?.Diameters.flatMap((x) => x.DiameterId)
                  );
                }}
              >
                {props.inventories?.map((r, index) => (
                  <MenuItem value={r.Id} key={r.Id}>
                    {"Input Setup #" + (index + 1)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item container paddingTop={1} gap={3}>
        <Grid item container xs={3}>
          <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
            *Item Category
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField

              value={ItemSelected.ItemCategoryId}
              fullWidth
              select
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
              onChange={(e) => {
                setItemSelected({
                  Id: ItemSelected.Id,
                  ItemCategoryId: e.target.value,
                  ItemTypeId: null,
                  SpliceTypeId: null,
                  BarStandardId: null,
                  BarGradeId: null,
                  BarCoatingId: null,
                  Diameters: [],
                })
                setDiasSelected([]);
                // ItemSelected.ItemCategoryId = e.target.value;
                // forceUpdate();
              }}
            >
              {props.itemCategories?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
            *Item Type
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              disabled={props.disabledEdit}
              value={ItemSelected.ItemTypeId}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                // multiple: true,
              }}
              fullWidth
              select
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              onChange={(e) => {
                setItemSelected({
                  Id: ItemSelected.Id,
                  ItemCategoryId: ItemSelected.ItemCategoryId,
                  ItemTypeId: e.target.value,
                  SpliceTypeId: null,
                  BarStandardId: null,
                  BarGradeId: null,
                  BarCoatingId: null,
                  Diameters: [],
                });
                setDiasSelected([]);

                // ItemSelected.ItemTypeId = e.target.value;
                // forceUpdate();

                // setItemSelected({
                //   ...ItemSelected,
                //   ItemTypeId: e.target.value,
                // });
              }}
            >
              {props.itemCategories?.find((i) => i.Id === ItemSelected.ItemCategoryId)?.Types?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {/* {ItemSelected.ItemCategoryId === props.itemCategories?.find((i) => i.Name === "PreCut")?.Id && (props.category === MACHINECONSTS.FORGING_MACHINE_ID || props.category === MACHINECONSTS.THREADING_MACHINE_ID) ? */}
        {props.category === MACHINECONSTS.FORGING_MACHINE_ID || props.category === MACHINECONSTS.THREADING_MACHINE_ID ?
          <Grid item container xs={3}>
            <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
              *Splice Type
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField

                value={ItemSelected.SpliceTypeId}
                fullWidth
                select
                InputProps={{
                  style: {
                    height: "40px",
                    fontSize: 12,
                    fontFamily: "Muli",
                  },
                }}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                }}
                onChange={(e) => {
                  ItemSelected.SpliceTypeId = e.target.value;
                  forceUpdate();
                }}
              >
                {props.spliceTypes?.map((r) => (
                  <MenuItem value={r.Id} key={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          :
          <></>}
        <Grid item container xs={3}>
          <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
            *Item Diameters
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              disabled={props.disabledEdit}
              value={diasSelected}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                multiple: true,
              }}
              fullWidth
              select
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              onChange={(e) => {
                let diaSelected = ItemSelected.Diameters;
                e.target.value.map((dia) => {
                  if (!diaSelected.filter((x) => x.DiameterId === dia)[0]) {
                    diaSelected = diaSelected.concat({
                      DiameterId: dia,
                      Name: props.itemCategories?.find((i) => i.Id === ItemSelected.ItemCategoryId)?.Types
                        ?.filter((x) => x.Id === ItemSelected.ItemTypeId)[0]
                        ?.Diameters.filter((l) => l.Id === dia)[0].Name,
                      MinCapacity: null,
                      MaxCapacity: null,
                      OptimalCycleCapacity: null,
                      FormerDiameter: null,
                      TargetOutputCapacity: null,
                    });
                  }
                });
                diaSelected.map((dia) => {
                  if (!e.target.value.filter((x) => x === dia.DiameterId)[0]) {
                    diaSelected = diaSelected.filter(
                      (x) => x.DiameterId !== dia.DiameterId
                    );
                  }
                });
                console.log(diaSelected);
                setItemSelected({ ...ItemSelected, Diameters: diaSelected });
                setDiasSelected(e.target.value);
              }}
            >
              {props.itemCategories?.find((i) => i.Id === ItemSelected.ItemCategoryId)?.Types
                ?.filter((x) => x.Id === ItemSelected.ItemTypeId)[0]
                ?.Diameters.map((r) => (
                  <MenuItem value={r.Id} key={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
            *Standard
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              disabled={props.disabledEdit}
              value={ItemSelected.BarStandardId}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
              fullWidth
              select
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              onChange={(e) => {
                setItemSelected({
                  ...ItemSelected,
                  BarStandardId: e.target.value,
                });
              }}
            >
              {props.standards?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
            *Grade
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              disabled={props.disabledEdit}
              value={ItemSelected.BarGradeId}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
              fullWidth
              select
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              onChange={(e) => {
                setItemSelected({
                  ...ItemSelected,
                  BarGradeId: e.target.value,
                });
              }}
            >
              {props.grade?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
            *Coating
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              disabled={props.disabledEdit}
              value={ItemSelected.BarCoatingId}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
              fullWidth
              select
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              onChange={(e) => {
                setItemSelected({
                  ...ItemSelected,
                  BarCoatingId: e.target.value,
                });
              }}
            >
              {props.coating?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item overflow={"auto"} marginTop={4}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}></TableCell>
                {ItemSelected?.Diameters?.map((item) => (
                  <TableCell sx={styles.tablebig}>{item.Name}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <StyledTableRow>
                <TableCell sx={styles.tableCell}>
                  Min. machine capacity per input (pcs)
                </TableCell>
                {ItemSelected?.Diameters?.map((item) => (
                  <TableCell sx={styles.tableCell} key={item.Id}>
                    <Grid item width={100}>
                      <TextField
                        disabled={props.disabledEdit}
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        value={item.MinCapacity}
                        sx={styles.tfInput}
                        onChange={(e) => {
                          item.MinCapacity = +e.target.value;
                          forceUpdate();
                        }}
                        type={"number"}
                        placeholder="No."
                        InputProps={{
                          inputProps: { min: 0 },
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      />
                    </Grid>
                  </TableCell>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <TableCell sx={styles.tableCell}>
                  Max. machine capacity per input (pcs)
                </TableCell>
                {ItemSelected?.Diameters?.map((item) => (
                  <TableCell sx={styles.tableCell} key={item.Id}>
                    <Grid item width={100}>
                      <TextField
                        disabled={props.disabledEdit}
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        value={item.MaxCapacity}
                        sx={styles.tfInput}
                        onChange={(e) => {
                          item.MaxCapacity = +e.target.value;
                          forceUpdate();
                        }}
                        type={"number"}
                        placeholder="No."
                        InputProps={{
                          inputProps: { min: 0 },
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      />
                    </Grid>
                  </TableCell>
                ))}
              </StyledTableRow>
              {props.category !== MACHINECONSTS.STIRRUP_MACHINE_ID ? (
                <StyledTableRow>
                  <TableCell sx={styles.tableCell}>
                    Optimal machine cycle capacity per input (pcs)
                  </TableCell>
                  {ItemSelected?.Diameters?.map((item) => (
                    <TableCell sx={styles.tableCell} key={item.Id}>
                      <Grid item width={100}>
                        <TextField
                          disabled={props.disabledEdit}
                          variant="standard" // <== changed this
                          margin="normal"
                          fullWidth
                          value={item.OptimalCycleCapacity}
                          sx={styles.tfInput}
                          onChange={(e) => {
                            item.OptimalCycleCapacity = +e.target.value;
                            forceUpdate();
                          }}
                          type={"number"}
                          placeholder="No."
                          InputProps={{
                            inputProps: { min: 0 },
                            disableUnderline: true,
                            style: styles.tfInput,
                          }}
                        />
                      </Grid>
                    </TableCell>
                  ))}
                </StyledTableRow>
              ) : (
                <></>
              )}

              <StyledTableRow>
                <TableCell sx={styles.tableCell}>
                  {props.category === MACHINECONSTS.TRIMMING_MACHINE_ID 
                  || props.category === MACHINECONSTS.FORGING_MACHINE_ID
                  || props.category === MACHINECONSTS.THREADING_MACHINE_ID
                  || props.category === MACHINECONSTS.FRICTION_WELDING_MACHINE_ID
                  
                   ?
                    "Target output capacity number of pcs per day" : "Target output capacity per dia kg/day"}
                </TableCell>
                {ItemSelected?.Diameters?.map((item) => (
                  <TableCell sx={styles.tableCell} key={item.Id}>
                    <Grid item width={100}>
                      <TextField
                        disabled={props.disabledEdit}
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        value={item.TargetOutputCapacity}
                        sx={styles.tfInput}
                        onChange={(e) => {
                          item.TargetOutputCapacity = +e.target.value;
                          forceUpdate();
                        }}
                        type={"number"}
                        placeholder="No."
                        InputProps={{
                          inputProps: { min: 0 },
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      />
                    </Grid>
                  </TableCell>
                ))}
              </StyledTableRow>
              {props.category === MACHINECONSTS.BENDING_2D_MACHINE_ID ||
                props.category === MACHINECONSTS.BENDING_3D_MACHINE_ID ||
                props.category === MACHINECONSTS.STIRRUP_MACHINE_ID 
                ? (
                  <StyledTableRow>
                    <TableCell sx={styles.tableCell}>
                      Former diameters per input 
                    </TableCell>
                    {ItemSelected?.Diameters?.map((item) => (
                      <TableCell sx={styles.tableCell} key={item.Id}>
                        <Grid item width={100}>
                          <TextField
                            disabled={props.disabledEdit}
                            variant="standard" // <== changed this
                            margin="normal"
                            fullWidth
                            value={item.FormerDiameter}
                            sx={styles.tfInput}
                            onChange={(e) => {
                              item.FormerDiameter = +e.target.value;
                              forceUpdate();
                            }}
                            type={"number"}
                            placeholder="No."
                            InputProps={{
                              inputProps: { min: 0 },
                              disableUnderline: true,
                              style: styles.tfInput,
                            }}
                          />
                        </Grid>
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ) : (
                  <></>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {ItemSelected ? (
        <Grid item container direction={"row"} xs={12} paddingTop={2}>
          <Grid item container xs={6}>
            {props.inventories.find((x) => x.Id === ItemSelected.Id) ? (
              <button
                disabled={props.disabledEdit}
                className={classing["DeleteButton"]}
                onClick={() => {
                  props.setInventories(
                    props.inventories.filter((x) => x.Id !== ItemSelected.Id)
                  );
                  setItemSelected({
                    Id: null,
                    ItemCategoryId: props.rMCategoryId,
                    ItemTypeId: null,
                    SpliceTypeId: null,
                    BarStandardId: null,
                    BarGradeId: null,
                    BarCoatingId: null,
                    Diameters: [],
                  });
                  setItemSelectedId(null);
                  setDiasSelected([]);
                }}
              >
                Delete
              </button>
            ) : (
              ""
            )}
          </Grid>
          <Grid item container xs={6} direction={"row-reverse"} gap={1}>
            <button
              className={classing["SaveBut"]}
              disabled={isDisabled}
              onClick={() => {
                CheckAllFilled();
              }}
            >
              {props.inventories.find((x) => x.Id === ItemSelected.Id)
                ? "Update Input Setup"
                : "Add Input Setup"}
            </button>
            <button
              className={classing["cancelbut"]}
              onClick={() => {
                setItemSelected({
                  Id: null,
                  ItemCategoryId: null,
                  ItemTypeId: null,
                  SpliceTypeId: null,
                  BarStandardId: null,
                  BarGradeId: null,
                  BarCoatingId: null,
                  Diameters: [],
                });
                setItemSelectedId(null);
                setDiasSelected([]);
              }}
            >
              Cancel
            </button>
            <Grid
              item
              fontFamily={"Muli"}
              fontSize={12}
              fontWeight={"bold"}
              color={"#ea001e"}
            >
              {errorEmpty ? "Check for empty field in the table" : ""}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default MachineSetup;
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px #e9e7e7",
    height: 37,
    width: 95,
  },
  tableCellEmp: {
    backgroundColor: "#fff",
    border: 0,
    width: 95,
  },
  tableCellMul: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    height: 37,
    padding: 0,
  },

  tableCellSmall: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    borderRight: "solid 1px #e9e7e7",
    width: 70,
    textAlign: "left",
    borderBottom: "0px",
    height: 37,
  },
  tablebig: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#2da4d0",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    width: 111,
  },
  tableCellDisable: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#2da4d0",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    paddingLeft: 2,
    height: 5,
  },
};
