import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import { useNavigate, useLocation } from "react-router-dom";
import Contract from "./Contract";
import classing from "../../../../Assets/Styles/projects.module.css";
import classes from "../Create.module.css";
import remove from "../../../../Assets/Images/Subprojects/remove.png";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import yp from "../../../../Assets/Images/Projects/yp.png";
import bp from "../../../../Assets/Images/Projects/bp.png";
import moment from "moment";

import {
  Button,
  Grid,
  TextField,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  Modal,
  Backdrop,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PropTypes from "prop-types";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureModal from "./PictureModal";
import ScModal from "./ScModal";
import * as services from "../../../../Services/projects-services.proxy";
import * as subServices from "../../../../Services/subprojects-services.proxy";
import DisciplineModal from "./DisciplineModal";
import { useSelector } from "react-redux";
import ErrorModal from "../../../../SharedComponents/ErrorModal"

const getProjectInputData = services.getProjectInputData;
const createSubProject = subServices.createSubProject;
const getSubProjectInputData = subServices.getSubProjectInputData;
const updateSubProject = subServices.updateSubProject;
const updateSubContractors = subServices.updateSubContractors;
const getSubUpdateInputData = subServices.getSubUpdateInputData;
const getAssignedSubprojectPhases = subServices.getAssignedSubprojectPhases

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: "500px",
  transform: 'translate(-50%, -50%)',
  p: 4,
  borderRadius: "12px",
  border: "solid 1px #707070",
  backgroundColor: "#fff",
  outline: "none"
}

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 12px;
  height:23px;
  width: 120px;
  box-shadow: 0 3px 6px 0 #0674b9;
  background-color: #fff;
  border-radius: 5px;
  border:0;
  color: #0674b9;

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  min-width: 87.6px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color: #101650;
        background-color: lightgrey;
        font-size: 10px;
        width:100px;
        marginLeft:'50%';

    `);

const BlueTip1 = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
            color: #101650;
            background-color: rgba(45, 164, 208, 0.3);
            font-size: 10px;
            width:80px;
        `);

export default function SubProject(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [errorMsg, setErrorMsg] = useState("");

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [projectId, setProjectId] = useState(null);

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (apiTest) {
      setProjectId(query.get("id"));
    }
    // cleanup
    return () => {
      apiTest = false;
    };
  }, []);

  const styles = {
    label: {
      fontFamily: "Muli",
      fontSize: 12,
      fontWeight: "bold",
      lineHeight: 1.5,
      textAlign: "left",
      color: "#101650",
    },
    error: {
      fontFamily: "Muli",
      fontSize: 12,
      lineHeight: 1.5,
      textAlign: "left",
      color: "#d32f2f",
      paddingTop: "1.5%",
    },
  };

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );


  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const [selectedDisc, setSelectedDisc] = useState([]);
  const [selectedTrades, setSelecetedTrades] = useState([]);

  const [subMilestoned, setSubMileStoned] = useState(false);
  const [subName, setSubName] = useState("");
  const [subNameError, setSubNameError] = useState(false);
  const [subNameInvalid, setSubNameInvalid] = useState(false);
  const [subType, setSubType] = useState("");
  const [subTypeError, setSubTypeError] = useState(false);
  const [subTypeOther, setSubTypeOther] = useState("");
  const [subPicture, setSubPicture] = useState(null);
  const [subPictureURL, setSubPictureURL] = useState(null);
  const [subCode, setSubCode] = useState("");
  const [subCodeInvalid, setSubCodeInvalid] = useState(false);
  const [subAbbreviation, setSubAbbreviation] = useState("");
  const [subAbbreviationInvalid, setSubAbbreviationInvalid] = useState(false);
  const [subDescription, setSubDescription] = useState("");
  const [subDescriptionInvalid, setSubDescriptionInvalid] = useState(false);
  const [subResponsibility, setSubResponsibility] = useState(1);
  const [subStartDate, setSubStartDate] = useState(null);
  const [subStartDateError, setSubStartDateError] = useState(false);
  const [subEndDate, setSubEndDate] = useState(null);
  const [subEndDateError, setSubEndDateError] = useState(false);

  const [subContractors, setSubContractors] = useState([]);

  const [subCompanies, setSubCompanies] = useState([]);

  const [subCompany, setSubCompany] = useState(null);
  const [subCompanyError, setSubCompanyError] = useState(false);

  const AccountType = localStorage.getItem("AccountType");

  const [empty, setEmpty] = useState(true);
  const [openImage, setOpenImage] = useState(false);
  const [openSc, setOpenSc] = useState(false);
  const [openDT, setOpenDT] = useState(false);

  const [phases, setPhases] = useState([]);
  const [phaseId, setPhaseId] = useState(null);
  const [phaseName, setPhaseName] = useState("*Phase Name");
  const [projectName, setProjectName] = useState("Project Name");
  const [subTypes, setSubTypes] = useState([]);
  const [subProjectId, setSubProjectId] = useState(null);
  const [phaseNameFromTable, setPhaseNameFromTable] = useState(
    localStorage.getItem("phaseName")
  );

  const localStoragePhaseId = localStorage.getItem("phaseId");
  const localStorageSubId = localStorage.getItem("subId");

  const [pName, setPName] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [subProjectParentName, setSubProjectParentName] = useState(null);
  const [subProjectParentId, setSubProjectParentId] = useState(null);
  const [showContract, setShowContract] = useState(false);

  const [disciplines, setDisciplines] = useState([]);
  const [trades, setTrades] = useState([]);

  const [contractStatuses, setContractStatuses] = useState([]);
  const [factoryNumbers, setFactoryNumbers] = useState([]);
  const [barStandards, setBarStandards] = useState([]);
  const [barGrades, setBarGrades] = useState([]);
  const [barCoatings, setBarCoatings] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [contractSubTypes, setContractSubTypes] = useState([]);
  const [contractBillingTypes, setContractBillingTypes] = useState([]);
  const [defaultSheetSizes, setDefaultSheetSizes] = useState([]);
  const [discSaved, setDiscSaved] = useState(false);
  const [discAssigned, setDiscAssigned] = useState(false);

  const [contractDetails, setContractDetails] = useState(null);
  const [subContracts, setSubContracts] = useState([]);

  const [contractorName, setContractorName] = useState("");

  const deleteItem = (item) => {
    setSubCompany(null);
    const index = subContractors.indexOf(item);
    if (index > -1) {
      subContractors.splice(index, 1);
    }
    if (subContractors.length === 0) { setSubResponsibility(1); setShowContract(false) }
  };

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [empty1, setEmpty1] = useState(true);
  const [invitedCompany, setInvitedCompany] = useState(null);

  const [subProSaved, setSubProSaved] = useState(false);
  const [subContSaved, setSubContSaved] = useState(false);



  const handleInputChange = (e, date) => {
    console.log("subb", e.target.value)
    if (e.target.value.length === 8) {
      setSubStartDateError(false);
      setSubEndDateError(false)
    }
    let switchDayAndMonth = (e.target.value).substring(3, 5) + "/" + (e.target.value).substring(0, 2) + "/" + (e.target.value).substring(6, 8)
    if (date === "start") setSubStartDate(switchDayAndMonth);
    else setSubEndDate(switchDayAndMonth)
  };

  useEffect(() => {
    if (subResponsibility === 2 && !contractDetails) setEmpty1(true);
    else setEmpty1(false);
  });

  useEffect(() => {
    let arr1 = disciplines.filter((d) => d.IsSelected === true);
    let arr2 = trades.filter((d) => d.IsSelected === true);
    setSelectedDisc(arr1);
    setDisciplines(arr1);
    setSelecetedTrades(arr2);
    setTrades(arr2);
  }, [discAssigned]);

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (projectId) {
      getProjectInputData(projectId).then((x) => {
        if (apiTest) {
          setProjectName(x.Project.Name);
          if (localStoragePhaseId) {
            setPhaseId(localStoragePhaseId);
            setPhaseName(
              x.Project.Phases.find((x) => x.Id === localStoragePhaseId)?.Name
            );
          }
        }
      });
      getAssignedSubprojectPhases(projectId).then(x => {
        if (apiTest) {
          setPhases(x);
          if (x.length === 1) {
            setPhaseName(x[0].Name);
            setPhaseId(x[0].Id)
          }
        }
      })
    }
    // cleanup
    return () => {
      apiTest = false;
    };
  }, [projectId]);

  useEffect(() => {
    // side effects
    if (subProjectParentId) setDiscSaved(true);
    if (localStorageSubId) setSubProjectId(localStorageSubId);
    let apiTest = true;
    if (projectId)
      getSubProjectInputData(subProjectId, projectId, subProjectParentId).then(
        (x) => {
          if (apiTest) {
            setSubTypes(x.Types);
            if (subProjectId) {
              setDiscSaved(true);
              setSubMileStoned(x.SubProject.IsMilestoned);
              setSubName(x.SubProject.Name);
              setSubType(x.SubProject.TypeId);
              if (x.SubProject.TypeOthers)
                setSubTypeOther(x.SubProject.TypeOthers);
              if (x.SubProject.Picture) {
                setSubPicture(x.SubProject.Picture.FileId);
                setSubPictureURL(x.SubProject.Picture.URL);
              }
              setSubCode(x.SubProject.Code);
              setSubAbbreviation(x.SubProject.Abbreviation);
              setSubDescription(x.SubProject.Description);
              setSubStartDate(moment(x.SubProject.StartDate).format("YYYY-MM-DD"));
              setSubEndDate(moment(x.SubProject.EndDate).format("YYYY-MM-DD"));
              setSubResponsibility(x.SubProject.ResponsibilityId);
              console.log("subp re", x.SubProject.ResponsibilityId)
              setDisciplines(
                x.SubProject.Disciplines.filter((d) => d.Name !== "Others")
              );
              setTrades(x.SubProject.Trades.filter((t) => t.Name !== "Others"));
              setSelectedDisc(
                x.SubProject.Disciplines.filter(
                  (d) => d.IsSelected === true && d.Name !== "Others"
                )
              );
              setSelecetedTrades(
                x.SubProject.Trades.filter(
                  (t) => t.IsSelected === true && t.Name !== "Others"
                )
              );
              if (x.SubProject.parent) setPName(x.SubProject.parent);
            } else {
              setDisciplines(x.Disciplines.filter((d) => d.Name !== "Others"));
              setTrades(x.Trades.filter((t) => t.Name !== "Others"));
              setSelectedDisc(
                x.Disciplines.filter(
                  (d) => d.IsSelected === true && d.Name !== "Others"
                )
              );
              setSelecetedTrades(
                x.Trades.filter(
                  (t) => t.IsSelected === true && t.Name !== "Others"
                )
              );
            }
          }
          setTimeout(() => {
            setSuccessMsg(null)
          }, 2000);
        }
      );
    // cleanup
    return () => {
      apiTest = false;
    };
  }, [projectId, subProjectId, subProSaved, subProjectParentId]);

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSubContractsData, setLoadingSubContractsData] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    if (
      !phaseId ||
      !subName ||
      subNameError ||
      !subType ||
      subTypeError ||
      !subStartDate ||
      subStartDateError ||
      !subEndDate ||
      subEndDateError ||
      subCodeInvalid ||
      subDescriptionInvalid ||
      subAbbreviationInvalid ||
      (discSaved === false &&
        !(
          AccountType === "2" &&
          (localStorage.getItem("planId") === "Core" || localStorage.getItem("planId") === "Pro" ||
            (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true"))
        ))
    ) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  });

  useEffect(() => {
    if (localStorage.getItem("phaseId"))
      setPhaseId(localStorage.getItem("phaseId"));
  });

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (subProjectId)
      setTimeout(() => {
        getSubUpdateInputData(subProjectId).then((x) => {
          if (apiTest) {
            setMeasurements(x.MeasurementSystems)
            setContractTypes(x.ContractTypes);
            setAccountTypes(x.AccountTypes);
            setContractStatuses(x.ContracttStatuses);
            setContractSubTypes(x.ContractSubTypes);
            setDefaultSheetSizes(x.SheetSizes);
            setContractBillingTypes(x.BillingTypes);
            if (x.InvitedCompanies.length > 0) {
              setSubResponsibility(2);
            } else setSubResponsibility(1);
            setSubContractors(x.InvitedCompanies);
            setSubCompanies(x.SubContractorCompanies);
            if (x.InvitedCompanies.length > 0) {
              setSubContracts(x.InvitedCompanies[0])
              setContractDetails(x.InvitedCompanies[0].Contracts[0]);
            }
          }
          setTimeout(() => {
            setSuccessMsg(null)
          }, 2000);
        });
      }, 1000);
    return () => {
      apiTest = false;
    };
  }, [subProjectId, subContSaved]);

  const subproject = {
    Id: subProjectId,
    Name: subName,
    Code: subCode,
    Abbreviation: subAbbreviation,
    Description: subDescription,
    TypeId: subType,
    TypeOthers: subTypeOther,
    Picture: {
      FileId: subPicture,
    },
    StartDate: subStartDate,
    EndDate: subEndDate,
    Disciplines: selectedDisc,
    Trades: selectedTrades,
    ResponsibilityId: subResponsibility,
    InvitedCompanies: [],
    PhaseId: phaseId,
    SubProjectParentId: subProjectParentId,
  };

  const subCompanyData = {
    InviteeEmail: subCompany?.InviteeEmail
      ? subCompany?.InviteeEmail
      : invitedCompany?.InviteeEmail,
    InvitedCompanyName: subCompany?.InvitedCompanyName
      ? subCompany?.InvitedCompanyName
      : invitedCompany?.InvitedCompanyName,
    File: subCompany?.File ? subCompany?.File : invitedCompany?.File,
    Contracts: subContracts,
  };

  const [loadingSc, setLoadingSc] = useState(false);

  const saveContract = () => {
    // console.log({ subContracts });
    // console.log({ subContractors });
    // console.log({ subCompanyData })
    setShowContract(false);
    let temp = subCompanyData;
    let arr = subContractors;
    arr.push(temp);
    let arr1 = arr.filter((o) => o.Contracts?.length > 0 && o.InviteeEmail);
    let arr2 = arr1.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.InviteeEmail === value.InviteeEmail &&
            t.Contracts === value.Contracts
        )
    );
    //console.log({ arr2 });
    let arr3 = arr2.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.InviteeEmail === value.InviteeEmail &&
            t.Contracts?.length === subContracts.length
        )
    );
    //console.log({ arr3 });
    let arr4 = arr2.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.InviteeEmail === value.InviteeEmail &&
            t.Contracts?.length !== subContracts.length
        )
    );
    //console.log({ arr4 });
    let arr5 = arr4.filter(obj => obj.InviteeEmail !== arr3[0].InviteeEmail);
    //console.log({ arr5 })
    let arr6 = arr3.concat(arr5);
    //console.log({ arr6 });
    setSubContractors(arr6);
    setLoadingSc(true);
    setTimeout(() => {
      updateSubContractors(subProjectId, arr3).then((x) => {
        if (x.status) { setErrorMsg(x.error); handleOpenModal(); setLoadingSc(false); }
        else {
          temp = null;
          arr = [];
          arr1 = [];
          arr2 = [];
          arr3 = [];
          arr4 = [];
          arr5 = [];
          arr6 = [];
          setContractDetails(null);
          setSubContracts([])
          setSubCompany(null);
          setEmpty(false);
          setLoadingSc(false);
          setSubContSaved(!subContSaved);
          setSuccessMsg("contractsUpdated")

        }
      });
    }, 1000);
  };

  const addOtherSub = () => {
    if (subProjectId) {
      updateSubProject(subproject).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setSubProSaved(!subProSaved);
          setSubContSaved(!subContSaved);
        }
      });
    } else {
      createSubProject(subproject).then((x) => {
        console.log("created");
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          navigate(`/saas/projects/dashboard?id=${projectId}`);
        }
      });
    }
    setTimeout(() => {
      localStorage.removeItem("subId");
      setSubMileStoned(false);
      setSubName("");
      setSubType("");
      setSubTypeOther("");
      setSubPicture(null);
      setSubPictureURL(null);
      setSubCode("");
      setSubAbbreviation("");
      setSubDescription("");
      setSubResponsibility(1);
      setSubStartDate(null);
      setSubEndDate(null);
      setSubProjectId(null);
    }, 1000);
  };

  const save = () => {
    if (subProjectId) {
      updateSubProject(subproject).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setSubProSaved(!subProSaved);
          setSubContSaved(!subContSaved);
          setSuccessMsg("subUpdated")
        }
        setLoading(false);
      });
    } else {
      createSubProject(subproject).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          console.log("created");
          navigate(`/saas/projects/dashboard?id=${projectId}`);
        }
        setLoading(false);

      });
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        padding: "3% 1% 1% 1%",
      }}
    >

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} container alignItems="center" padding="0 0 5% 10%">
          <Grid
            item
            xs={1.3}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Your SubProject
          </Grid>
          <Grid
            item
            md={6.6}
            xs={5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item xs={3.5} md={2} paddingLeft="1.5%">
            <button
              className={classing["view-dashboard"]}
              onClick={() => {
                navigate(`/saas/projects/dashboard?id=${projectId}`);
              }}
            >
              View project tree
            </button>
          </Grid>
          <Grid item xs={1} paddingLeft={{ xs: "5%", md: "1.5%" }}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("viewSubPro");
                localStorage.removeItem("noCreator");
                localStorage.removeItem("subCreator");
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid item xs={10} container>
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: 21,
              lineHeight: 1.48,
              textAlign: "left",
              color: "#101650",
              backgroundColor: "#fff",
              padding: { sm: "4%", lg: "2%" },
            }}
            style={{
              borderLeft:
                AccountType === "1"
                  ? "7px solid #e1e319"
                  : AccountType === "2"
                    ? "7px solid #2da4d0"
                    : "7px solid lightgrey",
            }}
          >
            Subproject{" "}
            <BlueTip1 title={"Add Subproject +"} placement="right">
              <span>
                <button
                  disabled={
                    empty ||
                    (localStorage.getItem("isGuest") === "true" &&
                      localStorage.getItem("AccountType") !== "1") ||
                    (localStorage.getItem("isTeamMember") === "true" &&
                      !UserPermissions?.find(
                        (x) => x.Name === "Project Manager"
                      ) &&
                      !UserPermissions?.find((x) => x.Name === "Admin") &&
                      !UserPermissions?.find(
                        (x) => x.Name === "Engineering Manager"
                      )) ||
                    (localStorage.getItem("noCreator") === "true" &&
                      localStorage.getItem("AccountType") !== "1")
                    || (
                      !UserPermissions?.find(
                        x => x.Permissions.find(y => y.Name === "Sub Project") &&
                          x.Permissions.find(y => y.Actions.find(z => z.Name === "Create"))
                      )
                    )
                  }
                  style={{ border: 0, backgroundColor: "#fff" }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    localStorage.removeItem("viewSubPro");
                    addOtherSub();
                  }}
                >
                  <img src={AccountType === "1" ? yp : bp} />
                </button>
              </span>
            </BlueTip1>
          </Grid>
        </Grid>
        <Grid item xs={10} container>
          <Grid
            item
            xl={0.295}
            lg={0.33}
            md={0.45}
            sm={0.55}
            sx={{
              backgroundColor:
                AccountType === "1"
                  ? "#e1e319"
                  : AccountType === "2"
                    ? "#2da4d0"
                    : "lightgrey",
            }}
          ></Grid>
          <Grid
            item
            xs={11}
            container
            alignItems="flex-start"
            justifyContent="center"
            spacing={3}
            bgcolor="#fff"
            padding="1% 0 2% 0"
          >
            <Grid
              item
              xs={12}
              sx={{
                fontFamily: "Muli",
                fontSize: 15,
                fontWeight: "bold",
                lineHeight: 1.47,
                textAlign: "left",
                color: "rgba(16, 22, 80, 0.5)",
              }}
              marginLeft="7%"
              container
              alignItems="center"
              gap={0.1}
            >
              <Grid item xs={2.5} md={2}>
                {projectName}
              </Grid>
              {phaseNameFromTable || subProjectParentName ? (
                <Grid item xs={2.5} md={pName || subProjectParentName ? 2 : 6.4}>
                  {phaseNameFromTable}
                </Grid>
              ) : (
                <>
                  <Grid item xs={1.5}>
                    {phaseName}
                  </Grid>
                  {phases?.length > 0 ? (
                    <Grid
                      item
                      xs={4.5}
                      id="Notifi-button"
                      aria-controls={open ? "noti-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    >
                      <KeyboardArrowDownIcon
                        htmlColor="#0674b9"
                        fontSize="medium"
                        style={{ height: 18 }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={4.5}></Grid>
                  )}
                </>
              )}
              <Menu
                id="noti-menu"
                aria-labelledby="Notifi-button"
                anchorEl={anchorEl}
                open={open}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "#fff",
                  },
                }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                }}
                disableScrollLock
              >
                {phases?.map((phase, index) => (
                  <MenuItem
                    key={index}
                    onClick={(e) => {
                      setPhaseId(phase?.Id);
                      setPhaseName(phase?.Name);
                      handleClose();
                    }}
                    style={{
                      height: "60px",
                      fontWeight: "bold",
                      fontFamily: "Muli",
                      fontSize: 13,
                      borderRadius: 5,
                      color: "#0674b9",
                      backgroundColor: "#fff",
                    }}
                  >
                    {phase?.Name}
                  </MenuItem>
                ))}
              </Menu>
              {subProjectParentId ? (
                <Grid item xs={2.5} md={4}>
                  {subProjectParentName}
                </Grid>
              ) : pName ? (
                <Grid item xs={2.5} md={4}>
                  {pName}
                </Grid>
              ) : (
                <></>
              )}
              {subProjectId ?
                <Grid item xs={3}>
                  <button
                    disabled={
                      (localStorage.getItem("isTeamMember") === "true" &&
                        !UserPermissions?.find(
                          (x) => x.Name === "Project Manager"
                        ) &&
                        !UserPermissions?.find((x) => x.Name === "Admin") &&
                        !UserPermissions?.find(
                          (x) => x.Name === "Engineering Manager"
                        ) &&
                        !UserPermissions?.find(
                          (x) => x.Name === "Team Leader"
                        )) ||
                        !subProjectId
                        || (
                          localStorage.getItem("isTeamMember") === "true" &&
                          (!UserPermissions?.find(
                            x => x.Permissions.find(y => y.Name === "Sub Project") &&
                              x.Permissions.find(y => y.Actions.find(z => z.Name === "Assign Team Member Responsibility"))
                          ))
                        )
                        ? true
                        : false
                    }
                    style={{
                      width: 200,
                      color:
                        subProjectId &&
                          localStorage.getItem("viewSubPro") !== "true" && (
                            (localStorage.getItem("planId")?.includes("Pro") ||
                              localStorage.getItem("planId")?.includes("Core"))
                          )
                          ? AccountType === "1"
                            ? "#e1e319"
                            : "#2da4d0"
                          : "lightgrey",
                    }}
                    onMouseOver={(e) => {
                      if (
                        (localStorage.getItem("isGuest") === "true" || localStorage.getItem("planId")?.includes("Pro") ||
                          localStorage.getItem("planId")?.includes("Core"))
                      ) {
                        e.target.style.cursor = "pointer";
                        e.target.style.color = "#fff";
                        e.target.style.backgroundColor =
                          AccountType === "1" ? "#e1e319" : " #2da4d0";
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (
                        (localStorage.getItem("isGuest") === "true" || localStorage.getItem("planId")?.includes("Pro") ||
                          localStorage.getItem("planId")?.includes("Core"))
                      ) {
                        e.target.style.cursor = "pointer";
                        e.target.style.color =
                          AccountType === "1" ? "#e1e319" : "#2da4d0";
                        e.target.style.backgroundColor = "#fff";
                      }
                    }}
                    className={
                      subProjectId &&
                        localStorage.getItem("viewSubPro") !== "true" && (localStorage.getItem("isGuest") === "true" || localStorage.getItem("planId"))
                        ? classes.addButtonEmpty
                        : AccountType === "1"
                          ? classes.addButtonMC
                          : classes.addButtonSC
                    }
                    onClick={() => {
                      if (localStorage.getItem("isGuest") === "true" || localStorage.getItem("planId")?.includes("Core") || localStorage.getItem("planId")?.includes("Pro"))
                        navigate(`/saas/projects/teammember?subId=${subProjectId}`);
                    }}
                  >
                    Assign Team Members +
                  </button>
                </Grid>
                :
                <></>
              }

            </Grid>

            {subProjectId && !subName ? (
              <Grid
                item
                xs={12}
                container
                sx={{
                  position: "relative",
                  left: "45%",
                }}
              >
                <Grid item xs={12} paddingLeft="2%">
                  <CircularProgress size={24} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontfamily: "Muli",
                    fontSize: 18,
                    lineHeight: 1.44,
                    textAlign: "left",
                    color: "#0674b9",
                  }}
                >
                  Loading ...
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={4} container>
              <Grid item xs={12} sx={styles.label}>
                *Subproject Name
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={
                    localStorage.getItem("viewSubPro") === "true" ? true : false
                  }
                  fullWidth
                  value={subName}
                  error={subNameError}
                  helperText={
                    subNameError
                      ? subNameInvalid
                        ? "Name must be less than 150 characters"
                        : "Enter a subproject name"
                      : ""
                  }
                  variant="standard"
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setSubNameError(true);
                    }
                  }}
                  onChange={(e) => {
                    setSubName(e.target.value);
                    if (!e.target.value) {
                      setSubNameError(true);
                    } else if (e.target.value.length > 150) {
                      setSubNameError(true);
                      setSubNameInvalid(true);
                    } else {
                      setSubNameError(false);
                      setSubNameInvalid(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} sx={styles.label}>
                *Subproject Type
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  fullWidth
                  disabled={
                    localStorage.getItem("viewSubPro") === "true" ? true : false
                  }
                >
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    value={subType}
                    error={subTypeError}
                    onBlur={(e) => {
                      if (!e.target.value) setSubTypeError(true);
                    }}
                    onChange={(e) => {
                      setSubTypeError(false);
                      setSubType(e.target.value);
                      if (
                        e.target.value !==
                        subTypes?.find((x) => x.Name === "Others")?.Id
                      )
                        setSubTypeOther("");
                    }}
                  >
                    {subTypes?.map((st, index) => (
                      <MenuItem
                        value={st.Id}
                        key={index}
                        style={{ display: "block" }}
                      >
                        {st.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <div style={styles.error}>
                    {subTypeError ? "Choose a type" : ""}
                  </div>
                </FormControl>
                {subType === subTypes?.find((x) => x.Name === "Others")?.Id ? (
                  <>
                    <Grid item sx={styles.label}>
                      *Other Type
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled={props.viewdisable}
                        variant="standard"
                        fullWidth
                        value={subTypeOther}
                        size="small"
                        error={subTypeError}
                        helperText={subTypeError ? "Enter a type" : ""}
                        onBlur={(e) => {
                          if (!e.target.value) setSubTypeError(true);
                        }}
                        onChange={(e) => {
                          setSubTypeOther(e.target.value);
                          setSubTypeError(false);
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item xs={0.3}></Grid>
            <Grid item xs={4} container>
              <Grid item md={3} xs={5} sx={styles.label}>
                Subproject Picture
              </Grid>
              <Grid item md={2} xs={2} paddingLeft={1}>
                <button
                  disabled={
                    localStorage.getItem("viewSubPro") === "true" ? true : false
                  }
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.color = "#fff";
                    e.target.style.backgroundColor =
                      AccountType === "1" ? "#e1e319" : " #2da4d0";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.color =
                      AccountType === "1" ? "#e1e319" : "#2da4d0";
                    e.target.style.backgroundColor = "#fff";
                  }}
                  onClick={() => setOpenImage(true)}
                  className={
                    AccountType === "1"
                      ? classes.addButtonMC
                      : classes.addButtonSC
                  }
                >
                  Add +
                </button>
              </Grid>
              <Grid item xs={12}>
                {subPictureURL ? (
                  <Grid item xs={10} width="200px" height="200px" marginTop={3}>
                    <img
                      src={subPictureURL}
                      alt="SubPicImg"
                      style={{ width: "200px", height: "200px" }}
                    ></img>
                  </Grid>
                ) : (
                  <TextField disabled fullWidth variant="standard" />
                )}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} sx={styles.label}>
                Subproject Code
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={
                    localStorage.getItem("viewSubPro") === "true" ? true : false
                  }
                  fullWidth
                  variant="standard"
                  value={subCode}
                  error={subCodeInvalid}
                  helperText={
                    subCodeInvalid ? "Code must be less than 30 characters" : ""
                  }
                  onChange={(e) => {
                    if (e.target.value.length > 30) setSubCodeInvalid(true);
                    else {
                      setSubCodeInvalid(false);
                      setSubCode(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={0.3}></Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} sx={styles.label}>
                Subproject Abbreviation
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={
                    localStorage.getItem("viewSubPro") === "true" ? true : false
                  }
                  fullWidth
                  variant="standard"
                  multiline
                  value={subAbbreviation}
                  error={subAbbreviationInvalid}
                  helperText={
                    subAbbreviationInvalid
                      ? "Abbreviation must be less than 30 characters"
                      : ""
                  }
                  onChange={(e) => {
                    if (e.target.value.length > 30)
                      setSubAbbreviationInvalid(true);
                    else {
                      setSubAbbreviationInvalid(false);
                      setSubAbbreviation(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} sx={styles.label}>
                Subproject Description
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={
                    localStorage.getItem("viewSubPro") === "true" ? true : false
                  }
                  fullWidth
                  variant="standard"
                  multiline
                  value={subDescription}
                  error={subDescriptionInvalid}
                  helperText={
                    subDescriptionInvalid
                      ? "Description must be less than 255 characters"
                      : ""
                  }
                  onChange={(e) => {
                    if (e.target.value.length > 255)
                      setSubDescriptionInvalid(true);
                    else {
                      setSubDescriptionInvalid(false);
                      setSubDescription(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={0.3}></Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} sx={styles.label}>
                <button
                  style={{
                    padding: "6px 11px 6px 12px",
                    borderRadius: "5px",
                    width: discSaved ? "170px" : "190px",
                    fontFamily: "Muli",
                    fontSize: 12,
                    fontWeight: "bold",
                    lineHeight: 1.5,
                    textAlign: "left",
                    border:
                      AccountType === "1"
                        ? "solid 1px #e1e319"
                        : "solid 1px #2da4d0",
                    backgroundColor: "#fff",
                    color: "#101650",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.backgroundColor =
                      AccountType === "1" ? "#e1e319" : "#2da4d0";
                    e.target.style.color = "#fff";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.border =
                      AccountType === "1"
                        ? "solid 1px #e1e319"
                        : "solid 1px #2da4d0";
                    e.target.style.backgroundColor = "#fff";
                    e.target.style.color = "#101650";
                  }}
                  onClick={(e) => {
                    setOpenDT(true);
                  }}
                >
                  {discSaved ||
                    (AccountType === "2" &&
                      (localStorage.getItem("planId") === "Pro"
                        || localStorage.getItem("planId") === "Core"
                        || (localStorage.getItem("isSubscribed") !== "true" && localStorage.getItem("isSubscribedManu") === "true")
                      )
                    )
                    ? "View "
                    : "*Identify "}
                  Discipline and Trade
                </button>
              </Grid>
            </Grid>
            <Grid item xs={6.5} md={6.3} container></Grid>
            <Grid item xs={10} md={4} container>
              <Grid item xs={12} sx={styles.label} container>
                <Grid
                  item
                  xs={5}
                  sx={{
                    color:
                      ((subProjectId && localStorage.getItem("planId")?.includes("Pro") &&
                        localStorage.getItem("isGuest") !== "true" &&
                        AccountType === "2")
                        ||
                        (subProjectId && localStorage.getItem("planId")?.includes("Pro") &&
                          localStorage.getItem("isGuest") !== "true" &&
                          localStorage.getItem("PhaseCreator") === "true")
                        ||
                        (!UserPermissions?.find(
                          x => x.Permissions.find(y => y.Name === "Sub Project") &&
                            x.Permissions.find(y => y.Actions.find(z => z.Name === "Assign Contractor Responsibility"))
                        ))) && (localStorage.getItem("viewSubPro") !== "true")
                        ? "#101650"
                        : "lightgrey",
                  }}
                >
                  * Subproject Responsibility
                  {
                    (localStorage.getItem("planId") === "Core" && localStorage.getItem("subCreator") === "true" && subProjectId) ? <><br /> "Upgrade your subscription plan to Pro or Pro+ to unlock this feature"</>
                      :
                      (!localStorage.getItem("planId") && localStorage.getItem("subCreator") === "true" && subProjectId) ? <><br /> "Subscribe to engineering Pro or Pro+ to unlock this feature"</>
                        : ""
                  }
                </Grid>
              </Grid>
              {((subProjectId && localStorage.getItem("planId")?.includes("Pro") &&
                localStorage.getItem("isGuest") !== "true" &&
                AccountType === "2")
                ||
                (subProjectId && localStorage.getItem("planId")?.includes("Pro") &&
                  localStorage.getItem("isGuest") !== "true" &&
                  localStorage.getItem("PhaseCreator") === "true")
                ||
                (!UserPermissions?.find(
                  x => x.Permissions.find(y => y.Name === "Sub Project") &&
                    x.Permissions.find(y => y.Actions.find(z => z.Name === "Assign Contractor Responsibility"))
                )))
                &&
                localStorage.getItem("viewSubPro") !== "true" ? (
                <Grid item container xs={12} paddingTop="1%">
                  <Grid
                    item
                    xs={3.5}
                    md={6.5}
                    lg={5}
                    xl={4}
                    onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                  >
                    <CustomSelect
                      disabled={
                        localStorage.getItem("viewSubPro") === "true" &&
                          localStorage.getItem("PhaseCreator") !== "true"
                          ? true
                          : false
                      }
                      MenuProps={{ disableScrollLock: true }}
                      value={subResponsibility}
                      onChange={(option) => {
                        setSubResponsibility(option);
                      }}
                    >
                      <StyledOption
                        value={1}
                        onClick={() => {
                          setContractDetails(null);
                          setInvitedCompany(null);
                          setSubContracts([]);
                          setSubCompany(null);
                          setSubContractors([]);
                        }}
                      >
                        InHouse
                      </StyledOption>
                      <StyledOption value={2}>
                        {AccountType === "1" ? "Subcontracted" : "Outsource"}
                      </StyledOption>
                    </CustomSelect>
                  </Grid>
                  {subResponsibility === 2 ? (
                    <Grid item xs={4} md={3} paddingLeft={1} paddingTop={0.3}>
                      <button
                        disabled={
                          localStorage.getItem("viewSubPro") === "true" &&
                            localStorage.getItem("PhaseCreator") !== "true"
                            ? true
                            : false
                        }
                        className={classes.SubResponsibilityButtons}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.backgroundColor = "#0674b9";
                          e.target.style.color = "#fff";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#fff";
                          e.target.style.color = "#0674b9";
                        }}
                        onClick={() => setOpenSc(true)}
                      >
                        New SC +
                      </button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : (
                <Grid item container xs={12} paddingTop="1%"></Grid>
              )}
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={4} xs={10} sx={styles.label} container spacing={0.5}>
              <Grid item xs={6} container>
                <Grid item xs={12} sx={styles.label}>
                  *Start Date
                </Grid>
                <Grid item xs={12} paddingTop="5%">
                  <TextField
                    fullWidth
                    disabled={
                      (localStorage.getItem("viewSubPro") === "true" || subMilestoned)
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{ className: classes.fieldDesign }}
                    size="small"
                    label={""}
                    type={"date"}
                    value={subStartDate}
                    variant="outlined"
                    error={subStartDateError}
                    // helperText={plannedStartDateErrorMessage}
                    // onBlur={(e) => {
                    //   if (!e.target.value) {
                    //     setPhsError(true);
                    //   }
                    // }}
                    onChange={(e) => {
                      setSubStartDate(e.target.value);
                      if (e.target.value.length > 10) setSubStartDateError(true);
                      else setSubStartDateError(false);

                    }}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={
                        (localStorage.getItem("viewSubPro") === "true" || subMilestoned)
                          ? true
                          : false
                      }
                      openTo="day"
                      views={["year", "month", "day"]}
                      label="dd/mm/yyyy"
                      // disableMaskedInput
                      inputFormat="dd/MM/yyyy"
                      value={subStartDate}
                      onChange={(newValue) => {
                        var date = new Date(newValue);
                        if (newValue === "") {
                          setSubStartDateError(true);
                        } else {
                          setSubStartDateError(false);
                        }
                        setSubStartDate(newValue.toLocaleDateString());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="MM/DD/YYYY"
                          value={subStartDate}
                          onChange={(e) => { handleInputChange(e, "start") }}
                          size="small"
                          onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                              e.preventDefault(); // Prevents the default behavior of the Backspace key
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={
                        (localStorage.getItem("viewSubPro") === "true" || subMilestoned)
                          ? true
                          : false
                      }
                      openTo="day"
                      views={["year", "month", "day"]}
                      label="dd/mm/yyyy"
                      disableMaskedInput
                      inputFormat="dd/MM/yyyy"
                      value={subStartDate}
                      onChange={(newValue) => {
                        var date = new Date(newValue);
                        if (newValue === "") {
                          setSubStartDateError(true);
                        } else {
                          setSubStartDateError(false);
                        }
                        setSubStartDate(newValue.toLocaleDateString());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "15px",
                            },
                          }}
                          error={subStartDateError}
                          onBlur={() => {
                            if (!subStartDate) setSubStartDateError(true);
                          }}
                          helperText={
                            subStartDateError ? "Enter a date" : <></>
                          }
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                <Grid item xs={12} sx={styles.label}>
                  *End Date
                </Grid>
                <Grid item xs={12} paddingTop="5%">
                <TextField
                    fullWidth
                    disabled={
                      (localStorage.getItem("viewSubPro") === "true" || subMilestoned)
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{ className: classes.fieldDesign }}
                    size="small"
                    label={""}
                    type={"date"}
                    value={subEndDate}
                    variant="outlined"
                    error={subEndDateError}
                    // helperText={plannedStartDateErrorMessage}
                    // onBlur={(e) => {
                    //   if (!e.target.value) {
                    //     setPhsError(true);
                    //   }
                    // }}
                    onChange={(e) => {
                      setSubEndDate(e.target.value);
                      if (e.target.value.length > 10) setSubEndDateError(true);
                      else setSubEndDateError(false);

                    }}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={
                        (localStorage.getItem("viewSubPro") === "true" || subMilestoned)
                          ? true
                          : false
                      }
                      openTo="day"
                      views={["year", "month", "day"]}
                      label="dd/mm/yyyy"
                      minDate={new Date(subStartDate)}
                      // disableMaskedInput
                      inputFormat="dd/MM/yyyy"
                      value={subEndDate}
                      onChange={(newValue) => {
                        var date = new Date(newValue);
                        if (newValue === "") {
                          setSubEndDateError(true);
                        } else {
                          setSubEndDateError(false);
                        }
                        setSubEndDate(newValue.toLocaleDateString());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          placeholder="MM/DD/YYYY"
                          value={subEndDate}
                          size="small"
                          onChange={(e) => { handleInputChange(e, "end") }}
                          onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                              e.preventDefault(); // Prevents the default behavior of the Backspace key
                            }
                          }}
                        />
                        // <TextField
                        //   {...params}
                        //   fullWidth
                        //   InputLabelProps={{
                        //     style: {
                        //       fontFamily: "Muli",
                        //       fontSize: "15px",
                        //     },
                        //   }}
                        //   error={subEndDateError}
                        //   onBlur={() => {
                        //     if (!subEndDate) setSubEndDateError(true);
                        //   }}
                        //   helperText={subEndDateError ? "Enter a date" : <></>}
                        //   size="small"
                        // />
                      )}
                    />
                  </LocalizationProvider> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={9.75} direction={"row-reverse"} gap={5}>
              <Button
                disabled={
                  localStorage.getItem("viewSubPro") === "true" &&
                    localStorage.getItem("PhaseCreator") !== "true"
                    ? true
                    : empty || loading
                }
                style={{
                  borderRadius: 5,
                  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                  backgroundColor: empty || localStorage.getItem("viewSubPro") === "true" ? "lightgrey" : "#0b5cab",
                  opacity: loading ? 0.6 : 1,
                  fontFamily: "Muli",
                  fontSize: 13,
                  fontWeight: "bold",
                  lineHeight: 1.46,
                  textAlign: "left",
                  color: "#fff",
                  textTransform: "none",
                }}
                onClick={() => {
                  setTimeout(() => {
                    save();
                  }, 1000);
                  setLoading(true);
                }}
              >
                {successMsg === "subUpdated" ? "Successfully Saved" : loading ? "Saving..." : "Save"}
              </Button>
              <button
                disabled={
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Team Leader")) ||
                    !subProjectId
                    ? true
                    : false
                }
                style={{
                  width: 150,
                  height: 30,
                  color:
                    subProjectId &&
                      localStorage.getItem("viewSubPro") !== "true"
                      ? AccountType === "1"
                        ? "#e1e319"
                        : "#2da4d0"
                      : "lightgrey",
                  backgroundColor: "#fff",
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.color = "#fff";
                  e.target.style.backgroundColor =
                    AccountType === "1" ? "#e1e319" : "#2da4d0";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color =
                    AccountType === "1" ? "#e1e319" : "#2da4d0";
                  e.target.style.backgroundColor = "#fff";
                }}
                className={
                  subProjectId && localStorage.getItem("viewSubPro") !== "true"
                    ? classes.addButtonEmpty
                    : AccountType === "1"
                      ? classes.addButtonMC
                      : classes.addButtonSC
                }
                onClick={(e) => {
                  e.target.style.cursor = "unset";
                  e.target.style.color =
                    AccountType === "1" ? "#e1e319" : "#2da4d0";
                  e.target.style.backgroundColor = "#fff";
                  setSubProjectParentId(subProjectId);
                  setSubProjectParentName(subName);
                  localStorage.removeItem("subId");
                  localStorage.removeItem("viewSubPro");
                  setSubProjectId(null);
                  setTimeout(() => {
                    setSubMileStoned(false);
                    setSubName("");
                    setSubType("");
                    setSubTypeOther("");
                    setSubPicture(null);
                    setSubPictureURL(null);
                    setSubCode("");
                    setSubAbbreviation("");
                    setSubDescription("");
                    setSubResponsibility(1);
                    setSubStartDate(null);
                    setSubEndDate(null);
                    setSubContractors([]);
                    setSubCompany(null);
                    setContractDetails(null);
                    setSubContracts([]);
                    setInvitedCompany(null);
                    setShowContract(false)
                  }, 1000);
                }}
              >
                Add sub-subproject +
              </button>
            </Grid>
            <Grid item md={0.3}></Grid>
            {subResponsibility === 2 ? (
              <Grid item xs={12} container>
                <Grid item xs={3} paddingLeft={12}>
                  <Select
                    disabled={
                      localStorage.getItem("viewSubPro") === "true" &&
                        localStorage.getItem("PhaseCreator") !== "true"
                        ? true
                        : false
                    }
                    MenuProps={{ disableScrollLock: true }}
                    label="SubContractor"
                    value={subCompany}
                    error={subCompanyError}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setSubCompanyError(true);
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      setSubCompany(e.target.value);
                      let arr = subContractors;
                      arr.push(e.target.value);
                      setSubContractors(arr);
                      arr = null;
                      setSubCompanyError(false);
                      setContractDetails(null);
                      setSubContracts([]);
                    }}
                    fullWidth
                    variant="standard"
                  >
                    {subCompanies?.map((option, index) => (
                      <MenuItem
                        value={option}
                        key={index}
                        style={{ display: "block" }}
                      >
                        {option?.InvitedCompanyName}
                      </MenuItem>
                    ))}
                  </Select>
                  <div style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                    {subCompanyError ? "Select a Sub Contractor" : ""}
                  </div>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {subContractors?.length > 0 ? (
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 13,
                  lineHeight: 1.46,
                  textAlign: "left",
                  color: "#2da4d0",
                }}
              >
                Please make sure you save each contract and subcontractor before adding another or removing an old one
              </Grid>
            ) : (
              ""
            )}
            <Grid
              item
              xs={12}
              container
              marginLeft={{ xs: "6%", md: "7%" }}
              rowSpacing={4}
            >
              {subContractors?.map((sc, index) => (
                <>
                  <Grid
                    item
                    xs={10}
                    md={10}
                    lg={5.3}
                    key={sc?.InvitedCompanyName}
                    container
                  >
                    <Grid
                      item
                      xs={0.5}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() => {
                        if (
                          localStorage.getItem("viewSubPro") === "true" &&
                          localStorage.getItem("PhaseCreator") !== "true"
                        )
                          console.log("do nothing");
                        else {
                          deleteItem(sc);
                          forceUpdate();
                        }
                      }}
                      paddingTop="1%"
                    >
                      <BlueTip title={"Remove"} placement="left">
                        <img src={remove} alt="remove" />
                      </BlueTip>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        value={sc?.InvitedCompanyName}
                        disabled
                        variant="standard"
                        id="outlined-name"
                        size="small"
                        InputProps={{ style: { height: "30px" } }}
                      />
                    </Grid>
                    <Grid item xs={5} container gap={1}>
                      <Grid item xs={8}>
                        <button
                          className={classes.SubResponsibilityButtons}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                            e.target.style.backgroundColor = "#0674b9";
                            e.target.style.color = "#fff";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#fff";
                            e.target.style.color = "#0674b9";
                          }}
                          onClick={() => {
                            setInvitedCompany(sc);
                            setOpenSc(true);
                          }}
                        >
                          View SC
                        </button>
                      </Grid>
                      <Grid item xs={12}>
                        <button
                          style={{
                            fontFamily: "Muli",
                            fontSize: 12,
                            height: 23,
                            width: 150,
                            boxShadow: "0 3px 6px 0 #0674b9",
                            backgroundColor: "#fff",
                            borderRadius: 5,
                            border: 0,
                            color: "#0674b9",
                          }}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                            e.target.style.backgroundColor = "#0674b9";
                            e.target.style.color = "#fff";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#fff";
                            e.target.style.color = "#0674b9";
                          }}
                          onClick={() => {
                            setInvitedCompany(sc);
                            if (sc?.Contracts?.length > 0) {
                              setContractDetails(sc?.Contracts[0]);
                              setSubContracts(sc?.Contracts);
                              setContractorName(sc?.InvitedCompanyName);
                            } else {
                              setContractDetails(null);
                              setSubContracts([]);
                              setContractorName(sc?.InvitedCompanyName);
                            }
                            setShowContract(!showContract);
                          }}
                        >
                          {contractDetails ? "View" : "*Add"} Contract Details
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index % 2 === 0 ? <Grid item xs={1.3}></Grid> : <></>}
                </>
              ))}
            </Grid>
            {subResponsibility === 2 ? (
              <Grid container item xs={9.75} direction={"row-reverse"}>
                <Button
                  disabled={
                    empty1 || localStorage.getItem("viewSubPro") === "true" || loadingSc
                      ? true
                      : empty
                  }
                  style={{
                    borderRadius: 5,
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: empty1 || localStorage.getItem("viewSubPro") === "true" || loadingSc ?
                      "lightgrey"
                      : empty ?
                        "lightgrey"
                        : "#fff",
                    fontFamily: "Muli",
                    fontSize: 13,
                    fontWeight: "bold",
                    lineHeight: 1.46,
                    textAlign: "left",
                    color: empty1 || localStorage.getItem("viewSubPro") === "true" || loadingSc ?
                      "#fff"
                      : empty ?
                        "#fff" : "#0b5cab",
                    textTransform: "none",
                    opacity: loadingSc ? 0.7 : 1,
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "#fff";
                    if (empty1 || localStorage.getItem("viewSubPro") === "true") e.target.style.backgroundColor = "lightgrey";
                    else e.target.style.backgroundColor = "#0b5cab";
                  }}
                  onMouseLeave={(e) => {
                    if (empty1 || localStorage.getItem("viewSubPro") === "true") e.target.style.color = "lightgrey";
                    else e.target.style.color = "#0b5cab";
                    e.target.style.backgroundColor = "#fff";
                  }}
                  onClick={() => saveContract()}
                >
                  {successMsg === "contractsUpdated" ? "Successfully Saved" : loadingSc ? "Saving . . . " : "Save SubContractors"}
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            {showContract ? (
              <Contract
                showContract={showContract}
                setShowContract={setShowContract}
                viewdisable={props.viewdisable}
                subProjectName={subName}
                contractorName={
                  subCompany?.InvitedCompanyName
                    ? subCompany?.InvitedCompanyName
                    : invitedCompany?.InvitedCompanyName
                      ? invitedCompany?.InvitedCompanyName
                      : contractorName
                }
                projectId={projectId}
                disciplines={selectedDisc}
                setDisciplines={setSelectedDisc}
                trades={selectedTrades}
                contractStatuses={contractStatuses}
                accountTypes={accountTypes}
                contractTypes={contractTypes}
                measurements={measurements}
                setFactoryNumbers={setFactoryNumbers}
                factoryNumbers={factoryNumbers}
                setBarStandards={setBarStandards}
                barStandards={barStandards}
                setBarGrades={setBarGrades}
                barGrades={barGrades}
                setBarCoatings={setBarCoatings}
                barCoatings={barCoatings}
                contractSubTypes={contractSubTypes}
                contractBillingTypes={contractBillingTypes}
                defaultSheetSizes={defaultSheetSizes}
                contractDetails={contractDetails}
                setContractDetails={setContractDetails}
                subContracts={subContracts}
                setSubContracts={setSubContracts}
                setSubContSaved={setSubContSaved}
                subContSaved={subContSaved}
              />
            ) : (
              <></>
            )}
            <PictureModal
              openModal={openImage}
              setOpenModal={setOpenImage}
              subPicture={subPicture}
              setSubPicture={setSubPicture}
              subPictureURL={subPictureURL}
              setSubPictureURL={setSubPictureURL}
              projectId={projectId}
              phaseId={phaseId}
            />
            <ScModal
              openModal={openSc}
              setOpenModal={setOpenSc}
              setSubCompany={setSubCompany}
              subCompany={subCompany}
              setSubCompanyError={setSubCompanyError}
              projectName={projectName}
              subContractors={subContractors}
              setSubContractors={setSubContractors}
              setContractDetails={setContractDetails}
              setSubContracts={setSubContracts}
              invitedCompany={invitedCompany}
              setInvitedCompany={setInvitedCompany}
            />
            <DisciplineModal
              subProjectParentId={subProjectParentId}
              openModal={openDT}
              setOpenModal={setOpenDT}
              disciplines={disciplines}
              setDisciplines={setDisciplines}
              trades={trades}
              setTrades={setTrades}
              setDiscSaved={setDiscSaved}
              setDiscAssigned={setDiscAssigned}
              discAssigned={discAssigned}
            />
          </Grid>
        </Grid>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container sx={modalStyle} justifyContent="center" alignItems="center" gap={10}>
            <Grid item sx={{
              fontFamily: "Muli",
              fontSize: 21,
              lineHeight: 1.48,
              textAlign: "center",
              color: "#0674b9",
            }}>
              Error: {errorMsg}
            </Grid>

            <Grid item container justifyContent="center" direction="row" gap={3}>
              <Grid item onClick={() => {
                handleCloseModal();
                navigate(navigate(`/saas/projects/dashboard?id=${projectId}`))
              }}
                className={classing["back-button"]}
                sx={{ backgroundColor: '#0674b9', color: '#fff', fontSize: 20 }}
              >
                Ok
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </div>
  );
}
