import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../Assets/Styles/MachineOptimization.module.css";
import * as productionServices from "../../../Services/production-planning-services";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
import ErrorModal from "../../../SharedComponents/ErrorModal";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getMachinesOptimizationList =
  productionServices.getMachinesOptimizationList;
const validateProductionRun = productionServices.validateProductionRun;
const deleteCombination = productionServices.deleteCombination;
const Machines = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [machineNotOptimized, setMachineNotOptimized] = useState(false);
  const [machines, setMachines] = useState([]);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const COMBINATION_ID = query?.get("id");
  const IS_VALIDATED = query?.get("validated");
  const [placingLoading, setPlacingLoading] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    if (COMBINATION_ID) {
      getMachinesOptimizationList(COMBINATION_ID).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setMachines(x);
        }
        setLoadingData(false);

      });
    }
  }, [COMBINATION_ID]);
  const [disabledReplan, setDisabledReplan] = useState(false);
  const [disabledPlace, setDisabledPlace] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Production Planning",
        "Place order",
        setDisabledPlace
      );
      CheckIfPermitted(
        UserPermissions,
        "Production Planning",
        "Replan SO combination",
        setDisabledReplan
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: props.adminPage ? null : `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid item xs={12} container rowSpacing={3} padding="2% 0 0 8%">
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            xs={4}
            sm={3}
            lg={2.5}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Production Runs on Machines
          </Grid>
          <Grid
            item
            xs={0.5}
            sm={1.5}
            md={3}
            lg={5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            container
            direction={"row"}
            xs={7.5}
            sm={7.5}
            md={5.5}
            lg={4.5}
            gap={1.5}
          >
            {/* {localStorage.getItem("planIdManu")?.includes("Pro +") ? (
              <Grid item paddingLeft={2}>
                <button
                  className={classes["Path-3900"]}
                  onMouseOver={(e) => {
                    // if(IS_VALIDATED === "true") e.target.style.cursor = "unset"
                    // else e.target.style.cursor = "pointer";
                    e.target.style.cursor = "pointer"
                  }}
                  // disabled={IS_VALIDATED === "true" ? true : false}
                  onClick={() => {
                    navigate(
                      `/saas/productionPlanning/Assigning?id=${COMBINATION_ID}`
                    );
                  }}
                >
                  View Estimated Time
                </button>
              </Grid>
            ) : (
              <></>
            )} */}
            {!disabledPlace ? (
              <Grid item paddingLeft={2}>
                <button
                  className={classes["Path-3900"]}
                  // onMouseOver={(e) => {
                  //   if (IS_VALIDATED === "true") e.target.style.cursor = "unset"
                  //   else e.target.style.cursor = "pointer";
                  // }}
                  disabled={IS_VALIDATED === "true" || placingLoading ? true : false}
                  onClick={() => {
                    let emptyMachine = false;
                    machines.map((m) => {
                      if (
                        m.ProductionOrderCode === null ||
                        m.ProductionOrderCode === ""
                      ) {
                        emptyMachine = true;
                        setMachineNotOptimized(true);
                      }
                    });
                    if (!emptyMachine) {
                      setPlacingLoading(true);
                      validateProductionRun(COMBINATION_ID).then((x) => {
                        setPlacingLoading(false);
                        navigate("/saas/productionPlanning/Calendar");
                      });
                    }

                  }}
                >
                  {placingLoading ? "Placing ..." : "Place Orders"}
                </button>
              </Grid>
            ) : (
              <></>
            )}

            {!disabledReplan ? (
              <Grid
                item
                className={classes["Replan"]}
                onMouseOver={(e) => {
                  if (IS_VALIDATED !== "true") {
                    e.target.style.cursor = "pointer";
                  }
                }}
                onClick={(e) => {
                  if (IS_VALIDATED !== "true") {
                    setLoadingData(true)
                    deleteCombination(COMBINATION_ID).then((x) => {
                      setLoadingData(false);
                      if (x.status || x.statusCode) {
                        if (x.status) setBEerror(x.error)
                        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                        setOpenErrorModal(true);
                      } else {
                        navigate("/saas/productionPlanning/Calendar");

                      }
                    });
                  }
                }}
                paddingTop={1.3}
              >
                Replan
              </Grid>
            ) : (
              <></>
            )}

            <Grid item paddingLeft={4} paddingTop={1}>
              <button
                className={classes["Go-Back"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>
            </Grid>
          </Grid>
        </Grid>
        {machineNotOptimized ? (
          <Grid
            paddingTop={2}
            paddingBottom={2}
            item
            fontFamily={"Muli"}
            fontSize={20}
            fontWeight={"bold"}
            color={"#ea001e"}
          >
            Save machine optimizations before placing orders.
          </Grid>
        ) : (
          ""
        )}
        <Grid item overflow={"auto"}>
          <TableContainer sx={{ width: "100%" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0px 0.5px",
                border: 0,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableCellHeadone}></TableCell>
                  <TableCell sx={styles.tableCellhead}>Machine Type</TableCell>
                  <TableCell sx={styles.tableCellhead}>
                    Production Order Code
                  </TableCell>
                  <TableCell sx={styles.tableCellhead}>
                    TW of Finished Goods (KG)
                  </TableCell>
                  <TableCell sx={styles.tableCellhead}>
                    TW of material used (KG)
                  </TableCell>
                  {/* <TableCell sx={styles.tableCellhead}> % of scrap</TableCell>
                  <TableCell sx={styles.tableCellhead}>% of remnant </TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {machines?.map((machine) => (
                  <TableRow key={machine.Id}>
                    <TableCell
                      sx={styles.tableCellBodyone}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#2da4d0";
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor =
                          "rgba(95, 186, 71, 0.3)";
                        e.target.style.color = "#101650";
                      }}
                      onClick={() => {
                        navigate(
                          `/saas/productionPlanning/machinesOptimization/system?id=${machine.Id}&name=${machine.Name}&combId=${COMBINATION_ID}&Order=${machine.ProductionOrderCode}&category=${machine.Category}&IsValidated=${IS_VALIDATED}`
                        );
                      }}
                    >
                      {machine.Name}
                    </TableCell>
                    <TableCell sx={styles.tablecellBody}>
                      {machine?.Category}
                    </TableCell>
                    <TableCell sx={styles.tablecellBody}>
                      {machine.ProductionOrderCode}
                    </TableCell>
                    <TableCell sx={styles.tablecellBody}>
                      {machine.FinishedGoodTW}
                    </TableCell>
                    <TableCell sx={styles.tablecellBody}>
                      {machine.MaterialUsedTW}
                    </TableCell>
                    {/* <TableCell sx={styles.tablecellBody}>
                      {machine.ScrapPercentage} %
                    </TableCell>
                    <TableCell sx={styles.tablecellBody}>
                      {machine.RemnantPercentage} %
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </div>
  );
};
const styles = {
  tableCellHeadone: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    fontWeight: "bold",
    width: "35px",
  },

  tableCellBodyone: {
    height: "15px",
    backgroundColor: "rgba(95, 186, 71, 0.3)",
    fontFamily: "Muli",
    fontSize: 17,
    color: "#101650",
    fontWeight: "bold",
    textAlign: "center",
    width: "35px",
    border: "solid 1px #2da4d0",
    borderRadius: 1,
  },
  tableCellhead: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: 1,
    width: 125,
  },

  tablecellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#444",
    textAlign: "center",
    lineHeight: 1,
    border: "solid 1px rgba(206, 210, 225, 0.97)",
    borderLeft: 0,
    borderRight: 0,
  },
};
export default Machines;
