import React, { useState, useEffect } from "react";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import { Grid, TextField } from "@mui/material";
import classes from "../../../Assets/Styles/Calender.module.css";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import * as productionPlanningServices from "../../../Services/production-planning-services";
import DatePicker from "./DatePicker";
import CalenderList from "./CalenderList";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import * as salesOrderConst from "../../../../salesOrderConst";

const getCalenderLists = salesOrderServices.getCalender;
const getFactoriesCodes = productionPlanningServices.getFactoriesCodes;
const createCombinationSales = productionPlanningServices.createCombinationSales;


function CalenderView(props) {
  const [dateSelected, setDateSelected] = useState(new Date());
  const [salesOrderCalendar, setSalesOrderCalendar] = useState([]);
  const [loading, setLoading] = useState(false);

  const [factoryFilter, setFactoryFilter] = useState();
  const [factoriesList, setFactoriesList] = useState([
  ]);
  const [statusesList,setStatusesList] = useState([]);
  const [statusFilter, setStatusFilter] = useState();

  const [selectedSalesOrders, setSelectedSalesOrders] = useState([]);
  const [planning, setplanning] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const [BEerror, setBEerror] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const SalesOrdersCombinationData = {
    Date: selectedDate,
    SalesOrders: selectedSalesOrders,
  };


  const createCombination = () => {
    setplanning(true);
    createCombinationSales(SalesOrdersCombinationData).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        navigate(`/saas/productionPlanning/Assigning?id=${x}`);

      }
      setplanning(false);
    });
  };


  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const navigate = useNavigate();

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getCalenderLists(new Date(dateSelected).toISOString().slice(0, 10)).then(
      (x) => {
        setFactoriesList(x.Factories);
        setStatusesList(x.Statuses);
        setFactoryFilter(x.Factories[0].Id)

      }
    );


    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let apiTest = true;
    setLoading(true);
    getCalenderLists(new Date(dateSelected).toISOString().slice(0, 10), factoryFilter, statusFilter).then(
      (x) => {
        if (apiTest) {
          let tmp = [];
          x.SalesOrders.map((s) => {
            tmp.push(s);
            if (s.Capacity === 0) {
              s.Capacity = s.FactoryDailyCapacity
              forceUpdate();
            }
          })


          setSalesOrderCalendar(tmp);

          setLoading(false);
        }
      }
    );


    return () => {
      apiTest = false;
    };
  }, [dateSelected, factoryFilter, statusFilter]);

  const blue = {
    100: "#DAECFF",
    200: "#99CCF3",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        height: 35px;
        width: 8rem;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
        border-radius: 0.75em;
        margin-top: 0.7em;
        padding: 10px;
        text-align: left;
        line-height: 1.5;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      
        &:hover {
          background: ${theme.palette.mode === "dark" ? "" : grey[100]};
          border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]
      };
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
      };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
        }
      
        &::after {
          content: '▾';
          float: right;
        }
        cursor: pointer;
        `
  );

  const StyledListbox = styled("ul")(
    ({ theme }) => `
        font-family: "Muli";
        font-size: 0.6rem;
        box-sizing: border-box;
        padding: 5px;
        width: 8rem;
        height:130px;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
        border-radius: 0.75em;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        overflow: scroll;
        outline: 0px;
        
        `
  );

  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: pointer;
        margin-top: 2px;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
          color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected
      } {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
          color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
  );

  const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
  `;

  const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const components = {
      Root: StyledButton,
      Listbox: StyledListbox,
      Popper: StyledPopper,
      ...props.components,
    };

    return <SelectUnstyled {...props} ref={ref} components={components} />;
  });
  return (
    <div
      style={{
        // minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container justifyContent="center" paddingTop={3} spacing={1}>
        <Grid item xs={7} className={classes["title"]}>
          Sales Order Calendar
        </Grid>
        <Grid item container direction="column" xs={4.5} alignContent="flex-end">
          <Grid item xs={1} alignSelf="flex-end">
            {" "}
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>

          <Grid item container direction='row' justifyContent='flex-end' alignItems='center' columnGap={1}>
            {statusFilter ?
              <Grid item style={{ color: 'grey', fontFamily: 'Muli', fontSize: '14px', marginTop: '5px', cursor: 'pointer' }}
                onClick={() => setStatusFilter(null)}>Reset</Grid>
              :
              <></>
            }

            <Grid item>
              <span style={{ color: '#101650', fontFamily: 'Muli', fontSize: '14px' }}>Status :  </span>
              <CustomSelect
                value={statusFilter}
                onChange={(option) => {
                  setStatusFilter(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Status
                </StyledOption>
                {statusesList?.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>

            <Grid item>
              <span style={{ color: '#101650', fontFamily: 'Muli', fontSize: '14px' }}>Factory :  </span>
              <CustomSelect
                value={factoryFilter}
                onChange={(option) => {
                  setFactoryFilter(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Factory Name
                </StyledOption>
                {factoriesList?.map((f) => (
                  <StyledOption key={f.Id} value={f.Id}>
                    {f.Code}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center' marginTop={2} columnGap={1}>
            <Grid item>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  className: `${classes.fieldDesign} cursor-pointer`, // Add cursor-pointer here
                }}
                 size="small"
                label={""}
                type={"date"}
                value={selectedDate}
                variant="outlined"

                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
              />
            </Grid>

            <Grid item alignSelf='flex-end' >
              <button
                disabled={
                  selectedSalesOrders.length === 0 || planning || !selectedDate
                    ? true
                    : false
                }
                className={classes["MoveToProduction"]}
                onClick={() => {
                  createCombination();
                }}
              >
                {planning ? "Planning..." : "Continue Planning"}
              </button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="row" rowSpacing={1} marginBottom={3}>
          <Grid item xs={12} md={2}>
            <DatePicker
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
            />
          </Grid>
          <Grid item xs={11.2} md={9.9} marginLeft={1.5}>
            <CalenderList
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
              salesOrderCalendar={salesOrderCalendar}
              loading={loading}
              selectedSalesOrders={selectedSalesOrders}
              setSelectedSalesOrders={setSelectedSalesOrders}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default CalenderView;
