import React from "react";
import { Grid, TextField } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
const ActivityResponsibility = (props) => {
  return (
    <Grid container paddingBottom={5} paddingTop={2} direction={"column"}>
      <Grid item className={classes["Activity-Details"]}>
        Activity Responsibility
      </Grid>

      <Grid item container direction={"row"} marginTop={4}>
        <Grid item md={2.93} xs={12}>
          <Grid item className={classes["field-title"]} xs={12}>
            *Start Time
          </Grid>
          <Grid item xs={11} marginTop={1}>
            <TextField
              fullWidth
              disabled={props.disableAllFields}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{ className: classes.fieldDesign }}
              size="small"
              label={""}
              type={"time"}
              value={props.startTime?.slice(0, 5)}
              variant="outlined"
              error={props.startTimeError}
              helperText={props.startTimeErrorMessage}
              onBlur={(e) => {
                if (!e.target.value) {
                  props.setStartTimeError(true);
                  props.setStartTimeErrorMessage("Enter a start Time");
                }
              }}
              onChange={(e) => {
                props.setStartTime(e.target.value);
                props.setStartTimeError(false);
                props.setStartTimeErrorMessage(null);
              }}
            />
          </Grid>
        </Grid>

        <Grid item md={2.93} xs={12}>
          <Grid item className={classes["field-title-disabled"]} xs={12}>
            End Time
          </Grid>
          <Grid
            item
            className={classes["Rectangle-1464"]}
            xs={11}
            marginTop={1}
            height={"50px"}
          >
            {props.endTime}
          </Grid>
        </Grid>
        <Grid item md={2.93} xs={12}>
          <Grid item className={classes["field-title-disabled"]} xs={12}>
            End Date
          </Grid>
          <Grid
            item
            className={classes["Rectangle-1464"]}
            xs={11}
            marginTop={1}
            height={"50px"}
          >
            {props.endDate
              ? new Date(props.endDate).toLocaleDateString()
              : null}
          </Grid>
        </Grid>
        <Grid item md={2.93} xs={12}>
          <Grid item className={classes["field-title"]} xs={12}>
            *Team member name
          </Grid>
          {props.disableAllFields ?
            <Grid item className={classes["field-title-disabled"]}>  <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >{props.teamMemberName}</Grid></Grid>
            :
            <Grid item xs={11} paddingTop={1}>
              <CustomSelect
                style={{ color: "#707070" }}
                disabled={props.disableAllFields || props.revisedFieldDisabled}
                value={props.teamMember}
                onChange={(option) => {
                  props.setTeamMember(option);
                  props.setTeamMemberError(false);
                }}
                onBlur={(e) => {
                  if (!props.teamMember) props.setTeamMemberError(true);
                }}
              >
                <StyledOption value={0} disabled>
                  Team Member
                </StyledOption>
                {props.teamMembers?.map((s) =>
                  s.UserGroupPermissions === "Engineer" ? (
                    <StyledOption key={s.Id} value={s.Id}>
                      {s.Name}
                    </StyledOption>
                  ) : (
                    ""
                  )
                )}
              </CustomSelect>
            </Grid>
          }

          <Grid item className={classes["errorMessage"]}>
            {props.teamMemberError ? "Select a Team Member" : <></>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 17px;
    box-sizing: border-box;
    width: 100%;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    text-align: left;
    color: #bcbcbc;
    padding:13px;
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default ActivityResponsibility;
