import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/Calender.module.css";

import draftWhite from "../../../Assets/Images/SalesOrder/Statuses/draftWhite.png";
import draftNavy from "../../../Assets/Images/SalesOrder/Statuses/draftNavy.png";

import placedWhite from "../../../Assets/Images/SalesOrder/Statuses/placedWhite.png";
import placedNavy from "../../../Assets/Images/SalesOrder/Statuses/placedNavy.png";

import plannedWhite from "../../../Assets/Images/SalesOrder/Statuses/plannedWhite.png";
import plannedNavy from "../../../Assets/Images/SalesOrder/Statuses/plannedNavy.png";

import productionWhite from "../../../Assets/Images/SalesOrder/Statuses/productionWhite.png";
import productionNavy from "../../../Assets/Images/SalesOrder/Statuses/productionNavy.png";

import loadingWhite from "../../../Assets/Images/SalesOrder/Statuses/loadingWhite.png";
import loadingNavy from "../../../Assets/Images/SalesOrder/Statuses/loadingNavy.png";

import deliveredWhite from "../../../Assets/Images/SalesOrder/Statuses/deliveredWhite.png";
import deliveredNavy from "../../../Assets/Images/SalesOrder/Statuses/deliveredNavy.png";

import CircularProgress from "@mui/material/CircularProgress";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import moment from "moment";

import Backdrop from "@mui/material/Backdrop";

import * as salesOrderConst from "../../../../salesOrderConst";
import { useNavigate } from "react-router-dom";

import { TextField } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';


function CalenderList(props) {
  const [mondayList, setMondayList] = useState([]);
  const [tuesdayList, setTuesdayList] = useState([]);
  const [wednesdayList, setWednesdayList] = useState([]);
  const [thursdayList, setThursdayList] = useState([]);
  const [fridayList, setFridayList] = useState([]);
  const [saturdayList, setSaturdayList] = useState([]);
  const [sundayList, setSundayList] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);



  const navigate = useNavigate();

  let usage = 0;

  useEffect(() => {
    if (props.salesOrderCalendar) {
      props.salesOrderCalendar.map((SalesOrderCalendarRow) => {
        {
          SalesOrderCalendarRow.Day === "Monday" ? (
            setMondayList(SalesOrderCalendarRow.SalesOrders)
          ) : SalesOrderCalendarRow.Day === "Tuesday" ? (
            setTuesdayList(SalesOrderCalendarRow.SalesOrders)
          ) : SalesOrderCalendarRow.Day === "Wednesday" ? (
            setWednesdayList(SalesOrderCalendarRow.SalesOrders)
          ) : SalesOrderCalendarRow.Day === "Thursday" ? (
            setThursdayList(SalesOrderCalendarRow.SalesOrders)
          ) : SalesOrderCalendarRow.Day === "Friday" ? (
            setFridayList(SalesOrderCalendarRow.SalesOrders)
          ) : SalesOrderCalendarRow.Day === "Saturday" ? (
            setSaturdayList(SalesOrderCalendarRow.SalesOrders)
          ) : SalesOrderCalendarRow.Day === "Sunday" ? (
            setSundayList(SalesOrderCalendarRow.SalesOrders)
          ) : (
            <></>
          );
        }
      });
    }
  }, [props.salesOrderCalendar]);

  const [cardSwapped, setCardSwapped] = useState(false);

  return (
    <>
      <Grid item container direction="row" xs={12}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={props.loading}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item xs={6} sm={4} md={2} className={classes["monthyear"]}>
          {new Date(props.dateSelected).toDateString()}
        </Grid>

        <Grid item container direction="row">
          <Grid
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              let d = moment(props.dateSelected).subtract(7, "days");
              d = d.format("llll");
              props.setDateSelected(new Date(d));
            }}
          >
            {" "}
            <ArrowLeftIcon />
          </Grid>
          <Grid
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              let d = moment(props.dateSelected).add(7, "days");
              d = d.format("llll");
              props.setDateSelected(new Date(d));
            }}
          >
            <ArrowRightIcon />
          </Grid>
        </Grid>
        {props.salesOrderCalendar.map((dayList, index) => (
          <>
            <Grid
              item
              container
              direction="column"
              key={dayList.id}
              lg={1.7}
              sm={2.9}
              marginRight={0.15}
            >
              <Grid
                item
                className={classes["Day"]}
                padding={1}
                fontWeight="bold"
                backgroundColor={
                  new Date(props.dateSelected).toISOString().slice(0, 10) ===
                    dayList.Date
                    ? "rgba(6, 116, 185, 0.5)"
                    : ""
                }
              >
                {dayList.Day}
              </Grid>

              <Grid
                item
                backgroundColor="#fff"
                padding={0.5}
                lg={1.7}
                sm={3}
                minHeight="565px"
                maxHeight="565px"
                overflow="auto"
                style={{cursor:'pointer'}}
              >
                <Grid container sx={{
                  position: 'sticky',
                  top: 0, // Stick to the top of the parent container when scrolling
                  backgroundColor: '#ffffff', // Background color for visual distinction
                  zIndex: 10, // Ensure it stays above other content
                  padding: '2px', // Optional padding for aesthetics
                }} >
                  <Grid item className={classes["Date"]}>
                    {dayList.Date}
                  </Grid>
                  <Grid item container direction="row" paddingTop={0.5}>
                    <Grid item xs={7} direction="row" className={classes.upperData} fontSize="9px">
                      {/* Capacity (Tons): {dayList.FactoryDailyCapacity} */}
                      Capacity (Tons): {dayList.DailyCapacity}

                      {/* <Grid item container direction="row" spacing={0.5}>

                
                      <Grid item xs={9}>
                        {" "}
                        <TextField
                          type="number"
                          fullWidth
                          value={dayList.Capacity}
                          InputProps={{
                            className: classes.textField,
                            style: {
                              fontFamily: "Muli",
                              fontSize: "10px",
                            },
                            inputProps: { min: 0 },
                          }}
                          onChange={(e) => {
                            dayList.Capacity = e.target.value;
                            forceUpdate();
                          }}
                        />
                       
                      </Grid>
                      <Grid item xs={3}>
                          <button
                            className={classes["save-button"]}
                            onClick={() => {}}
                          >
                            Save
                          </button>
                        </Grid>
                        </Grid> */}

                    </Grid>
                    <Grid
                      item
                      xs={5}
                      container
                      direction="row-reverse"
                      className={classes.upperData}
                      fontWeight="bold"

                    >
                      {index === 0
                        ? mondayList.reduce(function (prev, current) {
                          return prev + +current.TheoreticalWeight;
                        }, 0) / 1000
                        : index === 1
                          ? tuesdayList.reduce(function (prev, current) {
                            return prev + +current.TheoreticalWeight;
                          }, 0) / 1000
                          : index === 2
                            ? wednesdayList.reduce(function (prev, current) {
                              return prev + +current.TheoreticalWeight;
                            }, 0) / 1000
                            : index === 3
                              ? thursdayList.reduce(function (prev, current) {
                                return prev + +current.TheoreticalWeight;
                              }, 0) / 1000
                              : index === 4
                                ? fridayList.reduce(function (prev, current) {
                                  return prev + +current.TheoreticalWeight;
                                }, 0) / 1000
                                : index === 5
                                  ? saturdayList.reduce(function (prev, current) {
                                    return prev + +current.TheoreticalWeight;
                                  }, 0) / 1000
                                  : sundayList.reduce(function (prev, current) {
                                    return prev + +current.TheoreticalWeight;
                                  }, 0) / 1000}{" "}
                      &nbsp;Tons
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      paddingTop={0.5}
                      className={classes.upperData}
                      paddingBottom={1}
                      style={{
                        color:
                          index === 0
                            ? (
                              (mondayList.reduce(function (prev, current) {
                                return prev + +current.TheoreticalWeight;
                              }, 0) /
                                1000 /
                                dayList.Capacity) *
                              100
                            )?.toFixed(2) > 100
                              ? "red"
                              : "#0674b9"
                            : index === 1
                              ? (
                                (tuesdayList.reduce(function (prev, current) {
                                  return prev + +current.TheoreticalWeight;
                                }, 0) /
                                  1000 /
                                  dayList.DailyCapacity) *
                                100
                              )?.toFixed(2) > 100
                                ? "red"
                                : "#0674b9"
                              : index === 2
                                ? (
                                  (wednesdayList.reduce(function (prev, current) {
                                    return prev + +current.TheoreticalWeight;
                                  }, 0) /
                                    1000 /
                                    dayList.DailyCapacity) *
                                  100
                                )?.toFixed(2) > 100
                                  ? "red"
                                  : "#0674b9"
                                : index === 3
                                  ? (
                                    (thursdayList.reduce(function (prev, current) {
                                      return prev + +current.TheoreticalWeight;
                                    }, 0) /
                                      1000 /
                                      dayList.DailyCapacity) *
                                    100
                                  )?.toFixed(2) > 100
                                    ? "red"
                                    : "#0674b9"
                                  : index === 4
                                    ? (
                                      (fridayList.reduce(function (prev, current) {
                                        return prev + +current.TheoreticalWeight;
                                      }, 0) /
                                        1000 /
                                        dayList.DailyCapacity) *
                                      100
                                    )?.toFixed(2) > 100
                                      ? "red"
                                      : "#0674b9"
                                    : index === 5
                                      ? (
                                        (saturdayList.reduce(function (prev, current) {
                                          return prev + +current.TheoreticalWeight;
                                        }, 0) /
                                          1000 /
                                          dayList.DailyCapacity) *
                                        100
                                      )?.toFixed(2) > 100
                                        ? "red"
                                        : "#0674b9"
                                      : (
                                        (sundayList.reduce(function (prev, current) {
                                          return prev + +current.TheoreticalWeight;
                                        }, 0) /
                                          1000 /
                                          dayList.DailyCapacity) *
                                        100
                                      )?.toFixed(2) > 100
                                        ? "red"
                                        : "#0674b9",
                      }}
                    >
                      Usage :
                      <span style={{ fontWeight: "bold" }}>
                        {index === 0
                          ? (
                            (mondayList?.reduce(function (prev, current) {
                              return prev + +current.TheoreticalWeight;
                            }, 0) /
                              1000 /
                              dayList.DailyCapacity) *
                            100
                          )?.toFixed(2)
                          : index === 1 && tuesdayList.length > 0
                            ? (
                              (tuesdayList?.reduce(function (prev, current) {
                                return prev + +current.TheoreticalWeight;
                              }, 0) /
                                1000 /
                                dayList.DailyCapacity) *
                              100
                            )?.toFixed(2)
                            : index === 2 && wednesdayList.length > 0
                              ? (
                                (wednesdayList?.reduce(function (prev, current) {
                                  return prev + +current.TheoreticalWeight;
                                }, 0) /
                                  1000 /
                                  dayList.DailyCapacity) *
                                100
                              )?.toFixed(2)
                              : index === 3 && thursdayList.length > 0
                                ? (
                                  (thursdayList?.reduce(function (prev, current) {
                                    return prev + +current.TheoreticalWeight;
                                  }, 0) /
                                    1000 /
                                    dayList.DailyCapacity) *
                                  100
                                )?.toFixed(2)
                                : index === 4 && fridayList.length > 0
                                  ? (
                                    (fridayList?.reduce(function (prev, current) {
                                      return prev + +current.TheoreticalWeight;
                                    }, 0) /
                                      1000 /
                                      dayList.DailyCapacity) *
                                    100
                                  )?.toFixed(2)
                                  : index === 5 && saturdayList.length > 0
                                    ? (
                                      (saturdayList?.reduce(function (prev, current) {
                                        return prev + +current.TheoreticalWeight;
                                      }, 0) /
                                        1000 /
                                        dayList.DailyCapacity) *
                                      100
                                    )?.toFixed(2)
                                    : index === 6 && sundayList.length > 0
                                      ? (
                                        (sundayList?.reduce(function (prev, current) {
                                          return prev + +current.TheoreticalWeight;
                                        }, 0) /
                                          1000 /
                                          dayList.DailyCapacity) *
                                        100
                                      )?.toFixed(2)
                                      : 0}
                        %
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item xs={7} direction="row" className={classes.upperData} fontSize="9px">
                      Capacity (thread): {dayList.DailyThreadCapacity}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      container
                      direction="row-reverse"
                      className={classes.upperData}
                      fontWeight="bold"

                    >

                      {index === 0
                        ? mondayList.reduce(function (prev, current) {
                          return prev + +current.ThreadCount;
                        }, 0)
                        : index === 1
                          ? tuesdayList.reduce(function (prev, current) {
                            return prev + +current.ThreadCount;
                          }, 0)
                          : index === 2
                            ? wednesdayList.reduce(function (prev, current) {
                              return prev + +current.ThreadCount;
                            }, 0)
                            : index === 3
                              ? thursdayList.reduce(function (prev, current) {
                                return prev + +current.ThreadCount;
                              }, 0)
                              : index === 4
                                ? fridayList.reduce(function (prev, current) {
                                  return prev + +current.ThreadCount;
                                }, 0)
                                : index === 5
                                  ? saturdayList.reduce(function (prev, current) {
                                    return prev + +current.ThreadCount;
                                  }, 0)
                                  : sundayList.reduce(function (prev, current) {
                                    return prev + +current.ThreadCount;
                                  }, 0)}{" "}
                      &nbsp;Threads
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      paddingTop={0.5}
                      className={classes.upperData}
                      paddingBottom={1}
                      style={{
                        color:
                          index === 0
                            ? (
                              (mondayList.reduce(function (prev, current) {
                                return prev + +current.ThreadCount;
                              }, 0) /
                                dayList.DailyThreadCapacity) *
                              100
                            )?.toFixed(2) > 100
                              ? "red"
                              : "#0674b9"
                            : index === 1
                              ? (
                                (tuesdayList.reduce(function (prev, current) {
                                  return prev + +current.ThreadCount;
                                }, 0) /
                                  dayList.DailyThreadCapacity) *
                                100
                              )?.toFixed(2) > 100
                                ? "red"
                                : "#0674b9"
                              : index === 2
                                ? (
                                  (wednesdayList.reduce(function (prev, current) {
                                    return prev + +current.ThreadCount;
                                  }, 0) /
                                    dayList.DailyThreadCapacity) *
                                  100
                                )?.toFixed(2) > 100
                                  ? "red"
                                  : "#0674b9"
                                : index === 3
                                  ? (
                                    (thursdayList.reduce(function (prev, current) {
                                      return prev + +current.ThreadCount;
                                    }, 0) /
                                      dayList.DailyThreadCapacity) *
                                    100
                                  )?.toFixed(2) > 100
                                    ? "red"
                                    : "#0674b9"
                                  : index === 4
                                    ? (
                                      (fridayList.reduce(function (prev, current) {
                                        return prev + +current.ThreadCount;
                                      }, 0) /
                                        dayList.DailyThreadCapacity) *
                                      100
                                    )?.toFixed(2) > 100
                                      ? "red"
                                      : "#0674b9"
                                    : index === 5
                                      ? (
                                        (saturdayList.reduce(function (prev, current) {
                                          return prev + +current.ThreadCount;
                                        }, 0) /
                                          dayList.DailyThreadCapacity) *
                                        100
                                      )?.toFixed(2) > 100
                                        ? "red"
                                        : "#0674b9"
                                      : (
                                        (sundayList.reduce(function (prev, current) {
                                          return prev + +current.ThreadCount;
                                        }, 0) /
                                          dayList.DailyThreadCapacity) *
                                        100
                                      )?.toFixed(2) > 100
                                        ? "red"
                                        : "#0674b9",
                      }}
                    >
                      Thread Usage :
                      <span style={{ fontWeight: "bold" }}>
                        {index === 0
                          ? (
                            (mondayList?.reduce(function (prev, current) {
                              return prev + +current.ThreadCount;
                            }, 0) /
                              dayList.DailyThreadCapacity) *
                            100
                          )?.toFixed(2)
                          : index === 1 && tuesdayList.length > 0
                            ? (
                              (tuesdayList?.reduce(function (prev, current) {
                                return prev + +current.ThreadCount;
                              }, 0) /
                                dayList.DailyThreadCapacity) *
                              100
                            )?.toFixed(2)
                            : index === 2 && wednesdayList.length > 0
                              ? (
                                (wednesdayList?.reduce(function (prev, current) {
                                  return prev + +current.ThreadCount;
                                }, 0) /
                                  dayList.DailyThreadCapacity) *
                                100
                              )?.toFixed(2)
                              : index === 3 && thursdayList.length > 0
                                ? (
                                  (thursdayList?.reduce(function (prev, current) {
                                    return prev + +current.ThreadCount;
                                  }, 0) /
                                    dayList.DailyThreadCapacity) *
                                  100
                                )?.toFixed(2)
                                : index === 4 && fridayList.length > 0
                                  ? (
                                    (fridayList?.reduce(function (prev, current) {
                                      return prev + +current.ThreadCount;
                                    }, 0) /
                                      dayList.DailyThreadCapacity) *
                                    100
                                  )?.toFixed(2)
                                  : index === 5 && saturdayList.length > 0
                                    ? (
                                      (saturdayList?.reduce(function (prev, current) {
                                        return prev + +current.ThreadCount;
                                      }, 0) /
                                        dayList.DailyThreadCapacity) *
                                      100
                                    )?.toFixed(2)
                                    : index === 6 && sundayList.length > 0
                                      ? (
                                        (sundayList?.reduce(function (prev, current) {
                                          return prev + +current.ThreadCount;
                                        }, 0) /
                                          dayList.DailyThreadCapacity) *
                                        100
                                      )?.toFixed(2)
                                      : 0}
                        %
                      </span>

                    </Grid>
                  </Grid>
                </Grid>
                {index === 0 ? (
                  <ReactSortable
                    list={mondayList}
                    setList={setMondayList}
                    animation={150}
                    group="cards"
                  // onChange={(order, sortable, evt) => {
                  //   console.log(evt);
                  // }}
                  // onChoose={(e, sortable, store) => {
                  //   console.log(store);
                  // }}
                  >
                    {mondayList.map((so, index) => (
                      <>
                        <Grid
                          item
                          key={so.id}
                          container
                          marginBottom={1}
                          backgroundColor={
                            so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                              ? "#5fba47"
                              : so.DeliveryStatusId ===
                                salesOrderConst.REQUESTED_ID
                                ? "#e1e319"
                                : so.IsDelayed
                                  ? "#rgba(255, 0, 0, 0.48)"
                                  : "#0674b9"
                          }
                          style={{
                            opacity:
                              props.salesOrderCalendar[0].SalesOrders.some(
                                (sl) => sl.Id === so.Id
                              )
                                ? 1
                                : 0.5,
                          }}
                          color={
                            so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                              ? "#101650"
                              : "#fff"
                          }
                          height="142px"
                          padding={0.8}
                        // onMouseOver={(e) => {
                        //   e.target.style.cursor = "pointer";
                        // }}
                        // onClick={() =>
                        //   navigate(
                        //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                        //   )
                        // }
                        >

                          <Grid
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid container xs={6} columnGap={0.6}>
                              <Grid item

                                height='17px' width='17px'
                                style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?
                                  <>

                                    {
                                      props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                        <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                          onClick={() => {
                                            if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                            else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                          }}
                                        /> :
                                        <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                          onClick={() => {
                                            if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                            else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                          }} />
                                    }
                                  </>
                                  :
                                  <>

                                  </>
                                }

                                <VisibilityIcon fontSize="small"
                                  onClick={() =>
                                    navigate(
                                      `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                    )
                                  }
                                />
                              </Grid>



                              <Grid item className={classes["code"]}>

                                {so.Code.length > 15
                                  ? "..." + so.Code.slice(so.Code.length - 15)
                                  : so.Code}
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className={classes["weight"]}
                              fontWeight="bold"
                              xs={6}
                              container
                              justifyContent='flex-end'
                            >
                              {so.TheoreticalWeight} kgs
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            className={classes["description"]}
                            paddingTop={0.7}
                          >
                            {so.Description?.length > 140 ? so.Description.slice(0, 140) + "..." : so.Description}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID && so.SuggestedDate ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                className={classes["suggestedDate"]}
                                paddingTop={0.7}
                              >
                                Suggested Date:
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes["suggestedDate"]}
                              >
                                {" "}
                                {so.SuggestedDate}
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          <Grid
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            paddingTop={0.7}
                            alignItems="center"
                          >
                            <Grid item className={classes["status"]}>
                              {so.StatusId === salesOrderConst.DRAFT_ID
                                ? "Draft"
                                : so.StatusId === salesOrderConst.PLACED_ID
                                  ? "Placed"
                                  : so.StatusId === salesOrderConst.PLANNED_ID
                                    ? "Planned"
                                    : so.StatusId ===
                                      salesOrderConst.IN_PRODUCTION_ID
                                      ? "In Production"
                                      : so.StatusId === salesOrderConst.LOADING_ID
                                        ? "Loading"
                                        : so.StatusId === salesOrderConst.DELIVERED_ID
                                          ? "Delivered"
                                          : ""}
                            </Grid>
                            {so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID ? (
                              <Grid item>
                                <img
                                  src={
                                    so.StatusId === salesOrderConst.DRAFT_ID
                                      ? draftNavy
                                      : so.StatusId ===
                                        salesOrderConst.PLACED_ID
                                        ? placedNavy
                                        : so.StatusId ===
                                          salesOrderConst.PLANNED_ID
                                          ? plannedNavy
                                          : so.StatusId ===
                                            salesOrderConst.IN_PRODUCTION_ID
                                            ? productionNavy
                                            : so.StatusId ===
                                              salesOrderConst.LOADING_ID
                                              ? loadingNavy
                                              : deliveredNavy
                                  }
                                  width="80%"
                                  alt="icons"
                                />
                              </Grid>
                            ) : (
                              <Grid item>
                                <img
                                  src={
                                    so.StatusId === salesOrderConst.DRAFT_ID
                                      ? draftWhite
                                      : so.StatusId ===
                                        salesOrderConst.PLACED_ID
                                        ? placedWhite
                                        : so.StatusId ===
                                          salesOrderConst.PLANNED_ID
                                          ? plannedWhite
                                          : so.StatusId ===
                                            salesOrderConst.IN_PRODUCTION_ID
                                            ? productionWhite
                                            : so.StatusId ===
                                              salesOrderConst.LOADING_ID
                                              ? loadingWhite
                                              : so.StatusId ===
                                                salesOrderConst.DELIVERED_ID
                                                ? deliveredWhite
                                                : ""
                                  }
                                  width="80%"
                                  alt="icons"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </ReactSortable>
                ) : index === 1 ? (
                  <ReactSortable
                    list={tuesdayList}
                    setList={setTuesdayList}
                    animation={150}
                    swapClass={classes["dragged"]}
                    selectedClass={classes["dragged"]}
                    chosenClass={classes["dragged"]}
                    group="cards"
                    onChange={(order, sortable, evt) => { }}
                    onEnd={(evt) => { }}
                  >
                    {tuesdayList.map((so, index) => (
                      <Grid
                        item
                        key={so.id}
                        container
                        marginBottom={1}
                        backgroundColor={
                          so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                            ? "#5fba47"
                            : so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID
                              ? "#e1e319"
                              : so.IsDelayed
                                ? "#rgba(255, 0, 0, 0.48)"
                                : "#0674b9"
                        }
                        style={{
                          opacity: props.salesOrderCalendar[1].SalesOrders.some(
                            (sl) => sl.Code === so.Code
                          )
                            ? 1
                            : 0.5,
                        }}
                        color={
                          so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                            ? "#101650"
                            : "#fff"
                        }
                        height="142px"
                        padding={0.8}
                      // onMouseOver={(e) => {
                      //   e.target.style.cursor = "pointer";
                      // }}
                      // onClick={() =>
                      //   navigate(
                      //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                      //   )
                      // }
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >

                          <Grid item container direction='row' xs={6} columnGap={0.6} className={classes["code"]}>
                            <Grid item

                              height='17px' width='17px'
                              style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?
                                  <>

                                  {props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                    <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }}
                                    /> :
                                    <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }} />
                                  }
                                </>
                                :

                                <></>
                              }
                              <VisibilityIcon fontSize="small"
                                onClick={() =>
                                  navigate(
                                    `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                  )
                                }
                              />
                            </Grid>

                            {so.Code.length > 15
                              ? "..." + so.Code.slice(so.Code.length - 15)
                              : so.Code}
                          </Grid>
                          <Grid
                            item
                            className={classes["weight"]}
                            fontWeight="bold"
                            xs={6}
                            container
                            justifyContent='flex-end'
                          >
                            {so.TheoreticalWeight} kgs
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes["description"]}
                          paddingTop={0.7}
                        >
                          {so.Description?.length > 140 ? so.Description.slice(0, 140) + "..." : so.Description}

                        </Grid>
                        {so.DeliveryStatusId === salesOrderConst.REQUESTED_ID &&
                          so.SuggestedDate ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                              paddingTop={0.7}
                            >
                              Suggested Date:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                            >
                              {" "}
                              {so.SuggestedDate}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          paddingTop={0.7}
                          alignItems="center"
                        >
                          <Grid item className={classes["status"]}>
                            {so.StatusId === salesOrderConst.DRAFT_ID
                              ? "Draft"
                              : so.StatusId === salesOrderConst.PLACED_ID
                                ? "Placed"
                                : so.StatusId === salesOrderConst.PLANNED_ID
                                  ? "Planned"
                                  : so.StatusId === salesOrderConst.IN_PRODUCTION_ID
                                    ? "In Production"
                                    : so.StatusId === salesOrderConst.LOADING_ID
                                      ? "Loading"
                                      : so.StatusId === salesOrderConst.DELIVERED_ID
                                        ? "Delivered"
                                        : ""}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID ? (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftNavy
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedNavy
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedNavy
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionNavy
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingNavy
                                            : deliveredNavy
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftWhite
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedWhite
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedWhite
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionWhite
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingWhite
                                            : so.StatusId ===
                                              salesOrderConst.DELIVERED_ID
                                              ? deliveredWhite
                                              : ""
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </ReactSortable>
                ) : index === 2 ? (
                  <ReactSortable
                    list={wednesdayList}
                    setList={setWednesdayList}
                    animation={150}
                    group="cards"
                    onChange={(order, sortable, evt) => { }}
                    onEnd={(evt) => { }}
                  >
                    {wednesdayList.map((so, index) => (
                      <Grid
                        item
                        key={so.id}
                        container
                        marginBottom={1}
                        backgroundColor={
                          so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                            ? "#5fba47"
                            : so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID
                              ? "#e1e319"
                              : so.IsDelayed
                                ? "#rgba(255, 0, 0, 0.48)"
                                : "#0674b9"
                        }
                        style={{
                          opacity: props.salesOrderCalendar[2].SalesOrders.some(
                            (sl) => sl.Code === so.Code
                          )
                            ? 1
                            : 0.5,
                        }}
                        color={
                          so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                            ? "#101650"
                            : "#fff"
                        }
                        height="142px"
                        padding={0.8}
                      // onMouseOver={(e) => {
                      //   e.target.style.cursor = "pointer";
                      // }}
                      // onClick={() =>
                      //   navigate(
                      //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                      //   )
                      // }
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item container direction='row' xs={6} columnGap={0.6} className={classes["code"]}>
                            <Grid item

                              height='17px' width='17px'
                              style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?
                                  <>

                                  {props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                    <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }}
                                    /> :
                                    <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }} />
                                  }
                                </>
                                :
                                <></>
                              }

                              <VisibilityIcon fontSize="small"
                                onClick={() =>
                                  navigate(
                                    `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                  )
                                }
                              />
                            </Grid>

                            {so.Code.length > 15
                              ? "..." + so.Code.slice(so.Code.length - 15)
                              : so.Code}
                          </Grid>
                          <Grid
                            item
                            className={classes["weight"]}
                            fontWeight="bold"
                            container
                            justifyContent='flex-end'
                            xs={6}
                          >
                            {so.TheoreticalWeight} kgs
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes["description"]}
                          paddingTop={0.7}
                        >
                          {so.Description?.length > 140 ? so.Description.slice(0, 140) + "..." : so.Description}

                        </Grid>
                        {so.DeliveryStatusId === salesOrderConst.REQUESTED_ID &&
                          so.SuggestedDate ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                              paddingTop={0.7}
                            >
                              Suggested Date:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                            >
                              {" "}
                              {so.SuggestedDate}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          paddingTop={0.7}
                          alignItems="center"
                        >
                          <Grid item className={classes["status"]}>
                            {so.StatusId === salesOrderConst.DRAFT_ID
                              ? "Draft"
                              : so.StatusId === salesOrderConst.PLACED_ID
                                ? "Placed"
                                : so.StatusId === salesOrderConst.PLANNED_ID
                                  ? "Planned"
                                  : so.StatusId === salesOrderConst.IN_PRODUCTION_ID
                                    ? "In Production"
                                    : so.StatusId === salesOrderConst.LOADING_ID
                                      ? "Loading"
                                      : so.StatusId === salesOrderConst.DELIVERED_ID
                                        ? "Delivered"
                                        : ""}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID ? (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftNavy
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedNavy
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedNavy
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionNavy
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingNavy
                                            : deliveredNavy
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftWhite
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedWhite
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedWhite
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionWhite
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingWhite
                                            : so.StatusId ===
                                              salesOrderConst.DELIVERED_ID
                                              ? deliveredWhite
                                              : ""
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </ReactSortable>
                ) : index === 3 ? (
                  <ReactSortable
                    list={thursdayList}
                    setList={setThursdayList}
                    animation={150}
                    group="cards"
                    onChange={(order, sortable, evt) => { }}
                    onEnd={(evt) => { }}
                  >
                    {thursdayList.map((so, index) => (
                      <Grid
                        item
                        key={so.id}
                        container
                        marginBottom={1}
                        backgroundColor={
                          so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                            ? "#5fba47"
                            : so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID
                              ? "#e1e319"
                              : so.IsDelayed
                                ? "#rgba(255, 0, 0, 0.48)"
                                : "#0674b9"
                        }
                        style={{
                          opacity: props.salesOrderCalendar[3].SalesOrders.some(
                            (sl) => sl.Code === so.Code
                          )
                            ? 1
                            : 0.5,
                        }}
                        color={
                          so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                            ? "#101650"
                            : "#fff"
                        }
                        height="142px"
                        padding={0.8}
                      // onMouseOver={(e) => {
                      //   e.target.style.cursor = "pointer";
                      // }}
                      // onClick={() =>
                      //   navigate(
                      //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                      //   )
                      // }
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item container direction='row' xs={6} columnGap={0.6} className={classes["code"]}>
                            <Grid item
                              height='17px' width='17px'
                              style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?

                                <>
                                  {props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                    <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }}
                                    /> :
                                    <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }} />
                                  }
                                </>
                                :
                                <></>

                              }
                              <VisibilityIcon fontSize="small"
                                onClick={() =>
                                  navigate(
                                    `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                  )
                                }
                              />
                            </Grid>
                            {so.Code.length > 15
                              ? "..." + so.Code.slice(so.Code.length - 15)
                              : so.Code}
                          </Grid>
                          <Grid
                            item
                            className={classes["weight"]}
                            fontWeight="bold"
                            container
                            justifyContent='flex-end'
                            xs={6}
                          >
                            {so.TheoreticalWeight} kgs
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes["description"]}
                          paddingTop={0.7}
                        >
                          {so.Description?.length > 140 ? so.Description.slice(0, 140) + "..." : so.Description}

                        </Grid>
                        {so.DeliveryStatusId === salesOrderConst.REQUESTED_ID &&
                          so.SuggestedDate ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                              paddingTop={0.7}
                            >
                              Suggested Date:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                            >
                              {" "}
                              {so.SuggestedDate}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          paddingTop={0.7}
                          alignItems="center"
                        >
                          <Grid item className={classes["status"]}>
                            {so.StatusId === salesOrderConst.DRAFT_ID
                              ? "Draft"
                              : so.StatusId === salesOrderConst.PLACED_ID
                                ? "Placed"
                                : so.StatusId === salesOrderConst.PLANNED_ID
                                  ? "Planned"
                                  : so.StatusId === salesOrderConst.IN_PRODUCTION_ID
                                    ? "In Production"
                                    : so.StatusId === salesOrderConst.LOADING_ID
                                      ? "Loading"
                                      : so.StatusId === salesOrderConst.DELIVERED_ID
                                        ? "Delivered"
                                        : ""}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID ? (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftNavy
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedNavy
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedNavy
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionNavy
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingNavy
                                            : deliveredNavy
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftWhite
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedWhite
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedWhite
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionWhite
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingWhite
                                            : so.StatusId ===
                                              salesOrderConst.DELIVERED_ID
                                              ? deliveredWhite
                                              : ""
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </ReactSortable>
                ) : index === 4 ? (
                  <ReactSortable
                    list={fridayList}
                    setList={setFridayList}
                    animation={150}
                    group="cards"
                    onChange={(order, sortable, evt) => { }}
                    onEnd={(evt) => { }}
                  >
                    {fridayList.map((so, index) => (
                      <Grid
                        item
                        key={so.id}
                        container
                        marginBottom={1}
                        backgroundColor={
                          so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                            ? "#5fba47"
                            : so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID
                              ? "#e1e319"
                              : so.IsDelayed
                                ? "#rgba(255, 0, 0, 0.48)"
                                : "#0674b9"
                        }
                        style={{
                          opacity: props.salesOrderCalendar[4].SalesOrders.some(
                            (sl) => sl.Code === so.Code
                          )
                            ? 1
                            : 0.5,
                        }}
                        color={
                          so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                            ? "#101650"
                            : "#fff"
                        }
                        height="142px"
                        padding={0.8}
                      // onMouseOver={(e) => {
                      //   e.target.style.cursor = "pointer";
                      // }}
                      // onClick={() =>
                      //   navigate(
                      //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                      //   )
                      // }
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item container direction='row' xs={6} columnGap={0.6} className={classes["code"]}>
                            <Grid item

                              height='17px' width='17px'
                              style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?
                                  <>
                                  {props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                    <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }}
                                    /> :
                                    <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }} />
                                  }
                                </>
                                :
                                <></>
                              }
                              <VisibilityIcon fontSize="small"
                                onClick={() =>
                                  navigate(
                                    `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                  )
                                }
                              />
                            </Grid>


                            {so.Code.length > 15
                              ? "..." + so.Code.slice(so.Code.length - 15)
                              : so.Code}
                          </Grid>
                          <Grid
                            item
                            className={classes["weight"]}
                            fontWeight="bold"
                            container
                            justifyContent='flex-end'
                            xs={6}
                          >
                            {so.TheoreticalWeight} kgs
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes["description"]}
                          paddingTop={0.7}
                        >
                          {so.Description}

                        </Grid>
                        {so.DeliveryStatusId === salesOrderConst.REQUESTED_ID &&
                          so.SuggestedDate ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                              paddingTop={0.7}
                            >
                              Suggested Date:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                            >
                              {" "}
                              {so.SuggestedDate}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          paddingTop={0.7}
                          alignItems="center"
                        >
                          <Grid item className={classes["status"]}>
                            {so.StatusId === salesOrderConst.DRAFT_ID
                              ? "Draft"
                              : so.StatusId === salesOrderConst.PLACED_ID
                                ? "Placed"
                                : so.StatusId === salesOrderConst.PLANNED_ID
                                  ? "Planned"
                                  : so.StatusId === salesOrderConst.IN_PRODUCTION_ID
                                    ? "In Production"
                                    : so.StatusId === salesOrderConst.LOADING_ID
                                      ? "Loading"
                                      : so.StatusId === salesOrderConst.DELIVERED_ID
                                        ? "Delivered"
                                        : ""}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID ? (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftNavy
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedNavy
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedNavy
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionNavy
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingNavy
                                            : deliveredNavy
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftWhite
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedWhite
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedWhite
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionWhite
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingWhite
                                            : so.StatusId ===
                                              salesOrderConst.DELIVERED_ID
                                              ? deliveredWhite
                                              : ""
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </ReactSortable>
                ) : index === 5 ? (
                  <ReactSortable
                    list={saturdayList}
                    setList={setSaturdayList}
                    animation={150}
                    group="cards"
                    onChange={(order, sortable, evt) => { }}
                    onEnd={(evt) => { }}
                  >
                    {saturdayList.map((so, index) => (
                      <Grid
                        item
                        key={so.id}
                        container
                        marginBottom={1}
                        backgroundColor={
                          so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                            ? "#5fba47"
                            : so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID
                              ? "#e1e319"
                              : so.IsDelayed
                                ? "#rgba(255, 0, 0, 0.48)"
                                : "#0674b9"
                        }
                        style={{
                          opacity: props.salesOrderCalendar[5].SalesOrders.some(
                            (sl) => sl.Code === so.Code
                          )
                            ? 1
                            : 0.5,
                        }}
                        color={
                          so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                            ? "#101650"
                            : "#fff"
                        }
                        height="142px"
                        padding={0.8}
                      // onMouseOver={(e) => {
                      //   e.target.style.cursor = "pointer";
                      // }}
                      // onClick={() =>
                      //   navigate(
                      //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                      //   )
                      // }
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item container direction='row' xs={6} columnGap={0.6} className={classes["code"]}>
                            <Grid item

                              height='17px' width='17px'
                              style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?
                                  <>
                                  {props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                    <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }}
                                    /> :
                                    <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }} />
                                  }

                                </>
                                :
                                <></>
                              }
                              <VisibilityIcon fontSize="small"
                                onClick={() =>
                                  navigate(
                                    `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                  )
                                }
                              />
                            </Grid>

                            {so.Code.length > 15
                              ? "..." + so.Code.slice(so.Code.length - 15)
                              : so.Code}
                          </Grid>
                          <Grid
                            item
                            className={classes["weight"]}
                            fontWeight="bold"
                            container
                            justifyContent='flex-end'
                            xs={6}
                          >
                            {so.TheoreticalWeight} kgs
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes["description"]}
                          paddingTop={0.7}
                        >
                          {so.Description?.length > 140 ? so.Description.slice(0, 140) + "..." : so.Description}

                        </Grid>
                        {so.DeliveryStatusId === salesOrderConst.REQUESTED_ID &&
                          so.SuggestedDate ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                              paddingTop={0.7}
                            >
                              Suggested Date:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                            >
                              {" "}
                              {so.SuggestedDate}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          paddingTop={0.7}
                          alignItems="center"
                        >
                          <Grid item className={classes["status"]}>
                            {so.StatusId === salesOrderConst.DRAFT_ID
                              ? "Draft"
                              : so.StatusId === salesOrderConst.PLACED_ID
                                ? "Placed"
                                : so.StatusId === salesOrderConst.PLANNED_ID
                                  ? "Planned"
                                  : so.StatusId === salesOrderConst.IN_PRODUCTION_ID
                                    ? "In Production"
                                    : so.StatusId === salesOrderConst.LOADING_ID
                                      ? "Loading"
                                      : so.StatusId === salesOrderConst.DELIVERED_ID
                                        ? "Delivered"
                                        : ""}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID ? (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftNavy
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedNavy
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedNavy
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionNavy
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingNavy
                                            : deliveredNavy
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftWhite
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedWhite
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedWhite
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionWhite
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingWhite
                                            : so.StatusId ===
                                              salesOrderConst.DELIVERED_ID
                                              ? deliveredWhite
                                              : ""
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </ReactSortable>
                ) : (
                  <ReactSortable
                    list={sundayList}
                    setList={setSundayList}
                    animation={150}
                    group="cards"
                    onChange={(order, sortable, evt) => { }}
                    onEnd={(evt) => { }}
                  >
                    {sundayList.map((so, index) => (
                      <Grid
                        item
                        key={so.id}
                        container
                        marginBottom={1}
                        backgroundColor={
                          so.DeliveryStatusId === salesOrderConst.VALIDATED_ID
                            ? "#5fba47"
                            : so.DeliveryStatusId ===
                              salesOrderConst.REQUESTED_ID
                              ? "#e1e319"
                              : so.IsDelayed
                                ? "#rgba(255, 0, 0, 0.48)"
                                : "#0674b9"
                        }
                        style={{
                          opacity: props.salesOrderCalendar[6].SalesOrders.some(
                            (sl) => sl.Code === so.Code
                          )
                            ? 1
                            : 0.5,
                        }}
                        color={
                          so.DeliveryStatusId === salesOrderConst.REQUESTED_ID
                            ? "#101650"
                            : "#fff"
                        }
                        height="142px"
                        padding={0.8}
                      // onMouseOver={(e) => {
                      //   e.target.style.cursor = "pointer";
                      // }}
                      // onClick={() =>
                      //   navigate(
                      //     `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                      //   )
                      // }
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item container direction='row' xs={6} columnGap={0.6} className={classes["code"]}>
                            <Grid item

                              height='17px' width='17px'
                              style={{ cursor: 'pointer' }}>
                                {(so.StatusId === salesOrderConst.PLANNED_ID || so.StatusId === salesOrderConst.INTERRUPTED_ID) && !so.ISCombination ?

                                <>

                                  {props.selectedSalesOrders?.find((s) => s.Id === so.Id) ?
                                    <CheckCircleOutlineOutlinedIcon htmlColor="green" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }}
                                    /> :
                                    <CircleIcon htmlColor="white" style={{ width: '19px', height: '19px' }}
                                      onClick={() => {
                                        if (props.selectedSalesOrders?.find((s) => s.Id === so.Id)) props.setSelectedSalesOrders(props.selectedSalesOrders.filter((s) => s.Id !== so.Id))
                                        else props.setSelectedSalesOrders([...props.selectedSalesOrders, { Id: so.Id }])

                                      }} />
                                  }
                                </>
                                :
                                <></>
                              }

                              <VisibilityIcon fontSize="small"
                                onClick={() =>
                                  navigate(
                                    `/saas/salesOrderForm?id=${so.Id}&date=${dayList.Date}`
                                  )
                                }
                              />
                            </Grid>

                            {so.Code.length > 15
                              ? "..." + so.Code.slice(so.Code.length - 15)
                              : so.Code}
                          </Grid>
                          <Grid
                            item
                            className={classes["weight"]}
                            fontWeight="bold"
                            container
                            justifyContent='flex-end'
                            xs={6}
                          >
                            {so.TheoreticalWeight} kgs
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className={classes["description"]}
                          paddingTop={0.7}
                        >
                          {so.Description?.length > 140 ? so.Description.slice(0, 140) + "..." : so.Description}

                        </Grid>
                        {so.DeliveryStatusId === salesOrderConst.REQUESTED_ID &&
                          so.SuggestedDate ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                              paddingTop={0.7}
                            >
                              Suggested Date:
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes["suggestedDate"]}
                            >
                              {" "}
                              {so.SuggestedDate}
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          paddingTop={0.7}
                          alignItems="center"
                        >
                          <Grid item className={classes["status"]}>
                            {so.StatusId === salesOrderConst.DRAFT_ID
                              ? "Draft"
                              : so.StatusId === salesOrderConst.PLACED_ID
                                ? "Placed"
                                : so.StatusId === salesOrderConst.PLANNED_ID
                                  ? "Planned"
                                  : so.StatusId === salesOrderConst.IN_PRODUCTION_ID
                                    ? "In Production"
                                    : so.StatusId === salesOrderConst.LOADING_ID
                                      ? "Loading"
                                      : so.StatusId === salesOrderConst.DELIVERED_ID
                                        ? "Delivered"
                                        : ""}
                          </Grid>
                          {so.DeliveryStatusId ===
                            salesOrderConst.REQUESTED_ID ? (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftNavy
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedNavy
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedNavy
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionNavy
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingNavy
                                            : deliveredNavy
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <img
                                src={
                                  so.StatusId === salesOrderConst.DRAFT_ID
                                    ? draftWhite
                                    : so.StatusId === salesOrderConst.PLACED_ID
                                      ? placedWhite
                                      : so.StatusId === salesOrderConst.PLANNED_ID
                                        ? plannedWhite
                                        : so.StatusId ===
                                          salesOrderConst.IN_PRODUCTION_ID
                                          ? productionWhite
                                          : so.StatusId === salesOrderConst.LOADING_ID
                                            ? loadingWhite
                                            : so.StatusId ===
                                              salesOrderConst.DELIVERED_ID
                                              ? deliveredWhite
                                              : ""
                                }
                                width="80%"
                                alt="icons"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </ReactSortable>
                )}
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}

export default CalenderList;
