import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, Button } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Tooltip } from "@mui/material";
import uploadIcon from "../../../../Assets/Images/Settings/uploadImg.png";
import { styled } from "@mui/system";
import fileIcon from "../../../../Assets/Images/Table/file.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import * as machineBlockServices from "../../../../Services/machine-block-services.proxy";
const uploadFileMachine = machineBlockServices.uploadFileMachine;
const deleteFileMachine = machineBlockServices.deleteFileMachine;

const filter = createFilterOptions();
const CreateForm = (props) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [codeError, setCodeError] = useState(false);
  const [codeErrMessage, setCodeErrMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameErrMessage, setNameErrMessage] = useState("");
  const [iotcodeError, setIotCodeError] = useState(false);
  const [iotcodeErrMessage, setIotCodeErrMessage] = useState("");
  const [iotNameError, setIotNameError] = useState(false);
  const [iotNameErrMessage, setIotNameErrMessage] = useState("");
  const [iotPasswordError, setIotPasswordError] = useState(false);
  const [iotPasswordErrMessage, setIotPasswordErrMessage] = useState("");
  const [iotOSError, setIotOSError] = useState(false);
  const [iotOSErrMessage, setIotOSErrMessage] = useState("");
  const [printercodeError, setPrinterCodeError] = useState(false);
  const [printercodeErrMessage, setPrinterCodeErrMessage] = useState("");
  const [printerNameError, setPrinterNameError] = useState(false);
  const [printerNameErrMessage, setPrinterNameErrMessage] = useState("");
  const [printerModelError, setPrinterModelError] = useState(false);
  const [printerModelErrMessage, setPrinterModelErrMessage] = useState("");
  const [initialRender, setInitialRender] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      uploadFileMachine(e.target.files[0], props.BAY_ID).then((x) => {
        let tempIDS = props.filesIDS;
        props.setFilesIDS(tempIDS.concat({ FileId: x.FileId }));
        let tempFiles = props.files;
        props.setFiles(
          tempFiles.concat({
            FileId: x.FileId,
            DisplayName: x.DisplayName,
            Type: e.target.files[0].type,
            Size: e.target.files[0].size,
          })
        );
      });
    }
  };
  const handleFileDelete = (fileIdToDelete) => {
    let tempIDS = props.filesIDS;
    tempIDS = tempIDS.filter(function (item) {
      return item.FileId !== fileIdToDelete;
    });
    props.setFilesIDS(tempIDS);
    let tempFiles = props.files;
    tempFiles = tempFiles.filter(function (item) {
      return item.FileId !== fileIdToDelete;
    });
    props.setFiles(tempFiles);
  };
  useEffect(() => {
    if (
      (!props.category && props.isPC !== 'true') ||
      !props.name ||
      ((localStorage.getItem("companyPlanIdManu") === "Pro +" ||
        localStorage.getItem("companyPlanIdManu") === "Pro") &&
        (!props.iOTName ||
          !props.iOTOS ||
          !props.iOTPassword ||
          iotOSError ||
          iotNameError ||
          iotPasswordError ||
          (props.isLabelPrinting &&
            (!props.printercode ||
              !props.printerName ||
              !props.printerModel ||
              printercodeError ||
              printerNameError ||
              printerModelError)))) ||
      codeError ||
      nameError
    ) {
      props.setIsDisabled(true);
    } else {
      props.setIsDisabled(false);
    }
  });
  return (
    <Grid
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={1}
      // width={700}s
      gap={2}
      paddingBottom={1}
    >
      {props.isPC === 'true' ?
        <Grid item sm={5} xs={12}>
          <Grid item className={classing["TFTitle"]} paddingLeft={1}>
            *Machines
          </Grid>
          <Grid item>
            <TextField
              disabled={props.disabledEdit}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                multiple: true,
              }}
              value={props.productionCellMachines}
              fullWidth
              select
              InputProps={{
                style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
              }}
              onChange={(e) => {
                props.setProductionCellMachines(e.target.value);
              }}
            >
              <MenuItem value={0} key={0} disabled>
                *Machines
              </MenuItem>
              {props.machinesList?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        :
        <>
          <Grid item sm={3} xs={12}>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              *Machine Category
            </Grid>
            <Grid item>
              <TextField
                disabled={props.disabledEdit}
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={props.category}
                fullWidth
                select
                InputProps={{
                  style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                }}
                onChange={(e) => {
                  props.setCategory(e.target.value);
                  props.setSubCategory(null);
                  setInitialRender(!initialRender);
                  setValue(null);
                  if (e.target.value === 1 || e.target.value === 4) {
                    props.setIsLabelPrinting(true);
                  }
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  *Machine Category
                </MenuItem>
                {props.categories?.map((r) => (
                  <MenuItem value={r.Id} key={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item sm={4.5} xs={12}>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              Subcategory
            </Grid>
            <Grid item>
              <Autocomplete
                disabled={props.disabledEdit}
                key={initialRender}
                defaultValue={{
                  Id: props.subCategory,
                  Name: props.subcategories?.filter(
                    (x) => x.Id === props.subcategory
                  )[0]?.Name,
                }}
                value={props.subCategory}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    console.log(newValue);
                    setValue({
                      Name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      Name: newValue.inputValue,
                    });
                    props.setSubCategories(
                      props.subcategories.concat({
                        Id: null,
                        Name: newValue.inputValue,
                        CategoryId: props.category,
                      })
                    );
                    props.setSubCategory(null);
                    props.setSubCategoryName(newValue.inputValue);
                  } else {
                    setValue(newValue);
                    props.setSubCategory(newValue.Id);
                    props.setSubCategoryName(newValue.Name);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.Name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      Name: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={
                  props.category
                    ? props.subcategories.filter(
                      (x) => x.CategoryId === props.category
                    )
                    : []
                }
                sx={{ height: "28px", fontSize: 12, fontFamily: "Muli" }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option?.Name === undefined ? "" : option.Name;
                }}
                renderOption={(props, option) => <li {...props}>{option.Name}</li>}
                fullWidth
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: false }}
                    size={"small"}
                    sx={{ fontFamily: "Muli" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }

      <Grid item sm={3} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Code
        </Grid>
        <Grid item>
          <TextField
            disabled
            value={props.code}
            fullWidth
            InputProps={{
              style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
            }}
            placeholder="Code"
          />
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Name
        </Grid>
        <Grid item>
          <TextField
            disabled={props.disabledEdit}
            value={props.name}
            error={nameError}
            helperText={nameError ? nameErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Name"
            onBlur={(e) => {
              if (!e.target.value) {
                setNameError(true);
                setNameErrMessage("Enter a Name ");
              }
            }}
            onChange={(e) => {
              props.setName(e.target.value);
              if (!e.target.value) {
                setNameError(true);
                setNameErrMessage("Enter a Name");
              } else if (e.target.value.length > 50) {
                setNameError(true);
                setNameErrMessage("Name should be less than 50 characters");
              } else setNameError(false);
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={4.5}>
        <Grid xs={12}>
          <Grid item className={classing["TFTitle"]} paddingLeft={1}>
            Catalog
          </Grid>
          <Grid item xs={12}>
            <BlueTip
              title={"You can add multiple files"}
              placement="bottom-end"
              interactive="true"
            >
              <Button
                fullWidth
                disabled={props.disabledEdit}
                component="label"
                className={classing["fieldUploadDesign"]}
                sx={{ backgroundColor: "#fff", border: "#bcbcbc solid 1px" }}
              >
                <Grid container item xs={12}>
                  <Grid item xs={1} paddingTop={0.5}>
                    <img src={uploadIcon} alt={"Upload"} />
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    paddingTop={0.5}
                    className={classing["fileText"]}
                  >
                    Upload File
                  </Grid>
                </Grid>
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </Button>
            </BlueTip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {props.files?.map((item) => (
            <Grid
              paddingTop={1}
              container
              direction={"row"}
              key={item.FileId}
              value={item}
            >
              <Grid item xs={2} paddingLeft={1}>
                <img src={fileIcon} alt="file" width={15} />
              </Grid>
              <Grid
                item
                xs={5}
                fontSize={{ xs: 10, md: 14 }}
                onMouseOver={(e) => {
                  e.target.style.color = "grey";
                  e.target.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "black";
                }}
                onClick={() => {
                  window.open(item.URL);
                }}
              >
                <span>{item?.DisplayName?.slice(0, 11)}</span>
              </Grid>
              <Grid
                item
                paddingLeft={2}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  if (!props.disabledEdit) handleFileDelete(item.FileId);
                }}
              >
                <Grid item xs={1}>
                  <CloseIcon fontSize="small" />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Status
        </Grid>
        <Grid item>
          <TextField
            value={props.status}
            fullWidth
            InputProps={{
              style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="STATUS"
          />
        </Grid>
      </Grid>
      {localStorage.getItem("companyPlanIdManu") === "Pro +" ||
        localStorage.getItem("companyPlanIdManu") === "Pro" ? (
        <>
          <Grid item sm={4} xs={12}>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              *IoT code
            </Grid>
            <Grid item>
              <TextField
                value={props.iOTcode}
                disabled
                error={iotcodeError}
                helperText={iotcodeError ? iotcodeErrMessage : ""}
                fullWidth
                InputProps={{
                  style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: 8, fontFamily: "Muli" },
                }}
                placeholder="IOT Code"
                onBlur={(e) => {
                  if (!e.target.value) {
                    setIotCodeError(true);
                    setIotCodeErrMessage("Enter a code ");
                  }
                }}
                onChange={(e) => {
                  props.setIOTCode(e.target.value);
                  if (!e.target.value) {
                    setIotCodeError(true);
                    setIotCodeErrMessage("Enter a Code");
                  } else if (e.target.value.length > 10) {
                    setIotCodeError(true);
                    setIotCodeErrMessage(
                      "Code should be less than 10 characters"
                    );
                  } else setIotCodeError(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              *IoT device name
            </Grid>
            <Grid item>
              <TextField
                disabled={props.disabledEdit}
                value={props.iOTName}
                error={iotNameError}
                helperText={iotNameError ? iotNameErrMessage : ""}
                fullWidth
                InputProps={{
                  style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: 8, fontFamily: "Muli" },
                }}
                placeholder="IOT Name"
                onBlur={(e) => {
                  if (!e.target.value) {
                    setIotNameError(true);
                    setIotNameErrMessage("Enter a Name ");
                  }
                }}
                onChange={(e) => {
                  props.setIOTName(e.target.value);
                  if (!e.target.value) {
                    setIotNameError(true);
                    setIotNameErrMessage("Enter a Name");
                  } else if (e.target.value.length > 50) {
                    setIotNameError(true);
                    setIotNameErrMessage(
                      "Name should be less than 50 characters"
                    );
                  } else setIotNameError(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              *IoT device password
            </Grid>
            <Grid item>
              <TextField
                disabled={props.MACHINE_ID || props.productionCellId}
                autoComplete="new-password"
                value={props.iOTPassword}
                error={iotPasswordError}
                helperText={iotPasswordError ? iotPasswordErrMessage : ""}
                type={"password"}
                fullWidth
                InputProps={{
                  style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: 8, fontFamily: "Muli" },
                }}
                placeholder="IOT Password"
                onBlur={(e) => {
                  if (!e.target.value) {
                    setIotPasswordError(true);
                    setIotPasswordErrMessage("Enter a Password ");
                  }
                }}
                onChange={(e) => {
                  props.setIOTPassword(e.target.value);
                  if (!e.target.value) {
                    setIotPasswordError(true);
                    setIotPasswordErrMessage("Enter a Password");
                  } else setIotPasswordError(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid item sm={6.7} xs={12}>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              *IoT device operating system
            </Grid>
            <Grid item>
              <TextField
                disabled={props.disabledEdit}
                value={props.iOTOS}
                error={iotOSError}
                helperText={iotOSError ? iotOSErrMessage : ""}
                fullWidth
                InputProps={{
                  style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: 8, fontFamily: "Muli" },
                }}
                placeholder="IOT OS"
                onBlur={(e) => {
                  if (!e.target.value) {
                    setIotOSError(true);
                    setIotOSErrMessage("Enter an OS ");
                  }
                }}
                onChange={(e) => {
                  props.setIOTOS(e.target.value);
                  if (!e.target.value) {
                    setIotOSError(true);
                    setIotOSErrMessage("Enter an OS");
                  } else if (e.target.value.length > 50) {
                    setIotOSError(true);
                    setIotOSErrMessage("OS should be less than 50 characters");
                  } else setIotOSError(false);
                }}
              />
            </Grid>
          </Grid>
          {props.isPC === 'true'?
          <></>:
          <Grid item sm={5} container direction={"row"} gap={2}>
            <Grid item className={classing["Label-Printer"]} paddingTop={3.5}>
              Label Printer on this machine
            </Grid>
            <Grid item>
              <Grid item className={classing["Label-Printer"]}>
                Yes
              </Grid>
              <Grid
                item
                onClick={() => {
                  props.setIsLabelPrinting(true);
                }}
                className={
                  props.isLabelPrinting
                    ? classing["CheckedBox"]
                    : classing["notCheckedBox"]
                }
                marginTop={1}
              ></Grid>
            </Grid>
            <Grid item>
              <Grid item className={classing["Label-Printer"]}>
                No
              </Grid>
              <Grid
                item
                onClick={() => {
                  if (props.category !== 1 || props.category !== 4) {
                    props.setIsLabelPrinting(false);
                    props.setPrinterCode(null);
                    props.setPrinterName(null);
                    props.setPrinterModel(null);
                    setPrinterCodeError(false);
                    setPrinterNameError(false);
                    setPrinterModelError(false);
                  }
                }}
                className={
                  props.isLabelPrinting
                    ? classing["notCheckedBox"]
                    : classing["CheckedBox"]
                }
                marginTop={1}
              ></Grid>
            </Grid>
          </Grid>
          }
      
          {props.isLabelPrinting ? (
            <>
              <Grid item sm={3} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Printer code
                </Grid>
                <Grid item>
                  <TextField
                    disabled={props.disabledEdit}
                    value={props.printercode}
                    error={printercodeError}
                    helperText={printercodeError ? printercodeErrMessage : ""}
                    fullWidth
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 8, fontFamily: "Muli" },
                    }}
                    placeholder="Printer Code"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setPrinterCodeError(true);
                        setPrinterCodeErrMessage("Enter a code ");
                      }
                    }}
                    onChange={(e) => {
                      props.setPrinterCode(e.target.value);
                      if (!e.target.value) {
                        setPrinterCodeError(true);
                        setPrinterCodeErrMessage("Enter a Code");
                      } else if (e.target.value.length > 10) {
                        setPrinterCodeError(true);
                        setPrinterCodeErrMessage(
                          "Code should be less than 10 characters"
                        );
                      } else setPrinterCodeError(false);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Printer device name
                </Grid>
                <Grid item>
                  <TextField
                    disabled={props.disabledEdit}
                    value={props.printerName}
                    error={printerNameError}
                    helperText={printerNameError ? printerNameErrMessage : ""}
                    fullWidth
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 8, fontFamily: "Muli" },
                    }}
                    placeholder="Printer Name"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setPrinterNameError(true);
                        setPrinterNameErrMessage("Enter a Name ");
                      }
                    }}
                    onChange={(e) => {
                      props.setPrinterName(e.target.value);
                      if (!e.target.value) {
                        setPrinterNameError(true);
                        setPrinterNameErrMessage("Enter a Name");
                      } else if (e.target.value.length > 50) {
                        setPrinterNameError(true);
                        setPrinterNameErrMessage(
                          "Name should be less than 50 characters"
                        );
                      } else setPrinterNameError(false);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Model
                </Grid>
                <Grid item>
                  <TextField
                    disabled={props.disabledEdit}
                    value={props.printerModel}
                    error={printerModelError}
                    helperText={printerModelError ? printerModelErrMessage : ""}
                    fullWidth
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 8, fontFamily: "Muli" },
                    }}
                    placeholder="Model"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setPrinterModelError(true);
                        setPrinterModelErrMessage("Enter a Model ");
                      }
                    }}
                    onChange={(e) => {
                      props.setPrinterModel(e.target.value);
                      if (!e.target.value) {
                        setPrinterModelError(true);
                        setPrinterModelErrMessage("Enter a Model");
                      } else if (e.target.value.length > 50) {
                        setPrinterModelError(true);
                        setPrinterModelErrMessage(
                          "Model should be less than 50 characters"
                        );
                      } else setPrinterModelError(false);
                    }}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {props.MACHINE_ID ? (
        <></>
      ) : (
        <Grid
          item
          container
          xs={12}
          direction={"row-reverse"}
          gap={2}
          paddingRight={1}
        >
          <button
            className={classing["SaveBut"]}
            disabled={props.isdisabled || props.saveLoading}
            onClick={() => {
              if (props.isPC === 'true') props.saveProductionCell();
              else props.saveMachine();
            }}
          >
          {props.saveLoading? "Saving ...": "Save"}
          </button>
          <button
            className={classing["cancelbut"]}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </button>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateForm;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color:#0674b9;
        background-color: rgba(6, 116, 185, 0.3);
        font-size: 13px;
        width:180px;
     
    `);
