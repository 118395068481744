import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, TextField, Tooltip, TableContainer, Table, TableRow, TableHead, TableCell, TableBody, Backdrop, CircularProgress } from '@mui/material';
import classes from "../../../../../../Assets/Styles/InventorySetup.module.css";
import plusYellow from "../../../../../../Assets/Images/Inventory/plusYellow.png";
import InfoIcon from '@mui/icons-material/Info';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);


const filter = createFilterOptions();

const ProductFirstSection = (props) => {

  // const [value, setValue] = useState(props.inputSetups[0]?.ProductSubCategories?.find((s) => s.Id === props.inputSetups[0].ProductSubCategories[0].Id).Name);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [inputSelected, setInputSelected] = useState(0);
  const [inputSelectedObject, setInputSelectedObject] = useState(props.inputSetups ? props.inputSetups[0] : null);
  const [inputSelectedObjectIndex, setInputSelectedObjectIndex] = useState(0);

  const [currentSelectedGrades, setCurrentSelectedGrades] = useState([]);

  const [value, setValue] = useState(null);
  const [initialRender, setInitialRender] = useState(false);


  // useEffect(() => {
  //   let tmp = []
  //   inputSelectedObject?.SubCategories?.map((sc) => {
  //     sc.Treatments?.map((tr) => {
  //       tr.Grades?.map((gr) => {
  //         if (!currentSelectedGrades.includes(gr.GradeId)) {
  //           tmp.push(gr.GradeId)

  //         }

  //       })
  //     })
  //   })
  //   setCurrentSelectedGrades(tmp)
  // }, [inputSelectedObject])

  const inputObject = {
    Id: null,
    SupplierId: null,
    BrandId: null,
    ItemTypeId: null,
    SubCategories: [],

  }


  const cleanupProductSetups = () => {
    // props.setInputSetups(prevArray => {
    //   const newArray = [...prevArray]; // Create a shallow copy of the array
    //   newArray[inputSelected] = inputSelectedObject; // Update the object at the specified index
    //   return newArray; // Return the new array
    // });
    // console.log("props.inputSetups", props.inputSetups)
    // console.log("inputSelectedObject", inputSelectedObject)
    // const tmp = []
    // tmp.push(inputSelectedObject)
    // props.inputSetups.push(inputSelectedObject)
    // props.inputSetups?.map((i) => {
    //   i.SubCategories?.map((sc) => {
    //     sc.Treatments?.map((tr) => {
    //       if (tr.Grades?.length === 0) {
    //         sc.Treatments.filter((t) => t.Id !== tr.Id)
    //         forceUpdate();
    //       }
    //     })
    //   })
    // })
    props.saveProductReferences()
  }

  //working
  // const [list, setList] = useState(props.treatmentsList);

  // // State for selected items
  // const [selectedFromList, setSelectedFromList] = useState([]);

  // // State for the input value
  // const [inputValue, setInputValue] = useState('');


  // const handleAddingOption = (newName) => {
  //   const newOption = { Id: null, Name: newName };

  //   setList((prevList) => [...prevList, newOption]);
  //   setSelectedFromList((prevSelected) => [...prevSelected, newOption]);
  // };


  // States for handling subcategories editable
  const [productSubcategoriesList, setProductSubcategoriesList] = useState(props.productTypes?.find((s) => s.Id === inputSelectedObject?.ItemTypeId)?.SubCategories || []);
  const [selectedProductSubcategoriesList, setSelectedProductSubcategoriesList] = useState(props.productTypes?.find((p) => p.Id === inputSelectedObject?.ItemTypeId)?.SubCategories?.filter(item =>
    inputSelectedObject.SubCategories?.some(a => (a.SubCategoryName === item.Name || a.SubCategoryId === item.Id))) || []);
  const [subCategoryInputValue, setSubCategoryInputValue] = useState('');

  // States for handling treatments editable
  const [treatmentsList, setTreatmentsList] = useState(props.treatmentsList);
  const [selectedTreatmentsList, setSelectedTreatmentsList] = useState(props.inputSetups[0]?.SubCategories[0]?.Treatments?.map(({ TreatmentId }) => ({
    Id: TreatmentId,
    Name: props.treatmentsList?.find((t) => t.Id === TreatmentId)?.Name
  })) || []);
  const [treatmentInputValue, setTreatmentInputValue] = useState('');

  // States for handling grades editable
  const [gradesList, setGradesList] = useState(props.gradesList);
  const [selectedGradesList, setSelectedGradesList] = useState((props.inputSetups[0]?.SubCategories[0]?.Treatments[0]?.Grades?.map(({ GradeId }) => ({
    Id: GradeId,
    Name: props.gradesList?.find((t) => t.Id === GradeId)?.Name
  })) || []));
  const [gradeInputValue, setGradeInputValue] = useState('');

  const handleAddingTreatmentOption = (newName) => {
    const newOption = { Id: null, Name: newName };

    setTreatmentsList((prevList) => [...prevList, newOption]);
    setSelectedTreatmentsList((prevSelected) => [...prevSelected, newOption]);
  };

  // useEffect(() => {
  //   console.log("inputSelectedObject.SubCategories", inputSelectedObject)
  //   console.log("productSubcategoriesList", productSubcategoriesList)

  // }, [productSubcategoriesList])

  // State for the input value
  // const [treatmentInputValue, setTreatmentInputValue] = useState('');
  const [treatmentInputValueArray, setTreatmentInputValueArray] = useState(Array(selectedProductSubcategoriesList?.length).fill(''));


  const handleChange = (event, newValue) => {
    setSelectedProductSubcategoriesList(newValue);
  };

  const handleAddOption = (newName, type, sc, index) => {
    const newOption = { Id: null, Name: newName };
    if (type === 'Subcategory') {
      const subcategoryObject = {
        Id: null,
        SubCategoryId: null,
        Treatments: [],
        Grades: [],
        SpliceTypeNumber: null,
        SubCategoryName: subCategoryInputValue + ""
      }
      const updatedProductTypesSubcategories = props.productTypes?.map((i) => i.Id === inputSelectedObject?.ItemTypeId ? { ...i, SubCategories: [...i.SubCategories, newOption] } : i)
      props.setProductTypes(updatedProductTypesSubcategories);

      // console.log("inputSelectedObject", { ...inputSelectedObject, SubCategories: [...inputSelectedObject.SubCategories, subcategoryObject] })
      // const updatedInputSelectedObject = { ...inputSelectedObject, SubCategories: [...inputSelectedObject.SubCategories, subcategoryObject] }
      // setInputSelectedObject(updatedInputSelectedObject);

      inputSelectedObject.SubCategories = [...inputSelectedObject.SubCategories, subcategoryObject];
      forceUpdate();

      // setInputSelectedObject({...inputSelectedObject,SubCategories: [...productSubcategoriesList,subcategoryObject]});

      setProductSubcategoriesList((prevList) => [...prevList, newOption]);

      setSelectedProductSubcategoriesList((prevSelected) => [...prevSelected, newOption]);
    }
    if (type === 'Treatment') {

      // const newTreatment = { Id: null, Name: treatmentInputValue };

      // props.setTreatmentsList([...props.treatmentsList, newTreatment])

      const treatmentObject = {
        Id: null,
        TreatmentId: null,
        TreatmentName: newName + "",
        Name: newName,
        Grades: []

      }

      sc.Treatments = [...sc.Treatments, treatmentObject];
      forceUpdate();
      // console.log("treatmentObject", sc)

      // setSelectedTreatmentsList([...selectedTreatmentsList, newTreatment]);

      setTreatmentsList((prevList) => [...prevList, newOption]);
      setSelectedTreatmentsList((prevSelected) => [...prevSelected, newOption]);
      props.setTreatmentsList((prevList) => [...prevList, newOption])


    }

    if (type === 'Grade') {

      const gradeObject = {
        Id: null,
        GradeId: null,
        GradeName: newName + "",
        Name: newName,
      }

      sc.Grades = [...sc.Grades, gradeObject];
      forceUpdate();

      setGradesList((prevList) => [...prevList, newOption]);
      setSelectedGradesList((prevSelected) => [...prevSelected, newOption]);
      props.setGradesList((prevList) => [...prevList, newOption])
    }
  };



  const renderInputSetupObject = () => {
    return (
      <Grid item container className={classes["inputSetupContainer"]} padding={3} xs={12} marginRight={1.5} columnGap={{ xs: 1, sm: 3 }} rowGap={2}>
        <Grid item container direction="column" xs={12} sm={3.5} rowGap={0.5}>
          <Grid item className={classes["TFTitle"]}>*Supplier Name</Grid>
          <Grid item>
            <TextField
              select
              fullWidth
              value={inputSelectedObject.SupplierId}
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              SelectProps={{
                MenuProps: {
                  sx: { height: "150px" },
                  MenuProps: { disableScrollLock: true },
                },
              }}
              onChange={(e) => {
                inputSelectedObject.SupplierId = e.target.value;
                forceUpdate()
              }}
            >
              {props.suppliersList?.map((s) => (
                <MenuItem key={s.Id} value={s.Id}>{s.Name}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} sm={3.5} rowGap={0.5}>
          <Grid item className={classes["TFTitle"]}>*Brand Name</Grid>
          <Grid item>
            <TextField
              select
              fullWidth
              value={inputSelectedObject.BrandId}
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              SelectProps={{
                MenuProps: {
                  sx: { height: "150px" },
                  MenuProps: { disableScrollLock: true },
                },
              }}
              onChange={(e) => {
                inputSelectedObject.BrandId = e.target.value
                forceUpdate();

              }}
            >
              {props.suppliersList.find((s) => s.Id === inputSelectedObject.SupplierId)?.Brands?.map((s) => (
                <MenuItem key={s.Id} value={s.Id}>{s.Name}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} sm={3.5} rowGap={0.5}>
          <Grid item className={classes["TFTitle"]}>*Product Category</Grid>
          <Grid item>
            <TextField
              select
              fullWidth
              value={inputSelectedObject.ItemTypeId}
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              // SelectProps={{
              //   MenuProps: {
              //     sx: { height: "150px" },disableScrollLock: true
              //   },
              // }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
              onChange={(e) => {

                inputSelectedObject.ItemTypeId = e.target.value;
                forceUpdate();

                const updatedProductSubcategoriesList = props.productTypes?.find((s) => s.Id === e.target.value)?.SubCategories;
                setProductSubcategoriesList(updatedProductSubcategoriesList);

                // const updatedSelectedSubcategoriesList = props.productTypes?.find((p) => p.Id === inputSelectedObject.ItemTypeId)?.SubCategories?.filter(item =>
                //   inputSelectedObject.SubCategories?.some(a => (a.SubCategoryName === item.Name || a.SubCategoryId === item.Id)));
                setSelectedProductSubcategoriesList([]);

                // const updatedInputSelectedObject = { ...inputSelectedObject, SubCategories: [] }
                // setInputSelectedObject(updatedInputSelectedObject);

                inputSelectedObject.SubCategories = [];
                forceUpdate();

                // props.setProductTypes(updatedProductSubcategoriesList);
                // const resetInputSelectedObjectSubcategories = { ...inputSelectedObject, SubCategories: [] }
                // setInputSelectedObject(resetInputSelectedObjectSubcategories);
              }}
            >
              {props.productTypes?.map((s) => (
                <MenuItem key={s.Id} value={s.Id}>{s.Name}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} sm={3.5} rowGap={0.5}>
          <Grid item className={classes["TFTitle"]}>*Product Subcategory
            <Tooltip title="You can choose multiple subcategories" placement='right'><InfoIcon style={{ height: "14px" }} /></Tooltip></Grid>
          <Grid item>
            {/* working */}
            {/* <Autocomplete
              multiple
              options={list}
              value={selectedFromList}
              onChange={(event, newValue) => {
                console.log("newValue", newValue)
                setSelectedFromList(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              filterOptions={(options, { inputValue }) => {
                const filtered = options.filter(option =>
                  option.Name.toLowerCase().includes(inputValue.toLowerCase())
                );

                // Suggest adding a new value if it doesn't exist
                const isExisting = options.some(option => inputValue === option.Name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({ Id: null, Name: `Add "${inputValue}"` });
                }

                return filtered;
              }}
              getOptionLabel={(option) => option.Name}
              renderOption={(props, option) => {
                const isSelected = selectedFromList.some(selected => selected.Name === option.Name);
                const addedBefore = list.some(i => i.Name === option.Name)

                return (
                  <li
                    {...props}
                    onClick={() => {
                      if (option.Id === null && !isSelected && !addedBefore) {
                        handleAddOption(inputValue); // Use the tracked input value
                      } else {
                        if (isSelected) {
                          setSelectedFromList(prev => prev.filter(selected => selected.Name !== option.Name)); // Remove if already selected
                        } else {
                          setSelectedFromList(prev => [...prev, option]); // Add if not selected
                        }
                      }
                      setInputValue(''); // Clear input after adding
                    }}
                    style={{ fontWeight: isSelected ? 'bold' : 'normal' }} // Optional: highlight selected items
                  >
                    {option.Name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select or type to add..." />
              )}
            /> */}
            {/* test */}
            <Autocomplete
              multiple
              limitTags={1}
              id="size-small-filled-multi"
              size="small"
              options={productSubcategoriesList}
              value={selectedProductSubcategoriesList}
              onChange={(event, newValue) => {
                // console.log('newValue', newValue)
                setSelectedProductSubcategoriesList(newValue);

                // const updatedInputSelectedObject =
                // {
                //   ...inputSelectedObject,
                //   SubCategories: inputSelectedObject.SubCategories?.filter(item =>
                //     newValue.some(obj =>
                //       (obj.Id === item.SubCategoryId || obj.Name === item.SubCategoryName)
                //     )
                //   )
                // }
                // setInputSelectedObject(updatedInputSelectedObject);

                inputSelectedObject.SubCategories = inputSelectedObject.SubCategories?.filter(item => newValue.some(obj => (obj.Id === item.SubCategoryId || obj.Name === item.SubCategoryName)))
                forceUpdate();

              }}
              inputValue={subCategoryInputValue}
              onInputChange={(event, newInputValue) => {
                setSubCategoryInputValue(newInputValue);
              }}
              filterOptions={(options, { inputValue }) => {
                const filtered = options.filter(option =>
                  option.Name.toLowerCase().includes(inputValue.toLowerCase())
                );

                // Suggest adding a new value if it doesn't exist
                const isExisting = options?.some(option => inputValue === option.Name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({ Id: null, Name: `Add "${inputValue}"` });
                }

                return filtered;
              }}
              getOptionLabel={(option) => option.Name}
              renderOption={(props, option) => {
                const isSelected = selectedProductSubcategoriesList?.some(selected => selected.Name === option.Name);
                const addedBefore = productSubcategoriesList?.some(i => i.Name === option.Name)

                return (
                  <li
                    {...props}
                    onClick={() => {
                      if (option.Id === null && !isSelected && !addedBefore) {
                        handleAddOption(subCategoryInputValue, 'Subcategory', null, null); // Use the tracked input value
                        // console.log("1 handle add new value", subCategoryInputValue)
                      } else {
                        if (isSelected) {
                          // console.log("2 remove", option)
                          setSelectedProductSubcategoriesList(prev => prev.filter(selected => selected.Name !== option.Name)); // Remove if already selected
                          // const updatedInputSelectedObject = { ...inputSelectedObject, SubCategories: inputSelectedObject.SubCategories.filter((i) => (i.SubCategoryId !== option.Id || i.SubCategoryName !== option.Name)) }
                          //setInputSelectedObject(updatedInputSelectedObject);
                          inputSelectedObject.SubCategories = inputSelectedObject.SubCategories.filter((i) => (i.SubCategoryId !== option.Id || i.SubCategoryName !== option.Name));
                          forceUpdate();
                        } else {
                          // console.log("3 add", option)
                          setSelectedProductSubcategoriesList(prev => [...prev, option]); // Add if not selected
                          const subcategoryObject = {
                            SubCategoryId: option.Id ? option.Id : null,
                            SubCategoryName: option.Name + "",
                            Treatments: [],
                            Grades: [],
                            SpliceTypeNumber: null,
                          }
                          // const updatedInputSelectedObject = { ...inputSelectedObject, SubCategories: [...inputSelectedObject.SubCategories, subcategoryObject] }
                          //setInputSelectedObject(updatedInputSelectedObject);
                          inputSelectedObject.SubCategories = [...inputSelectedObject.SubCategories, subcategoryObject];
                          forceUpdate();
                        }
                      }
                      subCategoryInputValue(''); // Clear input after adding
                    }}
                    style={{ fontWeight: isSelected ? 'bold' : 'normal' }} // Optional: highlight selected items
                  >
                    {option.Name}
                  </li>
                );
              }}
              // renderTags={(value, getTagProps) => {
              //   // Return selected items as comma-separated plain text
              //   return (
              //     <span
              //       style={{
              //         display: 'inline-block',
              //         width: '120px', // Fixed width
              //         whiteSpace: 'nowrap', // Prevent wrapping
              //         overflow: 'hidden', // Hide overflow text
              //         textOverflow: 'ellipsis', // Add ellipsis when text overflows
              //       }}
              //     >
              //       {value.map(item => item.Name).join(', ')}
              //     </span>

              //   );
              // }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select or type to add..."
                  sx={{
                    width: '100%', // Fixed width for the input field
                    '& .MuiInputBase-root': {
                      minHeight: 40, // Fixed height to prevent expanding
                      display: 'flex',
                      padding: '5px', // Padding to avoid clipping
                      borderRadius: '4px', // Optional: set the border radius
                      fontSize: '13px', // Font size

                    },
                  }}

                />
              )}


            />
            {/* <Autocomplete
              multiple
              options={list}
              value={selectedFromList}
              onChange={(event, newValue) => {
                console.log("newValue", newValue)
                setSelectedFromList(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              filterOptions={(options, { inputValue }) => {
                const filtered = options.filter(option =>
                  option.Name.toLowerCase().includes(inputValue.toLowerCase())
                );

                // Suggest adding a new value if it doesn't exist
                const isExisting = options.some(option => inputValue === option.Name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({ Id: null, Name: `Add "${inputValue}"` });
                }

                return filtered;
              }}
              getOptionLabel={(option) => option.Name}
              renderOption={(props, option) => {
                const isSelected = selectedFromList.some(selected => selected.Name === option.Name);
                const addedBefore = list.some(i => i.Name === option.Name)

                return (
                  <li
                    {...props}
                    onClick={() => {
                      if (option.Id === null && !isSelected && !addedBefore) {
                        handleAddOption(inputValue); // Use the tracked input value
                      } else {
                        if (isSelected) {
                          setSelectedFromList(prev => prev.filter(selected => selected.Name !== option.Name)); // Remove if already selected
                        } else {
                          setSelectedFromList(prev => [...prev, option]); // Add if not selected
                        }
                      }
                      setInputValue(''); // Clear input after adding
                    }}
                    style={{ fontWeight: isSelected ? 'bold' : 'normal' }} // Optional: highlight selected items
                  >
                    {option.Name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select or type to add..." />
              )}
            /> */}
          </Grid>
        </Grid>

        {
          inputSelectedObject.SubCategories?.map((sc, index) => (
            <Grid item container xs={11} className={classes["inputSetupContainer"]} key={index} padding={3} columnGap={2}>
              <Grid item className={classes["Title"]} xs={12}>
                {
                  sc.SubCategoryId ?
                    props.productTypes?.find((p) => p.Id === inputSelectedObject?.ItemTypeId)?.SubCategories?.find((sub) => sub.Id === sc.SubCategoryId)?.Name
                    :
                    <>{sc.SubCategoryName}</>

                }</Grid>
              <Grid item container direction="column" xs={12} sm={2} rowGap={0.5}>
                <Grid item className={classes["TFTitle"]}>*No of splice type</Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    type="number"
                    value={sc.SpliceTypeNumber}
                    InputProps={{
                      style: {
                        height: "39px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                    }}
                    inputProps={{
                      min: 0,
                      max: 2
                    }}
                    onChange={(e) => {
                      if (e.target.value >= 0 && e.target.value <= 2) {
                        sc.SpliceTypeNumber = e.target.value;
                      }
                      forceUpdate();
                    }}
                  />

                </Grid>
              </Grid>
              <Grid item container direction="column" xs={12} sm={4} rowGap={0.5}>
                <Grid item className={classes["TFTitle"]} fontSize="12PX">*Product Sub1 Categories</Grid>
                <Grid item>
                  {/* <Autocomplete
                    multiple
                    options={props.treatmentsList}
                    value={sc.Treatments}
                    onChange={(event, newValue) => {
                      console.log('newValue', newValue);
                      sc.Treatments = newValue
                      forceUpdate();
                      // setSelectedTreatmentsList(newValue);
                    }}
                    inputValue={treatmentInputValueArray[index]}
                    onInputChange={(event, newInputValue) => {
                      treatmentInputValueArray[index] = newInputValue;
                      forceUpdate();
                      //setTreatmentInputValue(newInputValue);
                    }}
                    filterOptions={(options, { inputValue }) => {
                      const filtered = options.filter(option =>
                        option.Name?.toLowerCase()?.includes(inputValue?.toLowerCase())
                      );

                      // Suggest adding a new value if it doesn't exist
                      const isExisting = options?.some(option => inputValue === option.Name);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({ Id: null, Name: `Add "${inputValue}"` });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => option.Name}
                    renderOption={(props, option) => {
                      console.log("optionnn", option)
                      const isSelected = sc.Treatments?.some(selected => selected.Name === option.Name);
                      const addedBefore = props.treatmentsList?.some(i => i.Name === option.Name)

                      return (
                        <li
                          {...props}
                          onClick={() => {
                            if (option.Id === null && !isSelected && !addedBefore) {
                              handleAddOption(treatmentInputValueArray[index], 'Treatment', sc, index); // Use the tracked input value
                              console.log("1 handle add new value", treatmentInputValue)
                            } else {
                              if (isSelected) {
                                console.log("2 remove", option)
                                sc.Treatments = sc.Treatments.filter((t) => t.Name !== option.Name);
                                forceUpdate();
                                // setSelectedTreatmentsList(prev => prev.filter(selected => selected.Name !== option.Name)); // Remove if already selected
                              } else {
                                console.log("3 add", option);
                                sc.Treatments = [...sc.Treatments, option];
                                forceUpdate();
                                // setSelectedTreatmentsList(prev => [...prev, option]); // Add if not selected
                              }
                            }
                            //setTreatmentInputValue(''); // Clear input after adding
                            treatmentInputValueArray[index] = '';
                            forceUpdate();
                          }}
                          style={{ fontWeight: isSelected ? 'bold' : 'normal' }} // Optional: highlight selected items
                        >
                          {option.Name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select or type to add..." />
                    )}
                  /> */}
                  {index === 0 ?
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="size-small-filled-multi"
                      size="small"
                      options={treatmentsList}
                      value={selectedTreatmentsList}
                      onChange={(event, newValue) => {
                        // console.log("newValue", newValue)
                        setSelectedTreatmentsList(newValue);

                        sc.Treatments = sc.Treatments.filter(item =>
                          newValue.some(obj =>
                            (obj.Id === item.TreatmentId || obj.Name === item.TreatmentName)
                          )
                        );
                        forceUpdate();
                        // console.log("sc.Treatments", sc.Treatments)

                      }}
                      inputValue={treatmentInputValue}
                      onInputChange={(event, newInputValue) => {
                        setTreatmentInputValue(newInputValue);
                      }}
                      filterOptions={(options, { inputValue }) => {
                        const filtered = options.filter(option =>
                          option.Name.toLowerCase().includes(inputValue.toLowerCase())
                        );

                        // Suggest adding a new value if it doesn't exist
                        const isExisting = options.some(option => inputValue === option.Name);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({ Id: null, Name: `Add "${inputValue}"` });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => option.Name}
                      renderOption={(props, option) => {
                        const isSelected = selectedTreatmentsList.some(selected => selected.Name === option.Name);
                        const addedBefore = treatmentsList.some(i => i.Name === option.Name)

                        return (
                          <li
                            {...props}
                            onClick={() => {
                              if (option.Id === null && !isSelected && !addedBefore) {
                                handleAddOption(treatmentInputValue, 'Treatment', sc); // Use the tracked input value
                              } else {
                                if (isSelected) {
                                  setSelectedTreatmentsList(prev => prev.filter(selected => selected.Name !== option.Name)); // Remove if already selected
                                  sc.Treatments = sc.Treatments?.filter((t) => t.TreatmentId !== option.Id || t.TreatmentName !== option.Name)

                                } else {
                                  setSelectedTreatmentsList(prev => [...prev, option]); // Add if not selected
                                  const treatmentObject = {
                                    Id: null,
                                    TreatmentId: option.Id ? option.Id : null,
                                    TreatmentName: option.Name + "",
                                    Name: option.Name + "",
                                    Grades: []

                                  }

                                  sc.Treatments = [...sc.Treatments, treatmentObject];
                                  forceUpdate();
                                }
                              }
                              setTreatmentInputValue(''); // Clear input after adding
                            }}
                            style={{ fontWeight: isSelected ? 'bold' : 'normal' }} // Optional: highlight selected items
                          >
                            {option.Name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select or type to add..."
                          sx={{
                            width: '100%', // Fixed width for the input field
                            '& .MuiInputBase-root': {
                              minHeight: 40, // Fixed height to prevent expanding
                              display: 'flex',
                              padding: '0.5px', // Padding to avoid clipping
                              borderRadius: '4px', // Optional: set the border radius
                              fontSize: '13px', // Font size

                            },
                          }}
                        />
                      )}
                    />
                    :
                    <TextField
                      select
                      fullWidth
                      value={sc.Treatments?.map((obj) => treatmentsList.find((i) => i.Id === obj.TreatmentId).Name)}
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                          width: '340px',  // Keep the width of the TextField within the container
                          overflow: 'hidden',  // Hide overflow text
                          textOverflow: 'ellipsis',  // Truncate with ellipsis when text overflows
                          whiteSpace: 'nowrap',  // Prevent text from wrapping into the next line
                        },
                      }}
                      SelectProps={{
                        multiple: true,
                        MenuProps: {
                          disableScrollLock: true, sx: { height: "150px" },
                        },
                      }}
                      onChange={(e, addedValue) => {
                        // console.log("added value", addedValue)
                        let newTreatmentObject = {
                          Id: null,
                          TreatmentId: props.treatmentsList?.find((i) => i.Name === addedValue.props.value)?.Id,
                          Name: addedValue.props.value,
                          Grades: []
                        }
                        let tmp = sc.Treatments;
                        if (tmp?.find((i) => i.Name === addedValue.props.value)) {
                          sc.Treatments = sc.Treatments.filter((i) => i.Name !== addedValue.props.value);
                          forceUpdate();
                        }
                        else {
                          sc.Treatments = [...sc.Treatments, newTreatmentObject]
                          forceUpdate();

                        }
                      }}
                    >
                      {props.treatmentsList?.map((s, index) => (
                        <MenuItem key={index} value={s.Name}>{s.Name}</MenuItem>
                      ))}
                    </TextField>
                  }

                </Grid>
              </Grid>

              {/* <TextField
                      select
                      fullWidth
                      value={sc.Treatments?.map((obj) => obj.TreatmentName)}
                      InputProps={{
                        style: {
                          height: "30px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      SelectProps={{
                        multiple: true,
                        MenuProps: {
                          disableScrollLock: true, sx: { height: "150px" },
                        },
                      }}
                      onChange={(e, addedValue) => {
                        console.log("addedValue",addedValue)
                        console.log("on change",sc.Treatments)
                        let newTreatmentObject = {
                          Id: null,
                          TreatmentId: addedValue.props.value.Id?addedValue.props.value.Id:null,
                          TreatmentName: addedValue.props.value.Name,
                          Grades: []
                        }
                        let tmp = sc.Treatments;
                        if (tmp?.find((i) => i.TreatmentId === addedValue.props.value.Id)) {
                          console.log("removeee",sc.Treatments)
                          sc.Treatments = sc.Treatments.filter((i) => i.TreatmentId?i.TreatmentId !== addedValue.props.value.Id: i.TreatmentName !== addedValue.props.value.Name);
                          forceUpdate();

                        }
                        else {
                          console.log("add",sc.Treatments)

                          sc.Treatments = [...sc.Treatments, newTreatmentObject]
                          forceUpdate();

                        }

                      }}
                    >
                      {props.treatmentsList?.map((s, index) => (
                        <MenuItem key={index} value={s.Name}>{s.Name}</MenuItem>
                      ))}
                    </TextField> */}

              <Grid item container direction="column" xs={12} sm={4} rowGap={0.5}>
                <Grid item className={classes["TFTitle"]} fontSize="12PX">*Product Sub2 Categories</Grid>
                <Grid item>

                  {index === 0 ?
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="size-small-filled-multi"
                      size="small"
                      options={gradesList}
                      value={selectedGradesList}
                      onChange={(event, newValue) => {
                        // console.log("newValue", newValue)
                        setSelectedGradesList(newValue);

                        sc.Grades = sc.Grades?.filter(item =>
                          newValue.some(obj =>
                            (obj.Id === item.GradeId || obj.Name === item.GradeName)
                          )
                        );
                        forceUpdate();
                        // console.log("sc.Grades", sc.Grades)

                      }}
                      inputValue={gradeInputValue}
                      onInputChange={(event, newInputValue) => {
                        setGradeInputValue(newInputValue);
                      }}
                      filterOptions={(options, { inputValue }) => {
                        const filtered = options.filter(option =>
                          option.Name?.toLowerCase()?.includes(inputValue.toLowerCase())
                        );

                        // Suggest adding a new value if it doesn't exist
                        const isExisting = options.some(option => inputValue === option.Name);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({ Id: null, Name: `Add "${inputValue}"` });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => option.Name}
                      renderOption={(props, option) => {
                        const isSelected = selectedGradesList.some(selected => selected.Name === option.Name);
                        const addedBefore = gradesList.some(i => i.Name === option.Name)

                        return (
                          <li
                            {...props}
                            onClick={() => {
                              if (option.Id === null && !isSelected && !addedBefore) {
                                handleAddOption(gradeInputValue, 'Grade', sc); // Use the tracked input value
                              } else {
                                if (isSelected) {
                                  setSelectedGradesList(prev => prev.filter(selected => selected.Name !== option.Name)); // Remove if already selected
                                  sc.Grades = sc.Grades?.filter((t) => t.GradeId !== option.Id || t.GradeName !== option.Name)

                                } else {
                                  setSelectedGradesList(prev => [...prev, option]); // Add if not selected
                                  const gradeObject = {
                                    Id: null,
                                    GradeId: option.Id ? option.Id : null,
                                    GradeName: option.Name + "",
                                    Name: option.Name + "",
                                    Grades: []

                                  }

                                  sc.Grades = [...sc.Grades, gradeObject];
                                  forceUpdate();
                                }
                              }
                              setGradeInputValue(''); // Clear input after adding
                            }}
                            style={{ fontWeight: isSelected ? 'bold' : 'normal' }} // Optional: highlight selected items
                          >
                            {option.Name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select or type to add..."
                          sx={{
                            width: '100%', // Fixed width for the input field
                            '& .MuiInputBase-root': {
                              minHeight: 40, // Fixed height to prevent expanding
                              display: 'flex',
                              padding: '0.5px', // Padding to avoid clipping
                              borderRadius: '4px', // Optional: set the border radius
                              fontSize: '13px', // Font size

                            },
                          }}
                        />

                      )}
                    />
                    :
                    <TextField
                      select
                      fullWidth
                      value={sc.Grades?.map((obj) => obj.Name)}
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          width: '340px',
                          fontFamily: "Muli",
                        },
                      }}
                      SelectProps={{
                        multiple: true,
                        MenuProps: {
                          disableScrollLock: true, sx: { height: "150px" },
                        },
                      }}
                      onChange={(e, addedValue) => {
                        let newGradeObject = {
                          Id: null,
                          GradeId: props.gradesList?.find((g) => g.Name === addedValue.props.value)?.Id,
                          Name: addedValue.props.value,
                        }
                        let tmp = sc.Grades;
                        if (tmp?.find((i) => i.Name === addedValue.props.value)) {
                          sc.Grades = sc.Grades.filter((i) => i.Name !== addedValue.props.value);
                          forceUpdate();

                        }
                        else {
                          sc.Grades = [...sc.Grades, newGradeObject]
                          forceUpdate();

                        }
                      }}
                    >
                      {props.gradesList?.map((s, index) => (
                        <MenuItem key={index} value={s.Name}>{s.Name}</MenuItem>
                      ))}
                    </TextField>
                  }
                  {/* <TextField
                    select
                    fullWidth
                    value={sc.Grades}
                    InputProps={{
                      style: {
                        height: "30px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                    }}
                    SelectProps={{
                      multiple: true,
                      MenuProps: {
                        disableScrollLock: true, sx: { height: "150px" },
                      },
                    }}
                    onChange={(e, addedValue) => {
                      // let newGradeObject = {
                      //   Id: null,
                      //   GradeId: addedValue.props.value,
                      // }
                      // let tmp = sc.Grades;
                      // if (tmp.find((i) => i.GradeId === addedValue.props.value)) {
                      //   sc.Grades = sc.Grades.filter((i) => i.GradeId !== addedValue.props.value);
                      //   forceUpdate();

                      // }
                      // else {
                      //   sc.Grades = [...sc.Grades, newGradeObject]
                      //   forceUpdate();

                      // }

                      // if (currentSelectedGrades?.includes(addedValue.props.value)) setCurrentSelectedGrades(currentSelectedGrades.filter((i) => i !== addedValue.props.value));
                      // else currentSelectedGrades.push(addedValue.props.value)
                      // forceUpdate();

                      if (sc.Grades.includes(addedValue.props.value)) sc.Grades = sc.Grades = sc.Grades.filter((i) => i !== addedValue.props.value);
                      else sc.Grades = [...sc.Grades, addedValue.props.value]
                      forceUpdate();

                    }}
                  >
                    {props.gradesList?.map((s, index) => (
                      <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
                    ))}
                  </TextField> */}
                </Grid>
              </Grid>
              {sc.Grades?.length !== 0 && sc.Treatments?.length !== 0 ?
                <Grid item marginTop={4}
                  style={{
                    width: "100%",
                    overflowX: "auto"
                  }}>
                  <TableContainer sx={{ width: "max-content" }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0px 0px",
                        border: 0,
                      }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={styles.tableCellhead3}>
                            <>
                              <Grid
                                item
                                container
                                xs={11}
                                direction={"row-reverse"}
                              >
                                Sub2
                              </Grid>
                              <Grid item container xs={10}>
                                Sub1
                              </Grid>
                            </>
                          </TableCell>
                          {sc.Grades?.map((item, index) => (
                            <TableCell sx={styles.tableCellhead2} key={index}>
                              {/* {props.gradesList.find((t) => t.Name === item.Name)?.Name} */}
                              {item.Name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          sc.Treatments?.map((tr) => (
                            <StyledTableRow>
                              <TableCell sx={styles.tableCellhead}>{props.treatmentsList.find((t) => tr.TreatmentId ? t.Id === tr.TreatmentId : t.Name === tr.Name)?.Name}</TableCell>
                              {sc.Grades?.map((gr, index) => (
                                <TableCell sx={styles.tableCellhead2} key={index}>
                                  <Grid item container direction="column" alignItems="center" rowGap={1}>
                                    <Grid item className={
                                      tr.Grades?.find((g) => (g.GradeId ? g.GradeId === gr.GradeId : g.Name === gr.Name))
                                        ? classes["CheckedBox"]
                                        : classes["notCheckedBox"]
                                    }
                                      onClick={() => {
                                        // console.log("gr", gr)
                                        // console.log("tr.Grades", tr.Grades)

                                        if (tr.Grades?.find((g) => g.Name === gr.Name)) tr.Grades = tr.Grades.filter((i) => i.Name !== gr.Name);
                                        else {
                                          let newGradeObject = {
                                            Id: null,
                                            GradeId: gr.GradeId,
                                            GradeName: gr.GradeName,
                                            Name: gr.Name,
                                            ItemSeries: generateItemSeries(sc.SubCategoryId, tr, gr)
                                          }
                                          // console.log("newGradeObject", newGradeObject)
                                          tr.Grades = [...tr.Grades, newGradeObject]
                                        }
                                        forceUpdate();
                                      }}
                                    ></Grid>
                                    <Grid item sx={styles.tfInput} textAlign="center">
                                      {generateItemSeries(sc.SubCategoryId, tr, gr)}
                                    </Grid>

                                  </Grid>
                                </TableCell>
                              ))}
                            </StyledTableRow>
                          ))
                        }

                      </TableBody>

                    </Table>

                  </TableContainer>

                </Grid>
                :
                <></>}

            </Grid>
          ))
        }

        <Grid item container direction="row" xs={12} justifyContent="flex-end" columnGap={1}>
          <Grid item>
            <button
              disabled={props.savingProducts}
              className={classes["save-button"]}
              onClick={() => {
                cleanupProductSetups();
                // console.log(props.inputSetups)
              }}
            >
              {props.savingProducts ? "Saving ..." : "Save"}
            </button>
          </Grid>
          {/* {props.initialInputSetups.length !== 0 ?
            <Grid item>
              <button
                disabled={props.savingProducts}
                className={classes["save-button"]}
                onClick={() => {
                  props.setProductSectionNb(2)
                }}
              >
                Next
              </button>
            </Grid>
            :
            <></>
          } */}


        </Grid>

      </Grid>
    )

  }

  const generateItemSeries = (subcategoryId, treatment, grade) => {
    const brandName = props.suppliersList?.find((s) => s.Id === inputSelectedObject.SupplierId)?.Brands?.find((b) => b.Id === inputSelectedObject.BrandId)?.Name;
    const categoryName = props.productTypes?.find((p) => p.Id === inputSelectedObject.ItemTypeId)?.Name;
    const subcategoryName = props.productTypes?.find((p) => p.Id === inputSelectedObject.ItemTypeId)?.SubCategories?.find((sub) => sub.Id === subcategoryId)?.Name;
    const treatmentName = treatment.TreatmentId ? props.treatmentsList.find((t) => t.Id === treatment.TreatmentId)?.Name : treatment.Name
    const gradeName = grade.GradeId ? props.gradesList.find((t) => t.Id === grade.GradeId)?.Name : grade.Name

    // const gradeName = props.gradesList.find((g) => g.Id === gradeId)?.Name;
    // console.log("gradeId", grade.GradeId? props.gradesList.find((t) => t.Id === grade.GradeId)?.Name:"nn")
    // console.log("treatmentId", treatmentId)
    // console.log("treatmentName",treatmentName)
    // console.log("props.treatmentsList", props.treatmentsList)


    // console.log("gradesLis", props.gradesList)
    return brandName + " " + categoryName + " " + subcategoryName + " " + treatmentName + " " + gradeName;

  }

  return (
    <Grid item container rowGap={3}>
      {/* {!props.loadingProductData ?
        <> */}
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={props.loadingProductData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item container direction="column" xs={12} sm={3}>
        <Grid item className={classes["Title"]}>
          Inputs Setups &nbsp;
          <Tooltip title="Click to add new setup">
            <img alt="Add" src={plusYellow} style={{ cursor: "pointer" }}
              onClick={() => {
                // props.setInputSetups(prevArray => {
                //   const newArray = [...prevArray]; // Create a shallow copy of the array
                //   newArray[inputSelected] = inputSelectedObject; // Update the object at the specified index
                //   return newArray; // Return the new array
                // });
                setSelectedProductSubcategoriesList([]);
                setSelectedTreatmentsList([]);
                setSelectedGradesList([])
                props.inputSetups.push(inputObject)
                // console.log("addd", props.inputSetups)
                setInputSelectedObject(props.inputSetups[props.inputSetups?.length - 1])
                setInputSelected(props.inputSetups?.length - 1)
              }} />
          </Tooltip>
        </Grid>
        <Grid item>
          <TextField
            select
            value={inputSelected}
            fullWidth
            InputProps={{
              style: {
                color: "#0674b9",
                height: "35px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            SelectProps={{
              MenuProps: {
                sx: { height: "150px" },
                disableScrollLock: true, sx: { height: "150px" },

              },
            }}
            onChange={(e) => {
              // props.setInputSetups(prevArray => {
              //   const newArray = [...prevArray]; // Create a shallow copy of the array
              //   newArray[inputSelected] = inputSelectedObject; // Update the object at the specified index
              //   return newArray; // Return the new array
              // });
              setInputSelected(e.target.value);
              const objectToView = props.inputSetups[e.target.value];
              setInputSelectedObject(objectToView);
              // console.log("objectTo", objectToView)

              // console.log("inputt sleecetd objectt", props.inputSetups[e.target.value])


            }}
          >
            {props.inputSetups?.map((i, index) => (
              <MenuItem key={index} value={index}>Input Setup #{index + 1}</MenuItem>

            ))}
          </TextField>
        </Grid>
      </Grid>


      {inputSelectedObject ?
        renderInputSetupObject() :
        <></>
      }

      {/* </>
        :
        <></>
      } */}


    </Grid>

  )
}


const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 400,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 40,
  },
  tableCellhead3: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    background:
      "linear-gradient(to top right, #fff calc(50% - 1px), rgba(206, 210, 225, 0.97) , #fff calc(50% + 1px))",
  },
  tableCellhead2: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
  },
  tfInput: {
    color: "#707070",
    height: "28px",
    fontSize: 12,
    fontFamily: "Muli",
  },
};

export default ProductFirstSection