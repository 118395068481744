import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

import { styled } from "@mui/material/styles";
import { MenuItem, IconButton } from "@mui/material";

import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      height: "40px",
    },
  },
});

function Form2({
  continues,
  back,
  specialities,
  employeesRanges,
  countries,
  info,
  industryType,
  engineeringServices,

  disciplineCovered,
  trade,
}) {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [address, setAddress] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [trades, setTrades] = useState("");
  const [subTypesData, setSubTypesData] = useState([]);

  const [AddressError, setAddressError] = useState(false);
  const [AddressInvalid, setAddressInvalid] = useState(false);
  const [disciplineError, setDisciplineError] = useState(false);
  const [disciplineInvalid, setDisciplineInvalid] = useState(false);
  const [tradesError, setTradesError] = useState(false);
  const [tradesInvalid, setTradesInvalid] = useState(false);
  const [industry, setIndustry] = useState("");
  const [industryError, setIndustryError] = useState(false);
  const [industryInvalid, setIndustryInvalid] = useState(false);

  const [subIndustryInvalid, setSubIndustryInvalid] = useState(false);

  const [numOfEmployees, setNumOfEmployees] = useState("");
  const [numOfEmployeesError, setNumOfEmployeesError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [servicesField, setServicesField] = useState(info.ShowSubIndustryType);

  const [isoCodes, setisoCodes] = useState([]);
  const [fulled, setFulled] = useState(false);
  useEffect(() => {
    if (
      info.CompanyPhoneNumber === "+961" ||
      info.SpecialityId === "" ||
      info.Address === "" ||
      info.EmployeesRangeId === "" ||
      info.DisciplineCoveredId === "" ||
      info.TradedId === "" ||
      info.IndustryTypeId === "" ||
      industryError === true ||
      countryError === true ||
      AddressError === true ||
      AddressInvalid === true ||
      tradesError === true ||
      tradesInvalid === true ||
      disciplineError === true ||
      disciplineInvalid === true ||
      industryInvalid === true ||
      subIndustryInvalid === true ||
      numOfEmployeesError === true ||
      (info.ShowIndustryTypeOther === true && info.IndustryTypeOther === "") ||
      (info.ShowSubIndustryType === true && info.SubIndustryTypeId === "") ||
      (info.ShowDisciplineCoveredOther === true &&
        info.DisciplineCoveredOther === "") ||
      (info.ShowTradeOther === true && info.TradeOther === "")
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    for (var i = 0; i < countries.length; i++) {
      isoCodes[i] = countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });
  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox">
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12} direction={"row-reverse"}>
          <IconButton
            onClick={(e) => {
              navigate("/");
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              size="small"
              label="Industry Type"
              className="textField"
              value={info.IndustryTypeId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={industryError}
              helperText={industryError ? "Select an Industry Type" : ""}
              onChange={(e) => {
                setIndustry(e.target.value);
                info.IndustryTypeId = e.target.value;
                if (info.IndustryTypeId === "") {
                  setIndustryError(true);
                } else if (
                  e.target.value ===
                  industryType.find((x) => x.Name === "Others").Id
                ) {
                  info.ShowIndustryTypeOther = true;
                  info.ShowSubIndustryType = false;

                  setIndustry("");
                  setServicesField(false);
                  setIndustryInvalid(true);
                } else if (
                  industryType.find((x) => x.Id === e.target.value)
                    ?.SubIndustryTypes.length > 0
                ) {
                  setSubTypesData(
                    industryType.find((x) => x.Id === e.target.value)
                      .SubIndustryTypes
                  );
                  info.subTypesData = industryType.find(
                    (x) => x.Id === e.target.value
                  ).SubIndustryTypes;
                  info.ShowSubIndustryType = true;
                  info.ShowIndustryTypeOther = false;
                  info.IndustryTypeOther = "";

                  setSubIndustryInvalid(true);
                  setServicesField(true);
                  setIndustry("");
                } else {
                  setIndustryError(false);
                  setServicesField(false);
                  setIndustryInvalid(false);
                  setSubIndustryInvalid(false);
                  info.SubIndustryTypeId = null;
                  info.IndustryTypeOther = "";
                  info.ShowSubIndustryType = false;
                  info.ShowIndustryTypeOther = false;
                }
              }}
            >
              {industryType.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {info.ShowIndustryTypeOther ? (
            <Grid item>
              <CssTextField
                label="Please Enter Your Industry Type"
                size="small"
                inputProps={{ maxLength: 64 }}
                className="textField"
                value={info.IndustryTypeOther}
                InputLabelProps={{
                  style: {
                    fontFamily: "Muli",
                    fontSize: "16px",
                  },
                }}
                error={industryError}
                helperText={industryError ? "please enter your industry" : ""}
                onChange={(e) => {
                  setIndustry(e.target.value);
                  info.IndustryTypeOther = e.target.value;
                  if (info.IndustryTypeOther === "") {
                    setIndustryError(true);
                  } else {
                    setIndustryInvalid(false);
                    setIndustryError(false);
                    info.ShowIndustryTypeOther = true;
                  }
                }}
              />
            </Grid>
          ) : null}
          {info.ShowSubIndustryType ? (
            <Grid item>
              <CssTextField
                size="small"
                label="Engineering services"
                className="textField"
                value={info.SubIndustryTypeId}
                InputLabelProps={{
                  style: {
                    fontFamily: "Muli",
                    fontSize: "16px",
                  },
                }}
                select
                error={industryError}
                helperText={industryError ? "Select a Service" : ""}
                onChange={(e) => {
                  setIndustry(e.target.value);
                  info.SubIndustryTypeId = e.target.value;
                  if (info.SubIndustryTypeId === "") {
                    setIndustryError(true);
                  } else {
                    setSubIndustryInvalid(false);
                    setIndustryError(false);

                    info.ShowSubIndustryType = true;
                  }
                }}
              >
                {info.subTypesData.map((option) => (
                  <MenuItem value={option.Id} key={option.Id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </CssTextField>
            </Grid>
          ) : null}
          <Grid item>
            <CssTextField
              size="small"
              label="Discipline Covered"
              className="textField"
              value={info.DisciplineCoveredId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={disciplineError}
              helperText={disciplineError ? "Select a Discipline" : ""}
              onChange={(e) => {
                setDiscipline(e.target.value);
                info.DisciplineCoveredId = e.target.value;
                if (info.DisciplineCoveredId === "") {
                  setDisciplineError(true);
                } else if (
                  info.DisciplineCoveredId ===
                  disciplineCovered.find((x) => x.Name === "Others").Id
                ) {
                  setDisciplineInvalid(true);
                  info.ShowDisciplineCoveredOther = true;

                  setDiscipline("");
                } else {
                  setDisciplineError(false);

                  setDisciplineInvalid(false);
                  info.DisciplineCoveredOther = "";
                  info.ShowDisciplineCoveredOther = false;
                }
              }}
            >
              {disciplineCovered.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {info.ShowDisciplineCoveredOther ? (
            <Grid item>
              <CssTextField
                label="Please Enter your Discipline"
                size="small"
                inputProps={{ maxLength: 64 }}
                className="textField"
                value={info.DisciplineCoveredOther}
                InputLabelProps={{
                  style: {
                    fontFamily: "Muli",
                    fontSize: "16px",
                  },
                }}
                error={disciplineError}
                helperText={disciplineError ? "please enter a Discipline" : ""}
                onChange={(e) => {
                  setDiscipline(e.target.value);
                  info.DisciplineCoveredOther = e.target.value;
                  if (info.DisciplineCoveredOther === "") {
                    setDisciplineError(true);
                  } else {
                    setDisciplineError(false);
                    setDisciplineInvalid(false);
                    info.ShowDisciplineCoveredOther = true;
                  }
                }}
              />
            </Grid>
          ) : null}
          <Grid item>
            <CssTextField
              size="small"
              label="Trades"
              className="textField"
              value={info.TradedId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={tradesError}
              helperText={tradesError ? "Select a trade" : ""}
              onChange={(e) => {
                setTrades(e.target.value);
                info.TradedId = e.target.value;
                if (info.TradedId === "") {
                  setTradesError(true);
                } else if (info.TradedId === 5) {
                  setTradesInvalid(true);
                  info.ShowTradeOther = true;

                  setTrades("");
                } else {
                  setTradesError(false);

                  setTradesInvalid(false);
                  info.TradeOther = "";
                  info.ShowTradeOther = false;
                }
              }}
            >
              {trade.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {info.ShowTradeOther ? (
            <Grid item>
              <CssTextField
                label="Please Enter your Trade"
                size="small"
                className="textField"
                value={info.TradeOther}
                inputProps={{ maxLength: 64 }}
                InputLabelProps={{
                  style: {
                    fontFamily: "Muli",
                    fontSize: "16px",
                  },
                }}
                error={tradesError}
                helperText={tradesError ? "please enter a speciality" : ""}
                onChange={(e) => {
                  setTrades(e.target.value);
                  info.TradeOther = e.target.value;
                  if (info.TradeOther === "") {
                    setTradesError(true);
                  } else {
                    setTradesError(false);
                    setTradesInvalid(false);
                    info.ShowTradeOther = true;
                  }
                }}
              />
            </Grid>
          ) : null}
          <Grid item>
            <CssTextField
              size="small"
              label="Employees"
              className="textField"
              value={info.EmployeesRangeId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={numOfEmployeesError}
              helperText={numOfEmployeesError ? "Select a country" : ""}
              onChange={(e) => {
                setNumOfEmployees(e.target.value);
                info.EmployeesRangeId = e.target.value;
                if (info.EmployeesRangeId === "") {
                  setNumOfEmployeesError(true);
                } else {
                  setNumOfEmployeesError(false);
                }
              }}
            >
              {employeesRanges.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid item>
            <CssTextField
              label="Address"
              size="small"
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={AddressError}
              value={info.Address}
              helperText={
                AddressError
                  ? AddressInvalid
                    ? "Adddress  must be less than 150 characters"
                    : "Enter Company Address"
                  : ""
              }
              onChange={(e) => {
                setAddress(e.target.value);
                info.Address = e.target.value;
                if (info.Address === "") {
                  setAddressError(true);
                  setAddressInvalid(false);
                } else if ((info.Address !== "") & (info.Address.length > 50)) {
                  setAddressError(true);
                  setAddressInvalid(true);
                } else {
                  setAddressError(false);
                  setAddressInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item height={"5%"}>
            {fulled ? (
              <>
                <PhoneInput
                  placeholder="Enter phone number"
                  onlyCountries={isoCodes}
                  enableAreaCodes={true}
                  value={info.CompanyPhoneNumber}
                  onChange={(phone) => {
                    setCountry(phone);
                    info.CompanyPhoneNumber = phone;
                    if (
                      info.CompanyPhoneNumber === "" ||
                      info.CompanyPhoneNumber.length < 4 ||
                      info.CompanyPhoneNumber.length > 20
                    ) {
                      setCountryError(true);
                    } else {
                      setCountryError(false);
                    }
                  }}
                  country={localStorage.getItem("userCountry")?.toLowerCase()}
                  inputStyle={{
                    borderColor: "#707070",
                    width: "100%",
                    height: 40,
                    fontFamily: "Muli",
                    fontsize: "16px",
                  }}
                />
                {countryError ? (
                  <span
                    style={{
                      fontFamily: "Muli",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    Enter a valid Phone Number
                  </span>
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"}>
          <Grid item md={8} xs={8}>
            <Button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#CED2E1";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
              }}
              onClick={(e) => {
                back(e);
                e.target.style.backgroundColor = "#fff";
                e.target.style.cursor = "pointer";
              }}
              variant="contained"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                width: "71px",
                height: "32px",
                fontSize: "19px",
                fontFamily: "Muli",
                backgroundColor: "#fff",
                color: "rgba(16, 22, 80, 0.7)",
                border: "0px",
                marginBottom: 4,
              }}
            >
              Back
            </Button>
            <button
              className="buttonNext"
              disabled={isDisabled}
              style={{ marginLeft: 20 }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                continues(e);
                e.target.style.cursor = "pointer";
              }}
              variant="contained"
            >
              Next
            </button>
          </Grid>
          <Grid item md={4} xs={4} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 2 of 3</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form2;
