import React, { useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import PlusRow from "../../../../Assets/Images/Factory/plusRow.png";
import RemoveIcon from "../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import * as BLOCKCONSTS from "../../../../../FactoryConsts";
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);
const CreateForm = (props) => {
  const [codeErrMessage, setCodeErrMessage] = useState("");
  const [expand, setExpand] = useState(false);
  const [lineId, setLineId] = useState();
  const navigate = useNavigate();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [test, setTest] = useState([
    {
      Dia: 8,
      Lengths: [
        {
          Name: 7000,
          Qty: 2.1
        },
        {
          Name: 8000,
          Qty: 3
        }
      ]
    }
  ])

  return (
    <Grid
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={3}
      gap={2}
      paddingBottom={1}
    >
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Code
        </Grid>
        <Grid item>
          <TextField
            value={props.code}
            error={props.codeError}
            helperText={props.codeError ? codeErrMessage : ""}
            fullWidth
            disabled
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Code"
            onBlur={(e) => {
              if (!e.target.value) {
                props.setCodeError(true);
                setCodeErrMessage("Enter a code ");
              }
            }}
            onChange={(e) => {
              props.setCode(e.target.value);
              if (!e.target.value) {
                props.setCodeError(true);
                setCodeErrMessage("Enter a Code");
              } else if (e.target.value.length > 10) {
                props.setCodeError(true);
                setCodeErrMessage("Code should be less than 10 characters");
              } else props.setCodeError(false);
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Name
        </Grid>
        <Grid item>
          <TextField
            value={props.name}
            disabled={props.disabledEdit}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            onChange={(e) => {
              props.setName(e.target.value);
            }}
          >
          </TextField>
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Category
        </Grid>
        <Grid item>
          <TextField
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={props.category}
            disabled={props.disabledEdit}
            fullWidth
            select
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            onChange={(e) => {
              props.setCategory(e.target.value);
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Category
            </MenuItem>
            {props.categories?.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {props.category && props.category !== 4 && props.category !== 5 ? (
        <Grid item sm={3.5} xs={12}>
          <Grid item className={classing["TFTitle"]} paddingLeft={1}>
            *Type
          </Grid>
          <Grid item>
            <TextField
              disabled={props.disabledEdit}
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              value={props.type}
              fullWidth
              select
              InputProps={{
                style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
              }}
              onChange={(e) => {
                props.setType(e.target.value);
              }}
            >
              <MenuItem value={0} key={0} disabled>
                Type
              </MenuItem>
              {props.categories
                ?.find((x) => x.Id === props.category)
                ?.Types?.map((r) => (
                  <MenuItem
                    value={r.Id}
                    key={r.Id}
                    disabled={
                      props.category === 5 && (r.Id === 14 || r.Id === 15)
                        ? true
                        : false
                    }
                  >
                    {r.Name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <Grid item sm={2.3} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Block Status
        </Grid>
        <Grid item className={classing["Rectangle-3005"]}>
          {props.status === BLOCKCONSTS.ACTIVE_ID
            ? BLOCKCONSTS.ACTIVE
            : props.status === BLOCKCONSTS.ONHOLD_ID
              ? BLOCKCONSTS.ONHOLD
              : props.status === BLOCKCONSTS.INACTIVE_ID
                ? BLOCKCONSTS.INACTIVE
                : props.status === BLOCKCONSTS.ARCHIVED_ID
                  ? BLOCKCONSTS.ARCHIVED
                  : ""}
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Main Characteristic Values
        </Grid>
        <Grid item className={classing["Rectangle-3005"]} overflow="auto">
          {props.mainCharacteristiques}
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          No. of Zones
        </Grid>
        <Grid item className={classing["Rectangle-3005"]}>
          {props.nbOfZones}
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          No. of Monitored Resources
        </Grid>
        <Grid item className={classing["Rectangle-3005"]}>
          {props.nbOfResources}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} paddingTop={1} className={classing["Zones"]}>
          Zones
        </Grid>
        <Grid item xs={12} className={classing["plus-hint"]}>
          Press Plus icon to add new zone
        </Grid>
        <Grid item overflow={"auto"}>
          <TableContainer sx={{ width: "100%" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0px 0px",
                border: 0,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableCellEmp}></TableCell>
                  <TableCell sx={styles.tableCell}>*Code</TableCell>
                  <TableCell sx={styles.tableCell}>Diameters</TableCell>
                  <TableCell sx={styles.tablebig}>
                    *Dimensions (L, W, H) in (m)
                  </TableCell>
                  <TableCell sx={styles.tableCell}>Types </TableCell>
                  <TableCell sx={styles.tableCell}>*Maximum Capacity</TableCell>

                  <TableCell sx={styles.tableCell}>Occupied Capacity</TableCell>
                  <TableCell sx={styles.tableCell}>
                    Capacity unit of measure
                  </TableCell>
                  <TableCell sx={styles.tableCellEmp}></TableCell>
                </TableRow>
              </TableHead>

              {props.zones.map((zone, index) => (
                <TableBody key={zone.Id}>
                  <StyledTableRow>
                    <Grid item container>
                      {expand && lineId === index ? (
                        <Grid
                          item
                          marginTop={1.5}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            setExpand(false);
                          }}
                        >
                          <ExpandLessIcon
                            fontSize="medium"
                            htmlColor="#0674b9"
                          />
                          <button
                            disabled={
                              zone.Id &&
                                !props.disabledViewStock &&
                                !localStorage
                                  ?.getItem("planIdManu")
                                  ?.includes("Core")
                                ? false
                                : true
                            }
                            className={classing["ViewMore"]}
                            onClick={() => {
                              if (
                                props.category === 1 ||
                                props.category === 2
                              ) {
                                navigate(
                                  `/saas/factory/manageRM?id=${zone.Id}&categoryId=${props.category}&factoryId=${props.factoryId}&bayId=${props.BAY_ID}`
                                );
                              } else if (props.category === 3) {
                                navigate(
                                  `/saas/factory/manageProduct?id=${zone.Id}&categoryId=${props.category}&factoryId=${props.factoryId}&bayId=${props.BAY_ID}&blockCode=${props.code}&zoneCode=${zone.Code}`
                                );
                              } else if (
                                props.category === 4 ||
                                props.category === 5
                              ) {
                                navigate(
                                  `/saas/factory/ManageFGS?id=${zone.Id}&categoryId=${props.category}&factoryId=${props.factoryId}&bayId=${props.BAY_ID}&blockCode=${props.code}&zoneCode=${zone.Code}`
                                );
                              }
                            }}
                          >
                            View Stock
                          </button>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          marginTop={1.5}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            setExpand(true);
                            setLineId(index);
                          }}
                        >
                          <ExpandMoreIcon
                            fontSize="medium"
                            htmlColor="#0674b9"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <TableCell sx={styles.tableCell}>
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        value={zone.Code}
                        sx={styles.tfInput}
                        onChange={(e) => {
                          zone.Code = e.target.value;
                          forceUpdate();
                        }}
                        placeholder="Code"
                        InputProps={{
                          inputProps: { maxLength: 10 },
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      />
                    </TableCell>
                    {/* <TableCell sx={styles.tableCell}>
                      {zone?.Diameters?.map((dia) => {
                        return dia.Name + " ";
                      })}
                    </TableCell> */}
                    <TableCell sx={styles.tableCell}>
                      {/* {zone?.Diameters.split(',')?.map((dia) => {
                        return dia+ " ";
                      })} */}
                      <Grid item textAlign="center">
                        {zone?.Diameters?.map((dia, index) => {
                          if (index === zone.Diameters.length - 1) return dia.Diameter
                          else return dia.Diameter + " , "
                        })}
                      </Grid>
                    </TableCell>
                    <TableCell sx={styles.tableCellMul}>
                      <TableCell sx={styles.tableCellSmall}>
                        <Grid item width={50}>
                          <TextField
                            variant="standard" // <== changed this
                            margin="normal"
                            fullWidth
                            disabled={props.disabledEdit}
                            value={zone.Length}
                            sx={styles.tfInput}
                            onChange={(e) => {
                              zone.Length = e.target.value;
                              forceUpdate();
                            }}
                            type={"number"}
                            placeholder="No."
                            InputProps={{
                              inputProps: { min: 0 },
                              disableUnderline: true,
                              style: styles.tfInput,
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellSmall}>
                        <Grid item width={50}>
                          <TextField
                            variant="standard" // <== changed this
                            margin="normal"
                            fullWidth
                            disabled={props.disabledEdit}
                            value={zone.Width}
                            sx={styles.tfInput}
                            onChange={(e) => {
                              zone.Width = e.target.value;
                              forceUpdate();
                            }}
                            type={"number"}
                            placeholder="No."
                            InputProps={{
                              inputProps: { min: 0 },
                              disableUnderline: true,
                              style: styles.tfInput,
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellSmall}>
                        <Grid item width={50}>
                          <TextField
                            disabled={props.disabledEdit}
                            variant="standard" // <== changed this
                            margin="normal"
                            fullWidth
                            value={zone.Height}
                            sx={styles.tfInput}
                            onChange={(e) => {
                              zone.Height = e.target.value;
                              forceUpdate();
                            }}
                            type={"number"}
                            placeholder="No."
                            InputProps={{
                              inputProps: { min: 0 },
                              disableUnderline: true,
                              style: styles.tfInput,
                            }}
                          />
                        </Grid>
                      </TableCell>
                    </TableCell>
                    <TableCell sx={styles.tableCellDisable}>
                      {props.type === 1 ||
                        props.type === 4 ||
                        props.type === 10 ||
                        props.type === 13
                        ? "Bar"
                        : props.type === 2 ||
                          props.type === 5 ||
                          props.type === 11 ||
                          props.type === 14
                          ? "Coil"
                          : props.type === 3 ||
                            props.type === 6 ||
                            props.type === 12 ||
                            props.type === 15
                            ? "Decoiled Product"
                            : props.type === 7
                              ? "Coupler"
                              : props.type === 8
                                ? "End Anchor"
                                : props.type === 9
                                  ? "Sling"
                                  : ""}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Grid item width={100}>
                        <TextField
                          disabled={props.disabledEdit}
                          variant="standard" // <== changed this
                          margin="normal"
                          fullWidth
                          value={zone.CapacityPerItem / 1000}
                          sx={styles.tfInput}
                          onChange={(e) => {
                            zone.CapacityPerItem = e.target.value * 1000;
                            forceUpdate();
                          }}
                          type={"number"}
                          placeholder="No."
                          InputProps={{
                            inputProps: { min: 0 },
                            disableUnderline: true,
                            style: styles.tfInput,
                          }}
                        />
                      </Grid>
                    </TableCell>

                    <TableCell sx={styles.tableCellDisable}>
                      {/* {zone.OccupiedCapacity
                        ? props.category === 3
                          ? zone.OccupiedCapacity.toFixed(3)
                          : (zone.OccupiedCapacity / 1000).toFixed(3)
                        : 0} */}
                      {zone.OccupiedCapacity
                        ? props.category === 3 ? zone.OccupiedCapacity : (zone.OccupiedCapacity / 1000).toFixed(3)
                        : 0}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Grid item width={115}>
                        <TextField
                          disabled={props.disabledEdit}
                          variant="standard" // <== changed this
                          margin="normal"
                          fullWidth
                          value={zone.CapacityMeasureUnitId}
                          sx={styles.tfInput}
                          onChange={(e) => {
                            zone.CapacityMeasureUnitId = e.target.value;
                            forceUpdate();
                          }}
                          select
                          placeholder="No."
                          SelectProps={{
                            MenuProps: { disableScrollLock: true },
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            disableUnderline: true,
                            style: styles.tfInput,
                          }}
                        >
                          <MenuItem value={0} key={0} disabled>
                            Unit Measure
                          </MenuItem>
                          {props.unitMeasures.map((r) => (
                            <MenuItem
                              value={r.Id}
                              key={r.Id}
                              disabled={
                                (props.category === 1 ||
                                  props.category === 2 ||
                                  props.category === 4 ||
                                  props.category === 5) &&
                                  (r.Id === 2 || r.Id === 3)
                                  ? true
                                  : false
                              }
                            >
                              {r.Name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </TableCell>
                    {zone.OccupiedCapacity === 0 ||
                      zone.OccupiedCapacity === null ? (
                      <Grid
                        item
                        paddingTop={2.8}
                        paddingLeft={0.8}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          if (props.zones.length > 1) {
                            props.setZones(
                              props.zones.filter(
                                (x) => x.OrderId !== zone.OrderId
                              )
                            );
                            props.setNbOfZones(props.nbOfZones - 1);
                          }
                        }}
                      >
                        <img src={RemoveIcon} width={15} alt="plus" />
                      </Grid>
                    ) : (
                      <BlueTip
                        arrow
                        title={
                          <Grid item container paddingTop={1} paddingLeft={2}>
                            <Grid
                              item
                              fontFamily={"Muli"}
                              fontSize={18}
                              color={"#fff"}
                            >
                              Error
                            </Grid>
                            <Grid
                              item
                              fontFamily={"Muli"}
                              fontSize={13}
                              color={"#fff"}
                              xs={11}
                              paddingTop={2}
                            >
                              You can't delete this zone because the occupied
                              capacity is not zero.
                            </Grid>
                          </Grid>
                        }
                        sx={{
                          "& .MuiTooltip-arrow": {
                            top: "5px !important",
                            "&::before": {
                              backgroundColor: "#101650",
                            },
                          },
                        }}
                        interactive="true"
                        placement={"right"}
                      >
                        <Grid
                          item
                          paddingTop={2.8}
                          paddingLeft={0.8}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                        >
                          <img src={RemoveIcon} width={15} alt="plus" />
                        </Grid>
                      </BlueTip>
                    )}
                  </StyledTableRow>
                  {zone?.Diameters?.map((dia) => (
                    dia.Lengths.map((l) => (
                      <StyledTableRow>
                        <Grid item container>
                        </Grid>
                        <TableCell sx={styles.tableCell}>
                        </TableCell>
                        <TableCell sx={styles.tableCell} style={{ backgroundColor: "#f8f6f6" }}>
                          {/* {zone?.Diameters.split(',')?.map((dia) => {
                        return dia+ " ";
                      })} */}
                          <Grid item textAlign="center" style={{
                            boxShadow: "0 6px 12px 0 rgba(0, 0, 0, 0.16)",
                            padding: 2,
                            borderRadius: 5
                          }}> {dia?.Diameter} ; {l.Length} <br /> Qty: {l.Quantity}</Grid>

                        </TableCell>
                        <TableCell sx={styles.tableCellMul}>
                          <TableCell sx={styles.tableCellSmall} style={{ backgroundColor: "#f8f6f6" }}>
                            <Grid item width={50}>
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                disabled
                                value={zone.Length}
                                sx={styles.tfInput}
                                onChange={(e) => {
                                  zone.Length = e.target.value;
                                  forceUpdate();
                                }}
                                type={"number"}
                                placeholder="No."
                                InputProps={{
                                  inputProps: { min: 0 },
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              />
                            </Grid>
                          </TableCell>
                          <TableCell sx={styles.tableCellSmall} style={{ backgroundColor: "#f8f6f6" }}>
                            <Grid item width={50}>
                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                disabled
                                value={zone.Width}
                                sx={styles.tfInput}
                                onChange={(e) => {
                                  zone.Width = e.target.value;
                                  forceUpdate();
                                }}
                                type={"number"}
                                placeholder="No."
                                InputProps={{
                                  inputProps: { min: 0 },
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              />
                            </Grid>
                          </TableCell>
                          <TableCell sx={styles.tableCellSmall} style={{ backgroundColor: "#f8f6f6" }}>
                            <Grid item width={50}>
                              <TextField
                                disabled
                                variant="standard" // <== changed this
                                margin="normal"
                                fullWidth
                                value={zone.Height}
                                sx={styles.tfInput}
                                onChange={(e) => {
                                  zone.Height = e.target.value;
                                  forceUpdate();
                                }}
                                type={"number"}
                                placeholder="No."
                                InputProps={{
                                  inputProps: { min: 0 },
                                  disableUnderline: true,
                                  style: styles.tfInput,
                                }}
                              />
                            </Grid>
                          </TableCell>
                        </TableCell>
                        <TableCell sx={styles.tableCellDisable} style={{ backgroundColor: "#f8f6f6" }}>
                          {props.type === 1 ||
                            props.type === 4 ||
                            props.type === 10 ||
                            props.type === 13
                            ? "Bar"
                            : props.type === 2 ||
                              props.type === 5 ||
                              props.type === 11 ||
                              props.type === 14
                              ? "Coil"
                              : props.type === 3 ||
                                props.type === 6 ||
                                props.type === 12 ||
                                props.type === 15
                                ? "Decoiled Product"
                                : props.type === 7
                                  ? "Coupler"
                                  : props.type === 8
                                    ? "End Anchor"
                                    : props.type === 9
                                      ? "Sling"
                                      : ""}
                        </TableCell>
                        <TableCell sx={styles.tableCell} style={{ backgroundColor: "#f8f6f6" }}>
                          <Grid item width={100} className={styles.tfInput}>
                            {<TextField
                              disabled
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              value={zone.CapacityPerItem / 1000}
                              sx={styles.tfInput}
                              onChange={(e) => {
                                zone.CapacityPerItem = e.target.value * 1000;
                                forceUpdate();
                              }}
                              type={"number"}
                              placeholder="No."
                              InputProps={{
                                inputProps: { min: 0 },
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            />}
                          </Grid>
                        </TableCell>

                        <TableCell sx={styles.tableCellDisable} style={{ backgroundColor: "#f8f6f6" }}>
                          {props.category === 3 ? l.Weight : (l.Weight / 1000).toFixed(3)
                          }
                          {/* {l.Weight / 1000} */}
                        </TableCell>
                        <TableCell sx={styles.tableCell} style={{ backgroundColor: "#f8f6f6" }}>
                          <Grid item width={115}>
                            <TextField
                              disabled
                              variant="standard" // <== changed this
                              margin="normal"
                              fullWidth
                              value={zone.CapacityMeasureUnitId}
                              sx={styles.tfInput}
                              onChange={(e) => {
                                zone.CapacityMeasureUnitId = e.target.value;
                                forceUpdate();
                              }}
                              select
                              placeholder="No."
                              SelectProps={{
                                MenuProps: { disableScrollLock: true },
                              }}
                              InputProps={{
                                inputProps: { min: 0 },
                                disableUnderline: true,
                                style: styles.tfInput,
                              }}
                            >
                              <MenuItem value={0} key={0} disabled>
                                Unit Measure
                              </MenuItem>
                              {props.unitMeasures.map((r) => (
                                <MenuItem
                                  value={r.Id}
                                  key={r.Id}
                                  disabled={
                                    (props.category === 1 ||
                                      props.category === 2 ||
                                      props.category === 4 ||
                                      props.category === 5) &&
                                      (r.Id === 2 || r.Id === 3)
                                      ? true
                                      : false
                                  }
                                >
                                  {r.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </TableCell>

                      </StyledTableRow>
                    ))
                  ))}

                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          paddingTop={0.5}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            props.setZones(
              props.zones.concat({
                Id: null,
                OrderId: props.zones.length + 1,
                Code: "",
                Length: 0,
                Height: 0,
                Width: 0,
                InputType: "",
                CapacityPerItem: 0,
                CapacityMeasureUnitId: 0,
              })
            );
            props.setNbOfZones(props.nbOfZones + 1);
          }}
        >
          <img src={PlusRow} alt="plus" />
        </Grid>
      </Grid>
    </Grid>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px #e9e7e7",
    height: 37,
    width: 95,
  },
  tableCellEmp: {
    backgroundColor: "#fff",
    border: 0,
    width: 95,
  },
  tableCellMul: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    height: 37,
    padding: 0,
  },

  tableCellSmall: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    borderRight: "solid 1px #e9e7e7",
    width: 70,
    textAlign: "left",
    borderBottom: "0px",
    height: 37,
  },
  tablebig: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    width: 111,
  },
  tableCellDisable: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#2da4d0",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    paddingLeft: 2,
    height: 5,
  },
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
          width: 226px;
          height: 101px;
          padding: 5px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.36);
          background-color:#101650;
          
   
  `);

export default CreateForm;
