import { Grid, Modal } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import homeBg from '../../Assets/Images/Dashboard/logInBgColor.png';
import classes from "../../Assets/Styles/Dashboard/Item.module.css";
import classing from "../../Assets/Styles/Dashboard/Production.module.css";
import DashNavBar from './DashNavBar';
import * as services from '../../Services/machine-dashboard-services.proxy';
import ErrorModal from '../../SharedComponents/ErrorModal';

const updateUsedBundle = services.updateUsedBundle;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: "500px",
    transform: 'translate(-50%, -50%)',
    p: 4,
    borderRadius: "12px",
    border: "solid 1px #707070",
    backgroundColor: "#0674b9",
    outline: "none"
}

export default function Item(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let navigate = useNavigate();

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();
    const groupId = query.get("groupId");

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [error, setError] = useState("");
    const [confirming, setConfirming] = useState(false);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [BEerror, setBEerror] = useState(null);
    const [navigateToOnClose,setNavigateToOnClose] = useState("/machine/dashboard");


    useEffect(() => {
        if (!props.itemScanned) {
            setTimeout(() => {
                navigate(`/machine/scanItem?groupId=${groupId}`)
            }, 1000);
        }
    }, [props.itemScanned])

    return (
        <>
            <Grid
                sx={{
                    padding: '2% 3.5% 0 3.5%',
                    backgroundImage: `url(${homeBg})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingBottom: '10%',
                    minHeight: '100vh'
                }}>
                <DashNavBar />
                <Grid item xs={12} container paddingTop={{ xs: '7%', md: "5%", lg: "3%" }}>
                    <Grid item className={classes["Item-Label"]}>
                        Item Label
                    </Grid>

                    <Grid item container paddingTop={{ xs: "5%", md: "4%", lg: "2.7%" }} gap={{ xs: 1, sm: 1.7, md: 2.3, lg: 2.2, xl: 3 }}>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Item Tag No
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.TagNO}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Item Category
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Name}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Item Type
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Type}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Item Pieces
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.NumOfPieces}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Standard
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Standard}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Grade
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Grade}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Coating
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Coating}</span>

                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item container xs={12} gap={0.2} className={classes["Item-Tag-No"]}>
                                <Grid item xs={7} lg={5.9}>
                                    Dia (mm)
                                </Grid>
                                <Grid item xs={4.8} lg={5.9}>
                                    Lg (mm)
                                </Grid>

                            </Grid>

                            <Grid item container xs={12} gap={0.2} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <Grid item xs={7} lg={5.9}>
                                    <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Diameter}</span>
                                </Grid>
                                <Grid item xs={4.8} lg={5.9}>
                                    <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.Length}</span>

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Net Weight (Kg)
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{Number(props.itemScanned?.NetWeight)?.toFixed(3)}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Heat Number
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.HeatNumber}</span>
                            </Grid>
                        </Grid>

                        <Grid item xs={3.8} md={2.7} lg={2.5} className={classes["Rectangle-4017"]}>
                            <Grid item xs={12} className={classes["Item-Tag-No"]}>
                                Supplier Name
                            </Grid>

                            <Grid item xs={12} paddingTop={1.5} className={classes["bold-RM---1"]}>
                                <span style={{ overflowWrap: "break-word" }}>{props.itemScanned?.SupplierName}</span>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container paddingTop="5%" paddingBottom={{ xs: "10%", md: "7%" }} xs={11.4} sm={12} md={11.5} lg={11} gap={5} alignItems="center" justifyContent="flex-end">
                        <Grid item>
                            <button onClick={() => { navigate(-1); }} className={classes["cancel-button"]}> Cancel </button>
                        </Grid>

                        <Grid item>
                            <button
                                disabled={confirming}
                                onClick={() => {
                                    if (props.itemScanned?.BundleId)
                                        setConfirming(true);
                                    updateUsedBundle(props.itemScanned?.BundleId, groupId, null).then((x) => {
                                        setConfirming(false);
                                        // if (x.status) {
                                        //     setError(x.error);
                                        //     handleOpenModal();
                                        // }
                                        // else {
                                        if (x.status || x.statusCode) {
                                            if (x.status) setBEerror(x.error)
                                            if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                                            setOpenErrorModal(true);

                                        } else {
                                            navigate(`/machine/production?groupId=${groupId}`);
                                        }
                                    })
                                }}
                                className={classes["confirm-button"]}> {confirming ? "Confirming ..." : "Confirm"} </button>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Grid container sx={modalStyle} justifyContent="center" alignItems="center" gap={10}>
                        <Grid item className={classing["Do-you-want-to-reprint"]}>
                            {error}
                        </Grid>
                        <Grid item sx={{ fontFamily: 'Muli', fontWeight: 'bold', fontSize: 16, color: 'red', backgroundColor: '#fff', borderRadius: '3px', padding: '2%' }}>
                            Quantity of bars is less than required
                        </Grid>
                        <Grid item container justifyContent="center" direction="row" gap={3}>
                            <Grid item onClick={() => { handleCloseModal(); navigate(-1) }} className={classing["confirm-button"]}>
                                Go Back
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
            <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose} />
        </>
    )
}
