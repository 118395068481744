import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import logo from '../../Assets/Images/Dashboard/logo.png';
import loginBg from '../../Assets/Images/Dashboard/loginBg.png';
import barcode from '../../Assets/Images/Dashboard/barcode.png';
import denied from '../../Assets/Images/Dashboard/denied.png';
import approved from '../../Assets/Images/Dashboard/approved.png';
import { useNavigate } from 'react-router-dom';
import * as services from '../../Services/machine-dashboard-services.proxy';
import useScanDetection from 'use-scan-detection';

const getMachineById = services.getMachineById;
const humanResourceLogin = services.humanResourceLogin;

export default function MachineLogin() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [machine, setMachine] = useState(null)

    useEffect(() => {

        let apiTest = true;
        if (localStorage.getItem("machineId"))
            getMachineById(localStorage.getItem("machineId")).then((x) => {
                if (apiTest) {
                    setMachine(x);
                    localStorage.setItem("MachineCode",x.Code)
                }
            })
        return () => {
            apiTest = false;
        }
    }, [])

    const navigate = useNavigate();
    const [login, setLogin] = useState("");

    useScanDetection({
        onComplete: (code) => {
            humanResourceLogin(localStorage.getItem("machineId"), code.replace("Shift", "").replace("Enter", "")).then(x => {
                if (x.status) setLogin("denied")
                else {
                    setLogin("approved");
                    localStorage.setItem("companyName", x)
                    setTimeout(() => {
                        navigate('/machine/connect')
                    }, 1000);
                };

            })
        },
        minLength: 3
    })


    return (
        <Grid
            padding='3% 0 0 5%'
            sx={{
                backgroundImage: `url(${loginBg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                paddingBottom: '10%',
                height: '100vh'
            }}>
            <Grid item xs={12} container justifyContent='space-between'>
                <Grid item><img src={logo} alt="logo" /></Grid>
                <Grid item marginRight='15%'
                    onMouseOver={e => e.target.style.cursor = 'pointer'}
                    onClick={() => {
                        localStorage.clear();
                        navigate('/iotLogin');
                    }}>IOT Logout</Grid>
            </Grid>

            <Grid item xs={12} container alignItems='flex-start' justifyContent='center' paddingTop={{ xs: '10%', md: '4%' }} gap={2}>
                <Grid item xs={12} md={5} container alignItems='center' justifyContent='center'
                    paddingLeft={{ xs: '18%', md: 0, lg: 0 }}
                    gap={2}>
                    <Grid item xs={12} sx={{
                        fontFamily: 'Muli',
                        fontSize: 36,
                        fontWeight: 'bold',
                        lineHeight: 1.25,
                        textAlign: 'left',
                        color: '#181818',
                    }}>Machine Dashboard</Grid>
                    <Grid item xs={12} container sx={{
                        fontFamily: 'Muli',
                        fontSize: 15,
                        lineHeight: 1.8,
                        textAlign: 'left',
                        color: '#181818',
                        padding: '3.2% 0 0 10%'
                    }}>
                        <Grid item xs={12} container alignItems='center' gap={1}>
                            <Grid item sx={{
                                width: '9px',
                                height: '9px',
                                border: 'solid 2px #e1e319',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                            }}></Grid><Grid item>Machine Code: {machine?.Code}</Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems='center' gap={1}>
                            <Grid item sx={{
                                width: '9px',
                                height: '9px',
                                border: 'solid 2px #e1e319',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                            }}></Grid><Grid item>Machine Name: {machine?.Name}</Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems='center' gap={1}>
                            <Grid item sx={{
                                width: '9px',
                                height: '9px',
                                border: 'solid 2px #e1e319',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                            }}></Grid><Grid item>Category : {machine?.Category}</Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems='center' gap={1}>
                            <Grid item sx={{
                                width: '9px',
                                height: '9px',
                                border: 'solid 2px #e1e319',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                            }}></Grid><Grid item>Subcategory: {machine?.SubCategory}</Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems='center' gap={1}>
                            <Grid item sx={{
                                width: '9px',
                                height: '9px',
                                border: 'solid 2px #e1e319',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                            }}></Grid><Grid item>IOT Code: {machine?.IOTCode}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} md={5} lg={4}
                    container
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        boxShadow: '2px 5px 6px 0 #2da4d0',
                        backgroundColor: '#2da4d0',
                        padding: { xs: '5%', md: '3%' },
                    }} gap={2}>
                    <Grid item xs={12}
                        sx={{
                            fontFamily: 'Muli',
                            fontSize: 17,
                            fontWeight: 'bold',
                            lineHeight: 1.24,
                            textAlign: 'left',
                            color: '#fff',
                        }}>
                        Login to the machine dashboard:<br />
                        scan your access card barcode
                    </Grid>
                    <Grid item xs={12}
                    // onMouseOver={(e) => {
                    //     if (login === "denied") e.target.style.cursor = 'pointer'
                    //     else e.target.style.cursor = 'pointer'
                    // }}
                    // onClick={() => {
                    //     setTimeout(() => {
                    //         navigate('/machine/connect')
                    //     }, 1000);
                    //     if (login === "denied") setLogin("");
                    // }}
                    >
                        <img src={login === "approved" ? approved : login === "denied" ? denied : barcode} alt="logo" width='100%' />
                    </Grid>
                    <Grid item xs={12}
                        sx={{
                            fontFamily: 'Muli',
                            fontSize: 16,
                            lineHeight: 1.25,
                            textAlign: 'left',
                            color: '#fff'
                        }}
                        onMouseOver={(e) => e.target.style.cursor = 'pointer'}
                        onClick={() => navigate('/machine/emailLogin')}
                    >or login using your email</Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
