import * as constFile from "../../InventoryManagementConst";
import axios from "axios";

const CREATE_MR = constFile.CREATE_MR;

export const createMR = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_MR}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_MR_UPPER_DATA = constFile.UPDATE_MR_UPPER_DATA;
export const updateMRUpperData = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_MR_UPPER_DATA}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_MR_ITEMS = constFile.UPDATE_MR_ITEMS;
export const updateMRItems = (StockId, Items) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_MR_ITEMS}`,
      {
        StockId: StockId,
        Items: Items,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const MR_INPUT_DATA = constFile.MR_INPUT_DATA;

export const MRInputData = (InventoryId, StockId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + MR_INPUT_DATA}`,
      {
        InventoryId: InventoryId,
        StockId: StockId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const MR_TABLE_LIST = constFile.MR_TABLE_LIST;

export const getMRTableList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + MR_TABLE_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_INVENTORIES_CARD_LIST = constFile.GET_INVENTORIES_CARD_LIST;

export const getInventoriesCardList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INVENTORIES_CARD_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPLOAD_FILE = constFile.UPLOAD_FILE;
export const uploadFile = (File, InventoryId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", File);
  data.append("InventoryId", InventoryId);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_FILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const DELETE_FILE = constFile.DELETE_FILE;
export const deleteFile = (fileId, inventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        InventoryId: inventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_UOM = constFile.GET_UOM;
export const getUoM = (TypeCodeId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_UOM}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          TypeCodeId: TypeCodeId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_BUNDLES_NUMBER = constFile.GET_BUNDLES_NUMBER;
export const getBundlesNumber = (LengthId, ItemQuantity) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_BUNDLES_NUMBER}`,
      {
        LengthId: LengthId,
        ItemQuantity: ItemQuantity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_RM_CODE = constFile.GET_RM_CODE;
export const getRMCode = (
  InventoryId,
  RefId,
  DiaValueId,
  TypeCodeId,
  LengthId
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_RM_CODE}`,
      {
        InventoryId: InventoryId,
        RefId: RefId,
        DiaValueId: DiaValueId,
        TypeCodeId: TypeCodeId,
        LengthId: LengthId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_RM_DESCRIPTION = constFile.GET_RM_DESCRIPTION;
export const getRMDescription = (
  InventoryId,
  RefId,
  DiaValueId,
  TypeCodeId,
  LengthId
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_RM_DESCRIPTION}`,
      {
        InventoryId: InventoryId,
        RefId: RefId,
        DiaValueId: DiaValueId,
        TypeCodeId: TypeCodeId,
        LengthId: LengthId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_PRODUCT_CODE_DESCRIPTION = constFile.GET_PRODUCT_CODE_DESCRIPTION;
export const getProductCodeDescription = (
  GradeId,
  IsCode
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_PRODUCT_CODE_DESCRIPTION}`,
      {
        GradeId: GradeId,
        IsCode: IsCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};



const LABEL_RM_LIST = constFile.LABEL_RM_LIST;
export const getRMLabelList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + LABEL_RM_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const PRINT_RM = constFile.PRINT_RM;
export const printRMTags = (StockId, LabelId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PRINT_RM}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        StockId: StockId,
        LabelId: LabelId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const PRINT_PRODUCT = constFile.PRINT_PRODUCT;
export const printProductTags = (StockId, LabelId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PRINT_PRODUCT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        StockId: StockId,
        LabelId: LabelId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_MR_IF_SO = constFile.UPDATE_MR_IF_SO;
export const updateMRItemsIfSO = (StockId, Items) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_MR_IF_SO}`,
      {
        StockId: StockId,
        Items: Items,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};