import * as constFile from "../../InventorySetupConsts";
import axios from "axios";

const GET_INVENTORY_INPUT_DATA = constFile.GET_INVENTORY_INPUT_DATA;
export const getInventoryInputData = (FactoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INVENTORY_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FactoryId: FactoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const CREATE_INVENTORY = constFile.CREATE_INVENTORY;
export const createInventory = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_INVENTORY}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_INVENTORY = constFile.UPDATE_INVENTORY;
export const updateInventory = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_INVENTORY}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_INVETORY_LIST_CARD = constFile.GET_INVETORY_LIST_CARD;
export const getInventoryListCard = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INVETORY_LIST_CARD}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_INVENTORY_DIMENSION_INPUT_DATA =
  constFile.GET_INVENTORY_DIMENSION_INPUT_DATA;
export const getInventoryDimensionInputData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INVENTORY_DIMENSION_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_INVENTORY_DIMENSION = constFile.CREATE_INVENTORY_DIMENSION;
export const createInventoryDimension = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_INVENTORY_DIMENSION}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_INVENTORY_REFERENCES_INPUT_DATA =
  constFile.GET_INVENTORY_REFERENCES_INPUT_DATA;
export const getReferenceInputData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INVENTORY_REFERENCES_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_INVENTORY_REFERENCES = constFile.CREATE_INVENTORY_REFERENCES;
export const createInventoryReference = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_INVENTORY_REFERENCES}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_INVENTORY_REFERENCES = constFile.UPDATE_INVENTORY_REFERENCES;
export const updateInventoryReference = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_INVENTORY_REFERENCES}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_PRODUCT_REFERENCE = constFile.CREATE_PRODUCT_REFERENCE;
export const createProductReference = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
  .post(`${process.env.REACT_APP_URL + CREATE_PRODUCT_REFERENCE }`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  })
}

const UPDATE_PRODUCT_REFERENCE = constFile.UPDATE_PRODUCT_REFERENCE;
export const updateProductReference = (data,InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
  .put(`${process.env.REACT_APP_URL + UPDATE_PRODUCT_REFERENCE }`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      InventoryId: InventoryId,
    },
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  })
}

const GET_PRODUCT_REFERENCE_DATA = constFile.GET_PRODUCT_REFERENCE_DATA;
export const getProductReferenceData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PRODUCT_REFERENCE_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_PRODUCT_GRADE_DIAMETER_DATA = constFile.GET_PRODUCT_GRADE_DIAMETER_DATA;
export const getProductGradeDiameterData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PRODUCT_GRADE_DIAMETER_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const ADD_PRODUCT_REFERENCE_GRADE_DIAMETER = constFile.ADD_PRODUCT_REFERENCE_GRADE_DIAMETER;
export const addProductReferenceGradeDiameter = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
  .post(`${process.env.REACT_APP_URL + ADD_PRODUCT_REFERENCE_GRADE_DIAMETER }`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  })
}


const GET_INVENTORY_VALUE_INPUT_DATA = constFile.GET_INVENTORY_VALUE_INPUT_DATA;
export const getValueInputData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INVENTORY_VALUE_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_INVENTORY_VALUE = constFile.CREATE_INVENTORY_VALUE;
export const createInventoryValue = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_INVENTORY_VALUE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_INVENTORY_VALUE = constFile.UPDATE_INVENTORY_VALUE;
export const updateInventoryValue = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_INVENTORY_VALUE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_INVENTORY_REFERENCE_DIA_INPUT_DATA =
  constFile.GET_INVENTORY_REFERENCE_DIA_INPUT_DATA;
export const getReferenceDiaInputData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_INVENTORY_REFERENCE_DIA_INPUT_DATA}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          InventoryId: InventoryId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_INVENTORY_REFERENCE_DIA = constFile.CREATE_INVENTORY_REFERENCE_DIA;
export const createInventoryReferenceDiaValue = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_INVENTORY_REFERENCE_DIA}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_REMENANT_STOCK_INPUT_DATA = constFile.GET_REMENANT_STOCK_INPUT_DATA;
export const getRemenantStockInputData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_REMENANT_STOCK_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_REMENANT_STOCK = constFile.CREATE_REMENANT_STOCK;
export const createRemenantStock = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_REMENANT_STOCK}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_REMENANT_STOCK = constFile.UPDATE_REMENANT_STOCK;
export const updateRemenantStock = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_REMENANT_STOCK}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_CUSTOMIZED_CODE_INPUT_DATA = constFile.GET_CUSTOMIZED_CODE_INPUT_DATA;
export const getCustomizedCodeInputData = (InventoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_CUSTOMIZED_CODE_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_CUSTOMIZED_CODE = constFile.CREATE_CUSTOMIZED_CODE;
export const createCustomizedCode = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_CUSTOMIZED_CODE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_REMENANT_STOCK_LABEL_LIST = constFile.GET_REMENANT_STOCK_LABEL_LIST;
export const getRemnantStockLabels = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_REMENANT_STOCK_LABEL_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const PRINT_RE_TAGS = constFile.PRINT_RE_TAGS;
export const printReTags = (StockId , LabelId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PRINT_RE_TAGS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        StockId : StockId ,
        LabelId: LabelId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
