import React, { useState, useEffect } from "react";
import { Grid, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import { Stack } from "@mui/material";
import classes from "../../../Assets/Styles/Transport.module.css";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import SmallTruckS from "../../../Assets/Images/Loading/bigTruckS.png";
import SmallTruckNS from "../../../Assets/Images/Loading/Truck.png";
import SmallDriverNS from "../../../Assets/Images/Loading/Driver.png";
import SmallDriverS from "../../../Assets/Images/Loading/bigDriverS.png";
import SmallTrailerS from "../../../Assets/Images/Loading/bigTrailerS.png";
import SmallTrailerNS from "../../../Assets/Images/Loading/trailer.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import TruckModal from "./TruckModal";
import DriverModal from "./DriverModal";
import TrailerModal from "./TrailerModal";

import ChangeStatusModal from "./ChangeStatusModal";

import * as transportServices from "../../../Services/transport-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getDriverList = transportServices.getDriverList;
const getTrailerList = transportServices.getTrailerList;
const getTruckList = transportServices.getTruckList;

const TableViewTransport = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [truckSelected, setTruckSelected] = useState(false);
  const [driverSelected, setDriverSelected] = useState(true);
  const [trailerSelected, setTrailerSelected] = useState(false);
  const [addDisabled, setAddDisabled] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const [changeStatusDisabled, setChangeStatusDisabled] = useState(false);

  const [truckOpenModal, setTruckOpenModal] = useState(false);
  const [trailerOpenModal, setTrailerOpenModal] = useState(false);
  const [driverOpenModal, setDriverOpenModal] = useState(false);
  const [changeStatusOpenModal, setchangeStatusOpenModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [currentStatus, setCurrentStatus] = useState(0);

  const [loadingData, setLoadingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [driverId, setDriverId] = useState(null);
  const [trailerId, setTrailerId] = useState(null);
  const [truckId, setTruckId] = useState(null);

  const [searchText, setSearchText] = useState(null);
  const [modify, setModify] = useState(false);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
  };
  useEffect(() => {
    setLoadingData(true);
    if (driverSelected) {
      getDriverList(listData).then((x) => {
        setTableData(x.Drivers);
        setdataLength(x.Total);
        setLoadingData(false);
      });
    } else if (trailerSelected) {
      getTrailerList(listData).then((x) => {
        setTableData(x.Trailers);
        setdataLength(x.Total);
        setLoadingData(false);
      });
    } else if (truckSelected) {
      getTruckList(listData).then((x) => {
        setTableData(x.Trucks);
        setdataLength(x.Total);
        setLoadingData(false);
      });
    }
  }, [
    searchText,
    pageNumber,
    pageSize,
    modify,
    driverSelected,
    truckSelected,
    trailerSelected,
  ]);
  const [rowData, setRowData] = useState();
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Code",
      headerName: "Code",
      width: 200,
    },
    {
      field: "Name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "Type",
      headerName: "Type",
      width: 200,
      hide: truckSelected ? false : true,
    },
    {
      field: "Model",
      headerName: "Model",
      width: 200,
      hide: truckSelected ? false : true,
    },
    {
      field: "LicensePlate",
      headerName: "License Plate",
      width: 200,
      hide: truckSelected ? false : true,
    },
    {
      field: "MaxLoadHeight",
      headerName: "Max Load Height",
      width: 200,
      hide: trailerSelected ? false : true,
    },
    {
      field: "MaxLoadWeight",
      headerName: "Max Load Weight",
      width: 230,
      hide: trailerSelected ? false : true,
    },
    {
      field: "Dimensions",
      headerName: "Dimensions (LxWxH) in meters",
      width: 230,
      hide: trailerSelected ? false : true,
      renderCell: (params) => {
        return (
          <Grid item>
            {params?.row?.Dimensions
              ? params.row.Dimensions.Length +
                " X " +
                params.row.Dimensions.Width +
                " X " +
                params.row.Dimensions.Height
              : null}
          </Grid>
        );
      },
    },
    {
      field: "IDNumber",
      headerName: "Id Number",
      width: 224,
      hide: driverSelected ? false : true,
    },
    {
      field: "Address",
      headerName: "Address",
      width: 224,
      hide: driverSelected ? false : true,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 224,
      hide: driverSelected ? false : true,
    },
    {
      field: "Email",
      headerName: "Email",
      width: 224,
      hide: driverSelected ? false : true,
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 200,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "SalesOrders",
      headerName: "Sales Orders Loaded",
      width: 300,
      renderCell: (params) => {
        return (
         <span style={{overflowX:"auto"}}>{params.row.SalesOrders?.join(", ")}</span>
        );
      },
    },
  ];
  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Trucks, Trailers, Drivers",
        "Create",
        setAddDisabled
      );
    }
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Trucks, Trailers, Drivers",
        "Edit",
        setEditDisabled
      );
    }
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Trucks, Trailers, Drivers",
        "Update Status",
        setChangeStatusDisabled
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid item container paddingTop={4} paddingLeft={3} paddingRight={3}>
        <Grid item className={classes["Truck-List"]}>
          Truck List
        </Grid>
        <Grid
          item
          container
          lg={4}
          md={5}
          sm={7}
          direction={{ sm: "row-reverse", xs: "row" }}
          gap={2}
        >
         <Grid
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              setTruckSelected(true);
              setTrailerSelected(false);
              setDriverSelected(false);
            }}
          >
            <img
              src={truckSelected ? SmallTruckS : SmallTruckNS}
              alt={"img"}
              width={100}
            />
          </Grid>
          <Grid
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              setTruckSelected(false);
              setTrailerSelected(true);
              setDriverSelected(false);
            }}
          >
            <img
              src={trailerSelected ? SmallTrailerS : SmallTrailerNS}
              alt={"img"}
              width={100}
            />
          </Grid>
          <Grid
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              setTruckSelected(false);
              setTrailerSelected(false);
              setDriverSelected(true);
            }}
          >
            <img
              src={driverSelected ? SmallDriverS : SmallDriverNS}
              alt={"img"}
              width={100}
            />
          </Grid>
         
        </Grid>
        <Grid
          item
          container
          lg={7}
          md={5}
          sm={3}
          direction={{ sm: "row-reverse", xs: "row" }}
          gap={2}
        >
          <button
            className={classes["add-button"]}
            disabled={addDisabled}
            onClick={() => {
              if (truckSelected) {
                setTruckOpenModal(true);
              } else if (trailerSelected) {
                setTrailerOpenModal(true);
              } else if (driverSelected) {
                setDriverOpenModal(true);
              }
            }}
          >
            Add
          </button>
          <button
            className={classes["back-button"]}
            onClick={() => {
              navigate(-1);
            }}
          >
            Go back
          </button>
          <Grid item xs={12} container direction={"row-reverse"}>
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                onChange={inputHandler}
                placeholder="Search this list…"
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      fontSize="small"
                      htmlColor={"#707070"}
                      style={{ paddingLeft: 5 }}
                    />
                  ),
                }}
                sx={{
                  fontFamily: "Muli",
                  backgroundColor: "white",
                  borderRadius: 1,
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 0,
                    borderColor: "white",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <div
          style={{
            height: 500,
            width: "100%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={tableData ? tableData : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No Data To display
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No Data To display
                </Stack>
              ),
            }}
          />
        </div>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <MenuItem
            disabled={editDisabled}
            onClick={() => {
              if (truckSelected) {
                setTruckOpenModal(true);
                setTruckId(rowData.row.Id);
              } else if (trailerSelected) {
                setTrailerOpenModal(true);
                setTrailerId(rowData.row.Id);
              } else if (driverSelected) {
                setDriverId(rowData.row.Id);
                setDriverOpenModal(true);
              }
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Edit
          </MenuItem>

          <MenuItem
            disabled={changeStatusDisabled}
            onClick={() => {
              if (truckSelected) {
                setchangeStatusOpenModal(true);
                setTruckId(rowData.row.Id);
                setCurrentStatus(rowData.row.Status);
              } else if (trailerSelected) {
                setchangeStatusOpenModal(true);
                setTrailerId(rowData.row.Id);
                setCurrentStatus(rowData.row.Status);
              } else if (driverSelected) {
                setchangeStatusOpenModal(true);
                setDriverId(rowData.row.Id);
                setCurrentStatus(rowData.row.Status);
              }
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Change Status
          </MenuItem>
        </Menu>
      </Grid>
      <TruckModal
        openModal={truckOpenModal}
        setOpenModal={setTruckOpenModal}
        truckId={truckId}
        setTruckId={setTruckId}
        modify={modify}
        setModify={setModify}
      />
      <DriverModal
        openModal={driverOpenModal}
        setOpenModal={setDriverOpenModal}
        driverId={driverId}
        setDriverId={setDriverId}
        modify={modify}
        setModify={setModify}
      />
      <TrailerModal
        openModal={trailerOpenModal}
        setOpenModal={setTrailerOpenModal}
        trailerId={trailerId}
        setTrailerId={setTrailerId}
        modify={modify}
        setModify={setModify}
      />
      <ChangeStatusModal
        openModal={changeStatusOpenModal}
        setOpenModal={setchangeStatusOpenModal}
        modify={modify}
        setModify={setModify}
        trailerId={trailerId}
        setTrailerId={setTrailerId}
        truckId={truckId}
        setTruckId={setTruckId}
        driverId={driverId}
        trailerSelected={trailerSelected}
        truckSelected={truckSelected}
        driverSelected={driverSelected}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
      />
    </div>
  );
};
const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
export default TableViewTransport;
