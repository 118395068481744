import { MenuItem, TextField, Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Assets/Styles/home.css";
import classes from "../../Assets/Styles/Activities.module.css";
import classing from "../Admin/Document/DocumentModal.module.css";
import * as factoryServices from "../../Services/factories-services.proxy";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getInputData = factoryServices.getInputData;
const createfactory = factoryServices.createfactory;
const updatefactory = factoryServices.updatefactory;

const styles = {
  label: {
    fontFamily: "Muli",
    fontSize: 9,
    lineHeight: 1.56,
    textAlign: "left",
    color: "#bcbcbc",
  },
};

export default function Factory(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  const navigate = useNavigate();
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledModel, setDisabledModel] = useState(false);

  useEffect(() => {
    if (UserPermissions && ID) {
      CheckIfPermitted(
        UserPermissions,
        "Factory Set-Up",
        "Edit",
        setDisabledEdit
      );
      CheckIfPermitted(
        UserPermissions,
        "Factory Model",
        "View",
        setDisabledModel
      );
    }
  }, [UserPermissions, ID]);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [errorCode, setErrorCode] = useState(false);
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [country, setCountry] = useState(0);
  const [errorCountry, setErrorCountry] = useState(false);
  const [address, setAddress] = useState("");
  const [errorAddress, setErrorAddress] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [trade, setTrade] = useState(1);
  const [errorTrade, setErrorTrade] = useState(false);
  const [type, setType] = useState(0);
  const [errorType, setErrorType] = useState(false);
  const [volume, setVolume] = useState("");
  const [capacity, setCapacity] = useState("");
  const [errorCapacity, setErrorCapacity] = useState(false);
  const [threadCapacity, setThreadCapacity] = useState("");
  const [threadCapacityError, setThreadCapacityError] = useState(false);
  const [coverage, setCoverage] = useState("");
  const [errorCoverage, setErrorCoverage] = useState(false);
  const [deliveryDays, setDeliveryDays] = useState("");
  const [employees, setEmployees] = useState("");
  const [bays, setBays] = useState("1");
  const [errorBays, setErrorBays] = useState(false);
  const [countries, setCountries] = useState([]);
  const [types, setTypes] = useState([]);
  const [trades, setTrades] = useState([]);
  const [tempBays, setTempBays] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [fulled, setFulled] = useState(false);
  const [isoCodes, setisoCodes] = useState([]);
  const [isTraceable, setIsTraceable] = useState(null);
  const [traceableoptions, setTraceableoptions] = useState(["Yes", "No"]);
  const [errorTraceable, setErrorTraceable] = useState();

  useEffect(() => {
    let apiTest = true;
    if (ID) {
      getInputData(ID).then((x) => {
        if (apiTest) {
          setCode(x.Factory.Code);
          setName(x.Factory.Name);
          setCountry(x.Factory.CountryId);
          setAddress(x.Factory.Address);
          setPhone(x.Factory.PhoneNumber);
          setWebsite(x.Factory.Website);
          setEmail(x.Factory.Email);
          setTrade(1);
          setType(x.Factory.TypeId);
          setVolume(x.Factory.MonthlyVolume);
          setCapacity(x.Factory.DailyCapacity);
          setThreadCapacity(x.Factory.DailyThreadCapacity);
          setCoverage(x.Factory.DeliveryRadiusCoverage);
          setDeliveryDays(x.Factory.MinimumDeliveryDaysPerSalesorder);
          setEmployees(x.Factory.EmployeesNumber);
          setBays(x.Factory.BaysNumber);
          setTempBays(x.Factory.BaysNumber);
          setCountries(x.Countries);
          setTrades(x.Trades);
          setTypes(x.Types);
          setIsTraceable(x.Factory.IsTraceable === true ? "Yes" : "No");
        }
      });
    } else {
      getInputData(null).then((x) => {
        if (apiTest) {
          setCountries(x.Countries);
          setTrades(x.Trades);
          setTypes(x.Types);
          setCode(x.Factory.Code);
        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, []);

  useEffect(() => {
    for (var i = 0; i < countries.length; i++) {
      isoCodes[i] = countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });

  useEffect(() => {
    if (
      errorAddress ||
      errorBays ||
      errorCapacity ||
      errorCountry ||
      threadCapacityError ||
      errorCoverage ||
      errorEmail ||
      errorName ||
      errorPhone ||
      errorTrade ||
      errorType ||
      !address ||
      !bays ||
      !capacity ||
      !threadCapacity ||
      !country ||
      !coverage ||
      !email ||
      !name ||
      !phone ||
      !trade ||
      !type ||
      (localStorage
        .getItem("planIdManu")
        ?.includes("Pro +") && (errorTraceable || !isTraceable))
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  });

  const data = {
    Id: ID ? ID : null,
    Name: name,
    Code: code,
    Address: address,
    Email: email,
    Website: website,
    PhoneNumber: phone,
    MonthlyVolume: volume,
    DailyCapacity: capacity,
    DailyThreadCapacity: threadCapacity,
    DeliveryRadiusCoverage: coverage,
    MinimumDeliveryDaysPerSalesorder: deliveryDays,
    EmployeesNumber: employees,
    BaysNumber: bays,
    TypeId: type,
    TradeId: trade,
    CountryId: country,
    IsTraceable: isTraceable === "Yes" ? true : false
  };

  const save = () => {
    setLoading(true);
    if (ID) {
      updatefactory(data).then(() => {
        setLoading(false);
      });
    } else {
      createfactory(data).then(() => {
        setLoading(false);
        setCode("");
        setName("");
        setCountry(0);
        setAddress("");
        setPhone("");
        setWebsite("");
        setEmail("");
        setTrade(1);
        setType(0);
        setVolume("");
        setCapacity("");
        setCoverage("");
        setDeliveryDays("");
        setEmployees("");
        setBays(1);
        navigate("/saas/factory");
      });
    }
  };

  return (
    <Grid className="Rectangle779" padding="2.5% 0 0 8%">
      <Grid item xs={12} container rowSpacing={3}>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            xs={2.5}
            sm={1.5}
            lg={1.2}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Factory
          </Grid>
          <Grid
            item
            xs={0}
            sm={4.5}
            lg={7.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={4.5}
            sm={2.5}
            lg={1.4}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate("/saas/factoriesList");
            }}
          >
            View All Factories
          </Grid>
          {ID ? (
            <Grid item>
              <button
                disabled={disabledModel || !localStorage.getItem("planIdManu")?.includes("Pro")}
                className={classes["back-button"]}
                onClick={() => {
                  navigate(`/saas/factory/model?id=${ID}`);
                }}
              >
                View Factory Model
              </button>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item paddingLeft={2}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={11}
        md={7}
        gap={1.5}
        sx={{
          borderRadius: 5,
          backgroundColor: "#fff",
          padding: "2.3% 0 1% 2.5%",
          marginTop: "1.5%",
        }}
      >
        <Grid item container xs={12} gap={0.5} alignItems="flex-start">
          <Grid item xs={3} container>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Factory Code
            </Grid>
            <Grid item>
              <TextField
                disabled
                value={code}
                error={errorCode}
                helperText={errorCode ? "Enter a code" : ""}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                }}
                inputProps={{ maxLength: 10 }}
                placeholder="Factory Code"
                onBlur={(e) => {
                  if (!e.target.value) setErrorCode(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setErrorCode(true);
                  else setErrorCode(false);
                  setCode(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Factory Name
            </Grid>
            <Grid item>
              <TextField
                disabled={disabledEdit}
                value={name}
                error={errorName}
                helperText={errorName ? "Enter a name" : ""}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                }}
                inputProps={{ maxLength: 50 }}
                placeholder="Factory Name"
                onBlur={(e) => {
                  if (!e.target.value) setErrorName(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setErrorName(true);
                  else setErrorName(false);
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Country
            </Grid>
            <Grid item>
              <TextField
                disabled={disabledEdit}
                value={country}
                error={errorCountry}
                helperText={errorCountry ? "Enter a country" : ""}
                fullWidth
                select
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                }}
                onBlur={(e) => {
                  if (!e.target.value) setErrorCountry(true);
                }}
                onChange={(e) => {
                  setErrorCountry(false);
                  setCountry(e.target.value);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Countries
                </MenuItem>
                {countries?.map((c) => (
                  <MenuItem value={c.Id} key={c.Id}>
                    {c.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} gap={1} alignItems="flex-start">
          <Grid item xs={6}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Factory Address
            </Grid>
            <Grid item>
              <TextField
                disabled={disabledEdit}
                value={address}
                error={errorAddress}
                helperText={errorAddress ? "Enter an address" : ""}
                fullWidth
                multiline
                minRows={3.4}
                InputProps={{
                  style: { fontSize: 12, fontFamily: "Muli" },
                }}
                inputProps={{ maxLength: 150 }}
                placeholder="Factory Address"
                onBlur={(e) => {
                  if (!e.target.value) setErrorAddress(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setErrorAddress(true);
                  else setErrorAddress(false);
                  setAddress(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} container gap={1} alignItems="flex-start">
            {localStorage
              .getItem("planIdManu")
              ?.includes("Pro +") ?
              <Grid item xs={12}>
                <Grid item sx={styles.label} paddingLeft={1}>
                  Do you want to enable product Iot no traceability?
                </Grid>
                <Grid item>
                  <TextField
                    value={isTraceable}
                    disabled={disabledEdit}
                    fullWidth
                    select
                    error={errorTraceable}
                    helperText={errorTraceable ? "Choose" : ""}
                    InputProps={{
                      style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) setErrorTraceable(true);
                    }}
                    onChange={(e) => {
                      setErrorTraceable(false);
                      setIsTraceable(e.target.value);
                    }}
                  >
                    {traceableoptions?.map((t) => (
                      <MenuItem value={t} key={t}>
                        {t}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              :
              <></>
            }
            <Grid item xs={12}>
              <Grid item sx={styles.label} paddingLeft={1}>
                *Contact phone no.
              </Grid>
              <Grid item>
                {fulled ? (
                  <>
                    <PhoneInput
                      disabled={disabledEdit}
                      placeholder="Enter phone number"
                      onlyCountries={isoCodes}
                      enableAreaCodes={true}
                      value={phone}
                      onBlur={(e) => {
                        if (!e.target.value) setErrorPhone(true);
                      }}
                      onChange={(e) => {
                        setPhone(e);
                        if (phone === "" || phone.length < 6) {
                          setErrorPhone(true);
                        } else {
                          setErrorPhone(false);
                        }
                      }}
                      country={localStorage.getItem("userCountry")?.toLowerCase()}
                      style={{
                        borderColor: errorPhone ? "#d32f2f" : "",
                      }}
                      inputProps={{ maxLength: 25 }}
                      inputStyle={{
                        borderColor: errorPhone ? "#d32f2f" : "",
                        width: "100%",
                        height: "28px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      }}
                    />
                    {errorPhone ? (
                      <span
                        style={{
                          fontFamily: "Muli",
                          fontSize: 12,
                          color: "#d32f2f",
                        }}
                      >
                        Enter a valid Phone Number
                      </span>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} alignItems="flex-start">
          <Grid item sx={styles.label} paddingLeft={1}>
            *Email
          </Grid>
          <Grid item>
            <TextField
              disabled={disabledEdit}
              value={email}
              error={errorEmail}
              helperText={errorEmail ? "Enter a valid email" : ""}
              fullWidth
              InputProps={{
                style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
              }}
              inputProps={{ maxLength: 64 }}
              placeholder="Email"
              onBlur={(e) => {
                if (!e.target.value) setErrorEmail(true);
              }}
              onChange={(e) => {
                if (!e.target.value) setErrorEmail(true);
                else if (
                  !e.target.value.match(
                    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                  )
                ) {
                  setErrorEmail(true);
                } else setErrorEmail(false);
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid item sx={styles.label} paddingLeft={1}>
            Website
          </Grid>
          <Grid item>
            <TextField
              disabled={disabledEdit}
              value={website}
              // error={}
              // helperText={}
              fullWidth
              InputProps={{
                style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
              }}
              placeholder="Website"
              // onBlur={(e) => {}}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container gap={1} alignItems="flex-start">
          <Grid item xs={3}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Trade
            </Grid>
            <Grid item>
              <TextField
                value={trade}
                disabled={disabledEdit}

                fullWidth
                select
                error={errorTrade}
                helperText={errorTrade ? "Enter a Trade" : ""}
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                }}
                onBlur={(e) => {
                  if (!e.target.value) setErrorTrade(true);
                }}
                onChange={(e) => {
                  setErrorTrade(false);
                  setTrade(e.target.value);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Trades
                </MenuItem>
                {trades?.map((t) => (
                  <MenuItem value={t.Id} key={t.Id}>
                    {t.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Type
            </Grid>
            <Grid item>
              <TextField
                value={type}
                disabled={disabledEdit}

                fullWidth
                select
                error={errorType}
                helperText={errorType ? "Enter a Type" : ""}
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                }}
                onBlur={(e) => {
                  if (!e.target.value) setErrorType(true);
                }}
                onChange={(e) => {
                  setErrorType(false);
                  setType(e.target.value);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Types
                </MenuItem>
                {types?.map((t) => (
                  <MenuItem value={t.Id} key={t.Id}>
                    {t.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item sx={styles.label} paddingLeft={1}>
              Volume Monthly (Tons
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={volume}
                // error={}
                // helperText={}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: 0 },
                }}
                placeholder="Volume Monthly"
                // onBlur={(e) => {}}
                onChange={(e) => {
                  setVolume(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *DefaultDailyCapacity(Tons)
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={capacity}
                error={errorCapacity}
                helperText={errorCapacity ? "Enter a volume" : ""}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: 0 },
                }}
                placeholder="Default Daily Capacity"
                onBlur={(e) => {
                  if (!e.target.value) setErrorCapacity(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setErrorCapacity(true);
                  else setErrorCapacity(false);
                  setCapacity(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container gap={1} alignItems="flex-start">
        <Grid item xs={2.5}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *DefaultDailyThreadCapacity(qty)
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={threadCapacity}
                error={threadCapacityError}
                helperText={threadCapacityError ? "Enter a number" : ""}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: 0 },
                }}
                placeholder="Daily Thread Capacity"
                onBlur={(e) => {
                  if (!e.target.value) setThreadCapacityError(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setThreadCapacityError(true);
                  else setThreadCapacityError(false);
                  setThreadCapacity(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={2.5}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *Delivery Radius Coverage(km)
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={coverage}
                error={errorCoverage}
                helperText={errorCoverage ? "Enter a number" : ""}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: 0 },
                }}
                placeholder="Delivery Radius Coverage"
                onBlur={(e) => {
                  if (!e.target.value) setErrorCoverage(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setErrorCoverage(true);
                  else setErrorCoverage(false);
                  setCoverage(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item sx={styles.label} paddingLeft={1}>
              Min. days to deliver
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={deliveryDays}
                // error={}
                // helperText={}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: 0 },
                }}
                placeholder="Min. days to deliver"
                // onBlur={(e) => {}}
                onChange={(e) => {
                  setDeliveryDays(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item sx={styles.label} paddingLeft={1}>
              No. of Employees
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={employees}
                // error={}
                // helperText={}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: 0 },
                }}
                placeholder="No. of Employees"
                // onBlur={(e) => {}}
                onChange={(e) => {
                  setEmployees(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item sx={styles.label} paddingLeft={1}>
              *No. of Bays
            </Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={disabledEdit}
                value={bays}
                error={errorBays}
                helperText={errorBays ? "Enter a bays No." : ""}
                fullWidth
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                  inputProps: { min: tempBays ? tempBays : 1 },
                }}
                placeholder="No. of Bays"
                onBlur={(e) => {
                  if (!e.target.value) setErrorBays(true);
                }}
                onChange={(e) => {
                  if (!e.target.value) setErrorBays(true);
                  else setErrorBays(false);
                  setBays(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justifyContent={"flex-end"}
          direction="row"
          alignItems={"flex-end"}
          paddingRight={5}
        >
          <Grid item paddingRight={2}>
            <button
              className={classing.exitModal}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.backgroundColor = "#f5f5f5";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
              }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </Grid>
          <Grid item>
            <button
              disabled={isDisabled}
              className={classing.SaveModal}
              onMouseOver={(e) => {
                if (!isDisabled) e.target.style.cursor = "pointer";
                e.target.style.backgroundColor = "#242B64";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              style={{
                backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                fontFamily: "Muli",
                textTransform: "none",
                opacity: loading ? 0.7 : 1,
              }}
              onClick={() => {
                save();
              }}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
