//client
export const CLIENTS = "/client";
export const CLIENT_LIST = "/client/list";
export const GET_CLIENT = "/client/get-client";
export const CLIENT_INPUT_DATA = "/client/input-data";
export const UPDATE_CLIENT = "/client/update";
export const UPLOAD_DOCUMENT = "/client/upload-document-file";
export const UPLOAD_PROFILE = "/client/upload-profile-file";
export const DELETE_FILE = "/client/delete-file";

//Suppliers
export const CREATE_SUPPLIER = "/suppliers";
export const SUPPLIERS_LIST = "/suppliers/list";
export const GET_SUPPLIER_INPUT_DATA = "/suppliers/get-data";
export const UPDATE_SUPPLIER = "/suppliers/update"
export const UPLOAD_PROFILE_SUPPLIER = "/suppliers/upload-profile-file";
export const DELETE_FILE_SUPPLIER = "/suppliers/delete-file";

//document
export const DOCUMENTS = "/Document";
export const DOCUMENT_FILTERS = "/Document/list-input-data";
export const DOCUMENT_LIST = "/Document/list";
export const GET_DOCUMENT = "/Document/get-document";
export const DOCUMENT_INPUT_DATA = "/Document/input-data";
export const UPDATE_DOCUMENT = "/Document/update";
export const UPLOAD_DOCUMENT_FILE = "/Document/upload-file";
export const DELETE_DOCUMENT_FILE = "/Document/delete-file";

//tenant
export const INVITE = "/tenant/invite-tenant";
export const UPLOAD_CONTRACT = "/tenant/upload-contract";
//Get tenant Data
export const GET_DATA = "/tenant/get-data";
//UPDATE DOCMENTS
export const UPDATE_DOCUMENTS = "/tenant/update-documents";
//LISTINPUT INVITE
export const INVITE_FILTER_DATA_SC = "/sub-contractor-company/list-input-data";
//Collabrators List sc
export const GET_COLLABRATORS_SC =
  "/sub-contractor-company/collaborator-contractors";
//LISTINPUT INVITE
export const INVITE_FILTER_DATA_MC = "/main-contractor-company/list-input-data";
//Collabrators List sc
export const GET_COLLABRATORS_MC =
  "/main-contractor-company/collaborator-contractors";
//INVITE TENANT
export const INVITE_TENANT = "/tenant/invite-tenant";
//TENANT ACCECPT COLLABORATION
export const TENANT_ACCEPT_COLLABORATION =
  "/tenant/accept-collaboration-invitation";
//TENANT UPLOAD CONTARCT
export const TENANT_UPLOAD_CONTRACT = "/tenant/upload-contract";
// CHANGE Document Status
export const CHANGE_DOCUMENT_STATUS = "/Document/change-approval-status";

//Roles
export const ADMIN_MAIN = 1;
export const ADMIN_SUB = 7;
export const PROJECT_MANAGER_MAIN = 2;
export const PROJECT_MANAGER_SUB = 8;

export const DELETE_TM = "/team-member"

//Team Member (add)
export const TEAM_MEMBER = "/team-member";
//Team Member List
export const GET_TEAM_MEMBER_LIST = "/team-member/list";
//Team Member archived List
export const TEAM_MEMBER_ARCHIVED_LIST = "/team-member/archived-list";
//get team member list input data(filter)
export const TEAM_MEMBER_FILTER_DATA = "/team-member/list-input-data";
//get team member input data
export const TEAM_MEMBER_INPUT_DATA = "/team-member/input-data";
//update team member
export const UPDATE_TEAM_MEMBER = "/team-member/update";
//upload team member Document
export const TEAM_MEMBER_UPLOAD_DOCUMENT = "/team-member/upload-document";
//delete team member Document
export const TEAM_MEMBER_DELETE_DOCUMENT = "/team-member/delete-document";
//upload team member profile
export const TEAM_MEMBER_UPLOAD_PROFILE = "/team-member/upload-profile-picture";
//delete team member profile
export const TEAM_MEMBER_DELETE_PROFILE = "/team-member/delete-profile-picture";
// TM Change Status
export const CHANGE_TM_STATUS = "/team-member/change-team-member-status";
// TM Resend invitation
export const RESEND_TM_INVITATION = "/team-member/resend-invitation";
// TM get Profile data
export const GET_TMPROFILE_DATA = "/team-member/profile-data";
// TM update Profile data
export const UPDATE_TMPROFILE_DATA = "/team-member/update-profile-data";
// TM get Profile input data
export const GET_TMPROFILE_INPUT_DATA = "/team-member/profile-input-data";

//get USER ROLES lists
export const GET_USER_ROLES_LIST =
  "/pilecubes-permission/permission-group-list";
//get permission lists
export const GET_PERMISSION_LIST = "/pilecubes-permission/create-or-edit-data";
//ADD  USER ROLE
export const ADD_USER_ROLE = "/pilecubes-permission/create";
//EDIT  USER ROLE
export const EDIT_USER_ROLE = "/pilecubes-permission/update";
//TEAM MEMBER REGISTER
export const TEAM_MEMBER_REGISTER = "/team-member/register";
//TEAM MEMBER REGISTER Data
export const TEAM_MEMBER_REGISTER_DATA = "/team-member/registration-data";
//TEAM MEMBER PROFILE DATA
export const TEAM_MEMBER_PROFILE_DATA = "/team-member/profile-data";
//TEAM MEMBER ASSIGNED PROJECTS LIST
export const TM_ASSIGNED_PROJECT_LIST = "/team-member/assigned-projects-list";
//TEAM MEMBER CHANGE TEAM MEMBER SUB PROJECT STATUS
export const CHANGE_TM_SUBPROJECT_STATUS =
  "/team-member/change-team-member-sub-project-status";
//get Schedule
export const GET_SCHEDULE = "/schedule";

// Activities

//Activity Dasboard
export const GET_ACTIVITIES_CHART = "/activities/chart";

//Activities create and edit data
export const CREATE_EDIT_DATA_ACTIVITY = "/activities/create-or-edit-data";
//ADD AN ACTIVITY
export const ADD_ACTIVITY = "/activities";
//Get project List
export const GET_PROJECT_LIST = "/activities/get-projects-list";
//Get project phases and Subs
export const GET_PHASES_SUBS_BY_ID = "/dashboard/get-project-by-id";
//Get Team Member by Sub
export const GET_TM_BY_SUBPROJECT = "/team-member/list-by-sub-project";
//Get ACTIVITIES CARD
export const GET_ACTIVITIES_CARD_LIST = "/activities/card-list";
//start the activity
export const TM_START_ACTIVITY = "/activities/start-activity";
//TM  UPLOAD FILE
export const TM_UPLOAD_FILE = "/activities/upload-file";
//TM  UPLOAD Forge File FILE
export const TM_UPLOAD_FORGE_FILE = "/activities/upload-forge-file";
//TM  DELTE FILE
export const TM_DELETE_FILE = "/activities/delete-file";
//Activities Table
export const GET_ACTIVITIES_LIST = "/activities/table-list";
//save Activities files
export const SAVE_ACTIVITIES_FILES = "/activities/save-activity-files";
//TM  DELTE FILE after save
export const TM_DELETE_FILE_AFTER_SAVE = "/activities/delete-activity-file";
//DELETE ACTIVITY
export const DELETE_ACTIVITY = "/activities/delete";
//  Update Activity
export const UPDATE_ACTIVITY = "/activities/update";
//  Revise Activity
export const REVISE_ACTIVITY = "/activities/revise";
//TM  submit Activity
export const TM_SUBMIT_ACTIVITY = "/activities/submit-activity";
//Activities Versions Table
export const GET_ACTIVITIES_VERSIONS_LIST = "/activities/versions-list";
//Activity comments
//get Avtivity comment list
export const GET_COMMENT_BY_ACTIVITY = "/comments/list-by-activity";
//ADD COMMENT ON ACTIVITY
export const ADD_COMMENT_ON_ACTIVITY = "/comments";
//DELETE COMMENT
export const DELETE_COMMENT = "/comments";
//UPDATE COMMENT
export const UPDATE_COMMENT_ACTIVITY = "/comments/update";

//Accept Acivity
export const ACCEPT_ACTIVITY = "/activities/accept";
// get email activity details by id
export const GET_ACTIVITY_BY_ID = "/activities/get-by-id";

// get activities submittal data
export const GET_SUBMITTAL_RESPONSE_DATA =
  "/activities/get-create-submittal-response-data";

// create activities submittal data
export const CREATE_SUBMITTAL_RESPONSE_DATA =
  "/activities/create-submittal-response";
//download Activities  PDf
export const DOWNLOAD_ACTIVITY_PDF = "/content-generator/get-activity-pdf";

//list of discipline
export const LIST_OF_DISCIPLINE_BY_SUBPROJETID =
  "/disciplines/list-by-sub-project";
//MC ASSIGNMENT LOGS
//GET MC ASSINMENT LOG
export const GET_MC_ASSIGNMENT_LOG =
  "/main-contractor-company/get-assignment-log-list";
//GET MC ASSINMENT LOG FILTER DATA
export const GET_MC_ASSIGNMENT_LOG_FILTER_DATA =
  "/main-contractor-company/logs-list-input-data";
// change phase status
export const CHANGE_PHASE_STATUS = "/phases/change-phase-collaboration-status";

//SC ASSIGNMENT LOGS
//GET SC ASSINMENT LOG
export const GET_SC_ASSIGNMENT_LOG =
  "/sub-contractor-company/get-assignment-log-list";
//GET SC ASSINMENT LOG FILTER DATA
export const GET_SC_ASSIGNMENT_LOG_FILTER_DATA =
  "/sub-contractor-company/logs-list-input-data";
// change phase status
export const CHANGE_SUBPROJECT_STATUS =
  "/sub-projects/change-subproject-collaboration-status";

//RFI CONSTS
//get consultants by project
export const GET_CONSULTANT_BY_PROJECT =
  "/consultants/get-tenant-consultants-by-project";
//get TEAM MEMBERS by project
export const GET_TMS_BY_PROJECT = "/projects/project-tenant-team-members";
//create RFI
export const CREATE_RFI = "/request-for-information";
//Upload file RFI
export const UPLOAD_FILE_RFI = "/request-for-information/upload-file";
//Delete file RFI
export const DELETE_FILE_RFI = "/request-for-information/delete-file";
//GET RFI List
export const GET_RFI_LIST = "/request-for-information";
//GET RFI INPUT Data
export const GET_RFI_INPUT_DATA = "/request-for-information/input-data";
//responf to rfi
export const RESPONF_TO_RFI = "/request-for-information/respond";
//Upload ReSPONSE file RFI
export const UPLOAD_RESPONSE_FILE_RFI =
  "/request-for-information/upload-response-file";
//Delete ReSPONSE file RFI
export const DELETE_RESPONSE_FILE_RFI =
  "/request-for-information/delete-response-file";
//Get RFI BY ID
export const GET_RFI_BY_ID = "/request-for-information/consultant-rfi";
//Raise to Consultant
export const RAISE_TO_CONSULTANT =
  "/request-for-information/raise-to-consultant";
//download Rfi PDf
export const DOWNLOAD_RFI_PDF = "/content-generator/generate-rfi-pdf";

//PC Support
export const REQUEST_SUPPORT = "/email/request-support";

//activity timeline
export const GET_ACTIVITIES_TIMELINE = "/activities/timeline";
