import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import classes from "../../Assets/Styles/InventoryManagement.module.css";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, MenuItem } from "@mui/material";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import * as InventoryManagementServices from "../../Services/InventoryManagement-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MRBundlesLabelPreview from "../Admin/Production Label/PrintingRawMaterial/MRBundlesLabelPreview";
import * as LabelConsts from "../../../LabelsConst";
const getRMLabelList = InventoryManagementServices.getRMLabelList;
const printRMTags = InventoryManagementServices.printRMTags;
const printProductTags = InventoryManagementServices.printProductTags;


function MRBundlesPrintView(props) {
  const navigate = useNavigate();
  const ref = useRef();
  const [heightDifference, setHeightDifference] = useState(0);
  const [bundleFields, setBundlesFields] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [noLabels, setnoLabels] = useState(false);
  const [labels, setLabels] = useState([]);
  const [labelId, setLabelId] = useState(null);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const MR_ID = query.get("MRId");
  const is_RM = query.get("RM");
  const is_SO = query.get("isSO");



  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    props.setShowSaaSNavbar(false);
  }, [props.setShowSaaSNavbar]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (labelId) {
      setLoadingData(true);
      if(is_RM === "true" || is_SO === "true"){
        printRMTags(MR_ID, labelId).then((x) => {
          setLoadingData(false);
          if (x.status === 404) {
            setnoLabels(true);
          } else {
            setBundlesFields(x);
          }
        });
      }
      else {
        printProductTags(MR_ID, labelId).then((x) => {
          setLoadingData(false);
          if (x.status === 404) {
            setnoLabels(true);
          } else {
            setBundlesFields(x);
          }
        });
      }
   
    }
  }, [labelId]);
  useEffect(() => {
    getRMLabelList().then((x) => {
      console.log("cvbn",is_RM)
      if (is_RM === "true" || is_SO === "true") {
        setLabels(x.filter((l) => l.LabelTypeId === LabelConsts.RAW_MATERIAL_LABEL_ID));
        console.log("testtttttttt")

      } 
      else setLabels(x.filter((l) => l.LabelTypeId === LabelConsts.PRODUCT_LABEL_ID));

    });
  }, []);

  return (
    <Grid
      container
      direction={"row"}
      gap={2}
      bgcolor={"rgb(213 232 244)"}
      paddingBottom={10}
      minHeight={"100vh"}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item>
        <MRBundlesLabelPreview
          myRef={ref}
          heightDifference={heightDifference}
          bundleFields={bundleFields}
        />
      </Grid>
      {loadingData ? (
        ""
      ) : noLabels ? (
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid item xs={12} className={classes["Cant-Print-Label"]}>
            Can't Print Label.
          </Grid>
          <Grid item xs={12} className={classes["No-label-templates"]}>
            No label templates found, please contact your admin.
          </Grid>
          <Grid item>
            <button
              className={classes.cancelbut}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setCancelling(true);
                props.setPreviewPrinting(false);
                props.setShowSaaSNavbar(true);
                setTimeout(() => {
                  setCancelling(false);
                }, 1500);
              }}
            >
              {cancelling ? "Canceling..." : "Cancel"}
            </button>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={5}>
          <Grid
            item
            marginTop={3.1}
            container
            xs={12}
            direction={"row-reverse"}
            padding={2}
          >
            <Grid item marginLeft={2}>
              <ReactToPrint content={() => ref.current}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <button
                      className={classes.cancelbut}
                      disabled={bundleFields.length === 0 ? true : false}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        // setHeightDifference(200);
                        handlePrint();
                      }}
                    >
                      Print tags
                    </button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </Grid>
            <Grid item>
              <button
                className={classes.cancelbut}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setCancelling(true);
                  // props.setPreviewPrinting(false);
                  props.setShowSaaSNavbar(true);
                  navigate(-1);
                  setTimeout(() => {
                    setCancelling(false);
                  }, 1500);
                }}
              >
                {cancelling ? "Canceling..." : "Cancel"}
              </button>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction={"column"} gap={1}>
            <Grid item>
              <div className={classes["Production-Label"]}>
                Choose Label Template:
              </div>
            </Grid>
            <Grid item width={"120px"}>
              <TextField
                value={labelId}
                fullWidth
                disabled={bundleFields?.Tages?.length > 0 ? true : false}
                select
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                }}
                InputProps={{
                  style: {
                    color: "#707070",
                    backgroundColor: "#fff",
                    height: "28px",
                    fontSize: 12,
                    fontFamily: "Muli",
                  },
                }}
                onChange={(e) => {
                  setLabelId(e.target.value);
                }}
              >
                {labels.map((r) => (
                  <MenuItem value={r.Id} key={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {bundleFields?.Tages?.length > 0 ? (
              <Grid item>
                <button
                  className={classes.cancelbut}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  Empty Tag
                </button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} paddingTop={1}>
            <Grid item container>
              <div className={classes["Production-Label"]}>
                Print Label Preview
              </div>
            </Grid>
            <Grid
              item
              container
              paddingTop={1}
              paddingBottom={1}
              direction={"column"}
              gap={2}
              className={classes["LabelInfoTitle"]}
            >
              <Grid item container direction="row">
                <Grid item fontWeight="bold" xs={12} sm={6} md={3}>
                  Label Name
                </Grid>
                <Grid item xs={12} sm={6} md={7}>
                  {bundleFields?.LabelDetails?.Name
                    ? bundleFields.LabelDetails.Name
                    : ""}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item fontWeight="bold" xs={12} sm={6} md={3}>
                  Label Size
                </Grid>
                <Grid item xs={12} sm={6} md={7}>
                  {bundleFields?.LabelDetails?.LabelSizeId && bundleFields?.LabelDetails?.NumOfTags
                    ? bundleFields.LabelDetails.NumOfTags + " Tags"
                    : bundleFields.LabelDetails?.LabelHeight && bundleFields.LabelDetails?.LabelWidth ?
                      <>
                        <Grid
                          item
                          container
                          directon="column"
                        >
                          <Grid item xs={12}>
                            Height : {bundleFields.LabelDetails?.LabelHeight}
                          </Grid>

                          <Grid item xs={12}>
                            Width : {bundleFields.LabelDetails?.LabelWidth}
                          </Grid>
                        </Grid>
                      </>
                      :
                      <></>}
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item fontWeight="bold" xs={12} sm={6} md={3}>
                  Label Type
                </Grid>
                <Grid item xs={12} sm={6} md={7}>
                  {bundleFields?.LabelDetails?.LabelTypeName
                    ? bundleFields.LabelDetails.LabelTypeName
                    : ""}
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
    // <Grid container spacing={5} padding={3} >
    //   <Grid item container direction="column" ref={ref} width="860px">
    //   <Grid iem>MRBundlesPrintView</Grid>
    //   {
    //     bundlesArray.map((bundle,index)=>{

    //     })
    //   }
    //   </Grid>
    //   <Grid item container direction="row-reverse" spacing={1}>
    //   <Grid item>
    //     <button
    //       className={classes["back-button-whiteBg"]}
    //       onClick={() => {
    //         navigate(-1);
    //         props.setShowSaaSNavbar(true)
    //       }}
    //     >
    //       Go Back
    //     </button>
    //     </Grid>
    //     <Grid item>
    //     <ReactToPrint content={() => ref.current}>
    //             <PrintContextConsumer>
    //               {({ handlePrint }) => (
    //                 <button
    //                   className={classes["print-button"]}
    //                   onMouseOver={(e) => {
    //                     e.target.style.cursor = "pointer";
    //                   }}
    //                   onClick={() => {

    //                     handlePrint();
    //                   }}
    //                 >
    //                   Print
    //                 </button>
    //               )}
    //             </PrintContextConsumer>
    //           </ReactToPrint>

    //     </Grid>
    //   </Grid>
    // </Grid>
  );
}

export default MRBundlesPrintView;
