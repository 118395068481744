import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import close from "../../../Assets/Images/form/x.png";
import infoTip from "../../../Assets/Images/form/info.png";
import { styled } from "@mui/material/styles";
import { MenuItem, IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../../Navbar/WindowDimension";
import PhoneInput from "react-phone-input-2";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
    },
  },
});
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const BlueTip2 = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color: #fff;
        background-color: rgba(6, 116, 185, 0.7);
        font-size: 13px;
        font-family: Muli;
        width:180px;
     
    `);

function Form1(props) {
  const style = {
    blueTip: {
      width: 200,
    },
  };

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [companyInvalid, setCompanyInvalid] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [isoCodes, setisoCodes] = useState([]);

  const [emailInvalid, setEmailInvalid] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [employessError, setEmployessError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [mobileTip, setMobileTip] = useState(false);

  useEffect(() => {
    if (
      !props.companyName ||
      !props.email ||
      !props.country ||
      !props.employeesRange ||
      !props.phoneNumber ||
      companyError ||
      companyInvalid ||
      phoneNumberError ||
      employessError ||
      countryError ||
      emailError ||
      emailInvalid
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (width < 800) {
      setMobileTip(true);
    }
  });
  useEffect(() => {
    for (var i = 0; i < props.countries.length; i++) {
      isoCodes[i] = props.countries[i].IsoCode;
    }
  });
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);

  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox">
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12} direction={"row"}>
          <Grid item md={9} xs={11}>
            <div className="Join-Pilecubes"> Contact Pilecubes</div>
          </Grid>

          <Grid item md={3} xs={1} container direction={"row-reverse"}>
            <IconButton
              onClick={(e) => {
                navigate("/");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              label="Company Name"
              value={props.companyName}
              size="small"
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={companyError}
              helperText={
                companyError
                  ? companyInvalid
                    ? "Company name must be less than 150 characters"
                    : "Enter Company Name"
                  : ""
              }
              onChange={(e) => {
                props.setCompanyName(e.target.value);
                if (e.target.value === "") {
                  setCompanyError(true);
                  setCompanyInvalid(false);
                } else if ((e.target.value !== "") & (e.target.value > 150)) {
                  setCompanyError(true);
                  setCompanyInvalid(true);
                } else {
                  setCompanyError(false);
                  setCompanyInvalid(false);
                  
                }
              }}
            />
          </Grid>
          <Grid item>
            <CssTextField
              label="Company Email"
              size="small"
              value={props.email}
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={emailError}
              helperText={
                emailError
                  ? emailInvalid
                    ? "Enter a valid email"
                    : "Enter an email address"
                  : ""
              }
              onChange={(e) => {
                props.setEmail(e.target.value);
                if (e.target.value === "") {
                  setEmailError(true);
                  setEmailInvalid(false);
                } else if (
                  (e.target.value !== "") &
                    !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    ) ||
                  e.target.value.length > 64
                ) {
                  setEmailError(true);
                  setEmailInvalid(true);
                } else {
                  setEmailError(false);
                  setEmailInvalid(false);
                 
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={openTip}
                    onMouseOver={openTip}
                    onMouseLeave={closeTip}
                  >
                    {mobileTip ? (
                      <BlueTip2
                        open={tooltipIsOpen}
                        title={
                          "This email will be used to sign in and manage your account"
                        }
                        sx={style.blueTip}
                        placement="top-start"
                      >
                        <img width={"20px"} src={infoTip} alt={close}></img>
                      </BlueTip2>
                    ) : (
                      <BlueTip
                        open={tooltipIsOpen}
                        title={
                          "This email will be used to sign in and manage your account"
                        }
                        placement="right"
                      >
                        <img width={"20px"} src={infoTip} alt={close}></img>
                      </BlueTip>
                    )}
                  </IconButton>
                ),
              }}
            ></CssTextField>
          </Grid>

          <Grid item>
            <CssTextField
              size="small"
              label="Country"
              className="textField"
              value={props.country}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={countryError}
              helperText={countryError ? "Select a country" : ""}
              onChange={(e) => {
                if (e.target.value === "") {
                  setCountryError(true);
                } else {
                  setCountryError(false);
                  props.setCountry(e.target.value);
                }
              }}
            >
              {props.countries.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item>
            <CssTextField
              size="small"
              label="Employees"
              className="textField"
              value={props.employeesRange}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={employessError}
              helperText={employessError ? "Select a country" : ""}
              onChange={(e) => {
                if (e.target.value === "") {
                  setEmployessError(true);
                } else {
                  setEmployessError(false);
                  props.setEmployeesRange(e.target.value);
                }
              }}
            >
              {props.employeesRanges.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item>
            <PhoneInput
              placeholder="Enter phone number"
              onlyCountries={isoCodes}
              enableAreaCodes={true}
              value={props.phoneNumber}
              onChange={(phone) => {
                props.setPhoneNumber(phone);
                if (phone === "" || phone.length < 4 || phone.length > 20) {
                  setPhoneNumberError(true);
                } else {
                  setPhoneNumberError(false);
                  
                }
              }}
              country={localStorage.getItem("userCountry")?.toLowerCase()}
              inputStyle={{
                borderColor: "#707070",
                width: "100%",
                height: 40,
                fontFamily: "Muli",
                fontsize: "16px",
              }}
            />
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"}>
          <Grid item md={6} xs={6}>
            <button
              className="buttonNext1"
              disabled={isDisabled}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                props.continues(e);
              }}
              variant="contained"
            >
              Next
            </button>
          </Grid>
          <Grid item md={6} xs={6} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 1 of 2</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form1;
