import {
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../../../../../Assets/Images/SaaSbackground.png";
import classes from "../../../../../../Assets/Styles/factory.module.css";
import RemoveIcon from "../../../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import TransferModal from "../TransferModal";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as storageBlockServices from "../../../../../../Services/storage-block-services.proxy";
import * as stockCountServices from "../../../../../../Services/stock-count-services.proxy";

const getFilteredStockItemsOrBundles =
  stockCountServices.getFilteredStockItemsOrBundles;

const getZonesStock = storageBlockServices.getZonesStock;
const transferBundles = storageBlockServices.transferBundles;
const deleteBundle = storageBlockServices.deleteBundle;

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);
export default function ScanResults(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const ZONE_ID = query?.get("zoneId");
  const STOCK_COUNT_ID = query?.get("stockCountId");
  const CATEGORY_ID = query?.get("categoryId");

  const [totalSaved, setTotalSaved] = useState(0);
  const [totalScanned, setTotalScanned] = useState(0);

  const [foundSaved, setfoundSaved] = useState([]);
  const [foundNotSaved, setfoundNotSaved] = useState([]);
  const [savedNotfound, setsavedNotfound] = useState([]);

  const [foundSavedRemnant, setfoundSavedRemnant] = useState([]);
  const [foundNotSavedRemnant, setfoundNotSavedRemnant] = useState([]);
  const [savedNotfoundRemnant, setsavedNotfoundRemnant] = useState([]);

  const [notFound, setNotFound] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bundleToTransfer, setBundleToTransfer] = useState([]);
  const [materialReceipts, setMaterialReceipts] = useState([]);
  const [remenantBundles, setRemenantBunldes] = useState([]);

  const [storageBlocks, setStorageBlocks] = useState([]);
  const [storageId, setStorageId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [bundleId, setBundleId] = useState(0);
  const [modify, setModify] = useState(false);

  const [quantityToTransfer, setQuantityToTransfer] = useState(0);
  const [transfer, setTransfer] = useState(false);

  const [scanFilter, setScanFilter] = useState(1);

  const [tooltipFirstIsOpen, setTooltipFirstIsOpen] = useState(false);
  const openTipFirst = () => {
    setTooltipFirstIsOpen(true);
    setTooltipSecondIsOpen(false);
    setTooltipThirdIsOpen(false);
    setTooltipFourIsOpen(false);
  };
  const closeTipFirst = () => setTooltipFirstIsOpen(false);

  const [tooltipSecondIsOpen, setTooltipSecondIsOpen] = useState(false);
  const openTipSecond = () => {
    setTooltipFirstIsOpen(false);
    setTooltipSecondIsOpen(true);
    setTooltipThirdIsOpen(false);
    setTooltipFourIsOpen(false);

  }
  const closeTipSecond = () => setTooltipSecondIsOpen(false);

  const [tooltipThirdIsOpen, setTooltipThirdIsOpen] = useState(false);
  const openTipThird = () => {
    setTooltipFirstIsOpen(false);
    setTooltipSecondIsOpen(false);
    setTooltipThirdIsOpen(true);
    setTooltipFourIsOpen(false);

  }
  const closeTipThird = () => setTooltipThirdIsOpen(false);

  const [tooltipFourIsOpen, setTooltipFourIsOpen] = useState(false);
  const openTipFour = () => {
    setTooltipFirstIsOpen(false);
    setTooltipSecondIsOpen(false);
    setTooltipThirdIsOpen(false);
    setTooltipFourIsOpen(true);
  }
  const closeTipFour = () => setTooltipFourIsOpen(false);

  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`

  width:250px;
          color: #101650;
          background-color: rgba(6, 116, 185, 0.3);
          font-size: 13px;
          font-family: Muli;

      `);

  const bundlesSum = (array, sum) => {
    array.map((row) => {
      row.Items.map((item) => {
        if (item.Bundles.length > 0) sum += item.Bundles.length;
      });
    });
    return sum;
  };

  useEffect(() => {
    setLoadingData(true);
    if (ZONE_ID && CATEGORY_ID) {
      getFilteredStockItemsOrBundles(CATEGORY_ID, STOCK_COUNT_ID).then((x) => {
        setStorageBlocks(x.Storages);
        if (x.MaterialReceipts) {
          setfoundSaved(
            x.MaterialReceipts.FoundSavedRM.filter(
              (r) =>
                r.Items.filter((item) => item.Bundles.length > 0).length > 0
            )
          );
          setfoundNotSaved(
            x.MaterialReceipts.FoundNotSavedRM.filter(
              (r) =>
                r.Items.filter((item) => item.Bundles.length > 0).length > 0
            )
          );
          setsavedNotfound(
            x.MaterialReceipts.SavedNotFoundRM.filter(
              (r) =>
                r.Items.filter((item) => item.Bundles.length > 0).length > 0
            )
          );
          setMaterialReceipts(
            x.MaterialReceipts.FoundSavedRM.filter(
              (r) =>
                r.Items.filter((item) => item.Bundles.length > 0).length > 0
            )
          );
          setNotFound(x.MaterialReceipts.NotFound);
          let sum = 0;
          let sum1 = 0;
          sum = bundlesSum(x.MaterialReceipts.FoundSavedRM, sum);
          sum1 = bundlesSum(x.MaterialReceipts.SavedNotFoundRM, sum1);
          setTotalSaved(sum + sum1);

          let sum2 = 0;
          let sum3 = 0;
          sum2 = bundlesSum(x.MaterialReceipts.FoundSavedRM, sum2);
          sum3 = bundlesSum(x.MaterialReceipts.FoundNotSavedRM, sum3);
          setTotalScanned(sum2 + sum3 + x.MaterialReceipts.NotFound.length);
        }
        if (x.Bundles) {
          setfoundSavedRemnant(x.Bundles.FoundSaved);
          setfoundNotSavedRemnant(x.Bundles.FoundNotSaved);
          setsavedNotfoundRemnant(x.Bundles.SavedNotFound);
          setRemenantBunldes(x.Bundles.FoundSaved);
          setNotFound(x.Bundles.NotFound);
          setTotalSaved(
            x.Bundles.FoundSaved?.length + x.Bundles.SavedNotFound?.length
          );
          setTotalScanned(
            x.Bundles.FoundSaved?.length +
            x.Bundles.FoundNotSaved?.length +
            x.Bundles.NotFound?.length
          );
        }

        setLoadingData(false);
      });
    }
  }, [CATEGORY_ID, STOCK_COUNT_ID, modify]);

  const TransferObject = {
    BundleIds: bundleToTransfer,
    ToZoneId: zoneId,
  };
  const TransferTheBundles = () => {
    transferBundles(TransferObject).then((x) => {
      setModify(!modify);
      setQuantityToTransfer(0);
    });
  };

  const DelteTheBundle = () => {
    deleteBundle(bundleId).then((x) => {
      setModify(!modify);
    });
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
        paddingBottom: 60,
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item container paddingTop={3} rowGap={2}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={3.7}
            fontSize={{ xs: "10px", sm: "16px" }}
            fontWeight="bold"
            className={classes["Titlestock"]}
          >
            Stock Count Results
          </Grid>
          <Grid
            item
            xs={5}
            sm={7.5}
            lg={8.6}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>

          <Grid item>
            <button
              className={classes["back-button"]}
              style={{width:150}}
              onClick={() => {
                navigate('/saas/factory');
              }}
            >
              Back To Factory
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        paddingLeft={{ xs: "2.5%", md: "5.5%" }}
      >
        <Grid item className={classes["countResults"]} paddingTop={1.5}>
          Total of bundles saved in the zone: {totalSaved}
        </Grid>
        <Grid item className={classes["countResults"]}>
          Total of bundles scanned: {totalScanned}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >

        <Grid
          item
          onClick={() => {
            setScanFilter(1);
            setMaterialReceipts(foundSaved);
            setRemenantBunldes(foundSavedRemnant);
          }}
          className={
            scanFilter === 1
              ? classes["results-scanned-chosen"]
              : classes["results-scanned-not-chosen"]
          }
        >
          Scanned & Found
          <IconButton
            edge="end"
            onClick={openTipFirst}
            onMouseOver={openTipFirst}
            onMouseLeave={closeTipFirst}
          >
            <BlueTip open={tooltipFirstIsOpen} title="List of Bundles' Codes that are scanned and matched the saved Bundles in this zone"
              placement="top-start">
              <InfoIcon fontSize="small" htmlColor="#AAAAAA" />
            </BlueTip>
          </IconButton>
        </Grid>

        <Grid
          item
          onClick={() => {
            setScanFilter(2);
            setMaterialReceipts(foundNotSaved);
            setRemenantBunldes(foundNotSavedRemnant);
          }}
          className={
            scanFilter === 2
              ? classes["results-scanned-chosen"]
              : classes["results-scanned-not-chosen"]
          }
        >
          Scanned & Not Saved
          <IconButton
            edge="end"
            onClick={openTipSecond}
            onMouseOver={openTipSecond}
            onMouseLeave={closeTipSecond}
          >
            <BlueTip
              open={tooltipSecondIsOpen}
              title="List of Bundles' Codes that are scanned but not stored in this zone"
              placement="top-start"
            >
              <InfoIcon fontSize="small" htmlColor="#AAAAAA" />
            </BlueTip>
          </IconButton>
        </Grid>

        <Grid
          item
          onClick={() => {
            setMaterialReceipts(savedNotfound);
            setRemenantBunldes(savedNotfoundRemnant);
            setScanFilter(3);
          }}
          className={
            scanFilter === 3
              ? classes["results-scanned-chosen"]
              : classes["results-scanned-not-chosen"]
          }
        >
          Saved & Not Found
          <IconButton
            edge="end"
            onClick={openTipThird}
            onMouseOver={openTipThird}
            onMouseLeave={closeTipThird}
          >
            <BlueTip
              open={tooltipThirdIsOpen}
              title="List of Bundles' Codes that are saved in this zone but not scanned"
              placement="top-start"
            >
              <InfoIcon fontSize="small" htmlColor="#AAAAAA" />
            </BlueTip>
          </IconButton>
        </Grid>


        <Grid
          item
          onClick={() => {
            setScanFilter(4);
            setMaterialReceipts(notFound)
          }}
          className={
            scanFilter === 4
              ? classes["results-scanned-chosen"]
              : classes["results-scanned-not-chosen"]
          }
        >
          Not Found
          <IconButton
            edge="end"
            onClick={openTipFour}
            onMouseOver={openTipFour}
            onMouseLeave={closeTipFour}
          >
            <BlueTip
              open={tooltipFourIsOpen}
              title="List of Bundles' Codes that are scanned and doesn't exist in the system"
              placement="top-start"
            >
              <InfoIcon fontSize="small" htmlColor="#AAAAAA" />
            </BlueTip>
          </IconButton>
        </Grid>

      </Grid>
      <Grid
        item
        container
        direction={"row-reverse"}
        xs={11.5}
        md={10}
        gap={2}
        paddingTop={{ xs: 2, sm: 1 }}
      >
        <button
          className={classes["TransferButton"]}
          disabled={bundleToTransfer.length === 0 ? true : false}
          onClick={() => {
            setOpen(true);
            setTransfer(true);
          }}
        >
          Transfer multiple bundles
        </button>
      </Grid>

      <Grid
        item
        overflow={"auto"}
        paddingTop={2}
        paddingLeft={{ xs: "2.5%", md: "5.5%" }}
        xs={11.5}
        md={11}
        lg={12}
        className={classes["scrollTable"]}
      >
        {scanFilter === 4 && notFound.length > 0 ? (
          notFound.map((n) => (
            <>
              Barcode: {n}
              <br />
            </>
          ))
        ) : ((CATEGORY_ID === "1" || CATEGORY_ID === "3" )&& materialReceipts.length > 0) ||
          (CATEGORY_ID === "2" && remenantBundles?.length > 0) ? (
          <TableContainer sx={{ width: 1200 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                backgroundColor: "#fff",
                borderCollapse: "separate",
                borderSpacing: "0px 0px",
                border: 0,
              }}
            >
              <TableBody>
                {CATEGORY_ID === "1" || CATEGORY_ID === "3" 
                  ? materialReceipts?.map((mr) => (
                    <>
                      <StyledTableRow key={mr.Id}>
                        <TableCell sx={styles.tableCellRmCode}>
                          <Grid item paddingLeft={0.5} paddingTop={0.5}>
                            {mr.Code}
                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                        <TableCell sx={styles.tableCellRmCode}></TableCell>
                      </StyledTableRow>

                      {mr?.Items?.filter((item) => item.Bundles.length > 0).map((Item, index) => (
                        <>
                          <StyledTableRow key={Item.Id}>
                            <TableCell sx={styles.tableCellRMItem}>
                              <Grid
                                item
                                paddingLeft={2}
                                paddingTop={0.5}
                                paddingBottom={0.5}
                              >
                                {"Item" + Item.Name}
                              </Grid>
                            </TableCell>
                            <TableCell
                              sx={styles.tableCellRMItem}
                            ></TableCell>
                            <TableCell
                              sx={styles.tableCellRMItem}
                            ></TableCell>

                            <TableCell sx={styles.tableCellempty}></TableCell>
                            <TableCell sx={styles.tableCellempty}></TableCell>
                            <TableCell sx={styles.tableCellempty}></TableCell>
                            <TableCell sx={styles.tableCellempty}></TableCell>
                            <TableCell sx={styles.tableCellempty}></TableCell>
                            <TableCell sx={styles.tableCellempty}></TableCell>
                          </StyledTableRow>
                          {Item?.Bundles?.map((bundle) => (
                            <StyledTableRow key={bundle.BundleId}>
                              <TableCell sx={styles.tableCellBundleCircle}>
                                <Grid
                                  marginLeft={2}
                                  item
                                  className={
                                    bundleToTransfer.find(
                                      (x) => x === bundle.BundleId
                                    )
                                      ? classes["selectCircleSelected"]
                                      : classes["selectCircle"]
                                  }
                                  onClick={() => {
                                    if (
                                      bundleToTransfer.find(
                                        (x) => x === bundle.BundleId
                                      )
                                    ) {
                                      setBundleToTransfer(
                                        bundleToTransfer.filter(
                                          (x) => x !== bundle.BundleId
                                        )
                                      );
                                      setQuantityToTransfer(
                                        quantityToTransfer - +bundle.Weight
                                      );
                                    } else {
                                      setBundleToTransfer(
                                        bundleToTransfer.concat(
                                          bundle.BundleId
                                        )
                                      );
                                      setQuantityToTransfer(
                                        quantityToTransfer + +bundle.Weight
                                      );
                                    }
                                  }}
                                ></Grid>
                              </TableCell>
                              <TableCell sx={styles.tableCellBundle}>
                                <Grid container direction={"column"}>
                                  <Grid
                                    item
                                    className={classes["inputTitle"]}
                                  >
                                    Bundle Code
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes["InputBorder"]}
                                  >
                                    {bundle.Code}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell sx={styles.tableCellBundle}>
                                <Grid container direction={"column"}>
                                  <Grid
                                    item
                                    className={classes["inputTitle"]}
                                  >
                                    Bundle Name
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes["InputBorder"]}
                                  >
                                    {bundle.Name}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell sx={styles.tableCellBundle}>
                                <Grid container direction={"column"}>
                                  <Grid
                                    item
                                    className={classes["inputTitle"]}
                                  >
                                    Heat No.
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes["InputBorder"]}
                                  >
                                    {bundle.HeatNumber}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell sx={styles.tableCellBundle}>
                                <Grid container direction={"column"}>
                                  <Grid
                                    item
                                    className={classes["inputTitle"]}
                                  >
                                    Bundle Weight (Kg)
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes["InputBorder"]}
                                  >
                                    {bundle.Weight}
                                  </Grid>
                                </Grid>
                              </TableCell>

                              <TableCell sx={styles.tableCellBundle}>
                                <Grid container direction={"column"}>
                                  <Grid
                                    item
                                    className={classes["inputTitle"]}
                                  >
                                    Qty of Pcs
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes["InputBorder"]}
                                  >
                                    {bundle.Quantity}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell sx={styles.tableCellBundle}>
                                <Grid container direction={"column"}>
                                  <Grid
                                    item
                                    className={classes["inputTitle"]}
                                  >
                                    Mtc Doc Name
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes["InputBorder"]}
                                  >
                                    {bundle.MTC}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              {
                                foundNotSaved === materialReceipts ?
                                  <TableCell sx={styles.tableCellBundle}>
                                    <Grid container direction={"column"}>
                                      <Grid
                                        item
                                        className={classes["inputTitle"]}
                                      >
                                        Storage/Zone
                                      </Grid>
                                      <Grid
                                        item
                                        className={classes["InputBorder"]}
                                      >
                                        {bundle.StorageName}/{bundle.ZoneName}
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  : ""
                              }
                              <TableCell sx={styles.tableCellBundleButton}>
                                <button
                                  className={classes["TransferOneButton"]}
                                  onClick={() => {
                                    let bundles = [];
                                    bundles = bundles.concat(bundle.BundleId);
                                    setBundleToTransfer(bundles);
                                    setQuantityToTransfer(+bundle.Weight);
                                    setOpen(true);
                                    setTransfer(true);
                                  }}
                                >
                                  Transfer
                                </button>
                              </TableCell>
                              <TableCell sx={styles.tableCellBundleCircle}>
                                <Grid
                                  item
                                  paddingLeft={3}
                                  onMouseOver={(e) => {
                                    e.target.style.cursor = "pointer";
                                  }}
                                  onClick={() => {
                                    setOpen(true);
                                    setTransfer(false);
                                    setBundleId(bundle.BundleId);
                                  }}
                                >
                                  <img src={RemoveIcon} alt={"img"} />
                                </Grid>
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ))}
                    </>
                  ))
                  : remenantBundles?.map((bundle) => (
                    <>
                      <StyledTableRow key={bundle.BundleId}>
                        <TableCell sx={styles.tableCellBundleCircle}>
                          <Grid
                            marginLeft={2}
                            item
                            className={
                              bundleToTransfer.find(
                                (x) => x === bundle.BundleId
                              )
                                ? classes["selectCircleSelected"]
                                : classes["selectCircle"]
                            }
                            onClick={() => {
                              if (
                                bundleToTransfer.find(
                                  (x) => x === bundle.BundleId
                                )
                              ) {
                                setBundleToTransfer(
                                  bundleToTransfer.filter(
                                    (x) => x !== bundle.BundleId
                                  )
                                );
                                setQuantityToTransfer(
                                  quantityToTransfer - +bundle.Weight
                                );
                              } else {
                                setBundleToTransfer(
                                  bundleToTransfer.concat(bundle.BundleId)
                                );
                                setQuantityToTransfer(
                                  quantityToTransfer + +bundle.Weight
                                );
                              }
                            }}
                          ></Grid>
                        </TableCell>
                        <TableCell sx={styles.tableCellBundle}>
                          <Grid container direction={"column"}>
                            <Grid item className={classes["inputTitle"]}>
                              Bundle Code
                            </Grid>
                            <Grid item className={classes["InputBorder"]}>
                              {bundle.Code}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.tableCellBundle}>
                          <Grid container direction={"column"}>
                            <Grid item className={classes["inputTitle"]}>
                              Bundle Name
                            </Grid>
                            <Grid item className={classes["InputBorder"]}>
                              {bundle.Name}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.tableCellBundle}>
                          <Grid container direction={"column"}>
                            <Grid item className={classes["inputTitle"]}>
                              Heat No.
                            </Grid>
                            <Grid item className={classes["InputBorder"]}>
                              {bundle.HeatNumber}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.tableCellBundle}>
                          <Grid container direction={"column"}>
                            <Grid item className={classes["inputTitle"]}>
                              Bundle Weight (Kg)
                            </Grid>
                            <Grid item className={classes["InputBorder"]}>
                              {bundle.Weight}
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell sx={styles.tableCellBundle}>
                          <Grid container direction={"column"}>
                            <Grid item className={classes["inputTitle"]}>
                              Qty of Pcs
                            </Grid>
                            <Grid item className={classes["InputBorder"]}>
                              {bundle.Quantity}
                            </Grid>
                          </Grid>
                        </TableCell>

                        {
                          foundNotSavedRemnant === remenantBundles ?
                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid
                                  item
                                  className={classes["inputTitle"]}
                                >
                                  Storage/Zone
                                </Grid>
                                <Grid
                                  item
                                  className={classes["InputBorder"]}
                                >
                                  {bundle.StorageName}/{bundle.ZoneName}
                                </Grid>
                              </Grid>
                            </TableCell>
                            : ""
                        }

                        <TableCell sx={styles.tableCellBundleButton}>
                          <button
                            className={classes["TransferOneButton"]}
                            onClick={() => {
                              let bundles = [];
                              bundles = bundles.concat(bundle.BundleId);
                              setBundleToTransfer(bundles);
                              setQuantityToTransfer(+bundle.Weight);
                              setOpen(true);
                              setTransfer(true);
                            }}
                          >
                            Transfer
                          </button>
                        </TableCell>
                        <TableCell sx={styles.tableCellBundleCircle}>
                          <Grid
                            item
                            paddingLeft={3}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            onClick={() => {
                              setOpen(true);
                              setTransfer(false);
                              setBundleId(bundle.BundleId);
                            }}
                          >
                            <img src={RemoveIcon} alt={"img"} />
                          </Grid>
                        </TableCell>
                      </StyledTableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid item container justifyContent="center" marginTop="7%">
            No Data Found
          </Grid>
        )}

        <TransferModal
          openModal={open}
          setOpenModal={setOpen}
          storageBlocks={storageBlocks}
          setStorageBlocks={setStorageBlocks}
          storageId={storageId}
          setStorageId={setStorageId}
          zoneId={zoneId}
          setZoneId={setZoneId}
          quantityToTransfer={quantityToTransfer}
          loading={loading}
          setLoading={setLoading}
          transfer={transfer}
          setTransfer={setTransfer}
          TransferTheBundles={TransferTheBundles}
          ZONE_ID={ZONE_ID}
          DelteTheBundle={DelteTheBundle}
        />
      </Grid>
    </div>
  );
}
const styles = {
  tableCellRmCode: {
    backgroundColor: "rgba(225, 227, 25, 0.7)",
    fontFamily: "Muli",
    fontSize: 9,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    padding: 0,
  },
  tableCellRMItem: {
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 9,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    padding: 0,
  },
  tableCellempty: {
    backgroundColor: "#fff",
    border: 0,
    height: 15,
    padding: 0,
  },

  tableCellBundleCircle: {
    width: 60,
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
    padding: 0,
  },
  tableCellBundleButton: {
    width: 100,
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
    padding: 0,
  },
  tableCellBundle: {
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
  },
  tablebig: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    width: 111,
  },
  tableCellDisable: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#2da4d0",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    paddingLeft: 2,
    height: 5,
  },
};
