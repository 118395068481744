import React, { useState, useEffect } from "react";
import classes from "./UserRolesTable.module.css";
import { Grid, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
//import { idID } from "@mui/material/locale";
import * as permissionServices from "../../../Services/permissions-group.proxy";
const getUserRolesList = permissionServices.getUserRolesList;
const addUserRole = permissionServices.addUserRole;

const AddNewUser = (props) => {
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleInvalid, setTitleInvalid] = useState(false);

  const [roleDescription, setRoleDescription] = useState("");
  const [roleDescriptionError, setRoleDescriptionError] = useState(false);

  const [roleError, setRoleError] = useState(false);
  const [loadingButton, setloadingButton] = useState(false);

  const [usersRoles, setUsersRoles] = useState([]);
  const data = {
    Search: null,
    SortString: null,
  };
  useEffect(() => {
    let apiTest = true;

    getUserRolesList(data).then((x) => {
      if (apiTest) {
        setUsersRoles(x.PermissionGroups);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [props.role]);
  const addTheRole = () => {
    if (!title || titleError) setTitleError(true);
    else if (!roleDescription || roleDescriptionError)
      setRoleDescriptionError(true);
    else if (!props.permissionList || roleError) setRoleError(true);
    else {
      setloadingButton(true);
      addUserRole(title, roleDescription, usersRoles?.find((r) => r.Id === props.role).AccountTypeId, props.permissionList).then((x) => {
        if (x.status === 409) {
          setTitleError(true);
          setTitleInvalid(true);
        } else {
          setTitle("");
          setRoleDescription("");
          props.setpermissionList([]);
          props.setRole(0);
          props.setModify(!props.modify);
          window.scrollTo({
            top: window.innerHeight + 500,
            left: 0,
            behavior: "smooth",
          });
        }
        setloadingButton(false);
      });
    }
  };
  return (
    <Grid width={"89%"}>
      <Grid
        container
        direction={"row"}
        className={classes["RowsTitles"]}
        marginTop={2}
        paddingLeft={2}
      >
        <Grid item md={3.5} xs={6}>
          Add Role Group
        </Grid>
        <Grid item md={5.5} xs={4}>
          Description
        </Grid>
        <Grid item md={3} xs={0} display={{ md: "inherit", xs: "none" }}>
          Permissions
        </Grid>
      </Grid>
      <Grid className={classes["AddRoleBox"]}>
        <Grid
          container
          direction={"row"}
          className={classes["RowsTitles"]}
          marginTop={2}
          paddingTop={3}
          paddingLeft={2}
        >
          <Grid item md={3.5} xs={6} container gap={2.5}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                value={title}
                size="small"
                disabled={props.addPermission}
                className={classes["RoleTitleInput"]}
                InputLabelProps={{
                  style: {
                    fontFamily: "Muli",
                    fontSize: "12px",
                  },
                }}
                inputProps={{
                  style: { height: 20, backgroundColor: "#fff" },
                  maxLength: 50,
                }}
                error={titleError}
                helperText={
                  titleError
                    ? titleInvalid
                      ? "Title Already Exists"
                      : "Enter a title"
                    : ""
                }
                onBlur={() => {
                  if (!title) {
                    setTitleError(true);
                  }
                }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError(false);
                  setTitleInvalid(false);
                }}
              />
            </Grid>

          </Grid>
          <Grid item md={5.5} xs={6}>
            <TextField
              label="Description"
              size="large"
              disabled={props.addPermission}
              value={roleDescription}
              className={classes["RoleDescInput"]}
              multiline
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "12px",
                },
              }}
              inputProps={{
                style: { height: 75 },
                maxLength: 1000,
              }}
              error={roleDescriptionError}
              helperText={roleDescriptionError ? "Enter a description " : ""}
              sx={{ backgroundColor: "#fff" }}
              onBlur={() => {
                if (!roleDescription) {
                  setRoleDescriptionError(true);
                }
              }}
              onChange={(e) => {
                setRoleDescription(e.target.value);
                setRoleDescriptionError(false);
              }}
            />
          </Grid>
          <Grid item md={2.5} xs={8}>
            <TextField
              label="Select from existing roles"
              value={props.role}
              disabled={props.addPermission}
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              select
              size="small"
              className={classes["RoleTitleInput"]}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "12px",
                },
              }}
              inputProps={{
                style: { height: 20 },
              }}
              sx={{ backgroundColor: "white" }}
              error={roleError}
              helperText={roleError ? "Enter a Role" : ""}
              onBlur={() => {
                if (!props.role) {
                  setRoleError(true);
                }
              }}
              onChange={(e) => {
                props.setRole(e.target.value);
                props.setUserRoleId(e.target.value);
                setRoleError(false);
              }}
            >
              <MenuItem disabled value={0}>
                User Role
              </MenuItem>
              {usersRoles.map((option) =>
                option.IsSystem ? (
                  <MenuItem
                    value={option.Id}
                    key={option.Id}
                    disabled={
                      props.addPermission ||
                        localStorage.getItem("IsSubscribedBefore") === "true"
                        ? true
                        : false
                    }
                    onClick={(e) => e.preventDefault()}
                  >
                    {option.Name}
                  </MenuItem>
                ) : (
                  "<></>"
                )
              )}
            </TextField>
            <Grid item container direction={"row"} paddingTop={3}>
              <Grid item>
                <button
                  disabled={props.role === "" ? true : false}
                  onMouseOver={(e) => {
                    if (props.role !== "") {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#0674b9";
                      e.target.style.color = "#fff";
                    }
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#fff";
                    e.target.style.color = "#0674b9";
                  }}
                  onClick={(e) => {
                    props.setOpenModal(true);
                    props.setShowAdd(true);
                  }}
                  className={classes["EditPermission"]}
                >
                  Edit Permissions
                </button>
              </Grid>
              <Grid item marginLeft={3.2}>
                <button
                  disabled={
                    props.permissionList.length === 0 ||
                      localStorage.getItem("IsSubscribedBefore") === "true"
                      ? true
                      : false
                  }
                  className={classes["AddPermission"]}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.backgroundColor = "#0674b9";
                    e.target.style.color = "#fff";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#fff";
                    e.target.style.color = "#0674b9";
                  }}
                  onClick={(e) => {
                    addTheRole();
                  }}
                >
                  {loadingButton ? "Adding..." : "Add"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddNewUser;
