import React, { useEffect, useState } from 'react';
import classes from "../../../../../../Assets/Styles/InventorySetup.module.css";
import { Grid, FormControl, MenuItem, TextField, Tooltip, TableContainer, Table, TableRow, TableHead, TableCell, TableBody, Backdrop, CircularProgress, Select } from '@mui/material';
import ErrorModal from "../../../../../../SharedComponents/ErrorModal"

const ProductSecondSection = (props) => {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [diameters, setDiameters] = useState([]);
    const [grades, setGrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItemSeriesObject, setSelectedItemSeriesObject] = useState();
    const [spliceTypesList, setSpliceTypesList] = useState([]);
    const [modify, setModify] = useState(false);

    const [BEerror, setBEerror] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);



    useEffect(() => {
        props.getProductGradeDiameterData(props.inventoryId).then((x) => {
            if (x.status || x.statusCode) {
                if (x.status) setBEerror(x.error)
                if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                setOpenErrorModal(true);
            } else {
                setDiameters(x.Diameters);
                setGrades(x.Grades);
                setSelectedItemSeriesObject(x.Grades[0]);
                setSpliceTypesList(x.SpliceTypes)
            }
            setLoading(false);

        })
    }, [modify])

    const saveItemSeriesDias = () => {
        setLoading(true);
        props.addProductReferenceGradeDiameter(grades).then((x) => {
            if (x.status || x.statusCode) {
                if (x.status) setBEerror(x.error)
                if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                setOpenErrorModal(true);
            } else {
                setModify(!modify)
            }
            setLoading(false);
            props.setActiveTab(props.activeTab + 1);

        })
    }

    useEffect(() => {
        console.log("bvke", selectedItemSeriesObject)
        if (selectedItemSeriesObject?.Splices?.length > 0 && selectedItemSeriesObject?.SpliceTypeNumber === 0) {
            selectedItemSeriesObject.Splices = [];
            forceUpdate()
        }
        if (selectedItemSeriesObject?.Splices?.length === 0 && selectedItemSeriesObject?.SpliceTypeNumber > 0) {
            let spliceObject = {
                Id: null,
                TypeId: null,
                Process: null,
                Name: null
            }
            selectedItemSeriesObject.Splices = new Array(selectedItemSeriesObject?.SpliceTypeNumber).fill(spliceObject);
        }
        else if (selectedItemSeriesObject?.Splices?.length < selectedItemSeriesObject?.SpliceTypeNumber) {
            console.log("testtt", selectedItemSeriesObject?.SpliceTypeNumber + "ggg", selectedItemSeriesObject?.Splices?.length)
            let tmpAr = new Array(selectedItemSeriesObject?.SpliceTypeNumber - selectedItemSeriesObject?.Splices?.length).fill(0);
            let spliceObject = {
                Id: null,
                TypeId: null,
                Process: null,
                Name: null,
            }
            tmpAr.map((i) => {
                selectedItemSeriesObject.Splices = [...selectedItemSeriesObject.Splices, spliceObject]
                console.log("hjjkkl")

            })
            forceUpdate()


        }
        forceUpdate()

    }, [selectedItemSeriesObject])
    return (
        <>
            <Grid item container className={classes["inputSetupContainer"]} padding={3} xs={12} marginRight={1.5} columnGap={{ xs: 1, sm: 3 }} rowGap={3}>
                <Backdrop
                    sx={{
                        color: "#fff",
                        backgroundColor: "transparent",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                    open={loading}
                >
                    <CircularProgress />

                    <Grid
                        item
                        fontFamily={"Muli"}
                        fontSize={15}
                        fontWeight={"bold"}
                        color={"rgb(25, 118, 210)"}
                    >
                        Loading Data...
                    </Grid>
                </Backdrop>
                {selectedItemSeriesObject ?
                    <>
                        <Grid item container direction="column" xs={12} sm={2.5} rowGap={0.5}>
                            <Grid item className={classes["TFTitle"]}>*Item Series</Grid>
                            <Grid item width="100%">
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedItemSeriesObject.Id ? selectedItemSeriesObject.Id : 0}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: {
                                                        xs: "130px",
                                                    },
                                                },
                                            },
                                        }}
                                        fullWidth
                                        default={0}
                                        sx={{
                                            height: "34px",
                                            fontFamily: "Muli",
                                            fontSize: "13px",
                                        }}
                                        onChange={(e) => {
                                            console.log("gre", e)
                                            setSelectedItemSeriesObject(grades?.find((g) => g.Id === e.target.value))
                                        }}
                                    >
                                        {/* <MenuItem value={0} key={0} disabled>
                                            Storage Block
                                        </MenuItem> */}
                                        {grades?.map((s) => (
                                            <MenuItem key={s.Id} value={s.Id}>{s.ItemSeries}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <TextField
                                    select
                                    fullWidth
                                    value={selectedItemSeriesObject.Id}
                                    InputProps={{
                                        style: {
                                            height: "30px",
                                            fontSize: 12,
                                            fontFamily: "Muli",
                                        },
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            disableScrollLock: true, sx: { height: "150px" },

                                        },
                                    }}
                                    onChange={(e) => {
                                        console.log("gre", e)
                                        setSelectedItemSeriesObject(grades?.find((g) => g.Id === e.target.value))
                                    }}
                                >
                                    {grades?.map((s) => (
                                        <MenuItem key={s.Id} value={s.Id}>{s.ItemSeries}</MenuItem>
                                    ))}
                                </TextField> */}
                            </Grid>
                        </Grid>

                        <Grid item container direction="column" xs={12} sm={2.5} rowGap={0.5}>
                            <Grid item className={classes["TFTitle"]}>*List of Dia values in mm</Grid>
                            <Grid item width="100%">
                                <FormControl fullWidth>
                                    <Select
                                        multiple
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedItemSeriesObject.Diameters?.map((dia) => dia.DiameterId)}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: {
                                                        xs: "130px",
                                                    },
                                                },
                                            },
                                        }}
                                        fullWidth
                                        default={0}
                                        sx={{
                                            height: "34px",
                                            fontFamily: "Muli",
                                            fontSize: "13px",
                                        }}
                                        onChange={(e, addedValue) => {
                                            console.log(e)
                                            let diaObject = {
                                                Id: null,
                                                DiameterId: addedValue.props.value,
                                                SupplierCatalogCode: null,
                                                BoxWeight: null,
                                                PiecesPerBox: null
                                            }
                                            let tmp = selectedItemSeriesObject.Diameters;
                                            if (tmp.find((d) => d.DiameterId === addedValue.props.value))
                                                selectedItemSeriesObject.Diameters = selectedItemSeriesObject.Diameters.filter((d) => d.DiameterId !== addedValue.props.value)
                                            else selectedItemSeriesObject.Diameters = [...selectedItemSeriesObject.Diameters, diaObject];
                                            forceUpdate();



                                        }}
                                    >
                                        {/* <MenuItem value={0} key={0} disabled>
                                            Storage Block
                                        </MenuItem> */}
                                        {diameters?.map((s, index) => (
                                            <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <TextField
                                    select
                                    fullWidth
                                    value={selectedItemSeriesObject.Diameters?.map((dia) => dia.DiameterId)}
                                    InputProps={{
                                        style: {
                                            height: "30px",
                                            fontSize: 12,
                                            fontFamily: "Muli",
                                        },
                                    }}
                                    SelectProps={{
                                        multiple: true,
                                        MenuProps: {
                                            disableScrollLock: true, sx: { height: "150px" },
                                        },
                                    }}
                                    onChange={(e, addedValue) => {
                                        console.log(e)
                                        let diaObject = {
                                            Id: null,
                                            DiameterId: addedValue.props.value,
                                            SupplierCatalogCode: null,
                                            BoxWeight: null,
                                            PiecesPerBox: null
                                        }
                                        let tmp = selectedItemSeriesObject.Diameters;
                                        if (tmp.find((d) => d.DiameterId === addedValue.props.value))
                                            selectedItemSeriesObject.Diameters = selectedItemSeriesObject.Diameters.filter((d) => d.DiameterId !== addedValue.props.value)
                                        else selectedItemSeriesObject.Diameters = [...selectedItemSeriesObject.Diameters, diaObject];
                                        forceUpdate();



                                    }}
                                >
                                    {diameters?.map((s, index) => (
                                        <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
                                    ))}
                                </TextField> */}
                            </Grid>
                        </Grid>

                        {/* <Grid item container xs={12} sm={4} direction="row" alignItems="center">
                            <Grid item width="220px" className={classes["enableTraceability"]}>Do you want to enable product Iot no traceability?</Grid>
                            <Grid item className={
                                selectedItemSeriesObject.IsTraceable
                                    ? classes["CheckedBox"]
                                    : classes["notCheckedBox"]
                            }
                                marginTop={2}
                                onClick={() => {
                                    if (selectedItemSeriesObject.IsTraceable) selectedItemSeriesObject.IsTraceable = false
                                    else selectedItemSeriesObject.IsTraceable = true
                                    forceUpdate();



                                }}></Grid>
                        </Grid> */}

                        <Grid item container direction="column" xs={12} rowGap={1} marginTop={1}>
                            {selectedItemSeriesObject.SpliceTypeNumber > 0 ?
                                <Grid item className={classes["titleNavy"]}> Splice Types</Grid>
                                :
                                <></>}
                            {selectedItemSeriesObject.Splices?.map((item, index) => (
                                <Grid item container key={index} value={item.Id} columnGap={1.5}>
                                    {/* <Grid item container direction="column" xs={12} sm={2} rowGap={0.5}>
                                        <Grid item className={classes["TFTitle"]}>*Splice Type</Grid>
                                        <Grid item className={classes["staticTextfield"]}>
                                            Type {index + 1}
                                        </Grid>

                                    </Grid> */}
                                    <Grid item container direction="column" xs={12} sm={2} rowGap={0.5}>
                                        <Grid item className={classes["TFTitle"]}>*Splice Type</Grid>
                                        <Grid item>
                                            <TextField
                                                select
                                                fullWidth
                                                value={item.TypeId}
                                                InputProps={{
                                                    style: {
                                                        height: "30px",
                                                        fontSize: 12,
                                                        fontFamily: "Muli",
                                                    },
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        disableScrollLock: true, sx: { height: "150px" },
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    const updatedSplices = selectedItemSeriesObject.Splices.map((splice, idx) => {
                                                        if (idx === index) {
                                                            // If this is the item being changed, update its value
                                                            return {
                                                                ...splice,
                                                                TypeId: e.target.value // Assuming you're updating TypeId here
                                                            };
                                                        } else {
                                                            // Otherwise, return the splice as is
                                                            return splice;
                                                        }
                                                    });
                                                    selectedItemSeriesObject.Splices = updatedSplices

                                                    // item.TypeId = e.target.value;
                                                    forceUpdate();
                                                }}>
                                                {spliceTypesList?.map((s, index) => (
                                                    <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                    </Grid>
                                    {/* <Grid item container direction="column" xs={12} sm={2} rowGap={0.5}>
                                        <Grid item className={classes["TFTitle"]}>*Splice Type Abbreviation</Grid>
                                        <Grid item className={classes["staticTextfield"]}>
                                            {spliceTypesList?.find((t) => t.Id === item.TypeId)?.Abbreviation}
                                        </Grid>
                                    </Grid> */}
                                    <Grid item container direction="column" xs={12} sm={2} rowGap={0.5}>
                                        <Grid item className={classes["TFTitle"]}>*Splice Name</Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                value={item.Name}
                                                InputProps={{
                                                    style: {
                                                        height: "30px",
                                                        fontSize: 12,
                                                        fontFamily: "Muli",
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    const updatedSplices = selectedItemSeriesObject.Splices.map((splice, idx) => {
                                                        if (idx === index) {
                                                            // If this is the item being changed, update its value
                                                            return {
                                                                ...splice,
                                                                Name: e.target.value // Assuming you're updating TypeId here
                                                            };
                                                        } else {
                                                            // Otherwise, return the splice as is
                                                            return splice;
                                                        }
                                                    });
                                                    selectedItemSeriesObject.Splices = updatedSplices

                                                    // item.TypeId = e.target.value;
                                                    forceUpdate();
                                                    // item.Process = e.target.value;
                                                    // forceUpdate();
                                                }} />
                                        </Grid>

                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={2} rowGap={0.5}>
                                        <Grid item className={classes["TFTitle"]}>*Splice Process</Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                value={item.Process}
                                                InputProps={{
                                                    style: {
                                                        height: "30px",
                                                        fontSize: 12,
                                                        fontFamily: "Muli",
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    const updatedSplices = selectedItemSeriesObject.Splices.map((splice, idx) => {
                                                        if (idx === index) {
                                                            // If this is the item being changed, update its value
                                                            return {
                                                                ...splice,
                                                                Process: e.target.value // Assuming you're updating TypeId here
                                                            };
                                                        } else {
                                                            // Otherwise, return the splice as is
                                                            return splice;
                                                        }
                                                    });
                                                    selectedItemSeriesObject.Splices = updatedSplices

                                                    // item.TypeId = e.target.value;
                                                    forceUpdate();
                                                    // item.Process = e.target.value;
                                                    // forceUpdate();
                                                }} />
                                        </Grid>

                                    </Grid>
                                </Grid>

                            ))}
                        </Grid>

                        <Grid item xs={12} className={classes["TableTitle"]} paddingTop={3}>
                            Characteristics
                        </Grid>

                        <Grid item overflow={"auto"}>
                            <TableContainer sx={{ width: "100%" }}>
                                <Table
                                    stickyHeader
                                    aria-label="sticky table"
                                    sx={{
                                        borderCollapse: "separate",
                                        borderSpacing: "0px 0px",
                                        border: 0,
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={styles.tableCellhead} style={{ color: '#0674b9' }}>

                                                Dia Selected (mm) :<br /> (kg)

                                            </TableCell>
                                            {selectedItemSeriesObject?.Diameters?.map((dia, index) => (
                                                <TableCell sx={styles.tableCellhead2}>
                                                    <Grid item>{diameters?.find((d) => d.Id === dia.DiameterId)?.Name}</Grid>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={styles.tableCellhead}>
                                                Supplier Catalog Code
                                            </TableCell>
                                            {selectedItemSeriesObject?.Diameters?.map((dia, index) => (
                                                <TableCell sx={styles.tableCellhead2}>
                                                    <TextField
                                                        value={dia.SupplierCatalogCode}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            style: styles.tfInput,
                                                        }}
                                                        InputProps={{
                                                            // disableUnderline: true,
                                                            style: styles.tfInput,
                                                        }}
                                                        onChange={(e) => {
                                                            dia.SupplierCatalogCode = e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={styles.tableCellhead}>
                                                No of Pieces per box: (Units)
                                            </TableCell>
                                            {selectedItemSeriesObject?.Diameters?.map((dia, index) => (
                                                <TableCell sx={styles.tableCellhead2}>
                                                    <TextField
                                                        variant="standard"
                                                        value={dia.PiecesPerBox}
                                                        fullWidth
                                                        type={"number"}
                                                        defaultValue={0}
                                                        InputLabelProps={{
                                                            style: styles.tfInput,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: styles.tfInput,
                                                            inputProps: { min: 0 },
                                                        }}
                                                        onChange={(e) => {
                                                            console.log(props.itemSelectedData);
                                                            dia.PiecesPerBox = +e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={styles.tableCellhead}>
                                                Box Weight (kg)
                                            </TableCell>
                                            {selectedItemSeriesObject?.Diameters?.map((dia, index) => (
                                                <TableCell sx={styles.tableCellhead2}>
                                                    <TextField
                                                        variant="standard"
                                                        value={dia.BoxWeight}
                                                        fullWidth
                                                        type={"number"}
                                                        defaultValue={0}
                                                        InputLabelProps={{
                                                            style: styles.tfInput,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: styles.tfInput,
                                                            inputProps: { min: 0 },
                                                        }}
                                                        onChange={(e) => {
                                                            console.log(props.itemSelectedData);
                                                            dia.BoxWeight = +e.target.value;
                                                            forceUpdate();
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                        <Grid item container direction="row" xs={12} justifyContent="flex-end" columnGap={1}>
                            <button
                                disabled={loading}
                                className={classes["cancelbut"]}
                                onClick={() => {
                                    props.setProductSectionNb(1)
                                }}
                            >
                                Go Back
                            </button>
                            <button
                                disabled={loading}
                                className={classes["SaveBut"]}
                                onClick={() => {
                                    saveItemSeriesDias()
                                    console.log("all ", grades)
                                }}
                            >
                                {props.savingProducts ? "Saving ..." : "Save"}
                            </button>

                        </Grid>
                    </>
                    :
                    <></>}

            </Grid>
            <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
        </>
    )
}

export default ProductSecondSection

const styles = {
    tableCell: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#707070",
        lineHeight: 1.17,
        textAlign: "left",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        maxHeight: "20px",
        width: 157,
    },
    tableCellhead: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#707070",
        textAlign: "left",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        maxHeight: "20px",
        width: 112,
    },
    tableCellhead3: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#0674b9",
        textAlign: "center",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        maxHeight: "20px",
        width: 75,
        padding: "0px 0px 10px 0px",
        background:
            "linear-gradient(to top right, #fff calc(50% - 1px), rgba(206, 210, 225, 0.97) , #fff calc(50% + 1px))",
    },
    tableCellhead2: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#0674b9",
        textAlign: "center",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        maxHeight: "20px",
        width: 75,
    },
    tableCellheadPr: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#707070",
        textAlign: "left",
        maxHeight: "20px",
        width: 266,
    },
    tableCellBody: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#707070",
        lineHeight: 1.17,
        textAlign: "left",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        maxHeight: "20px",
        width: 75,
        paddingTop: 3.5,
    },
    tableCellheadBody: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#707070",
        textAlign: "left",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        maxHeight: "20px",
        width: 140,
    },
    tableCellheadPrBody: {
        backgroundColor: "#fff",
        fontFamily: "Muli",
        fontSize: 12,
        color: "#707070",
        textAlign: "left",
        maxHeight: "20px",
        borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
        width: 180,
    },
    tfInput: {
        color: "#707070",
        height: "28px",
        fontSize: 12,
        fontFamily: "Muli",
        textAlign: "center"
    },
};