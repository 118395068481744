import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Main from "../Pages/Main";
import Sub from "../Pages/Sub";
import WhyPileCubes from "../Pages/WhyPileCubes";
import TermsAndConditions from "../Pages/TermsAndConditions";
import BookDemo from "../Components/Home/bookDemo";
import Pricing from "../Pages/Pricing";
import ContactUs from "../Pages/ContactUs";
import Login from "../Pages/Login";
import IotLogin from "../Components/IOTLogin/LoginView";
import Success from "../Components/Pricing/SubscriptionResponses/Success";
import Failed from "../Components/Pricing/SubscriptionResponses/Failed";
import ResetForm from "../Components/Login/ResetPassword/ResetForm";
import TeamMemberRegistration from "../Components/TeamMemberRegistration/TeamMemberRegistration";
import SubmittalsEmailed from "../SAAS/Components/Execution/SubmittalsEmailed";
import RFISubmittals from "../SAAS/Components/Admin/Document/RFIS/RFISubmittals";
import Admin from "../SAAS/Pages/Admin";
import Execution from "../SAAS/Pages/Execution";
import Sales from "../SAAS/Pages/Sales";
import Projects from "../SAAS/Pages/Project";
import PageNotFound from "../Pages/PageNotFound";
import SystemCrash from "../Pages/SystemCrash";
import Settings from "../SAAS/Components/Profile/Settings";
import ProfileDetails from "../SAAS/Components/Profile/ProfileDetails";
import ClientTableView from "../SAAS/Components/Admin/Client/ClientTableView";
import DocumentTableView from "../SAAS/Components/Admin/Document/DocumentTableView";
import McTableView from "../SAAS/Components/Admin/MC/McTableView";
import ScTableView from "../SAAS/Components/Admin/SC/ScTableView";
import UserRolesTableView from "../SAAS/Components/Admin/UserRoles/UserRolesTableView";
import TeamMemberTableView from "../SAAS/Components/Admin/TeamMember/TeamMemberTableView";
import Join from "../SAAS/Components/Project/Join";
import Tables from "../SAAS/Components/Project/Tables";
import AssignedTables from "../SAAS/Components/Project/AssignedTables";
import Create from "../SAAS/Components/Project/Create/Create";
import Notifications from "../SAAS/Pages/Notifications";
import Phases from "../SAAS/Components/Project/Phases";
import TeamMember from "../SAAS/Components/Project/Create/TeamMember/TeamMember";
import SubProject from "../SAAS/Components/Project/Create/SubProject/SubProject";
import SubTables from "../SAAS/Components/Project/Create/SubProject/SubTables";
import Dashboard from "../SAAS/Components/Project/Create/Dashboard/Dashboard";
import MilesStones from "../SAAS/Components/Project/MileStones/MilesStones";
import Invitations from "../SAAS/Pages/Invitations";
import CreateActivities from "../SAAS/Components/Execution/Create/CreateActivities";
import ActivitiesTable from "../SAAS/Components/Execution/ActivitiesTable";
import RevisionsActivitiesTable from "../SAAS/Components/Execution/RevisionsActivityTables";
import SubmittalResponse from "../SAAS/Components/Execution/Create/SubmittalResponse";
import AddRFI from "../SAAS/Components/Admin/Document/RFIS/AddRFI";
import RFIList from "../SAAS/Components/Admin/Document/RFIS/RFIList";
import RFIResponse from "../SAAS/Components/Admin/Document/RFIS/RFIResponse";
import Viewer from "../SAAS/Components/Execution/FrogViewer/Viewer";
import Scheduled from "../Components/Pricing/Scheduled";
import SalesProjectList from "../SAAS/Components/Sales/SalesProjectList";
import SalesOrderList from "../SAAS/Components/Sales/SalesOrderList";
import SalesOrderListCard from "../SAAS/Components/Sales/SalesOrderListCard";
import SalesOrderDashboard from "../SAAS/Components/Sales/SalesOrderDashboard";
import SalesOrderForm from "../SAAS/Components/Sales/CreateSalesOrder/SalesOrderForm";
import CalenderView from "../SAAS/Components/Sales/Calender/CalenderView";
import ProductionLabelView from "../SAAS/Components/Admin/Production Label/ProductionLabelView";
import ProductionLabelCreations from "../SAAS/Components/Admin/Production Label/ProductionLabelCreations";
import ProductionLabelTable from "../SAAS/Components/Admin/Production Label/ProductionLabelTable";
import ProjectLabels from "../SAAS/Components/Admin/Production Label/ProjectLabels";
import Factory from "../SAAS/Pages/Factory";
import FactoryCreation from "../SAAS/Components/Factory/FactoryCreation";
import Bay from "../SAAS/Components/Factory/Bay/Bay";
import FactoryTableView from "../SAAS/Components/Factory/Tables/FactoryTableView";
import BayTableView from "../SAAS/Components/Factory/Tables/BayTableView";
import BlockTableView from "../SAAS/Components/Factory/Tables/BlockTableView";
import StorageCreation from "../SAAS/Components/Factory/Bay/Storage Block/StorageCreation";
import RoutesCreation from "../SAAS/Components/Factory/Routes/RoutesCreation";
import RoutesTable from "../SAAS/Components/Factory/Routes/RoutesTable";
import MasterParameters from "../SAAS/Components/Factory/MasterParameters";
import MachineCreation from "../SAAS/Components/Factory/Bay/Machine Block/MachineCreation";
import HandlingCreation from "../SAAS/Components/Factory/Bay/Handling Block/HandlingCreation";
import FactoryModel from "../SAAS/Components/Factory/Factory Model/FactoryModel";
import Calendar from "../SAAS/Components/Planning/Calendar";
import Assigning from "../SAAS/Components/Planning/Assigning";
import InventorySetup from "../SAAS/Components/Admin/Inventory Setup/InventorySetup";
import InventoryManagementListCard from "../SAAS/Components/InventoryManagement/InventoryManagementListCard";
import MaterialReceiptCreationForm from "../SAAS/Components/InventoryManagement/MaterialReceiptCreationForm";
import InventoryControlSystem from "../SAAS/Components/Admin/Inventory Setup/InventoryTabs/InventoryControlSystem";
import MaterialReceiptTable from "../SAAS/Components/InventoryManagement/MaterialReceiptsTable";
import MRBundlesPrintView from "../SAAS/Components/InventoryManagement/MRBundlesPrintView";
import Machines from "../SAAS/Components/Planning/Machine optimization/Machines";
import OptimizationSystem from "../SAAS/Components/Planning/Machine optimization/OptimizationSystem";
import Connect from "../SAAS/Components/Dashboard/Connect";
import MachineDashboard from "../SAAS/Components/Dashboard/Dashboard";
import Orders from "../SAAS/Components/Dashboard/Orders";
import AssignedLines from "../SAAS/Components/Dashboard/AssignedLines";
import Item from "../SAAS/Components/Dashboard/ItemData";
import ScanItem from "../SAAS/Components/Dashboard/ScanItem";
import Production from "../SAAS/Components/Dashboard/ProductionFlow";
import Summary from "../SAAS/Components/Dashboard/Summary";
import MachineLogin from "../SAAS/Components/Dashboard/Login";
import LoginByEmail from "../SAAS/Components/Dashboard/LoginByEmail";
import ProtectedRoute from "./ProtectedRoute";
import ManageFGS from "../SAAS/Components/Factory/Bay/Storage Block/ManageStock/ManageFGS";
import ManageRM from "../SAAS/Components/Factory/Bay/Storage Block/ManageStock/ManageRM";
import ManageProduct from "../SAAS/Components/Factory/Bay/Storage Block/ManageStock/ManageProduct";
import StockCount from "../SAAS/Components/Factory/Bay/Storage Block/ManageStock/StockCount/StockCount";
import ScanMaterial from "../SAAS/Components/Factory/Bay/Storage Block/ManageStock/StockCount/ScanMaterial";
import ScanResults from "../SAAS/Components/Factory/Bay/Storage Block/ManageStock/StockCount/ScanResults";
import MachinePrintLabel from "../SAAS/Components/Dashboard/MachinePrintLabel";
import LoadingCalendar from "../SAAS/Components/Loading/Calender";
import DeliveryCalender from "../SAAS/Components/Delivery/Calendar/Calendar";
import TableViewTransport from "../SAAS/Components/Admin/Transport/TableViewTransport";
import ResultView from "../SAAS/Components/Loading/ResultView";
import DeliveryNote from "../SAAS/Components/Delivery/DeliveryNote/DeliveryNote";
import DeliveryNoteListCard from "../SAAS/Components/Delivery/DeliveryNoteListCard";
import DeliverNotePrint from "../SAAS/Components/Delivery/DeliveryNote/DeliverNotePrint";
import DeliveryResponse from "../SAAS/Components/Delivery/Delivery Response/DeliveryResponse";
import SaleOrderNote from "../SAAS/Components/Delivery/DeliveryNote/SaleOrderNote";
import HoldedOrders from "../SAAS/Components/Factory/Bay/Machine Block/HoldedOrders";
import SupplierTableView from "../SAAS/Components/Admin/Supplier/SupplierTableView";
import ActivitiesDashboard from "../SAAS/Components/Execution/ActivitiesDashboard";
import ActivitiesTimelineView from "../SAAS/Components/Execution/Timeline/ActivitiesTimelineView";

export default function Navigate({
  active,
  setActive,
  active5,
  setActive5,
  setRegister,
  setNaved,
  isVerified,
  token,
  setCn,
  cn,
  setSetupProfile,
  setSetupMenu,
  booked,
  setBooked,
  setScrolled,
  scrolled,
  isLoggedIn,
  accounType,
  setIsSubscribed,
  setIsVerified,
  account,
  setAccount,
  setToken,
  isSubscribed,
  setAccountName,
  setProfileSrc,
  setLoading,
  setContactUspricing,
  setTerms,
  setShowSaaSNavbar,
}) {
  const [edit, setEdit] = useState(false);
  const [addPhase, setAddPhase] = useState(false);
  const [view, setView] = useState(false);
  const [join, setJoin] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const [itemScanned, setItemScanned] = useState(null);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Home
            token={token}
            isVerified={isVerified}
            isSubscribed={isSubscribed}
            setNaved={setNaved}
            setTerms={setTerms}
            booked={booked}
            setBooked={setBooked}
            setScrolled={setScrolled}
            scrolled={scrolled}
            setRegister={setRegister}
            account={account}
          />
        }
      />
      <Route
        exact
        path="/bookDemo"
        element={
          <BookDemo
            booked={booked}
            setBooked={setBooked}
            setScrolled={setScrolled}
            scrolled={scrolled}
            setRegister={setRegister}
          />
        }
      />
      <Route
        exact
        path="/whyPileCubes"
        element={
          <WhyPileCubes
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/terms-and-conditions"
        element={
          <TermsAndConditions
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/scheduled"
        element={
          <Scheduled
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/pricing"
        element={
          <Pricing
            token={token}
            isVerified={isVerified}
            isSubscribed={isSubscribed}
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
            setLoading={setLoading}
            accounType={accounType}
            account={account}
            isLoggedIn={isLoggedIn}
            setContactUspricing={setContactUspricing}
          />
        }
      />
      <Route
        path="/TeamMemberRegistration"
        element={
          <TeamMemberRegistration
            setRegister={setRegister}
            setNaved={setNaved}
            setToken={setToken}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/contactUs"
        element={
          <ContactUs
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/main"
        element={
          <Main
            setAccountName={setAccountName}
            setRegister={setRegister}
            setLoading={setLoading}
            account={account}
            setToken={setToken}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            token={token}
            isSubscribed={isSubscribed}
          />
        }
      />
      <Route
        exact
        path="/sub"
        element={
          <Sub
            setAccountName={setAccountName}
            setRegister={setRegister}
            setLoading={setLoading}
            account={account}
            setToken={setToken}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            token={token}
            isSubscribed={isSubscribed}
          />
        }
      />
      <Route
        exact
        path="/login"
        element={
          <Login
            setShowSaaSNavbar={setShowSaaSNavbar}
            setRegister={setRegister}
            setLoading={setLoading}
            setScrolled={setScrolled}
            token={token}
            setToken={setToken}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            isSubscribed={isSubscribed}
            setIsSubscribed={setIsSubscribed}
            setAccount={setAccount}
            account={account}
            setAccountName={setAccountName}
          />
        }
      />
      <Route
        exact
        path="/Iotlogin"
        element={
          <IotLogin
            setShowSaaSNavbar={setShowSaaSNavbar}
            setNaved={setNaved}
            setRegister={setRegister}
          />
        }
      />
      <Route
        exact
        path="/user/reset"
        element={
          <ResetForm setRegister={setRegister} setLoading={setLoading} />
        }
      />
      <Route
        exact
        path="/payment/success"
        element={
          <Success
            setRegister={setRegister}
            setIsSubscribed={setIsSubscribed}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/submittals"
        element={
          <SubmittalsEmailed
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/RFIsubmittals"
        element={
          <RFISubmittals
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/DeliveryResponse"
        element={
          <DeliveryResponse
            setRegister={setRegister}
            setNaved={setNaved}
            setTerms={setTerms}
          />
        }
      />
      <Route
        exact
        path="/payment/failed"
        element={<Failed setRegister={setRegister} />}
      />
      {localStorage.getItem("isVerified") === "true" &&
        (localStorage.getItem("isSubscribed") === "true" ||
          localStorage.getItem("isSubscribedManu") === "true" ||
          localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
          localStorage.getItem("isGuest") === "true" ||
          localStorage.getItem("isTeamMember") === "true" ||
          localStorage.getItem("IsFreePlanActivated") === "true" ||
          localStorage.getItem("IsSubscribedBefore") === "true") ? (
        <>
          <Route
            path="/saas/admin"
            element={
              <Admin
                setRegister={setRegister}
                setNaved={setNaved}
                setSetupProfile={setSetupProfile}
                setSetupMenu={setSetupMenu}
              />
            }
          />
          <Route
            path="/ForgeViewer"
            element={
              <Viewer
                setRegister={setRegister}
                setNaved={setNaved}
                setShowSaaSNavbar={setShowSaaSNavbar}
              />
            }
          />
          <Route
            path="/saas/projects"
            element={
              <Projects
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
                join={join}
                setJoin={setJoin}
                setSetupProfile={setSetupProfile}
                setSetupMenu={setSetupMenu}
              />
            }
          />
          <Route
            path="/saas/projects/teamMember"
            element={
              <TeamMember
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            }
          />
          <Route
            path="/saas/projects/subprojects"
            element={
              <SubProject
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            }
          />
          <Route
            path="/saas/projects/creating"
            element={
              <Create
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
                join={join}
                setJoin={setJoin}
                addPhase={addPhase}
                setAddPhase={setAddPhase}
              />
            }
          />
          <Route
            path="/saas/projects/search"
            element={
              <Join
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                join={join}
                setJoin={setJoin}
              />
            }
          />
          <Route
            path="/saas/projects/subprojects/created"
            element={
              <SubTables
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            }
          />
          <Route
            path="/saas/projects/dashboard"
            element={
              <Dashboard
                setRegister={setRegister}
                setNaved={setNaved}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            }
          />
          {localStorage.getItem("isSubscribed") === "true" ||
            (localStorage.getItem("isTeamMember") === "true" &&
              (localStorage.getItem("companyPlanId")?.includes("Core") ||
                localStorage.getItem("companyPlanId")?.includes("Pro"))) ||
            localStorage.getItem("isGuest") === "true" ? (
            <Route
              path="/saas/projects/milesstones"
              element={
                <MilesStones
                  setRegister={setRegister}
                  setNaved={setNaved}
                  projectId={projectId}
                  setProjectId={setProjectId}
                />
              }
            />
          ) : (
            ""
          )}

          <Route
            path="/saas/projects/created"
            element={
              <Tables
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            }
          />
          <Route
            path="/saas/execution/activitiesTable"
            element={
              <ActivitiesTable setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/execution/activitiesTimeline"
            element={
              <ActivitiesTimelineView setRegister={setRegister} setNaved={setNaved} />
            }
          />
            <Route
            path="/saas/execution/activitiesDashboard"
            element={
              <ActivitiesDashboard setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/admin/RFI/create"
            element={<AddRFI setRegister={setRegister} setNaved={setNaved} />}
          />
          <Route
            path="/saas/admin/RFI/List"
            element={<RFIList setRegister={setRegister} setNaved={setNaved} />}
          />
          <Route
            path="/saas/admin/RFI/Response"
            element={
              <RFIResponse setRegister={setRegister} setNaved={setNaved} />
            }
          />

          <Route
            path="/saas/execution/RevisionsActivitiesTable"
            element={
              <RevisionsActivitiesTable
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />
          <Route
            path="/saas/execution/SubmittalResponse"
            element={
              <SubmittalResponse
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />
          <Route
            path="/saas/projects/assigned"
            element={
              <AssignedTables
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
              />
            }
          />
          <Route
            path="/saas/projects/phases"
            element={
              <Phases
                setRegister={setRegister}
                setNaved={setNaved}
                edit={edit}
                setEdit={setEdit}
                view={view}
                setView={setView}
                projectId={projectId}
                setProjectId={setProjectId}
                setJoin={setJoin}
                addPhase={addPhase}
                setAddPhase={setAddPhase}
              />
            }
          />
          <Route
            path="/saas/execution"
            element={
              <Execution
                setRegister={setRegister}
                setNaved={setNaved}
                setShowSaaSNavbar={setShowSaaSNavbar}
              />
            }
          />
          <Route
            path="/saas/execution/create"
            element={
              <CreateActivities
                setRegister={setRegister}
                setNaved={setNaved}
                setShowSaaSNavbar={setShowSaaSNavbar}
              />
            }
          />
          <Route
            path="/saas/sales"
            element={<Sales setRegister={setRegister} setNaved={setNaved} />}
          />
          <Route
            path="/saas/salesOrderDashboard"
            element={
              <SalesOrderDashboard
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />

          <Route
            path="/saas/salesOrderListCard"
            element={
              <SalesOrderListCard
                setRegister={setRegister}
                setNaved={setNaved}
                projectId={projectId}
                setProjectId={setProjectId}
                setShowSaaSNavbar={setShowSaaSNavbar}
              />
            }
          />
          <Route
            path="/saas/salesProjectList"
            element={
              <SalesProjectList
                setRegister={setRegister}
                setNaved={setNaved}
                setSetupProfile={setSetupProfile}
                setSetupMenu={setSetupMenu}
              />
            }
          />
          <Route
            path="/saas/salesOrderList"
            element={
              <SalesOrderList
                setRegister={setRegister}
                setNaved={setNaved}
                setSetupProfile={setSetupProfile}
                setSetupMenu={setSetupMenu}
                projectId={projectId}
              />
            }
          />
          <Route
            path="/saas/salesOrderForm"
            element={
              <SalesOrderForm
                setRegister={setRegister}
                setNaved={setNaved}
                setShowSaaSNavbar={setShowSaaSNavbar}
              />
            }
          />

          {localStorage.getItem("AccountType") === "1" ? (
            <></>
          ) : (
            <>
              <Route
                path="/saas/salesOrdersCalender"
                element={
                  <CalenderView setRegister={setRegister} setNaved={setNaved} />
                }
              />
              <Route
                path="/saas/factory"
                element={
                  <Factory
                    setRegister={setRegister}
                    setNaved={setNaved}
                    setSetupMenu={setSetupMenu}
                    setSetupProfile={setSetupProfile}
                  />
                }
              />
              <Route
                path="/saas/factory/create"
                element={
                  <FactoryCreation
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/factoriesList"
                element={
                  <FactoryTableView
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/masterParameters"
                element={
                  <MasterParameters
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              {localStorage.getItem("isSubscribedManu") ? (
                <>
                  <Route
                    path="/saas/factory/model"
                    element={
                      <FactoryModel
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />

                  <Route
                    path="/saas/factory/route/create"
                    element={
                      <RoutesCreation
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/factory/routes"
                    element={
                      <RoutesTable
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/factory/bay"
                    element={
                      <Bay setRegister={setRegister} setNaved={setNaved} />
                    }
                  />
                  <Route
                    path="/saas/factory/bay/storageBlock"
                    element={
                      <StorageCreation
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/factory/bay/machineBlock"
                    element={
                      <MachineCreation
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/factory/bay/machineBlock/holdedOrders"
                    element={
                      <HoldedOrders
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/factory/bay/handlingBlock"
                    element={
                      <HandlingCreation
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />

                  <Route
                    path="/saas/baysList"
                    element={
                      <BayTableView
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/blocksList"
                    element={
                      <BlockTableView
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/productionPlanning/Assigning"
                    element={
                      <Assigning
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                  <Route
                    path="/saas/productionPlanning/Calendar"
                    element={
                      <Calendar setRegister={setRegister} setNaved={setNaved} />
                    }
                  />
                  <Route
                    path="/saas/productionPlanning/machinesOptimization"
                    element={
                      <Machines setRegister={setRegister} setNaved={setNaved} />
                    }
                  />
                  <Route
                    path="/saas/productionPlanning/machinesOptimization/system"
                    element={
                      <OptimizationSystem
                        setRegister={setRegister}
                        setNaved={setNaved}
                      />
                    }
                  />
                </>
              ) : (
                ""
              )}

              <Route
                path="/saas/admin/productionLabel"
                element={
                  <ProductionLabelView
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/admin/productionLabel/create"
                element={
                  <ProductionLabelCreations
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/admin/productionLabel/table"
                element={
                  <ProductionLabelTable
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/admin/productionLabel/projectLabel"
                element={
                  <ProjectLabels
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/admin/inventory"
                element={
                  <InventorySetup
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              {localStorage.getItem("IsFreePlanActivated") === "false" ? (
                <>
                  {localStorage.getItem("planIdManu")?.includes("Pro")}
                  <Route
                    path="/saas/admin/inventoryManagement"
                    element={
                      <InventoryManagementListCard
                        setRegister={setRegister}
                        setNaved={setNaved}
                        setSetupMenu={setSetupMenu}
                        setSetupProfile={setSetupProfile}
                      />
                    }
                  />
                </>
              ) : (
                <></>
              )}

              <Route
                path="/saas/admin/inventory/manage"
                element={
                  <InventoryControlSystem
                    setRegister={setRegister}
                    setNaved={setNaved}
                    setShowSaaSNavbar={setShowSaaSNavbar}
                  />
                }
              />

              <Route
                path="/saas/admin/inventoryManagement/materialReceiptCreationForm"
                element={
                  <MaterialReceiptCreationForm
                    setRegister={setRegister}
                    setNaved={setNaved}
                    setShowSaaSNavbar={setShowSaaSNavbar}
                  />
                }
              />
              <Route
                path="/saas/admin/inventoryManagement/materialReceiptTableList"
                element={
                  <MaterialReceiptTable
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/admin/inventoryManagement/materialReceiptBundlesPrintView"
                element={
                  <MRBundlesPrintView
                    setRegister={setRegister}
                    setNaved={setNaved}
                    setShowSaaSNavbar={setShowSaaSNavbar}
                  />
                }
              />
              <Route
                path="/saas/factory/ManageFGS"
                element={
                  <ManageFGS setRegister={setRegister} setNaved={setNaved} />
                }
              />
              <Route
                path="/saas/factory/manageRM"
                element={
                  <ManageRM setRegister={setRegister} setNaved={setNaved} />
                }
              />
              <Route
                path="/saas/factory/manageProduct"
                element={
                  <ManageProduct
                    setRegister={setRegister}
                    setNaved={setNaved}
                  />
                }
              />
              <Route
                path="/saas/factory/stockCount"
                element={
                  <StockCount setRegister={setRegister} setNaved={setNaved} />
                }
              />
              <Route
                path="/saas/factory/ScanMaterial"
                element={
                  <ScanMaterial setRegister={setRegister} setNaved={setNaved} />
                }
              />
              <Route
                path="/saas/factory/ScanResult"
                element={
                  <ScanResults setRegister={setRegister} setNaved={setNaved} />
                }
              />
              {localStorage.getItem("AccountType") === "2" &&
                localStorage.getItem("isSubscribedManu") &&
                !localStorage.getItem("planIdManu")?.includes("Core") ? (
                <Route
                  path="/saas/Admin/Transport"
                  element={
                    <TableViewTransport
                      setRegister={setRegister}
                      setNaved={setNaved}
                    />
                  }
                />
              ) : (
                ""
              )}
            </>
          )}

          <Route
            path="/saas/admin/client"
            element={
              <ClientTableView setRegister={setRegister} setNaved={setNaved} />
            }
          />

          <Route
            path="/saas/admin/supplier"
            element={
              <SupplierTableView setRegister={setRegister} setNaved={setNaved} />
            }
          />

          <Route
            path="/saas/admin/document"
            element={
              <DocumentTableView
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />
          {localStorage.getItem("AccountType") === "1" ? (
            <></>
          ) : (
            <Route
              path="/saas/admin/Mc"
              element={
                <McTableView setRegister={setRegister} setNaved={setNaved} />
              }
            />
          )}
          <Route
            path="/saas/admin/Sc"
            element={
              <ScTableView setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/admin/TeamMember"
            element={
              <TeamMemberTableView
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />
          {!localStorage.getItem("planId") &&
            !localStorage.getItem("planIdManu") &&
            localStorage.getItem("IsFreePlanActivated") === "true" ? (
            ""
          ) : (
            <Route
              path="/saas/admin/UserRoles"
              element={
                <UserRolesTableView
                  setRegister={setRegister}
                  setNaved={setNaved}
                />
              }
            />
          )}

          <Route
            path="/saas/notifications"
            element={
              <Notifications setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/invitations"
            element={
              <Invitations setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/profileDetails"
            element={
              <ProfileDetails
                setProfileSrc={setProfileSrc}
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />
          <Route
            path="/saas/profileSettings"
            element={
              <Settings
                setSetupProfile={setSetupProfile}
                setRegister={setRegister}
                setNaved={setNaved}
                active5={active5}
                setActive5={setActive5}
                active={active}
                setActive={setActive}
                setCn={setCn}
                cn={cn}
                setProfileSrc={setProfileSrc}
              />
            }
          />
          {localStorage.getItem("planIdManu")?.includes("Pro") ?
            <Route
              path="/saas/Loading/Calendar"
              element={
                <LoadingCalendar setRegister={setRegister} setNaved={setNaved} />
              }
            />
            :
            <></>
          }

          <Route
            path="/saas/Loading/ViewResult"
            element={
              <ResultView setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/Delivery/Calendar"
            element={
              <DeliveryCalender setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/Delivery/SaleOrderNote"
            element={
              <SaleOrderNote setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/Delivery/DeliveryNote"
            element={
              <DeliveryNote setRegister={setRegister} setNaved={setNaved} />
            }
          />
          <Route
            path="/saas/Delivery/DeliveryCardList"
            element={
              <DeliveryNoteListCard
                setRegister={setRegister}
                setNaved={setNaved}
              />
            }
          />
          <Route
            path="/saas/Delivery/DeliveryNote/print"
            element={
              <DeliverNotePrint
                setRegister={setRegister}
                setNaved={setNaved}
                setShowSaaSNavbar={setShowSaaSNavbar}
              />
            }
          />
        </>
      ) : (
        <></>
      )}
      <Route
        path="/machine/connect"
        element={
          <ProtectedRoute>
            <Connect />
          </ProtectedRoute>
        }
      />
      <Route path="/machine/dashboard" element={<MachineDashboard />} />
      <Route path="/machine/Orders" element={<Orders />} />
      <Route path="/machine/assignedLines" element={<AssignedLines />} />
      <Route
        path="/machine/item"
        element={<Item itemScanned={itemScanned} />}
      />
      <Route
        path="/machine/scanItem"
        element={<ScanItem setItemScanned={setItemScanned} />}
      />
      <Route path="/machine/production" element={<Production />} />
      <Route path="/machine/summary" element={<Summary />} />
      <Route path="/machine/print" element={<MachinePrintLabel />} />
      <Route path="/machine/login" element={<MachineLogin />} />
      <Route
        path="/machine/emailLogin"
        element={<LoginByEmail setLoading={setLoading} />}
      />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/under-maintenance" element={<SystemCrash setRegister={setRegister}
        setNaved={setNaved} />} />
    </Routes>
  );
}
