import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../Assets/Styles/projects.module.css";
import chart1 from "../../Assets/Images/Charts/chart1.jpeg";
import chart2 from "../../Assets/Images/Charts/chart2.jpeg";
import chart3 from "../../Assets/Images/Charts/chart3.jpeg";
import chart4 from "../../Assets/Images/Charts/chart4.jpeg";
import chart5 from "../../Assets/Images/Charts/chart5.jpeg";
import chart6 from "../../Assets/Images/Charts/chart6.jpeg";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import * as activitiesServices from "../../Services/activities-services.proxy"

const getActivitiesChart = activitiesServices.getActivitiesChart;

const styles = {
  active: {
    backgroundImage:
      "linear-gradient(to right, #e1e319, rgba(225, 227, 25, 0.3))",
  },
  notActive: {
    backgroundImage: "linear-gradient(to right, #fff, #fff)",
  },
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: IBM Plex Sans, sans-serif;
      font-size: 10px;
      box-sizing: border-box;
      min-height: 32px;
      width: 9rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
      border-radius: 0.75em;
      margin: 0.5em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      min-width: 11rem;
      height:150px;
      overflow: scroll;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default function ActivitiesDashboard(props) {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // useEffect(() => {
  //   if (ID) {
  //     setProject(ID);
  //   }
  // }, [ID]);

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [statusId, setStatusId] = useState(null);

  const [statuses, setStatuses] = useState([]);

  const [projectsSelectedIds, setProjectsSelectedIds] = useState([]);

  const [projects, setProjects] = useState([]);

  const [dashboardStatuses, setDashboardStatuses] = useState([]);
  const [dashboardProjects, setDashboardProjects] = useState([]);
  const [total, setTotal] = useState(0);

  const [assignees, setAssignees] = useState([]);
  const [assigneeFilterIds, setAssigneeFilterIds] = useState([]);

  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(false);

  const data = {
    ProjectIds: projectsSelectedIds,
    AssigneeIds: assigneeFilterIds
  }

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getActivitiesChart(data).then((x) => {
      if (apiTest) {
        setProjects(x.Projects);
        setStatuses(x.Statuses);
        setAssignees(x.Assignees)
        setDashboardStatuses(x.Statuses);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [projectsSelectedIds, assigneeFilterIds]);

  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid item container md={11.95} paddingBottom={2} paddingLeft={3.5}>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Dashboard
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sm={5}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 1 }}
          >
            <Grid item md={1.5} xs={3}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction={"row-reverse"}
          alignItems="center"
          paddingRight={{ md: 2, xs: 0 }}
          marginBottom={2}
          columnGap={2}
        >
          <Grid item xs={1.5}>
            <TextField
              select
              fullWidth
              label="Project Name"
              value={projectsSelectedIds}
              InputProps={{
                style: {
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              SelectProps={{
                multiple: true,
                MenuProps: {
                  disableScrollLock: true, sx: { height: "150px" },
                },
              }}
              onChange={(e, addedValue) => {
                setProjectsSelectedIds(prevState => {
                  const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                  if (currentIndex !== -1) {
                    return prevState.filter((_, index) => index !== currentIndex);
                  } else {
                    return [...prevState, addedValue.props.value];
                  }
                });
                // if(projects.includes(addedValue)) projects.filter((p)=> p !== addedValue)
                // else projects.push(addedValue)

                forceUpdate();

              }}
            >
              {projects?.map((s, index) => (
                <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              select
              fullWidth
              label="Assignee"
              value={assigneeFilterIds}
              InputProps={{
                style: {
                  fontSize: 12,
                  fontFamily: "Muli",
                },
              }}
              SelectProps={{
                multiple: true,
                MenuProps: {
                  disableScrollLock: true, sx: { height: "150px" },
                },
              }}
              onChange={(e, addedValue) => {
                setAssigneeFilterIds(prevState => {
                  const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                  if (currentIndex !== -1) {
                    return prevState.filter((_, index) => index !== currentIndex);
                  } else {
                    return [...prevState, addedValue.props.value];
                  }
                });
                forceUpdate();

              }}
            >
              {assignees?.map((s, index) => (
                <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          {projectsSelectedIds?.length > 0 || assigneeFilterIds?.length > 0 ? (
            <Grid item>
              <span
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setProjectsSelectedIds([]);
                  setAssigneeFilterIds([]);
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: 10,
                  color: "#000",
                  paddingTop: 25,
                  paddingRight: 15,
                }}
              >
                Clear filters
              </span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          xs={11.65}
          container
          gap={5}
          sx={{ borderRadius: 5, backgroundColor: "#fff" }}
          padding="1%"
          marginLeft="1.5%"
        >
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              md={1.5}
              sx={{
                fontFamily: "Muli",
                fontSize: 18,
                fontWeight: "bold",
                lineHeight: 1.44,
                textAlign: "left",
                color: "#101650",
              }}
            >
              Activity Status
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                opacity: 0.9,
                fontFamily: "Muli",
                fontSize: 16,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                color: "#707070",
              }}
            >

              {projectsSelectedIds.length > 0 ?
                <Grid item container direction="row">
                  {projectsSelectedIds.map((p, index) => (
                    <Grid item>{projects.find((i) => i.Id === p).Name} {index !== projectsSelectedIds.length - 1 ? " - " : ""}</Grid>
                  ))}
                </Grid>
                :
                "All Projects"}
            </Grid>
          </Grid>
          <Grid
            item
            xs={11.5}
            sx={{ borderLeft: "solid 1px #707070" }}
            container
            alignItems="center"
          >
            {statuses?.map((status, index) => (
              <Grid
                key={index}
                item
                container
                xs={2}
                alignItems="flex-start"
                justifyContent="center"
                sx={statusId === status.Id ? styles.active : styles.notActive}
                style={{
                  borderRight: "solid 1px #707070",
                  textAlign: "center",
                  color: "#0674b9",
                  fontFamily: "Muli",
                  fontSize: 14,
                  lineHeight: 1.47,
                  height: 40,
                  paddingTop: "0.7%",
                }}
              >
                <Grid item>{status.Name === "InProgress" ? "In Progress" : status.Name}</Grid>
              </Grid>
            ))}
          </Grid>
          {loadingData ? (
            <Grid
              item
              xs={11.5}
              container
              alignItem="center"
              justifyContent="center"
              minHeight={250}
            >
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={11.5} container>
              {statusId === null ? (
                dashboardStatuses?.map((status, index) => (
                  <Grid
                    item
                    xs={2}
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    container
                    minHeight={250}
                  >
                    <Grid
                      item
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundImage:
                          status.Name === "Draft"
                            ? `url(${chart1})`
                            : status.Name === "Planned"
                              ? `url(${chart2})`
                              : status.Name === "InProgress"
                                ? `url(${chart3})`
                                : status.Name === "Submitted for  1- Internal Approval"
                                  ? `url(${chart4})`
                                  : status.Name === "Submitted for  2- Internal Approval"
                                    ? `url(${chart5})`
                                    : `url(${chart6})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        paddingBottom: "5%",
                        width: '70%',
                        height: '200px',
                      }}
                      container
                    >
                      <Grid
                        item
                        marginTop={2}
                        sx={{
                          fontFamily: "Muli",
                          fontSize: 25,
                          fontWeight: "bold",
                          lineHeight: 1.44,
                          color: "#0674b9",
                          textAlign: "center"
                        }}
                      >
                        {status.Count} <br /> <span style={{ fontSize: 14, fontWeight: "normal" }}>{status.Count === 1?"Activity":"Activities"}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <></>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
