import React, { useState, useEffect } from "react";
import classing from "../../../../Assets/Styles/factory.module.css";
import { Grid, TextField, MenuItem } from "@mui/material";
import * as MACHINECONSTS from "../../../../../FactoryConsts";

const MachineSequence = (props) => {
  return (
    <Grid
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={3}
      paddingBottom={1}
      gap={2}
    >
      <Grid item xs={12} paddingTop={1} className={classing["Title"]}>
        Machine Sequence and output capacity
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
        {props.category === MACHINECONSTS.TRIMMING_MACHINE_ID || props.category === MACHINECONSTS.FORGING_MACHINE_ID ?
          "*Target output capacity per thread":"*Target output capacity kg/day"} 
          
        </Grid>
        <Grid item xs={3}>
          <TextField
             disabled={props.disabledEdit}
            value={props.MachineOutputCapicity}
            fullWidth
            type="number"
            InputProps={{
              style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
              inputProps: { min: 0 },
            }}
            placeholder="Output Capacity"
            onChange={(e) => {
              props.setMachineOutputCapicity(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
          Machine Predecessors
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
             disabled={props.disabledEdit}
            SelectProps={{
              MenuProps: { disableScrollLock: true },
              multiple: true,
            }}
            value={props.machinePredecessors}
            fullWidth
            select
            InputProps={{
              style: {
                height: "40px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              props.setMachinePredecessors(e.target.value);
            }}
          >
            {props.machinesAvailable
              ?.filter((x) => x.Id !== props.MACHINE_ID)
              .map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.Code + "/" + r.Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid item xs={12} className={classing["TFTitle"]} paddingLeft={1}>
          Machine Successors
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
             disabled={props.disabledEdit}
            SelectProps={{
              MenuProps: { disableScrollLock: true },
              multiple: true,
            }}
            value={props.machineSucessors}
            fullWidth
            select
            InputProps={{
              style: {
                height: "40px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              props.setMachineSucessors(e.target.value);
            }}
          >
            {props.machinesAvailable
              ?.filter((x) => x.Id !== props.MACHINE_ID)
              ?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.Code + "/" + r.Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        container
        direction={"row"}
        gap={4}
        paddingTop={1}
      >
        <Grid item className={classing["Label-Printer"]} paddingTop={3.5}>
          Machine sequence
        </Grid>
        {props.machinesSequences?.map((seq) => (
          <Grid item key={seq.Id}>
            <Grid item className={classing["Label-Printer"]}>
              {seq.Name}
            </Grid>
            <Grid
              item
              onClick={() => {
                if (seq.id === props.machineSequenceId) {
                  props.setMachineSequenceId(null);
                } else {
                  console.log(
                    props.machinesSequences.filter((x) => x.Id === seq.Id)
                  );
                  props.setMachineSequenceId(seq.Id);
                }
              }}
              className={
                props.machineSequenceId === seq.Id
                  ? classing["CheckedBox"]
                  : classing["notCheckedBox"]
              }
              marginTop={1}
            ></Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default MachineSequence;
