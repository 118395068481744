import * as constFile from "../../saasApiConst";
import axios from "axios";
//GET USER ROLES LIST
const GET_USER_ROLES_LIST = constFile.GET_USER_ROLES_LIST;
export const getUserRolesList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_USER_ROLES_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
//GET PERMISSIONS LIST
const GET_PERMISSION_LIST = constFile.GET_PERMISSION_LIST;
export const getPermissionList = (permissionGroupId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PERMISSION_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        permissionGroupId: permissionGroupId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//ADD USER ROLE
const ADD_USER_ROLE = constFile.ADD_USER_ROLE;
export const addUserRole = (Name, Description, AccountTypeId,Permissions) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ADD_USER_ROLE}`,
      {
        Id: null,
        Name: Name,
        Description: Description,
        AccountTypeId:AccountTypeId,
        Permissions: Permissions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//EDIT USER ROLE
const EDIT_USER_ROLE = constFile.EDIT_USER_ROLE;
export const EDITUserRole = (Id, Name, Description, Permissions) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + EDIT_USER_ROLE}`,
      {
        Id: Id,
        Name: Name,
        Description: Description,
        Permissions: Permissions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};
