import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import AddValueIcon from "../../../../../Assets/Images/Factory/plusIcon.png";
import RemoveIcon from "../../../../../Assets/Images/SalesOrder/RemoveIcon.png";



const RawMaterialSection = (props) => {

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  return (
    <>
      <Grid item md={0.7} sm={1.5} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          No. of Item References
        </Grid>
        <Grid item>
          <TextField
            value={props.nbOfReferences}
            fullWidth
            disabled
            InputProps={{
              style: {
                color: "#0674b9",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item overflow={"auto"}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}>Reference Name</TableCell>
                {props.references?.map((ref, index) => (
                  <TableCell
                    sx={
                      index === 2
                        ? styles.tableCellheadPr
                        : styles.tableCellhead
                    }
                  >
                    <Grid item className={classes["Lfields"]} xs={12}>
                      ref &nbsp;{index + 1}
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        value={ref.ReferenceId}
                        fullWidth
                        select
                        disabled
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                      >
                        {props.referencesNames.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.loadingData ? (
                <></>
              ) : (
                <TableRow>
                  <TableCell sx={styles.tableCellBody}>
                    *Reference Value
                  </TableCell>
                  {props.references?.map((ref, index) => (
                    <TableCell
                      key={index}
                      sx={
                        index === 2
                          ? styles.tableCellheadPr
                          : styles.tableCellhead
                      }
                    >
                      {ref?.Values?.map((valueRef, indexValue) => (
                        <Grid
                          width={266}
                          item
                          key={indexValue}
                          paddingTop={0.5}
                          container
                          direction={"row"}
                          gap={1}
                        >
                          {indexValue === ref?.Values.length - 1 ? (
                            <Grid
                              item
                              paddingTop={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                ref.Values = ref.Values.concat({ value: null });

                                forceUpdate();
                              }}
                            >
                              <img src={AddValueIcon} width={15} alt={"add"} />
                            </Grid>
                          ) : (
                            <Grid item width={15}></Grid>
                          )}
                          <Grid item>
                            <TextField
                              value={valueRef.value}
                              fullWidth
                              InputProps={{
                                style: {
                                  color: "#707070",
                                  height: "28px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                },
                              }}
                              placeholder={"Value"}
                              onChange={(e) => {
                                valueRef.value = e.target.value.trim();

                                forceUpdate();
                              }}
                            ></TextField>
                          </Grid>
                          {indexValue !== 0 ? (
                            <Grid
                              item
                              paddingTop={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                console.log(ref.Values);
                                ref.Values = ref.Values.filter(
                                  (val, indx) => indexValue !== indx
                                );

                                forceUpdate();
                              }}
                            >
                              <img src={RemoveIcon} width={15} alt={"add"} />
                            </Grid>
                          ) : (
                            <Grid item width={15}></Grid>
                          )}
                        </Grid>
                      ))}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container xs={10.6} direction={"row-reverse"}>
        <Grid
          item

          className={classes["Generate-Reference-Group"]}

          onClick={() => {
            if (
              props.references[0].Values[0].value &&
              props.references[1].Values[0].value &&
              props.references[2].Values[0].value
            )
              props.generateReferenceGroup();
          }}
        >
          Click here to generate reference group
        </Grid>
      </Grid>

      <Grid container paddingTop={2}>
        <Grid item container xs={9.8} direction={"row"} paddingBottom={2}>
          <Grid item xs={6} className={classes["Ref-Family-Group"]}>
            Ref Family Group
          </Grid>
          <Grid item xs={6} className={classes["Ref-Family-Group"]}>
            *Ref Group Abbreviation
          </Grid>
        </Grid>
        {props.referenceGroup.map((refGrp, index) => (
          <Grid
            key={index}
            item
            container
            xs={9.8}
            direction={"row"}
            paddingTop={0.5}
          >
            <Grid item container xs={6} direction={"row"} gap={1}>
              <Grid item className={classes["Ref-Family-Group"]} paddingTop={1}>
                {index + 1}
              </Grid>
              <Grid
                item
                xs={7}
                className={classes["Rectangle-3722"]}
                marginTop={0.5}
              >
                {refGrp.Code}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={11} paddingTop={0.5}>
                <TextField
                  value={refGrp.Abbreviation}
                  fullWidth
                  InputProps={{
                    style: {
                      color: "#707070",
                      height: "28px",
                      fontSize: 12,
                      fontFamily: "Muli",
                    },
                  }}
                  placeholder={"Abbreviation"}
                  onChange={(e) => {
                    refGrp.Abbreviation = e.target.value;

                    forceUpdate();
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              {index !== 0 ? (
                <button
                  className={classes["cancelbut"]}
                  onClick={() => {
                    props.setReferenceGroup(
                      props.referenceGroup.filter((val, indx) => index !== indx)
                    );
                  }}
                >
                  Remove Ref Group
                </button>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classes["SaveBut"]}
          disabled={props.isdisabled}
          onClick={() => {
            props.checkIfFieldsNotEmpty();
          }}
        >
          {props.saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setActiveTab(props.activeTab - 1);
          }}
        >
          Go Back
        </button>
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={12}
          fontWeight={"bold"}
          color={"#ea001e"}
        >
          {props.emptyField ? props.emptyFieldMessage : ""}
        </Grid>
      </Grid>
    </>
  )
}

const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 157,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 266,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 266,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
};

export default RawMaterialSection