import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import addIcon from "../../../Assets/Images/addIcon.png";
import removeIcon from "../../../Assets/Images/removeIcon.png";
import * as shapesServices from "../../../Services/sales-order-services.proxy";
import * as SALESORDERDCONSTS from "../../../../salesOrderConst";

const getPublishedShapes = shapesServices.getPublishedShapes;

const styles = {
  title: {
    fontFamily: "Muli",
    fontSize: "18px",
    color: " #101650",
    fontWeight: "bold",
  },

  subTitle: {
    fontFamily: "Muli",
    fontSize: "16px",
    color: " #101650",
  },

  shapeRectangle: {
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "center",
    color: "#0674b9",
    backgroundColor: "#fff",
  },

  code: {
    fontFamily: "Muli",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#0674b9",
  },
};

function ShapeLibraryList(props) {
  const [searchText, setSearchText] = useState(null);

  const [modify, setModify] = useState(false);

  const [toggleIcon, setToggleIcon] = useState();

  const [selectedShape, setSelectedShape] = useState([]);

  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [catalogShapes, setCatalogShapes] = useState([]);
  const [catalogueList, setCatalogueList] = useState([]);

  const [shapesLoading, setShapesLoading] = useState(false);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const listData = {
    "Pagination": {
      "PageSize": 100,
      "PageNumber": 1
    },
    "Search": searchText ? searchText : null,
    "VisibilityId": null,
    "StatusId": null,
    "TypeId": null,
    "CatalogueId": selectedCatalog,
    "NumOfAngles": null,
    "NumOfSides": null,
    "DimensionId": null
  }
  useEffect(() => {
    setShapesLoading(true)
    getPublishedShapes(searchText, listData).then((x) => {
      // props.setShapes(x.Shapes);
      // if (props.servicesTypes.find((s) => (s.Id === SALESORDERDCONSTS.THREAD_SERVICE_ID || s.Id === SALESORDERDCONSTS.CUT_SERVICE_ID))) {
      //   console.log('cut thread')
      //   setCatalogueList(x.CatalogueList);

      // }
      // else {
      //   console.log("not cut thread")
      //   setCatalogueList(x.CatalogueList?.filter((c) => c.Name !== 'nVent' && c.Name !== "Threading Services"));
      // }
      // if (props.servicesTypes?.length > 0) {
      //   console.log("props.servicesTypes",props.servicesTypes)
      //   if (!props.servicesTypes.find((s) => (s.Id === SALESORDERDCONSTS.THREAD_SERVICE_ID || s.Id === SALESORDERDCONSTS.CUT_SERVICE_ID))) {
      //     setCatalogueList(x.CatalogueList?.filter((c) => c.Name !== 'nVent' && c.Name !== 'Nvent Lenton' && c.Name !== "Threading Services"));

      //   }
      //   else if (!props.servicesTypes.find((s) => (s.Id === SALESORDERDCONSTS.THREAD_SERVICE_ID || s.Id === SALESORDERDCONSTS.CUT_SERVICE_ID))) {
      //     setCatalogueList(x.CatalogueList?.filter((c) => c.Name !== 'BS 8666:2005' && c.Name !== 'ACI'));

      //   }
      //   else setCatalogueList(x.CatalogueList);
      // }
      // else setCatalogueList([]);

      const serviceIds = props.servicesTypes?.map(service => service.Id);

      // Function to filter the array based on the conditions
      const filteredAr = x.CatalogueList?.filter(item => {
        if (serviceIds.includes(SALESORDERDCONSTS.CUT_SERVICE_ID) || serviceIds.includes(SALESORDERDCONSTS.THREAD_SERVICE_ID)) {
          // Include "nVent", "Threading Services", and "Nvent Lenton" if Id 1 or 2 exists in services
          if (
            item.Name?.toLowerCase() === "nvent" ||
            item.Name?.toLowerCase() === "threading services" ||
            item.Name?.toLowerCase() === "nvent lenton"
          ) {
            return true;
          }
        }

        if (serviceIds.includes(SALESORDERDCONSTS.CUT_AND_BEND_SERVICE_ID)) {
          // Include "ACI" and "BS 8666:2005" if Id 3 exists in services
          if (
            item.Name?.toLowerCase() === "aci" ||
            item.Name?.toLowerCase() === "bs 8666:2005"
          ) {
            return true;
          }
        }

        return false; // If none of the conditions match, exclude the item
      });
      setCatalogueList(filteredAr);



      props.setShapes(x.Catalogues?.find((c) => c.Id === selectedCatalog)?.Shapes);
      // setSelectedCatalog(x.CatalogueList?.find((c)=> c.Name === "BS 8666:2005")?.Id)
      // setSelectedCatalog(x.CatalogueList?.find((c)=> c.Name === "BS 8666:2005")?.Id)
      //console.log(x.CatalogueList?.find((c) => c.Name === "BS 8666:2005")?.Id)
      setCatalogShapes(x.Catalogues?.find((c) => c.Id === selectedCatalog)?.Shapes)
      props.setCatalogs(x.CatalogueList);
      setShapesLoading(false)
    });
  }, [searchText, selectedCatalog, props.servicesTypes, props.subprojectId]);
  return (
    <Grid container xs={12} height={"fit-content"} item>
      <Grid
        item
        xs={12}
        sx={styles.title}
        fontSize={{ sm: "13px", lg: "18px" }}
      >
        Universal Shape Library
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <TextField
          size="small"
          fullWidth
          value={searchText}
          onChange={inputHandler}
          placeholder="Search this list…"
          InputProps={{
            startAdornment: (
              <SearchIcon
                fontSize="small"
                htmlColor={"#707070"}
                style={{ paddingLeft: 5 }}
              />
            ),
          }}
          sx={{
            fontFamily: "Muli",
            backgroundColor: "white",
            borderRadius: 1,

            "& .MuiInput-underline:before": {
              borderBottom: 0,

              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid item container xs={12} direction="column" spacing={1} paddingTop={1}>
        <Grid item sx={styles.subTitle}>
          Choose a shape catalog
        </Grid>
        <Grid item>
          <TextField
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={selectedCatalog}
            defaultValue={catalogueList?.find((c) => c.Name === "BS 8666:2005")?.Id}
            fullWidth
            InputProps={{
              style: { height: "45px" },
            }}
            placeholder="Shape Catalog"

            onChange={(e) => {
              setSelectedCatalog(e.target.value);
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Shape Catalog
            </MenuItem>
            {catalogueList?.map((r) => (
              <MenuItem
                value={r.Id}
                key={r.Id}
                style={{ display: "block" }}
              >
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {shapesLoading ?
        <Grid item xs={12} textAlign="center" paddingTop={2}>
          <CircularProgress size="25px" />
        </Grid>
        :
        <></>}
      <Grid
        item
        container
        bgcolor={"#f2f2f2"}
        xs={12}
        gap={1}
        paddingBottom={5}
        paddingTop={2}
        justifyContent="center"
        alignItems="center"
      >
        {
          !catalogShapes && selectedCatalog && !shapesLoading ?
            <Grid item> There are no shapes available in this catalog.</Grid>
            :
            catalogShapes?.map((shape) => (
              <Grid
                key={shape.Id}
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={10}
              >
                <Grid
                  item
                  xs={4}
                  sx={styles.shapeRectangle}
                  alignSelf="flex-start"
                  paddingRight={2}
                  paddingLeft={2}
                  onClick={() => {
                    props.setSelectedShape(shape);
                    console.log(shape)
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                >
                  <img src={addIcon} />
                </Grid>
                <Grid
                  item
                  sx={styles.shapeRectangle}
                  xs={10}
                  container
                  direction="row"
                  height="150px"
                  key={shape.id}
                  marginBottom={2}
                  padding={1}

                // onClick={() => {

                // }}
                >
                  <Grid
                    item
                    alignSelf="center"
                    justifySelf="center"
                    paddingTop={4}
                    paddingBottom={4}
                    container
                  >
                    <img
                      src={shape?.File?.URL}
                      alt={"shapeImage"}
                      width={"100%"}
                      height={124}
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.code}>
                    Code:
                    {catalogueList?.find((c) => c.Id === selectedCatalog)?.Name === "nVent" ||
                      catalogueList?.find((c) => c.Id === selectedCatalog)?.Name === "Nvent Lenton" ||
                      catalogueList?.find((c) => c.Id === selectedCatalog)?.Name === "Threading Services" ?
                      shape.Name && shape.subCode ? shape.Name + " " + shape.subCode :
                        shape.Name
                      :
                      shape.subCode && shape.Code ? shape.Code + " " + shape.subCode :
                        shape.Code}
                  </Grid>
                </Grid>
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
}

export default ShapeLibraryList;
