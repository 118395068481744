//Project Order Books Cards
export const GET_PROJECT_ORDER_BOOKS_CARDS_LIST =
  "/sales-orders/project-order-books-cards";

//Project Order Books Table List
export const GET_PROJECT_ORDER_BOOKS_TABLE_LIST =
  "/sales-orders/project-order-books-table";

//Create Sales Order
export const CREATE_SALES_ORDER = "/sales-orders";

//Get Sales Order Cards List By Project Id
export const GET_SALES_ORDER_CARDS_BY_PROJECTID =
  "/sales-orders/sales-order-cards";

//Get Sales Order Table list By Project Id
export const GET_SALES_ORDER_TABLE_BY_PROJECTID =
  "/sales-orders/sales-order-table";

//Get Sales Order Statuses Dashboard
export const GET_SALES_ORDER_DASHBOARD = "/sales-orders/sales-order-dashboard";

//Get Sales Project Dashboard
export const GET_SALES_PROJECTS_ORDER_DASHBOARD =
  "/sales-orders/sales-order-projects-dashboard";

//Sales Order Progress Statuses
export const DRAFT = "Draft";
export const PLACED = "Placed";
export const PLANNED = "Planned";
export const IN_PRODUCTION = "In Production";
export const LOADING = "Loading";
export const DELIVERED = "Delivered";
export const ON_HOLD = "On Hold";
export const INTERRUPTED = "Interrupted";
export const PRODUCED = "Produced";

//Sales Order Statuses
export const DRAFT_ID = 1;
export const PLACED_ID = 2;
export const PLANNED_ID = 3;
export const IN_PRODUCTION_ID = 4;
export const LOADING_ID = 5;
export const DELIVERED_ID = 6;
export const HOLD_ID = 7;
export const INTERRUPTED_ID = 8;
export const PRODUCED_ID = 9;

export const statusList = [
  {
    Id: 1,
    Name: "Draft"
  },
  {
    Id: 2,
    Name: "Placed"
  },
  {
    Id: 3,
    Name: "Planned"
  },
  {
    Id: 4,
    Name: "In Production"
  },
  {
    Id: 5,
    Name: "Loading"
  },
  {
    Id: 6,
    Name: "Delivered"
  },
  {
    Id: 7,
    Name: "On Hold"
  },
  {
    Id: 8,
    Name: "Interrupted"
  },
  {
    Id: 9,
    Name: "Produced"
  }
]

//Planned Delivery Date Statuses
export const NA = "N/A";
export const REQUESTED = "Requested";
export const VALIDATED = "Validated";

export const NA_ID = 1;
export const REQUESTED_ID = 2;
export const VALIDATED_ID = 3;

//get published Shapes
export const GET_PUBLISHED_SHAPES = "/admin/shape/get-published-shapes";

//Elemets Measurment Types
export const MEASURE_VARIABLE = 1;
export const MEASURE_CONSTANT = 2;
export const MEASURE_CALCULATED = 3;

//sales order Creation

//Create or Edit Data
export const CREATE_OR_EDIT_DATA = "/sales-orders/create-or-edit-data";

//unit of measures
export const METRIC_MEASURES = 1;
export const IMPERAIL_MEASURES = 2;

// Upload File
export const UPLOAD_FILE = "/sales-orders/upload-file";

// Delete File
export const DELETE_FILE = "/sales-orders/delete-file";

//Update sale order
export const UPDATE_SALES_ORDER = "/sales-orders/update";

//Place Sales Order
export const PLACE_SALES_ORDER = "/sales-orders/change-status";

//Place Sales Order
export const CHANGE_SALES_ORDER_STATUS = "/sales-orders/change-status";

//calculate length
export const CALCULATE_ACTUAL_LENGTH = "/sales-orders/calculate-length";

//Suggest Date
export const SUGGEST_DATE = "/sales-orders/suggest-date";

//Suggest Date
export const ACCEPT_SUGGESTED_DATE = "/sales-orders/accept-suggested-date";

//Import SDI
export const IMPORT_SDI = "/sales-orders/import-sdi-file";

//Export SDI
export const EXPORT_SDI = "/sales-orders/generate-sdi-file";

//get data scene  by id
export const GET_DATASCENE_BY_ID = "/sales-orders/data-scene";

// Calender
export const SALES_ORDER_CALENDER = "/sales-orders/sales-order-calendar";

//Print Label
export const PRINT_LABEL = "/label/print-sales-order";

//List of Created Projects
export const PROJECTS_LIST_DATA = "/sales-orders/projects-list-data";

export const RAW_MATERIAL_LABEL_ID = 1;
export const REMNENT_LABEL_ID = 2;
export const FINISHED_GOODS_LABEL_ID = 3;
export const DELIVERY_NOTE_LABEL_ID = 4;
export const PRODUCT_LABEL_ID = 5;



//Hold SO
export const HOLD_SO = "/sales-orders/request-hold-sales-order";

//shape input data
export const GET_LINE_SHAPE_INPUT_DATA = "/sales-orders/line-shape-input-data";

export const GET_FACTORY_PRODUCT_REFERENCES = "/sales-orders/product-reference";

export const GET_WEIGHT_PEREMETER = "/sales-orders/weight-permeter";

//member services
export const CUT_SERVICE_ID = 1;
export const THREAD_SERVICE_ID = 2;
export const CUT_AND_BEND_SERVICE_ID = 3;


//sales order types

export const PRODUCTION_ID = 1;
export const TEST_SAMPLE_ID = 2;

export const salesOrderTypes = [
  {
    Id: 1,
    Name: 'Production'

  },
  {
    Id: 2,
    Name: 'Test Sample'

  }
]

