import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import { Grid, CircularProgress } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import ActivityDetails from "./ActivityDetails";
import { useNavigate, useLocation } from "react-router-dom";
import SubProjectDocuments from "./SubProjectDocuments";
import * as activityServices from "../../../Services/activities-services.proxy";
const getProjectList = activityServices.getProjectList;

const getPhasesAndSubs = activityServices.getPhasesAndSubs;
const CreateActivities = (props) => {
  useEffect(() => {
    props.setShowSaaSNavbar(true);
  }, [props.setShowSaaSNavbar]);
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const [projects, setProjects] = useState([]);
  const [openedCard, setopenedCard] = useState([]);
  const [viewTable, setViewTable] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [subProjectId, setSubProjectId] = useState(null);
  const [activityId, setActivityId] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [activityDetailsId, setActivityDetailsId] = useState(null);
  const [modify, setModify] = useState(false);
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(false);

  const [disableSelection, setDisableSelection] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ActId = query.get("id");
  const editFields = query.get("edit");

  const [fromPage, setFromPage] = useState();

  useEffect(()=> {
    console.log("activvv",activityId);
  },[activityId])
  useEffect(() => {
    // side effects
    let apiTest = true;
    if (apiTest) {
     if(location?.state?.id) setActivityId(location?.state?.id);
      setActivityDetailsId(location?.state?.detailsId);
      setFromPage(location?.state?.from)
      if (location?.state?.edit === false) {
        setDisableSelection(true);
        setViewOnly(true);
      }
      if (ActId && editFields === "false") {
        setActivityId(ActId);
        setDisableSelection(true);
        setViewOnly(true);
      }
    }
    setModify(!modify);
    // cleanup
    return () => {
      apiTest = false;
    };
  }, []);
  useEffect(() => {
    getProjectList().then((x) => {
      setProjects(x);
    });
  }, []);
  const getProjectDetails = (projectId) => {
    setProjectDetailsLoading(true);
    getPhasesAndSubs(projectId).then((x) => {
      setProjectDetails(x.Sections);
      setProjectDetailsLoading(false);
    });
  };
  const renderSubProjects = (parentArray) => {
    return parentArray.map((parent) => (
      <Grid container key={parent.Id}>
        <Grid
          item
          className={classes["Rectangle-1467"]}
          xs={11.5}
          container
          direction={"row"}
          color={
            subProjectId === parent.Id
              ? "#fff"
              : openedCard?.includes(parent.Id)
                ? "#0674b9"
                : "rgba(6, 116, 185, 0.5)"
          }
          bgcolor={subProjectId === parent.Id ? "#0674b9" : "#fff"}
          onClick={() => {
            if (!disableSelection) {
              setSubProjectId(parent.Id);
              setModify(!modify);
            }
          }}
        >
          <Grid
            item
            xs={2}
            md={1}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              if (openedCard.includes(parent.Id)) {
                let arrayIDs = openedCard;
                arrayIDs = arrayIDs.filter(function (itemphase) {
                  return itemphase !== parent.Id;
                });
                setopenedCard(arrayIDs);
              } else {
                let arrayIDs = openedCard;

                setopenedCard(arrayIDs.concat(parent.Id));
              }
            }}
          >
            {parent.Sections?.length > 0 ? (
              openedCard?.includes(parent.Id) ? (
                "-"
              ) : (
                "+"
              )
            ) : (
              <></>
            )}
          </Grid>
          <Grid item paddingLeft={2.5} xs={9} md={10}>
            {parent.Name}
          </Grid>
        </Grid>
        {openedCard?.includes(parent.Id) ? (
          renderSubProjects(parent.Sections)
        ) : (
          <></>
        )}
      </Grid>
    ));
  };
  useEffect(() => {
    if (activityId) {
      setDisableSelection(true);
    }
  }, [activityId]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {viewTable ? (
        <SubProjectDocuments
          setViewTable={setViewTable}
          subProjectId={subProjectId}
        />
      ) : (
        <Grid
          container
          paddingTop={6}
          paddingLeft={{ md: 6, xs: 6 }}
          paddingBottom={5}
        >
          <Grid container item>
            <Grid item md={1} xs={2} className={classes["page-title"]}>
              Activities
            </Grid>
            <Grid
              item
              md={9.7}
              xs={7.8}
              className={classes["Line12"]}
              marginTop={1.3}
            ></Grid>
            <Grid
              item
              md={1}
              xs={2}
              className={classes["View-all-Activities"]}
              paddingLeft={1}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => {
                navigate("/saas/execution/activitiesTable");
              }}
            >
              View all Activities
            </Grid>
          </Grid>
          <Grid
            xs={10}
            container
            item
            md={8.55}
            bgcolor={"#fff"}
            marginTop={3.7}
          >
            <ActivityDetails
              setViewTable={setViewTable}
              subProjectId={subProjectId}
              activityId={activityId}
              setActivityId={setActivityId}
              setSubProjectId={setSubProjectId}
              setProjectId={setProjectId}
              getProjectDetails={getProjectDetails}
              viewOnly={viewOnly}
              activityDetailsId={activityDetailsId}
              setActivityDetailsId={setActivityDetailsId}
              modify={modify}
              setModify={setModify}
              setViewOnly={setViewOnly}
              from={fromPage}
            />
          </Grid>
          <Grid
            container
            xs={10}
            height={"fit-content"}
            item
            md={3}
            marginLeft={1}
          >
            <Grid item xs={12} className={classes["page-title"]}>
              Select a project:
            </Grid>
            <Grid
              item
              container
              bgcolor={"#f2f2f2"}
              xs={12}
              gap={1}
              paddingBottom={5}
              paddingLeft={1.5}
              paddingTop={1}
            >
              {projects.map((project) => (
                <Grid container key={project.Id}>
                  <Grid
                    item
                    className={classes["Rectangle-1467"]}
                    xs={11.5}
                    container
                    direction={"row"}
                    onClick={() => {
                      if (!disableSelection) {
                        getProjectDetails(project.Id);
                        setProjectId(project.Id);
                      }
                    }}
                    color={projectId === project.Id ? "#fff" : "#0674b9"}
                    bgcolor={projectId === project.Id ? "#0674b9" : "#fff"}
                  >
                    <Grid item paddingLeft={2.5}>
                      {project.Name}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container height={"fit-content"} item xs={12} paddingTop={2}>
              <Grid item xs={12} className={classes["page-title"]}>
                Select Subproject to create the activity:
              </Grid>
              <Grid
                item
                container
                bgcolor={"#f2f2f2"}
                xs={12}
                gap={1}
                paddingBottom={5}
                paddingLeft={1.5}
                paddingTop={1}
              >
                {projectDetailsLoading ? (
                  <Grid item container justifyContent="center" marginTop={5}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <>
                    {projectDetails?.map((phase) => (
                      <Grid container key={phase.Id}>
                        <Grid
                          item
                          className={classes["Rectangle-1466"]}
                          xs={11.5}
                          container
                          direction={"row"}
                          color={"#0674b9"}
                        >
                          <Grid
                            item
                            xs={2}
                            md={1}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            onClick={(e) => {
                              if (openedCard.includes(phase.Id)) {
                                let arrayIDs = openedCard;
                                arrayIDs = arrayIDs.filter(function (itemphase) {
                                  return itemphase !== phase.Id;
                                });
                                setopenedCard(arrayIDs);
                              } else {
                                let arrayIDs = openedCard;

                                setopenedCard(arrayIDs.concat(phase.Id));
                              }
                            }}
                          >
                            {phase.Sections?.length > 0 ? (
                              openedCard?.includes(phase.Id) ? (
                                "-"
                              ) : (
                                "+"
                              )
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item paddingLeft={2.5} xs={9} md={10}>
                            {phase.Name}
                          </Grid>
                        </Grid>
                        {openedCard?.includes(phase.Id) ? (
                          renderSubProjects(phase.Sections)
                        ) : (
                          <></>
                        )}
                      </Grid>
                    ))}
                  </>
                )}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CreateActivities;
