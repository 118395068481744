import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Tooltip } from "@mui/material";
import infoTip from "../../../Assets/Images/form/info.png";
import { min } from "date-fns";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      height: "40px",
    },
  },
});

function Form2(props) {
  const style = {
    blueTip: {
      width: 200,
    },
  };
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [positionError, setPositionError] = useState(false);
  const [positionInvalid, setPositionInvalid] = useState(false);

  const [contactNameError, setcontactNameError] = useState(false);
  const [contactNameInvalid, setcontactNameInvalid] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [nbofFactoriesError, setNbofFactoriesError] = useState(false);
  const [NbofFactoriesInvalid, setNbofFactoriesInvalid] = useState(false);

  const [FactoriesVolumeError, setFactoriesVolumeError] = useState(false);
  const [FactoriesVolumeInvalid, setFactoriesVolumeInvalid] = useState(false);

  const [agreeCheckError, SetAgreeCheckError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isoCodes, setisoCodes] = useState([]);
  const [fulled, setFulled] = useState(false);

  useEffect(() => {
    for (var i = 0; i < props.countries.length; i++) {
      isoCodes[i] = props.countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });
  useEffect(() => {
    if (props.loggedInManu) {
      if (!props.factoriesVolume || !props.nbOfFactories) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      if (
        !props.personalName ||
        !props.position ||
        !props.personalEmail ||
        !props.personalPhoneNumber ||
        !props.agreeCheck ||
        positionError ||
        phoneNumberError ||
        emailError ||
        positionInvalid ||
        emailInvalid ||
        contactNameError ||
        contactNameInvalid
      ) {
        setIsDisabled(true);
      } else {
        if (localStorage.getItem("SubscriptionTypeId") === "3") {
          if (!props.factoriesVolume || !props.nbOfFactories) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
        } else {
          setIsDisabled(false);
        }
      }
    }
  });
  useEffect(() => {
    for (var i = 0; i < props.countries.length; i++) {
      isoCodes[i] = props.countries[i].IsoCode;
    }
  });
  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox">
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12}>
          <Grid item md={9} xs={11}>
            <div className="contactInfo"> Primary Contact Information </div>
          </Grid>

          <Grid item md={3} xs={1} container direction={"row-reverse"}>
            <IconButton
              onClick={(e) => {
                navigate("/");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"} spacing={1.5}>
          {props.loggedInManu ? (
            ""
          ) : (
            <>
              {" "}
              <Grid item md={11.5}>
                <CssTextField
                  label="Contact Name"
                  size="small"
                  value={props.personalName}
                  className="textField"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "16px",
                    },
                  }}
                  error={contactNameError}
                  helperText={
                    contactNameError
                      ? contactNameInvalid
                        ? "Name must be less than 64 characters"
                        : "Enter your name"
                      : ""
                  }
                  onChange={(e) => {
                    props.setPersonalName(e.target.value);
                    if (e.target.value === "") {
                      setcontactNameError(true);
                      setcontactNameInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                      (e.target.value.length > 64)
                    ) {
                      setcontactNameError(true);
                      setcontactNameInvalid(true);
                    } else {
                      setcontactNameError(false);
                      setcontactNameInvalid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item md={11.5}>
                <CssTextField
                  label="Position"
                  size="small"
                  className="textField"
                  value={props.position}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "16px",
                    },
                  }}
                  error={positionError}
                  helperText={
                    positionError
                      ? positionInvalid
                        ? "Postion must be less than 20 characters"
                        : "Enter your Position"
                      : ""
                  }
                  onChange={(e) => {
                    props.setPosition(e.target.value);
                    if (e.target.value === "") {
                      setPositionError(true);
                      setPositionInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                      (e.target.value.length > 20)
                    ) {
                      setPositionError(true);
                      setPositionInvalid(true);
                    } else {
                      setPositionError(false);
                      setPositionInvalid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item md={11.5}>
                <CssTextField
                  label="Primary Email"
                  size="small"
                  className="textField"
                  value={props.personalEmail}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "16px",
                    },
                  }}
                  error={emailError}
                  helperText={
                    emailError
                      ? emailInvalid
                        ? "Enter a valid email"
                        : "Enter an email adress"
                      : ""
                  }
                  onChange={(e) => {
                    props.setPersonalEmail(e.target.value);

                    if (e.target.value === "") {
                      setEmailError(true);
                      setEmailInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                        !e.target.value.match(
                          /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                        ) ||
                      e.target.value > 64
                    ) {
                      setEmailError(true);
                      setEmailInvalid(true);
                    } else {
                      setEmailError(false);
                      setEmailInvalid(false);
                    }
                  }}
                ></CssTextField>
              </Grid>
              <Grid item height={"18%"} md={11.5}>
                {fulled ? (
                  <PhoneInput
                    placeholder="Enter phone number"
                    enableAreaCodes={true}
                    value={props.personalPhoneNumber}
                    onChange={(phone) => {
                      props.setPersonalPhoneNumber(phone);
                      if (
                        props.personalPhoneNumber === "" ||
                        props.personalPhoneNumber.length < 4 ||
                        props.personalPhoneNumber.length > 25
                      ) {
                        setPhoneNumberError(true);
                      } else {
                        setPhoneNumberError(false);
                      }
                    }}
                    country={localStorage.getItem("userCountry")?.toLowerCase()}
                    inputStyle={{
                      borderColor: "#707070",
                      width: "100%",
                      height: 40,
                      fontFamily: "Muli",
                      fontsize: "16px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </>
          )}

          {localStorage.getItem("SubscriptionTypeId") === "3" ? (
            <>
              <Grid item md={11.5}>
                <CssTextField
                  label="Number of factories"
                  size="small"
                  value={props.nbOfFactories}
                  type="number"
                  className="textField"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "16px",
                    },
                  }}
                  onChange={(e) => {
                    props.setNbOfFactories(e.target.value);
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <IconButton edge="end">
                        <BlueTip
                          title={
                            "Number of factories: The number of factories that the company wants to implement the manufacturing solution."
                          }
                          sx={style.blueTip}
                          placement="right"
                        >
                          <img width={"20px"} src={infoTip} alt={"close"}></img>
                        </BlueTip>
                      </IconButton>
                    ),
                  }}
                ></CssTextField>
              </Grid>
              <Grid item md={11.5}>
                <CssTextField
                  label="Factory volume"
                  size="small"
                  value={props.factoriesVolume}
                  type="number"
                  className="textField"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "16px",
                    },
                  }}
                  onChange={(e) => {
                    props.setFactoriesVolume(e.target.value);
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <IconButton edge="end">
                        <BlueTip
                          title={
                            "Factory volume: The estimated average monthly volume per factory (per ton)."
                          }
                          sx={style.blueTip}
                          placement="right"
                        >
                          <img width={"20px"} src={infoTip} alt={"close"}></img>
                        </BlueTip>
                      </IconButton>
                    ),
                  }}
                ></CssTextField>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
        {props.loggedInManu ? (
          ""
        ) : (
          <>
            <Grid item container md={12} direction={"row"}>
              <Grid item md={1}>
                <input
                  className="checkBox"
                  type="checkbox"
                  defaultChecked={props.agreeCheck}
                  onChange={(e) => {
                    props.setAgreeCheck(e.target.checked);

                    SetAgreeCheckError(!e.target.checked);
                  }}
                ></input>
              </Grid>

              <Grid item md={11}>
                <span className="agreement">
                  <span className="text-style-1">I agree to the </span>
                  <a
                    href="/#"
                    style={{ textDecoration: "none", color: "#0b5cab" }}
                  >
                    Terms and Conditions.
                  </a>
                </span>
              </Grid>
              {agreeCheckError ? (
                <span class="agreeError">
                  Please read and agree to the terms and conditions.
                </span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={12}>
              <span className="policy">
                By Submitting, you agree to the processing of your personal data
                by Pilecubes as described in the
                <span className="text-style-1"> Privacy Statement</span>
                <span className="text-style-2">.</span>
              </span>
            </Grid>
          </>
        )}

        <Grid item container md={12} direction={"row"} marginTop={3.5}>
          <Grid item md={8} xs={8}>
            {props.loggedInManu ? (
              ""
            ) : (
              <Button
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#CED2E1";
                  e.target.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                }}
                onClick={(e) => {
                  props.back(e);
                  e.target.style.backgroundColor = "#fff";
                }}
                variant="contained"
                style={{
                  textTransform: "none",
                  borderRadius: "5px",
                  width: "71px",
                  height: "32px",
                  fontSize: "19px",
                  fontFamily: "Muli",
                  backgroundColor: "#fff",
                  color: "rgba(16, 22, 80, 0.7)",
                  marginBottom: 7,
                }}
              >
                Back
              </Button>
            )}

            <button
              className="buttonNext1"
              disabled={isDisabled || props.submitLoading}
              style={{ marginLeft: 20 }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                props.sendContactUs();
                // register(e);
              }}
              variant="contained"
            >
              {props.submitLoading? "Submitting ...":"Submit"}
            </button>
          </Grid>
          <Grid item md={4} xs={4} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 2 of 2</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form2;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
// function register(e) {
//   console.log(info);
//   console.log(
//     registrationEmail,
//     tenantId,
//     inviterTenantId,
//     projectId,
//     subProjectId
//   );
//   axios
//     .post(`${process.env.REACT_APP_URL + MC_REGISTER}`, {
//       TenantId: tenantId,
//       Name: info.Name,
//       RegistrationNumber: info.RegistrationNumber,
//       Address: info.Address,
//       CompanyPhoneNumber: info.CompanyPhoneNumber,
//       AgreementAcceptance: true,
//       CountryId: info.CountryId,
//       SpecialityId: info.SpecialityId,
//       SpecialityOther: info.SpecialityOther,
//       EmployeesRangeId: info.EmployeesRangeId,
//       User: {
//         UserId: info.User.UserId,
//         Password: info.User.Password,
//         Email: tenantId ? null : info.User.Email,
//         UserDetails: {
//           Name: info.User.UserDetails.Name,
//           PersonalEmail: info.User.UserDetails.PersonalEmail,
//           Position: info.User.UserDetails.Position,
//           PhoneNumber: info.User.UserDetails.PhoneNumber,
//           UserId: info.User.UserDetails.UserId,
//         },
//       },
//       InviterTenantId: inviterTenantId,
//       ProjectId: projectId,
//       SubProjectId: subProjectId,
//     })
//     .then((res) => {
//       localStorage.setItem("accessToken", res.data.accessToken);
//       localStorage.setItem("isVerified", "false");
//       localStorage.setItem("companyName", info.Name);
//       localStorage.setItem("AccountType", 1);
//       localStorage.setItem(
//         "isGuest",
//         jwt(localStorage.getItem("accessToken")).IsGuest
//       );
//       setAccountName(info.Name);
//       continues(e);
//     })
//     .catch((err) => {
//       setOpen(true);
//       setErrMsg(err.response.data.error);
//     });
// }
