import React, { useState, useEffect, useMemo } from "react";
import { ClickAwayListener, Grid, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../Assets/Styles/MachineOptimization.module.css";
import MachineBlockIcon from "../../../Assets/Images/Factory/mbhighres.png";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import Switch from "@mui/material/Switch";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Zoom from "@mui/material/Zoom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as productionServices from "../../../Services/production-planning-services";
import ErrorModal from "../../../SharedComponents/ErrorModal";

const getMachineOccupation = productionServices.getMachineOccupation;
const machineOptimazation = productionServices.machineOptimazation;
const validateOptimazation = productionServices.validateOptimazation;

const OptimizationSystem = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [loadingData, setLoadingData] = useState(true);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const IS_VALIDATED = query?.get("IsValidated");
  const MACHINE_ID = query.get("id");
  const MACHINE_NAME = query.get("name");
  const MACHINE_CATEGORY = query.get("category");
  const PROCDUCTION_ORDER = query.get("Order");

  const [saving, setSaving] = useState(false);
  const handleTooltipClose = () => {
    setChosenLineId(null);
  };
  const [chosenId, setChosenId] = useState(null);
  const [chosenLineId, setChosenLineId] = useState(null);
  const [noRM, setnoRM] = useState(false);

  const [minScrapGeneration, setMinScrapGeneration] = useState(true);
  const [maxProductionTime, setMaxProductionTime] = useState(false);
  const [maximizeOfRemenantsUse, setMaximizeOfRemenantsUse] = useState(false);
  const [finishedGoodsTW, setFinishedGoodsTWe] = useState(0);
  const [materialUsedTW, setMaterialUsedTWe] = useState(0);
  const [scrapPercentage, setScrapPercentagee] = useState("0");
  const [remnantPercentage, setRemnantPercentagee] = useState("0");
  const COMBINATION_ID = query?.get("combId");
  const [diametersForMachine, setDiametersForMachine] = useState([]);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  useEffect(() => {
    if (MACHINE_ID) {
      getMachineOccupation(MACHINE_ID, COMBINATION_ID).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
          setLoadingData(false);

        } else {
          setDiametersForMachine(x.Diameters);
          setFinishedGoodsTWe(x.FinishedGoodsTW);
          setMaterialUsedTWe(x.MaterialUsedTW);
          setScrapPercentagee(x.ScrapPercentage);
          setRemnantPercentagee(x.RemnantPercentage);
          if (!x.Diameters[0].Output) {
            machineOptimazation({
              MachineId: MACHINE_ID,
              AlgorithmId: 1,
              OptimizationInputs: x.Diameters,
            }).then((x2) => {
              setLoadingData(false);
              if (x2.status || x2.statusCode) {
                if (x2.status) setBEerror(x2.error)
                if (x2.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                setOpenErrorModal(true);
              } else {
                setDiametersForMachine(x2.Diameters);
                setFinishedGoodsTWe(x2.FinishedGoodsTW);
                setMaterialUsedTWe(x2.MaterialUsedTW);
                setScrapPercentagee(x2.ScrapPercentage);
                setRemnantPercentagee(x2.RemnantPercentage);
              }
            });
          } else {
            setLoadingData(false);
            if (x.Algorithm === 1) {
              setMinScrapGeneration(true);
            } else if (x.Algorithm === 2) {
              setMaxProductionTime(true);
              setMinScrapGeneration(false);
            } else if (x.Algorithm === 3) {
              setMaximizeOfRemenantsUse(true);
              setMinScrapGeneration(false);
            }
          }
        }
      });
    }
  }, [MACHINE_ID]);

  const optimize = (AlgorithmId) => {
    setLoadingData(true);
    machineOptimazation({
      MachineId: MACHINE_ID,
      AlgorithmId: AlgorithmId,
      OptimizationInputs: diametersForMachine,
    }).then((x) => {
      setLoadingData(false);
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setDiametersForMachine(x.Diameters);
        setFinishedGoodsTWe(x.FinishedGoodsTW);
        setMaterialUsedTWe(x.MaterialUsedTW);
        setScrapPercentagee(x.ScrapPercentage);
        setRemnantPercentagee(x.RemnantPercentage);
      }

    });
  };
  const validate = () => {
    setSaving(true);
    validateOptimazation({
      MachineId: MACHINE_ID,
      CombinationId: COMBINATION_ID,

      Algorithm: minScrapGeneration
        ? 1
        : maxProductionTime
          ? 2
          : maximizeOfRemenantsUse
            ? 3
            : 0,
      FinishedGoodsTW: finishedGoodsTW,
      MaterialUsedTW: materialUsedTW,
      ScrapPercentage: scrapPercentage,
      RemnantPercentage: remnantPercentage,
      Diameters: diametersForMachine,
    }).then((x) => {
      setSaving(false);
      if (x.status || x.statusCode) {
        // setnoRM(true);
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);

      } else {
        navigate(-1);
      }
    });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: props.adminPage ? null : `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid
        item
        xs={12}
        container
        rowSpacing={0}
        padding="2% 0 0 2%"
        paddingBottom={5}
      >
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            container
            direction={"row"}
            xs={9.5}
            sm={4}
            lg={2.5}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <img src={MachineBlockIcon} alt={"img"} width={40} />
            <Grid
              item
              paddingLeft={1}
              paddingTop={1}
              fontSize={{ md: 18, xs: 12 }}
            >
              {MACHINE_NAME + " " + " occupation list"}
            </Grid>
          </Grid>
          <Grid
            item
            xs={0}
            sm={5}
            md={4}
            lg={7}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item container direction={"row"} xs={2} sm={3} md={2} lg={2}>
            <Grid item paddingLeft={{ sm: 10, xs: 2 }}>
              <button
                className={classes["Go-Back"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          paddingLeft={5}
          item
          xs={12}
          fontSize={{ md: 18, xs: 12 }}
          sx={{
            fontFamily: "Muli",
            lineHeight: 1.44,
            textAlign: "left",
            color: "#101650",
          }}
        >
          {PROCDUCTION_ORDER}
        </Grid>
        {/* <Grid
          item
          className={classes["Rectangle-4046"]}
          container
          marginTop={2}
          marginLeft={{ md: 5, sm: 0 }}
          paddingTop={1}
          paddingLeft={1}
          lg={10.3}
          md={11}
          sm={11.7}
          gap={{ lg: 3, md: 0.5, sm: 0.2 }}
          direction={"row"}
          paddingBottom={1}
        >
          <Grid item lg={2.2} md={1.7} sm={1.2} container direction={"column"}>
            <Grid
              item
              className={classes["Optimization-Parameters"]}
              fontSize={{ md: 15, sm: 12 }}
            >
              Optimization Parameters
            </Grid>
            <Grid
              item
              className={classes["MachineType"]}
              fontSize={{ md: 15, sm: 12 }}
            >
              {MACHINE_CATEGORY}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={{ sm: "row", xs: "column" }}
            lg={7.2}
            md={7.8}
            sm={7.5}
            justifyContent={{ sm: "center", xs: "left" }}
            gap={{ lg: "8%", md: "2%", sm: "2%" }}
          >
            <Grid item paddingTop={0.2}>
              <Grid
                item
                className={classes["Opt-params"]}
                fontSize={{ md: 13, sm: 10 }}
              >
                Minimize Scrap Generation
              </Grid>
              <Grid
                item
                container
                justifyContent={{ sm: "center", xs: "left" }}
              >
                <Android12Switch
                  checked={minScrapGeneration}
                  onChange={(e) => {
                    setMinScrapGeneration(e.target.checked);
                    if (e.target.checked) {
                      optimize(1);
                      setMaxProductionTime(false);
                      setMaximizeOfRemenantsUse(false);
                    }
                  }}
                />
                <Grid
                  container
                  item
                  justifyContent={{ sm: "center", xs: "left" }}
                  className={classes["Enabled"]}
                >
                  {minScrapGeneration ? "Enabled" : "Disabled"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item paddingTop={0.2}>
              <Grid
                item
                className={classes["Opt-params"]}
                fontSize={{ md: 13, sm: 10 }}
              >
                Maximize Production Time
              </Grid>
              <Grid
                item
                container
                justifyContent={{ sm: "center", xs: "left" }}
              >
                <Android12Switch
                  checked={maxProductionTime}
                  onChange={(e) => {
                    setMaxProductionTime(e.target.checked);
                    if (e.target.checked) {
                      optimize(2);
                      setMinScrapGeneration(false);
                      setMaximizeOfRemenantsUse(false);
                    }
                  }}
                />
                <Grid
                  container
                  item
                  justifyContent={{ sm: "center", xs: "left" }}
                  className={classes["Enabled"]}
                >
                  {maxProductionTime ? "Enabled" : "Disabled"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item paddingTop={0.2}>
              <Grid
                item
                className={classes["Opt-params"]}
                fontSize={{ md: 13, sm: 10 }}
              >
                Maximize Use of Remnants
              </Grid>
              <Grid
                item
                container
                justifyContent={{ sm: "center", xs: "left" }}
              >
                <Android12Switch
                  checked={maximizeOfRemenantsUse}
                  onChange={(e) => {
                    setMaximizeOfRemenantsUse(e.target.checked);
                    if (e.target.checked) {
                      optimize(3);
                      setMinScrapGeneration(false);
                      setMaxProductionTime(false);
                    }
                  }}
                />
                <Grid
                  container
                  item
                  justifyContent={{ sm: "center", xs: "left" }}
                  className={classes["Enabled"]}
                >
                  {maximizeOfRemenantsUse ? "Enabled" : "Disabled"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={2}
            md={2.3}
            sm={3}
            paddingTop={0.2}
            direction={"column"}
          >
            <Grid
              item
              container
              xs={12}
              direction={"row-reverse"}
              gap={1}
              paddingTop={4}
            >
              <button
                className={classes["SaveBut"]}
                disabled={IS_VALIDATED === "true" || saving ? true : false}
                onClick={() => {
                  validate();
                }}
              >
                {saving ? "Saving..." : "Save"}
              </button>
              <button
                className={classes["cancelbut"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Grid> */}

     
            <Grid
              item
              container
              xs={10.7}
              direction={"row-reverse"}
              gap={1}
              paddingTop={1.5}
            >
              <button
                className={classes["SaveBut"]}
                disabled={IS_VALIDATED === "true" || saving ? true : false}
                onClick={() => {
                  validate();
                }}
              >
                {saving ? "Saving..." : "Save"}
              </button>
              <button
                className={classes["cancelbut"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </Grid>
        <Grid
          item
          className={classes["Rectangle-4046"]}
          container
          marginTop={2.5}
          marginLeft={{ md: 5, sm: 0 }}
          paddingTop={1}
          paddingLeft={1}
          paddingRight={1}
          lg={5.5}
          md={7}
          sm={11.7}
          direction={"row"}
          paddingBottom={1}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Grid item className={classes["macchine-info"]}>
              TW of Finished Goods (Kg)
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              className={classes["macchine-info"]}
            >
              {finishedGoodsTW}
            </Grid>
          </Grid>
          <Grid item>
            <Grid item className={classes["macchine-info"]}>
              TW of material used (Kg)
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              className={classes["macchine-info"]}
            >
              {materialUsedTW}
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid item className={classes["macchine-info"]}>
              % of scrap
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              className={classes["macchine-info"]}
            >
              {scrapPercentage} %
            </Grid>
          </Grid>
          <Grid item>
            <Grid item className={classes["macchine-info"]}>
              % of remnant
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              className={classes["macchine-info"]}
            >
              {remnantPercentage}
            </Grid>
          </Grid> */}
        </Grid>
        {BEerror ? (
          <Grid
            item
            xs={12}
            paddingTop={1}
            sx={{
              fontFamily: "Muli",
              fontSize: 12,
              fontWeight: "bold",
              textAlign: "center",
              color: "#ea001e",
            }}
          >
            {/* Quantity of raw material And remnant insufficient */}
            {BEerror}
          </Grid>
        ) : (
          <></>
        )}
        {diametersForMachine?.map((diameter, diaIndex) => (
          <Grid
            item
            container
            lg={10.3}
            md={11}
            sm={11.7}
            marginTop={4}
            direction={"row"}
            gap={2}
            marginLeft={{ md: 5, sm: 0 }}
          >
            <Grid item>
              <Grid
                item
                className={classes["Rectangle-4027"]}
                paddingLeft={1.5}
                paddingTop={1}
                paddingBottom={0.5}
              >
                <Grid item container direction={"row"}>
                  <Grid item xs={7} className={classes["diaName"]}>
                    Dia: {diameter.Diameter}
                  </Grid>
                  <BlueTip
                    arrow
                    title={"Select position for Dia collection order "}
                    interactive="true"
                  >
                    <Grid item xs={4} paddingTop={0.5}>
                      <TextField
                        value={diameter.Order}
                        fullWidth
                        InputProps={{
                          style: {
                            height: "15px",
                            fontSize: 8,
                            fontFamily: "Muli",
                            color: " #707070",
                            backgroundColor: "#f1f1f1",
                            borderRadius: 7,
                            border: 0,
                            textAlign: "center",
                          },
                        }}
                        placeholder="No."
                        onChange={(e) => {
                          diameter.Order = e.target.value;

                          setDiametersForMachine(
                            diametersForMachine.sort(
                              (a, b) => a.Order - b.Order
                            )
                          );

                          forceUpdate();
                        }}
                      />
                    </Grid>
                  </BlueTip>
                </Grid>
                <Grid item className={classes["diaRef"]}>
                  Standard: {diameter.BarStandard}
                </Grid>
                <Grid item className={classes["diaRef"]}>
                  Grade: {diameter.BarGrade}
                </Grid>
                <Grid item className={classes["diaRef"]}>
                  Coating: {diameter.BarCoating}
                </Grid>
              </Grid>
              <Grid
                item
                className={classes["Rectangle-4027"]}
                marginTop={1}
                paddingLeft={1.5}
                paddingTop={0.5}
                paddingBottom={0.5}
              >
                <Grid item className={classes["infoMachines"]}>
                  TW of Finished Goods
                </Grid>
                <Grid item className={classes["totalstyles"]}>
                  {diameter.FinishedGoodsTW + " Kgs"}
                </Grid>
                <Grid item className={classes["infoMachines"]}>
                  TW of Material used
                </Grid>
                <Grid item className={classes["totalstyles"]}>
                  {diameter.MaterialUsedTW + " Kgs"}
                </Grid>
                {/* <Grid item className={classes["infoMachines"]}>
                  % of scrap
                </Grid>
                <Grid item className={classes["percentageStyles"]}>
                  {diameter.ScrapPercentage}
                </Grid>
                <Grid item className={classes["infoMachines"]}>
                  % of remnant
                </Grid>
                <Grid item className={classes["percentageStyles"]}>
                  {diameter.RemnantPercentage}
                </Grid> */}
              </Grid>
            </Grid>
            <Grid
              item
              className={classes["Rectangle-4046"]}
              lg={10}
              md={8}
              sm={8}
              xs={10}
            >
              {diameter?.Output?.Cycles.map((material, index) => (
                <>
                  {index !== 0 ? (
                    <Grid item xs={12} className={classes["Line-859"]}></Grid>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    container
                    direction={"row"}
                    paddingLeft={3}
                    paddingTop={0.5}
                    paddingBottom={1}
                  >
                    <Grid item xs={3} className={classes["materialtype"]}>
                      {material.IsInputRemnant ? "Remnant" : "Raw material"}
                    </Grid>
                    <Grid item xs={9} className={classes["materialWeight"]}>
                      {"Input Bar Length: " + material.InputBarLength + " mm "}
                      {"Used Length: " + material.UsedLength + " mm "}
                      {"Used Weight: " +
                        (material.UsedWeight)?.toFixed(2) +
                        " Kg "}
                      {"Total Number Of Bars: " + material.Repetition}
                    </Grid>
                  </Grid>
                </>
              ))}
              <Grid item xs={12} className={classes["Line-859"]}></Grid>
              <Grid
                item
                container
                direction={"row"}
                paddingLeft={3}
                paddingTop={0.5}
              >
                <Grid item xs={6} className={classes["SalesTags"]}>
                  {diameter.Diameter +
                    " " +
                    diameter.BarStandard +
                    " " +
                    diameter.BarGrade +
                    " " +
                    diameter.BarCoating}
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction={"row-reverse"}
                  className={classes["materialWeight"]}
                  gap={1}
                  paddingRight={1}
                >
                  <Grid
                    item
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (chosenId === diaIndex) {
                        setChosenId(null);
                      } else {
                        setChosenId(diaIndex);
                      }
                    }}
                  >
                    {chosenId === diaIndex ? (
                      <ExpandLessIcon htmlColor="#2da4d0" />
                    ) : (
                      <ExpandMoreIcon htmlColor="#2da4d0" />
                    )}
                  </Grid>
                  <Grid item>
                    Total Weight:&nbsp;
                    {diameter?.Lines?.reduce(function (acc, obj) {
                      return acc + obj?.ActualWeight;
                    }, 0).toFixed(2)}
                    Kgs
                  </Grid>
                </Grid>
              </Grid>
              {chosenId === diaIndex ? (
                diameter?.Lines?.map((tag) => (
                  <>
                    <Grid item xs={12} className={classes["Line-859"]}></Grid>

                    <Grid
                      item
                      container
                      direction={"row"}
                      paddingLeft={3}
                      paddingTop={0.5}
                      paddingBottom={1}
                      bgcolor={chosenLineId?.Id === tag.Id ? "#f2f2f2" : "#fff"}
                    >
                      <Grid item xs={6} className={classes["SalesTags"]}>
                        Tag#{tag.BarMarkNo}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction={"row"}
                        className={classes["SalesTags"]}
                      >
                        <Grid item xs={8}>
                          {"Length: " +
                            tag.ActualLength +
                            "mm " +
                            " Weight: " +
                            tag.ActualWeight +
                            "Kgs " +
                            "Total number Of Pcs: " +
                            tag.TotalNumberOfBars}
                        </Grid>
                        <Grid item xs={3} container direction={"row-reverse"}>
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <LineTip
                              TransitionComponent={Zoom}
                              onClose={handleTooltipClose}
                              title={
                                <Grid
                                  container
                                  gap={2}
                                  paddingLeft={0.5}
                                  paddingTop={1}
                                  paddingBottom={1}
                                >
                                  <Grid container direction={"row"} gap={0.5}>
                                    <Grid item className={classes["LineInfo"]}>
                                      Route Code
                                    </Grid>
                                    <Grid
                                      item
                                      className={classes["LineValues"]}
                                    >
                                      {tag?.Route?.Code}
                                    </Grid>
                                  </Grid>
                                  <Grid container direction={"row"} gap={0.5}>
                                    <Grid item className={classes["LineInfo"]}>
                                      Machines Assigned On
                                    </Grid>
                                    {tag?.Route?.RouteMachines?.map((mch) => (
                                      <Grid
                                        item
                                        className={classes["LineValues"]}
                                      >
                                        {mch.Code + " "}
                                      </Grid>
                                    ))}
                                  </Grid>
                                  <Grid container direction={"row"} gap={0.5}>
                                    <Grid item className={classes["LineInfo"]}>
                                      Sales Order Code
                                    </Grid>
                                    <Grid
                                      item
                                      className={classes["LineValues"]}
                                    >
                                      {tag.SalesOrderCode}
                                    </Grid>
                                  </Grid>
                                  <Grid container direction={"row"} gap={0.5}>
                                    <Grid item className={classes["LineInfo"]}>
                                      Member Name
                                    </Grid>
                                    <Grid
                                      item
                                      className={classes["LineValues"]}
                                    >
                                      {tag.MemberName}
                                    </Grid>
                                  </Grid>
                                  <Grid container direction={"row"} gap={0.5}>
                                    <Grid item className={classes["LineInfo"]}>
                                      Member Type
                                    </Grid>
                                    <Grid
                                      item
                                      className={classes["LineValues"]}
                                    >
                                      {tag.MemberType}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              }
                              interactive="true"
                              placement="right-start"
                              open={chosenLineId?.Id === tag.Id ? true : false}
                            >
                              <Grid
                                item
                                onClick={() => {
                                  setChosenLineId(tag);
                                }}
                                className={classes["Rectangle-4041"]}
                              ></Grid>
                            </LineTip>
                          </ClickAwayListener>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))
              ) : (
                <></>
              )}
              <Grid item xs={12} className={classes["Line-859"]}></Grid>

              {/* <Grid
                item
                container
                direction={"row"}
                paddingLeft={3}
                paddingTop={0.5}
                paddingBottom={1}
              >
                <Grid item xs={6} className={classes["RemnantAndScrap"]}>
                  Remnant
                </Grid>
                <Grid item xs={6} className={classes["materialWeight"]}>
                  {diameter.RemnantWeight
                    ? diameter.RemnantWeight?.toFixed(2) + "Kgs"
                    : 0}
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes["Line-859"]}></Grid>
              <Grid
                item
                container
                direction={"row"}
                paddingLeft={3}
                paddingTop={0.5}
                paddingBottom={1}
              >
                <Grid item xs={6} className={classes["RemnantAndScrap"]}>
                  Scrap
                </Grid>
                <Grid item xs={6} className={classes["materialWeight"]}>
                  {diameter.ScrapWeight
                    ? diameter.ScrapWeight?.toFixed(2) + "Kgs"
                    : 0}
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </div>
  );
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,

  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#0674b9",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#0674b9",
    opacity: 1,
  },
}));
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color:#fcfafa;
      background-color:  #101650;
      font-size: 10px;
      width:100.1px;
      line-height: 1.5;
   
  `);
const LineTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
       
        background-color:  #fff;
        width:176px;
     
    `);
export default OptimizationSystem;
