import * as constFile from "../../saasApiConst";
import { CHANGE_APPROVAL_STATUS, UNARCHIVE_DOCUMENT } from "../../StatusConst";
import axios from "axios";
const FormData = require("form-data");


//Suppliers
const CREATE_SUPPLIER = constFile.CREATE_SUPPLIER;
export const createSupplier = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_SUPPLIER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

const GET_SUPPLIER_INPUT_DATA = constFile.GET_SUPPLIER_INPUT_DATA;
export const getSupplierInputData = (supplierId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SUPPLIER_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {Id: supplierId },

    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

const SUPPLIERS_LIST = constFile.SUPPLIERS_LIST;
export const getSuppliersList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + SUPPLIERS_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_SUPPLIER = constFile.UPDATE_SUPPLIER
export const updateSupplier = (data) => {
const token = localStorage.getItem("accessToken");
return axios
.put(`${process.env.REACT_APP_URL + UPDATE_SUPPLIER}`, data , {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
.then((response) => {
  return response.data
})
.catch((error) => {
  return error.response.data;
})
}

//Upload profile
const UPLOAD_PROFILE_SUPPLIER = constFile.UPLOAD_PROFILE_SUPPLIER;
export const uploadProfileSupplier = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_PROFILE_SUPPLIER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete File
const DELETE_FILE_SUPPLIER = constFile.DELETE_FILE_SUPPLIER;
export const deleteFileSupplier = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete tm
const DELETE_TM = constFile.DELETE_TM;
export const deleteTM = (TeamMemberId ) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_TM}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        TeamMemberId : TeamMemberId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Clients
const CLIENTS = constFile.CLIENTS;

//Get Clients Data
const CLIENT_LIST = constFile.CLIENT_LIST;
export const getClientsData = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CLIENT_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Add Client
export const addClient = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CLIENTS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete Client
export const deleteClient = (clientId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + CLIENTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { clientId: clientId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Get Client By ID
const GET_CLIENT = constFile.GET_CLIENT;
export const getClientByID = (clientId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_CLIENT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { Id: clientId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//clients input data
const CLIENT_INPUT_DATA = constFile.CLIENT_INPUT_DATA;
//Get Input Data
export const getInputData = (clientId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + CLIENT_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { clientId: clientId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Update Client
const UPDATE_CLIENT = constFile.UPDATE_CLIENT;
export const updateClient = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_CLIENT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Upload document
const UPLOAD_DOCUMENT = constFile.UPLOAD_DOCUMENT;
export const uploadDocument = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_DOCUMENT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//changeApprovalStatus
export const changeApprovalStatus = (rowId, statusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_APPROVAL_STATUS}`,
      {
        DocumentId: rowId,
        ApprovalStatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//unarchive document
export const unarchiveDocument = (rowId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UNARCHIVE_DOCUMENT}`,
      {
        DocumentId: rowId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Upload profile
const UPLOAD_PROFILE = constFile.UPLOAD_PROFILE;
export const uploadProfile = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete File
const DELETE_FILE = constFile.DELETE_FILE;
export const deleteFile = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Documents
const DOCUMENTS = constFile.DOCUMENTS;

const DOCUMENT_FILTERS = constFile.DOCUMENT_FILTERS;
//Documents filters
export const getDocumentFilters = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DOCUMENT_FILTERS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Get Documents Data
const DOCUMENT_LIST = constFile.DOCUMENT_LIST;
export const getDocumentsData = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + DOCUMENT_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Add Document
export const addDocument = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + DOCUMENTS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete Document
export const deleteDocument = (documentId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DOCUMENTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { documentId: documentId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Get Document By ID
const GET_DOCUMENT = constFile.GET_DOCUMENT;
export const getDocumentByID = (documentId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DOCUMENT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { Id: documentId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Documents input data
const DOCUMENT_INPUT_DATA = constFile.DOCUMENT_INPUT_DATA;
//Get Docment Input Data
export const getDocumentInputData = (documentId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DOCUMENT_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { documentId: documentId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Update Document
const UPDATE_DOCUMENT = constFile.UPDATE_DOCUMENT;
export const updateDocument = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_DOCUMENT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Upload document file
const UPLOAD_DOCUMENT_FILE = constFile.UPLOAD_DOCUMENT_FILE;
export const uploadDocumentFile = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_DOCUMENT_FILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete File
const DELETE_DOCUMENT_FILE = constFile.DELETE_DOCUMENT_FILE;
export const deleteDocumentFile = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_DOCUMENT_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//tEAM MEMBER input data
const TEAM_MEMBER_INPUT_DATA = constFile.TEAM_MEMBER_INPUT_DATA;
//Get TEAM MEMBER Input Data
export const getTeamMemberInputData = (teamMemberId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + TEAM_MEMBER_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { teamMemberId: teamMemberId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//GET Team Members
const GET_TEAM_MEMBER_LIST = constFile.GET_TEAM_MEMBER_LIST;
export const getTeamMemberList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_TEAM_MEMBER_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const TEAM_MEMBER = constFile.TEAM_MEMBER;
export const addTeamMember = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + TEAM_MEMBER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
const TEAM_MEMBER_ARCHIVED_LIST = constFile.TEAM_MEMBER_ARCHIVED_LIST;
export const getTeamMemberArchivedList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + TEAM_MEMBER_ARCHIVED_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const TEAM_MEMBER_FILTER_DATA = constFile.TEAM_MEMBER_FILTER_DATA;
export const getTeamMemberFilters = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + TEAM_MEMBER_FILTER_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const UPDATE_TEAM_MEMBER = constFile.UPDATE_TEAM_MEMBER;
export const updateTeamMember = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_TEAM_MEMBER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const TEAM_MEMBER_UPLOAD_DOCUMENT = constFile.TEAM_MEMBER_UPLOAD_DOCUMENT
export const uploadTeamMemberDocument = (file, userId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + TEAM_MEMBER_UPLOAD_DOCUMENT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const TEAM_MEMBER_DELETE_DOCUMENT = constFile.TEAM_MEMBER_DELETE_DOCUMENT
export const deleteTeamMemberDocument = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + TEAM_MEMBER_DELETE_DOCUMENT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const TEAM_MEMBER_UPLOAD_PROFILE = constFile.TEAM_MEMBER_UPLOAD_PROFILE;
export const uploadTeamMemberProfile = (file, UserId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("UserId", UserId);
  return axios
    .post(`${process.env.REACT_APP_URL + TEAM_MEMBER_UPLOAD_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const TEAM_MEMBER_DELETE_PROFILE = constFile.TEAM_MEMBER_DELETE_PROFILE;
export const deleteTeamMemberProfile = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + TEAM_MEMBER_DELETE_PROFILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const CHANGE_TM_STATUS = constFile.CHANGE_TM_STATUS;
export const changeTMStatus = (rowId, statusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_TM_STATUS}`,
      {
        TeamMemberId: rowId,
        StatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const RESEND_TM_INVITATION = constFile.RESEND_TM_INVITATION;
export const resendTMInvitation = (rowId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + RESEND_TM_INVITATION}`,
      {
        Id: rowId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//TEAM MEMBER REGISTER
const TEAM_MEMBER_REGISTER = constFile.TEAM_MEMBER_REGISTER;
export const teamMemberRegister = (
  Name,
  Email,
  Password,
  FileId,
  CountryId,
  Address,
  PhoneNumber,
  AgreementAcceptance,
  AccessToken
) => {
  return axios
    .post(`${process.env.REACT_APP_URL + TEAM_MEMBER_REGISTER}`, {
      Name: Name,
      Email: Email,
      Password: Password,
      ProfilePicture: {
        FileId: FileId,
      },
      CountryId: CountryId,
      Address: Address,
      PhoneNumber: PhoneNumber,
      AgreementAcceptance: AgreementAcceptance,
      AccessToken: AccessToken,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
//TEAM MEMBER  Data
const TEAM_MEMBER_REGISTER_DATA = constFile.TEAM_MEMBER_REGISTER_DATA;
export const teamMemberRegisterData = (AccessToken) => {
  return axios
    .get(`${process.env.REACT_APP_URL + TEAM_MEMBER_REGISTER_DATA}`, {
      params: { Token: AccessToken },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
};
//GETTING TEAM MEMBER DATA
const TEAM_MEMBER_PROFILE_DATA = constFile.TEAM_MEMBER_PROFILE_DATA;
export const TeamMemberDetails = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + TEAM_MEMBER_PROFILE_DATA}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CHANGE_TM_SUBPROJECT_STATUS = constFile.CHANGE_TM_SUBPROJECT_STATUS;
export const changeTMSubrojectStatus = (TeamMemberSubProjectId, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_TM_SUBPROJECT_STATUS}`,
      {
        TeamMemberSubProjectId: TeamMemberSubProjectId,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const TM_ASSIGNED_PROJECT_LIST = constFile.TM_ASSIGNED_PROJECT_LIST;
export const getTMAssignedProjectList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + TM_ASSIGNED_PROJECT_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};


//request support
const REQUEST_SUPPORT = constFile.REQUEST_SUPPORT;
export const requestSupport = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + REQUEST_SUPPORT}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
