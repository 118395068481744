import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import classes from "./ClientModal.module.css";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import infoTip from "../../../../Assets/Images/form/info.png";
import { IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as SaasServices from "../../../Services/saas-services.proxy";
import { MenuItem } from "@mui/material";
import fileIcon from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";

const addClient = SaasServices.addClient;
const getInputData = SaasServices.getInputData;
const uploadProfile = SaasServices.uploadProfile;
const uploadDocument = SaasServices.uploadDocument;
const deleteFile = SaasServices.deleteFile;
const getClientById = SaasServices.getClientByID;
const updateClient = SaasServices.updateClient;

const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "62%",
  height: "90%",
  overflow: "scroll",
  display: "block",
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
     
   
  `);
const ClientModal = (props) => {
  const [open, setOpen] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);

  const [creator, setCreator] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);

  const [logo, setLogo] = useState(null);
  const [logoId, setLogoId] = useState(null);
  const [previousLogoId, setPreviousLogoId] = useState(null);
  const [clientId, setCLientId] = useState(null);

  useEffect(() => {
    if (props.rowData) {
      setCLientId(props.rowData.id);
    } else {
      setCLientId(null);
    }
  }, [props.rowData]);

  const AccountType = localStorage.getItem("AccountType");
  const [personalName, setPersonalName] = useState("");
  const [personalNameError, setPersonalNameError] = useState(false);
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState(false);
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalEmailError, setPersonalEmailError] = useState(false);
  const [personalEmailInvalid, setPersonalEmailInvalid] = useState(false);
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [personalPhoneNumberError, setPersonalPhoneNumberError] =
    useState(false);
  const [countries, setCountries] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (
      !name ||
      !email ||
      !country ||
      !address ||
      !phoneNumber ||
      !personalName ||
      !position ||
      !personalEmail ||
      !personalPhoneNumber ||
      emailError ||
      emailInvalid ||
      emailExist ||
      countryError ||
      addressError ||
      phoneNumberError ||
      personalEmailError ||
      personalEmailInvalid ||
      personalPhoneNumberError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    let apiTest = true;
    if (clientId) {
      getInputData(clientId).then((x) => {
        if (apiTest) {
          setName(x.Name);
          setCountries(x.Countries);
          setCountry(x.CountryId);
          setAddress(x.Address);
          if (x.Document) setFile(x.Document);
          if (x.ProfilePicture) setLogo(x.ProfilePicture);
          setAddress(x.Address);
          setPhoneNumber(x.PhoneNumber);
          setWebsite(x.Website);
          setDescription(x.Description);
          setEmail(x.Email);
          setFiles(x.Documents);
          x.Documents?.map((d) => {
            let tempFileId = null;
            tempFileId = d.FileId;
            let fileData = { FileId: tempFileId };
            fileIds.push(fileData);
          });
        }
      });
      getClientById(clientId).then((x) => {
        if (apiTest) {
          setPersonalEmail(x.PersonalEmail);
          setPersonalName(x.PersonalContactName);
          setPosition(x.Position);
          setPersonalPhoneNumber(x.PersonalPhoneNumber);
          setCreator(x.Creator);
          setCreatedDate(x.CreationTime);
        }
      });
    } else {
      setName("");
      setCountry("");
      setAddress("");
      setFile("");
      setLogo("");
      setAddress("");
      setPhoneNumber("");
      setWebsite("");
      setDescription("");
      setEmail("");
      setPersonalEmail("");
      setPersonalName("");
      setPosition("");
      setPersonalPhoneNumber("");
      setCreator("");
      setCreatedDate("");
      setFiles([]);
      setFileIds([]);
      getInputData(null).then((x) => {
        if (apiTest) {
          setCountries(x.Countries);
        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, [clientId]);

  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteFile(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);

      uploadDocument(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  const handleLogoChange = (e) => {
    if (previousLogoId) {
      deleteFile(previousLogoId);
    }
    if (e.target.files[0]) {
      if (logoId) setPreviousFileId(logoId);

      uploadProfile(e.target.files[0]).then((x) => {
        setLogo(x);
        setLogoId(x.FileId);
      });
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1); // 2nd parameter means remove one item only
    }
  };

  const data = {
    Id: clientId,
    Name: name,
    Email: email,
    Address: address,
    PhoneNumber: phoneNumber,
    Website: website,
    Description: description,
    CountryId: country,
    CountryCode: "961",
    PersonalContactName: personalName,
    Position: position,
    PersonalEmail: personalEmail,
    PersonalCountryCode: "961",
    PersonalPhoneNumber: personalPhoneNumber,
    ProfilePicture: {
      FileId: logoId,
    },
    Documents: fileIds,
  };

  const [loading, setLoading] = useState(false);
  const save = () => {
    if (data.Id)
      updateClient(data).then((x) => {
        if (x.status === 409) {
          setEmailError(true);
          setEmailExist(true);
          setLoading(false);
        }
        else {
          props.setModify(!props.modify);
          setLoading(false);
          setTimeout(() => {
            props.setRowData(null);
            handleClose();
          }, [1000]);
        }
      });
    else
      addClient(data).then((x) => {
        if (x.status === 409) {
          setEmailError(true);
          setEmailExist(true);
          setLoading(false);
        }
        else {
          props.setModify(!props.modify);
          setLoading(false);
          setTimeout(() => {
            props.setRowData(null);
            handleClose();
          }, [1000]);
        }
      });
  };

  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    if (!clientId) {
      setName("");
      setCountry("");
      setAddress("");
      setFile("");
      setLogo("");
      setAddress("");
      setPhoneNumber("");
      setWebsite("");
      setDescription("");
      setEmail("");
      setPersonalEmail("");
      setPersonalName("");
      setPosition("");
      setPersonalPhoneNumber("");
      setFiles([]);
      setFileIds([]);
      setNameError(false);
      setEmailError(false);
      setEmailExist(false);
      setCountryError(false);
      setAddressError(false);
      setPersonalEmailError(false);
      setPersonalNameError(false);
      setPositionError(false);
      setPhoneNumberError(false);
      setPersonalPhoneNumberError(false);
    }
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf={"flex-end"}
            paddingRight="18.5%"
            paddingTop="13px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Box sx={style}>
              <Grid container alignItems="center" direction="column" gap={1}>
                <Grid item className={classes.newClient} paddingTop={1}>
                  {clientId ? "Edit Client" : "New Client"}
                </Grid>

                <Grid
                  item
                  className={classes["Line-402"]}
                  alignSelf={"center"}
                  justifySelf={"center"}
                  width="92%"
                ></Grid>
                <Grid item container paddingLeft={"4%"} gap={1}>
                  <Grid
                    item
                    xs={12}
                    className={classes["Client-Information"]}
                    alignSelf="flex-start"
                  >
                    Client Information
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="140px"
                      alignSelf="flex-start"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="140px"
                      alignSelf="flex-start"
                    />
                  )}

                  <Grid item container direction={"row"}>
                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>*Name</span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            value={name}
                            error={nameError}
                            id="outlined-name"
                            size="small"
                            helperText={nameError ? "Enter a client name" : ""}
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 150 }}
                            onBlur={(e) => {
                              if (!e.target.value) setNameError(true);
                            }}
                            onChange={(e) => {
                              setNameError(false);

                              setName(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>*Email</span>
                        </Grid>
                        <Grid item>
                          <TextField
                            error={emailError}
                            className={classes["Field-input"]}
                            value={email}
                            id="outlined-name"
                            size="small"
                            helperText={
                              emailError
                                ? emailInvalid
                                  ? "Enter a valid email address"
                                  : emailExist
                                    ? "Email already exist"
                                    : "Enter an email address"
                                : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setEmailError(true);
                            }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (
                                !e.target.value ||
                                !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )
                              ) {
                                setEmailError(true);
                                setEmailInvalid(true);
                              } else {
                                setEmailError(false);
                                setEmailInvalid(false);
                                setEmailExist(false)
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Country
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            select
                            error={countryError}
                            className={classes["Field-input"]}
                            value={country}
                            helperText={countryError ? "Select a country" : ""}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            onBlur={(e) => {
                              if (!e.target.value) setCountryError(true);
                            }}
                            onChange={(e) => {
                              setCountry(e.target.value);
                              setCountryError(false);
                            }}
                          >
                            {countries.map((option) => (
                              <MenuItem
                                value={option.Id}
                                key={option.Id}
                                style={{ display: "block" }}
                              >
                                {option.Name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Address{" "}
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            error={addressError}
                            value={address}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 150 }}
                            helperText={addressError ? " Enter an address" : ""}
                            onBlur={(e) => {
                              if (!e.target.value) setAddressError(true);
                            }}
                            onChange={(e) => {
                              setAddress(e.target.value);
                              setAddressError(false);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"row"}>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <span className={classes["field-label2"]}>
                              Creator
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={creator}
                              disabled
                              className={classes["Field-input2"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <span className={classes["field-label2"]}>
                              Created Date
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={createdDate.slice(0, 10)}
                              disabled
                              className={classes["Field-input2"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Phone Number
                          </span>
                        </Grid>
                        <Grid item>
                          <PhoneInput
                            placeholder="Enter phone number"
                            // onlyCountries={isoCodes}
                            enableAreaCodes={true}
                            onBlur={(e) => {
                              if (!e.target.value) setPhoneNumberError(true);
                            }}
                            value={phoneNumber}
                            onChange={(phone) => {
                              setPhoneNumber(phone);
                              if (
                                phone === "" ||
                                phone.length < 7 ||
                                phone.length > 25
                              ) {
                                setPhoneNumberError(true);
                              } else {
                                setPhoneNumberError(false);
                              }
                            }}
                            country={localStorage.getItem("userCountry")?.toLowerCase()}
                            style={{
                              borderColor: phoneNumberError ? "#ea001e" : "",
                            }}
                            inputStyle={{
                              borderColor: phoneNumberError ? "#ea001e" : "",
                              width: "90%",
                              height: 15,
                              fontFamily: "Muli",
                              fontsize: "16px",
                            }}
                          />
                          {phoneNumberError ? (
                            <span
                              style={{
                                fontFamily: "Muli",
                                color: "#ea001e",
                                fontSize: "12px",
                              }}
                            >
                              Phone number must be between 7 and 25 characters
                            </span>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            Website
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            value={website}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => {
                              setWebsite(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            Description
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            value={description}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "80px" } }}
                            inputProps={{ maxLength: 255 }}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {files.length === 0 ? (
                        <IconButton
                          edge="end"
                          onClick={openTip}
                          onMouseOver={openTip}
                          onMouseLeave={closeTip}
                        >
                          <BlueTip
                            width="400px"
                            open={tooltipIsOpen}
                            title={
                              "You can  upload any document related to the client or a contract."
                            }
                            placement="right"
                          >
                            <img
                              width={"20px"}
                              src={infoTip}
                              alt={"close"}
                            ></img>
                          </BlueTip>
                        </IconButton>
                      ) : (
                        <Grid item paddingTop={2}></Grid>
                      )}
                      <Grid item container direction={"row"}>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <Button
                              component="label"
                              style={{
                                width: "80%",
                                textTransform: "none",
                                fontFamily: "Muli",
                                fontSize: "12px",
                                lineHeight: 1.45,
                                textAlign: "left",
                                color: "#101650",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                border: "solid 1px #b8b8b8",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "30px",
                              }}
                            >
                              <FileUploadOutlinedIcon htmlColor="#529bd7" />
                              <span style={{ marginLeft: "5%" }}>
                                Upload File
                              </span>
                              <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                              />
                            </Button>
                          </Grid>
                          {clientId && files.length > 0 ? (
                            <Grid item marginRight={1}>
                              <IconButton
                                edge="end"
                                onClick={openTip}
                                onMouseOver={openTip}
                                onMouseLeave={closeTip}
                              >
                                <BlueTip
                                  width="100px"
                                  open={tooltipIsOpen}
                                  title={"Click on file to download"}
                                  placement="right"
                                >
                                  <img
                                    width={"15px"}
                                    src={infoTip}
                                    alt={"close"}
                                  ></img>
                                </BlueTip>
                              </IconButton>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {files?.map((item) => (
                            <Grid
                              item
                              container
                              paddingTop={1}
                              key={item.FileId}
                            >
                              <Grid
                                item
                                xs={2}
                                paddingLeft={1}
                                paddingTop={0.5}
                              >
                                <img
                                  src={
                                    AccountType === "1" ? fileIcon : fileIconSub
                                  }
                                  alt="file"
                                  width={15}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                paddingTop={0.5}
                                onMouseOver={(e) => {
                                  e.target.style.color = "grey";
                                  e.target.style.cursor = "pointer";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "black";
                                }}
                                onClick={() => {
                                  window.open(item.URL);
                                }}
                              >
                                {item.DisplayName.slice(0, 10)}
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                paddingTop={0.8}
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                }}
                                onClick={() => {
                                  deleteItem(item);

                                  setFile(null);
                                  setFileId(null);
                                  forceUpdate();
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <Button
                              component="label"
                              style={{
                                width: "80%",
                                textTransform: "none",
                                fontFamily: "Muli",
                                fontSize: "12px",
                                lineHeight: 1.45,
                                textAlign: "left",
                                color: "#101650",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                border: "solid 1px #b8b8b8",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "30px",
                              }}
                            >
                              <FileUploadOutlinedIcon
                                htmlColor="#529bd7"
                                style={{
                                  alignSelf: "center",
                                  paddingRight: "5%",
                                }}
                              />
                              <span style={{ alignSelf: "center" }}>
                                Upload Logo
                              </span>
                              <input
                                type="file"
                                hidden
                                onChange={handleLogoChange}
                              />
                            </Button>
                          </Grid>
                          {logo ? (
                            <Grid item container paddingTop={1}>
                              <Grid
                                item
                                xs={2}
                                paddingLeft={1}
                                paddingTop={0.8}
                              >
                                <img
                                  src={logo.URL}
                                  alt="logo"
                                  width={18}
                                  height={18}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                paddingTop={0.8}
                                onMouseOver={(e) => {
                                  e.target.style.color = "grey";
                                  e.target.style.cursor = "pointer";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "black";
                                }}
                                onClick={() => {
                                  window.open(logo.URL);
                                }}
                              >
                                {logo.DisplayName.slice(0, 5)}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                paddingTop={1}
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                }}
                                onClick={() => {
                                  setLogo(null);
                                  setLogoId(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item xs={6}></Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes["Client-Information"]}
                    alignSelf="flex-start"
                  >
                    Personal Information
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="160px"
                      alignSelf="flex-start"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="160px"
                      alignSelf="flex-start"
                    />
                  )}

                  <Grid item container direction={"row"}>
                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Personal Contact Name
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            value={personalName}
                            error={personalNameError}
                            id="outlined-name"
                            size="small"
                            helperText={
                              personalNameError ? "Enter a personal name" : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setPersonalNameError(true);
                            }}
                            onChange={(e) => {
                              setPersonalNameError(false);
                              setPersonalName(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Position
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            value={position}
                            error={positionError}
                            id="outlined-name"
                            size="small"
                            helperText={
                              positionError ? "Enter a job position" : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 20 }}
                            onBlur={(e) => {
                              if (!e.target.value) setPositionError(true);
                            }}
                            onChange={(e) => {
                              setPositionError(false);
                              setPosition(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Personal Email
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            error={personalEmailError}
                            className={classes["Field-input"]}
                            value={personalEmail}
                            id="outlined-name"
                            size="small"
                            helperText={
                              personalEmailError
                                ? personalEmailInvalid
                                  ? "Enter a valid email address"
                                  : "Enter an email address"
                                : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setPersonalEmailError(true);
                            }}
                            onChange={(e) => {
                              setPersonalEmail(e.target.value);
                              if (
                                !e.target.value ||
                                !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )
                              ) {
                                setPersonalEmailError(true);
                                setPersonalEmailInvalid(true);
                              } else {
                                setPersonalEmailError(false);
                                setPersonalEmailInvalid(false);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Phone Number
                          </span>
                        </Grid>
                        <Grid item>
                          <PhoneInput
                            placeholder="Enter phone number"
                            // onlyCountries={isoCodes}
                            enableAreaCodes={true}
                            onBlur={(e) => {
                              if (!e.target.value)
                                setPersonalPhoneNumberError(true);
                            }}
                            value={personalPhoneNumber}
                            onChange={(phone) => {
                              setPersonalPhoneNumber(phone);
                              if (
                                phone === "" ||
                                phone.length < 7 ||
                                phone.length > 20
                              ) {
                                setPersonalPhoneNumberError(true);
                              } else {
                                setPersonalPhoneNumberError(false);
                              }
                            }}
                            country={localStorage.getItem("userCountry")?.toLowerCase()}
                            style={{
                              borderColor: personalPhoneNumberError
                                ? "#ea001e"
                                : "",
                            }}
                            inputStyle={{
                              borderColor: personalPhoneNumberError
                                ? "#ea001e"
                                : "",
                              width: "90%",
                              height: "10px",
                              fontFamily: "Muli",
                              fontsize: "16px",
                            }}
                          />
                          {personalPhoneNumberError ? (
                            <span
                              style={{
                                fontFamily: "Muli",
                                color: "#ea001e",
                                fontSize: "12px",
                              }}
                            >
                              Phone number must be less than 25 char and more
                              than 7
                            </span>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"flex-end"}
                  direction="row"
                  alignItems={"flex-end"}
                  paddingRight={5}
                  paddingBottom={1}
                  paddingTop={4}
                >
                  <Grid item paddingRight={2}>
                    <button
                      className={classes.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#f5f5f5";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                      }}
                      onClick={() => {
                        props.setRowData(null);
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={isDisabled}
                      className={classes.SaveModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#242B64";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#0b5cab";
                      }}
                      style={{
                        backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                        fontFamily: "Muli",
                        textTransform: "none",
                        opacity: loading ? 0.7 : 1,
                      }}
                      onClick={() => {
                        setLoading(true);
                        save();
                      }}
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ClientModal;
