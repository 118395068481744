import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import AddValueIcon from "../../../../../Assets/Images/Inventory/AddFIcon.png";
import RemoveIcon from "../../../../../Assets/Images/Inventory/RemFIcon.png";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";

const getCustomizedCodeInputData = inventoryServices.getCustomizedCodeInputData;
const createCustomizedCode = inventoryServices.createCustomizedCode;
const ItemCode = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [dataOfCodes, setDataOfCodes] = useState([
    {
      Id: 1,
      Name: "Raw Material",
      Code: {
        Id: "a1",
        Fields: [],
      },
      Description: {
        Id: "a2",
        Fields: [],
      },
    },
    {
      Id: 2,
      Name: "PreCut",
      Code: {
        Id: "b1",
        Fields: [],
      },
      Description: {
        Id: "b2",
        Fields: [],
      },
    },
    {
      Id: 3,
      Name: "Remnant",
      Code: {
        Id: "c1",
        Fields: [],
      },
      Description: {
        Id: "c2",
        Fields: [],
      },
    },
    {
      Id: 4,
      Name: "Product",
      Code: {
        Id: "d1",
        Fields: [],
      },
      Description: {
        Id: "d2",
        Fields: [],
      },
    },

  ]);
  const [selectedObject, setSelectedObject] = useState();
  const [parentObjectId, setParentObjectId] = useState();
  const [generatedCode, setGeneratedCode] = useState("");
  const [generatedExample, setGeneratedExample] = useState("");

  const [loadingData, setLoadingData] = useState(true);
  const [saving, setSaving] = useState(false);

  const [isdisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (props.inventoryId) {
      getCustomizedCodeInputData(props.inventoryId).then((x) => {
        setLoadingData(false);
        setFields(x.Fields);

        let RMOBJECT = dataOfCodes.filter((x) => x.Name === "Raw Material")[0];
        let RMOBJECTBE = x.Data.filter((x) => x.Name === "Raw Material")[0];
        if (!RMOBJECTBE) {
          setDataOfCodes(
            dataOfCodes.filter((x) => x.Name !== "Raw Material")
          );
        } else {
          RMOBJECT.Id = RMOBJECTBE.Id;
          RMOBJECT.Code.Fields = RMOBJECTBE.CodeFields;
          RMOBJECT.Description.Fields = RMOBJECTBE.DescriptionFields;
        }

        let RNOBJECT = dataOfCodes.filter((x) => x.Name === "Remnant")[0];
        let RNOBJECTBE = x.Data.filter((x) => x.Name === "Remnant")[0];
        if (!RNOBJECTBE) {
          let tmp = dataOfCodes.filter((x) => x.Name !== "Remnant");
          setDataOfCodes(tmp);
        } else {
          RNOBJECT.Id = RNOBJECTBE.Id;
          RNOBJECT.Code.Fields = RNOBJECTBE.CodeFields;
          RNOBJECT.Description.Fields = RNOBJECTBE.DescriptionFields;
        }

        let PROBJECT = dataOfCodes.filter((x) => x.Name === "Product")[0];
        let PROBJECTBE = x.Data.filter((x) => x.Name === "Product")[0];
        if (!PROBJECTBE) {
          setDataOfCodes(dataOfCodes.filter((x) => x.Name !== "Product"));
        } else {
          PROBJECT.Id = PROBJECTBE.Id;
          PROBJECT.Code.Fields = PROBJECTBE.CodeFields;
          PROBJECT.Description.Fields = PROBJECTBE.DescriptionFields;
        }

        let PreCutOBJECT = dataOfCodes.filter((x) => x.Name === "PreCut")[0];
        let PreCutOBJECTBE = x.Data.filter((x) => x.Name === "PreCut")[0];
        if (!PreCutOBJECTBE) {
          setDataOfCodes(dataOfCodes.filter((x) => x.Name !== "PreCut"));
        } else {
          PreCutOBJECT.Id = PreCutOBJECTBE.Id;
          PreCutOBJECT.Code.Fields = PreCutOBJECTBE.CodeFields;
          PreCutOBJECT.Description.Fields = PreCutOBJECTBE.DescriptionFields;
        }

        forceUpdate();
      });
    }
  }, [props.inventoryId]);

  const save = () => {
    const filteredArray = dataOfCodes.filter(obj => typeof obj.Id !== 'number');

    setSaving(true);
    let DataToSend = { Fields: [] };
    filteredArray.map((dc) => {
      DataToSend.Fields = DataToSend.Fields.concat({
        Id: dc.Id,
        CodeFields: dc.Code.Fields,
        DescriptionFields: dc.Description.Fields,
      });
    });
    createCustomizedCode(DataToSend).then((x) => {
      navigate(-1);
      setSaving(false)
    });

  };

  const constructExample = (fieldsTo, Name) => {
    let exampleToShow = fieldsTo
      .flatMap((x) => {
        if (x.Name === "Free text") {
          return "FREE TEXT";
        } else if (x.Name === "Inventory category name") {
          return Name;
        } else if (x.Name === "Inventory category abbrevation") {
          return Name === "Raw Material" ? "RM" : "RE";
        } else if (x.Name === "Inventory type name") {
          return "BAR";
        } else if (x.Name === "Inventory type abbreviation") {
          return "BR";
        } else if (x.Name === "Dimension name 1") {
          return "NOMINAL DIA";
        } else if (x.Name === "Dimension name 2") {
          return "LENGTH";
        } else if (x.Name === "Dimension Abbreviation 1") {
          return "DIA";
        } else if (x.Name === "Dimension Abbreviation 2") {
          return "LG";
        } else if (x.Name === "Dim1 values") {
          return "8";
        } else if (x.Name === "Dim2 values") {
          return "12";
        } else if (x.Name === "Dim1 Uom Value") {
          return "MM";
        } else if (x.Name === "Dim2 Uom Value") {
          return "M";
        } else if (x.Name === "Remnant length") {
          return "9000";
        } else if (x.Name === "Ref1 name") {
          return "STANDARD";
        } else if (x.Name === "Ref2 name") {
          return "GRADE";
        } else if (x.Name === "Ref3 name") {
          return "COATING";
        } else if (x.Name === "Ref1 value") {
          return "BS4449:2005";
        } else if (x.Name === "Ref2 value") {
          return "500B";
        } else if (x.Name === "Ref3 value") {
          return "BLACK";
        } else if (x.Name === "Ref group Abbreviation") {
          return "B500BB";
        }
      })
      .join("-");
    return exampleToShow;
  };
  return (
    <Grid container>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item xs={12} className={classes["Customise-Code"]}>
        Customize Code & Description
      </Grid>

      <Grid container direction={"row"}>
        <Grid item xs={4} paddingTop={7}>
          <Grid item xs={12} className={classes["Customise-Code"]}>
            Fields
          </Grid>
          {fields?.map((field, index) => (
            <Grid item container xs={12} direction={"row"} paddingTop={2}>
              <Grid item xs={11}>
                <Grid
                  item
                  container
                  direction={"row"}
                  className={classes["Rectangle-3722"]}
                >
                  <Grid item xs={11}>
                    {field.Name}
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={(e) => {
                      if (
                        (field.Name === "Dim2 values" &&
                          dataOfCodes.filter((x) => x.Id === parentObjectId)[0]
                            .Name !== "Raw Material") ||
                        (field.Name === "Remnant length" &&
                          dataOfCodes.filter((x) => x.Id === parentObjectId)[0]
                            .Name !== "Remnant")
                      ) {
                      } else if (
                        !selectedObject.Fields.filter(
                          (x) => x.Id === field.Id
                        )[0] ||
                        field.Name === "Free text"
                      ) {
                        selectedObject.Fields = selectedObject.Fields.concat({
                          FieldId: field.Id,
                          Name: field.Name,
                          Content: null,
                        });
                        forceUpdate();
                      }
                    }}
                  >
                    <img src={AddValueIcon} width={13} alt={"Add"} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} className={classes["enumFields"]}>
                {index + 1}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid item container xs={8}>
          <div style={{
            width: "100%",
            overflowX: "scroll",
          }}>
            <div style={{
              display: "flex",
              whiteSpace: "nowrap",
              flexShrink: 0
            }}>

              {dataOfCodes.filter(obj => typeof obj.Id !== 'number')?.map((data) => (
                <Grid item minWidth="400px">
                  <Grid item className={classes["For-Raw-Material"]}>
                    For &nbsp; {data.Name}
                  </Grid>
                  <Grid item xs={12} container direction={"row"} paddingTop={2}>
                    <Grid
                      item
                      xs={6}
                      className={classes["code-desc"]}
                      color={
                        selectedObject?.Id === data.Code.Id ? "#0674b9" : "#707070"
                      }
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={(e) => {
                        setParentObjectId(data.Id);
                        setSelectedObject(data.Code);
                      }}
                    >
                      Code&nbsp;&nbsp;+
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={classes["code-desc"]}
                      color={
                        selectedObject?.Id === data.Description.Id
                          ? "#0674b9"
                          : "#707070"
                      }
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={(e) => {
                        setParentObjectId(data.Id);
                        setSelectedObject(data.Description);
                      }}
                    >
                      Description&nbsp;&nbsp;+
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction={"row"} gap={1}>
                    <Grid
                      paddingLeft={1}
                      xs={5}
                      paddingBottom={5}
                      paddingTop={2.5}
                      bgcolor={
                        selectedObject?.Id === data.Code.Id
                          ? "rgba(6, 116, 185, 0.3)"
                          : "#fff"
                      }
                    >
                      {data.Code.Fields.map((code, indexcode) =>
                        code.Name === "Free text" ? (
                          <Grid
                            key={code.FieldId}
                            item
                            container
                            marginTop={1.5}
                            width={150}
                          >
                            <TextField
                              value={code.Content}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <Grid
                                    item
                                    onMouseOver={(e) => {
                                      e.target.style.cursor = "pointer";
                                    }}
                                    onClick={(e) => {
                                      data.Code.Fields = data.Code.Fields.filter(
                                        (x, ind) => ind !== indexcode
                                      );
                                      forceUpdate();
                                    }}
                                  >
                                    <img src={RemoveIcon} width={13} alt={"delete"} />
                                  </Grid>
                                ),
                                style: {
                                  color: "#707070",
                                  height: "32px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                  backgroundColor: "#fff",
                                },
                              }}
                              placeholder={"content"}
                              onChange={(e) => {
                                code.Content = e.target.value;
                                forceUpdate();
                              }}
                            ></TextField>
                          </Grid>
                        ) : (
                          <Grid
                            key={code.FieldId}
                            item
                            container
                            direction={"row"}
                            className={classes["Rectangle-37222"]}
                            marginTop={1.5}
                          >
                            <Grid
                              item
                              xs={11}
                              whiteSpace={"nowrap"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                            >
                              {code.Name}
                            </Grid>

                            <Grid
                              item
                              xs={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={(e) => {
                                data.Code.Fields = data.Code.Fields.filter(
                                  (x, ind) => ind !== indexcode
                                );
                                forceUpdate();
                              }}
                            >
                              <img src={RemoveIcon} width={13} alt={"Add"} />
                            </Grid>
                          </Grid>
                        )
                      )}
                      {data.Code.Fields.length > 0 ? (
                        <Grid
                          item
                          className={classes["Generate-Reference-Group"]}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={(e) => {
                            let GC = data.Code.Fields.flatMap((x) => {
                              if (x.Name === "Free text") {
                                return x.Content;
                              } else {
                                return x.Name;
                              }
                            }).join(" ");
                            setGeneratedExample(
                              constructExample(data.Code.Fields, data.Name)
                            );
                            setGeneratedCode(GC);
                            window.scrollTo({
                              top: window.innerHeight,
                              behavior: "smooth",
                            });
                          }}
                        >
                          View Code
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid
                      xs={5}
                      paddingBottom={5}
                      paddingTop={2.5}
                      paddingLeft={1}
                      bgcolor={
                        selectedObject?.Id === data.Description.Id
                          ? "rgba(6, 116, 185, 0.3)"
                          : "#fff"
                      }
                    >
                      {data.Description.Fields.map((desc, indxdesc) =>
                        desc.Name === "Free text" ? (
                          <Grid
                            key={desc.FieldId}
                            item
                            container
                            marginTop={1.5}
                            width={150}
                          >
                            <TextField
                              value={desc.Content}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <Grid
                                    item
                                    onMouseOver={(e) => {
                                      e.target.style.cursor = "pointer";
                                    }}
                                    onClick={(e) => {
                                      data.Description.Fields =
                                        data.Description.Fields.filter(
                                          (x, ind) => ind !== indxdesc
                                        );
                                      forceUpdate();
                                    }}
                                  >
                                    <img src={RemoveIcon} width={13} alt={"delete"} />
                                  </Grid>
                                ),
                                style: {
                                  color: "#707070",
                                  height: "32px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                  backgroundColor: "#fff",
                                },
                              }}
                              placeholder={"content"}
                              onChange={(e) => {
                                desc.Content = e.target.value;
                                forceUpdate();
                              }}
                            ></TextField>
                          </Grid>
                        ) : (
                          <Grid
                            marginTop={1.5}
                            key={desc.Id}
                            item
                            container
                            direction={"row"}
                            className={classes["Rectangle-37222"]}
                          >
                            <Grid
                              item
                              xs={11}
                              whiteSpace={"nowrap"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                            >
                              {desc.Name}
                            </Grid>

                            <Grid
                              item
                              xs={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={(e) => {
                                data.Description.Fields =
                                  data.Description.Fields.filter(
                                    (x, ind) => ind !== indxdesc
                                  );
                                forceUpdate();
                              }}
                            >
                              <img src={RemoveIcon} width={13} alt={"Add"} />
                            </Grid>
                          </Grid>
                        )
                      )}
                      {data.Description.Fields.length > 0 ? (
                        <Grid
                          item
                          className={classes["Generate-Reference-Group"]}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={(e) => {
                            let GC = data.Description.Fields.flatMap(
                              (x) => x.Name
                            ).join(" ");
                            setGeneratedCode(GC);
                            setGeneratedExample(
                              constructExample(data.Description.Fields, data.Name)
                            );
                            window.scrollTo({
                              top: window.innerHeight,
                              behavior: "smooth",
                            });
                          }}
                        >
                          View Code
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}


            </div>
          </div>
        </Grid>


        {/* <Grid item container overflow={"auto"} marginTop={4} whiteSpace="nowrap" xs={8}>
          {dataOfCodes.map((data) => (
            <Grid item minWidth="400px">
              <Grid item className={classes["For-Raw-Material"]}>
                For &nbsp; {data.Name}
              </Grid>
              <Grid item xs={12} container direction={"row"} paddingTop={2}>
                <Grid
                  item
                  xs={6}
                  className={classes["code-desc"]}
                  color={
                    selectedObject?.Id === data.Code.Id ? "#0674b9" : "#707070"
                  }
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    setParentObjectId(data.Id);
                    setSelectedObject(data.Code);
                  }}
                >
                  Code&nbsp;&nbsp;+
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={classes["code-desc"]}
                  color={
                    selectedObject?.Id === data.Description.Id
                      ? "#0674b9"
                      : "#707070"
                  }
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    setParentObjectId(data.Id);
                    setSelectedObject(data.Description);
                  }}
                >
                  Description&nbsp;&nbsp;+
                </Grid>
              </Grid>
              <Grid item xs={12} container direction={"row"} gap={1}>
                <Grid
                  paddingLeft={1}
                  xs={5}
                  paddingBottom={5}
                  paddingTop={2.5}
                  bgcolor={
                    selectedObject?.Id === data.Code.Id
                      ? "rgba(6, 116, 185, 0.3)"
                      : "#fff"
                  }
                >
                  {data.Code.Fields.map((code, indexcode) =>
                    code.Name === "Free text" ? (
                      <Grid
                        key={code.FieldId}
                        item
                        container
                        marginTop={1.5}
                        width={150}
                      >
                        <TextField
                          value={code.Content}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <Grid
                                item
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                }}
                                onClick={(e) => {
                                  data.Code.Fields = data.Code.Fields.filter(
                                    (x, ind) => ind !== indexcode
                                  );
                                  forceUpdate();
                                }}
                              >
                                <img src={RemoveIcon} width={13} alt={"delete"} />
                              </Grid>
                            ),
                            style: {
                              color: "#707070",
                              height: "32px",
                              fontSize: 12,
                              fontFamily: "Muli",
                              backgroundColor: "#fff",
                            },
                          }}
                          placeholder={"content"}
                          onChange={(e) => {
                            code.Content = e.target.value;
                            forceUpdate();
                          }}
                        ></TextField>
                      </Grid>
                    ) : (
                      <Grid
                        key={code.FieldId}
                        item
                        container
                        direction={"row"}
                        className={classes["Rectangle-37222"]}
                        marginTop={1.5}
                      >
                        <Grid
                          item
                          xs={11}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {code.Name}
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={(e) => {
                            data.Code.Fields = data.Code.Fields.filter(
                              (x, ind) => ind !== indexcode
                            );
                            forceUpdate();
                          }}
                        >
                          <img src={RemoveIcon} width={13} alt={"Add"} />
                        </Grid>
                      </Grid>
                    )
                  )}
                  {data.Code.Fields.length > 0 ? (
                    <Grid
                      item
                      className={classes["Generate-Reference-Group"]}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={(e) => {
                        let GC = data.Code.Fields.flatMap((x) => {
                          if (x.Name === "Free text") {
                            return x.Content;
                          } else {
                            return x.Name;
                          }
                        }).join(" ");
                        setGeneratedExample(
                          constructExample(data.Code.Fields, data.Name)
                        );
                        setGeneratedCode(GC);
                        window.scrollTo({
                          top: window.innerHeight,
                          behavior: "smooth",
                        });
                      }}
                    >
                      View Code
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid
                  xs={5}
                  paddingBottom={5}
                  paddingTop={2.5}
                  paddingLeft={1}
                  bgcolor={
                    selectedObject?.Id === data.Description.Id
                      ? "rgba(6, 116, 185, 0.3)"
                      : "#fff"
                  }
                >
                  {data.Description.Fields.map((desc, indxdesc) =>
                    desc.Name === "Free text" ? (
                      <Grid
                        key={desc.FieldId}
                        item
                        container
                        marginTop={1.5}
                        width={150}
                      >
                        <TextField
                          value={desc.Content}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <Grid
                                item
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                }}
                                onClick={(e) => {
                                  data.Description.Fields =
                                    data.Description.Fields.filter(
                                      (x, ind) => ind !== indxdesc
                                    );
                                  forceUpdate();
                                }}
                              >
                                <img src={RemoveIcon} width={13} alt={"delete"} />
                              </Grid>
                            ),
                            style: {
                              color: "#707070",
                              height: "32px",
                              fontSize: 12,
                              fontFamily: "Muli",
                              backgroundColor: "#fff",
                            },
                          }}
                          placeholder={"content"}
                          onChange={(e) => {
                            desc.Content = e.target.value;
                            forceUpdate();
                          }}
                        ></TextField>
                      </Grid>
                    ) : (
                      <Grid
                        marginTop={1.5}
                        key={desc.Id}
                        item
                        container
                        direction={"row"}
                        className={classes["Rectangle-37222"]}
                      >
                        <Grid
                          item
                          xs={11}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {desc.Name}
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={(e) => {
                            data.Description.Fields =
                              data.Description.Fields.filter(
                                (x, ind) => ind !== indxdesc
                              );
                            forceUpdate();
                          }}
                        >
                          <img src={RemoveIcon} width={13} alt={"Add"} />
                        </Grid>
                      </Grid>
                    )
                  )}
                  {data.Description.Fields.length > 0 ? (
                    <Grid
                      item
                      className={classes["Generate-Reference-Group"]}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={(e) => {
                        let GC = data.Description.Fields.flatMap(
                          (x) => x.Name
                        ).join(" ");
                        setGeneratedCode(GC);
                        setGeneratedExample(
                          constructExample(data.Description.Fields, data.Name)
                        );
                        window.scrollTo({
                          top: window.innerHeight,
                          behavior: "smooth",
                        });
                      }}
                    >
                      View Code
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid> */}

      </Grid>
      {
        generatedCode ? (
          <>
            <Grid
              item
              xs={12}
              className={classes["Customise-Code"]}
              marginTop={2}
            >
              Generated Code:
            </Grid>
            <Grid
              item
              className={classes["Customise-Code"]}
              fontWeight={"normal"}
            >
              {generatedCode}
            </Grid>
            {generatedExample ? (
              <>
                <Grid
                  item
                  xs={12}
                  className={classes["Customise-Code"]}
                  marginTop={2}
                >
                  Static Example:
                </Grid>
                <Grid
                  item
                  className={classes["Customise-Code"]}
                  fontWeight={"normal"}
                >
                  {generatedExample}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          ""
        )
      }

      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classes["SaveBut"]}
          disabled={saving}
          onClick={() => {
            save();
          }}
        >
          {saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setActiveTab(props.activeTab - 1);
          }}
        >
          Go Back
        </button>
      </Grid>
    </Grid >
  );
};

export default ItemCode;
