import React, { useState, useEffect } from "react";
import classes from "./Create.module.css";
import { Button, Grid } from "@mui/material";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ConsultantModal from "./ConsultantModal";
import CircularProgress from "@mui/material/CircularProgress";
import McModal from "./McModal";
import PropTypes from "prop-types";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import PictureModal from "./PictureModal";
import * as projectServices from "../../../Services/projects-services.proxy";
import ContractDetails from "./ContractDetails";
import LocationTest from "./LocationTest";
import { useSelector } from "react-redux";
import GoogleMapLocation from "./GoogleMapLocation";
import GoogleMapWithSearch from "./GoogleMapWithSearch";
import moment from "moment";




const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 12px;
  height:23px;
  width: 87px;
  box-shadow: 0 3px 6px 0 #e1e319;
  background-color: #fff;
  border-radius: 5px;
  border:0;
  color: #e1e319;

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  min-width: 87.6px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;  
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};



export default function PhaseDetails(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [phaseName, setPhaseName] = useState("");
  const [phaseCountry, setPhaseCountry] = useState("");
  const [phaseVisibility, setPhaseVisibility] = useState(2);
  const [phaseCode, setPhaseCode] = useState("");
  const [phaseAddress, setPhaseAddress] = useState("");
  const [phasePicture, setPhasePicture] = useState(null);
  const [phasePictureURL, setPhasePictureURL] = useState("");

  const [openLocation, setOpenLocation] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [phaseAbbreviation, setPhaseAbbreviation] = useState("");
  const [phaseDescription, setPhaseDescription] = useState("");
  const [phaseConsultantName, setPhaseConsultantName] = useState(null);
  const [phaseStarts, setPhaseStarts] = useState("");
  const [phaseEnds, setPhaseEnds] = useState("");
  const [phaseResponsibilityType, setPhaseResponsibilityType] = useState(2);

  const [phaseNameError, setPhaseNameError] = useState(false);
  const [phaseNameErrorMsg, setPhaseNameErrorMsg] = useState(null);

  const [phaseCountryError, setPhaseCountryError] = useState(false);

  const [phaseVisibilityError, setPhaseVisibilityError] = useState(false);

  const [phaseAddressError, setPhaseAddressError] = useState(false);
  const [phaseAddressErrorMsg, setPhaseAddressErrorMsg] = useState(false);

  const [phasePictureError, setPhasePictureError] = useState(false);

  const [phaseCodeInvalid, setPhaseCodeInvalid] = useState(false);
  const [phaseAbbreviationInvalid, setPhaseAbbreviationInvalid] =
    useState(false);
  const [phaseDescriptionInvalid, setPhaseDescriptionInvalid] = useState(false);

  const [phaseConsultantError, setPhaseConsultantError] = useState(false);
  const [phaseConsultantErrorMsg, setPhaseConsultantErrorMsg] = useState(false);
  const [mcName, setMcName] = useState(null);

  const [mcInformationError, setMcInformationError] = useState(false);
  const [mcInformationErrorMsg, setMcInformationErrorMsg] = useState(false);

  const [searchText, setSearchText] = useState(null);

  const [phsError, setPhsError] = useState(false);
  const [pheError, setPheError] = useState(false);

  const [openImage, setOpenImage] = useState(false);
  const [openConsultantModal, setOpenConsultantModal] = useState(false);
  const [consultantInformation, setConsultantInformation] = useState(null);
  const [mcInformation, setMCInformation] = useState(null);

  const [openMC, setOpenMC] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [modify, setModify] = useState(false);
  //ContractDetails
  const [contractId, setContractId] = useState(null);
  const [contractName, setContractName] = useState("");
  const [contractType, setContractType] = useState(1);
  const [contractSubType, setContractSubType] = useState("");
  const [contractTypeOther, setContractTypeOther] = useState("");
  const [contractAbbreviation, setContractAbbreviation] = useState("");
  const [contractCode, setContractCode] = useState("");
  const [contractDescription, setContractDescription] = useState("");
  const [contractFile, setContractFile] = useState(null);
  const [contractFileId, setContractFileId] = useState(null);
  const [contractStartDate, setContractStartDate] = useState(null);
  const [contractEndDate, setContractEndDate] = useState(null);
  const [contractStatus, setContractStatus] = useState(1);
  const [salesCreator, setSalesCreator] = useState("");
  const [factoryNb, setFactoryNb] = useState("");
  const [barStandard, setBarStandard] = useState("");
  const [barGrade, setBarGrade] = useState("");
  const [barCoating, setBarCoating] = useState("");
  const [measurement, setMeasurement] = useState(1);
  const [unit, setUnit] = useState("mm -- kg");
  const [billingType, setBillingType] = useState("");
  const [billingTypeOther, setBillingTypeOther] = useState("");
  const [billingRR, setBillingRR] = useState("");
  const [sheetSize, setSheetSize] = useState("");
  const [sheetSizeOther, setSheetSizeOther] = useState("");
  const [phaseId, setPhaseId] = useState(null);
  const [empty, setEmpty] = useState(true);
  const [newcons, setNewcons] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [contractIndex, setContractIndex] = useState(0);
  const [disableType, setDisableType] = useState(false);
  const [mileStoned, setMileStoned] = useState(false);
  const [isCuttingService, setIsCuttingService] = useState(false);
  const [isThreadingService, setIsThreadingService] = useState(false);
  const [isCutBendService, setIsCutBendService] = useState(false);


  useEffect(() => {
    let apitest = true;
    if (consultantInformation && apitest) {
      let arr = props.consultants;
      let arr1 = arr.filter((obj) => obj !== null);
      let arr2 = arr1.filter((obj) => obj.Name !== consultantInformation.Name);
      arr2.push(consultantInformation);
      props.setConsultants(arr2);
      arr = [];
      arr2 = [];
      setPhaseConsultantName(consultantInformation);
    }
    return () => {
      apitest = false;
    };
  }, [consultantInformation]);

  useEffect(() => {
    let arr = props.consultants.filter((obj) => obj !== null);
    props.setConsultants(arr);
  }, []);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  useEffect(() => {
    let arr = props.mainContractors;
    let arr1 = arr.filter((obj) => obj !== null);
    props.setMainContractors(arr1);
    arr = [];
    arr1 = [];
  }, []);

  useEffect(() => {
    if (mcInformation) {
      let arr = props.mainContractors.filter(obj => obj.CreationDate !== mcInformation.CreationDate)
      arr.push(mcInformation);
      props.setMainContractors(arr);
      arr = [];
      if (mcInformation?.InvitedCompanyName)
        setMcName(mcInformation);
    }
  }, [mcInformation]);

  const contract = {
    Id: null,
    Name: contractName,
    TypeId: contractSubType ? contractSubType : null,
    TypeOthers: contractTypeOther ? contractTypeOther : null,
    Abbreviation: contractAbbreviation
      ? contractAbbreviation
      : null,
    Code: contractCode ? contractCode : null,
    Description: contractDescription ? contractDescription : null,
    File: contractFileId ? { FileId: contractFileId } : null,
    BillingTypeId: billingType ? billingType : null,
    BillingTypeOthers: billingTypeOther ? billingTypeOther : null,
    BillingRetentionRatio: billingRR ? billingRR : null,
    DefaultSheetSizeId: sheetSize ? sheetSize : null,
    DefaultSheetSizeOthers: sheetSizeOther
      ? sheetSizeOther
      : null,
    StartDate: contractStartDate,
    EndDate: contractEndDate,
    AccountTypeId: contractType,
    StatusId: contractStatus,
    SalesOrderCreatorId: salesCreator ? salesCreator : null,
    MeasurementSystemId: measurement ? measurement : null,
    FactoryNumber: factoryNb ? factoryNb : null,
    BarStandardId: barStandard ? barStandard : null,
    BarGradeId: barGrade ? barGrade : null,
    BarCoatingId: barCoating ? barCoating : null,
    IsCuttingService: isCuttingService,
    IsThreadingService: isThreadingService,
    IsBendCutService: isCutBendService
  }

  const addNewContract = () => {
    if (contractId) {
      let index = contracts
        .map((x) => {
          return x.Id;
        })
        .indexOf(contractId);
      contracts.splice(index, 1);
    }
    if (contractName !== null) {
      let temp = contract;
      let arr = contracts;
      arr.push(temp);
      let filtered = arr;
      filtered = arr.filter(contract => contract?.Name !== "")
      setContracts(filtered);
      temp = null;
      arr = [];
      filtered = [];
    }
    setContractName("");
    if (contractType === 1)
      setContractType(2);
    else setContractType(1);
    setDisableType(true);
    setContractAbbreviation("");
    setContractCode("");
    setContractDescription("");
    setContractFile(null);
    setContractFileId(null);
    setContractStartDate(null);
    setContractEndDate(null);
    setBillingType("");
    setBillingRR("");
    setSheetSize("");
    setSheetSizeOther("");
    setBillingTypeOther("");
    setContractTypeOther("");
    setContractSubType("");
    setContractStatus(1);
    setSalesCreator("");
  };

  const phase = {
    Id: phaseId,
    Name: phaseName,
    Description: phaseDescription ? phaseDescription : null,
    Code: phaseCode ? phaseCode : null,
    Address: phaseAddress,
    CountryId: phaseCountry,
    Location: {
      Latitude: latitude,
      Longitude: longitude,
    },
    Abbreviation: phaseAbbreviation ? phaseAbbreviation : null,
    Picture: {
      FileId: phasePicture,
    },
    VisibilityId: phaseVisibility,
    StartDate: phaseStarts,
    EndDate: phaseEnds,
    Consultant: phaseConsultantName?.Id
      ? { Id: phaseConsultantName?.Id }
      : phaseConsultantName,
    ResponsibilityId: localStorage.getItem("AccountType") === "2" ? phaseResponsibilityType : null,
    InvitedCompanies:
      localStorage.getItem("AccountType") === "2" && phaseResponsibilityType === 1
        ? []
        : localStorage.getItem("AccountType") === "2" && phaseResponsibilityType === 2
          ? [
            {
              InviteeEmail: mcName?.InviteeEmail,
              InvitedCompanyName: mcName?.InvitedCompanyName,
              AccountTypeId: 1,
              File: mcName?.File,
              Contracts: contracts,
            },
          ]
          : null,
  };

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (props.phases.length > 0 && apiTest && !props.addPhase) {
      if (props.phases[props.phaseIndex]) {
        setPhaseId(props.phases[props.phaseIndex]?.Id);
        setPhaseName(props.phases[props.phaseIndex]?.Name);
        setPhaseCountry(props.phases[props.phaseIndex]?.CountryId);
        setPhaseCode(props.phases[props.phaseIndex]?.Code);
        setPhaseAddress(props.phases[props.phaseIndex]?.Address);
        if (props.phases[props.phaseIndex]?.Picture) {
          setPhasePicture(props.phases[props.phaseIndex]?.Picture.FileId);
          setPhasePictureURL(props.phases[props.phaseIndex]?.Picture?.URL);
        }
        setPhaseVisibility(props.phases[props.phaseIndex]?.VisibilityId);
        setLongitude(props.phases[props.phaseIndex]?.Location.Longitude);
        setLatitude(props.phases[props.phaseIndex]?.Location.Latitude);
        setPhaseAbbreviation(props.phases[props.phaseIndex]?.Abbreviation);
        setPhaseDescription(props.phases[props.phaseIndex]?.Description);
        setConsultantInformation(props.phases[props.phaseIndex]?.Consultant);
        setPhaseResponsibilityType(
          props.phases[props.phaseIndex]?.ResponsibilityId
        );
        setPhaseStarts(moment(props.phases[props.phaseIndex]?.StartDate).format("YYYY-MM-DD"));
        setPhaseEnds(moment(props.phases[props.phaseIndex]?.EndDate).format("YYYY-MM-DD"));
        setMileStoned(props.phases[props.phaseIndex]?.IsMilestoned);
        if ((localStorage.getItem("AccountType") === "2") ||
          (localStorage.getItem("AccountType") === "1"
            && props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[0]?.Id
          )
        ) {
          if (props.phases[props.phaseIndex]?.InvitedCompanies?.length > 0) {
            setMCInformation(
              props.phases[props.phaseIndex]?.InvitedCompanies[0]
            );
            //setMcName(props.phases[props.phaseIndex]?.InvitedCompanies[0]);
          }
          if (
            props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts
              ?.length > 0
          ) {
            setContracts(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts);
            setContractIndex(0);
          }
        }
      }
    } else {
      setPhaseName("");
      setPhaseCountry(props.projectCountry);
      setPhaseCode("");
      setPhaseAddress(props.projectAddress);
      setPhasePicture(null);
      setPhasePictureURL("");
      setLongitude("");
      setLatitude("");
      setPhaseAbbreviation("");
      setPhaseDescription("");
      setPhaseConsultantName(null);
      setPhaseStarts(null);
      setPhaseEnds(null);
      setMileStoned(false);
      setPhaseVisibility(props.projectVisibility);
      setConsultantInformation(null);
      setMCInformation(null);
      setMcName(null);
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
    // cleanup
    return () => {
      apiTest = false;
    };
  }, [props.phaseIndex]);

  useEffect(() => {
    if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]) {
      setContractId(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.Id)
      setContractName(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.Name
      );
      setContractType(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId
      );
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 1)
        setContractSubType(
          props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.TypeId
        );
      if (
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.TypeOthers
      )
        setContractTypeOther(
          props.phases[props.phaseIndex]?.InvitedCompanies[0]
            ?.Contracts[contractIndex]?.TypeOthers
        );
      setContractStatus(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.StatusId
      );
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 2)
        setSalesCreator(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.SalesOrderCreatorId)
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 2) {
        setFactoryNb(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.FactoryNumber)
        props.setBarStandards(props.factoryNumbers.find(f => f.Id === props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.FactoryNumber)?.References.find(r => r.ReferenceId === 1)?.Values);
        props.setBarGrades(props.factoryNumbers.find(f => f.Id === props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.FactoryNumber)?.References.find(r => r.ReferenceId === 2)?.Values);
        props.setBarCoatings(props.factoryNumbers.find(f => f.Id === props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.FactoryNumber)?.References.find(r => r.ReferenceId === 3)?.Values);
      } if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 2)
        setBarStandard(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.BarStandardId)
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 2)
        setBarGrade(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.BarGradeId)
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 2)
        setBarCoating(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.BarCoatingId)
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.AccountTypeId === 2)
        //setMeasurement(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.MeasurementSystemId)
        setMeasurement(1)
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.MeasurementSystemId === 1)
        setUnit("mm -- kg")
      if (props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.MeasurementSystemId === 2)
        setUnit("inches -- pounds")
      setContractAbbreviation(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.Abbreviation
      );
      setContractCode(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.Code
      );
      setContractDescription(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.Description
      );
      setContractFile(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.File
      );
      setContractFileId(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.File?.FileId
      );
      setBillingType(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.BillingTypeId
      );
      if (
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.BillingTypeOthers
      )
        setBillingTypeOther(
          props.phases[props.phaseIndex]?.InvitedCompanies[0]
            ?.Contracts[contractIndex]?.BillingTypeOthers
        );
      setBillingRR(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.BillingRetentionRatio
      );
      setSheetSize(
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.DefaultSheetSizeId
      );
      if (
        props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.DefaultSheetSizeOthers
      )
        setSheetSizeOther(
          props.phases[props.phaseIndex]?.InvitedCompanies[0]
            ?.Contracts[contractIndex]?.DefaultSheetSizeOthers
        );
      setContractStartDate(
        moment(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.StartDate).format("YYYY-MM-DD")
      );
      setContractEndDate(
        moment(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.EndDate).format("YYYY-MM-DD")

      );
      setIsCutBendService(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.IsBendCutService);
      setIsCuttingService(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.IsCuttingService);
      setIsThreadingService(props.phases[props.phaseIndex]?.InvitedCompanies[0]?.Contracts[contractIndex]?.IsThreadingService);
    }
    else {
      setContractId(null)
      setContractName("");
      if ((localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Pro") || localStorage.getItem("planIdManu")?.includes("Core")) && !localStorage.getItem("planId"))
        setContractType(2);
      else setContractType(1);
      setContractSubType("");
      setContractTypeOther("");
      setContractStatus(1);
      setSalesCreator("")
      setContractAbbreviation("");
      setContractCode("");
      setContractDescription("");
      setContractFile(null);
      setContractFileId(null);
      setBillingType("");
      setBillingTypeOther("");
      setBillingRR("");
      setSheetSize("");
      setSheetSizeOther("");
      setContractStartDate(null);
      setContractEndDate(null);
    }
  }, [props.phaseIndex, contractIndex])

  useEffect(() => {
    if (props.editPhase) {
      var result = props.phases.find((obj) => {
        return obj.Id === props.editPhaseId;
      });
      if (props.editPhaseId) {
        setPhaseName(result.Name);
        setPhaseCode(result.Code);
      }
    }
  }, [props.editPhase]);

  const create = () => {
    if (contractId) {
      let index = contracts
        .map((x) => {
          return x.Id;
        })
        .indexOf(contractId);
      contracts.splice(index, 1);
    }
    if (contractName !== null) {
      let tempc = contract;
      let arrc = contracts;
      arrc.push(tempc);
      let filteredc = arrc;
      filteredc = arrc.filter(contract => contract?.Name !== "")
      setContracts(filteredc);
      tempc = null;
      arrc = [];
      filteredc = [];
    }
    if (phaseId) {
      let index = props.phases
        .map((x) => {
          return x.Id;
        })
        .indexOf(phaseId);

      props.phases.splice(index, 1);
    }
    let temp = phase;
    let arr = props.phases;
    arr.push(temp);
    let filtered = arr.filter(phase => phase.Name !== "");
    props.setPhases(filtered);
    temp = null;
    arr = [];
    filtered = [];
    props.create();
    setTimeout(() => {
      setPhaseName("");
      setPhaseCountry("");
      setPhaseCode("");
      setPhaseAddress("");
      setPhasePicture(null);
      setPhasePictureURL("");
      setLongitude("");
      setLatitude("");
      setPhaseAbbreviation("");
      setPhaseDescription("");
      setPhaseConsultantName(null);
      setPhaseStarts(null);
      setPhaseEnds(null);
      setMileStoned(false);
      setPhaseVisibility(2);
      setConsultantInformation(null);
      setMCInformation(null);
      setMcName(null);
      setContracts([]);
      setContractId(null);
      setContractName("");
      if ((localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Pro") || localStorage.getItem("planIdManu")?.includes("Core")) && !localStorage.getItem("planId"))
        setContractType(2);
      else setContractType(1);
      setContractSubType("");
      setContractStatus(1);
      setSalesCreator("");
      setContractTypeOther("");
      setContractCode("");
      setContractDescription("");
      setContractAbbreviation("");
      setContractFile("");
      setContractFileId("");
      setBillingType("");
      setBillingTypeOther("");
      setBillingRR("");
      setSheetSize("");
      setContractStartDate(null);
      setContractEndDate(null);
    }, 3000);
  };

  const createPhase = () => {
    if (contractId) {
      let index = contracts
        .map((x) => {
          return x.Id;
        })
        .indexOf(contractId);
      contracts.splice(index, 1);
    }
    if (contractName !== null) {
      let tempc = contract;
      let arrc = contracts;
      arrc.push(tempc);
      let filteredc = arrc;
      filteredc = arrc.filter(contract => contract?.Name !== "")
      setContracts(filteredc);
      tempc = null;
      arrc = [];
      filteredc = [];
    }
    if (phaseId) {
      let index = props.phases
        .map((x) => {
          return x.Id;
        })
        .indexOf(phaseId);
      props.phases.splice(index, 1);
    }
    if (phaseName !== null) {
      let temp = phase;
      let arr = props.phases;
      arr.push(temp);
      let filtered = arr.filter(phase => phase.Name !== "");
      props.setPhases(filtered);
      temp = null;
      arr = [];
      filtered = [];
    }
    setPhaseName("");
    setPhaseCountry("");
    setPhaseCode("");
    setPhaseAddress("");
    setPhasePicture(null);
    setPhasePictureURL("");
    setLongitude("");
    setLatitude("");
    setPhaseAbbreviation("");
    setPhaseDescription("");
    setPhaseConsultantName(null);
    setPhaseStarts(null);
    setPhaseEnds(null);
    setMileStoned(false);
    setPhaseVisibility(2);
    setConsultantInformation(null);
    setPhaseResponsibilityType(1);
    setMCInformation(null);
    setMcName(null);
    setContractId(null);
    setContractName("");
    if ((localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Pro") || localStorage.getItem("planIdManu")?.includes("Core")) && !localStorage.getItem("planId"))
      setContractType(2);
    else setContractType(1);
    setContractSubType("");
    setSalesCreator("");
    setContractStatus(1);
    setContractTypeOther("");
    setContractCode("");
    setContractDescription("");
    setContractAbbreviation("");
    setContractFile("");
    setContractFileId("");
    setBillingType("");
    setBillingTypeOther("");
    setBillingRR("");
    setSheetSize("");
    setContractStartDate(null);
    setContractEndDate(null);
  };

  const styles = {
    label: {
      fontFamily: "Muli",
      fontSize: 12,
      fontWeight: "bold",
      lineHeight: 1.5,
      textAlign: "left",
      color: "#101650",
    },
    error: {
      fontFamily: "Muli",
      fontSize: 12,
      lineHeight: 1.5,
      textAlign: "left",
      color: "#d32f2f",
      paddingTop: "1.5%",
    },
  };

  useEffect(() => {
    if (
      phaseNameError ||
      !phaseName ||
      phaseCountryError ||
      !phaseCountry ||
      phaseAddressError ||
      !phaseAddress ||
      // phasePictureError ||
      // !phasePicture ||
      // phaseConsultantError ||
      // !phaseConsultantName ||
      phsError ||
      !phaseStarts ||
      pheError ||
      !phaseEnds ||
      !phaseVisibility ||
      phaseVisibilityError ||
      phaseCodeInvalid ||
      phaseDescriptionInvalid ||
      phaseAbbreviationInvalid ||
      (localStorage.getItem("AccountType") === "2" &&
        phaseResponsibilityType === 2 &&
        (
          !contractStartDate ||
          !contractEndDate ||
          !contractStatus ||
          !contractName ||
          !contractType ||
          (contractType === 1 && !contractSubType) ||
          // (contractType === 2 && !salesCreator && !factoryNb && !barStandard && !barGrade && !barCoating)
          (contractType === 2 && (!salesCreator || !factoryNb || !barStandard || !barGrade || !barCoating || (!isCuttingService && !isCutBendService && !isThreadingService)))

        )
      )
    )
      setEmpty(true);
    else setEmpty(false);
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
      columnSpacing={10}
      bgcolor="#fff"
      paddingTop="5%"
      paddingBottom={5}
    >
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 15,
          fontWeight: "bold",
          lineHeight: 1.47,
          textAlign: "left",
          color: "rgba(16, 22, 80, 0.5)",
        }}
        marginLeft="8%"
        alignItems="flex-end"
        spacing={1}
        container
      >
        <Grid item>{props.projectName}</Grid>
        <Grid item xs={8}>
          Phase
        </Grid>
        <Grid item>
          <button
            disabled={
              localStorage.getItem("IsSubscribedBefore") === "true" ||
                (localStorage.getItem("isGuest") === "true" ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ) && localStorage.getItem("notCreator") === "true") ||
                  empty) &&
                !props.join
                ? true
                : false
            }
            style={{ width: 150, height: 30 }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "#fff";
              e.target.style.backgroundColor =
                localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
            }}
            onMouseLeave={(e) => {
              e.target.style.color =
                localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
              e.target.style.backgroundColor = "#fff";
            }}
            className={
              localStorage.getItem("IsSubscribedBefore") === "true" ||
                ((localStorage.getItem("isGuest") === "true" ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    )) ||
                  empty ||
                  localStorage.getItem("notCreator") === "true") &&
                  !props.join)
                ? classes.addButtonEmpty
                : localStorage.getItem("AccountType") === "1"
                  ? classes.addButtonMC
                  : classes.addButtonSC
            }
            onClick={() => {
              setPhaseId(null);
              props.setAddPhase(true);
              props.setViewdisable(false);
              props.setPhaseIndex(props.phases.length);
              createPhase();
            }}
          >
            Add new phase
          </button>
        </Grid>
      </Grid>

      {phaseId && !phaseName ? (
        <Grid
          item
          xs={12}
          container
          sx={{
            position: "relative",
            left: "45%",
          }}
        >
          <Grid item xs={12} paddingLeft="2%">
            <CircularProgress size={24} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              fontfamily: "Muli",
              fontSize: 18,
              lineHeight: 1.44,
              textAlign: "left",
              color: "#0674b9",
            }}
          >
            Loading ...
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          *Phase Name
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            value={phaseName}
            error={phaseNameError}
            helperText={phaseNameError ? phaseNameErrorMsg : ""}
            variant="standard"
            onBlur={(e) => {
              if (!e.target.value) {
                setPhaseNameError(true);
                setPhaseNameErrorMsg("Enter a name");
              }
            }}
            onChange={(e) => {
              setPhaseName(e.target.value);
              if (!e.target.value) {
                setPhaseNameError(true);
                setPhaseNameErrorMsg("Enter a name");
              } else if (e.target.value.length > 150) {
                setPhaseNameError(true);
                setPhaseNameErrorMsg("Name must be less than 150 char");
              } else {
                setPhaseNameError(false);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1.4}></Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          *Phase Country
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={phaseCountry}
              error={phaseCountryError}
              label="Age"
              onBlur={(e) => {
                if (!e.target.value) setPhaseCountryError(true);
              }}
              onChange={(e) => {
                setPhaseCountryError(false);
                setPhaseCountry(e.target.value);
              }}
            >
              {props.countries?.map((option, index) => (
                <MenuItem
                  value={option.Id}
                  key={index}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {phaseCountryError ? "Choose a country" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          Phase Code
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            variant="standard"
            value={phaseCode}
            error={phaseCodeInvalid}
            helperText={
              phaseCodeInvalid ? "Code must be less than 30 characters" : ""
            }
            onChange={(e) => {
              if (e.target.value.length > 30) setPhaseCodeInvalid(true);
              else {
                setPhaseCodeInvalid(false);
                setPhaseCode(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1.4}></Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          *Phase Address
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            value={phaseAddress}
            error={phaseAddressError}
            helperText={phaseAddressError ? phaseAddressErrorMsg : ""}
            onBlur={(e) => {
              if (!e.target.value) {
                setPhaseAddressError(true);
                setPhaseAddressErrorMsg("Enter your address");
              } else {
                setPhaseAddressError(false);
              }
            }}
            onChange={(e) => {
              setPhaseAddress(e.target.value);
              if (!e.target.value) {
                setPhaseAddressError(true);
                setPhaseAddressErrorMsg("Enter your address");
              } else if (e.target.value.length > 150) {
                setPhaseAddressError(true);
                setPhaseAddressErrorMsg("Address must be less than 150 char");
              } else {
                setPhaseAddressError(false);
              }
            }}
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid item xs={4.3} container>
        <Grid item md={3} xs={5} sx={styles.label}>
          Phase Picture
        </Grid>
        <Grid item md={2} xs={2} paddingLeft={1}>
          <button
            disabled={props.viewdisable}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "#fff";
              e.target.style.backgroundColor =
                localStorage.getItem("AccountType") === "1" ? "#e1e319" : " #2da4d0";
            }}
            onMouseLeave={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color =
                localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
              e.target.style.backgroundColor = "#fff";
            }}
            onClick={() => setOpenImage(true)}
            className={
              localStorage.getItem("AccountType") === "1" ? classes.addButtonMC : classes.addButtonSC
            }
          >
            Add +
          </button>
        </Grid>
        <Grid item xs={12}>
          {phasePictureURL ? (
            <Grid item xs={10} width="200px" height="200px" marginTop={3}>
              <img
                src={phasePictureURL}
                alt={"phasseimg"}
                style={{ width: "200px", height: "200px" }}
              ></img>
            </Grid>
          ) : (
            <TextField disabled fullWidth variant="standard" />
          )}
        </Grid>
      </Grid>
      <Grid item xs={1.4}></Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          *Phase Location
        </Grid>
        <Grid item md={2} xs={2} padding="5% 0 0 1%">
          <button
            disabled={props.viewdisable}
            onMouseOver={(e) => {
              if (props.viewdisable) e.target.style.cursor = "unset";
              else e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              setOpenLocation(true);
            }}
            style={{ backgroundColor: "#fff", border: 0 }}
          >
            <LocationOnIcon
              style={{ backgroundColor: "#fff", color: "#2da4d0", border: 0 }}
            />
          </button>
        </Grid>
        <Grid item xs={10}>
          {longitude && latitude ? (
            <div
              style={{
                marginTop: "5%",
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                color: "#101650",
              }}
            >
              Longitude:{longitude}
              <br />
              Latitude:{latitude}
            </div>
          ) : (
            <TextField
              disabled
              fullWidth
              label="Enter Phase Location"
              variant="standard"
            />
          )}
        </Grid>
        {latitude && longitude ? (
          <Grid
            item
            xs={12}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              setOpenLocation(true);
            }}
          >
            {/* <GoogleMapLocation
              longitude={longitude}
              setLongitude={setLongitude}
              latitude={latitude}
              setLatitude={setLatitude}
            /> */}
            <GoogleMapWithSearch
              longitude={longitude}
              setLongitude={setLongitude}
              latitude={latitude}
              setLatitude={setLatitude}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={9} md={4} sx={styles.label}>
          Consultant Name
        </Grid>
        <Grid item xs={2}>
          <button
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "#fff";
              e.target.style.backgroundColor =
                localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
            }}
            onMouseLeave={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color =
                localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
              e.target.style.backgroundColor = "#fff";
            }}
            className={
              localStorage.getItem("AccountType") === "1" ? classes.addButtonMC : classes.addButtonSC
            }
            onClick={() => {
              setOpenConsultantModal(true);
              if (phaseConsultantName && !phaseConsultantName.Id)
                setConsultantInformation(phaseConsultantName);
            }}
          >
            {phaseConsultantName?.Id ? "View" : "Add +"}
          </button>
        </Grid>
        {phaseConsultantName?.Id && !props.viewdisable ? (
          <Grid item xs={2}>
            <button
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color = "#fff";
                e.target.style.backgroundColor =
                  localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
              }}
              onMouseLeave={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color =
                  localStorage.getItem("AccountType") === "1" ? "#e1e319" : "#2da4d0";
                e.target.style.backgroundColor = "#fff";
              }}
              className={
                localStorage.getItem("AccountType") === "1" ? classes.addButtonMC : classes.addButtonSC
              }
              onClick={() => {
                setOpenConsultantModal(true);
                setPhaseConsultantName(null);
                setConsultantInformation(null);
                setNewcons(true);
              }}
            >
              Add +
            </button>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Select
            MenuProps={{ disableScrollLock: true }}
            disabled={props.viewdisable}
            value={phaseConsultantName}
            error={phaseConsultantError}
            onBlur={(e) => {
              if (!e.target.value) {
                setPhaseConsultantError(true);
                setPhaseConsultantErrorMsg("Select a consultant");
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              setPhaseConsultantName(e.target.value);
              setPhaseConsultantError(false);
            }}
            fullWidth
            variant="standard"
          >
            {props.consultants?.map((option, index) => (
              <MenuItem value={option} key={index} style={{ display: "block" }}>
                {option?.Name}
              </MenuItem>
            ))}
          </Select>
          <div style={styles.error}>
            {phaseConsultantError ? phaseConsultantErrorMsg : ""}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={1.4}></Grid>
      <Grid item xs={4.3} container marginTop="1%">
        <Grid item xs={12} sx={styles.label}>
          *Phase Visiblity
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={phaseVisibility}
              error={phaseVisibilityError}
              label="Age"
              onBlur={(e) => {
                if (!e.target.value) setPhaseVisibilityError(true);
              }}
              onChange={(e) => {
                setPhaseVisibilityError(false);
                setPhaseVisibility(e.target.value);
              }}
            >
              {props.visibilities?.map((option, index) => (
                <MenuItem
                  value={option.Id}
                  key={index}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {phaseVisibilityError ? "Choose a visibility" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          Phase Abbreviation
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            variant="standard"
            multiline
            value={phaseAbbreviation}
            error={phaseAbbreviationInvalid}
            helperText={
              phaseAbbreviationInvalid
                ? "Abbreviation must be less than 30 characters"
                : ""
            }
            onChange={(e) => {
              if (e.target.value.length > 30) setPhaseAbbreviationInvalid(true);
              else {
                setPhaseAbbreviationInvalid(false);
                setPhaseAbbreviation(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1.4}></Grid>
      <Grid item xs={4.3} container>
        <Grid item xs={12} sx={styles.label}>
          Phase Description
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            variant="standard"
            multiline
            value={phaseDescription}
            error={phaseDescriptionInvalid}
            helperText={
              phaseDescriptionInvalid
                ? "Description must be less than 255 characters"
                : ""
            }
            onChange={(e) => {
              if (e.target.value.length > 255) setPhaseDescriptionInvalid(true);
              else {
                setPhaseDescriptionInvalid(false);
                setPhaseDescription(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      {
        (localStorage.getItem("isTeamMember") === "true" && UserPermissions?.find(
          x => x.Permissions.find(y => y.Name === "Phase") &&
            x.Permissions.find(y => y.Actions.find(z => z.Name === "Assign Contractor Responsibility"))
        )) ||
          (localStorage.getItem("AccountType") !== "1" && localStorage.getItem("isSubscribed") === "true")
          ||
          (localStorage.getItem("AccountType") !== "1" && localStorage.getItem("isSubscribedManu") === "true")
          || localStorage.getItem("IsFreePlanActivated") === "true"
          ? (
            <Grid item xs={10} md={4.3} container>
              <Grid item xs={12} sx={styles.label}>
                * Phase Responsibility
              </Grid>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={4}
                  md={3}
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                >
                  <CustomSelect
                    disabled={props.viewdisable}
                    MenuProps={{ disableScrollLock: true }}
                    value={phaseResponsibilityType}
                    onChange={(option) => {
                      setPhaseResponsibilityType(option);
                    }}
                  >
                    <StyledOption
                      value={1}
                      onClick={() => {
                        setMcInformationError(false);
                        setMCInformation(null);
                        setMcName(null);
                        setContracts([]);
                        setContractId(null);
                        setContractName("");
                        if ((localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Pro") || localStorage.getItem("planIdManu")?.includes("Core")) && !localStorage.getItem("planId"))
                          setContractType(2);
                        else setContractType(1);
                        setContractSubType("");
                        setContractStatus(1);
                        setSalesCreator("");
                        setContractTypeOther("");
                        setContractCode("");
                        setContractDescription("");
                        setContractAbbreviation("");
                        setContractFile("");
                        setContractFileId("");
                        setBillingType("");
                        setBillingTypeOther("");
                        setBillingRR("");
                        setSheetSize("");
                        setContractStartDate(null);
                        setContractEndDate(null);
                      }}
                    >
                      InHouse
                    </StyledOption>
                    <StyledOption value={2}>Outsource</StyledOption>
                  </CustomSelect>
                </Grid>
                {phaseResponsibilityType === 2 ? (
                  <Grid item xs={4} md={3} paddingLeft={1} paddingTop={0.3}>
                    <button
                      className={classes.PhaseResponsibilityButtons}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#e1e319";
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                        e.target.style.color = "#e1e319";
                      }}
                      onClick={() => setOpenMC(true)}
                    >
                      {mcInformation || mcName ? "View" : "Add MC +"}
                    </button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item md={4.3} xs={10}></Grid>
          )}
      <Grid item xs={1.4}></Grid>
      <Grid item md={4.3} xs={10} sx={styles.label} container spacing={0.5}>
        <Grid item xs={6} container>
          <Grid item xs={12} sx={styles.label}>
            *Phase starts
          </Grid>
          <Grid item xs={12} paddingTop="5%">
            <TextField
              fullWidth
              disabled={props.viewdisable || mileStoned}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{ className: classes.fieldDesign }}
              size="small"
              label={""}
              type={"date"}
              value={phaseStarts ? phaseStarts : ""}
              defaultValue={""}
              variant="outlined"
              error={phsError}
              // helperText={plannedStartDateErrorMessage}
              onBlur={(e) => {
                if (!e.target.value) {
                  setPhsError(true);
                }
              }}
              onChange={(e) => {
                setPhaseStarts(e.target.value);
                if (e.target.value.length > 10) setPhsError(true);
                else setPhsError(false);

              }}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={props.viewdisable || mileStoned}
                openTo="day"
                views={["year", "month", "day"]}
                label="dd/mm/yyyy"
                disableMaskedInput
                inputFormat="dd/MM/yyyy"
                value={phaseStarts}
                onChange={(newValue) => {
                  var date = new Date(newValue);
                  if (newValue === "") {
                    setPhsError(true);
                  } else {
                    setPhsError(false);
                  }
                  setPhaseStarts(newValue.toLocaleDateString());
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontFamily: "Muli",
                        fontSize: "15px",
                      },
                    }}
                    error={phsError}
                    onBlur={() => {
                      if (!phaseStarts) setPhsError(true);
                    }}
                    helperText={phsError ? "Enter a date" : <></>}
                    size="small"
                  />
                )}
              />
            </LocalizationProvider> */}
          </Grid>
        </Grid>
        <Grid item xs={6} container>
          <Grid item xs={12} sx={styles.label}>
            *Phase ends
          </Grid>
          <Grid item xs={12} paddingTop="5%">
            <TextField
              fullWidth
              disabled={props.viewdisable || mileStoned}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{ className: classes.fieldDesign }}
              size="small"
              label={""}
              type={"date"}
              value={phaseEnds ? phaseEnds : ""}
              variant="outlined"
              error={pheError}
              defaultValue={""}
              // helperText={plannedStartDateErrorMessage}
              onBlur={(e) => {
                if (!e.target.value) {
                  setPheError(true);
                }
              }}
              onChange={(e) => {
                setPhaseEnds(e.target.value);
                if (e.target.value.length > 10) setPheError(true);
                else setPheError(false);

              }}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={props.viewdisable || mileStoned}
                openTo="day"
                views={["year", "month", "day"]}
                label="dd/mm/yyyy"
                minDate={new Date(phaseStarts)}
                disableMaskedInput
                inputFormat="dd/MM/yyyy"
                value={phaseEnds}
                onChange={(newValue) => {
                  var date = new Date(newValue);
                  if (newValue === "") {
                    setPheError(true);
                  } else {
                    setPheError(false);
                  }
                  setPhaseEnds(newValue.toLocaleDateString());
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontFamily: "Muli",
                        fontSize: "15px",
                      },
                    }}
                    error={pheError}
                    onBlur={() => {
                      if (!phaseEnds) setPheError(true);
                    }}
                    helperText={pheError ? "Enter a date" : <></>}
                    size="small"
                  />
                )}
              />
            </LocalizationProvider> */}
          </Grid>
        </Grid>
      </Grid>
      {phaseResponsibilityType === 2 && localStorage.getItem("AccountType") !== "1" ? (
        <Grid item xs={12} container>
          <Grid item xs={3} paddingLeft={12}>
            <FormControl
              variant="standard"
              fullWidth
              disabled={props.viewdisable}
            >
              <Select
                MenuProps={{ disableScrollLock: true }}
                value={mcName}
                error={mcInformationError}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setMcInformationError(true);
                    setMcInformationErrorMsg("Select a Main Contractor");
                  }
                }}
                onChange={(e) => {
                  e.preventDefault();
                  setMcName(e.target.value);
                  setMCInformation(e.target.value);
                  setMcInformationError(false);
                  setContracts([]);
                  setContractId(null);
                  setContractName("");
                  if ((localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Pro") || localStorage.getItem("planIdManu")?.includes("Core")) && !localStorage.getItem("planId"))
                    setContractType(2);
                  else setContractType(1);
                  setContractSubType("");
                  setContractStatus(1);
                  setSalesCreator("");
                  setContractTypeOther("");
                  setContractCode("");
                  setContractDescription("");
                  setContractAbbreviation("");
                  setContractFile("");
                  setContractFileId("");
                  setBillingType("");
                  setBillingTypeOther("");
                  setBillingRR("");
                  setSheetSize("");
                  setContractStartDate(null);
                  setContractEndDate(null);
                }}
              >
                {props.mainContractors?.map((option, index) => (
                  <MenuItem
                    value={option}
                    key={index}
                    style={{ display: "block" }}
                  >
                    {option?.InvitedCompanyName}
                  </MenuItem>
                ))}
              </Select>
              <div style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                {mcInformationError ? mcInformationErrorMsg : ""}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <LocationTest
        openModal={openLocation}
        setOpenModal={setOpenLocation}
        longitude={longitude}
        setLongitude={setLongitude}
        latitude={latitude}
        setLatitude={setLatitude}
      />
      <PictureModal
        openModal={openImage}
        setOpenModal={setOpenImage}
        phasePicture={phasePicture}
        setPhasePicture={setPhasePicture}
        phasePictureURL={phasePictureURL}
        setPhasePictureURL={setPhasePictureURL}
        projectId={props.projectId}
      />
      <ConsultantModal
        newcons={newcons}
        setNewcons={setNewcons}
        openModal={openConsultantModal}
        setOpenModal={setOpenConsultantModal}
        setConsultantInformation={setConsultantInformation}
        consultantInformation={consultantInformation}
        viewdisable={props.viewdisable}
        countries={props.countries}
        phaseConsultantName={phaseConsultantName}
        setPhaseConsultantError={setPhaseConsultantError}
        join={props.join}
      />
      <McModal
        projectName={props.projectName}
        setMcInformationError={setMcInformationError}
        openModal={openMC}
        setOpenModal={setOpenMC}
        rowData={rowData}
        setRowData={setRowData}
        setModify={setModify}
        modify={modify}
        setMCInformation={setMCInformation}
        mcInformation={mcInformation}
        viewdisable={props.viewdisable}
        setMcName={setMcName}
      />
      {phaseResponsibilityType === 2 && (contractId || mcInformation || mcName) ? (
        <ContractDetails
          disableType={disableType}
          addNewContract={addNewContract}
          contracts={contracts}
          setContractIndex={setContractIndex}
          join={props.join}
          viewdisable={props.viewdisable}
          contractId={contractId}
          setContractId={setContractId}
          contractName={contractName}
          setContractName={setContractName}
          contractType={contractType}
          setContractType={setContractType}
          contractSubType={contractSubType}
          setContractSubType={setContractSubType}
          contractAbbreviation={contractAbbreviation}
          setContractAbbreviation={setContractAbbreviation}
          contractCode={contractCode}
          setContractCode={setContractCode}
          contractDescription={contractDescription}
          setContractDescription={setContractDescription}
          contractFile={contractFile}
          setContractFile={setContractFile}
          contractFileId={contractFileId}
          setContractFileId={setContractFileId}
          salesCreator={salesCreator}
          setSalesCreator={setSalesCreator}
          contractStatus={contractStatus}
          setContractStatus={setContractStatus}
          contractStartDate={contractStartDate}
          setContractStartDate={setContractStartDate}
          contractEndDate={contractEndDate}
          setContractEndDate={setContractEndDate}
          factoryNb={factoryNb}
          setFactoryNb={setFactoryNb}
          setBarStandards={props.setBarStandards}
          setBarGrades={props.setBarGrades}
          setBarCoatings={props.setBarCoatings}
          barStandard={barStandard}
          setBarStandard={setBarStandard}
          barGrade={barGrade}
          setBarGrade={setBarGrade}
          barCoating={barCoating}
          setBarCoating={setBarCoating}
          measurement={measurement}
          setMeasurement={setMeasurement}
          unit={unit}
          setUnit={setUnit}
          billingType={billingType}
          setBillingType={setBillingType}
          billingRR={billingRR}
          setBillingRR={setBillingRR}
          sheetSize={sheetSize}
          setSheetSize={setSheetSize}
          createPhase={createPhase}
          contractTypes={props.contractTypes}
          accountTypes={props.accountTypes}
          salesCreators={props.salesCreators}
          factoryNumbers={props.factoryNumbers}
          barStandards={props.barStandards}
          barGrades={props.barGrades}
          barCoatings={props.barCoatings}
          measurements={props.measurements}
          contractStatuses={props.contractStatuses}
          contractBillingTypes={props.contractBillingTypes}
          defaultSheetSizes={props.defaultSheetSizes}
          phases={props.phases}
          setPhases={props.setPhases}
          editPhaseId={props.editPhaseId}
          setSheetSizeOther={setSheetSizeOther}
          sheetSizeOther={sheetSizeOther}
          setBillingTypeOther={setBillingTypeOther}
          billingTypeOther={billingTypeOther}
          setContractTypeOther={setContractTypeOther}
          contractTypeOther={contractTypeOther}
          projectId={props.projectId}
          phaseName={phaseName}
          contractorName={
            mcInformation
              ? mcInformation?.InvitedCompanyName
              : mcName?.CompanyName
          }
          isCuttingService={isCuttingService}
          setIsCuttingService={setIsCuttingService}
          isCutBendService={isCutBendService}
          setIsCutBendService={setIsCutBendService}
          isThreadingService={isThreadingService}
          setIsThreadingService={setIsThreadingService}
        />
      ) : (
        <></>
      )}
      <Grid container item xs={10} direction={"row-reverse"} gap={5}>
        <Button
          disabled={
            empty || (props.viewdisable && !props.addPhase && !props.join)
          }
          style={{
            borderRadius: 5,
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor:
              empty || (props.viewdisable && !props.addPhase && !props.join)
                ? "lightgrey"
                : "#0b5cab",
            opacity: props.loading ? 0.6 : 1,
            fontFamily: "Muli",
            fontSize: 13,
            fontWeight: "bold",
            lineHeight: 1.46,
            textAlign: "left",
            color: "#fff",
            textTransform: "none",
          }}
          onClick={() => {
            create();
            props.setLoading(true);
          }}
        >
          {props.loading
            ? "Loading..."
            : props.projectId
              ? props.join
                ? props.addPhase
                  ? "Save"
                  : "Join"
                : "Save"
              : "Create"}
        </Button>
      </Grid>
    </Grid>
  );
}