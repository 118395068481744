import { Button, Grid, MenuItem, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActivityCard from "./ActivityCard";
import * as activityServices from "../../Services/activities-services.proxy";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import * as permissionsFunctions from "../../../ReusableFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import ErrorModal from "../../SharedComponents/ErrorModal";
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import moment from "moment";

const CheckIfPermitted = permissionsFunctions.CheckIfPermitted;
const getActivitiesCardList = activityServices.getActivitiesCardList;
export default function Activities(props) {
  const navigate = useNavigate();
  const data0 = {
    Code: "Activity Code",
    Description:
      "Summary Activity Description...Summary Activity Description...",
    ActivityStatus: "",
    SubmittalStatus: "",
  };
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [activitiesPerStatuses, setActivitiesPerStatuses] = useState([]);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedAssigneesIds, setSelectedAssigneesIds] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState(null);

  //lists
  const [statuses, setStatuses] = useState(["Draft", "Planned", "In Progress", "Submitted for 1-internal approval", "Submitted for 2-internal approval", "Submitted to consultant"])
  const [assignees, setAssignees] = useState([]);
  const [types, setTypes] = useState([]);

  //loading and error handling
  const [BEerror, setBEerror] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [modify, setModify] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);



  const listData = {
    StartDate: dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DD') : "",
    EndDate: dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : "",
    AssigneeIds: selectedAssigneesIds,
    TypeId: selectedTypeId
  }

  useEffect(() => {
    setLoading(true);
    getActivitiesCardList(listData).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setTotal(x.length);
        setActivitiesPerStatuses(x.Statuses);
        setAssignees(x.Assignee);
        setTypes(x.Types);
      }
      setLoading(false);
    });
  }, [dateRange, selectedAssigneesIds, selectedTypeId, modify]);
  const planType = localStorage.getItem("planId");

  const [disabled, setDisabled] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("IsFreePlanActivated") === "true" &&
      localStorage.getItem("isSubscribed") === "false"
    ) {
      setDisabled(true);
    } else if (
      !localStorage.getItem("isSubscribed") &&
      localStorage.getItem("isGuest") !== "true"
    ) {
      setDisabled(true);
    }
  }, []);
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Drawing Activity",
        "Create",
        setCreateDisabled
      );
    }
    if (!localStorage.getItem("isSubscribed")) {
      setCreateDisabled(true);
    }
  }, [UserPermissions]);

  return (

    <Grid
      container
      justifyContent="center"
      padding={"1% 0 0 0%"}
      rowSpacing={2}
      paddingBottom={5}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
          position: 'absolute',
          top: 0, // Fix the backdrop to the top of the screen
          left: 0,
          width: '100%',
          height: '100vh', // Cover the entire viewport height
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={loading}
      >
        <CircularProgress />
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading ...
        </Grid>
      </Backdrop>

      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          padding={1}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Grid
            item
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            {localStorage.getItem("IsFreePlanActivated") === "true" ||
              !localStorage.getItem("isSubscribed")
              ? "Subscribe to Engineering Plan to create activities."
              : planType === "Pro"
                ? "Upgrade subscription required (Pro+)"
                : planType === "Core"
                  ? "Upgrade subscription required (Pro or Pro+)"
                  : localStorage.getItem("isGuest") === "true"
                    ? "Please subscribe to create Activity"
                    : UserPermissions.find(
                      (permission) =>
                        permission.Name === "Engineer" ||
                        permission.Name === "Document Controller"
                    )
                      ? "You dont have the permission"
                      : ""}
          </Grid>

          <Grid item md={2} container>
            {localStorage.getItem("isTeamMember") === "true" ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/saas/profileSettings");
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12} container rowSpacing={3} padding={4} marginTop={{ xs: 2, sm: 0 }}>
        <Grid item xs={12} container alignItems="center" paddingLeft={{ xs: 1, sm: 7 }} >
          <Grid
            item
            xs={3}
            md={1}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Activities
          </Grid>
          <Grid
            item
            xs={3.5}
            sm={8.9}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item container direction="column" xs={5.5} md={2} textAlign="right"
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold"
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal"
              }}

              onClick={() => {
                navigate("/saas/execution/activitiesTable");
              }}
            >
              View all Activities
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold"
              }
              }
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal"
              }}
              onClick={() => {
                navigate("/saas/execution/activitiesDashboard");
              }}
            >
              View Dashboard
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold"
              }
              }
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal"
              }}
              onClick={() => {
                navigate("/saas/execution/activitiesTimeline");
              }}
            >
              View Timeline
            </Grid>
          </Grid>

        </Grid>

        <Grid item container direction="row" columnSpacing={2} alignItems="center" rowGap={1}>
          <Grid item xs={12} sm={3}>
            <Button
              disabled={
                localStorage.getItem("IsSubscribedBefore") === "true"
                  ? true
                  : createDisabled &&
                    localStorage.getItem("isGuest") === "false"
                    ? true
                    : false
              }
              onClick={() => {
                // let ActivytyPermissions = UserPermissions.map((role) =>
                //   role.Permissions.find(
                //     (permission) => permission.Name === "Drawing Activity"
                //   )
                // );
                if (
                  (total === 1000 && planType === "Core") ||
                  (total === 5000 && planType === "Pro") ||
                  UserPermissions.find(
                    (permission) =>
                      permission.Name === "Engineer" ||
                      permission.Name === "Document Controller"
                  )
                ) {
                  setDisabled(true);
                } else {
                  navigate("/saas/execution/create");
                }
              }}
              style={{
                borderRadius: 5,
                backgroundColor:
                  localStorage.getItem("IsSubscribedBefore") === "true"
                    ? "#707070"
                    : createDisabled &&
                      localStorage.getItem("isGuest") === "false"
                      ? "#707070"
                      : "#101650",
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
                textTransform: "none",
                padding: "10px",
              }}
            >
              + Create an Activity
            </Button>
          </Grid>
          <Grid item container xs={12} sm={9} direction={{ xs: "flex-start", md: "row-reverse" }} columnGap={0.5} rowGap={1}>

            <Grid item xs={5.5} sm={2}>
              <TextField
                select
                fullWidth
                id="outlined-type"
                label="Type"
                value={selectedTypeId}
                InputProps={{
                  style: {
                    fontFamily: "Muli",
                    height: "45px"
                  },
                }}
                onChange={(e) => {
                  setSelectedTypeId(e.target.value)
                }}
              >
                {types?.map((s, index) => (
                  <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5.5} sm={2}>
              <TextField
                select
                fullWidth
                id="outlined-assignee"
                label="Assignee"
                value={selectedAssigneesIds}
                InputProps={{
                  style: {
                    // fontSize: 12,
                    fontFamily: "Muli",
                    height: "45px",
                  },
                }}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                SelectProps={{
                  multiple: true,
                  MenuProps: {
                    disableScrollLock: true, sx: { height: "150px" },
                  },
                }}
                onChange={(e, addedValue) => {
                  setSelectedAssigneesIds(prevState => {
                    const currentIndex = prevState.findIndex(i => i === addedValue.props.value);
                    if (currentIndex !== -1) {
                      return prevState.filter((_, index) => index !== currentIndex);
                    } else {
                      return [...prevState, addedValue.props.value];
                    }
                  });
                  forceUpdate();
                }}
              >
                {assignees?.map((s, index) => (
                  <MenuItem key={index} value={s.Id}>{s.Name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item  xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="From"
                  endText="To"
                  value={dateRange}
                  onChange={(newValue) => {
                    setDateRange(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} InputProps={{
                        style: {
                          fontFamily: "Muli",
                          height: "45px",
                        },
                      }} />
                      <Box sx={{ mx: 1, fontFamily:"Muli" }}> to </Box>
                      <TextField {...endProps} InputProps={{
                        style: {
                          fontFamily: "Muli",
                          height: "45px",
                        },
                      }} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {dateRange[0] !== null || dateRange[1] !== null || selectedAssigneesIds.length > 0 || selectedTypeId ?
              <Grid item xs={1} justifySelf="center" height="60px"
                style={{ fontFamily: "Muli", fontSize: "12px" }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.fontWeight = "bold"
                }}
                onMouseOut={(e) => {
                  e.target.style.cursor = "unset";
                  e.target.style.fontWeight = "normal"
                }}
                onClick={() => {
                  setDateRange([null, null]);
                  setSelectedAssigneesIds([]);
                  setSelectedTypeId(null)
                }}
              >
                <span> <br />Clear Filters </span></Grid>
              :
              <></>}
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={4}>
          <Grid item container direction="row" maxWidth="100%">
            {activitiesPerStatuses?.length > 0 ? (
              <Grid
                container
                style={{ overflowX: "auto" }}
                bgcolor="#F5F5F5"
              >
                <Grid item container direction="row" bgcolor="#F5F5F5" paddingBottom={5} wrap="nowrap" flexWrap="nowrap">
                  {activitiesPerStatuses.map((s) => (

                    <Grid item container minWidth={{ xs: "80%", sm: "15%" }} key={s.Id} padding={1} rowGap={0.5}>
                      <Grid item xs={12} style={{ fontFamily: "Muli", fontSize: "12px", color: "#101650", fontWeight: "bold" }}>{s.Name}</Grid>
                      <Grid item container xs={12} direction="column" bgcolor=" #E8E8E8" minHeight="100%" borderRadius="10px">
                        {s.Activities.map((d) =>
                          d !== null ? (
                            <Grid item key={d.Id} margin={2}>
                              <ActivityCard data={d} modify={modify} setModify={setModify} />
                            </Grid>
                          ) : (
                            ""
                          )
                        )}
                      </Grid>
                    </Grid>

                  ))}
                </Grid>
              </Grid>

            ) : (
              <>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </Grid>

  );
}
