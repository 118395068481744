import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as contractServices from "../../../Services/projects-services.proxy";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import DeletePhaseContract from "./DeletePhaseContract";
import classes from "../../Admin/UserRoles/modal.module.css";

const uploadContractFile = contractServices.uploadContractFile;
const deleteContractFile = contractServices.deleteContractFile;
const styles = {
  label: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "left",
    color: "#101650",
  },
  error: {
    fontFamily: "Muli",
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "left",
    color: "#d32f2f",
    paddingTop: "1.5%",
  },
};

export default function ContractDetails(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDelete, setOpenDelete] = useState(false);

  const [cnError, setCnError] = useState(false);
  const [cnInvalid, setCnInvalid] = useState(false);
  const [ctError, setCtError] = useState(false);
  const [ctoError, setCtoError] = useState(false);
  const [cstError, setCstError] = useState(false);
  const [cendError, setCendError] = useState(false);
  const [ccInvalid, setCcInvalid] = useState(false);
  const [cabInvalid, setCabInvalid] = useState(false);
  const [cdeInvalid, setCdeInvalid] = useState(false);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [multiplePhases, setMultiplePhases] = useState(false);
  const [phaseName, setPhaseName] = useState("Phase Name");
  const [contractorName, setContractorName] = useState("Contractor Name");
  const [billingRRs, setBillingRRs] = useState([]);
  const [csError, setCsError] = useState(false);
  const [atError, setAtError] = useState(false);
  const [socError, setSocError] = useState(false);
  const [fnError, setFnError] = useState(false);
  const [bsError, setbsError] = useState(false);
  const [bgError, setbgError] = useState(false);
  const [bcError, setbcError] = useState(false);
  const [msError, setMsError] = useState(false);

  useEffect(() => {
    setPhaseName(props.phaseName);
    setContractorName(props.contractorName);
  }, [props.contractorName, props.phaseName]);

  useEffect(() => {
    for (let i = 1; i <= 100; i++) {
      let arr = billingRRs;
      arr.push(i + "%");
      setBillingRRs(arr);
    }
  });

  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteContractFile(previousFileId, props.projectId);
    }
    if (e.target.files[0]) {
      if (props.contractFileId) setPreviousFileId(props.contractFileId);
      uploadContractFile(e.target.files[0], props.projectId).then((x) => {
        props.setContractFile(x);
        props.setContractFileId(x.FileId);
      });
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      columnGap={5}
      rowGap={5}
      bgcolor="#fff"
      padding="5% 0 3% 0"
    >
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 21,
          lineHeight: 1.48,
          textAlign: "left",
          color: "#101650",
          backgroundColor: "#fff",
          borderBottom: "solid #2da4d0",
        }}
        marginLeft="14%"
        marginRight={{ xl: "73%", lg: "70%", md: "65%", xs: "60%" }}
      >
        Contract Details
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 15,
          fontWeight: "bold",
          lineHeight: 1.47,
          textAlign: "left",
          color: "rgba(16, 22, 80, 0.5)",
        }}
        marginLeft="15%"
        container
      >
        <Grid item xs={3.5}>
          {phaseName + " - " + contractorName}
        </Grid>
        <Grid item xs={1.5}>
          {props.contractName}
        </Grid>

        {props.contracts.length > 0 ? (
          <Grid item xs={1}>
            <Grid
              item
              xl={2}
              id="contract-drop"
              aria-controls={open ? "noti-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            >
              <KeyboardArrowDownIcon
                htmlColor="#0674b9"
                fontSize="medium"
                style={{ height: 18 }}
              />
            </Grid>
            <Menu
              id="contract-drop"
              aria-labelledby="contract-drop"
              anchorEl={anchorEl}
              open={open}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#fff",
                },
              }}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
              }}
              disableScrollLock
            >
              {props.contracts?.map((contract, index) => (
                <MenuItem
                  key={contract.Id}
                  onClick={(e) => {
                    props.setContractIndex(index);
                    handleClose();
                  }}
                  style={{
                    height: "60px",
                    fontWeight: "bold",
                    fontFamily: "Muli",
                    fontSize: 13,
                    borderRadius: 5,
                    color: "#0674b9",
                    backgroundColor: "#fff",
                  }}
                >
                  {contract?.Name}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        ) : (
          <Grid item xs={1}></Grid>
        )}
        <Grid item>
          <button
            disabled={
              (!localStorage.getItem("planId")?.includes("Pro") &&
                !localStorage.getItem("planId")?.includes("Core")) ||
              (!localStorage.getItem("planIdManu")?.includes("Pro") &&
                !localStorage.getItem("planIdManu")?.includes("Core") &&
                localStorage.getItem("IsFreePlanActivated") !== "true") ||
              props.contractId === null ||
              props.contracts.length === 2 ||
              (props.viewdisable && !props.join)
            }
            style={{
              fontFamily: "Muli",
              fontSize: 12,
              height: 23,
              width: 150,
              boxShadow:
                (!localStorage.getItem("planId")?.includes("Pro") &&
                  !localStorage.getItem("planId")?.includes("Core")) ||
                  (!localStorage.getItem("planIdManu")?.includes("Pro") &&
                    !localStorage.getItem("planIdManu")?.includes("Core") &&
                    localStorage.getItem("IsFreePlanActivated") !== "true") ||
                  props.contractId === null ||
                  props.contracts.length === 2 ||
                  (props.viewdisable && !props.join)
                  ? "0 3px 6px 0 lightgrey"
                  : "0 3px 6px 0 #0674b9",
              backgroundColor:
                (!localStorage.getItem("planId")?.includes("Pro") &&
                  !localStorage.getItem("planId")?.includes("Core")) ||
                  (!localStorage.getItem("planIdManu")?.includes("Pro") &&
                    !localStorage.getItem("planIdManu")?.includes("Core") &&
                    localStorage.getItem("IsFreePlanActivated") !== "true") ||
                  props.contractId === null ||
                  props.contracts.length === 2 ||
                  (props.viewdisable && !props.join)
                  ? "lightgrey"
                  : "#fff",
              borderRadius: 5,
              border: 0,
              color:
                (!localStorage.getItem("planId")?.includes("Pro") &&
                  !localStorage.getItem("planId")?.includes("Core")) ||
                  (!localStorage.getItem("planIdManu")?.includes("Pro") &&
                    !localStorage.getItem("planIdManu")?.includes("Core") &&
                    localStorage.getItem("IsFreePlanActivated") !== "true") ||
                  props.contractId === null ||
                  props.contracts.length === 2 ||
                  (props.viewdisable && !props.join)
                  ? "#fff"
                  : "#0674b9",
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.backgroundColor = "#0674b9";
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#fff";
              e.target.style.color = "#0674b9";
            }}
            onClick={() => {
              props.setContractId(null);
              props.addNewContract();
            }}
          >
            New Contract +
          </button>
          <div style={styles.label}>
            {localStorage.getItem("IsFreePlanActivated") !== "true" &&
              !localStorage.getItem("planIdManu")?.includes("Pro") &&
              !localStorage.getItem("planIdManu")?.includes("Core") &&
              !props.viewdisable ? (
              <div style={{ marginTop: "1.5%" }}>
                Subscribe to manufacturing plan to unlock this feature
              </div>
            ) : !localStorage.getItem("planId")?.includes("Pro") &&
              !localStorage.getItem("planId")?.includes("Core") &&
              !props.viewdisable ? (
              <div style={{ marginTop: "1.5%" }}>
                Subscribe to engineering plan to unlock this feature
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          *Contract Name
        </Grid>
        <Grid item xs={12} paddingTop="1.6%">
          <TextField
            disabled={props.viewdisable && !props.join}
            fullWidth
            variant="standard"
            value={props.contractName}
            error={cnError}
            helperText={
              cnError
                ? cnInvalid
                  ? "Contract Name must be less than 150 characters"
                  : "Enter a contract name"
                : ""
            }
            onBlur={(e) => {
              if (!e.target.value) setCnError(true);
            }}
            onChange={(e) => {
              props.setContractName(e.target.value);
              if (e.target.value.length > 150) {
                setCnError(true);
                setCnInvalid(true);
              } else {
                setCnError(false);
                setCnInvalid(false);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label} marginTop="1.5%">
          *Contract Type
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={(props.viewdisable && !props.join) || props.disableType}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={atError}
              value={props.contractType}
              onBlur={(e) => {
                if (!e.target.value) setAtError(true);
              }}
              onChange={(e) => {
                props.setContractType(e.target.value);
                setAtError(false);
              }}
            >
              {props.accountTypes?.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                  disabled={
                    option.Id === 1 &&
                    !localStorage.getItem("planId")?.includes("Pro") &&
                    !localStorage.getItem("planId")?.includes("Core")
                  }
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {atError ? "Enter a contract type" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract Abbreviation
        </Grid>
        <Grid item xs={12} paddingTop="1.6%">
          <TextField
            disabled={props.viewdisable && !props.join}
            fullWidth
            variant="standard"
            value={props.contractAbbreviation}
            error={cabInvalid}
            helperText={
              cabInvalid ? "Abbreviation must be less than 30 characters" : ""
            }
            onChange={(e) => {
              if (e.target.value.length > 30) setCabInvalid(true);
              else {
                setCabInvalid(false);
                props.setContractAbbreviation(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      {props.contractType === 1 ? (
        <Grid item xs={4} container>
          <Grid item xs={12} sx={styles.label} marginTop="1.5%">
            *Contract Sub-Type
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="standard"
              fullWidth
              disabled={props.viewdisable && !props.join}
            >
              <Select
                MenuProps={{ disableScrollLock: true }}
                error={ctError}
                value={props.contractSubType}
                onBlur={(e) => {
                  if (!e.target.value) setCtError(true);
                }}
                onChange={(e) => {
                  props.setContractSubType(e.target.value);
                  setCtError(false);
                  if (
                    e.target.value !==
                    props.contractTypes?.find((x) => x.Name === "Others")?.Id
                  )
                    props.setContractTypeOther("");
                }}
              >
                {props.contractTypes?.map((option) => (
                  <MenuItem
                    value={option.Id}
                    key={option.Id}
                    style={{ display: "block" }}
                  >
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
              <div style={styles.error}>
                {ctError ? "Enter a contract sub-type" : ""}
              </div>
            </FormControl>
            {props.contractSubType ===
              props.contractTypes?.find((x) => x.Name === "Others")?.Id ? (
              <>
                <Grid item sx={styles.label} paddingTop="2%">
                  *Other Type
                </Grid>
                <Grid item>
                  <TextField
                    disabled={props.viewdisable && !props.join}
                    variant="standard"
                    fullWidth
                    value={props.contractTypeOther}
                    size="small"
                    error={ctoError}
                    helperText={ctoError ? "Enter a contract sub-type" : ""}
                    onBlur={(e) => {
                      if (!e.target.value) setCtoError(true);
                    }}
                    onChange={(e) => {
                      props.setContractTypeOther(e.target.value);
                      setCtoError(false);
                    }}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Factory No.
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={fnError}
                  value={props.factoryNb}
                  onBlur={(e) => {
                    if (!e.target.value) setFnError(true);
                  }}
                  onChange={(e) => {
                    props.setFactoryNb(e.target.value);
                    props.setBarStandards(
                      props.factoryNumbers
                        .find((f) => f.Id === e.target.value)
                        ?.References.find((r) => r.ReferenceId === 1)?.Values
                    );
                    props.setBarGrades(
                      props.factoryNumbers
                        .find((f) => f.Id === e.target.value)
                        ?.References.find((r) => r.ReferenceId === 2)?.Values
                    );
                    props.setBarCoatings(
                      props.factoryNumbers
                        .find((f) => f.Id === e.target.value)
                        ?.References.find((r) => r.ReferenceId === 3)?.Values
                    );
                    setFnError(false);
                  }}
                >
                  {props.factoryNumbers?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {fnError ? "Enter a factory number" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Bar Standard
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={bsError}
                  value={props.barStandard}
                  onBlur={(e) => {
                    if (!e.target.value) setbsError(true);
                  }}
                  onChange={(e) => {
                    props.setBarStandard(e.target.value);
                    setbsError(false);
                  }}
                >
                  {props.barStandards?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {bsError ? "Enter a Bar Standard" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Bar Grade
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={bgError}
                  value={props.barGrade}
                  onBlur={(e) => {
                    if (!e.target.value) setbgError(true);
                  }}
                  onChange={(e) => {
                    props.setBarGrade(e.target.value);
                    setbgError(false);
                  }}
                >
                  {props.barGrades?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {bgError ? "Enter a Bar Grade" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Bar Coating
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={bcError}
                  value={props.barCoating}
                  onBlur={(e) => {
                    if (!e.target.value) setbcError(true);
                  }}
                  onChange={(e) => {
                    props.setBarCoating(e.target.value);
                    setbcError(false);
                  }}
                >
                  {props.barCoatings?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {bcError ? "Enter a Bar Coating" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={8.5} container rowGap={1}>
            <Grid item xs={12} sx={styles.label}>
              *Services
            </Grid>
            <Grid item container direction='row' columnGap={1}>
              <Grid item container xs={12} md={3} columnGap={1} alignItems='center'>
                <Grid
                  item
                  className={classes[props.isCuttingService ? "CheckedBox" : "notCheckedBox"]}
                  onClick={() => {
                    props.setIsCuttingService(!props.isCuttingService)
                  }}
                ></Grid>
                <Grid
                  item
                  className={classes["Action-permission"]}
                  marginTop={0.1}
                >
                  Cut to Length
                </Grid>
              </Grid>
              <Grid item container xs={12} md={3} columnGap={1} alignItems='center'>
                <Grid
                  item
                  className={classes[props.isCutBendService ? "CheckedBox" : "notCheckedBox"]}
                  onClick={() => {
                    props.setIsCutBendService(!props.isCutBendService)
                  }}
                ></Grid>
                <Grid
                  item
                  className={classes["Action-permission"]}
                  marginTop={0.1}
                >
                  Cut & Bend
                </Grid>
              </Grid>
              <Grid item container xs={12} md={3} columnGap={1} alignItems='center'>
                <Grid
                  item
                  className={classes[props.isThreadingService ? "CheckedBox" : "notCheckedBox"]}
                  onClick={() => {
                    props.setIsThreadingService(!props.isThreadingService)
                  }}
                ></Grid>
                <Grid
                  item
                  className={classes["Action-permission"]}
                  marginTop={0.1}
                >
                  Threading
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </>
      )}
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract Code
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable && !props.join}
            fullWidth
            variant="standard"
            value={props.contractCode}
            error={ccInvalid}
            helperText={ccInvalid ? "Code must be less than 30 characters" : ""}
            onChange={(e) => {
              if (e.target.value.length > 30) setCcInvalid(true);
              else {
                setCcInvalid(false);
                props.setContractCode(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract Description
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable && !props.join}
            fullWidth
            variant="standard"
            multiline
            value={props.contractDescription}
            error={cdeInvalid}
            helperText={
              cdeInvalid ? "Description must be less than 255 characters" : ""
            }
            onChange={(e) => {
              if (e.target.value.length > 255) setCdeInvalid(true);
              else {
                setCdeInvalid(false);
                props.setContractDescription(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} container></Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Contract File
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{ borderBottom: "solid 2px #b8b8b8" }}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Button
              disabled={props.viewdisable && !props.join}
              component="label"
              style={{
                textTransform: "none",
                fontFamily: "Muli",
                fontSize: "12px",
                lineHeight: 1.45,
                textAlign: "left",
                color: "#101650",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "32px",
              }}
            >
              <FileUploadOutlinedIcon
                htmlColor="#529bd7"
                style={{
                  alignSelf: "center",
                  paddingRight: "5%",
                }}
              />
              <input type="file" hidden onChange={handleFileChange} required />
            </Button>
          </Grid>
          <Grid item xs={10}>
            <span
              style={{ alignSelf: "center" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color = "grey";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "black";
              }}
              onClick={(e) => {
                if (props.contractFile) {
                  window.open(props.contractFile?.URL);
                } else {
                  e.target.style.color = "grey";
                }
              }}
            >
              {props.contractFile
                ? props.contractFile.DisplayName
                : "Upload File"}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          Billing Type
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable && !props.join}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={props.billingType}
              onChange={(e) => {
                props.setBillingType(e.target.value);
                if (
                  e.target.value !==
                  props.contractBillingTypes?.find((x) => x.Name === "Others")
                    ?.Id
                )
                  props.setBillingTypeOther("");
              }}
            >
              {props.contractBillingTypes.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {props.billingType ===
            props.contractBillingTypes?.find((x) => x.Name === "Others")?.Id ? (
            <>
              <Grid item sx={styles.label} paddingTop="2%">
                *Other Billing Type
              </Grid>
              <Grid item>
                <TextField
                  disabled={props.viewdisable && !props.join}
                  variant="standard"
                  fullWidth
                  value={props.billingTypeOther}
                  size="small"
                  onChange={(e) => {
                    props.setBillingTypeOther(e.target.value);
                  }}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {props.contractType === 1 ? (
        <Grid item xs={4} container>
          <Grid item xs={12} sx={styles.label}>
            Billing Retention Ratio
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="standard"
              fullWidth
              disabled={props.viewdisable && !props.join}
            >
              <Select
                MenuProps={{ disableScrollLock: true }}
                value={props.billingRR}
                onChange={(e) => {
                  props.setBillingRR(e.target.value.toString());
                }}
              >
                {billingRRs.map((b, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {b}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={4} container></Grid>
      )}
      {props.contractType === 1 ? (
        <>
          <Grid item xs={4} container></Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label}>
              Default Sheet Size
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  value={props.sheetSize}
                  onChange={(e) => {
                    props.setSheetSize(e.target.value);
                    if (
                      e.target.value !==
                      props.defaultSheetSizes?.find((x) => x.Name === "Others")
                        ?.Id
                    )
                      props.setSheetSizeOther("");
                  }}
                >
                  {props.defaultSheetSizes.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {props.sheetSize ===
                props.defaultSheetSizes?.find((x) => x.Name === "Others")?.Id ? (
                <>
                  <Grid item sx={styles.label}>
                    *Other Sheet Size
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={props.viewdisable && !props.join}
                      variant="standard"
                      fullWidth
                      value={props.sheetSizeOther}
                      size="small"
                      onChange={(e) => {
                        props.setSheetSizeOther(e.target.value);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Measurement System
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={msError}
                  value={props.measurement}
                  onBlur={(e) => {
                    if (!e.target.value) setMsError(true);
                  }}
                  onChange={(e) => {
                    props.setMeasurement(e.target.value);
                    if (e.target.value === 1) props.setUnit("mm -- kg");
                    else props.setUnit("inches -- pounds");
                    setMsError(false);
                  }}
                >
                  {props.measurements?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                      disabled={option.Id !== 1}
                    >
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {msError ? "Enter a measurement system" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Unit of Measures
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                variant="standard"
                value={props.unit}
              />
            </Grid>
          </Grid>
        </>
      )}
      {props.contractType === 2 ? (
        <>
          <Grid item xs={4} container>
            <Grid item xs={12} sx={styles.label} marginTop="1.5%">
              *Sales Order Creator
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                disabled={props.viewdisable && !props.join}
              >
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  error={socError}
                  value={props.salesCreator}
                  onBlur={(e) => {
                    if (!e.target.value) setSocError(true);
                  }}
                  onChange={(e) => {
                    props.setSalesCreator(e.target.value);
                    setSocError(false);
                  }}
                >
                  {props.salesCreators?.map((option) => (
                    <MenuItem
                      value={option.Id}
                      key={option.Id}
                      style={{ display: "block" }}
                    >
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
                <div style={styles.error}>
                  {socError ? "Enter a sales order creator" : ""}
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={5.5}>
              <Grid item xs={12} sx={styles.label}>
                Subproject Disciplines
              </Grid>
              <Grid item xs={12} container gap={0.5} paddingTop="2%">
                <Grid
                  item
                  className={classes["FullChecked"]}
                  marginTop={0.4}
                ></Grid>
                <Grid
                  item
                  className={classes["Action-permission"]}
                  marginTop={0.1}
                >
                  Structural
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5.5}>
              <Grid item xs={12} sx={styles.label}>
                Subproject Trades
              </Grid>
              <Grid item xs={12} container gap={0.5} paddingTop="2%">
                <Grid
                  item
                  className={classes["FullChecked"]}
                  marginTop={0.4}
                ></Grid>
                <Grid
                  item
                  className={classes["Action-permission"]}
                  marginTop={0.1}
                >
                  Rebar
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </>
      ) : (
        <></>
      )}
      <Grid item xs={4} container>
        <Grid item xs={12} sx={styles.label}>
          *Status
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable && !props.join}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={csError}
              value={props.contractStatus}
              onBlur={(e) => {
                if (!e.target.value) setCsError(true);
              }}
              onChange={(e) => {
                props.setContractStatus(e.target.value);
                setCsError(false);
              }}
            >
              {props.contractStatuses?.map((option) => (
                <MenuItem
                  value={option.Id}
                  key={option.Id}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {csError ? "Enter a contract status" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={1.8} container>
        <Grid item xs={12} sx={styles.label}>
          *Start Date
        </Grid>
        <Grid item xs={12} paddingTop="5%">
          <TextField
            fullWidth
            disabled={props.viewdisable && !props.join}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{ className: classes.fieldDesign }}
            size="small"
            label={""}
            type={"date"}
            value={props.contractStartDate}
            variant="outlined"
            // error={phsError}
            // helperText={plannedStartDateErrorMessage}
            // onBlur={(e) => {
            //   if (!e.target.value) {
            //     setPhsError(true);
            //   }
            // }}
            onChange={(e) => {
              props.setContractStartDate(e.target.value);
              // if (e.target.value.length > 10) setPhsError(true);
              // else setPhsError(false);

            }}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={props.viewdisable && !props.join}
              openTo="day"
              disablePast
              views={["year", "month", "day"]}
              label="dd/mm/yyyy"
              disableMaskedInput
              inputFormat="dd/MM/yyyy"
              value={props.contractStartDate}
              onChange={(newValue) => {
                if (newValue === "") {
                  setCstError(true);
                } else {
                  setCstError(false);
                }
                var date = new Date(newValue);
                props.setContractStartDate(newValue.toLocaleDateString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "15px",
                    },
                  }}
                  error={cstError}
                  onBlur={() => {
                    if (!props.contractStartDate) setCstError(true);
                  }}
                  helperText={cstError ? "Enter a date" : <></>}
                  size="small"
                />
              )}
            />
          </LocalizationProvider> */}
        </Grid>
      </Grid>
      <Grid item xs={1.8} container>
        <Grid item xs={12} sx={styles.label}>
          *End Date
        </Grid>
        <Grid item xs={12} paddingTop="5%">
          <TextField
            fullWidth
            disabled={props.viewdisable && !props.join}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{ className: classes.fieldDesign }}
            size="small"
            label={""}
            type={"date"}
            value={props.contractEndDate}
            variant="outlined"
            // error={phsError}
            // helperText={plannedStartDateErrorMessage}
            // onBlur={(e) => {
            //   if (!e.target.value) {
            //     setPhsError(true);
            //   }
            // }}
            onChange={(e) => {
              props.setContractEndDate(e.target.value);
              // if (e.target.value.length > 10) setPhsError(true);
              // else setPhsError(false);

            }}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={props.viewdisable && !props.join}
              openTo="day"
              disablePast
              views={["year", "month", "day"]}
              label="dd/mm/yyyy"
              minDate={new Date(props.contractStartDate)}
              disableMaskedInput
              inputFormat="dd/MM/yyyy"
              value={props.contractEndDate}
              onChange={(newValue) => {
                if (newValue === "") {
                  setCendError(true);
                } else {
                  setCendError(false);
                }
                var date = new Date(newValue);
                props.setContractEndDate(newValue.toLocaleDateString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "15px",
                    },
                  }}
                  error={cendError}
                  onBlur={() => {
                    if (!props.setContractEndDate) setCendError(true);
                  }}
                  helperText={cendError ? "Enter a date" : <></>}
                  size="small"
                />
              )}
            />
          </LocalizationProvider> */}
        </Grid>
      </Grid>
      <Grid item xs={8} container>
        <Button
          disabled={
            props.contracts.length === 1 ||
            (localStorage.getItem("IsFreePlanActivated") !== "true" &&
              !localStorage.getItem("planIdManu")?.includes("Pro") &&
              !localStorage.getItem("planIdManu")?.includes("Core")) ||
            props.contractId === null ||
            (props.viewdisable && !props.join)
          }
          style={{
            borderRadius: 5,
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor:
              props.contracts.length === 1 ||
                (localStorage.getItem("IsFreePlanActivated") !== "true" &&
                  !localStorage.getItem("planIdManu")?.includes("Pro") &&
                  !localStorage.getItem("planIdManu")?.includes("Core")) ||
                props.contractId === null ||
                (props.viewdisable && !props.join)
                ? "lightgrey"
                : "#fff",
            fontFamily: "Muli",
            fontSize: 13,
            fontWeight: "bold",
            lineHeight: 1.46,
            textAlign: "left",
            color:
              props.contracts.length === 1 ||
                (localStorage.getItem("IsFreePlanActivated") !== "true" &&
                  !localStorage.getItem("planIdManu")?.includes("Pro") &&
                  !localStorage.getItem("planIdManu")?.includes("Core")) ||
                props.contractId === null ||
                (props.viewdisable && !props.join)
                ? "#fff"
                : "#0b5cab",
            textTransform: "none",
            opacity: 1,
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0b5cab";
            e.target.style.color = "#fff";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#fff";
            e.target.style.color = "#0b5cab";
          }}
          onClick={() => setOpenDelete(true)}
        >
          Delete Contract
        </Button>
      </Grid>
      <DeletePhaseContract
        open={openDelete}
        setOpen={setOpenDelete}
        contractId={props.contractId}
      />
    </Grid>
  );
}
