import React, { useEffect, useState } from "react";
import SaasBack from "../../Assets/Images/SaaSbackground.png";
import { Grid, TextField, MenuItem } from "@mui/material";
import classes from "../../Assets/Styles/factory.module.css";
import DocksIcoon from "../../Assets/Images/Factory/DocksIcon.png";
import PlusIcoon from "../../Assets/Images/Factory/plusRow.png";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import * as masterParametersServices from "../../Services/factories-services.proxy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Menu from "@mui/material/Menu";
import RemoveIcon from "../../Assets/Images/Projects/delete.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorModal from "../../SharedComponents/ErrorModal";
import AlertModal from "./AlertModal";

import {
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";
var moment = require("moment");
const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const createParameters = masterParametersServices.createParameters;
const updateParameters = masterParametersServices.updateParameters;
const getParametersInputData = masterParametersServices.getParametersInputData;

function MasterParameters(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledFG, setDisabledFG] = useState(false);
  const [disabledCutting, setDisabledCutting] = useState(false);
  const [disabledDN, setDisabledDN] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Factory Master Parameters",
        "FG parameters",
        setDisabledFG
      );
      CheckIfPermitted(
        UserPermissions,
        "Factory Master Parameters",
        "Cutting parameters",
        setDisabledCutting
      );
      CheckIfPermitted(
        UserPermissions,
        "Factory Master Parameters",
        "Loading and delivery  parameters",
        setDisabledDN
      );
    }
  }, [UserPermissions]);
  const [typeOfInputsList, setTypeOfInputsList] = useState([]);

  const [splitMaxWeight, setSplitMaxWeight] = useState(false);
  const [splitMaxNoPcs, setSplitMaxNoPcs] = useState(false);

  const [distMaxQ, setDistMaxQ] = useState(false);
  const [distEqualQ, setDistEqualQ] = useState(false);

  const [diaList, setDiaList] = useState([]);

  const [docks, setDocks] = useState([]);
  const [bays, setbays] = useState([]);

  const [dockToBeAdded, setDockToBeAdded] = useState({
    Id: null,
    BayId: 0,
    Code: null,
    Name: null,
    Length: null,
    Width: null,
  });
  const [dockChosenId, setDockChosenId] = useState();
  const [parametersId, setParametersId] = useState(null);

  const [bendMinNb, setBendMinNb] = useState();
  const [bendMaxNb, setBendMaxNb] = useState();

  const [bendMinNbError, setBendMinNbError] = useState();
  const [bendMaxNbError, setBendMaxNbError] = useState();

  const [noBendMinNb, setNoBendMinNb] = useState();
  const [noBendMaxNb, setNoBendMaxNb] = useState();

  const [noBendMinNbError, setNoBendMinNbError] = useState();
  const [noBendMaxNbError, setNoBendMaxNbError] = useState();

  const [maxSOWeight, setMaxSOWeight] = useState();
  const [maxSOWeightError, setMaxSOWeightError] = useState(false);

  const [maxLoadWeight, setMaxLoadWeight] = useState();
  const [maxLoadWeightError, setMaxLoadWeightError] = useState(false);

  const [emptyFieldInTS, setEmptyFieldInTS] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [modify, setModify] = useState(false);

  const [successMsgSaved, setSuccessMsgSaved] = useState(false);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [tooltipEditIsOpen, setTooltipEditIsOpen] = useState(false);

  const navigate = useNavigate();

  const [inputsArray, setInputsArray] = useState([]);
  const [inputObject, setInputObject] = useState();
  const [inputObjectChosen, setInputObjectChosen] = useState(null);

  const [timeSlots, setTimeSlots] = useState([]);
  const [days, setDays] = useState([]);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const [splitingParamsChanged, setSplitingParamsChanged] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const MasterParametersData = {
    Id: parametersId,
    FactoryId: ID,
    MaxBarToleranceWithBend: bendMaxNb,
    MinBarToleranceWithBend: bendMinNb,
    MaxBarToleranceWithNoBend: noBendMaxNb,
    MinBarToleranceWithNoBend: noBendMinNb,
    SplitByMaxPcs: splitMaxNoPcs,
    SplitByMaxWeight: splitMaxWeight,
    DistributeByEqualQuantity: distEqualQ,
    DistributeByMaxQuantity: distMaxQ,
    Inputs: inputsArray,
    Docks: docks,
    MaxLoadWeight: maxLoadWeight,
    MaxSalesOrderWeight: maxSOWeight,
    TimeSlots: timeSlots,
  };
  useEffect(() => {
    setLoading(true);
    getParametersInputData(ID).then((x) => {
      setTypeOfInputsList(x.Types);
      setDiaList(x.Diameters);
      setbays(x.Bays);
      setDays(x.Days);
      if (x.MasterParameters !== null) {
        setParametersId(x.MasterParameters.Id);
        setBendMaxNb(x.MasterParameters.MaxBarToleranceWithBend);
        setBendMinNb(x.MasterParameters.MinBarToleranceWithBend);
        setNoBendMaxNb(x.MasterParameters.MaxBarToleranceWithNoBend);
        setNoBendMinNb(x.MasterParameters.MinBarToleranceWithNoBend);
        setSplitMaxNoPcs(x.MasterParameters.SplitByMaxPcs);
        setSplitMaxWeight(x.MasterParameters.SplitByMaxWeight);
        setDistEqualQ(x.MasterParameters.DistributeByEqualQuantity);
        setDistMaxQ(x.MasterParameters.DistributeByMaxQuantity);
        setInputsArray(x.MasterParameters.Inputs);
        setInputObject(x.MasterParameters.Inputs[0]);
        setInputObjectChosen(x.MasterParameters.Inputs[0].TypeId);
        setDocks(x.MasterParameters.Docks);
        setTimeSlots(x.MasterParameters.TimeSlots);
        setMaxLoadWeight(x.MasterParameters.MaxLoadWeight);
        setMaxSOWeight(x.MasterParameters.MaxSalesOrderWeight);
      } else {
        let TempInputsArray = [];

        x?.Types?.map((input) => {
          TempInputsArray = TempInputsArray.concat({
            TypeId: input.Id,
            Name: input.Name,
            Diameters: [],
          });
        });
        TempInputsArray?.map((input) => {
          x.Diameters?.map((diaValue) => {
            input.Diameters = input.Diameters.concat({
              DiameterId: diaValue.Id,
              Name: diaValue.Name,
              MaxQuantity: 0,
              MaxWeight: 0,
            });
          });
        });
        setInputsArray(TempInputsArray);
        setInputObject(TempInputsArray[0]);
        setInputObjectChosen(TempInputsArray[0].TypeId);
      }
      setLoading(false);
    });
  }, [modify]);

  const save = () => {
    let empty = false;
    if (localStorage.getItem("planIdManu")?.includes("Pro")) {
      if (timeSlots?.length > 0) {
        timeSlots.map((ts) => {
          if (!ts.Code || !ts.StartTime || !ts.EndTime || !ts.Days.length > 0) {
            empty = true;
            setEmptyFieldInTS(true);
            setErrorMessage(
              "There are emprty fields in time slots, please check"
            );
          }
          if (!empty)
            timeSlots.map((ts2) => {
              ts.Days.map((day) => {
                if (ts2.Days.includes(day)) {
                  let startTime1 = moment(ts.StartTime, "HH:mm:ss");
                  let endTime1 = moment(ts.EndTime, "HH:mm:ss");
                  let startTime2 = moment(ts2.StartTime, "HH:mm:ss");
                  let endTime2 = moment(ts2.EndTime, "HH:mm:ss");

                  if (
                    (startTime1.isAfter(startTime2) &&
                      startTime1.isBefore(endTime2)) ||
                    (endTime1.isAfter(startTime2) &&
                      endTime1.isBefore(endTime2))
                  ) {
                    empty = true;
                    setEmptyFieldInTS(true);
                    setErrorMessage(
                      "There are time slots that overlaps, please check"
                    );
                  }
                }
              });
            });
        });
      }
    }

    if (!empty) {
      setLoading(true);
      setEmptyFieldInTS(false);

      if (parametersId) {
        setUpdateLoading(true);
        updateParameters(MasterParametersData).then((x) => {
          setLoading(false);
          setUpdateLoading(false);
          if (x.status) {
            setBEerror(x.error);
            setOpenErrorModal(true);
          }
          else {
            setModify(!modify);
            setSuccessMsgSaved(true);
            setTimeout(() => {
              setSuccessMsgSaved(false);
              setOpenAlertModal(false);
            }, 1500);
          }

        });
      } else {
        createParameters(MasterParametersData).then(() => {
          setLoading(false);
          setModify(!modify);
          setSuccessMsgSaved(true);
          setTimeout(() => {
            setSuccessMsgSaved(false);
          }, 3000);
        });
      }
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid item container paddingTop={3} rowGap={2}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={3.7}
            fontSize={{ xs: "13px", sm: "18px" }}
            fontWeight="bold"
            className={classes["Title"]}
            paddingTop={{ xs: 1.5, sm: 3 }}
          >
            <Grid item>Master Parameters</Grid>
            <Grid item fontWeight="normal">
              {localStorage.getItem("FactoryName")}
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            sm={5}
            lg={7}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={3.7}
            sm={2.5}
            lg={1}
            className={classes["ViewText"]}
            onClick={() => {
              navigate("/saas/factoriesList");
            }}
          >
            View All Factories
          </Grid>
          <Grid item>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("FactoryName");
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={11}
          md={10}
          backgroundColor="#fff"
          marginLeft={{ xs: 2, lg: 15 }}
          borderRadius="5px"
          padding={2.5}
          columnGap={2}
          marginBottom={3}
        >
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
            }}
            open={loading}
          >
            <CircularProgress />

            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"rgb(25, 118, 210)"}
            >
              Loading Data...
            </Grid>
          </Backdrop>
          <Grid item xs={12} className={classes["OptimizationParameters"]}>
            FG Parameters
          </Grid>
          <Grid item xs={12} className={classes["ParameterType"]}>
            Raw Material
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={12}
            gap={1}
            className={classes["greyText"]}
            paddingTop={2}
          >
            <Grid item>Type of Input</Grid>
            <Grid item width="150px" xs={2}>
              <TextField
                disabled
                variant="standard"
                value={typeOfInputsList[0]?.Name}
                fullWidth
                InputLabelProps={{
                  style: styles.tfInput,
                }}
                InputProps={{
                  disableUnderline: true,
                  style: styles.tfInput,
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Grid item overflow={"auto"}>
            <TableContainer sx={{ width: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 0px",
                  border: 0,
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>Dia (mm)</TableCell>
                    {inputObject?.Diameters?.map((Dia, index) => (
                      <TableCell sx={styles.tableCellhead2} key={Dia.Id}>
                        <Grid item width={75}>
                          {diaList?.find((d) => d.Id === Dia.DiameterId).Name}
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>
                      max weight (kg)
                    </TableCell>
                    {inputObject?.Diameters?.map((Dia, index) => (
                      <TableCell sx={styles.tableCellhead2} key={Dia.Id}>
                        <TextField
                          disabled={disabledFG}
                          variant="standard"
                          value={Dia.MaxWeight}
                          fullWidth
                          label={"Weight"}
                          type={"number"}
                          InputLabelProps={{
                            style: styles.tfInput,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: styles.tfInput,
                            inputProps: { min: 0 },
                          }}
                          onChange={(e) => {
                            Dia.MaxWeight = +e.target.value;
                            forceUpdate();
                            setSplitingParamsChanged(true);
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>
                      max quantity (pcs)
                    </TableCell>
                    {inputObject?.Diameters?.map((Dia, index) => (
                      <TableCell sx={styles.tableCellhead2} key={Dia.Id}>
                        <TextField
                          disabled={disabledFG}
                          variant="standard"
                          value={Dia.MaxQuantity}
                          fullWidth
                          label={"Quantity"}
                          type={"number"}
                          InputLabelProps={{
                            style: styles.tfInput,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: styles.tfInput,
                            inputProps: { min: 0 },
                          }}
                          onChange={(e) => {
                            Dia.MaxQuantity = e.target.value;
                            forceUpdate();
                            setSplitingParamsChanged(true);
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            container
            className={classes["ParametersFields"]}
            paddingTop={4}
            direction="row"
            xs={12}
          >
            <Grid
              item
              xs={2.2}
              sm={1.2}
              md={0.5}
              className={classes["ParametersFields"]}
            >
              Split Label based on
            </Grid>
            <Grid item container direction="column" xs={9} sm={2} gap={1}>
              <Grid item container direction="row" gap={1}>
                <Grid
                  item
                  onClick={() => {
                    if (!disabledFG) {
                      setSplitMaxWeight(true);
                      setSplitMaxNoPcs(false);
                      setSplitingParamsChanged(true);
                    }
                  }}
                  className={
                    splitMaxWeight
                      ? classes["CheckedBox"]
                      : classes["notCheckedBox"]
                  }
                ></Grid>
                <Grid item>max weight</Grid>
              </Grid>
              <Grid item container direction="row" gap={1}>
                <Grid
                  item
                  onClick={() => {
                    if (!disabledFG) {
                      setSplitMaxWeight(false);
                      setSplitMaxNoPcs(true);
                      setSplitingParamsChanged(true);

                    }
                  }}
                  className={
                    splitMaxNoPcs
                      ? classes["CheckedBox"]
                      : classes["notCheckedBox"]
                  }
                ></Grid>
                <Grid item>max no of pcs</Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2.2}
              sm={1.2}
              md={0.7}
              className={classes["ParametersFields"]}
              paddingTop={{ xs: 2, sm: 0 }}
            >
              Distribution
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={9}
              sm={6}
              gap={1}
              paddingTop={{ xs: 2, sm: 0 }}
            >
              <Grid item container direction="row" gap={1}>
                <Grid
                  item
                  onClick={() => {
                    if (!disabledFG) {
                      setDistMaxQ(true);
                      setDistEqualQ(false);
                      setSplitingParamsChanged(true);
                    }
                  }}
                  className={
                    distMaxQ ? classes["CheckedBox"] : classes["notCheckedBox"]
                  }
                ></Grid>
                <Grid item>max qty of distribution</Grid>
              </Grid>
              <Grid item container direction="row" gap={1}>
                <Grid
                  item
                  onClick={() => {
                    if (!disabledFG) {
                      setDistMaxQ(false);
                      setDistEqualQ(true);
                      setSplitingParamsChanged(true);
                    }
                  }}
                  className={
                    distEqualQ
                      ? classes["CheckedBox"]
                      : classes["notCheckedBox"]
                  }
                ></Grid>
                <Grid item>equal qty distribution</Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes["OptimizationParameters"]}
            paddingTop={2}
          >
            Parameters
          </Grid>
          <Grid item xs={12} className={classes["ParameterType"]}>
            Loading and delivery
          </Grid>

          <Grid item container direction="row" xs={12} gap={1} paddingTop={2}>
            <Grid
              item
              xs={2}
              sm={1}
              md={1.4}
              className={classes["ParametersFields"]}
              paddingTop={0.5}
            >
              Max load weight (Kg)
            </Grid>

            <Grid item className={classes["ParametersFields"]} xs={2} md={1}>
              <TextField
                type="number"
                disabled={disabledDN}
                value={maxLoadWeight}
                error={maxLoadWeightError}
                fullWidth
                placeholder="number"
                InputProps={{
                  style: { height: "25px", fontSize: "11px" },
                  inputProps: { min: 0 },
                }}
                onBlur={(e) => {
                  if (!e.target.value) setMaxLoadWeightError(true);
                }}
                onChange={(e) => {
                  setMaxLoadWeight(e.target.value);
                  if (!e.target.value) setMaxLoadWeightError(true);
                  else if (+e.target.value < +maxSOWeight) {
                    setMaxSOWeightError(true);
                  } else {
                    setMaxLoadWeightError(false);
                    setMaxSOWeightError(false);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12} gap={1} paddingTop={1}>
            <Grid
              item
              xs={2}
              sm={1}
              md={1.4}
              className={classes["ParametersFields"]}
              paddingTop={0.5}
            >
              Max sales order weight (Kg)
            </Grid>

            <Grid item className={classes["ParametersFields"]} xs={2} md={1}>
              <TextField
                type="number"
                disabled={disabledDN}
                value={maxSOWeight}
                error={maxSOWeightError}
                helperText={
                  maxSOWeightError
                    ? "The sales order weight exceeds max load weight"
                    : null
                }
                fullWidth
                placeholder="number"
                InputProps={{
                  style: { height: "25px", fontSize: "11px" },
                  inputProps: { min: 0 },
                }}
                onChange={(e) => {
                  if (+e.target.value > +maxLoadWeight) {
                    setMaxSOWeightError(true);
                  } else {
                    setMaxSOWeightError(false);
                  }
                  setMaxSOWeight(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes["OptimizationParameters"]}
            paddingTop={4}
          >
            Parameters
          </Grid>
          <Grid item xs={12} className={classes["ParameterType"]}>
            Cutting
          </Grid>
          <Grid
            item
            xs={12}
            className={classes["ParametersFields"]}
            paddingTop={2}
          >
            Length Optimization tolerances in (mm)
          </Grid>
          <Grid item container direction="row" xs={12} gap={1} paddingTop={2}>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              className={classes["ParametersFields"]}
            >
              Bars with no Bend
            </Grid>
            <Grid item container direction="column" xs={2} sm={1} gap={0.5}>
              <Grid item className={classes["MinMaxFields"]}>
                * -Tolerance
              </Grid>

              <Grid item className={classes["ParametersFields"]}>
                <TextField
                  disabled={disabledCutting}
                  type="number"
                  value={noBendMinNb}
                  error={noBendMinNbError}
                  fullWidth
                  placeholder="number"
                  InputProps={{
                    style: { height: "25px", fontSize: "11px" },
                    inputProps: { min: 0 },
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) setNoBendMinNbError(true);
                  }}
                  onChange={(e) => {
                    if (!e.target.value) setNoBendMinNbError(true);
                    else {
                      setNoBendMinNbError(false);
                    }
                    setNoBendMinNb(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={2} sm={1} gap={0.5}>
              <Grid item className={classes["MinMaxFields"]}>
                * +Tolerance
              </Grid>

              <Grid item className={classes["ParametersFields"]}>
                <TextField
                  disabled={disabledCutting}
                  type="number"
                  value={noBendMaxNb}
                  error={noBendMaxNbError}
                  fullWidth
                  placeholder="number"
                  InputProps={{
                    style: { height: "25px", fontSize: "11px" },
                    inputProps: { min: 0 },
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) setNoBendMaxNbError(true);
                  }}
                  onChange={(e) => {
                    if (!e.target.value) setNoBendMaxNbError(true);
                    else {
                      setNoBendMaxNbError(false);
                    }
                    setNoBendMaxNb(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="row" xs={12} gap={1} paddingTop={2}>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              className={classes["ParametersFields"]}
            >
              Bars with Bend
            </Grid>
            <Grid item container direction="column" xs={2} sm={1} gap={0.5}>
              <Grid item className={classes["MinMaxFields"]}>
                * -Tolerance
              </Grid>

              <Grid item className={classes["ParametersFields"]}>
                <TextField
                  type="number"
                  value={bendMinNb}
                  disabled={disabledCutting}
                  error={bendMinNbError}
                  fullWidth
                  placeholder="number"
                  InputProps={{
                    style: { height: "25px", fontSize: "11px" },
                    inputProps: { min: 0 },
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) setBendMinNbError(true);
                  }}
                  onChange={(e) => {
                    if (!e.target.value) setBendMinNbError(true);
                    else {
                      setBendMinNbError(false);
                    }
                    setBendMinNb(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={2} sm={1} gap={0.5}>
              <Grid item className={classes["MinMaxFields"]}>
                * +Tolerance
              </Grid>

              <Grid item className={classes["ParametersFields"]}>
                <TextField
                  type="number"
                  value={bendMaxNb}
                  disabled={disabledCutting}
                  error={bendMaxNbError}
                  fullWidth
                  placeholder="number"
                  InputProps={{
                    style: { height: "25px", fontSize: "11px" },
                    inputProps: { min: 0 },
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) setBendMaxNbError(true);
                  }}
                  onChange={(e) => {
                    if (!e.target.value) setBendMaxNbError(true);
                    else {
                      setBendMaxNbError(false);
                    }
                    setBendMaxNb(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {localStorage.getItem("planIdManu")?.includes("Core") ||
            localStorage.getItem("IsFreePlanActivated") === "true" ? (
            <Grid item container xs={10} className="hintMsg" paddingTop={4}>
              ** Upgrade your Manufacturing subscription to add Dock/Transit
              Time Slots **
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} container direction={"row"} gap={1} paddingTop={4} marginTop={1.5} alignItems="center">
            <Grid item>
              <img src={DocksIcoon} alt={"img"} width={45} />
            </Grid>
            <Grid item className={classes["Docks"]}>
              Docks
            </Grid>
            <BlueTip
              arrow
              open={tooltipIsOpen}
              title={
                <>
                  <Grid
                    item
                    container
                    paddingTop={3}
                    paddingLeft={1}
                    direction={"row"}
                    xs={12}
                    gap={3}
                  >
                    <Grid item className={classes["ttinput"]} paddingTop={0.5}>
                      Bay#
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      container
                      direction={"row"}
                      gap={1}
                      height={40}
                      overflow={"auto"}
                    >
                      {bays?.map((bay, index) => (
                        <Grid
                          item
                          paddingTop={0.5}
                          className={
                            dockToBeAdded.BayId === bay.Id
                              ? classes["baySelected"]
                              : classes["bayNotSelected"]
                          }
                          onClick={() => {
                            dockToBeAdded.BayId = bay.Id;
                            forceUpdate();
                          }}
                        >
                          {index + 1}
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item className={classes["ttinput"]} paddingTop={0.5}>
                      Dock Code
                    </Grid>
                    <Grid item sm={4}>
                      <TextField
                        value={dockToBeAdded.Code}
                        disabled
                        fullWidth
                        InputProps={{
                          style: {
                            height: "22px",
                            fontSize: 12,
                            fontFamily: "Muli",
                            backgroundColor: "#fff",
                          },
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    paddingTop={3}
                    paddingLeft={1}
                    direction={"row"}
                    xs={12}
                    gap={1}
                  >
                    <Grid
                      item
                      className={classes["ttinput"]}
                      paddingTop={0.5}
                      sm={1.7}
                    >
                      Dock Name
                    </Grid>
                    <Grid item sm={2.5}>
                      <TextField
                        value={dockToBeAdded.Name}
                        fullWidth
                        InputProps={{
                          style: {
                            height: "22px",
                            fontSize: 12,
                            fontFamily: "Muli",
                            backgroundColor: "#fff",
                          },
                        }}
                        onChange={(e) => {
                          dockToBeAdded.Name = e.target.value;
                          forceUpdate();
                        }}
                      ></TextField>
                    </Grid>
                    <Grid
                      item
                      className={classes["ttinput"]}
                      paddingTop={0.5}
                      sm={2}
                    >
                      Dimension (m)
                    </Grid>
                    <Grid item container direction={"row"} sm={5} gap={0.5}>
                      <Grid item sm={5.5}>
                        <TextField
                          value={dockToBeAdded.Length}
                          fullWidth
                          type="number"
                          InputProps={{
                            style: {
                              height: "22px",
                              fontSize: 12,
                              fontFamily: "Muli",
                              backgroundColor: "#fff",
                            },
                            inputProps: { min: 0 },
                          }}
                          placeholder={"Length"}
                          onChange={(e) => {
                            dockToBeAdded.Length = e.target.value;
                            forceUpdate();
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item sm={5.5}>
                        <TextField
                          value={dockToBeAdded.Width}
                          fullWidth
                          type="number"
                          InputProps={{
                            style: {
                              height: "22px",
                              fontSize: 12,
                              fontFamily: "Muli",
                              backgroundColor: "#fff",
                            },
                            inputProps: { min: 0 },
                          }}
                          placeholder={"Width"}
                          onChange={(e) => {
                            dockToBeAdded.Width = e.target.value;
                            forceUpdate();
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row-reverse"
                    spacing={2}
                    paddingTop={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <button
                        disabled={
                          !dockToBeAdded.BayId ||
                            !dockToBeAdded.Name ||
                            !dockToBeAdded.Width ||
                            !dockToBeAdded.Length
                            ? true
                            : false
                        }
                        className={classes["SaveButtool"]}
                        onClick={() => {
                          let temp = structuredClone(dockToBeAdded);
                          setDocks(docks.concat(temp));
                          setTooltipIsOpen(false);
                          setDockToBeAdded({
                            Id: null,
                            BayId: 0,
                            Code: null,
                            Name: null,
                            Length: null,
                            Width: null,
                          });
                        }}
                      >
                        Save
                      </button>
                    </Grid>
                    <Grid item>
                      <button
                        className={classes["cancelbuttool"]}
                        onClick={() => {
                          setTooltipIsOpen(false);
                          setDockToBeAdded({
                            Id: null,
                            BayId: 0,
                            Code: null,
                            Name: null,
                            Length: null,
                            Width: null,
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </Grid>
                  </Grid>
                </>
              }
              sx={{
                "& .MuiTooltip-arrow": {
                  top: "-25px !important",
                  "&::before": {
                    backgroundColor: "rgba(45, 164, 208, 0.3)",
                  },
                },
              }}
              interactive="true"
              placement={"right"}
            >
              <Grid
                item
                paddingTop={1.2}
                onClick={() => {
                  if (!disabledDN && localStorage.getItem("planIdManu")?.includes("Pro"))
                    setTooltipIsOpen(true);
                }}
                onMouseOver={(e) => {
                  if (localStorage.getItem("planIdManu")?.includes("Pro"))
                    e.target.style.cursor = "pointer";
                }}
              >
                <img src={PlusIcoon} alt={"img"} width="18px" height="18px" />
              </Grid>
            </BlueTip>
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            gap={2}
            paddingLeft={5}
            paddingTop={2}
          >
            {docks?.map((dock, index) => (
              <Grid item container direction={"row"} gap={1}>
                <BlueTip
                  arrow
                  open={
                    tooltipEditIsOpen && index === dockChosenId ? true : false
                  }
                  title={
                    <>
                      <Grid
                        item
                        container
                        paddingTop={3}
                        paddingLeft={1}
                        direction={"row"}
                        xs={12}
                        gap={3}
                      >
                        <Grid
                          item
                          className={classes["ttinput"]}
                          paddingTop={0.5}
                        >
                          Bay#
                        </Grid>
                        <Grid
                          item
                          sm={3}
                          container
                          direction={"row"}
                          gap={1}
                          height={40}
                          overflow={"auto"}
                        >
                          {bays?.map((bay, ind) => (
                            <Grid
                              item
                              className={
                                dock.BayId === bay.Id
                                  ? classes["baySelected"]
                                  : classes["bayNotSelected"]
                              }
                              onClick={() => {
                                dock.BayId = bay.Id;
                                forceUpdate();
                              }}
                            >
                              {ind + 1}
                            </Grid>
                          ))}
                        </Grid>
                        <Grid
                          item
                          className={classes["ttinput"]}
                          paddingTop={0.5}
                        >
                          Dock Code
                        </Grid>
                        <Grid item sm={4}>
                          <TextField
                            value={dock.Code}
                            disabled
                            fullWidth
                            InputProps={{
                              style: {
                                height: "22px",
                                fontSize: 12,
                                fontFamily: "Muli",
                                backgroundColor: "#fff",
                              },
                            }}
                          ></TextField>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        paddingTop={3}
                        paddingLeft={1}
                        direction={"row"}
                        xs={12}
                        gap={1}
                      >
                        <Grid
                          item
                          className={classes["ttinput"]}
                          paddingTop={0.5}
                          sm={1.1}
                        >
                          Dock Name
                        </Grid>
                        <Grid item sm={2.5}>
                          <TextField
                            value={dock.Name}
                            fullWidth
                            InputProps={{
                              style: {
                                height: "22px",
                                fontSize: 12,
                                fontFamily: "Muli",
                                backgroundColor: "#fff",
                              },
                            }}
                            onChange={(e) => {
                              dock.Name = e.target.value;
                              forceUpdate();
                            }}
                          ></TextField>
                        </Grid>
                        <Grid
                          item
                          className={classes["ttinput"]}
                          paddingTop={0.5}
                          sm={2}
                        >
                          Dimension (m)
                        </Grid>
                        <Grid
                          item
                          container
                          direction={"row"}
                          sm={5.5}
                          gap={0.5}
                        >
                          <Grid item sm={5.5}>
                            <TextField
                              value={dock.Length}
                              fullWidth
                              type="number"
                              InputProps={{
                                style: {
                                  height: "22px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                  backgroundColor: "#fff",
                                },
                                inputProps: { min: 0 },
                              }}
                              placeholder={"Length"}
                              onChange={(e) => {
                                dock.Length = e.target.value;
                                forceUpdate();
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item sm={5.5}>
                            <TextField
                              value={dock.Width}
                              fullWidth
                              type="number"
                              InputProps={{
                                style: {
                                  height: "22px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                  backgroundColor: "#fff",
                                },
                                inputProps: { min: 0 },
                              }}
                              placeholder={"Width"}
                              onChange={(e) => {
                                dock.Width = e.target.value;
                                forceUpdate();
                              }}
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row-reverse"
                        spacing={2}
                        paddingTop={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <button
                            disabled={
                              !dock.BayId ||
                                !dock.Name ||
                                !dock.Width ||
                                !dock.Length
                                ? true
                                : false
                            }
                            className={classes["SaveButtool"]}
                            onClick={() => {
                              setTooltipEditIsOpen(false);
                            }}
                          >
                            Save
                          </button>
                        </Grid>
                        <Grid item>
                          <button
                            className={classes["cancelbuttool"]}
                            onClick={() => {
                              setTooltipEditIsOpen(false);
                              setDockChosenId(null);
                            }}
                          >
                            Cancel
                          </button>
                        </Grid>
                      </Grid>
                    </>
                  }
                  sx={{
                    "& .MuiTooltip-arrow": {
                      top: "-25px !important",
                      "&::before": {
                        backgroundColor: "rgba(45, 164, 208, 0.3)",
                      },
                    },
                  }}
                  interactive="true"
                  placement={"right"}
                >
                  <Grid item className={classes["ParametersFields"]}>
                    {dock.Name}
                  </Grid>
                </BlueTip>
                <Grid
                  item
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    setDockChosenId(index);
                    handleClick(e);
                  }}
                >
                  {dockChosenId === index ? (
                    <ArrowDropUpIcon htmlColor="#707070" fontSize="small" />
                  ) : (
                    <ArrowDropDownIcon htmlColor="#707070" fontSize="small" />
                  )}
                </Grid>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem
                    disabled={disabledDN}
                    className={classes["ParametersFields"]}
                    onClick={() => {
                      setTooltipEditIsOpen(true);
                      handleClose();
                    }}
                  >
                    Edit Dock
                  </MenuItem>
                  <MenuItem
                    disabled={disabledDN}
                    className={classes["ParametersFields"]}
                    onClick={() => {
                      handleClose();

                      setDocks(
                        docks.filter((x, indx) => indx !== dockChosenId)
                      );
                    }}
                  >
                    Delete Dock
                  </MenuItem>
                </Menu>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} container direction={"row"} gap={1} paddingTop={3}>
            <Grid
              item
              // sx={{
              //   fontFamily: "Muli",
              //   fontSize: "15px",
              //   fontWeight: "bold",
              //   lineHeight: 1.27,
              //   textAlign: "left",
              //   color: "#101650",
              // }}
              className={classes["Docks"]}
            >
              Transit Time Slots
            </Grid>
            <Grid
              item
              onClick={() => {
                if (
                  !disabledDN &&
                  localStorage.getItem("IsFreePlanActivated") !== "true" &&
                  !localStorage.getItem("planIdManu")?.includes("Core")
                )
                  setTimeSlots(
                    timeSlots.concat({
                      Id: null,
                      Code: null,
                      Days: [],
                      StartTime: null,
                      EndTime: null,
                    })
                  );
              }}
              onMouseOver={(e) => {
                if (
                  localStorage.getItem("IsFreePlanActivated") !== "true" &&
                  !localStorage.getItem("planIdManu")?.includes("Core")
                )
                  e.target.style.cursor = "pointer";
              }}
            >
              <img src={PlusIcoon} alt={"img"} width="18px" height="18px" />
            </Grid>
          </Grid>
          {timeSlots?.map((x, index) => (
            <Grid
              item
              container
              direction="row"
              key={index}
              alignItem="center"
              gap={2}
              paddingTop={2}
            >
              <Grid item width="177px">
                <TextField
                  disabled={disabledDN}
                  size="small"
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        marginTop: "10px",
                        height: "139px",
                      },
                    },
                  }}
                  label={"Days"}
                  select
                  SelectProps={{
                    multiple: true,
                    MenuProps: { disableScrollLock: true },
                  }}
                  value={x.Days}
                  fullWidth
                  sx={{
                    fontFamily: "Muli",
                    fontSize: "11px",
                    height: "39px",
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      paddingTop:"5px",
                      fontFamily: "Muli",
                      height:"40px"
                    },
                  }}
                  onChange={(e) => {
                    x.Days = e.target.value;
                    forceUpdate();
                  }}
                >
                  {days?.map((r) => (
                    <MenuItem key={r.Id} value={r.Id} style={{fontSize:"10px"}}>
                      {r.Name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item width="177px">
                <TextField
                  disabled={disabledDN}
                  value={x.Code}
                  fullWidth
                  size={"small"}
                  variant={"outlined"}
                  InputProps={{
                    style: {
                      fontFamily: "Muli",
                      backgroundColor: "#fff",
                      fontSize: "15px",
                      height:"40px"
                    },
                  }}
                  label={"Time Slot Code"}
                  onChange={(e) => {
                    x.Code = e.target.value;
                    forceUpdate();
                  }}
                ></TextField>
              </Grid>
              <Grid item width="177px">
                <TextField
                  disabled={disabledDN}
                  size="small"
                  variant="outlined"
                  error={false}
                  fullWidth
                  type={"time"}
                  label={"Start Time"}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      fontFamily: "Muli",
                      backgroundColor: "#fff",
                      fontSize: "15px",
                      height:"40px"
                    },
                  }}
                  value={x.StartTime}
                  onChange={(e) => {
                    x.StartTime = e.target.value;
                    forceUpdate();
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: "14px",
                  lineHeight: 1.29,
                  textAlign: "left",
                  color: "#101650",
                  paddingTop: "9px"
                }}
              >
                To
              </Grid>
              <Grid item width="177px">
                <TextField
                  disabled={disabledDN}
                  size="small"
                  type={"time"}
                  label={"End Time"}
                  value={x.EndTime}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      fontFamily: "Muli",
                      backgroundColor: "#fff",
                      fontSize: "15px",
                      height:"40px"
                    },
                  }}
                  onChange={(e) => {
                    x.EndTime = e.target.value;
                    forceUpdate();
                  }}
                  variant="outlined"
                  error={false}
                  fullWidth
                />
              </Grid>
              <Grid
                paddingTop={1}
                item
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  if (!disabledDN)
                    setTimeSlots(timeSlots.filter((x, i) => i !== index));
                }}
              >
                <img src={RemoveIcon} alt={"img"} />
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={12}
            fontWeight={"bold"}
            color={"#ea001e"}
            paddingTop={1}
          >
            {emptyFieldInTS ? errorMessage : ""}
          </Grid>
          <Grid
            item
            container
            direction="row-reverse"
            spacing={2}
            paddingTop={4}
            alignItems="center"
          >
            <Grid item>
              <button
                // disabled={isDisabled}
                className={classes["SaveBut"]}
                onClick={() => {
                  if (splitingParamsChanged) setOpenAlertModal(true);
                  else save()
                }}
                disabled={
                  (!splitMaxNoPcs && !splitMaxWeight) || (!distEqualQ && !distMaxQ) || !noBendMaxNb || !noBendMinNb || !bendMaxNb || !bendMinNb
                }
              >
                {loading ? "Saving" : "Save"}
              </button>
            </Grid>
            <Grid item>
              <button
                className={classes["cancel-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </Grid>

            {successMsgSaved ? (
              <>
                <Grid item className={classes["ParametersFields"]}>
                  Succesfully Saved
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} modify={modify} setModify={setModify} />
      <AlertModal openAlertModal={openAlertModal} setOpenAlertModal={setOpenAlertModal} save={save} modify={modify} setModify={setModify} updateLoading={updateLoading} loading={loading} successMsgSaved={successMsgSaved} />
    </div>
  );
}

const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
          min-width: 500px;
          height: 160px;
          padding: 5px;
          border-radius: 4px;
          margin-top:40px;
          background-color:rgba(45, 164, 208, 0.3);


  `);
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 157,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 112,
  },
  tableCellhead2: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 150,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 266,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
  tfInput: {
    color: "#707070",
    height: "28px",
    fontSize: 12,
    fontFamily: "Muli",
  },
};

export default MasterParameters;
