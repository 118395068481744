import React, { useState, useEffect } from "react";
import { Grid, TextField, Box, Button } from "@mui/material";
import iotLogin from "../../Assets/Images/Login/iotLogin.png";
import PCLogo from "../../Assets/Images/Login/PCLogo.png";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import * as services from "../../SAAS/Services/machine-dashboard-services.proxy";

const IOTLogIn = services.IOTLogIn;

function LoginView(props) {
  const [iotCode, setIotCode] = useState("");
  const [password, setPassword] = useState("");
  const [iotError, setIotError] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    props.setShowSaaSNavbar(false);
  }, [props.setShowSaaSNavbar]);

  useEffect(() => {
    let apiTest = true;
    if (apiTest) {
      if (iotCode === "" || password === "") setIsDisabled(true);
      else setIsDisabled(false);
    }
    return () => {
      apiTest = false;
    };
  }, [iotCode, password]);

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          backgroundColor: "#e1e319",
          height: "100vh",
        }}
        container
        alignItems="center"
        justifyContent="center"
        paddingBottom={{ xs: "50%", md: "" }}
      >
        <Grid item>
          <img src={iotLogin} alt="iot" />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        xs={6}
        backgroundColor="#2da4d0"
        paddingTop={10}
        gap={1}
        alignItems="center"
        style={{ fontFamily: "Muli", color: "#fff" }}
      >
        <Grid item container direction="row-reverse" paddingRight={3}>
          {" "}
          <button
            className="back-button"
            onClick={() => {
              localStorage.removeItem("machineId");
              navigate('/');
            }}
          >
            Go Back
          </button>
        </Grid>
        <Grid item>
          <img src={PCLogo} width="250px" height="80px" />
        </Grid>
        <Grid item fontSize="25px" fontWeight="bold">
          IOT login area
        </Grid>

        <Grid
          item
          container
          direction="column"
          fontSize="15px"
          paddingTop={5}
          gap={1}
          paddingLeft={{ xs: "20%", md: "33%" }}
        >
          <Grid item>IOT Code</Grid>
          <Grid item width="250px">
            <TextField
              fullWidth
              value={iotCode}
              size="small"
              InputProps={{
                style: {
                  backgroundColor: "#fff",
                },
              }}
              onChange={(e) => {
                setIotCode(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          fontSize="15px"
          gap={1}
          paddingLeft={{ xs: "20%", md: "33%" }}
        >
          <Grid item>Password</Grid>
          <Grid item>
            <TextField
              type={showPassword ? "text" : "password"}
              size="small"
              InputProps={{
                style: {
                  backgroundColor: "#fff",
                },
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </Grid>
        </Grid>
        <Grid item paddingTop={3} width="250px">
          <Button
            variant="contained"
            disabled={isDisabled || loadingProgress}
            onClick={() => {
              setLoadingProgress(true);
              IOTLogIn(iotCode, password).then((res) => {
                if (res.status) {
                  setIotError(res.error);
                  setLoadingProgress(false);
                } else {
                  setIotError(null);
                  localStorage.setItem("machineId", res.MachineId);
                  localStorage.setItem("InventoryId", res.InventoryId);
                  localStorage.setItem("machineCategory", res.CategoryId);
                  localStorage.setItem("ProductionCellId",res.ProductionCellId);
                  // navigate("/machine/login");
                  navigate('/machine/connect')
                  setLoadingProgress(false);
                }
              });
            }}
            fullWidth
          >
            Log In &nbsp;
            {loadingProgress && <CircularProgress size={20} />}
          </Button>

          {iotError ?
          <Grid item marginTop={2}>{iotError}</Grid>  : ""}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginView;
